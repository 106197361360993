<template>
  <div class="suppliers-customer-view-page">
    <div class="page-header">
      <h4>Customer | {{ customer.name }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'SupplierDashboard'}">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'SupplierCustomers'}">View Customers</router-link>
        </li>
        <li class="breadcrumb-item active">Customer Details</li>
      </ol>
      <div class="page-header-nav-options">
        <b-button
            v-if="showSaveButton"
            @click="onClickSave"
            class="btn-submit-save mb-1 ml-0 text-center w-100 d-block d-lg-none"
            variant="primary">
          Save
        </b-button>
      </div>
    </div>

    <div class="suppliers-customer-view-body">
      <tabs ref="suppliers-customer-view-table" cache-lifetime="0"
            @changed="changedTab">
        <tab name="Account Summary" id="suppliers-customer-view-account-summary" :class-name="'tab-content'"
             style="padding-top: 15px;">
          <div class="row no-gutter">
            <div class="col-12 col-md-3">
              <div class="row no-gutter">
                <div class="col-12">
                  <div class="detail">
                    <div class="detail__header">
                      Customer
                    </div>
                    <div class="detail__body text-center">
                      <!--                                            <div class="detail__logo">-->
                      <!--                                                <template v-if="customer.logoUrl">-->
                      <!--                                                    <div class="detail__img-container">-->
                      <!--                                                        <img class="img-fluid" :src="customer.logoUrl"/>-->
                      <!--                                                    </div>-->
                      <!--                                                    &lt;!&ndash; <a href="#"-->
                      <!--                                                        v-if="url"-->
                      <!--                                                        class="detail__edit-logo"-->
                      <!--                                                        @click.prevent="handleEditLogo">-->
                      <!--                                                        <i class='bx bx-pencil' ></i>-->
                      <!--                                                    </a> &ndash;&gt;-->
                      <!--                                                </template>-->
                      <!--                                                <template v-else>-->
                      <!--                                                    <vue-dropzone-->
                      <!--                                                        @vdropzone-file-added="handleFileAdded"-->
                      <!--                                                        :options="dropzoneOptions"-->
                      <!--                                                        ref="uploadLogoDropzone"-->
                      <!--                                                        id="logoUpload"-->
                      <!--                                                        :useCustomSlot=true>-->
                      <!--                                                        <div class="dropzone-custom-content">-->
                      <!--                                                            <span>Logo</span>-->
                      <!--                                                        </div>-->
                      <!--                                                    </vue-dropzone>-->
                      <!--                                                </template>-->
                      <!--                                            </div>-->

                      <h2 class="detail__heading font-16 font-inter-bold">{{
                          customer.name
                        }}</h2>
                      <p class="small">Customer Since: {{ customer.since }}</p>
                      <p class="small">Created By: {{ customer.createdBy }}</p>
                      <p class="small detail__status d-flex justify-content-center align-items-center">
                        Status: <i class='bx bxs-circle mx-2 detail__status-icon'
                                   :class="customerIsActive ? 'active' : ''"></i> <span
                          class="font-16">{{ customerIsActive ? 'Active' : 'Not Active' }}</span></p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="detail">
                    <div class="detail__header font-13">
                      About {{ customer.name }}
                    </div>
                    <div class="detail__body text-center">
                      <div class="row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold account-number-field">Account
                          Number</p></div>
                        <div class="col-12 col-md-6">
                          <p class="">
                            <b-form-input v-model="customer.number" type="text" class="form-control"
                                          placeholder="Account Number"></b-form-input>
                          </p>
                        </div>
                      </div>
                      <div class="row customer-type-row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold">Customer
                          Type</p></div>
                        <div class="col-12 col-md-6">
                          <p class="">
                            <multiselect
                                v-model="computedCustomerType"
                                :options="customerTypeOptions"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Customer Type"
                            />
                          </p>
                        </div>
                      </div>
                      <div class="row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold">Customer
                          Phone</p></div>
                        <div class="col-12 col-md-6"><p class="">{{ customer.phone }}</p></div>
                      </div>
                      <div class="row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold">Customer
                          Email</p></div>
                        <div class="col-12 col-md-6"><p class="">{{ customer.email }}</p></div>
                      </div>
                      <div class="row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold">Contact People</p></div>
                        <div class="col-12 col-md-6">
                          <p class="" v-for="contact in customer.contacts" :key="contact.id">{{ contact.fullName }}</p>
                          <!--                                                    <b-link class="suppliers-customer-view-body__link">Add User</b-link>-->
                        </div>
                      </div>
                      <div class="row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold">Business
                          Address</p></div>
                        <div class="col-12 col-md-6">
                          <p class="">{{ customer.address }}</p>
                          <!--                                                    <b-link class="suppliers-customer-view-body__link">Edit</b-link>-->
                        </div>
                      </div>
                      <div v-for="(location, key) in customer.locations" :key="key"
                           class="row text-left no-gutter detail__item">
                        <div class="col-12 col-md-6"><p class="font-weight-bold">Warehouse Location
                          {{ (key > 0) ? key + 1 : '' }}</p></div>
                        <div class="col-12 col-md-6">
                          <p class="">
                            <span>{{ location.address }}</span>
                            <span v-if="location.suburb"><span v-if="location.address">, </span>{{
                                location.suburb
                              }}</span>
                            <span v-if="location.state"><span
                                v-if="location.address || location.suburb">, </span>{{ location.state }}</span>
                          </p>
                          <!--                                                    <b-link class="suppliers-customer-view-body__link">Edit</b-link>-->
                        </div>
                      </div>
                      <!--                                            <b-button block variant="outline-primary font-13">
                                                                      View All Properties
                                                                  </b-button>-->
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row no-gutter">
                <div class="col-12">
                  <tab-table title="Current Orders"
                             :items="currentOrders"
                             :fields="ordersAllFields"
                             :filter="filter"
                             @row-clicked="onOrderRowClicked"
                             @view-more-clicked="onOrderViewMoreClicked">
                  </tab-table>
                </div>

                <div class="col-12">
                  <tab-table title="Current Active"
                             :items="currentRfqs"
                             :fields="activeRequestAllFields"
                             :filter="filter"
                             @row-clicked="onActiveRequestRowClicked"
                             @view-more-clicked="onActiveRequestViewMoreClicked">
                  </tab-table>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab name="RFQ" id="suppliers-customer-view-rfq" :class-name="'tab-content-table'">
          <tab-paginated-table
              :fields="rfqFields"
              :items="rfqs"
              :filter="filter"
              :current-page="currentPage.rfq"
              :per-page="perPage.rfq"
              @row-clicked="onRfqRowClicked"
          >
          </tab-paginated-table>
        </tab>
        <tab name="Orders" id="suppliers-customer-view-orders" :class-name="'tab-content-table'">
          <tab-paginated-table
              :fields="orderFields"
              :items="orders"
              :filter="filter"
              @row-clicked="onOrderDetailRowClicked"
          >
          </tab-paginated-table>
        </tab>
        <tab name="Credits" id="suppliers-customer-view-credits" :class-name="'tab-content-table'">
          <tab-paginated-table
              :fields="creditFields"
              :items="credits"
              :filter="filter"
              @row-clicked="onCreditDetailRowClicked"
          >
          </tab-paginated-table>
        </tab>
        <tab name="Details" id="suppliers-customer-view-details" :class-name="'tab-content-table'">
          <tabs cache-lifetime="0" :options="{ useUrlFragment: false }" class="supplier-customer-view-tabs">
            <tab name="Account" id="suppliers-customer-view-details-account" style="background-color: white">
              <div class="row" style="margin-top: 15px;">
                <div class="col-12 col-md-6">
                  <template v-for="form in detailsFormsLeft">
                    <div class="block" :key="form.title">
                      <div class="block-header bg-navyblue">
                        {{ form.title }} <small v-if="form.subtitle">{{ form.subtitle }}</small>
                      </div>
                      <div class="block-body bg-white px-2">
                        <template v-for="field in form.fields">
                          <b-form-group
                              label-cols-sm="4"
                              :label="field.label"
                              :label-for="form.id + '-' + field.id"
                              :key="form.id + '-' + field.id">
                            <template v-if="field.type === 'multiSelect' && field.id === 'repairer-type'">
                              <multiselect
                                  v-model="computedCustomerType"
                                  :options="customerTypeOptions"
                                  :showLabels="false"
                                  class="customer-type-multiselect"
                                  :option-height="29"
                                  :max-height="203"
                                  track-by="key"
                                  label="value"
                                  :close-on-select="true"
                                  placeholder="Customer Type"
                              />
                            </template>
                            <template v-else-if="field.type === 'text' && field.id === 'repairer-account-nbr'">
                              <b-form-input v-model="customer.number" type="text" class="form-control"
                                            placeholder="Account Number"></b-form-input>
                            </template>
                            <template v-else-if="field.type === 'multiSelect'">
                              <multiselect
                                  v-model="field.value"
                                  :options="field.options"
                                  track-by="value"
                                  label="value"
                                  :showLabels="false"
                                  :option-height="29"
                                  :max-height="203"
                                  :close-on-select="true"
                                  :placeholder="field.placeholder"
                              />
                            </template>
                            <template v-else-if="field.type === 'select'">
                              <b-form-select
                                  :id="form.id + '-' + field.id"
                                  v-model="field.value"
                                  :class="field.class"
                                  :options="field.options">
                              </b-form-select>
                            </template>
                            <template v-else-if="field.type === 'radio'">
                              <b-form-radio-group
                                  :id="form.id + '-' + field.id"
                                  v-model="field.value"
                                  size="lg"
                                  :name="form.id + '-' + field.id"
                                  :options="field.options">
                              </b-form-radio-group>
                            </template>
                            <template v-else-if="field.type === 'checkbox'">
                              <b-form-checkbox-group
                                  :id="form.id + '-' + field.id"
                                  v-model="field.value"
                                  :class="field.class"
                                  :name="form.id + '-' + field.id"
                                  :options="field.options">
                              </b-form-checkbox-group>
                              value: {{ field.value }}
                            </template>
                            <template v-else>
                              <b-form-input
                                  :id="form.id + '-' + field.id"
                                  :type="field.type"
                                  v-model="field.value"
                                  :class="field.class"
                                  :placeholder="field.placeholder">
                              </b-form-input>
                            </template>
                          </b-form-group>
                        </template>
                      </div>
                    </div>
                  </template>

                  <!--<div class="px-2 d-flex justify-content-end mt-4">
                      <b-button block variant="primary" class="mb-4 w-auto" style="min-width: 230px;">
                          <i class='bx bx-plus' style="font-size: 17px; vertical-align: middle;"></i>
                          Add Another Delivery Location
                      </b-button>
                  </div>-->
                </div>
                <div class="col-12 col-md-6">
                  <template v-for="form in detailsFormsRight">
                    <div class="block" :key="form.title">
                      <div class="block-header bg-navyblue">
                        {{ form.title }} <small v-if="form.subtitle">{{ form.subtitle }}</small>
                      </div>
                      <div class="block-body bg-white px-2">
                        <template v-for="field in form.fields">
                          <b-form-group
                              label-cols-sm="4"
                              :label="field.label"
                              :label-for="form.id + '-' + field.id"
                              :key="form.id + '-' + field.id">
                            <template v-if="field.type === 'select'">
                              <b-form-select
                                  :id="form.id + '-' + field.id"
                                  v-model="field.value"
                                  :class="field.class"
                                  :options="field.options">
                              </b-form-select>
                            </template>
                            <template v-else-if="field.type === 'radio'">
                              <b-form-radio-group
                                  :id="form.id + '-' + field.id"
                                  v-model="field.value"
                                  size="lg"
                                  :name="form.id + '-' + field.id"
                                  :options="field.options">
                              </b-form-radio-group>
                            </template>
                            <template v-else-if="field.type === 'checkbox'">
                              <b-form-checkbox-group
                                  :id="form.id + '-' + field.id"
                                  v-model="field.value"
                                  :class="field.class"
                                  :name="form.id + '-' + field.id"
                                  :options="field.options">
                              </b-form-checkbox-group>
                            </template>
                            <template v-else>
                              <b-form-input
                                  :id="form.id + '-' + field.id"
                                  :type="field.type"
                                  v-model="field.value"
                                  :class="field.class"
                                  :placeholder="field.placeholder">
                              </b-form-input>
                            </template>
                          </b-form-group>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </tab>
            <tab name="Credits" id="suppliers-customer-view-details-credit" style="background-color: white">
              <div class="col-md-6">
                <div class="group-title" style="margin-top: 15px;">
                  Credit Processing Policy
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Enable Credit Processing Policy</span> <br/>
                    <p class="notifications-info-text">Create and apply a policy in line with your business Credit processing Terms and Conditions.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 143px;">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          :disabled="customer.settings.credits.policies.applyAllCustomers"
                          v-model="customer.settings.credits.policies.enableCreditProcessingPolicy"
                          :class="{
                            'disabled-switch-checkbox': customer.settings.credits.policies.applyAllCustomers
                          }"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="customer.settings.credits.policies.enableCreditProcessingPolicy" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>

<!--                <div class="row">-->
<!--                  <label class="col-12 col-form-label col-lg-7">-->
<!--                    <span class="notifications-info-title">Apply to All Customers</span> <br/>-->
<!--                    <p class="notifications-info-text">Selecting <strong>Yes</strong> applies to all customers across-->
<!--                      all RFQ's (Default). Selecting <strong>No</strong>-->
<!--                      allows you set this by customer in the customers settings page.</p>-->
<!--                  </label>-->
<!--                  <div class="d-flex justify-content-end col-12 col-lg-5 apply-to-all-customers-checkboxes"-->
<!--                       style="place-items: center; padding-bottom: 20px; padding-right: 15px;">-->
<!--                    <div class="text-right row justify-content-end" style="padding-right: 15px;">-->
<!--                      <label style="cursor: pointer" class="form-check-inline radio">-->
<!--                        <input class="form-check-input" type="radio"-->
<!--                               name="remember"-->
<!--                               :value="true"-->
<!--                               v-model="customer.settings.credits.policies.applyAllCustomers"-->
<!--                               aria-label="Same as registered business address"-->
<!--                        >-->
<!--                        <span class="icon"><i class='bx bx-check'></i></span><span-->
<!--                          class="text received-quote">Yes</span>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                    <div class="text-right row justify-content-end">-->
<!--                      <label style="cursor: pointer" class="form-check-inline radio">-->
<!--                        <input class="form-check-input" type="radio"-->
<!--                               name="remember"-->
<!--                               :value="false"-->
<!--                               v-model="customer.settings.credits.policies.applyAllCustomers"-->
<!--                               aria-label="Same as registered business address"-->
<!--                        >-->
<!--                        <span class="icon"><i class='bx bx-check'></i></span><span-->
<!--                          class="text received-quote">No</span>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Reminder - Require Photos with Credit Request</span> <br/>
                    <p class="notifications-info-text">Enable this to remind customers that photos are required with every Credit request for processing.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 143px;">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          :class="{
                            'disabled-switch-checkbox': customer.settings.credits.policies.applyAllCustomers
                          }"
                          :disabled="customer.settings.credits.policies.applyAllCustomers"
                          v-model="customer.settings.credits.policies.requirePhotosEveryCreditRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="customer.settings.credits.policies.requirePhotosEveryCreditRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Reasons for Return to Display Photo</span> <br/>
                    <p class="notifications-info-text">Photos requests can be automatically displayed to the customer if they choose a reason for return. Select the Reason category to have your Photo requirement displayed.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px; width: 140px">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <multiselect
                          class="settings-rfq-response-time-multiselect credit-make-multiselect credit-multiselect-height settings-credit-reason-for-return-multiselect"
                          :showLabels="false"
                          :option-height="29"
                          style="min-width: 140px"
                          :disabled="customer.settings.credits.policies.applyAllCustomers"
                          :options="['Incorrect Parts Delivered', 'Incorrect Parts Ordered',  'Damaged Parts In Transport', 'Damaged Aprts Ex Warehouse', 'No Longer Needed', 'Change In Repair Method']"
                          v-model="customer.settings.credits.policies.reasonForReturnToDisplayPhoto"
                          :close-on-select="true"
                          :max-height="250"
                          :multiple="true"
                          placeholder="None"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Credit Return Period</span> <br/>
                    <p class="notifications-info-text">Set the number of days that a customer has to return a part for a
                      Credit.</p>
                    <p class="notifications-info-text">
                      If an attempt to request a credit is outside of nbr of days then they will be notified as per your
                      notice below.
                    </p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <b-form-input v-model="customer.settings.credits.policies.creditReturnPeriod" :disabled="customer.settings.credits.policies.applyAllCustomers" type="text" class="form-control"></b-form-input>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label">
                    <span class="notifications-info-title">Credit Return Terms & Conditions</span> <br/>
                    <p class="notifications-info-text">Add the credit return Terms and Conditions information you would like to appear when a customer creates a credit request outside of the return period.</p>
                  </label>
                  <div class="col-12 notifications-form-checkbox" style="padding-top: 15px; margin-bottom: 50px;">
                    <b-textarea v-model="customer.settings.credits.policies.creditReturnPeriodNotice" :disabled="customer.settings.credits.policies.applyAllCustomers" style="height: 155px"></b-textarea>
                  </div>
                </div>

              </div>
            </tab>
            <tab name="RFQ" id="suppliers-customer-view-details-RFQ" style="background-color: white">
              <div class="col-md-6">
                <div class="group-title mt-3">
                  Part Acceptance Settings
                </div>
                <div class="row mt-3">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Non-Returnable - Acceptance Agreement</span> <br/>
                    <p class="notifications-info-text">Define a Part acceptance agreement to apply for all customers to acknowledge as a digital acceptance record for In Stock parts.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          v-model="customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          :disabled="customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                          :class="{
                            'disabled-switch-checkbox': customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers
                          }"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Display Part Acceptance - T&C's</span> <br/>
                    <p class="notifications-info-text">Selecting <b>Yes</b> applies and displays your T&C's to every part
                      marked with this status to the Customer. No, excludes the display of your T&C's</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics"
                              class="form-check-input"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              type="radio"
                              :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics"
                              class="form-check-input"
                              type="radio"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              :value="false">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>


                <div :class="{'empty-editor': isEmpty(customer.settings.rfq.partAcceptanceSettings.tics) && customer.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics && isClickedSave}" class="ml-3 mb-3">
                  <vue-editor
                      :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                      v-model="customer.settings.rfq.partAcceptanceSettings.tics"
                      :editorToolbar="editorToolbar"></vue-editor>
                </div>

                <div style="height: 5px; background-color: rgba(27, 30, 56, 0.12) !important; margin: 10px 0px"></div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Require - Acceptance of Order T&C's</span> <br/>
                    <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to tick for acceptance of
                      the agreement and prevents them Ordering if not ticked.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics"
                              class="form-check-input"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              type="radio"
                              :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics"
                              class="form-check-input"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              type="radio"
                              :value="false">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Require - Signee to Add Name/Job Position</span> <br/>
                    <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to type their Job
                      position/role in your customers company.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition"
                              class="form-check-input"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              type="radio"
                              :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition"
                              class="form-check-input"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              type="radio"
                              :value="false">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Require - E-Signature</span> <br/>
                    <p class="notifications-info-text">Get your customer to E-Sign the acceptance on their Desktop, Mobile or Tablet device.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.requireESignature"
                              class="form-check-input"
                              type="radio"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.rfq.partAcceptanceSettings.requireESignature"
                              class="form-check-input"
                              type="radio"
                              :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                              :value="false">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Send Order Acceptance by</span> <br/>
                    <p class="notifications-info-text">Sends the form for your customer to accept & sign by the following
                      default method.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 220px;">
                      <multiselect
                          :showLabels="false"
                          :options="acceptanceByOptions"
                          :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                          v-model="computedSendOrderAcceptanceBy"
                          :option-height="29"
                          :track-by="'key'"
                          :label="'value'"
                          :max-height="203"
                          :allow-empty="false"
                          :close-on-select="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Notification of Acceptance</span> <br/>
                    <p class="notifications-info-text">Receive notifications after the customer accepts when you're offline.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 220px;">
                      <multiselect
                          :showLabels="false"
                          :options="notificationOfAcceptanceOptions"
                          :option-height="29"
                          :disabled="!customer.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement || customer.settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                          v-model="computedNotificationOfAcceptance"
                          :track-by="'key'"
                          :label="'value'"
                          :max-height="203"
                          :allow-empty="false"
                          :close-on-select="true"
                      />
                    </div>
                  </div>
                </div>

                <p v-if="isClickedSave && isEmpty(customer.settings.rfq.partAcceptanceSettings.tics) && customer.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics" style="padding-left: 15px;" class="error-text">Check missing highlighted fields to save</p>

                <div class="d-flex mb-2-7 mt-15 d-flex justify-content-end align-items-center">
                  <button @click="showModal" class="btn btn-secondary primary-show-example-btn mr-lg-2">Show Me an Example</button>
                  <button @click="onSaveNonReturnable" class="btn btn-primary primary-save-btn">Save</button>
                </div>

              </div>
            </tab>
            <tab name="Orders" id="suppliers-customer-view-details-Order" style="background-color: white">
              <div class="col-md-6">
                <div class="group-title mt-3">
                  Customer Order From Settings
                </div>
                <div class="row mt-3">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Back Order - Acceptance Agreement</span> <br/>
                    <p class="notifications-info-text">Define a part acceptance agreement to apply for all customers to
                      acknowledge as a digital acceptance record for parts.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          v-model="customer.settings.orders.specialOrder.enableAcceptanceAgreement"
                          :disabled="customer.settings.orders.specialOrder.customerToApply"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="customer.settings.orders.specialOrder.enableAcceptanceAgreement" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>

<!--                <div class="row">-->
<!--                  <label class="col-12 col-form-label col-lg-6">-->
<!--                    <span class="notifications-info-title">Apply to All Customers</span> <br/>-->
<!--                    <p class="notifications-info-text">Selecting <b>Yes</b> applies to all customers across all RFQ's-->
<!--                      (Default). Selecting <b>No</b> allows you set this by customer in the customers settings page.</p>-->
<!--                  </label>-->
<!--                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">-->
<!--                    <div class="d-flex justify-content-between" style="width: 140px;">-->
<!--                      <div>-->
<!--                        <label class="form-check-inline radio">-->
<!--                          <input class="form-check-input" type="radio"-->
<!--                                 :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement"-->
<!--                                 v-model="customer.settings.orders.specialOrder.customerToApply"-->
<!--                                 :value="true">-->
<!--                          <span class="icon clickable"><i class='bx bx-check'></i></span><span-->
<!--                            class="text">Yes</span>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                      <div class="">-->
<!--                        <label class="form-check-inline radio">-->
<!--                          <input-->
<!--                              v-model="customer.settings.orders.specialOrder.customerToApply"-->
<!--                              :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement"-->
<!--                              class="form-check-input" type="radio"-->
<!--                              :value="false">-->
<!--                          <span class="icon clickable"><i class='bx bx-check'></i></span><span-->
<!--                            class="text">No</span>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div style="height: 5px; background-color: rgba(27, 30, 56, 0.12) !important; margin: 10px 0px"></div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Display Order Acceptance - T&C's</span> <br/>
                    <p class="notifications-info-text">Selecting <b>Yes</b> applies and displays your T&c'S to every part
                      marked with this status to the Customer. No, excludes the display of your T&C's</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" type="radio"
                                 :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                                 v-model="customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable"
                                 :value="true">
                          <span :class="{'empty-field': isClickedSave1 && customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null}" class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              v-model="customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable"
                              class="form-check-input" type="radio"
                              :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                              :value="false">
                          <span :class="{'empty-field': isClickedSave1 && customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null}" class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

                <div :class="{'empty-editor': isClickedSave1 && customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable && isEmpty(customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription) }" class="ml-3 mb-3">
                  <vue-editor
                      v-model="customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription"
                      :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                      :editorToolbar="editorToolbar"></vue-editor>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Require - Acceptance of Order T&c's</span> <br/>
                    <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to tick for acceptance of
                      the agreement and prevents them Ordering if not ticked.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply" type="radio" v-model="customer.settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs"
                                 :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              class="form-check-input" :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply" type="radio" v-model="customer.settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs"
                              :value="false">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Require - Signee to Add Name/Job Position</span> <br/>
                    <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to type their Job
                      position/role in your customers company.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" type="radio"
                                 :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                                 v-model="customer.settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition"
                                 :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              class="form-check-input" type="radio"
                              :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                              :value="false" v-model="customer.settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Require E-Signature</span> <br/>
                    <p class="notifications-info-text">Get your customer to E-Sign the acceptance on their Desktop, Mobile
                      or Tablet device.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 140px;">
                      <div>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" type="radio"
                                 :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                                 v-model="customer.settings.orders.specialOrder.enableRequireESignature"
                                 :value="true">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">Yes</span>
                        </label>
                      </div>
                      <div class="">
                        <label class="form-check-inline radio">
                          <input
                              class="form-check-input" type="radio"
                              :disabled="!customer.settings.orders.specialOrder.enableAcceptanceAgreement || customer.settings.orders.specialOrder.customerToApply"
                              v-model="customer.settings.orders.specialOrder.enableRequireESignature"
                              :value="false">
                          <span class="icon clickable"><i class='bx bx-check'></i></span><span
                            class="text">No</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Send Order Acceptance by</span> <br/>
                    <p class="notifications-info-text">Sends the form for your customer to accept & sign by the following
                      default method.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex justify-content-between" style="width: 220px;">
                      <multiselect
                          :showLabels="false"
                          v-model="customer.settings.orders.specialOrder.sendOrderAcceptanceBy"
                          :options="['Email']"
                          :disabled="true"
                          :option-height="29"
                          :max-height="203"
                          :close-on-select="true"
                      />
                    </div>
                  </div>
                </div>

                <p v-if="((isEmpty(customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription) && customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable) || customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null) && isClickedSave1" style="padding-left: 15px;" class="error-text">Check missing highlighted fields to save</p>

                <div class="d-flex mb-2-7 mt-15 d-flex justify-content-end align-items-center">
                  <button @click="showModal1" class="btn btn-secondary primary-show-example-btn mr-lg-2">Show Me an Example</button>
                  <button @click="onSave" class="btn btn-primary primary-save-btn">Save</button>
                </div>

              </div>
            </tab>
          </tabs>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filterValue="filter"
                :limit-length="50"
                @selectAdditional="redirectToRfqView"
                @onEnter="onEnter"
                :additionalMenu="additionalMenu"
                @input="v => filter = v"
                height="38px"
                aria-expanded="false"
                data-toggle="dropdown"
                class="search-input"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="8"
            />

<!--            <b-form-input-->
<!--                v-model="filter"-->
<!--                type="text"-->
<!--                class="search-input"-->
<!--                placeholder="Search or Filter results"-->
<!--                debounce="500">-->
<!--            </b-form-input>-->
          </div>
          <div class="tabs-nav-right-btn">
            <b-button
                v-if="showSaveButton"
                @click="onClickSave"
                class="btn-submit-save d-none d-lg-block"
                variant="primary">
              Save
            </b-button>
          </div>
        </template>
      </tabs>
    </div>

    <b-modal @hide="onCloseModal" ref="special-order-example" id="special-order-modal" size="custom-special-order-modal" title="Back Order - Part Acceptance Agreement">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned special-supplier-order-modal form-container">
        <form>
          <div class="form-group row pr-3">
            <label class="col col-form-label">{{currentCompany.businessName}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the supply of the following part/s with your order.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Order Number: </p>
              <p class="">220785</p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Invoice Number: </p>
              <p class="">887317P</p>
            </div>
          </div>
          <div class="d-flex pr-3 pl-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">LEXUS</p>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3">
            <div class="col-12 col-md-1" style="padding: 0px">
              <label class="input-checkbox col-form-label font-weight-bold pl-5">
                <input
                    name="part"
                    type="checkbox"
                />
                <div class="checkmark"></div>
              </label>
            </div>
            <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control">
              <b>Part: </b> Front Bumper Bar | MAXU9898911 | QTY 1 | $0,000.00
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-supp-order">
              <vue-editor
                  v-model="customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription"
                  :disabled="true"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>
          </div>
          <div v-if="customer.settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs" class="w-100 text-right row p-3">
            <label class="input-checkbox col-form-label font-weight-bold pl-5">
              <input
                  name="remember"
                  type="checkbox"
                  :checked="true"
                  :disabled="true"
              />
              <div class="checkmark"></div>I have read & accept the suppliers Terms and Conditions
            </label>
          </div>
          <div v-if="customer.settings.orders.specialOrder.enableRequireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature1">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 special-order-signature-block" ref="specialOrderBlock">
                <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad1"
                                   class="special-order-signature-canvas"/>
              </div>
              <a
                  v-if="!isEmptySignature1"
                  class="control-elem-1__item"
                  @click.prevent="clearSignature1"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                <i class="bx bx-trash"></i>
              </a>
            </template>
          </div>
          <template v-if="customer.settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
          </template>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
          <b-button class="input-btn decline">Decline</b-button>
          <b-button class="input-btn">Accept</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal ref="non-returnable-example" id="non-returnable-modal" size="custom-non-returnable-modal" @hide="isOpenNonReturnalbleModal = false"
             title="Non-Returnable - Part Acceptance Agreement">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned non-returnable-supplier-modal form-container">
        <form>
          <div class="form-group row pr-3">
            <label class="col col-form-label">{{currentCompany.businessName}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the
            supply of the following part/s with your order.</span></label>
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label"><span class="font-weight-normal">Select the part/s in the list below to accept.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Quote Number: </p>
              <p class="">220785</p>
            </div>
          </div>
          <div class="d-flex pr-3 pl-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">LEXUS</p>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3">
            <!--            <input-->
            <!--                type="text"-->
            <!--                class="col-12 form-control"-->
            <!--            />-->
            <div class="col-12 col-md-1" style="padding: 0px">
              <label class="input-checkbox col-form-label font-weight-bold pl-5">
                <input
                    name="part"
                    type="checkbox"
                />
                <div class="checkmark"></div>
              </label>
            </div>

            <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control">
              <b>Part: </b> Front Bumper Bar | MAXU9898911 | QTY 1 | $0,000.00
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="customer.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-non-returnable">
              <vue-editor
                  v-model="customer.settings.rfq.partAcceptanceSettings.tics"
                  :disabled="true"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>
            <!--            <div v-html="settings.acceptance" class="col-12 form-control non-returnable-text-message" type="text" disabled></div>-->
          </div>
          <div v-if="customer.settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics" class="w-100 text-right row p-3">
            <label class="input-checkbox col-form-label font-weight-bold pl-5">
              <input
                  name="remember"
                  type="checkbox"
              />
              <div class="checkmark"></div>I have read & accept the suppliers Terms and Conditions
            </label>
          </div>

          <div v-if="customer.settings.rfq.partAcceptanceSettings.requireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 non-returnable-signature-block" ref="nonReturnableSignatureBlock">
                <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad"
                                   class="non-returnable-signature-canvas"/>
              </div>
              <a
                  href="#"
                  class="control-elem-1__item"
                  v-if="!isEmptySignature"
                  @click.prevent="clearSignature"
                  style="width: 40px"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                <i style="top: 9px" class="bx bx-trash"></i>
              </a>
            </template>
          </div>
          <template v-if="customer.settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
          </template>

        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
          <b-button class="input-btn decline">Decline</b-button>
          <b-button class="input-btn">Accept</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import tabTable from '../../../components/tabTable';
import tabPaginatedTable from '../../../components/tabPaginatedTable';
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import Axios from "axios";
import accounting from 'accounting'
import * as dayjs from "dayjs";
import { VPerfectSignature } from 'v-perfect-signature'
import {VueEditor} from 'vue2-editor'
import _ from "lodash";

export default {
  name: 'customer-view',
  components: {
    tabTable,
    tabPaginatedTable,
    Multiselect,
    VPerfectSignature,
    VueEditor,
    SearchFilter
  },
  data() {
    return {
      isClickedSave1: false,
      isOpenNonReturnalbleModal: false,
      isOpenModal: false,
      isClickedSave: false,
      isEmptySignature: true,
      isEmptySignature1: true,
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      editorToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }], [{
          list: "ordered"
        }, {
          list: "bullet"
        },], [{
          indent: "-1"
        }, {
          indent: "+1"
        }], // outdent/indent
        [{
          color: []
        }],
        ["clean"] // remove formatting button
      ],
      acceptanceByOptions: [
        {
          value: 'Email',
          key: 'Email',
        },
        {
          value: 'SMS',
          key: 'SMS',
          $isDisabled: true
        },
      ],
      notificationOfAcceptanceOptions: [
        {
          value: 'Email',
          key: true,
        },
        {
          value: 'Disable',
          key: false,
        },
      ],
      customerTypeOptions: [
        {
          value: 'Auto Body Repairer',
          key: 'Car Repairer',
        },
        {
          value: 'Motorbike Repairer',
          key: 'Bike Repairer',
        },
        {
          value: 'Truck Repairer',
          key: 'Truck Repairer',
        }
      ],
      inputText: "test",
      searchString: "",
      ids: {
        order: [],
        rfq: [],
        credit: []
      },
      rfqs: [],
      credits: [],
      orders: [],
      file: null,
      customerId: 0,
      isEditable: false,
      searchFilter: '',
      activeTab: '',
      customer: {
        settings: {
          orders: {
            specialOrder: {
              customerToApply: false,
              displayOrderAcceptanceTaCsDescription: null,
              displayOrderAcceptanceTaCsEnable: false,
              enableAcceptanceAgreement: false,
              enableRequireAcceptanceOfOrderTaCs: false,
              enableRequireESignature: false,
              enableRequireSigneeToAddNameOrJobPosition: false,
              sendOrderAcceptanceBy: 'Email'
            },
          },
          credits: {
            policies: {
              applyAllCustomers: false,
              creditReturnPeriod: null,
              creditReturnPeriodNotice: null,
              enableCreditProcessingPolicy: false,
              requirePhotosEveryCreditRequest: false,
              reasonForReturnToDisplayPhoto: [],
            }
          },
          rfq: {
           partAcceptanceSettings: {
             "nonReturnableAcceptanceAgreement": false,
             "applyToAllCustomers": false,
             "displayPartAcceptanceTics": false,
             "tics": '',
             "requireAcceptanceOfOrderTics": false,
             "requireSigneeToAddNameOrJobPosition": false,
             "requireESignature": false,
             "notificationOfAcceptance": false,
             "sendOrderAcceptanceBy": ""
           }
          }
        }
      },
      supplier: {},
      selectedUser: {},
      ordersAllFields: [
        {
          label: "Repairer Order Nbr",
          key: "number",
          class: "font-inter font-inter--bold",
          tdClass: "font-12 order",
          thClass: "font-13 table-header"
        },
        {
          label: "Invoice Nbr",
          key: "invoiceNumber",
          class: "font-inter font-inter--bold",
          tdClass: "font-12 order",
          thClass: "font-13 table-header"
        },
        {
          label: "Order Total",
          key: "cost",
          class: "font-inter font-inter--bold",
          tdClass: "font-12 gross clickable",
          thClass: "font-13 table-header",
          formatter: (value => {
            return accounting.formatMoney(value)
          })
        },
        {
          label: "Order Fill(%)",
          key: "partsFill",
          class: "font-inter font-inter--bold",
          tdClass: "font-12 order-fill clickable",
          thClass: "font-13 table-header",
          formatter: (value => {
            return value + '%'
          })
        },
        {
          label: "Order Date",
          key: "dateOrdered",
          class: "font-inter",
          tdClass: "font-inter--semibold font-12 order-placement clickable",
          thClass: "font-inter--bold font-13 table-header",
          formatter: (value => {
            return value ? dayjs(value).format('DD/MM/YYYY') : '';
          })
        },
        {
          label: "Delivery Date",
          key: "dateDue",
          class: "font-inter",
          tdClass: "font-inter--semibold font-12 delivery-date clickable",
          thClass: "font-inter--bold font-13 table-header",
          formatter: (value => {
            return value ? dayjs(value).format('DD/MM/YYYY') : '';
          })
        },
        {
          label: "Delivery Location",
          key: "location",
          class: "font-inter",
          tdClass: "font-inter--semibold font-12 reward-points clickable",
          thClass: "font-inter--bold font-13 table-header",
        },
        {
          label: "",
          key: "actions",
          tdClass: "actions clickable text-right",
          thClass: "table-header"
        }
      ],
      activeRequestAllFields: [
        {
          label: "RFQ Number",
          key: "number",
          tdClass: "table-col table-col--bold rfq-number",
          thClass: "table-header"
        },
        {
          label: "Part Qty",
          key: "qty",
          tdClass: "table-col table-col--bold part-qty clickable",
          thClass: "table-header"
        },
        {
          label: "VIN",
          key: "vin",
          tdClass: "table-col table-col--semibold vin clickable",
          thClass: "table-header"
        },
        {
          label: "Estimator",
          key: "estimator",
          tdClass: "table-col table-col--semibold estimator clickable",
          thClass: "table-header"
        },
        {
          label: "Submission Cut Off",
          key: "dateDue",
          tdClass: "table-col table-col--semibold submission-cut-off clickable",
          thClass: "table-header",
          formatter: (value => {
            return value ? dayjs(value).format('DD/MM/YYYY - hh:mmA') : '';
          })
        },
        {
          label: "Value",
          key: "orderPrice",
          tdClass: "table-col table-col--semibold value clickable",
          thClass: "table-header"
        },
        {
          label: "",
          key: "actions",
          tdClass: "table-col table-col--semibold actions clickable text-right",
          thClass: "table-header"
        }
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true
      },
      filter: '',
      perPage: {
        rfq: 10
      },
      currentPage: {
        rfq: 1
      },
      rfqFields: [
        {
          label: "RFQ Number",
          key: "number",
          sortable: true,
          tdClass: 'font-12 clickable rfq-number',
          thClass: 'font-13',
          class: 'font-inter font-inter--bold'
        },
        {
          label: "Part Qty",
          key: "qty",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "VIN",
          key: "vin",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "Estimator",
          key: "estimator",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "Submission Cut-Off",
          key: "dateDue",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter',
          formatter: (value => {
            return value ? dayjs(value).format('DD/MM/YYYY - hh:mmA') : '';
          })
        },
        {
          label: "Value",
          key: "orderPrice",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        }
      ],
      orderFields: [
        {
          label: "Order Number",
          key: "number",
          sortable: true,
          tdClass: ' font-12 clickable order-number',
          thClass: 'font-13',
          class: 'font-inter font-inter--bold'
        },
        {
          label: "Part Qty",
          key: "partsQty",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter"
        },
        {
          label: "VIN",
          key: "vin",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter"
        },
        {
          label: "Estimator",
          key: "estimator",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter"
        },
        {
          label: "Parts Interpreter",
          key: "interpreter",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter"
        },
        {
          label: "Submission Cut-Off",
          key: "dateDue",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter",
          formatter: (value => {
            return value ? dayjs(value).format('DD/MM/YYYY - hh:mmA') : '';
          })
        },
        {
          label: "Value",
          key: "costStr",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter"
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold',
          class: "font-inter"
        }
      ],
      creditFields: [
        {
          label: "Credit Number",
          key: "number",
          sortable: true,
          tdClass: 'font-12 clickable credit-number',
          thClass: 'font-13',
          class: 'font-inter font-inter--bold'
        },
        {
          label: "Order Nbr",
          key: "orderNumber",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        },
        {
          label: "VIN",
          key: "vin",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        },
        {
          label: "Estimator",
          key: "estimator",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        },
        {
          label: "Parts Interpreter",
          key: "interpreter",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        },
        {
          label: "Part Qty",
          key: "totalQty",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        },
        {
          label: "Value",
          key: "amount",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13',
          class: 'font-inter'
        }
      ],
      detailsFormsLeft: {
        repairerAccount: {
          id: "repairer-account",
          title: "Repairer Account",
          fields: [
            {
              id: "repairer-name",
              type: "text",
              label: "Repair Name",
              value: "",
              placeholder: "Name",
              class: ""
            },
            {
              id: "repairer-account-nbr",
              type: "text",
              label: "Repair Account Nbr",
              value: "",
              placeholder: "Account Number",
              class: ""
            },
            {
              id: "repairer-type",
              type: "multiSelect",
              label: "Repairer Type",
              value: "",
              placeholder: "Type",
              options: [],
              class: ""
            },
            {
              id: "primary-contact-person",
              type: "tel",
              label: "Primary Contact Person",
              value: "",
              placeholder: "Contact Person Number",
              class: ""
            },
            {
              id: "contact-phone",
              type: "tel",
              label: "Contact Phone",
              value: "",
              placeholder: "Phone Number",
              class: ""
            },
            {
              id: "contact-email",
              type: "email",
              label: "Contact Email",
              value: "",
              placeholder: "Email",
              class: ""
            },
            {
              id: "website",
              type: "url",
              label: "Website",
              value: "",
              placeholder: "Website",
              class: ""
            }
          ]
        },
        businessAddress: {
          id: "business-address",
          title: "Business Address",
          fields: [
            {
              id: "businesses-addresses",
              type: "text",
              label: "Business Address",
              value: "",
              placeholder: "Business Address",
              class: ""
            },
            {
              id: "level-unit-lot-nbr",
              type: "text",
              label: "Level/Unit/Lot Nbr",
              value: "",
              placeholder: "Level/Unit/Lot Nbr",
              class: ""
            },
            {
              id: "street-nbr-name",
              type: "text",
              label: "Street Nbr/Name",
              value: "",
              placeholder: "Street Nbr or Name",
              class: ""
            },
            {
              id: "suburb",
              type: "text",
              label: "Suburb",
              value: "",
              placeholder: "Suburb",
              class: ""
            },
            {
              id: "state",
              type: "multiSelect",
              label: "State",
              value: null,
              options: [],
              placeholder: "Select state",
              class: ""
            },
            {
              id: "postcode",
              type: "text",
              label: "Postcode",
              value: "",
              placeholder: "Postcode",
              class: "col-3"
            },
            {
              id: "country",
              type: "multiSelect",
              label: "Country",
              value: null,
              options: [],
              placeholder: "Select your country",
              class: ""
            }
          ]
        },
        deliveryLocation: {
          id: "delivery-location",
          title: "Delivery Location",
          fields: [
            {
              id: "same-as-above",
              type: "radio",
              label: "Same As Above",
              value: "no",
              options: [
                {text: "Yes", value: "yes"},
                {text: "No", value: "no"},
              ],
              placeholder: "",
              class: ""
            },
            {
              id: "location-name",
              type: "text",
              label: "Location Name",
              value: "",
              placeholder: "location name",
              class: ""
            },
            {
              id: "business-address",
              type: "text",
              label: "Business Address",
              value: "",
              placeholder: "Address",
              class: ""
            },
            {
              id: "unit-level",
              type: "text",
              label: "Unit/Level",
              value: "",
              placeholder: "Unit/Level",
              class: ""
            },
            {
              id: "street-name",
              type: "text",
              label: "Street Name",
              value: "",
              placeholder: "Street",
              class: ""
            },
            {
              id: "suburb",
              type: "text",
              label: "Suburb",
              value: "",
              placeholder: "Suburb",
              class: ""
            },
            {
              id: "state",
              type: "multiSelect",
              label: "State",
              value: null,
              options: [],
              placeholder: "Select state",
              class: ""
            },
            {
              id: "postcode",
              type: "text",
              label: "Postcode",
              value: "",
              placeholder: "Postcode",
              class: "col-3"
            },
            {
              id: "country",
              type: "multiSelect",
              label: "Country",
              value: null,
              options: [],
              placeholder: "Select your country",
              class: ""
            }
          ]
        }
      },
      detailsFormsRight: {
        financiaDetails: {
          id: "financial-details",
          title: "Financial Details",
          subtitle: "(All defaults can be overriden on individual transaction)",
          fields: [
            {
              id: "tax",
              type: "text",
              label: "Tax",
              value: "",
              placeholder: "Australian Business Number",
              class: ""
            },
            {
              id: "acn",
              type: "text",
              label: "ACN",
              value: "",
              placeholder: "Australian Business Number",
              class: ""
            },
            {
              id: "sales-discount",
              type: "text",
              label: "Sales Discount %",
              value: "",
              placeholder: "0%",
              class: "col-1"
            },
            {
              id: "currency",
              type: "text",
              label: "Currency",
              value: "",
              placeholder: "Default Currency - AUD",
              class: ""
            },
            {
              id: "approved-for-credit",
              type: "radio",
              label: "Approved for Credit",
              value: "yes",
              options: [
                {text: "Yes", value: "yes"},
                {text: "No", value: "no"},
              ],
              placeholder: "",
              class: ""
            },
            {
              id: "credit-limit-account",
              type: "text",
              label: "Credit Limit Account",
              value: "",
              placeholder: "$000,000.00",
              class: "col-3"
            },
          ]
        },
        rewardsProgram: {
          id: "rewards-program",
          title: "Rewards Program"
        },
      }
    };
  },
  computed: {
    ...mapGetters({
      addressStates: "addressStates",
      addressCountries: "addressCountries",
      allOrders: "supplier/order/getAllOrders",
      allRfqs: "supplier/rfq/getAllRfqs",
      allCredits: "supplier/credit/getAllCredits",
      currentCompany: 'currentCompany/getDetails',
    }),
    additionalMenu() {
      if (!(this.isCurrentTabAccountSummary || this.isCurrentTabOrders || this.isCurrentTabRfq)) {
        return
      }
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let v = this.isCurrentTabAccountSummary || this.isCurrentTabOrders ? this.allOrders : this.allRfqs
      let data = _.filter(v, (r) => {
        if (_.includes(this.searchFilter, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    computedSendOrderAcceptanceBy: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.acceptanceByOptions, (itm) => {
            return itm.key === vm.customer.settings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        this.customer.settings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy = item?.key;
      },
    },
    computedNotificationOfAcceptance: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.notificationOfAcceptanceOptions, (itm) => {
            return itm.key === vm.customer.settings.rfq.partAcceptanceSettings.notificationOfAcceptance
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        this.customer.settings.rfq.partAcceptanceSettings.notificationOfAcceptance = item?.key;
      },
    },
    computedCustomerType: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.customerTypeOptions, (itm) => {
            return itm.key === vm.customer.type
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        this.customer.type = item.key;
      },
    },
    customerIsActive() {
      return this.customer.isActive;
    },
    url() {
      // mockup purpose logo url
      return "http://gitlab.naevette.com.au/partssearch/businessapp-partssearch-app/uploads/73d1dbce1a07de2195039ce17a3484c3/aaa_smash_repair.png";

      // uncomment for dragdrop like designed
      // if (!this.isEditable) {
      //     return "http://gitlab.naevette.com.au/partssearch/businessapp-partssearch-app/uploads/73d1dbce1a07de2195039ce17a3484c3/aaa_smash_repair.png";
      // } else {
      //     if (this.file) {
      //         return URL.createObjectURL(this.file);
      //     }
      //     return false;
      // }
    },
    // mimic get from store
    currentRfqs() {
      let result = [];
      _.forEach(this.rfqs, item => {
        result.push(item);
        if (_.size(result) === 5) return false;
      });
      return result;
    },
    currentOrders() {
      let result = [];
      _.forEach(this.orders, item => {
        result.push(item);
        if (_.size(result) === 5) return false;
      });
      return result;
    },
    showSaveButton() {
      return this.isCurrentTabAccountSummary || this.isCurrentTabDetails;
    },
    isCurrentTabAccountSummary() {
      return this.activeTab === 'Account Summary';
    },
    isCurrentTabRfq() {
      return this.activeTab === 'RFQ';
    },
    isCurrentTabOrders() {
      return this.activeTab === 'Orders';
    },
    isCurrentTabCredits() {
      return this.activeTab === 'Credits';
    },
    isCurrentTabDetails() {
      return this.activeTab === 'Details';
    }
  },
  mounted() {
    // default to !isEditable presentation
    this.customerId = this.$route.params.customer_id;
    this.getCustomer(this.customerId);
    this.getCustomerRFQs(this.customerId);
    this.getCustomerOrders(this.customerId);
    this.getCustomerCredits(this.customerId);
    this.isEditable = false;
    this.setMultiselectOptions(this.detailsFormsLeft.businessAddress, 'state', this.addressStates);
    this.setMultiselectOptions(this.detailsFormsLeft.businessAddress, 'country', this.addressCountries);
    this.setMultiselectOptions(this.detailsFormsLeft.deliveryLocation, 'state', this.addressStates);
    this.setMultiselectOptions(this.detailsFormsLeft.deliveryLocation, 'country', this.addressCountries);

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    redirectToRfqView(itm) {
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter)) {
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    handleResize() {
      this.handleResize1()
      this.handleResize2()
    },
    handleResize1() {
      if (!this.isOpenNonReturnalbleModal) {
        return
      }
      setTimeout(() => {
        let signatureCanvas = document.getElementsByClassName('non-returnable-signature-canvas')
        if (signatureCanvas && signatureCanvas[0]) {
          let width = this.$refs.nonReturnableSignatureBlock.offsetWidth;
          signatureCanvas[0].width = width
          signatureCanvas[0].style.width = width + 'px'
          signatureCanvas[0].height = 100
          signatureCanvas[0].style.height = '100px'
        }
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.inputPointsHandler()
        }
      }, 100)
    },
    handleResize2() {
      if (!this.isOpenModal) {
        return
      }
      setTimeout(() => {
        let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
        if (signatureCanvas && signatureCanvas[0]) {
          let width = this.$refs.specialOrderBlock.offsetWidth;
          signatureCanvas[0].width = width
          signatureCanvas[0].style.width = width + 'px'
          signatureCanvas[0].height = 100
          signatureCanvas[0].style.height = '100px'
        }
        if (this.$refs.signaturePad1) {
          this.$refs.signaturePad1.inputPointsHandler()
        }
      }, 100)
    },
    checkSignature1 () {
      if (this?.$refs?.signaturePad) {
        this.isEmptySignature1 = this.$refs.signaturePad1.isEmpty()
      }
    },
    checkSignature () {
      if (this?.$refs?.signaturePad) {
        this.isEmptySignature = this.$refs.signaturePad.isEmpty()
      }
    },
    onSaveNonReturnable() {
      this.isClickedSave = true;
      if (_.isEmpty(this.customer.settings.rfq.partAcceptanceSettings.tics) && this.customer.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics) {
        return
      }
      this.onClickSave();
    },
    onSave () {
      this.isClickedSave1 = true;
      if ((_.isEmpty(this.customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription) && this.customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable) || this.customer.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null) {
        return
      }
      this.onClickSave();
    },
    clearSignature () {
      let signaturePad = this.$refs.signaturePad
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    clearSignature1 () {
      let signaturePad = this.$refs.signaturePad1
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    isEmpty(val) {
      return _.isEmpty(val)
    },

    showModal () {
      this.$refs['non-returnable-example'].show();
      this.isOpenNonReturnalbleModal = true;
      this.$nextTick(() => {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('non-returnable-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.nonReturnableSignatureBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
        }, 100)
      })
    },
    showModal1 () {
      this.$refs['special-order-example'].show();
      this.isOpenModal = true;
      this.$nextTick(() => {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.specialOrderBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
        })
      })
    },
    onCloseModal () {
      this.isOpenModal = false;
    },
    onClickSave() {
      Axios.post('/ir/supplier/customer/' + this.customerId, {
        customerId: this.customerId,
        type: this.customer.type,
        accountNumber: this.customer.number,
        settings: this.customer.settings,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$toast.success('Customer details updated successfully')
            } else {
              this.$toast.error('Error')
            }
          })
          .catch((e) => {
            this.$toast.error('Error')
            console.log(e)
          })
    },
    setRepairerAccount() {
      this.detailsFormsLeft.repairerAccount.fields[0].value = this.customer.name;
      this.detailsFormsLeft.repairerAccount.fields[1].value = this.customer.number;
      this.detailsFormsLeft.repairerAccount.fields[2].value = {value: this.customer.type}
      if (!_.isEmpty(this.customer.contacts) && !_.isEmpty(this.customer.contacts[0].phone))
        this.detailsFormsLeft.repairerAccount.fields[3].value = this.customer.contacts[0].phone;
      this.detailsFormsLeft.repairerAccount.fields[4].value = this.customer.phone;
      this.detailsFormsLeft.repairerAccount.fields[5].value = this.customer.email;
    },
    setBusinessAddress() {
      this.detailsFormsLeft.businessAddress.fields[0].value = this.customer.address;
      this.detailsFormsLeft.businessAddress.fields[1].value = this.customer.unit_level_lot;
      this.detailsFormsLeft.businessAddress.fields[2].value = this.customer.street;
      this.detailsFormsLeft.businessAddress.fields[3].value = this.customer.suburb;
      this.detailsFormsLeft.businessAddress.fields[4].value = _.find(this.addressStates, state => state.key === this.customer.state);
      this.detailsFormsLeft.businessAddress.fields[5].value = this.customer.postcode;
      this.detailsFormsLeft.businessAddress.fields[6].value = _.find(this.addressCountries, country => country.key === this.customer.country);
    },
    setDeliveryLocation() {
      if (!_.isEmpty(this.customer.locations)) {
        let location = _.first(this.customer.locations);
        this.detailsFormsLeft.deliveryLocation.fields[1].value = location.warehouseName;
        this.detailsFormsLeft.deliveryLocation.fields[2].value = location.address;
        if (!_.isEmpty(location.unitLevel)) this.detailsFormsLeft.deliveryLocation.fields[3].value = location.unitLevel;
        if (!_.isEmpty(location.street)) this.detailsFormsLeft.deliveryLocation.fields[4].value = location.street;
        this.detailsFormsLeft.deliveryLocation.fields[5].value = location.suburb;
        this.detailsFormsLeft.deliveryLocation.fields[6].value = _.find(this.addressStates, state => state.key === location.state);
        this.detailsFormsLeft.deliveryLocation.fields[7].value = location.postcode;
        if (!_.isEmpty(location.country))
          this.detailsFormsLeft.deliveryLocation.fields[8].value = _.find(this.addressCountries, country => country.key === location.country);
      }
    },
    getCustomer(id) {
      Axios.get('ir/supplier/customer/' + id)
          .then(response => {
            this.customer = response.data.customer;
            this.customer.settings = {
              credits: {
                policies: {
                  applyAllCustomers: response?.data?.customer?.settings?.credits?.policies?.applyAllCustomers ? response.data.customer.settings.credits.policies.applyAllCustomers : false,
                  creditReturnPeriod: response?.data?.customer?.settings?.credits?.policies?.creditReturnPeriod ? response.data.customer.settings.credits.policies.creditReturnPeriod : null,
                  creditReturnPeriodNotice: response?.data?.customer?.settings?.credits?.policies?.creditReturnPeriodNotice ? response.data.customer.settings.credits.policies.creditReturnPeriodNotice : null,
                  enableCreditProcessingPolicy: response?.data?.customer?.settings?.credits?.policies?.enableCreditProcessingPolicy ? response.data.customer.settings.credits.policies.enableCreditProcessingPolicy : false,
                  requirePhotosEveryCreditRequest: response?.data?.customer?.settings?.credits?.policies?.requirePhotosEveryCreditRequest ? response.data.customer.settings.credits.policies.requirePhotosEveryCreditRequest : false,
                  reasonForReturnToDisplayPhoto: response?.data?.customer?.settings?.credits?.policies?.reasonForReturnToDisplayPhoto ? response.data.customer.settings.credits.policies.reasonForReturnToDisplayPhoto : [],
                },
              },
              rfq: {
                partAcceptanceSettings: {
                  "nonReturnableAcceptanceAgreement": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.nonReturnableAcceptanceAgreement ? true : false,
                  "applyToAllCustomers": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.applyToAllCustomers ? true : false,
                  "displayPartAcceptanceTics": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.displayPartAcceptanceTics ? true : false,
                  "tics": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.tics ? response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.tics : false,
                  "requireAcceptanceOfOrderTics": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.requireAcceptanceOfOrderTics ? true : false,
                  "requireSigneeToAddNameOrJobPosition": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.requireSigneeToAddNameOrJobPosition ? true : false,
                  "requireESignature": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.requireESignature ? true : false,
                  "notificationOfAcceptance": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.notificationOfAcceptance ? true : false,
                  "sendOrderAcceptanceBy": response?.data?.customer?.settings?.rfq?.partAcceptanceSettings?.sendOrderAcceptanceBy ? response.data.customer.settings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy  : 'Email',
                }
              },

              orders: {
                specialOrder: {
                  customerToApply: response?.data?.customer?.settings?.orders?.specialOrder?.customerToApply ? true : false,
                  displayOrderAcceptanceTaCsDescription: response?.data?.customer?.settings?.orders?.specialOrder?.displayOrderAcceptanceTaCsDescription ? response?.data?.customer?.settings?.orders?.specialOrder?.displayOrderAcceptanceTaCsDescription : false,
                  displayOrderAcceptanceTaCsEnable: response?.data?.customer?.settings?.orders?.specialOrder?.displayOrderAcceptanceTaCsEnable ? true : false,
                  enableAcceptanceAgreement: response?.data?.customer?.settings?.orders?.specialOrder?.enableAcceptanceAgreement ? true : false,
                  enableRequireAcceptanceOfOrderTaCs: response?.data?.customer?.settings?.orders?.specialOrder?.enableRequireAcceptanceOfOrderTaCs ? true : false,
                  enableRequireESignature: response?.data?.customer?.settings?.orders?.specialOrder?.enableRequireESignature ? true : false,
                  enableRequireSigneeToAddNameOrJobPosition: response?.data?.customer?.settings?.orders?.specialOrder?.enableRequireSigneeToAddNameOrJobPosition ? true : false,
                  sendOrderAcceptanceBy: 'Email'
                },
              }
            }
            this.setRepairerAccount();
            this.setBusinessAddress();
            this.setDeliveryLocation();
            console.log('getCustomer', response);
          })
          .catch(error => {
            console.log(error);
          });
    },
    getCustomerRFQs(id) {
      Axios.get('ir/supplier/customer/' + id + "/rfqs")
          .then(response => {
            console.log('getCustomerRFQs', response.data);
            if (response.data.status === true) {
              this.ids.rfq = response.data.ids;
              this.loadCustomerRfqs();
            }

          })
          .catch(error => {
            console.log(error);
          });
    },
    getCustomerOrders(id) {
      Axios.get('ir/supplier/customer/' + id + "/orders")
          .then(response => {
            console.log('getCustomerOrders', response.data);
            if (response.data.status === true) {
              this.ids.order = response.data.ids;
              this.loadCustomerOrders();
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    getCustomerCredits(id) {
      Axios.get('ir/supplier/customer/' + id + "/credits")
          .then(response => {
            console.log('getCustomerCredits', response.data);
            if (response.data.status === true) {
              this.ids.credit = response.data.ids;
              this.loadCustomerCredits();
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    loadCustomerOrders() {
      this.orders = _.filter(this.allOrders, item => {
        return _.indexOf(this.ids.order, Number(item.id)) >= 0;
      });
    },
    loadCustomerRfqs() {
      this.rfqs = _.filter(this.allRfqs, item => {
        return _.indexOf(this.ids.rfq, Number(item.id)) >= 0;
      });
    },
    loadCustomerCredits() {
      this.credits = _.filter(this.allCredits, item => {
        return _.indexOf(this.ids.credit, Number(item.id)) >= 0;
      });
    },
    setMultiselectOptions(form = {}, name = '', options = []) {
      let stateIndex = _.findIndex(form.fields, field => {
        return field.id === name;
      });
      form.fields[stateIndex].options = options;
    },
    handleFileAdded(file) {
      this.file = file;
    },
    handleEditLogo() {
      this.file = null;
    },
    onOrderRowClicked(row) {
      console.log('order clicked:', row)
    },
    onActiveRequestRowClicked(row) {
      console.log('active request clicked:', row)
    },
    onOrderViewMoreClicked() {
      console.log('view more orders');
      window.location.href = '#suppliers-customer-view-orders';
    },
    onActiveRequestViewMoreClicked() {
      console.log('view more active RFQ');
      window.location.href = '#suppliers-customer-view-rfq';
    },
    onRfqRowClicked(row) {
      this.$router.push({
        name: 'SupplierRFQView',
        params: {rfq_id: row.id, tab: 'All', filters: {customer: this.customer.name, rfqNumber: row.number}}
      })
      console.log('[paginated-table] RFQ row clicked:', row);
    },
    onOrderDetailRowClicked(row) {
      this.$router.push({
        name: 'SupplierPartsOrderView',
        params: {orderId: row.id, tab: 'All', filters: {customer: this.customer.name, orderNumber: row.number}}
      })
      console.log('[paginated-table] Order row clicked:', row);
    },
    onCreditDetailRowClicked(row) {
      this.$router.push({
        name: 'SuppliersCreditView',
        params: {creditId: row.id, tab: 'All', filters: {customer: this.customer.name, creditNumber: row.number}}
      })
      console.log('[paginated-table] Credit row clicked:', row);
    },
    changedTab(obj) {
      console.log(obj);
      window.location.href = '#' + obj.tab.id;
      this.activeTab = obj.tab.name;
    }
  },
  watch: {
    allOrders: {
      handler: function () {
        this.loadCustomerOrders();
      },
      deep: true
    },
    allRfqs: {
      handler: function () {
        this.loadCustomerRfqs();
      },
      deep: true
    }
  }
};
</script>

<style>
.theme-supplier .suppliers-customer-view-page .empty-editor .ql-editor {
  background-color: rgb(255,201,201) !important;
}

.theme-supplier .suppliers-customer-view-page .ql-toolbar.ql-snow {
  border-radius: 3px 3px 0 0;
}

.theme-supplier .suppliers-customer-view-page .ql-container.ql-snow {
  border-radius: 0 0 3px 3px;
}

.theme-supplier .suppliers-customer-view-page .empty-field {
  background-color: rgb(255,201,201) !important;
}

.supplier-customer-view-tabs .nav-tabs.nav-tabs-2 {
  padding-left: 31px;
}


.supplier-customer-view-tabs .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #29BBC1;
}

.supplier-customer-view-tabs .custom-switch {
  margin-top: 0px;
  padding-left: 3.25rem;
}

.supplier-customer-view-tabs .disabled-switch-checkbox .custom-control-label {
  cursor: default;
}
.supplier-customer-view-tabs .disabled-switch-checkbox .custom-control-label:hover {
  cursor: default;
}

.supplier-customer-view-tabs
.custom-switch
.custom-control-input:checked
~ .custom-control-label::after {
  height: 19px;
  width: 19px;
  border-radius: 11.5px;
  background-color: #ffffff;
  transform: translateX(1.25rem);
}

.supplier-customer-view-tabs .custom-switch .custom-control-label::after {
  height: 19px;
  width: 19px;
  border-radius: 11.5px;
  background-color: #ffffff;
}

.supplier-customer-view-tabs .custom-switch .custom-control-label::before {
  width: 39px;
  height: 23px;
  border-radius: 11.5px;
  border-color: lightgray;
  background-color: lightgray;
}
</style>

<style scoped>
.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon i,
.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon,
.theme-supplier label.radio>input[type="radio"]:disabled+span.icon i,
.theme-supplier label.radio>input[type="radio"]:disabled+span.icon {
  border-color: lightgray !important;
  color: lightgray;
  cursor: not-allowed;
}

.theme-supplier label.checkbox > input[type="checkbox"]:disabled + span.icon {
  border-color: lightgray !important;
  cursor: not-allowed;
}

label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
  color: lightgray !important;
  cursor: not-allowed;
}

.primary-show-example-btn {
  border: 2px solid #29BBC1;
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
}

label.checkbox>input[type="checkbox"]:checked+span.icon {
  border: 2px solid #29BBC1 !important;
  background-color: rgba(27, 30, 56, 0.03) !important;
}

label.checkbox>input[type="checkbox"]:checked+span.icon i {
  transform: translate(-2px, -2px);
  color: #29BBC1;
}

label.checkbox>input[type="checkbox"]+span.icon {
  border: 2px solid #29BBC1 !important;
  background-color: rgba(27, 30, 56, 0.03) !important;
}

label.checkbox>span.text {
  font-size: 12px;
}
</style>

<style scoped>
.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon i,
.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon,
.theme-supplier label.radio>input[type="radio"]:disabled+span.icon i,
.theme-supplier label.radio>input[type="radio"]:disabled+span.icon {
  border-color: lightgray !important;
  color: lightgray;
  cursor: not-allowed;
}

.theme-supplier label.checkbox > input[type="checkbox"]:disabled + span.icon {
  border-color: lightgray !important;
  cursor: not-allowed;
}

label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
  color: lightgray !important;
  cursor: not-allowed;
}

@media screen and (max-width: 991px) {
  .apply-to-all-customers-checkboxes {
    justify-content: flex-start !important;
  }
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.settings-active-toggle-button {
  padding-right: 26px !important;
}

.notifications-info-title {
  font-size: 13px;
}

.notifications-info-text {
  color: rgba(0, 0, 0, .5);
  font-weight: normal;
  margin-top: 6px;
}

.notifications-form-checkbox {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 991px) {
  .notifications-form-checkbox {
    justify-content: flex-start;
    padding-top: 0 !important;
    padding-bottom: 20px;
  }
}

.form-check-inline.radio {
  width: auto;
}
</style>

<style>
.disabled-switch-checkbox .custom-control-label:before,
.disabled-switch-checkbox .custom-control-label:after {
  cursor: not-allowed;
}

@media (max-width: 1773px) {
  .suppliers-customer-view-page .customer-type-row {
    flex-direction: column;
    width: 200%;
  }
}

@media (max-width: 1117px) {
  .suppliers-customer-view-page .customer-type-multiselect .multiselect__tags {
    height: 67px;
  }

  .suppliers-customer-view-page .customer-type-multiselect .multiselect__select {
    top: 23px !important;
  }
}

@media (max-width: 767px) {
  .suppliers-customer-view-page .customer-type-row {
    flex-direction: column;
    width: 100%;
  }

  .suppliers-customer-view-page .customer-type-multiselect .multiselect__tags {
    height: 32px;
  }

  .suppliers-customer-view-page .customer-type-multiselect .multiselect__select {
    top: 4px !important;
  }
}


</style>

<style scoped>
.account-number-field {
  padding-top: 8px;
}

@media (max-width: 1250px) {
  .account-number-field {
    padding-top: 0px;
  }
}

</style>

<!--<style lang="scss">-->
<!--    @import "../../../styles/style-views";-->
<!--</style>-->
