/*eslint-disable */
export function isLogoutAction (state) {state.isLogout}

export function isLoggedIn (state) {
  return (Boolean(state.token && state.token !== '' && state.token !== 'undefined' && state.token !== undefined && state.token.length > 1))
}

export function authStatus (state) {
  return state.status
}

export function getAuthToken (state) {
  return state.token
}
