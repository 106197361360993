/*eslint-disable */
import _ from 'lodash';
import {
    SUPPLIER_CREDIT_IDS_UPDATE_APPROVED,
    SUPPLIER_CREDIT_IDS_UPDATE_DENIED,
    SUPPLIER_CREDIT_IDS_UPDATE_UNDER_REVIEW,
    SUPPLIER_CREDIT_UPDATED,
    SUPPLIER_CREDIT_ALL_CURRENT_PAGE,
    SUPPLIER_CREDIT_UNDER_CURRENT_PAGE,
    SUPPLIER_CREDIT_APPROVED_CURRENT_PAGE,
    SUPPLIER_CREDIT_DENIED_CURRENT_PAGE,
    SUPPLIER_CREDIT_PER_PAGE,
    SUPPLIER_CREDIT_FILTER,
    SUPPLIER_CREDIT_SET_TOKEN,
    SUPPLIER_CREDIT_SET_TOKENS,
    SUPPLIER_CREDIT_SET_SEARCH_FILTER_OBJ,
    SUPPLIER_CREDIT_CANCELLED_CURRENT_PAGE, SUPPLIER_CREDIT_REINSTALLATION, SUPPLIER_CREDIT_SHORT_UPDATING,
} from '../../../mutation-types';

export default {
    [SUPPLIER_CREDIT_REINSTALLATION](state) {
        state.items = [];
        state.perPage = 100;
        state.creditsFromUpdate = [];
        state.filter = '';
        state.token = [];
        state.tokens = {
            0: [],
            1: [],
            2: [],
            3: [],
        };
        state.searchFilterObj = {
            search: '',
        };
        state.creditAllCurrentPage = 1;
        state.creditDeniedCurrentPage = 1;
        state.creditApprovedCurrentPage = 1;
        state.creditUnderCurrentPage = 1;
        state.creditCancelledCurrentPage = 1;
        state.lastTimeCheck = {
            items: 0,
            UnderReview: 0,
            Approved: 0,
        }
    },
    [SUPPLIER_CREDIT_SET_TOKEN](state, ui) {
        state.token.splice(0, state.token.length);
        _.forEach(ui, itm => {
            state.token.push(itm)
        })
    },
    [SUPPLIER_CREDIT_SET_TOKENS](state, ui) {
        for (let key in state.tokens) {
            delete state.tokens.key
        }
        for (let key in ui) {
            state.tokens[key] = ui[key].slice();
        }
    },
    [SUPPLIER_CREDIT_SET_SEARCH_FILTER_OBJ](state, ui) {
        state.searchFilterObj = ui;
    },
    // [SUPPLIER_CREDIT_IDS_UPDATE_UNDER_REVIEW](state, ui) {
    //     state.UnderReview = _.union(state.UnderReview, ui);
    //     state.Approved    = _.pullAll(state.Approved, ui);
    //     state.Denied      = _.pullAll(state.Denied, ui);
    // },
    // [SUPPLIER_CREDIT_IDS_UPDATE_APPROVED](state, ui) {
    //     state.Approved = _.union(state.Approved, ui);
    //     state.UnderReview = _.pullAll(state.UnderReview, ui);
    //     state.Denied      = _.pullAll(state.Denied, ui);
    // },
    // [SUPPLIER_CREDIT_IDS_UPDATE_DENIED](state, ui) {
    //     state.Denied = _.union(state.Denied, ui);
    //     state.UnderReview = _.pullAll(state.UnderReview, ui);
    //     state.Approved    = _.pullAll(state.Approved, ui);
    // },
    [SUPPLIER_CREDIT_UPDATED](state, ui) {
        state.items = _.unionBy(ui, state.items, 'id');
        if (!_.isEmpty(ui)) {
            state.creditsFromUpdate = ui;
        }
    },
    [SUPPLIER_CREDIT_SHORT_UPDATING](state, ui) {
        state.items = _.unionBy(ui, [], 'id');
    },

    [SUPPLIER_CREDIT_ALL_CURRENT_PAGE](state, ui) {
        state.creditAllCurrentPage = ui;
    },

    [SUPPLIER_CREDIT_UNDER_CURRENT_PAGE](state, ui) {
        state.creditUnderCurrentPage = ui;
    },

    [SUPPLIER_CREDIT_APPROVED_CURRENT_PAGE](state, ui) {
        state.creditApprovedCurrentPage = ui;
    },

    [SUPPLIER_CREDIT_DENIED_CURRENT_PAGE](state, ui) {
        state.creditDeniedCurrentPage = ui;
    },
    [SUPPLIER_CREDIT_CANCELLED_CURRENT_PAGE](state, ui) {
        state.creditCancelledCurrentPage = ui;
    },

    [SUPPLIER_CREDIT_PER_PAGE](state, ui) {
        state.perPage = ui;
    },

    [SUPPLIER_CREDIT_FILTER](state, ui) {
        state.filter = ui;
    },
};
