/*eslint-disable */
import Axios from 'axios'
import _ from 'lodash'
import {
  REPAIRER_RFQ_IDS_UPDATE_CANCELLED,
  REPAIRER_RFQ_IDS_UPDATE_ORDERED,
  REPAIRER_RFQ_IDS_UPDATE_PRICED,
  REPAIRER_RFQ_IDS_UPDATE_RECEIVED,
  REPAIRER_RFQ_IDS_UPDATE_SUBMITTED,
  REPAIRER_RFQ_UPDATED,
  REPAIRER_CURRENT_RFQ_UPDATE,
  REPAIRER_RFQ_FILTER_UPDATE, SUPPLIER_RFQ_UPDATED, SUPPLIER_RFQ_SET_VIEWING_USERS,
} from '../../../mutation-types'

export function getRFQById (context, rfqId) {
  return new Promise((resolve, reject) => {
    Axios.get('/ir/repairer/rfq/' + rfqId)
      .then((response) => {
        context.commit(REPAIRER_CURRENT_RFQ_UPDATE, response.data.rfq)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function setFilter (context, filter) {
  context.commit(REPAIRER_RFQ_FILTER_UPDATE, filter)
}

// export function getRFQByIds(context, payload) {
//     Axios.post('/ir/repairer/rfqs', {ids:payload})
//         .then(response => {
//             context.commit(REPAIRER_RFQ_UPDATED, response.data.rfqs);
//         })
//         .catch(error => {
//             console.log(error);
//         });
// }

export function getRFQsForTableView (context, payload = 0) {

  let url = '/ir/repairer/rfqs/all'
  if (!_.isEmpty(payload)) url = '/ir/repairer/rfqs/' + payload

  Axios.post(url)
    .then(response => {
      if (response.data._status) {
        context.commit(REPAIRER_RFQ_UPDATED, response.data.rfqs)
        if (response.data.rfqs.length > 0) {
          if (!_.isEmpty(context.state.currentRfq) && _.some(response.data.rfqs, (rfq) => {
            return (Number(rfq.id) === Number(context.state.currentRfq.id))
          })) {
            getRFQById(context, context.state.currentRfq.id).catch(e => {
              console.log(e)
            })
          }
        }
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export function init (context, payload = 0) {
  return getRFQsForTableView(context, payload)
}

