<template>
  <div class="row settings-business-profile-tab">
    <tabs ref="business-profile-tabs" :options="{ useUrlFragment: false, defaultTabHash: computedDefaultBusinessProfileHash }"
          class="business-profile-tabs" @changed="onBusinessTabChange">
      <tab name="Details" id="business-details" class-name="content-table">
        <div class="row business-details">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">Details</div>
              <div class="card-block mt-3">
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Account ID</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.displayId" :readonly="true" :disabled="true" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Entity Name</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.name" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Business Name</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.businessName" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">ABN</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.abn" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">ACN</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.acn" type="text"
                           class="form-control" placeholder="ACN"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Supplier Vehicle Parts Category</label>
                  <div class="col-lg-8">
                    <multiselect
                        v-model="companyInfo.supplierVehicleCategory"
                        :options="['CAR', 'TRUCK']"
                        :showLabels="false"
                        :option-height="29"
                        :disabled="true"
                        :value="'CAR'"
                        :max-height="203"
                        :close-on-select="true"
                        placeholder="Supplier Vehicle Parts Category"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">
                    <span v-if="companyInfo.supplierTypes.length > 1">Business Types</span>
                    <span v-else>Business Type</span>
                  </label>
                  <div class="col-lg-8">
                    <input
                        v-for="(type, index) in companyInfo.supplierTypes"
                        :key="index"
                        :value="getType(type)"
                        :class="{
                          'business-type-input': index + 1 !== companyInfo.supplierTypes.length
                        }"
                        type="text"
                        class="form-control"
                        readonly
                        disabled/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Phone</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.phone" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Fax</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.fax" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
<!--                <div class="form-group row">-->
<!--                  <label class="col col-form-label col-lg-4">-->
<!--                    <span v-if="isUserTypeSupplier">-->
<!--                      Parts Email-->
<!--                    </span>-->
<!--                    <span v-else>-->
<!--                      Email-->
<!--                    </span>-->
<!--                  </label>-->
<!--                  <div class="col-lg-8">-->
<!--                    <input v-model="companyInfo.email" type="text"-->
<!--                           class="form-control" placeholder/>-->
<!--                    <small-->
<!--                        class="col-form-label form-text show-help-for-body"-->
<!--                        v-if="!$v.companyInfo.email.email"-->
<!--                    >Please enter a valid email address</small>-->
<!--                  </div>-->
<!--                </div>-->
                <div v-if="isUserTypeSupplier" class="form-group row">
                  <label class="col col-form-label col-lg-4">Description</label>
                  <div class="col-lg-8">
                    <textarea class="form-control" rows="4" v-model="companyInfo.about"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <tabs ref="addresses" :options="{ useUrlFragment: false }" class="business-profile-address-tabs">
              <tab name="Primary Business Address" id="primary-business-address" ref="primaryBusinessAddress"
                   class-name="tab-content-table">
                <div class="card w-100">
                  <div class="card-header">Primary Business Address</div>
                  <div class="card-block mt-3">
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Search Address</label>
                      <div class="col-lg-8">
                        <google-location
                            type="text"
                            v-model="companyInfo.address.address"
                            @onPlaceChange="addressChanged"
                            id="location_my_account"
                            placeholder="Search for an Address"
                        ></google-location>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Unit/Level</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.unitLevelLot" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Address Line</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.street" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Suburb</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.suburb" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">State</label>
                      <div class="col-lg-8">
                        <multiselect
                            v-model="companyInfo.address.state"
                            :options="addressStates"
                            track-by="value"
                            label="value"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select state"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Postcode</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.postcode" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Country</label>
                      <div class="col-lg-8">
                        <multiselect
                            v-model="companyInfo.address.country"
                            :options="addressCountries"
                            track-by="value"
                            label="value"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select country"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
              <tab name="Primary Parts Dept Address"
                   id="primary-parts-dept-address" ref="primaryPartsDeptAddress" class-name="tab-content-table">
                <div class="card w-100">
                  <div class="card-header">Primary Parts Dept Address</div>
                  <div class="card-block mt-3">
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Primary Parts Dept Business Address</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.primaryBusinessAddress.address" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Suburb</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.primaryBusinessAddress.suburb" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">State</label>
                      <div class="col-lg-8">
                        <multiselect
                            v-model="companyInfo.primaryBusinessAddress.state"
                            :options="addressStates"
                            track-by="value"
                            label="value"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select state"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Postcode</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.primaryBusinessAddress.postcode" type="text"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabs>
          </div>
          <div class="col-md-6" style="transform: translateY(-60px)">
            <tabs v-if="isUserTypeSupplier" ref="business-profile-tabs" :options="{ useUrlFragment: false }"
                  class="business-profile-tabs">
              <tab name="Franchise" id="business-details" class-name="content-table">
                <div class="card">
                  <div class="card-header">Details</div>
                  <div class="card-block">
                    <div class="row w-100 align-items-center mb-3" style="margin-top: 15px; margin-left: 15px !important;">
                      <div @click="addFranchise" class="add-new-rule-btn d-flex justify-content-center">
                        + Add New Franchise
                      </div>
                    </div>
                    <div v-for="(itm, ind) in franchises" :key="ind">
                      <div class="col-12 mb-3 purchase-price-selector-client-list">
                        <div @click="toggleClientInformation(ind)" style="cursor: pointer;" class="client-block">
                          <span>{{itm.name}}</span>
                          <i v-if="ind !== selectedFranchiseInd" class='bx bx-down-arrow'></i>
                          <i v-else class='bx bx-up-arrow'></i>
                        </div>
                      </div>
                      <template v-if="selectedFranchiseInd === ind">
                        <div class="client-information mt-3">
                          <div class="form-group row">
                            <label class="col col-form-label col-lg-4">Franchise Name</label>
                            <div class="col-lg-8">
                              <input v-model="itm.name" @change="updateFranchise(itm)" type="text" class="form-control" placeholder/>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col col-form-label col-lg-4">Parts Email</label>
                            <div class="col-lg-8">
                              <input
                                  @blur="checkEmail(itm)"
                                  @focus="itm.isCheckEmail = true;"
                                  v-model="itm.email"
                                  type="text"
                                  class="form-control" placeholder/>
                              <div class="w-100 text-right" v-show="!itm.isCheckEmail && itm.email !== ''">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is incorrect
                                    </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col col-form-label col-lg-4">Orders Email</label>
                            <div class="col-lg-8">
                              <input
                                  @blur="checkEmail2(itm)"
                                  @focus="itm.isCheckEmail2 = true;"
                                  v-model="itm.emailOrders"
                                  type="text"
                                  class="form-control" placeholder/>
                              <div class="w-100 text-right" v-show="!itm.isCheckEmail2 && itm.emailOrders !== ''">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is incorrect
                                    </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col col-form-label col-lg-4">Сredits Email</label>
                            <div class="col-lg-8">
                              <input
                                  @blur="checkEmail3(itm)"
                                  @focus="itm.isCheckEmail3 = true;"
                                  v-model="itm.emailCredits"
                                  type="text"
                                  class="form-control" placeholder/>
                              <div class="w-100 text-right" v-show="!itm.isCheckEmail3 && itm.emailCredits !== ''">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is incorrect
                                    </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col col-lg-4 col-form-label">Search and add Makes</label>
                          <div class="col-lg-8">
                            <multiselect
                                v-model="itm.selectedBrand"
                                :options="getBrands()"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="true"
                                :preserve-search="true"
                                :resetAfter="true"
                                @input="v => onSelectedMake(v, itm)"
                                placeholder="Type to search and add a make"
                                :preselect-first="false"
                            >
                              <template
                                  v-slot:cell(selection)="{ values, search, isOpen }"></template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label">Makes of Parts Supplied</label>
                          <div class="col-lg-8">
                            <supplier-makes :chips="itm.makes"
                                            @remove="v => removeFranchiseBrand(v, itm)"></supplier-makes>
                          </div>
                        </div>

                        <div class="form-group row flex-column">
                          <label v-if="!itm.logo" class="col-lg-4 col-form-label">Add logo</label>
                          <label v-else class="col-lg-4 col-form-label">Logo</label>
                          <div class="">
                            <div v-if="itm.logo" class="mt-3">
                              <div class="company-logo w-100">
                                <img :src="itm.logo" class="company-logo w-100"
                                     alt="Company Logo"/>
                              </div>
                              <button
                                  @click="removeFranchiseLogo(itm)"
                                  type="button"
                                  class="btn btn-danger w-100 mt-2"
                              >Delete Logo
                              </button>
                            </div>
                            <vue-dropzone
                                v-else
                                v-on:vdropzone-file-added="addImgUpload"
                                v-on:vdropzone-success="successImgUploadBusinessProfile"
                                v-on:vdropzone-complete="completeImgUpload"
                                v-on:vdropzone-sending="sendImgUpload"
                                ref="imgUploadDropBusiness"
                                id="imgUploadDropBusiness"
                                class="dropify-wrapper dropzoneContainer mt-3"
                                style="height: auto;"
                                :options="getVueDropzoneOptions(itm)"
                                :useCustomSlot="true"
                            >
                              <div class="dropify-message" style="transform: translateY(0%);">
                                <i class="bx bx-cloud-upload"></i>
                                <p class="drop-down-text">Drag and Drop Logo Here</p>
                                <p class="text-or mb-0-5">Or</p>
                                <div class="text-sm-center mb-1 ex-image-upload-buttons">
                                  <button
                                      type="button"
                                      class="btn btn-primary waves-effect waves-light browse-btn"
                                  >Browse Logo
                                  </button>
                                </div>
                              </div>
                            </vue-dropzone>
                          </div>
                        </div>

                        <div class="w-100 d-flex justify-content-end mb-15">
                          <button v-if="franchises.length > 1" @click="openModalForDeleting(itm)" class="delete-user">Delete</button>
                        </div>
                      </template>
                    </div>

                  </div>
                </div>
              </tab>
            </tabs>

          </div>
        </div>
      </tab>
      <tab name="Contacts" id="business-contacts" class-name="content-table">
        <b-table responsive hover :items="computedContacts" :fields="computedContactFields">
          <template v-slot:cell(username)="data">
            {{ data.item.firstName }} {{ data.item.lastName }}
          </template>

          <template v-slot:cell(businessLocation)="data">
            <multiselect
                v-model="data.item.location"
                :options="locations"
                track-by="id"
                label="name"
                :showLabels="false"
                :option-height="29"
                :max-height="203"
                :close-on-select="true"
                placeholder="Select your business location"
                :multiple="false"
                @input="businessLocationUpdate(data.item)"
            />
          </template>
          <template v-slot:cell(action)="data">
            <div v-if="!data.item.isVisible"
                 class="eye hide-icon"
                 id="hide-icon-tooltip"
                 v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show users as a contact to suppliers', placement:'leftbottom'}"
                 @click="onClickHide(data.index)">
              <i class="bx bx-hide"></i>
            </div>
            <div v-else
                 class="eye show-icon"
                 id="show-icon-tooltip"
                 v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Hide users as a contact to suppliers', placement:'leftbottom'}"
                 @click="onClickShow(data.index)">
              <i class="bx bx-show"></i>
            </div>
          </template>
        </b-table>
      </tab>
      <tab name="Locations" id="business-locations" class-name="content-table">
        <b-table ref="locationTable" hover :items="locations"
                 :fields="locationFields">
          <template v-slot:cell(name)="data">
            <b-form-input
                v-if="data.item.isEditable"
                v-model="selectedLocation.name"
                type="text"/>
            <span v-else>{{ data.item.name }}</span>
          </template>
          <template v-slot:cell(address)="data">
            <div v-if="data.item.isEditable">
              <input type="text" v-model="selectedLocation.address" class="form-control"/>
            </div>
            <div v-else>{{ data.item.address }}</div>
          </template>
          <template v-slot:cell(suburb)="data">
            <div v-if="data.item.isEditable">
              <input type="text" v-model="selectedLocation.suburb" class="form-control"/>
            </div>
            <div v-else>{{ data.item.suburb }}</div>
          </template>
          <template v-slot:cell(state)="data">
            <div v-if="data.item.isEditable">
              <multiselect
                  v-model="selectedLocation.stateValue"
                  :options="addressStates"
                  track-by="value"
                  label="value"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select state"
              />
            </div>
            <div v-else-if="data.item.stateValue">{{ data.item.stateValue.value }}</div>
          </template>
          <template v-slot:cell(postcode)="data">
            <div v-if="data.item.isEditable">
              <input type="text" v-model="selectedLocation.postcode"
                     class="form-control"/>
            </div>
            <div v-else>{{ data.item.postcode }}</div>
          </template>
          <template v-slot:cell(phone)="data">
            <div v-if="data.item.isEditable">
              <input type="text" v-model="selectedLocation.phone" class="form-control"/>
            </div>
            <div v-else>{{ data.item.phone }}</div>
          </template>
          <template v-slot:cell(openHours)="data">
            <div v-if="data.item.isEditable">
              <date-picker v-model="selectedLocation.openHours"  :place-holder-text="'hh:mm'" :isTime="true"></date-picker>
              <!--              <input type="text" v-model="selectedLocation.openHours"-->
              <!--                     class="form-control"/>-->
            </div>
            <div v-else>{{ data.item.openHours }}</div>
          </template>
          <template v-slot:cell(openDays)="data">
            <div v-if="data.item.isEditable">
              <multiselect
                  v-model="selectedLocation.openDays"
                  :options="['Mon to Friday', 'Mon to Saturday']"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select delivery"
              />
            </div>
            <div v-else>{{ data.item.openDays }}</div>
          </template>
          <template v-slot:cell(closingHours)="data">
            <div v-if="data.item.isEditable">
              <date-picker v-model="selectedLocation.closingHours"  :place-holder-text="'hh:mm'" :isTime="true"></date-picker>
<!--              <input type="text" v-model="selectedLocation.closingHours"-->
<!--                     class="form-control"/>-->
            </div>
            <div v-else>{{ data.item.closingHours }}</div>
          </template>
          <template v-slot:cell(delivery)="data">
            <div v-if="data.item.isEditable">
              <multiselect
                  :class="'location_delivery'"
                  v-model="selectedLocation.delivery"
                  :options="deliveryOptions"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select delivery"
              />
            </div>
            <div v-else>{{ data.item.delivery }}</div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="action-btn">
              <button
                  v-if="!data.item.isEditable"
                  class="clickable btn_edit"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit Location', placement:'leftbottom'}"
                  @click="onClickEditRow(data.index)"
              ></button>
              <button
                  v-else
                  class="clickable btn_save"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Save Location', placement:'leftbottom'}"
                  @click="onClickSaveRow(data.index)"
              ></button>
              <button
                  v-if="!data.item.isEditable"
                  class="clickable btn_trush"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete Location', placement:'leftbottom'}"
                  @click="onClickRemoveRow(data.index)"></button>
            </div>
          </template>
        </b-table>
      </tab>
    </tabs>

    <b-modal ref="deleteFranchiseModal" id="delete-franchise" size="custom-delete-franchise" title="WARNING - Delete a Franchise Profile">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-view-part-photo form-container">
        <form>
          <div class="pr-3 pl-3 mt-3 mb-3">
            <div class="pl-0 pb-3 ">Deleting this Franchise profile will <b>Permanently Remove</b> notification email targets and auto filtering functionality applied to User accounts.</div>
            <div class="pl-0 pb-3 ">Contact support for Assistance</div>
            <div class="pl-0 pb-3 ">Are you sure you want to continue?</div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Cancel Action</b-button>
          <b-button @click="deleteFranchise" class="input-btn">Continue</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import _ from "lodash";
import NProgress from "nprogress";
import {mapGetters, mapActions} from "vuex";
import {email} from "vuelidate/lib/validators";
import GoogleLocation from '@/components/utility/google-location'
import supplierMakes from '@/components/supplier-makes'
import DatePicker from '../../../../components/datePicker';
import Axios from "axios";

export default {
  name: "the-business-profile",
  data () {
    return {
      franchises: [],
      selectedFranchiseInd: 0,
      selectedBrand: '',
      contactFields: [
        {
          label: 'Name',
          key: 'username',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Position',
          key: 'companyRole',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Office Phone',
          key: 'workPhone',
          sortable: true,
          tdClass: 'table-body',
          thClass: ''
        },
        {
          label: 'Mobile Phone',
          key: 'mobilePhone',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Location',
          key: 'businessLocation',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Action',
          key: 'action',
          sortable: true,
          tdClass: 'table-body text-center',
          thClass: 'table-header text-center'
        }
      ],
      locationFields: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Address',
          key: 'address',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Suburb',
          key: 'suburb',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'State',
          key: 'state',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Postcode',
          key: 'postcode',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Phone',
          key: 'phone',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Open Days',
          key: 'openDays',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Open Hours',
          key: 'openHours',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Closing Hours',
          key: 'closingHours',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Delivery',
          key: 'delivery',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Action',
          key: 'action',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header action-btns'
        }
      ],
      companyLogoDropzoneOptions: {
        headers: { Authorization: localStorage.getItem('token') },
        resizeWidth: 900,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/company/logo/upload/',
        method: 'post',
        maxFilesize: 50,
        // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        clickable: ['.browse-btn'],
        dictFileTooBig:
            'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      companyInfo: {
        name: '',
        businessName: '',
        abn: '',
        acn: '',
        supplierType: '',
        supplierTypes: [],
        phone: '',
        fax: '',
        displayId: null,
        email: '',
        emailOrders: '',
        emailCredits: '',
        brands: [],
        deleteBrands: [],
        logoImgPath: '',
        address: {
          address: '',
          unitLevelLot: '',
          street: '',
          suburb: '',
          postcode: '',
          state: '',
          country: ''
        },
        primaryBusinessAddress: {
          address: '',
          unitLevelLot: '',
          street: '',
          suburb: '',
          postcode: '',
          state: '',
          country: ''
        },
        supplierVehicleCategory: 'CAR',
      },
      initialBrands: [],
      locations: [],
      deleteLocations: [],
      contacts: [],
      contactsVisibility: {
        visible: [],
        inVisible: []
      },
      selectedLocation: {
        id: '',
        name: '',
        address: '',
        suburb: '',
        state: '',
        postcode: '',
        phone: '',
        openHours: '',
        closingHours: '',
        openDays: null,
        delivery: '',
      },
      deliveryOptions: [
        'Road'
      ],
      franchiseIdForDeleting: -1,
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    multiselect: Multiselect,
    GoogleLocation,
    supplierMakes,
    DatePicker,
  },
  computed: {
    ...mapGetters({
      usersAll: 'users/getAllUsers',
      currentUser: 'currentUser/getCurrentUserInfo',
      addressCountries: 'addressCountries',
      initialCompanyInfo: 'currentCompany/getDetails',
      initialLocations: 'currentCompany/getLocations',
      addressStates: 'addressStates',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      brands: 'brandList/getBrands',
      getterSettings: 'settings/getSettings',
      getSupplierBusinessTypes: 'getSupplierBusinessTypes'
    }),
    computedContacts() {
      return _.filter(this.contacts, (c) => {
        return c?.businessLocation?.includes(this.companyInfo?.id)
      })
    },
    computedContactFields () {
      return this.contactFields;
    },
    computedBrands () {
      return _.filter(this.brands, (brand) => {
        return !this?.companyInfo?.brands?.includes(brand)
      })
    },
    getSettings () {
      return _.cloneDeep(this.getterSettings)
    },
    computedDefaultBusinessProfileHash () {
      if (localStorage.getItem("settings.tabs.defaultBusinessProfileTab")) {
        return localStorage.getItem("settings.tabs.defaultBusinessProfileTab");
      }
      return "business-details";
    },
  },
  methods: {
    ...mapActions({
      loadBrandList: 'brandList/loadBrandList',
      loadUsersAll: 'users/fetchAllUsers',
    }),
    getType(t) {
      return _.find(this.getSupplierBusinessTypes, (type) => {
        return type.value == t
      })?.label
    },
    removeFranchiseLogo(f) {
      Axios.post(      `/ir/company/logo/delete/${this.companyInfo.id}/${f.id}`, )
          .then((r) => {
            if (r?.data?._status) {
              f.logo = null;
              this.updateVuexSettings();
            }
          })

    },
    getVueDropzoneOptions(f) {
      let v = _.cloneDeep(this.companyLogoDropzoneOptions);
      v.url = appConfig.baseAPIURL + '/ir/company/logo/upload/' + this.companyInfo.id + '/' + f.id;
      return v;
    },
    openModalForDeleting(f) {
      this.franchiseIdForDeleting = f.id;
      this.$refs.deleteFranchiseModal.show();
    },
    getBrands() {
      return _.filter(this.brands, (brand) => {
        return !this.isFranchiseInludesMake(brand)
      })
    },
    isFranchiseInludesMake(v) {
      return _.some(this.franchises, (f) => {
        return f?.makes?.includes(v)
      })
    },
    deleteFranchise() {
      Axios.post('/ir/company/delete-franchise', {
        "franchiseId": this.franchiseIdForDeleting,
      })
          .then((r) => {
            if (r?.data?._status) {
              let ind = _.findIndex(this.franchises, (f) => {
                return Number(f.id) === Number(this.franchiseIdForDeleting)
              })
              if (ind !== -1) {
                this.franchises.splice(ind, 1)
                this.updateVuexSettings();
              }
            }
          })
         .finally(() => {
           this.$refs.deleteFranchiseModal.hide();
         })
    },
    onSelectedMake(v, f) {
      if (!v) return
      if (f.makes.includes(v)) return

      Axios.post('/ir/company/add-make-for-franchise', {
        "franchiseId": f.id,
        makes: [v]
      })
          .then((r) => {
            if (r?.data?._status) {
              f.makes.push(v)
              f.makes = f.makes.filter((elem, pos, arr) => {
                return arr.indexOf(elem) === pos
              })
              this.updateVuexSettings();
            }
          })
    },
    removeFranchiseBrand(v, f) {
      Axios.post('/ir/company/delete-make-for-franchise', {
        "franchiseId": f.id,
        makes: [v]
      })
          .then((r) => {
            if (r?.data?._status) {
              f.makes.splice(f.makes.indexOf(v), 1)
            }
            this.updateVuexSettings();
          })
    },
    loadFranchises() {
      this.franchises = _.map(this.getSettings.franchises, (itm) => {
        return {
          ...itm,
          isCheckEmail: true,
          isCheckEmail2: true,
          isCheckEmail3: true,
        }
      })
    },
    addFranchise() {
      Axios.post('/ir/company/add-franchise', {
        "franchiseName": 'Set Franchise Name',
        "email": " ",
        "emailOrders": " ",
        "emailCredits": " ",
      })
          .then((r) => {
            if (r?.data?._status && r?.data?.franchiseId) {
              this.franchises.push({
                id: r.data.franchiseId,
                email: '',
                emailOrders: '',
                emailCredits: '',
                name: 'Set Franchise Name',
                makes: [],
              })
              this.selectedFranchiseInd = this.franchises.length - 1;
              this.updateVuexSettings();
            }
          })
    },
    updateFranchise(f) {
      Axios.post('/ir/company/update-franchise', {
        "franchiseName": f.name,
        "email": f.email,
        "emailOrders": f.emailOrders,
        "emailCredits": f.emailCredits,
        franchiseId: f.id
      })
          .then((r) => {
            if (r?.data?._status) {
              this.updateVuexSettings();
            }
          })
    },
    updateVuexSettings() {
      let r = _.cloneDeep(this.getterSettings)
      r.franchises = this.franchises
      this.$store.commit('settings/allSettingsUpdated', r)
    },
    checkEmail(itm) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      itm.isCheckEmail = re.test(itm.email);
      if (itm.isCheckEmail  || itm.email == '' || itm.email == null) {
        this.updateFranchise(itm);
      }
    },
    checkEmail2(itm) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      itm.isCheckEmail2 = re.test(itm.emailOrders);
      if (itm.isCheckEmail2  || itm.emailOrders == '' || itm.emailOrders == null) {
        this.updateFranchise(itm);
      }
    },
    checkEmail3(itm) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      itm.isCheckEmail3 = re.test(itm.emailCredits);
      if (itm.isCheckEmail3  || itm.emailCredits == '' || itm.emailCredits == null) {
        this.updateFranchise(itm);
      }
    },
    toggleClientInformation(index) {
      if (this.selectedFranchiseInd === index) {
        this.selectedFranchiseInd = -1;
      } else {
        this.selectedFranchiseInd = index;
      }
    },
    addressChanged: function (addr) {
      let country = _.find(this.addressCountries, item => {
        return item.key === addr["country"];
      })
      let state = _.find(this.addressStates, item => {
        return item.key === addr["state"]
      })
      this.companyInfo.address.address = addr["address"];
      this.companyInfo.address.unitLevelLot = addr['levelUnitLot']
      this.companyInfo.address.street = addr["street_name"];
      this.companyInfo.address.suburb = addr["suburb"];
      this.companyInfo.address.state = state;
      this.companyInfo.address.postcode = addr["postcode"];
      this.companyInfo.address.country = country;
    },
    onBusinessTabChange: function (tab) {
      this.$emit('changedTab', tab.tab.id)
      localStorage.setItem("settings.tabs.defaultBusinessProfileTab", tab.tab.id);
    },
    getLocationById: function (id) {
      let location = {}
      let locations = _.cloneDeep(this.locations);
      if (!_.isEmpty(locations)) {
        location = _.find(locations, (location) => {
          return id === location.id
        })
      }
      return location
    },
    setLocations: function (data) {
      let val = _.cloneDeep(data);
      let states = _.cloneDeep(this.addressStates);
      this.locations = val.map(location => ({
        ...location,
        isEditable: false,
        stateValue: _.find(states, item => {
          return item.key === location.state
        })
      }))
    },
    setContacts: function (data) {
      let usersAll = _.cloneDeep(data);
      if (!_.isEmpty(usersAll)) {
        this.contacts = null
        let contacts = []
        _.forEach(usersAll, (item) => {
          let contact = item
          contact.location = this.getLocationById(item.businessLocation)
          contacts.push(contact)
        })
        this.contacts = contacts
      }
    },
    setCompanyInfo: function (company) {
      console.log(company, 'compant')
      if (!_.isEmpty(company)) {
        let val = JSON.parse(JSON.stringify(company)),
            country = _.find(this.addressCountries, item => {
              return (item.key === val.address.country) || (item.key === val.address?.country?.key) || (item.key === val.address?.country?.[0]?.key)
            }),
            state = _.find(this.addressStates, item => {
              return item.key === val.address.state || item.key === val?.address?.state?.key || item.key === val?.address?.state?.[0]?.key
            })

        this.initialBrands = this.initialCompanyInfo.brands
        this.companyInfo = {
          about: val.about,
          id: this.initialCompanyInfo.id,
          name: val.name,
          acn: val.acn,
          businessName: val.businessName,
          abn: val.abn,
          supplierType: val.supplierType,
          supplierTypes: val.supplierTypes,
          supplierVehicleCategory: val.supplierVehicleCategory,
          phone: val.phone,
          fax: val.fax,
          email: val.email,
          emailOrders: val.emailOrders,
          emailCredits: val.emailCredits,
          brands: (!_.isEmpty(val.brands)) ? val.brands : [],
          deleteBrands: [],
          logoImgPath: val.logoImgPath,
          address: {
            address: val.address.address,
            unitLevelLot: val.address.unitLevelLot,
            street: val.address.street,
            suburb: val.address.suburb,
            postcode: val.address.postcode,
            state: (!_.isEmpty(state)) ? state : null,
            country: (!_.isEmpty(country)) ? country : null
          },
          displayId: val.displayId,
          primaryBusinessAddress: val.primaryBusinessAddress,
        }
        let primaryStateValue = null
        if (val.primaryBusinessAddress && val.primaryBusinessAddress.state) {
          primaryStateValue = _.find(this.addressStates, (item) => {
            return val.primaryBusinessAddress.state === item.key
          })
        }
        if (!_.isEmpty(primaryStateValue)) {
          this.companyInfo.primaryBusinessAddress.state = primaryStateValue
        }
        // setTimeout(() => {
        //   if (this.$refs.imgUploadDropBusiness) {
        //     let url = appConfig.baseAPIURL + '/ir/company/logo/upload/' + this.initialCompanyInfo?.id;
        //     this.$refs.imgUploadDropBusiness.options.url = url
        //     this.$refs.imgUploadDropBusiness.dropzone.options.url = url
        //     this.companyLogoDropzoneOptions.url = url
        //   }
        // }, 200)
      }
    },
    clickDeleteCompanyLogo: function () {
      NProgress.start()
      this.companyInfo.logoImgPath = ''
      Axios.post(`/ir/company/logo/delete/` + this.initialCompanyInfo.id)
          .then((r) => {
            if (r.data.status)
              this.$toast.success('Company logo has been removed')
            NProgress.done()
          })
          .catch((error) => {
            this.$toast.error(error)
            NProgress.done()
          });
    },
    onClickShow: function (index) {
      let visibility = this.contactsVisibility
      const id = this.contacts[index].id
      this.contacts[index].isVisible = false
      visibility.inVisible.push(id)
      visibility.visible = _.without(this.contactsVisibility.visible, id)
      this.contactsVisibility = visibility
      this.$store.commit('users/settingsUpdateVisibleAllUsers', _.cloneDeep(this.contacts))
      this.saveCompanyInfo({
        contacts: visibility
      })
    },
    onClickHide: function (index) {
      let visibility = this.contactsVisibility
      const id = this.contacts[index].id
      this.contacts[index].isVisible = true
      visibility.visible.push(id)
      visibility.inVisible = _.without(this.contactsVisibility.inVisible, id)
      this.contactsVisibility = visibility
      this.$store.commit('users/settingsUpdateVisibleAllUsers', _.cloneDeep(this.contacts))
      this.saveCompanyInfo({
        contacts: visibility
      })
    },
    removeBrand: function (removedOption) {
      console.log('removedOption', removedOption)
      this.companyInfo.brands.splice(this.companyInfo.brands.indexOf(removedOption), 1)
      this.companyInfo.deleteBrands.push(removedOption)
    },
    updatedBusinessProfile: function () {
      let data = {
        id: this.initialCompanyInfo.id,
        businessName: this.companyInfo.businessName,
        details: {
          abn: this.companyInfo.abn,
          about: this.companyInfo.about,
          email: this.companyInfo.email,
          emailOrders: this.companyInfo.emailOrders,
          emailCredits: this.companyInfo.emailCredits,
          fax: this.companyInfo.fax,
          businessName: this.companyInfo.businessName,
          phone: this.companyInfo.phone,
          acn: this.companyInfo.acn,
          name: this.companyInfo.name,
        },
        address: {
          address: this.companyInfo.address.address,
          country: this.companyInfo?.address?.country?.key ? this.companyInfo.address.country.key : null,
          unitLevelLot: this.companyInfo.address.unitLevelLot,
          street: this.companyInfo.address.street,
          postcode: this.companyInfo.address.postcode,
          suburb: this.companyInfo.address.suburb,
          state: this.companyInfo?.address?.state?.key ? this.companyInfo.address.state.key : null,
        },
        addBrands: this.companyInfo.brands.filter(x => !this.initialBrands.includes(x)),
        deleteBrands: this.companyInfo.deleteBrands.filter(x => !this.companyInfo.brands.includes(x)),
        locations: this.locations,
        deleteLocations: this.deleteLocations,
        contacts: this.contactsVisibility,
        primaryBusinessAddress: Object.assign({}, this.companyInfo.primaryBusinessAddress),
      }
      if (data.primaryBusinessAddress.state && data.primaryBusinessAddress.state.key) {
        data.primaryBusinessAddress.state = data.primaryBusinessAddress.state.key
      }
      data.details.supplierType = this.companyInfo.supplierType
      data.details.supplierTypes = this.companyInfo.supplierTypes
      data.details.supplierVehicleCategory = this.companyInfo.supplierVehicleCategory

      console.log('updatedBusinessProfile', data)
      return data
    },
    saveCompanyInfo: function (data = {}) {
      let dataForSave = data
      if (_.isEmpty(dataForSave)) dataForSave = this.updatedBusinessProfile();
      dataForSave.id = this.companyInfo.id
      NProgress.start()
      this.$store.dispatch('currentCompany/saveBusinessProfile', _.cloneDeep(dataForSave))
          .then((res) => {
            console.log('res', res)
            if (res.data.status) {
              this.deleteLocations = []
              this.initialBrands = this.companyInfo.brands
              this.companyInfo.deleteBrands = []
              this.$toast.success('Company details updated successfully')
              NProgress.done()
            }
          })
          .catch((err) => {
            this.$toast.error(err)
            NProgress.done()
          })
    },
    businessLocationUpdate: function (data) {
      let user = {
        id: data.id,
        businessLocation: data.location.id,
      }
      this.updateUser(user)
    },
    updateUser: function (user) {
      NProgress.start();
      let roles = ['Administrator', 'Estimator', 'Parts Coordinator'];
      user.groupId = roles.indexOf(user.companyRole) + 1;
      this.$store.dispatch('users/updateUser', {
        id: user.id,
        updatedUser: user
      }).then(() => {
        this.$toast.success('User has been updated')
        NProgress.done()
        this.loadUsersAll();
      }).catch((err) => {
        this.$toast.error(err)
        NProgress.done()
      })

    },
    refreshSelectedLocation: function () {
      this.selectedLocation = {
        id: '',
        name: '',
        address: '',
        suburb: '',
        state: '',
        stateValue: '',
        postcode: '',
        phone: '',
        openHours: '',
        closingHours: '',
        openDays: null,
        delivery: '',
      }
      //this.$refs.locationTable.refresh()
    },
    onClickEditRow: function (index) {
      let locations = JSON.parse(JSON.stringify(this.locations))
      let location = JSON.parse(JSON.stringify(locations[index]))
      let stateValue = _.find(this.addressStates, (item) => {
        return location.state === item.key
      })
      for (let key in locations) {
        locations[key].isEditable = false
      }
      this.selectedLocation = {
        id: location.id,
        name: location.name,
        address: location.address,
        suburb: location.suburb,
        state: location.state,
        stateValue: stateValue,
        postcode: location.postcode,
        phone: location.phone,
        openHours: location.openHours,
        closingHours: location.closingHours,
        openDays: location.openDays,
        delivery: location.delivery,
      }
      locations[index].isEditable = true
      this.locations = locations
      this.$refs.locationTable.refresh()
    },
    onClickSaveRow: function (index) {
      let locations = JSON.parse(JSON.stringify(this.locations))
      locations[index] = JSON.parse(JSON.stringify(this.selectedLocation))
      if (!_.isEmpty(this.selectedLocation.stateValue)) locations[index].state = this.selectedLocation.stateValue.key
      this.locations = locations
      this.refreshSelectedLocation()
      this.saveCompanyInfo()
    },
    onClickRemoveRow: function (index) {
      let location = this.locations[index],
          locations = this.locations
      locations.splice(index, 1)
      if ('id' in location) this.deleteLocations.push(location.id)
      this.refreshSelectedLocation()
      this.locations = locations
    },
    onClickSaveCompanyInfo() {
      this.saveCompanyInfo()
    },
    onClickAddLocation() {
      this.locations.push({
        name: '',
        address: '',
        phone: '',
        openHours: '',
        closingHours: '',
        openDays: null,
        delivery: '',
        isEditable: true
      })
      this.refreshSelectedLocation()
    },
    addImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    completeImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].removeAttribute('style')
    },
    sendImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    successImgUploadBusinessProfile (file, response) {
      if (response.status) {
        this.franchises[this.selectedFranchiseInd].logo = file.dataURL
        this.updateVuexSettings();
      } else {
        setTimeout(() => {
          this.$refs.imgUploadDropBusiness.removeFile(file)
        }, 1000)
      }
    },
  },
  mounted () {
    this.loadBrandList()
    this.setCompanyInfo(this.initialCompanyInfo)
    if (!_.isEmpty(this.initialLocations)) {
      this.setLocations(this.initialLocations)
    }
    this.setContacts(this.usersAll)
  },
  watch: {
    getterSettings: {
      handler() {
        this.loadFranchises();
      },
      immediate: true,
    },
    initialLocations: {
      deep: true,
      handler (val) {
        this.setContacts(this.usersAll)
        this.setLocations(val)
      }
    },
    currentUser: {
      deep: true,
      handler () {
        this.setCompanyInfo(this.initialCompanyInfo)
      }
    },
    initialCompanyInfo: {
      deep: true,
      handler (val) {
        this.setCompanyInfo(val)
      }
    },
    usersAll: {
      deep: true,
      handler (val) {
        this.setContacts(val)
      }
    },
    // selectedBrand: function (newVal) {
    //   if (!newVal) return
    //   if (this.companyInfo.brands.includes(newVal)) return
    //   this.companyInfo.brands.push(newVal)
    //   this.companyInfo.brands = this.companyInfo.brands.filter((elem, pos, arr) => {
    //     return arr.indexOf(elem) === pos
    //   })
    // },
  },
  validations: {
    companyInfo: {
      email: {
        email
      },
      emailOrders: {
        email
      },
      emailCredits: {
        email
      },
    }
  }
}
</script>
<style>
.business-type-input {
  margin-bottom: 15px;
}

.modal-custom-delete-franchise .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-custom-delete-franchise {
  margin: 15rem auto !important;
  border-radius: 3px !important;
}

.modal-custom-delete-franchise {
  max-width: 424px !important;
}

.modal-custom-delete-franchise .modal-body {
  padding-bottom: 0;
}

.modal-custom-delete-franchise .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-custom-delete-franchise .modal-header {
  background-color: #29BBC1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-custom-delete-franchise .modal-content {
  border-radius: 3px !important;
}

.modal-custom-delete-franchise .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #29BBC1 !important;
  border-color: #29BBC1 !important;
}

.modal-custom-delete-franchise .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-custom-delete-franchise .input-btn:focus {
  box-shadow: none !important;
}
</style>

<style scoped>

.add-new-rule-btn {
  font-size: 1rem;
  font-weight: bold;
  color: #29BBC1;
  cursor: pointer;
}

.purchase-price-selector-client-list {
  width: 100%;
}

.purchase-price-selector-client-list .client-block {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(28, 31, 57, 0.25);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  color: #1C1F39;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  padding: 10px;
}

.purchase-price-selector-client-list .client-block span {
  font-size: 13px !important;
}

.purchase-price-selector-client-list .bx-up-arrow,
.purchase-price-selector-client-list .bx-down-arrow {
  font-size: 20px;
}

</style>
