<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import VCalandarDatePicker from 'v-calendar/src/components/DatePicker'

export default {
  name: 'DatePicker',
  props: {
    isDateTime: {
      type: Boolean,
      default: false,
    },
    isTime: {
      type: Boolean,
      default: false,
    },
    placeHolderText: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {}
  },
  computed: {
    computedMasks() {
      let masks = {
        input: 'DD/MM/YYYY',
      };
      if (this.isDateTime) {
        masks = {
          input: 'hh:mmA || DD/MM/YYYY',
          L: 'hh:mmA || DD/MM/YYYY',
          inputDateTime: 'hh:mmA || DD/MM/YYYY',
        };
      } else if (this.isTime) {
        masks = {
          input: 'hh:mmA',
        };
      }
      return masks;
    },
    computedModelConfig() {
      let config = {
        type: 'string',
        mask: 'DD/MM/YYYY'
      }
      if (this.isDateTime) {
        config.mask = 'hh:mmA || DD/MM/YYYY';
      } else if (this.isTime) {
        config.mask = 'hh:mmA';
      }
      return config;
    },
    computedMode() {
      if (this.isDateTime) {
        return 'dateTime'
      } else if (this.isTime) {
        return 'time'
      }
      return 'date'
    },
    computedValue: {
      get () {
        return this.value
      },
      set (val) {
        return val
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.$emit('updatedValue')
      },
      immediate: true
    }
  },
  methods: {
    onMouseOver(e) {
      this.$emit('mouseover', _.toLower(e.target.tagName))
    },
    updateData (val) {
      this.$emit('input', val)
      this.$emit('onChange')
    },
  },
  components: {
    VCalandarDatePicker,
  },
}

</script>

<template>
  <div @mouseover="onMouseOver" class="">
    <v-calandar-date-picker
        v-model="computedValue"
        color="teal"
        :attributes="[{key: 'today',highlight: true,dates: new Date(),},]"
        :masks="computedMasks"
        :model-config="computedModelConfig"
        @input="updateData"
        :popover="{ visibility: 'click' }"
        :mode="computedMode"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
            class="form-control"
            :value="inputValue"
            v-on="inputEvents"
            :disabled="isDisabled"
            :readonly="isReadonly"
            :placeholder="placeHolderText"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @change="updateData(inputValue)"
        />
      </template>
    </v-calandar-date-picker>
  </div>
</template>
