<script>
/*eslint-disable */
import NProgress from "nprogress";
import Email from "../../../components/auth/Email.vue";
import Axios from "axios";

export default {
  name: "ForgotPasswordPageCcnsw",
  components: {
    Email
  },
  data() {
    return {
      userEmail: "",
      isCheckEmail: true
    };
  },
  methods: {
    sendForgotRequest() {
      NProgress.start();
      Axios({url: '/ir/auth/forgot/password', data: {email: this.userEmail}, method: 'POST'})
          .then(resp => {
            this.isCheckEmail = !(resp && resp.data && resp.data.status === false);
            //TODO remove after testing
            if(!_.isEmpty(resp.data['_debug']) && !_.isEmpty(resp.data['_debug']['resetLink'])) {
              window.location = resp.data['_debug']['resetLink'];
            } else if (this.isCheckEmail) this.$router.push('/login');
          })
          .catch(err => {
            console.log(err);
          }).finally(() => {
        NProgress.done();
      });
    },
    goToHome() {
      document.location.replace('/login');
      // this.$router.push('/');
    },
  }
};
</script>

<template>
  <div class="container-fluid page">
    <div class="content">
      <div class="background column">
        <div style="width: 694px"></div>
      </div>
      <div class="form-container column">
        <div class="form"  style="bottom: 70px">
          <div>
            <div class="logo">
            </div>
          </div>
          <form method="POST" @submit.prevent="login">
            <div>
              <img src="@/assets/logos/accident-repair-centres-logo.jpg" class="accident-logo"/>
              <span class="close" @click.prevent="goToHome">×</span>
            </div>
            <h2 class="header-text">Forgot your Password?</h2>
            <p>Dont’ worry, just enter the email address your account is registered with and we will send
              you link to reset your password</p>
            <br/>
            <div class="form-group">
              <label>Email Address</label>
              <email v-model="userEmail"></email>
              <p>
                Get Help with your password.
                <a href="#" class="blue-theme" @click.prevent="$router.push({name: 'SupportPasswordPage'})">Click here</a>
              </p>
            </div>
            <div class="form-group">
              <button :disabled="!isCheckEmail || !userEmail"
                      type="button"
                      @click="sendForgotRequest"
                      class="btn input-btn">Send Password Reset Link</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../../../styles/login/style.css";
</style>

<style scoped>
.close{
  cursor: pointer;
}
.blue-theme{
  color: #00529C;
}
.blue-theme:hover{
  color: #013c72;
}
.input-btn{
  background-color: #00529C;
}
.input-btn:hover{
  background-color: #013c72;
  color: white;
}
.input-btn:disabled:hover{
  background-color: #ccc;
}
.accident-logo{
  height: 100px;
  margin-bottom: 70px;
}
.page {
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .page .content {
    height: 100%;
  }
  .page .content .form-container {
    height: 100%;
  }
  .page .form {
    height: 100%;
  }
  .page .form form {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: center;
  }
}
</style>
