import { Model } from 'vue-mc'
import _ from 'lodash'

/**
 * Order model
 */
export default class Order extends Model {

  // Default attributes that define the "empty" state.
  defaults () {
    return {
      id: null,
      QPNumber: '',
      amount: 0,
      customer: {},
      dateCreated: '',
      dateDue: '',
      deliveryTo: {},
      discountsApplied: 0,
      partsDeliveryETA: '',
      invoiceNumber: '',
      total: 0,
      subtotal: 0,
      gst: 0,
      files: [],
      number: '',
      parts: [],
      statusId: 1,
      paymentTerms: 'credit',
      status: 'Open',
      vehicle: {},
      estimator: '',
    }
  }

  // Attribute mutations.
  mutations () {
    return {
      id: (id) => Number(id) || null,
      statusId: (statusId) => Number(statusId) || 1,
      discountsApplied: (discountsApplied) => Number(discountsApplied) || 0,
      total: (total) => Number(total) || 0,
      subtotal: (subtotal) => Number(subtotal) || 0,
      gst: (gst) => Number(gst) || 0,
      QPNumber: String,
      amount: Number,
      dateCreated: String,
      dateDue: String,
      number: String,
      paymentTerms: String,
      status: String,
      // estimator:  String||null,
      //instruction: (deliveryTo)=>{deliveryTo.instruction || '3'},
    }
  }

  /**
   * Is status Open
   * @return {boolean}
   */
  isOpen () {
    return this.status === 'Open'
  }

  isDenied () {
    return this.status === 'Denied'
  }

  isCancelled () {
    return this.status === 'Cancelled'
  }


  /**
   * Is status Processing
   * @return {boolean}
   */
  isProcessing () {
    return this.status === 'Processing'
  }

  /**
   * Is status Shipped
   * @return {boolean}
   */
  isShipped () {
    return this.status === 'Shipped'
  }

  /**
   * Is status Delivered
   * @return {boolean}
   */
  isDelivered () {
    return this.status === 'Delivered'
  }

  /**
   * Is status Completed
   * @return {boolean}
   */
  isCompleted () {
    return this.status === 'Completed'
  }

  /**
   * Get pricing completed percent
   * @returns {number}
   */
  get pricingCompletedPercent () {
    let countOfParts = this.parts.length,
      partsCountWithPrice = 0,
      result = 0

    _.forEach(this.parts, (part) => {
      if (Number(part.price) && !_.isEmpty(part.partNumber)) partsCountWithPrice++
    })
    result = partsCountWithPrice / (countOfParts / 100)
    return Math.round(result)
  }

  /**
   * Get parts in stock percent
   * @returns {number}
   */
  get partsInStock () {
    let contOfParts = 0,
      partsInStock = 0,
      result = 0
    _.forEach(this.parts, (part) => {
      if ((part.stock === 1 || part.stock === 2) && part.status !== 'CancelPart') partsInStock++
      if (part.status !== 'CancelPart'){      contOfParts++}
    })
    if (contOfParts > 0) {
      result = partsInStock / (contOfParts / 100)
      return Math.round(result)
    }
    return ''
  }

  // Attribute validation
  validation () {

  }

  // Route configuration
  routes () {

  }
}
