<template>
  <div class="repairer-parts-orders-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>Parts Management</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active">View Parts Management</li>
          </ol>
        </div>
        <div class="search-filter-in-header">
          <search-filter
              type="estimates"
              v-model="searchFilter2"
              :filterValue="searchFilter2"
              :additionalMenu="additionalMenu"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              :placeholder="'Search'"
              :isSearch="true"
              :debug="false"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>
    </div>
    <div class="repairer-parts-orders-body">
      <tabs
          ref="repairer-parts-orders-table"
          cache-lifetime="0"
          :options="{useUrlFragment:false}"
          :custom-counts="computedCustomCountForTab"
          @changed="tabChanged">
        <tab name="Current" id="Current" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedCurrentOrders"
                :fields="allFields"
                :filter="filter"
                :current-page="gettersCurrentPageCurrent"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @row-clicked="clickRow"
                @row-contextmenu="openMenuForOrders"
                @filtered="value => partsFilteredCurrent = value"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(delivered)="data">
                {{ data.value }}%
              </template>
              <template #cell(deliveryType)="data">
                {{ formatDeliveryType(data.value) }}
                <span v-if="data.item.onBackOrder" style="color: red"><br v-if="data.value"/>Back Order</span>
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: #0cb458">Today</span>
                  <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br />ETA Change</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                  <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br />ETA Change</span>
                </template>
                <template v-else>
                  <span :class="{'color-orange': isShowDeliveryText(data.item)}">{{ getDate(data.item) | formatDate('DD/MM/YYYY') }}</span>
                  <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br v-if="getDate(data.item)"/>ETA Change</span>
                </template>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  !data.item.shipped,
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.shipped}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true)"
                       class="bx bxs-flag-alt text-warning"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Entries'"
              :arrayOfSomeone="gettersCurrentOrders"
              :arrayOfSomeoneFiltered="filter ? partsFilteredCurrent : computedCurrentOrders"
              :currentPage="gettersCurrentPageCurrent"
              @changedCurrentPage="value => $store.commit('repairer/order/repairerCurrentOrdersCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value =>  $store.commit('repairer/order/repairerOrdersPerPage', value)"
          >
          </block-pagination>

        </tab>
        <tab name="Back Order" id="BackOrder" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedBackOrders"
                :fields="allFields"
                :filter="filter"
                :current-page="gettersCurrentPageBackOrder"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @row-clicked="clickRow"
                @row-contextmenu="openMenuForOrders"
                @filtered="value => partsFilteredBack = value"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(delivered)="data">
                {{ data.value }}%
              </template>
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(deliveryType)="data">
                {{ formatDeliveryType(data.value) }}
                <span v-if="data.item.onBackOrder" style="color: red"><br v-if="data.value"/>Back Order</span>
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: #0cb458">Today</span>
                  <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br />ETA Change</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                  <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br />ETA Change</span>
                </template>
                <template v-else>
                  <span :class="{'color-orange': isShowDeliveryText(data.item)}">{{ getDate(data.item) | formatDate('DD/MM/YYYY') }}</span>
                  <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br v-if="getDate(data.item)"/>ETA Change</span>
                </template>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  !data.item.shipped,
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.shipped}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true)"
                       class="bx bxs-flag-alt text-warning"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Entries'"
              :arrayOfSomeone="gettersBackOrders"
              :arrayOfSomeoneFiltered="filter ? partsFilteredBack : computedBackOrders"
              :currentPage="gettersCurrentPageBackOrder"
              @changedCurrentPage="value => $store.commit('repairer/order/repairerBackOrdersCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value =>  $store.commit('repairer/order/repairerOrdersPerPage', value)"
          >
          </block-pagination>

        </tab>
        <tab name="Completed" id="Completed" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedCompletedOrders"
                :fields="allFields"
                :filter="filter"
                :current-page="gettersCurrentPageCompleted"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @row-clicked="clickRow"
                @row-contextmenu="openMenuForOrders"
                @filtered="value => partsFilteredCompleted = value"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(deliveryType)="data">
                {{ formatDeliveryType(data.value) }}
              </template>
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(delivered)="data">
                {{ data.value }}%
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <span :class="{'color-orange': isShowDeliveryText(data.item)}">{{ getDate(data.item) | formatDate('DD/MM/YYYY') }}</span>
                <span v-if="isShowDeliveryText(data.item)" style="color: orange"><br v-if="getDate(data.item)"/>ETA Change</span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  !data.item.shipped,
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.shipped}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true)"
                       class="bx bxs-flag-alt text-warning"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Entries'"
              :arrayOfSomeone="gettersCompletedOrders"
              :arrayOfSomeoneFiltered="filter ? partsFilteredCompleted : computedCompletedOrders"
              :currentPage="gettersCurrentPageCompleted"
              @changedCurrentPage="value => $store.commit('repairer/order/repairerCompletedOrdersCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value =>  $store.commit('repairer/order/repairerOrdersPerPage', value)"
          >
          </block-pagination>

        </tab>
        <template slot="nav-item-right">
          <b-dropdown v-if="!isMobileOnly" text="Options" class="dropdown-options ml-3">
            <template v-slot:button-content>
              Export
              <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
            </template>
            <b-dropdown-item @click="exportReport('csv')">.CSV</b-dropdown-item>
            <b-dropdown-item @click="exportReport('xlsx')">.XLS</b-dropdown-item>
          </b-dropdown>
          <div class="tabs-nav-right-search">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :filterValue="filter"
                :placeholder="'Filter By'"
                :isShowSearIcon="false"
                :limit-length="isSupplierFilterActive ? 10 : 50"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="5"
                @change="onSearchFilterChange"
                @updateToken="value => $store.commit('repairer/order/setToken', value)"
                @updateTokens="value => $store.commit('repairer/order/setTokens', value)"
                :tokenCopy="computedToken"
                :tokensCopy="getterTokens"
                :debug="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
          </div>
          <button v-if="isMobileOnly" @click="openScanModal" style="margin-left: 10px;" class="btn btn-primary primary-save-btn">Scan</button>
        </template>
      </tabs>
    </div>

    <vue-context ref="menuOrders"
                 :style="{width: contextWidth + 'px'}"
                 :lazy="true"
                 @close="contextClose"
                 @open="contextOpen"
                 :closeOnScroll="true">
      <template slot-scope="item">
        <b-button-toolbar key-nav>
          <b-button-group vertical style="width: 168px" class="parts-order-view__context-menu">
            <template  v-if="item && item.data && item.data.row">
              <b-button
                  class="text-left font-12 font-inter--semibold"
                  variant="outline-primary"
                  block
                  @click="viewOrder(item.data.row.id)"
              > View Order
              </b-button>
              <b-button
                  v-if="item.data.row.state != 2 && (item.data.row.delivered == 100 || item.data.row.isCanBeCompleted || item.data.row.status === 'Denied' || item.data.row.status === 'Cancelled')"
                  class="text-left font-12 font-inter--semibold"
                  variant="outline-primary"
                  block
                  @click="markOrderCompleted(item.data.row.id)"
              >Mark Order Completed
              </b-button>
              <b-button
                  v-if="item.data.row.state != 1"
                  class="text-left font-12 font-inter--semibold"
                  variant="outline-primary"
                  block
                  @click="markOrderCurrent(item.data.row.id)"
              >Move to Current
              </b-button>
              <b-button
                  v-if="item.data.row.onBackOrder && item.data.row.state != 3"
                  class="text-left font-12 font-inter--semibold"
                  variant="outline-primary"
                  @click="markOrderBack(item.data.row.id)"
                  block
              >Mark on Back Order
              </b-button>

            </template>
          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>

    <b-modal ref="notifySupplier" id="parts-order-scan-modal" size="custom-mark-as-returned parts-order-scan-modal" style="padding-left: 0px;" @hide="onCloseModal"
             title="">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div style="display: grid; grid-template-columns: 20fr 1fr;">
        <h5 style="text-align: center; color: #29bbc1; font-weight: bold; margin-bottom: 20px; line-height: 21px">SCAN BARCODE</h5>
        <span class="close" @click="$refs.notifySupplier.hide()">×</span>
      </div>
      <div class="d-flex justify-content-center flex-column">
        <div class="modal-mark-as-returned form-container" style="margin-bottom: 15px;">
          <qr-code-scanner
              :qrbox="250"
              :fps="10"
              style="width: auto;"
              @result="onScan"
          ></qr-code-scanner>
        </div>

        <div v-if="!_isEmpty(decodedResults)" class="notify-supplier-and-parts-block" style="margin-bottom: 50px" :style="{'width': calcWidth + 'px'}">
          <div
              v-for="itm in decodedResults"
              class="notify-supplier-item"
              @click="goToOrder(itm)"
              :class="{
                'crossed-out-text': !isFoundOrder(itm)
              }"
          >{{itm}}</div>
        </div>
      </div>

      <template v-slot:modal-footer="{ confirm, cancel }">
        <b-button v-if="!_isEmpty(decodedResults)" @click="searchOrder" class="input-btn">Search Order<span v-if="decodedResults.length > 1 && !isHasOnlyOneFoundedItem">s</span></b-button>
        <b-button class="input-btn cancel mr-2" style="display:none;" @click="$refs.notifySupplier.hide()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
/*eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import BlockPagination from '../../../components/utility/block-pagination'
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import moment from 'moment'
import {isMobileOnly} from 'mobile-device-detect';
import QrCodeScanner from '../../../components/qrcode-scanner'
import ScannerSound from '../../../assets/audio/scanner-sound.mp3'
import { VueContext } from 'vue-context'
import Axios from "axios";
import * as dayjs from "dayjs";
import accounting from "accounting";

var isToday = require('dayjs/plugin/isToday')
var isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

export default {
  name: 'partsOrders',
  data: function () {
    return {
      // filter: '',
      isMobileOnly: false,
      searchFilter: '',
      searchFilter2: '',
      isAddedStyles: false,
      partsFiltered: [],
      decodedResults: [],
      contextWidth: 0,
      currentTab: '',
      contextOpenEvent: null,
      partsFilteredCurrent: [],
      partsFilteredCompleted: [],
      partsFilteredBack: [],
      allFields: [
        {
          label: 'Order Nbr',
          key: 'number',
          sortable: true,
          tdClass: 'clickable number',
          thClass: '',
          class: 'table-col-narrower'
        },
        { label: 'Invoice Nbr', key: 'invoiceNumber', sortable: true, tdClass: 'clickable', thClass: '' },
        {
          label: 'Quoting Package Nbr',
          key: 'QPNumber',
          sortable: true,
          tdClass: 'clickable number number',
          thClass: '',
          class: 'table-col-narrow'
        },
        { label: 'Rego', key: 'rego', sortable: true, tdClass: 'clickable ', thClass: '' },
        { label: 'Supplier', key: 'supplierName', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Make Model', key: 'makeModel', sortable: true, tdClass: 'clickable number', thClass: '' },
        // { label: 'VIN', key: 'vin', sortable: true, tdClass: 'clickable ', thClass: '', class: 'table-col-wide' },
        { label: 'Order Cost', key: 'costStr', sortable: true, tdClass: 'clickable number', thClass: '', class: 'table-col-wide' },
        { label: 'Date Ordered', key: 'dateCreated', sortable: true, tdClass: 'clickable ', thClass: '' },
        { label: 'Delivery Date', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Delivery Type', key: 'deliveryType', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Parts Rcvd', key: 'delivered', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Status', key: 'status', sortable: true, tdClass: 'clickable status number', thClass: '' },
      ],
    }
  },
  mounted () {
    this.isMobileOnly = isMobileOnly;
    if (this.$route.params?.filter) {
      this.setFilter(this.$route.params.filter)
    }
    if (this.$route.params?.tab) {
      setTimeout(() => {
        this.$refs['repairer-parts-orders-table']?.selectTab('#' + this.$route.params.tab)
      }, 0)
    }
    if (this.getterSearchFilterObj) {
      this.searchFilter = Object.assign({}, this.getterSearchFilterObj)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$route.params?.filter === 'Back Orders' || this.$route.params?.filter === 'Shipped') {
      this.setFilter('')
    }
    next()
  },
  methods: {
    ...mapActions({
      setFilter: 'repairer/order/setFilter'
    }),
    getDate(item) {
      if (_.isEmpty(item.supplierDeliveryDate) && _.isEmpty(item.supplierOrderPartsDeliveryETA) && _.isEmpty(item.repairerDeliveryDate)) {
        return item.dateDue
      }
      return item.dateDue
      let d1 = new Date(dayjs(item.supplierDeliveryDate,'DD/MM/YYYY').format('YYYY-MM-DD')).getTime()
      let d2 = new Date(dayjs(item.supplierOrderPartsDeliveryETA,'DD/MM/YYYY').format('YYYY-MM-DD')).getTime()
      let d3 = new Date(dayjs(item.repairerDeliveryDate,'DD/MM/YYYY').format('YYYY-MM-DD')).getTime()
      return new Date(_.max([d1, d2, d3]))
    },
    isShowDeliveryText(item) {
      // console.log('isShowDeliveryText', item.id, item.supplierDeliveryDate, item.supplierOrderPartsDeliveryETA)
      return (!_.isEmpty(item.supplierDeliveryDate) ||  !_.isEmpty(item.supplierOrderPartsDeliveryETA))
    },
    redirectToRfqView(itm) {
      if (itm.isJobView) {
        this.setFilter('')
        this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
      } else {
        this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.value}});
      }
    },
    onEnter() {
      setTimeout(() => {
        let order = _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })
        if (order && !_.isEmpty(this.searchFilter2)) {
          this.setFilter('')
          this.$router.push({name: 'RepairerJobView', params: {job_id: order.value}});
          // this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: order.id}});
        }
      }, 500)
    },
    filterData(data) {
      if (this.isPartNumberFilterActive) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partNumber) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)

        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (nbr) => {
            if (order.partsNumbers && order.partsNumbers.toLowerCase().includes(nbr.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isSupplierFilterActive) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].supplier) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.supplier.toLowerCase().split(';').filter(Boolean)
        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (suppName) => {
            if (order.supplierName && order.supplierName.toLowerCase().trim().includes(suppName.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isMakeFilterActive) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].make) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.make.toLowerCase().split(';').filter(Boolean)
        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (make) => {
            if (order.make && order.make.toLowerCase().trim().includes(make.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isInvoiceNumberFilterActive) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].invoiceNumber) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.invoiceNumber.toLowerCase().split(';').filter(Boolean)
        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (invoiceNumber) => {
            if (order.invoiceNumber && order.invoiceNumber.toLowerCase().trim().includes(invoiceNumber.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isStatusFilterActive) {
        let filter = [];
        let isReceivedFilterActive = false;
        let isNotReceivedFilterActive = false;
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].status) {
            filter = obj[key];
            let statuses = obj[key].status.split(';').filter(Boolean);
            _.forEach(statuses, (st) => {
              if (st === 'Received') {
                isReceivedFilterActive = true;
              }
              if (st === 'Not Received') {
                isNotReceivedFilterActive = true;
              }
            })
            break
          }
        }
        data = _.filter(data, order => {
          let bool = false
          if (isReceivedFilterActive) {
            if (order.partsStatuses && (order.partsStatuses.somePartsReceived || order.partsStatuses.allPartsReceived)) {
              bool = true
            }
          }
          if (filter.status.includes('Order Completed')) {
            if (order.state == 2) {
              bool = true
            }
          }
          if (filter.status.includes('Incorrect Part Delivered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrect) {
              bool = true
            }
          }
          if (filter.status.includes('Incorrect Part Ordered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrectOrdered) {
              bool = true
            }
          }
          if (filter.status.includes('No Longer Needed')) {
            if (order.partsStatuses && order.partsStatuses.somePartsNoLongerNeeded) {
              bool = true
            }
          }
          if (filter.status.includes('Change in Repair Method')) {
            if (order.partsStatuses && order.partsStatuses.somePartsChangeInRepairMethod) {
              bool = true
            }
          }
          if (filter.status.includes('Damaged Part in Transport')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamaged) {
              bool = true
            }
          }
          if (filter.status.includes('Damaged Part EX Warehouse')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamagedExWarehouse) {
              bool = true
            }
          }
          if (filter.status.includes('Partial Delivery')) {
            if (order.partsStatuses && order.partsStatuses.somePartsPartialDelivery) {
              bool = true
            }
          }
          if (filter.status.includes('Cancelled Part')) {
            if (order.partsStatuses && order.partsStatuses.somePartsCancelled) {
              bool = true
            }
          }
          if (isNotReceivedFilterActive) {
            if (order.partsStatuses && order.partsStatuses.somePartsReceived === false) {
              bool = true
            }
          }
          return bool
        })
      }
      return data
    },
    formatDeliveryType(v) {
      if (_.isEmpty(v)) {
        return ''
      }
      return v.charAt(0).toUpperCase() + v.slice(1)
    },
    isToday(v) {
      return dayjs(v).isToday()
    },
    isTomorrow(v) {
      return dayjs(v).isTomorrow()
    },
    markOrderCompleted (id) {
      this.$refs.menuOrders.close();
      Axios.post('/ir/repairer/order/' + id + '/completed')
          .then(response => {
            if (response.data.status) {
              this.$toast.success('The Order has been completed')
              this.$store.dispatch('repairer/order/init', {}, { root: true })
              // this.$router.push('/r/orders')
            } else {
              this.$toast.error('The Order hasn`t been completed')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('The Order hasn`t been completed')
          })
    },
    markOrderCurrent (id) {
      this.$refs.menuOrders.close();
      Axios.post('/ir/repairer/order/' + id + '/mark-as-current')
          .then(response => {
            if (response.data.status) {
              this.$toast.success('The Order has been marked as current')
              this.$store.dispatch('repairer/order/init', {}, { root: true })
              // this.$router.push('/r/orders')
            } else {
              this.$toast.error('The Order hasn`t been marked as current')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('The Order hasn`t been marked as current')
          })
    },
    markOrderBack (id) {
      this.$refs.menuOrders.close();
      Axios.post('/ir/repairer/order/' + id + '/mark-as-backorder')
          .then(response => {
            if (response.data.status) {
              this.$toast.success('The Order has been marked as Bask Order')
              this.$store.dispatch('repairer/order/init', {}, { root: true })
              // this.$router.push('/r/orders')
            } else {
              this.$toast.error('The Order hasn`t been marked as Bask Order')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('The Order hasn`t been marked as Bask Order')
          })
    },
    viewOrder (id) {
      this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: id}});
      this.$refs.menuOrders.close();
    },
    openMenuForOrders (row, index, event) {
      event.preventDefault()
      let tableRow = event.target.parentElement
      let rows = tableRow.parentElement.rows
      while (!rows) {
        tableRow = tableRow.parentElement
        rows = tableRow.parentElement.rows
      }
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i]
          elem.style.backgroundColor = ''
        }
      }
      tableRow.style.backgroundColor = 'rgb(233,248,249)'
      if (row.state != 2 && (row.delivered == 100 || row.isCanBeCompleted || row.status === 'Denied' || row.status === 'Cancelled')) {
        this.contextWidth = 168
      } else if (row.onBackOrder && row.state != 3) {
        this.contextWidth = 149
      } else if (row.state != 1) {
        this.contextWidth = 132
      } else {
        this.contextWidth = 102
      }
      this.$refs.menuOrders.open(event, { row: row, index: index })
    },
    tabChanged (selectedTab) {
      this.currentTab = selectedTab.tab.name;
    },
    contextOpen: function (event) {
      this.contextOpenEvent = event
    },
    contextClose: function () {
      let event = this.contextOpenEvent
      if (event) {
        let row = event.target.parentElement
        let rows = row.parentElement.rows
        while (!rows) {
          row = row.parentElement
          rows = row.parentElement.rows
        }
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i]
            elem.style.backgroundColor = ''
          }
        }
      }
    },
    isFoundOrder(text) {
      return !!_.find(this.gettersAllOrders, (itm) => {
        return this.filterTableFunc(itm, text)
      })
    },
    goToOrder(text) {
      let order = _.find(this.gettersAllOrders, (itm) => {
        return this.filterTableFunc(itm, text)
      })

      if (order) {
        if (document.getElementsByClassName('site-header')?.[0]) {
          document.getElementsByClassName('site-header')[0].style.display = 'block'
        }
        this.$router.push({ path: '/r/order/' + order.id })
      }
    },
    searchOrder() {
      this.$refs.notifySupplier.hide()
    },
    onScan (decodedText, decodedResult) {
      let audio = new Audio(ScannerSound);
      let interval = setInterval(() => {
        audio.play()
            .then(() => {
              clearInterval(interval)
            })
            .catch(e => {
              // console.log(e, 'e')
            })
      }, 0)
      if (document.getElementById('qr-code-full-region__dashboard_section_csr')) {
        document.getElementById('qr-code-full-region__dashboard_section_csr').style.display = 'none'
      }
      if (document.getElementsByClassName('modal-footer')?.[0]) {
        document.getElementsByClassName('modal-footer')[0].style.justifyContent = 'center'
      }
      console.log(decodedResult, decodedText)
      if (!_.isEmpty(decodedText) && !_.includes(this.decodedResults, decodedText)) {
        this.decodedResults.unshift(decodedText)
      }
    },
    onCloseModal() {
      if ( document.getElementsByClassName('site-header')?.[0]) {
        document.getElementsByClassName('site-header')[0].style.display = 'block'
      }
      if (!_.isEmpty(this.decodedResults)) {
        this.filter = '';
        _.forEach(this.decodedResults, (t, ind) => {
          if (this.isHasOnlyOneFoundedItem) {
            if (this.isFoundOrder(t)) {
              let order = _.find(this.gettersAllOrders, (itm) => {
                return this.filterTableFunc(itm, t)
              })
              if (order) {
                this.$router.push({ path: '/r/order/' + order.id })
              }
            }
          } else {
            if (this.isFoundOrder(t)) {
              let rT = t;
              if (ind + 1 !== this.decodedResults.length) {
                rT += '; '
              }
              this.filter += rT
            }
          }
        })
      }
    },

    _isEmpty (v) {
      return _.isEmpty(v)
    },

    openScanModal() {
      this.decodedResults = [];
      this.$refs.notifySupplier.show()
      setTimeout(() => {
        let heigth = window.innerHeight - 210;
        if (heigth > 360 ) {
          heigth = 360
        }
        let scanHeight = heigth + 'px'
        // let scanHeight = accounting.toFixed(window.innerHeight - 200, 2) + 'px'
        // let scanHeight = accounting.toFixed(((window.innerHeight / 3) * 1.5), 2) + 'px'
        let buttonTranslate = accounting.toFixed((window.innerHeight - 46 - heigth) / 2, 2)
        if ( document.getElementsByClassName('site-header')?.[0]) {
          document.getElementsByClassName('site-header')[0].style.display = 'none'
        }
        if (document.getElementsByClassName('parts-order-scan-modal')?.[0]) {
          // document.getElementsByClassName('parts-order-scan-modal')[0].style.overflow = 'hidden'
          document.getElementsByClassName('parts-order-scan-modal')[0].setAttribute('style', 'margin: 0px !important')
        }
        if (document.getElementsByClassName('modal-content')?.[0]) {
          document.getElementsByClassName('modal-content')[0].style.minHeight = window.innerHeight + 'px'
        }
        if (document.getElementsByClassName('modal-footer')?.[0]) {
          document.getElementsByClassName('modal-footer')[0].style.justifyContent = 'flex-end'
        }
        if (document.getElementById('qr-code-full-region__scan_region')) {
          document.getElementById('qr-code-full-region__scan_region').style.height = scanHeight;
        }
        if (!this.isAddedStyles) {
          document.head.insertAdjacentHTML("beforeend", `<style>#qr-code-full-region__scan_region video, #qr-code-full-region { height: ${scanHeight}}</style>`)
          document.head.insertAdjacentHTML("beforeend", `<style>#qr-code-full-region__dashboard_section_csr {transform: translateY(${buttonTranslate}px);</style>`)
          document.head.insertAdjacentHTML("beforeend", `<style>#html5-qrcode-button-camera-permission, #qr-code-full-region__dashboard_section_csr {transform: translateY(${buttonTranslate - 82 }px);</style>`)
        }

        if (document.getElementById('qr-code-full-region__dashboard_section_csr')) {
          document.getElementById('qr-code-full-region__dashboard_section_csr').style.display = 'block'
        }
      }, 0)
    },
    getTitleForPackageIcon(item) {
      if (item.status === 'Processing' && this.isOrderProcessing(item)) {
        return 'Order in Processing'
      }
      if (item.status === 'Denied') {
        return 'Order Denied'
      }
      if (item.status === 'Cancelled') {
        return 'Order Cancelled'
      }
      return 'Order Processed'
    },
    isOrderProcessing (item) {
      return item &&
          item.partsStatuses &&
          !item.partsStatuses.allPartsReceived &&
          !item.partsStatuses.somePartsReceived &&
          !item.partsStatuses.somePartsDamaged &&
          !item.partsStatuses.somePartsDamagedExWarehouse &&
          !item.partsStatuses.somePartsIncorrect &&
          !item.partsStatuses.somePartsIncorrectOrdered &&
          !item.partsStatuses.somePartsNoLongerNeeded &&
          !item.partsStatuses.somePartsChangeInRepairMethod
    },
    getTooltipForIncorrectPart(part) {
      let result = ''
      let inc = part?.partsStatuses?.somePartsIncorrect == true;
      let incOrd = part?.partsStatuses?.somePartsIncorrectOrdered == true;
      let noLon = part?.partsStatuses?.somePartsNoLongerNeeded == true;
      let charIn = part?.partsStatuses?.somePartsChangeInRepairMethod == true;

      if (inc) {
        result += 'Incorrect Part Delivered'
      }
      if (incOrd) {
        if (inc) {
          result += ', '
        }
        result += 'Incorrect Part Ordered'
      }
      if (noLon) {
        if (inc || incOrd) {
          result += ', '
        }
        result += 'No Longer Needed'
      }
      if (charIn) {
        if (inc || incOrd || noLon) {
          result += ', '
        }
        result += 'Change in Repair Method'
      }

      return result
    },
    getTooltipForReturnPart(part) {
      let result = ''
      if (part?.partsStatuses?.somePartsDamagedExWarehouse == true && part?.partsStatuses?.somePartsDamaged == true) {
        result = 'Damaged Part in Transport and EX Warehouse'
      } else if (part?.partsStatuses?.somePartsDamagedExWarehouse == true) {
        result = 'Damaged Part EX Warehouse'
      } else if (part?.partsStatuses?.somePartsDamaged == true) {
        result = 'Damaged Part in Transport'
      }
      return result
    },
    exportReport (type) {
      let token = localStorage.getItem('token')

      let filteredItems = this.filteredItemsBack;
      if (this.currentTab === 'Completed') {
        filteredItems = this.filteredItemsCompleted;
      } else if (this.currentTab === 'Current') {
        filteredItems = this.filteredItemsCurrent;
      }

      window.open(appConfig.baseAPIURL + `/ir/repairer/orders/export-table?ids=${filteredItems}&direction=desc&format=${type}&at=${token}`)
    },
    viewPartStatus (status) {
      if (status === 'Shipped') {
        return 'Shipped'
      }
      return status
    },
    clickRow: function (order, index, event) {
      this.$router.push({ path: '/r/order/' + order.id })
      return
    },
    changePaginator () {

    },
    onSearchFilterChange (filters) {
      this.$store.commit('repairer/order/setSearchFilterObj', filters)
      if (filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
        // this.filter = filters.search;
      } else {
        this.setFilter('')
        // this.filter = '';
      }
      // console.log('onSearchFilterChange', filters);
    },
    filterTableFunc (row, filter) {
      let formattedFilter = filter.toLowerCase()
      let dateDue = row.dateDue ? moment(row.dateDue).format('DD/MM/YYYY || hh:mmA') : ''
      let dateCreated = row.dateCreated ? moment(row.dateCreated).format('DD/MM/YYYY') : ''

      if (filter === 'Ordered') return this.filterByStatus(row, 'Open')
      else if (filter === 'Back Orders') return row.onBackOrder
      else if (filter === 'Shipped') return row.shipped
      else if (filter === 'Delivered') return this.filterByStatus(row, 'Delivered')

      if (dateDue && dateDue.includes(formattedFilter)) {
        return true
      } else if (dateCreated && dateCreated.includes(formattedFilter)) {
        return true
      } else if (row.partsNumbers && row.partsNumbers.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.QPNumber && row.QPNumber.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.invoiceNumber && row.invoiceNumber.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.costStr && row.costStr.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.delivered && row.delivered == formattedFilter) {
        return true
      } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.makeModel && row.makeModel.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.number && row.number.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.rego && row.rego.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.rfqNumber && row.rfqNumber.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.supplierName && row.supplierName.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.vin && row.vin.toLowerCase().includes(formattedFilter)) {
        return true
      }
    },
    filterTable (row, filter) {
      if (!_.isEmpty(this.decodedResults) && this.decodedResults.length > 1) {
        return _.some(this.decodedResults, (t) => {
          return this.filterTableFunc(row, t)
        })
      }

      return this.filterTableFunc(row, filter)
    },
    filterByStatus (row, neededStatus) {
      return row.status == neededStatus
    }
  },
  computed: {
    ...mapGetters({
      gettersAllOrders: 'repairer/order/getAllOrders',
      gettersCurrentOrders: 'repairer/order/getCurrentOrders',
      gettersBackOrders: 'repairer/order/getBackOrders',
      gettersCompletedOrders: 'repairer/order/getCompletedOrders',
      gettersFilter: 'repairer/order/getFilter',
      gettersPerPage: 'repairer/order/getPerPage',
      gettersCurrentPageCurrent: 'repairer/order/getCurrentPageCurrent',
      gettersCurrentPageCompleted: 'repairer/order/getCurrentPageCompleted',
      gettersCurrentPageBackOrder: 'repairer/order/getCurrentPageBackOrder',
      getterSearchFilterObj: 'repairer/order/getSearchFilterObj',
      getterToken: 'repairer/order/getToken',
      getterTokens: 'repairer/order/getTokens',
    }),
    isHasOnlyOneFoundedItem() {
      return _.filter(this.decodedResults, (t) => {
        return this.isFoundOrder(t)
      }).length == 1
    },
    calcWidth() {
      return window.innerWidth - 26
    },
    additionalMenu() {
      if (_.isEmpty(this.searchFilter2)) {
        return []
      }
      let data = _.filter(this.gettersAllOrders, (r) => {
        return _.startsWith(_.trim(_.toLower(r.number)), _.trim(_.toLower(this.searchFilter2)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.number
        if (itm.supplierName) {
          label +=  ' - '
        }

        if (itm.supplierName) {
          label += itm.supplierName + ' '
        }
        result.push({
          value: itm.id,
          label: label,
          number: itm.number,
          rfqId: itm.rfqId,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds=[]
      let jobViewOptions = []
      _.forEach(result, (r) => {
        if (!_.includes(addedJobViewIds, r.jobViewId)) {
          let l = 'JOB VIEW'
          if (r.number) {
            l = r.number + ' - JOB VIEW'
          }

          if (r.rfqId != r.jobViewId) {
            if (result.length == 1) {
              jobViewOptions.push({
                value: r.rfqId,
                label: l,
                isJobView: true
              })
              addedJobViewIds.push(r.jobViewId)
            }
          } else {
            jobViewOptions.push({
              value: r.rfqId,
              label: l,
              isJobView: true
            })
            addedJobViewIds.push(r.jobViewId)
          }
        }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    computedCustomCountForTab () {
      let counts = {
        'Current': this.gettersCurrentOrders?.length,
        'BackOrder': this.gettersBackOrders?.length,
        'Completed': this.gettersCompletedOrders?.length,
      }
      return counts
    },
    filteredItemsCurrent () {
      let data = []
      if (this.filter) {
        data = this.partsFilteredCurrent
      } else {
        data = this.computedCurrentOrders
      }
      let result = ''
      _.forEach(data, (itm, index) => {
        if (index === data.length - 1) {
          result += itm.id
        } else {
          result += itm.id + ','
        }
      })
      return result
    },
    filteredItemsBack () {
      let data = []
      if (this.filter) {
        data = this.partsFilteredBack
      } else {
        data = this.computedBackOrders;
      }
      let result = ''
      _.forEach(data, (itm, index) => {
        if (index === data.length - 1) {
          result += itm.id
        } else {
          result += itm.id + ','
        }
      })
      return result
    },
    filteredItemsCompleted () {
      let data = []
      if (this.filter) {
        data = this.partsFilteredCompleted
      } else {
        data = this.computedCompletedOrders
      }
      let result = ''
      _.forEach(data, (itm, index) => {
        if (index === data.length - 1) {
          result += itm.id
        } else {
          result += itm.id + ','
        }
      })
      return result
    },
    computedToken () {
      return this.getterToken ? this.getterToken : []
    },

    isPartNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].partNumber) {
          return true
        }
      }
      return false
    },
    isMakeFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].make) {
          return true
        }
      }
      return false
    },
    isStatusFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].status) {
          return true
        }
      }
      return false
    },
    isSupplierFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].supplier) {
          return true
        }
      }
      return false
    },
    isInvoiceNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].invoiceNumber) {
          return true
        }
      }
      return false
    },
    computedSearchFilters () {
      let makes = {}
      let partNumbers = {}
      let suppliers = {}
      let invoiceNbrs = {}
      let data = this.gettersAllOrders.slice()

      data.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }

      })

      data.forEach(rfq => {
        if (rfq.supplierName) {
          suppliers[rfq.supplierName.trim().toLowerCase()] = { label: rfq.supplierName.trim() }
        }
      })

      data.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = { label: rfq.make.trim() }
        }
      })

      data.forEach(rfq => {
        if (rfq.invoiceNumber) {
          invoiceNbrs[rfq.invoiceNumber.trim().toLowerCase()] = { label: rfq.invoiceNumber.trim() }
        }
      })

      suppliers = _.orderBy(Object.values(suppliers), ['label'], ['asc'])
      invoiceNbrs = _.orderBy(Object.values(invoiceNbrs), ['label'], ['asc'])
      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      let statusOptions = [
        { label: 'Received' },
        { label: 'Incorrect Part Delivered' },
        { label: 'Incorrect Part Ordered' },
        { label: 'Damaged Part in Transport' },
        { label: 'Damaged Part EX Warehouse' },
        { label: 'No Longer Needed' },
        { label: 'Change in Repair Method' },
        { label: 'Cancelled Part' },
        { label: 'Not Received' },
        { label: 'Partial Delivery' },
        { label: 'Order Completed' },
      ]

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'invoiceNumber',
          label: 'Invoice Nbr',
          iconClass: 'bx-user',
          options: [
            invoiceNbrs,
          ]
        },
        {
          id: 'supplier',
          label: 'Supplier',
          iconClass: 'bx-user',
          options: [
            suppliers
          ]
        },
        {
          id: 'status',
          label: 'Status',
          iconClass: 'bx-user',
          options: [
            statusOptions
          ]
        },
      ]
    },
    searchFilterObj () {
      return this.getterSearchFilterObj
    },
    filter: {
      get () {
        return this.gettersFilter
      },
      set (value) {
        this.setFilter(value)
      }
    },
    computedCurrentOrders () {
      let currentOrders = this.filterData(_.cloneDeep(this.gettersCurrentOrders))

      currentOrders = _.orderBy(currentOrders, ['dateDue'], ['desc'])
      let nextAvailRunItems = _.filter(currentOrders, (order) => {
        return order.dateDue == null
      })
      let tomorrowItems = _.filter(currentOrders, (order) => {
        return this.isTomorrow(order.dateDue)
      })
      let todayItems = _.filter(currentOrders, (order) => {
        return this.isToday(order.dateDue)
      })
      let otherItems = _.filter(currentOrders, (order) => {
        return !this.isToday(order.dateDue) && !this.isToday(order.dateDue) && order.dateDue !== null
      })

      return _.uniqBy(_.concat(nextAvailRunItems, todayItems, tomorrowItems, otherItems),'id')
    },
    computedBackOrders () {
      let currentOrders = this.filterData(_.cloneDeep(this.gettersBackOrders))

      return _.orderBy(currentOrders, ['dateDue'], ['desc'])
    },
    computedCompletedOrders () {
      return this.filterData(_.cloneDeep(this.gettersCompletedOrders))
    },
  },
  components: {
    BlockPagination,
    QrCodeScanner,
    VueContext,
    SearchFilter
  }
}
</script>
<style>
.search-filter-in-header .custom-dropdown {
  min-width: 21rem !important;
}

.search-filter-in-header .custom-dropdown .dropdown-item {
  padding: 7px 15px !important;
}

</style>
<style scoped>
.color-orange {
  color: orange;
}

.crossed-out-text {
  text-decoration: line-through;
  text-decoration-color: red;
}

.red-icon {
  color: red !important;
}

.repairer-parts-orders-page td {
  height: 16px;
  width: 46px;
  color: #1C1F39;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.repairer-parts-orders-page .number {
  font-weight: bold;
}

.repairer-parts-orders-page .status .status-ordered {
  color: #2DCA73;
}

.repairer-parts-orders-body .notf-icon {
  padding: 0 5px;
  color: #c6c7ce;
}

.repairer-parts-orders-body .bx-buildings {
  position: relative;
}

.bx-buildings-active {
  color: #1B1E38;
}
.repairer-parts-orders-body .bxs-truck-active{
  color: #43B968 !important;
}

.repairer-parts-orders-body .after-buildins:after {
  content: '';
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xMC4wMDEgMTYuNDEzTDYuMjg4IDEyLjcwOEw3LjcgMTEuMjkyTDkuOTk5IDEzLjU4N0wxNS4yOTMgOC4yOTNMMTYuNzA3IDkuNzA3TDEwLjAwMSAxNi40MTNaIiBmaWxsPSIjMkRDQTczIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-size: contain;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  top: 0;
  right: 0;
}

.repairer-parts-orders-body .dropdown-options {
  margin-right: 70px !important;
}

@media (min-width: 426px) {
  .tabs-component .tab-header.float-sm-right div.tabs-nav-right-search {
    min-width: 350px;
  }
}
</style>
<style>
#parts-order-scan-modal___BV_modal_backdrop_ {
  background-color: white;
  opacity: 1;
}
#parts-order-scan-modal::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;
}
#parts-order-scan-modal {
  scrollbar-width: none;
}
#parts-order-scan-modal___BV_modal_footer_ {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: white;
  padding-top: 9px;
}

#parts-order-scan-modal {
  padding-left: 0px !important;
}
.parts-order-scan-modal .modal-header {
  display: none;
}

.notify-supplier-and-parts-block {
  display: flex;
  /*flex-direction: column;*/
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
}

.notify-supplier-and-parts-block .notify-supplier-item {
  border: 1px solid silver;
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 11px;
  font-weight: bold;
  margin-right: 15px;
  width: 100%;
  overflow-wrap: break-word;
}

#html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-start,
#html5-qrcode-button-file-selection,
#html5-qrcode-button-camera-permission {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #29bbc1 !important;
  border-color: #29bbc1 !important;
  cursor: pointer;
  font-weight: bold;
  background: #29bbc1;
  color: white;
  line-height: 18px;
  box-sizing: border-box;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
}

#html5-qrcode-button-camera-permission {
  background-color: orange !important;
  border: 1px solid orange !important;
}
#html5-qrcode-button-camera-stop {
  background-color: red !important;
  border: 1px solid red !important;
}

#qr-code-full-region__scan_region video {
  object-fit: fill;
}

#html5-qrcode-select-camera {
  border: 1px solid silver;
  border-radius: 3px;
  padding: 7px;
  margin-top: 15px;
}

#html5-qrcode-button-camera-start,
#html5-qrcode-button-camera-stop {
  margin-top: 15px;
}

#qr-code-full-region__dashboard_section_csr {
  /*transform: translateY(30px);*/
}

.notify-supplier-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: white !important;
  border-color: #79B1BB !important;
  cursor: pointer;
  font-weight: bold;
  background: white;
  color: white;
  line-height: 18px;
  box-sizing: border-box;
  border: 2px solid #79B1BB !important;
  border-radius: 3px;
}

#html5-qrcode-anchor-scan-type-change {
  color: #1C1F39;
  line-height: 38px;
  font-size: 1rem !important;
  font-weight: bold;
}

/*#qr-code-full-region div:first-child img {*/
/*  display: none;*/
/*}*/

#qr-code-full-region div img {
  display: none;
}

#html5-qrcode-button-camera-stop:hover,
#html5-qrcode-button-camera-start:hover,
#html5-qrcode-button-file-selection:hover,
#html5-qrcode-button-camera-permission:hover {
  color: #1C1F39;
}

@media (max-width: 785px) {
  .repairer-credit-page .tab-header.rs-btn-group.float-sm-right.mt-1-sm.pr-3.d-flex.align-items-center {
    flex-direction: column !important;
    height: auto !important;
    justify-content: start !important;
    float: left !important;
    align-items: start !important;
    margin-left: 15px !important;
    padding-right: 25px !important;
    margin-top: 10px !important;
  }

  .repairer-credit-page .tab-header.rs-btn-group.float-sm-right.mt-1-sm.pr-3.d-flex.align-items-center .dropdown-options {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 767px) {
  .repairer-credit-page .tab-header.rs-btn-group.float-sm-right.mt-1-sm.pr-3.d-flex.align-items-center .dropdown-options .dropdown-menu.show {
    transform: translate3d(0px, 38px, 0px) !important;
  }
}

@media (max-width: 905px) {
  .tabs-component .tab-header.float-sm-right div.tabs-nav-right-search {
    min-width: 300px !important;
  }
  .theme-repairer .repairer-parts-orders-body .dropdown-options {
    margin-right: 0px !important;
  }
}
</style>

