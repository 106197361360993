<script>
/* eslint-disable  */
import numeral from 'numeral'

export default {
  name: 'rebate-number-formatter',
  data: function () {
    return {
      isFocus: false,
      oldValue: null,
      formattedValue: this.value,
      isShowRebate: true,
      isShowRebateLast: true,
      isShowRebateInput: false,
    }
  },
  props: {
    refName: {
      type: String,
      default: ''
    },
    isTotalManual: {
      default: false,
    },
    originalValue: null,
    value: 0,
    percentage: {
      default: false
    },
    format: {
      type: String,
      default: '$0,0'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isCanBeZero: {
      type: Boolean,
      default: true,
    },
    isCanBeNegative: {
      type: Boolean,
      default: true,
    },
    isRebate: {
      type: Boolean,
      default: false,
    },
    isUseAnotherValueForComparison: {
      type: Boolean,
      default: false,
    },
    anotherValue: {
      type: [String, Object, Number],
      default: null
    },
    rebate: {
      type: String,
      default: ''
    },
    isCanOnlyIncrease: {
      type: Boolean,
      default: false,
    },
    startValue: null,
  },
  mounted: function () {
    this.formatValue()
    this.setVisibleOfRebateInput()
  },
  watch: {
    value: {
      handler: function (val, old) {
        console.log('watch.value', val, old)
        this.setVisibleOfRebateInput()
        this.oldValue = old
        if (val != old) {
          if (!this.isFocus) {
            this.$nextTick(() => {
              this.formatValue()
            })
          }
        }
      }
    },
    isRebate: {
      handler: function () {
        this.setVisibleOfRebateInput()
      }
    }
  },
  computed: {
    computedRef: function () {
      return 'numberFormatter' + this.refName
    },
  },
  methods: {
    setVisibleOfRebateInput() {
      if (this.isUseAnotherValueForComparison) {
        this.isShowRebateInput = this.isShowRebate && this.isRebate && !_.isNumber(this.anotherValue) && !_.isString(this.anotherValue)
      } else {
        this.isShowRebateInput = this.isShowRebate && this.isRebate && !_.isNumber(this.value) && !_.isString(this.value)
      }
    },
    onChange: function () {
      if (!this.isShowRebate && this.isShowRebateLast && this.isUseAnotherValueForComparison) {

        if (!this.isCanBeNegative && Number(this.value) < 0 && Number(this.oldValue) > 0) {
          this.$emit('changedValue', this.oldValue)
          this.$emit('change')
          return
        }
        if (!this.isCanBeZero && Number(this.value) === 0 && Number(this.oldValue) !== 0) {
          this.$emit('changedValue', this.oldValue)
          this.$emit('change')
          return
        }
        if (this.isCanOnlyIncrease && Number(this.startValue) && Number(this.value) < Number(this.startValue)) {
          this.$emit('changedValue', this.startValue)
          this.$emit('change')
          return
        }

        this.$emit('changedValue', this.value)
        this.$emit('change')
      } else {
        let time = 0
        if (this.isCanOnlyIncrease) {
          time = 200
        }

        setTimeout(() => {
          if (!this.isCanBeNegative && Number(this.value) < 0 && Number(this.oldValue) > 0) {
            this.$emit('changedValue', this.oldValue)
            this.setValue(this.oldValue)
            return
          }
          if (!this.isCanBeZero && Number(this.value) === 0 && Number(this.oldValue) !== 0) {
            this.$emit('changedValue', this.oldValue)
            this.setValue(this.oldValue)
            return
          }
          if (this.isCanOnlyIncrease && Number(this.startValue) && Number(this.value) < Number(this.startValue)) {
            this.$emit('changedValue', this.startValue)
            this.setValue(this.startValue)
            // this.value = old;
            return
          }

          this.$emit('changedValue', this.value)
          this.setValue(this.value)
          this.$emit('change')
        }, time)
      }
    },
    updateValue: function (value) {
      // console.log('number.update', value, value.includes('-'))
      if (!this.isCanBeNegative && value.includes('-')) {
        return
      }
      if (this.isTotalManual && (value == '' || value === null)) {
        var result = null
      } else {
        var result = value == '0.0' ? '0.0' : value == '.' ? '0.' : numeral(value).format('0.[00]')
      }
      this.$emit('input', result)
    },
    formatValue: function () {
      if (this.percentage) {
        if ((numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) > 100) {
          this.formattedValue = 100 + '%'
        } else {
          this.formattedValue = (numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) + '%'
        }
      } else {
        this.formattedValue = numeral(this.value).format(this.format)
        // this.$emit('blur')
      }
    },
    selectAll: function (event) {
      this.$emit('focus')
      this.isFocus = true
      // setTimeout(function () {
        event.target.select()
      // }, 0)
    },

    setValue: function (val) {
      let result = numeral(val).format('0.[00]')
      let formattedResult = numeral(result).format(this.format)
      if (this.percentage) {
        if ((numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) > 100) {
          formattedResult = 100 + '%'
        } else {
          formattedResult = (numeral(result).format(this.format)).slice(0, (numeral(result).format(this.format)).length - 3) + '%'
        }
      }
      this.$emit('input', result)
      let vm = this
      setTimeout(function () {
        vm.$refs[vm.computedRef].value = formattedResult
      }, 0)
    },
    emitEvent: function (type, event) {
      this.$emit(type, event)
    },
    focus: function () {
      this.isFocus = true
      this.$refs[this.computedRef].focus()
    },
    select: function () {
      this.isFocus = true
      this.$refs[this.computedRef].select()
    },
    click: function () {
      this.isFocus = true
      this.$refs[this.computedRef].click()
    },
    blur: function () {
      this.isFocus = false
      this.formatValue()
      this.$emit('blur')
      if (this.isRebate) {
        this.isShowRebate = true
        this.isShowRebateLast = false
        this.setVisibleOfRebateInput()
      }
    },
    onRebateFocus () {
      this.$refs[this.computedRef].blur()
      this.isShowRebate = false;
      this.isShowRebateLast = true
      this.setVisibleOfRebateInput()
      this.$nextTick(() => {
        this.$refs[this.computedRef].focus()
      })
    },
    onRebateBlur () {
      this.isShowRebate = true;
      this.isShowRebateLast = false
      this.setVisibleOfRebateInput()
    },
    _isNumber(v) {
      return _.isNumber(v)
    },
    _isString(v) {
      return _.isString(v)
    }
  }
}

</script>

<template>
  <input v-if="isShowRebateInput"
         :ref="computedRef"
         v-bind:value="rebate"
         v-on:keydown="emitEvent('onKeyDown', $event)"
         v-on:input="updateValue($event.target.value)"
         v-on:focus="onRebateFocus"
         v-on:blur="onRebateBlur"
         @mouseover="$emit('mouseover')"
         @mouseleave="$emit('mouseleave')"
         @keydown.left="$emit('onKeyPressLeft', $event)"
         @keydown.right="$emit('onKeyPressRight', $event)"
         @keydown.up="$emit('onKeyPressUp')"
         @keydown.down="$emit('onKeyPressDown')"
         :disabled="isDisabled"
         @change="onChange()">

  <input v-else :ref="computedRef"
         v-bind:value="formattedValue"
         v-on:keydown="emitEvent('onKeyDown', $event)"
         v-on:input="updateValue($event.target.value)"
         v-on:focus="selectAll"
         v-on:blur="blur"
         @mouseover="$emit('mouseover')"
         @mouseleave="$emit('mouseleave')"
         @keydown.left="$emit('onKeyPressLeft', $event)"
         @keydown.right="$emit('onKeyPressRight', $event)"
         @keydown.up="$emit('onKeyPressUp')"
         @keydown.down="$emit('onKeyPressDown')"
         :disabled="isDisabled"
         @change="onChange()">
</template>




