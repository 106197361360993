<template>
  <div class="dashboard-panel card" ref="container" v-bind:style="{'max-width':width || '100%'}">
    <div class="card-body">
      <div class="card-title">
        <h4 class="title-text">Parts Orders</h4>
        <div class="grid">
          <div class="input-text" style="margin-right: 0px">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :style="'min-width:' +  this.widthInputSearch + 'px'"
                :filterValue="filter"
                :isMyltipleOptions="true"
                @selectAdditional="redirectToRfqView"
                @onEnter="onEnter"
                :additionalMenu="additionalMenu"
                :isMyltiple="true"
                :optionsLength="5"
                :limit-length="50"
                @updateToken="value => $store.commit('supplier/order/setToken', value)"
                @updateTokens="value => $store.commit('supplier/order/setTokens', value)"
                @change="onSearchFilterChange"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
          </div>
        </div>
        <!--                <a href="#" class="btn btn-primary" style="vertical-align: middle;">-->
        <!--                    <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>-->
        <!--                    <span style="vertical-align: middle;">Submit Order</span>-->
        <!--                </a>-->
      </div>
      <div class="cards-list">
        <div class="card-item" @click="openRouteWithHash('open')">
          <div class="header-item">
            Ordered
            <a href>
              <i class="bx bx-link-external"></i>
            </a>
          </div>
          <div class="body-item">
            <div class="list-values clickable">
              <div class="list-values-header">
                <span class="numb">{{ gettersOpenOrders.length }}</span> New Orders
              </div>
            </div>
          </div>
        </div>
        <div class="card-item" @click="openRouteWithHash('processing')">
          <div class="header-item">
            Processing
            <a href>
              <i class="bx bx-package text-success"></i>
            </a>
          </div>
          <div class="body-item">
            <div class="list-values clickable">
              <div class="list-values-header">
                <span class="numb">{{ gettersProcessingOrders.length }}</span> Processed Orders
              </div>
            </div>
          </div>
        </div>
        <div class="card-item" @click="openRouteWithHash('parts-shipped')">
          <div class="header-item">
            Shipped
            <i class="bx bxs-truck text-primary clickable" style="color: #42b867 !important;"></i>
          </div>
          <div class="body-item">
            <div class="list-values clickable">
              <div class="list-values-header">
                <span class="numb"> {{ gettersPartsShippedOrders.length }} </span> Orders Shipped
              </div>
            </div>
          </div>
        </div>
        <div class="card-item" @click="openRouteWithHash('parts-delivered')">
          <div class="header-item">
            Delivered
            <i class="bx bx-buildings clickable"></i>
          </div>
          <div class="body-item">
            <div class="list-values clickable">
              <div class="list-values-header">
                <span class="numb"> {{ gettersPartsDeliveredOrders.length }} </span> Parts Order
                Delivered
              </div>
            </div>
          </div>
        </div>
      </div>
      <h4 class="title-text chart-name">
        Parts Orders - <small>{{ this.showChartBy.label }}</small>

        <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <i class="bx bx-chevron-down text-black"></i>
          </template>
          <b-dropdown-item @click="showByMonth">
            by Month
          </b-dropdown-item>
          <b-dropdown-item @click="showByWeek">
            by Week
          </b-dropdown-item>
        </b-dropdown>
      </h4>
      <div id="chart-area"></div>
    </div>
  </div>
</template>

<style scoped>
@import url(../../../../styles/dashboard/style.css);

.chart-name {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.chart-name i {
  font-size: 32px;
  cursor: pointer;
}

.chart-name small {
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}
</style>

<script>
import chart from "tui-chart";
import "tui-chart/dist/tui-chart.css";
import {mapActions, mapGetters} from "vuex";
import Axios from 'axios';
import accounting from "accounting";
import _ from "lodash";
import SearchFilter from '../../../../components/utility/search-filter/search-filter'

export default {
  name: "PartsOrders",
  props: {
    width: String,
    height: String,
    seriesColor: {
      type: String,
      default: "#FFCFB1"
    }
  },
  components: {
    SearchFilter
  },
  mounted() {
    // this is bad

    const menuBtn = document.querySelector(".nav-link.toggle-button");
    if (menuBtn) {
      menuBtn.addEventListener("click", () => {
        this.resizeChart();
      });
    }

    window.addEventListener("orientationchange", () => {
      this.resizeChart();
    });
    this.getChartData();
  },
  data() {
    return {
      widthInputSearch: 350,
      space: 16,
      ordersChart: null,
      showChartBy: {
        type: 'month',
        label: 'by Month'
      },
      searchFilter: '',
      filter: '',
      chartData: {
        month: {
          credits: {},
          categories: [],
          series: [
            {
              name: "Orders",
              data: []
            }
          ]
        },
        week: {
          credits: {},
          categories: [],
          series: [
            {
              name: "Orders",
              data: []
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isStartedReinstate: 'isStartedReinstate',
      gettersOpenOrders: 'supplier/order/getOpenOrders',
      gettersProcessingOrders: 'supplier/order/getProcessingOrders',
      gettersPartsShippedOrders: 'supplier/order/getPartsShippedOrders',
      gettersPartsDeliveredOrders: 'supplier/order/getPartsDeliveredOrders',
      getterSearchFilterObj: 'supplier/order/getSearchFilterObj',
      gettersAllOrders: 'supplier/order/getAllOrders',
    }),
    isWidthInputSearch(){
      if(this.searchFilter.length * 8 + 75 > 350 && this.searchFilter.length * 8 + 75 < 600){
        this.widthInputSearch = this.searchFilter.length * 8 + 75
      }
      if(this.searchFilter.length * 8 + 75 > 600){
        this.widthInputSearch = 600
      }
      if(this.searchFilter.length * 8 + 75 < 350){
        this.widthInputSearch = 350
      }
    },
    searchFilterObj() {
      return this.getterSearchFilterObj
    },
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllOrders, (r) => {
        if (_.includes(this.searchFilter, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter.split(' -')?.[0]))) && _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {
        return Number(itm.repairerMainRfqId)
      })

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label += ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    computedSearchFilters() {
      let partNumbers = {}
      let customers = {}
      let backOrderCustomers = {}
      let repairerOrderNumbers = {}
      let invoiceNbrs = {}

      this.gettersAllOrders.forEach(order => {
        if (order.partsNumbers) {
          let numbers = order.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
          })
        }

      })

      this.gettersAllOrders.forEach(order => {
        if (order.customerName) {
          customers[order.customerName.trim().toLowerCase()] = {label: order.customerName.trim()}
        }
      })

      this.gettersAllOrders.forEach(order => {
        if (order.customerName && order.isBackOrderAcceptances) {
          backOrderCustomers[order.customerName.trim().toLowerCase()] = {label: order.customerName.trim()}
        }
      })

      this.gettersAllOrders.forEach(order => {
        if (order.number) {
          repairerOrderNumbers[order.number.trim().toLowerCase()] = {label: order.number.trim()}
        }
      })

      this.gettersAllOrders.forEach(order => {
        if (order.invoiceNumber) {
          invoiceNbrs[order.invoiceNumber.trim().toLowerCase()] = {label: order.invoiceNumber.trim()}
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      backOrderCustomers = _.orderBy(Object.values(backOrderCustomers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      backOrderCustomers.unshift({label: 'All Customers'})
      repairerOrderNumbers = _.orderBy(Object.values(repairerOrderNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      invoiceNbrs = _.orderBy(Object.values(invoiceNbrs), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      // // Create date template
      const date = [
        {
          id: 'date',
          label: 'Date',
          resultObjectOnly: true,
          type: 'Date',
          iconClass: 'bx-calendar'
        }
      ]

      let partStockStatuses = [
        {label: 'None'},
        {label: 'In Stock'},
        {label: 'Low Stock'},
        {label: 'No Stock'},
        {label: 'On Back Order'},
        {label: 'No Longer Avail'},
      ]

      let partRcvdStatuses = [
        {label: 'Received'},
        {label: 'Incorrect Part Delivered'},
        {label: 'Incorrect Part Ordered'},
        {label: 'Damaged Part in Transport'},
        {label: 'Damaged Part EX Warehouse'},
        {label: 'No Longer Needed'},
        {label: 'Change in Repair Method'},
        {label: 'Cancelled Part'},
        {label: 'Not Received'},
        {label: 'Partial Delivery'},
        {label: 'Order Completed'},
      ]

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'customer',
          label: 'Customer',
          iconClass: 'bx-user',
          options: [
            customers,
          ]
        },
        {
          id: 'number',
          label: 'Repairer Order Nbr',
          iconClass: 'bx-user',
          options: [
            repairerOrderNumbers
          ]
        },
        {
          id: 'invoiceNumber',
          label: 'Invoice Nbr',
          iconClass: 'bx-user',
          options: [
            invoiceNbrs
          ]
        },
        {
          id: 'backOrderCustomer',
          label: 'Back Order Acceptances',
          iconClass: 'bx-user',
          options: [
            backOrderCustomers
          ]
        },
        {
          id: 'partStockStatus',
          label: 'Part Stock Status',
          iconClass: 'bx-user',
          options: [
            partStockStatuses,
          ]
        },
        {
          id: 'partRcvdStatus',
          label: 'Part Rcvd Status',
          iconClass: 'bx-user',
          options: [
            partRcvdStatuses,
          ]
        },
        {
          id: 'dateRange',
          label: 'Date Range',
          iconClass: 'bx-store',
          options: [
            []
          ]
        }
      ]
    },
  },
  methods: {
    ...mapActions({
      setFilter: 'supplier/order/setFilter'
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      this.$router.push({name: 'RefView', params: {rfqId: itm.value, number: itm.number}})
    },
    onEnter() {
      setTimeout(() => {
        let item = this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter)) {
          this.setFilter('')
          this.$router.push({name: 'RefView', params: {rfqId: item.value, number: item.number}})
        } else {
          this.$router.push({name: 'SuppliersPartsOrders'})
        }
      }, 500)
    },
    onSearchFilterChange(filters) {
      console.log(filters, 'filters')
      this.$store.commit('supplier/order/setSearchFilterObj', filters)
      if (filters && filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
        // this.filter = filters.search;
      } else {
        this.setFilter('')
        // this.filter = '';
      }
    },
    openRouteWithHash(hash) {
      this.$router.push({name: 'SuppliersPartsOrders', hash: '#' + hash})
    },
    setShowChartBy(type) {
      let result = {
        type: 'month',
        label: 'by Month'
      };
      if (type === 'week' && !_.isEmpty(this.chartData.week.categories)) {
        result = {
          type: 'week',
          label: 'by Week'
        };
      }
      this.createChart(result.type);
      this.showChartBy = result;
    },
    setChartDataByKey(chartData = {}, chartKey = '') {
      let categories = [],
          data = [];
      _.forEach(chartData[chartKey].totalSales, (value, key) => {
        categories.push(key);
        data.push(value);
      });
      this.chartData[chartKey].credits = chartData[chartKey].credits;
      this.chartData[chartKey].categories = categories;
      this.chartData[chartKey].series[0].data = data;
    },
    clearChartData() {
      if (!this.$refs.container) return;
      setTimeout(() => {
        this.ordersChart.destroy();
      }, 100);
    },
    getChartData() {
      NProgress.start();
      Axios.get('/ir/supplier/dashboard').then(response => {
        console.log('response.data', response.data);
        this.setChartDataByKey(response.data, 'month');
        this.setChartDataByKey(response.data, 'week');
        this.createChart();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        NProgress.done();
      });
    },
    resizeChart() {
      if (!this.$refs.container) return;
      setTimeout(() => {
        this.ordersChart.resize({
          width: this.$refs.container.clientWidth - this.space,
          height: this.height
        });
      }, 100);
    },
    createChart(type = 'month') {
      const container = document.getElementById("chart-area");
      let data = (type === 'month') ? this.chartData.month : this.chartData.week;

      let credits = _.map(data.credits, (item, key) => {
        return {
          date: key,
          value: item
        }
      });

      const options = {
        chartExportMenu: {
          visible: false
        },
        tooltip: {
          offsetX: 1,
          offsetY: -15,
          template: (category, item) => {
            let creditData = _.find(credits, credit => {
              return credit.date === category
            });

            let head =
                    '<div class="tt-title">' + category + "</div>",
                body =
                    `<div  class="tt-body">
                                    <ul>
                                    <li><span style="white-space: nowrap; font-weight: bold;">Orders Placed &nbsp;</span><span class="tt-value">` +
                    accounting.formatMoney(item.value) +
                    `</span></li>
                                    <li><span style="white-space: nowrap; font-weight: bold;">Credits Raised &nbsp;</span><span class="tt-value">` +
                    accounting.formatMoney((!_.isEmpty(creditData)) ? creditData.value : 0)
                    + `</span><li>
                                    </ul>
                                    </div>`;
            return (
                '<div class="tooltip-template">' +
                head +
                body +
                '<div class="point"></div></div>'
            );
          }
        },
        chart: {
          width: this.$refs.container.clientWidth - this.space,
          height: this.height || 304,
          format: "1,000"
        },
        yAxis: {
          title: "",
          prefix: "$",
          min: 0,
          max: data.maxY,
        },
        xAxis: {
          title: "&nbsp;",
          pointOnColumn: true
        },
        legend: {
          visible: false
        },
        series: {
          barWidth: 30
        }
      };
      const theme = {
        chart: {
          fontFamily: "Inter",
          fontSize: "16px"
        },
        series: {
          colors: ["#FFCFB1"],
          width: "50px"
        },
        xAxis: {
          tickColor: "rgba(27,30,56,0.1)",
          label: {
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: "bold",
            color: "#1C1F39"
          }
        },
        yAxis: {
          tickColor: "rgba(27,30,56,0.1)",
          label: {
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: "bold",
            color: "#1C1F39"
          }
        },
        plot: {
          lineColor: "rgba(27,30,56,0.1)",
          background: "#efefef"
        }
      };

      chart.registerTheme("myTheme", theme);
      options.theme = "myTheme";

      container.innerHTML = '';
      this.ordersChart = chart.columnChart(container, data, options);

      window.addEventListener("resize", () => {
        this.resizeChart();
      });
    },
    showByMonth() {
      this.setShowChartBy('month');
    },
    showByWeek() {
      this.setShowChartBy('week');
    },
  },
  watch: {
    isStartedReinstate: {
      handler(val) {
        if (val) {
          // this.clearChartData()
        } else {
          this.getChartData()
        }
      },
      immediate: true,
    },
  }
};
</script>
