import {
    SUPPLIER_MARK_MESSAGE_AS_READ,
    SUPPLIER_SET_MESSAGES,
    SUPPLIER_UPDATE_MESSAGES,
    SUPPLIER_DASHBOARD_REINSTALLATION,
    SUPPLIER_UPDATE_ACTIVITY,
    SUPPLIER_SET_ACTIVITY,
    SUPPLIER_UPDATE_FILES
} from "../../../mutation-types";
import _ from "lodash";

export default {
    [SUPPLIER_UPDATE_FILES] (state, ui) {
        state.filesUpdater = ui
    },
    [SUPPLIER_UPDATE_ACTIVITY] (state, ui) {
        let activity = ui.activity

        if (ui.type == 'newActivityRecord') {
            let index = _.findIndex(state.activityList, (a) => {
                return Number(a.id) === Number(activity.id)
            })
            if (index === -1) {
                state.activityList.push(activity)
            }
        } else if (ui.type == 'updateActivityRecord') {
            let index = _.findIndex(state.activityList, (a) => {
                return Number(a.id) === Number(ui?.id?.id)
            })
            if (index != -1) {
                state.activityList.splice(index, 1, ui?.id)
            }
        } else if (ui.type == 'removeActivityRecord') {
            let index = _.findIndex(state.activityList, (a) => {
                return Number(a.id) === Number(ui.id)
            })
            if (index != -1) {
                state.activityList.splice(index, 1)
            }
        }
    },
    [SUPPLIER_SET_ACTIVITY] (state, ui) {
        state.activityList = ui
    },
    [SUPPLIER_SET_MESSAGES] (state, ui) {
        state.messages = ui
    },
    [SUPPLIER_DASHBOARD_REINSTALLATION] (state) {
        state.messages = []
    },
    [SUPPLIER_UPDATE_MESSAGES] (state, ui) {
        let index = _.findIndex(state.messages, (m) => {
            return (m.rfqToSupplierId &&  ui.rfqToSupplierId && Number(m.rfqToSupplierId) === Number(ui.rfqToSupplierId)) ||
                (m.orderId &&  ui.orderId && Number(m.orderId) === Number(ui.orderId)) ||
                (m.creditId &&  ui.creditId && Number(m.creditId) === Number(ui.creditId))
        })
        if (index !== -1) {
            state.messages.splice(index, 1)
        }
        state.messages.push(ui)

    },
    [SUPPLIER_MARK_MESSAGE_AS_READ](state, ui) {
        let index = _.findIndex(state.messages, (m) => {
            return Number(m.id) === Number(ui)
        })
        if (index !== -1) {
            state.messages[index].viewedOn = '9999'
        }
    },
}
