<template>
  <div class="d-flex justify-content-between px-15 additional-info-wrapper">
    <div class="d-flex align-items-center pr-5">
      <div class="additional-info vin-block pr-3">
        <span class="label pr-1">VIN:</span>
        <span> {{ vin }} </span>
      </div>
      <div class="additional-info build-date" style="margin-right: 50px;">
        <span class="label pr-1">Build Date:</span>
        <span> {{ buildDate }} </span>
      </div>
      <div v-if="paintCode != null && paintCode != false && isReapirRFQStatus !== 'Submitted' && isReapirRFQStatus !== 'Received'" class="additional-info d-flex align-items-center pr-5">
        <div class="w-100 pr-3">
          <span class="label">Paint Code</span>
        </div>
        <div class="">
          <span class="quote-field">{{paintCode}}</span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center additional-info">
      <div class="w-100 pr-3">
        <div class="">
          <span class="label" style="margin-bottom: 15px;">Quoting Package Nbr</span>
        </div>
      </div>
      <div>
        <div class="quote-field"> {{ quoteNumber }} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "additional-info",
  props: {
    vin: {
      type: String,
      default: '',
    },
    buildDate: {
      type: String,
      default: '',
    },
    quoteNumber: {
      type: String,
      default: '',
    },
    paintCode: {
      type: String||Boolean,
      default: null
    },
    isReapirRFQStatus: {
      type: String,
      default: '',
    }
  }
}
</script>

<style scoped>
.additional-info-wrapper {
  margin-top: 15px;
  font-weight: bold;
}

.quote-field {
  text-align: left;
  display: inline-block;
  border: 1px solid rgba(27, 30, 56, 0.25);
  min-height: 30px;
  border-radius: 4px;
  padding: 5px;
  min-width: 165px
}
.vin-block {
  word-wrap: break-word;
  margin-top: 0px;
  width: 220px;
}
.additional-info span {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .additional-info {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1100px) {
  .vin-block {
    width: 170px;
  }
}

@media screen and (max-width: 1100px) {
  .vin-block {
    width: 170px;
  }
}

@media screen and (max-width: 1028px) {
  .additional-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .vin-block span,
  .build-date span {
    padding-bottom: 7px;
  }
}

@media screen and (max-width: 991px) {
  .quote-block-wrapper {
    justify-content: start;
    padding-top: 10px !important;
  }

  .quote-block-wrapper .quote-block {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .additional-info {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 475px) {
  .additional-info-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .additional-info-wrapper div {
    flex-direction: column;
    align-items: flex-start !important;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 650px) {
  .additional-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .additional-info-wrapper {
    overflow-x: scroll;
    padding-bottom: 10px;
  }
}
</style>
