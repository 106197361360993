<template>
  <div class="row no-gutters text-center notifications-table">
    <div class="notf-icon">
      <i class='bx bx-link-external'
         :id="`ext-notification-${this.rfq.id}`"
         :class="{'theme-color': externalNotificationActive, 'aqua-icon': items.item.status  === 'New' && !items.item.wasRead}"
      ></i>
      <b-tooltip
          v-if="externalNotificationActive"
          placement="bottomleft"
          custom-class="ps-tooltip ps-tooltip--table"
          :target="`ext-notification-${this.rfq.id}`">
        Supplier {{ rfq.notification.supplier }} has requested {{ rfq.notification.time | formatExtension }} extension.
      </b-tooltip>
    </div>
    <div class="notf-icon">
      <i
          v-if="items.item.pricesUpdateRequest === 'UpdateRequested'"
          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Request for Update ' + formatDate(items.item.pricesUpdateRequestedOn), placement:'leftbottom'}"
          class='bx bx-dollar orange-icon'>
      </i>
      <i
          v-else-if="
          items.item.rfqUpdateRequested === 'UpdateRequested' &&
          (((items.item.status === 'Priced' || items.item.status === 'New') && isBiddersBlinking) || (items.item.status !== 'Priced' && items.item.status !== 'New'))"
          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'RFQ update requested' + getCustomerName(items.item.customerName), placement:'leftbottom'}"
          class='bx bx-dollar orange-icon orange-icon-blinker'>
      </i>
      <i
          v-else-if="items.item.pricesUpdateRequest === 'UpdateReceived'"
          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Supplier Updated Quote', placement:'leftbottom'}"
          class='green-icon green-icon-blinker bx bx-dollar'>
      </i>
      <i
          v-else-if="items.item.rfqUpdateRequested  === 'UpdatedPricesSent'"
          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: items.item.rfqUpdateSentOn ? 'RFQ Update ' + formatDate(items.item.rfqUpdateSentOn) : 'RFQ Update', placement:'leftbottom'}"
          class='green-icon bx bx-dollar'>
      </i>
      <i
          v-else-if="items.item.status === 'Priced' && isUpdatedPriced && isBiddersBlinking"
          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTextForUpdatedBidder + 'updated', placement:'leftbottom'}"
          class='green-icon green-icon-blinker bx bx-dollar'>
      </i>
      <i
          v-else-if="items.item.status === 'Priced' && isRequestedUpdate && isBiddersBlinking"
          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'Pricing / Stock update requested', placement:'leftbottom'}"
          class='orange-icon-blinker orange-icon bx bx-dollar'>
      </i>
      <i v-else class='bx bx-dollar'></i>
    </div>
    <div class="notf-icon">
      <i class='bx bx-flag' :class="{
          'orange-icon-blinker orange-icon' : (items.item.status === 'Submitted' || items.item.status === 'New') && items.item.isSentAsDirectOrder,
          'red-icon-blinker red-icon' : items.item.isSentAsDirectOrder && declinedBidder,
          'green-icon': items.item.status === 'Ordered' && items.item.isSentAsDirectOrder,
         }"
         v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForFlagIcon(), placement:'leftbottom'}"
      ></i>
    </div>
    <div class="notf-icon">
      <i :class="{
          'aqua-icon': items.item.scheduleTime,
          'orange-icon-blinker orange-icon' : isBlinkingOrangeIcon,
          'bx bx-copy-alt': !isUserTypeSupplier,
          'bx bx-timer supplier-timer-icon': isUserTypeSupplier,
          'orange-icon': !isUserTypeSupplier && (items.item.isAlternate || items.item.isSupplement)
         }"
         :style="{fontSize: isUserTypeSupplier ? '23px' : '20px'}"
         v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForTimer(), placement:'leftbottom'}"
      ></i>
    </div>
    <div class="notf-icon">
      <i
          v-if="(isUserTypeSupplier && isHasOEMGEN) || !isUserTypeSupplier"
          class="bx bx-message-rounded-dots"
          style="font-size: 20px"
          :class="{
            'repairer-message-icon-blinking': !isUserTypeSupplier && isBiddersBlinking && items.item.hasUnreadMessages,
            'supplier-message-icon-blinking': isUserTypeSupplier && isBiddersBlinking && items.item.hasUnreadMessages,
          }"
          :style="{
              paddingLeft: isUserTypeSupplier ? '3px' : '0px',
              paddingTop: isUserTypeSupplier ? '1px' : '0px',
      }"
      ></i>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import * as dayjs from "dayjs";
import {mapGetters} from "vuex";

export default {
  name: 'notification-on-table',
  props: {
    items: {
      type: Object,
      default () {
        return {
          item: {
            id: null,
            pricesUpdateRequest: '',
            pricesUpdateRequestedOn: '',
            rfqUpdateSentOn: '',
            customerName: '',
            notification: {
              time: '',
              supplier: '',
            }
          },
        }
      }
    },
    dueDate10Min: {
      type: Boolean,
      default: false,
    },
    isBlinkingOrangeIcon: {
      type: Boolean,
      default: false,
    },
    isBiddersBlinking: {
      type: Boolean,
      default: false,
    },
    dueDate20Min: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {}
  },
  filters: {
    formatExtension (time) {
      time = Number(time)
      switch (time) {
        case 30:
          return `30 mins`
          break
        case 60:
          return `1 hour`
          break
        case 90:
          return `1.5 hour`
          break
        case 120:
          return `2 hour`
          break
        default:
          return time
      }
    }
  },
  methods: {
    getTooltipForTimer() {
      if (this.isUserTypeSupplier) {
        if (this.items.item.scheduleTime) {
          return 'Scheduled  Submission' + '<br/>' + this.formatDate(this.items.item.scheduleTime)
        }
      } else {
        if (this.items.item.isAlternate) {
          return 'Alternate RFQ'
        } else if (this.items.item.isSupplement) {
          return 'Supplement RFQ'
        }
      }
    },
    getTooltipForFlagIcon() {
      if (this.items.item.status === 'Submitted' && this.items.item.isSentAsDirectOrder && !this.declinedBidder) {
        return 'Supplier notified. Waiting on Order confirmation'
      } else if (this.items.item.status === 'Ordered' && this.items.item.isSentAsDirectOrder) {
        return 'Direct order has been processed'
      } else if (this.declinedBidder) {
        return this.declinedBidder?.supplier + ' has declined the DIRECT ORDER request. <br/>' + (this.declinedBidder.declineReason !='') ? this.declinedBidder.declineReason :''
      }
      return ''
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return dayjs(date).format('DD/MM/YYYY - hh:mmA')
    },
    getCustomerName(name) {
      if (name) {
        return ' - ' + name;
      }
      return  '';
    },
  },
  computed: {
    ...mapGetters({
      isHasOEMGEN: 'currentCompany/isHasOEMGEN',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
    }),
    rfq () {
      return this.items.item
    },
    declinedBidder() {
      let bidders = _.filter(this.rfq?.bidders, {'status': 'Declined'})
      let bidder = bidders?.at(0)
      return bidder
    },
    isUpdatedPriced() {
      let bidders = _.filter(this.rfq?.bidders, (b) => {
        return b.status === 'Priced' && b.isUpdatedPrices
      })
      return !_.isEmpty(bidders)
    },
    getTextForUpdatedBidder() {
      let text = 'Pricing & Stock has been '
      let bidders = _.filter(this.rfq?.bidders, (b) => {
        return b.status === 'Priced' && b.isUpdatedPrices
      })
      if (!_.isEmpty(bidders)) {
        _.forEach(bidders, (b, ind) => {
          if (ind + 1 == bidders.length) {
            text = bidders[0].supplier + ' - '
          } else {
            text = bidders[0].supplier + ', '
          }
        })
      }
      return text
    },
    isRequestedUpdate() {
      let bidders = _.filter(this.rfq?.bidders, (b) => {
        return b.status === 'Priced' && b.isSentUpdateRequested
      })
      return !_.isEmpty(bidders)
    },
    externalNotificationActive () {
      // return true; // un-comment for testing
      return this.rfq.notification && this.rfq.notification.time
    }
  },
  watch: {
    dueDate10Min: {
      handler: function (val, old) {
        if (old && !val) {
          this.$emit('updateTable')
        }
      },
      immediate: true
    },
    dueDate20Min: {
      handler: function (val, old) {
        // console.log(old, val)
        // this.$emit('updateTable')
      },
      immediate: true
    },
  }

}
</script>

<style>
.supplier-timer-icon {
  transform: translateY(-2px) !important;
}
.supplier-message-icon-blinking {
  animation: blinker 1s linear infinite;
  color: #29bbc1 !important;
}

.repairer-message-icon-blinking {
  animation: blinker 1s linear infinite;
  color: #79B1BB !important;
}
.orange-icon {
  color: #f0710f !important;
}
.orange-icon-blinker,
.red-icon-blinker {
  animation: blinker 1s linear infinite;
}
.green-icon {
  color: green !important;
}
.green-icon-blinker {
  animation: blinker 1s linear infinite;
}
.aqua-icon {
  color: #29bbc1 !important;
}
@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.red-icon {
  color: red !important;
}
</style>
