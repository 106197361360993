import { render, staticRenderFns } from "./fill-businnes-details.vue?vue&type=template&id=3950c136&scoped=true"
import script from "./fill-businnes-details.vue?vue&type=script&lang=js"
export * from "./fill-businnes-details.vue?vue&type=script&lang=js"
import style0 from "./fill-businnes-details.vue?vue&type=style&index=0&id=3950c136&prod&lang=css"
import style1 from "./fill-businnes-details.vue?vue&type=style&index=1&id=3950c136&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3950c136",
  null
  
)

export default component.exports