<script>
    /* eslint-disable */
    import {mapState} from 'vuex';

    export default {
        name: 'part-list',
        props: [],
        data: function () {
            return {
                searchCategory: '',
                searchPartName: '',
                selectedCategory: 1,
                selectedPartName: {},
            };
        },
        methods: {
            selectCategory: function (cat) {
                this.selectedCategory = cat.id;
            },
            addPart: function (data) {
                this.selectedPartName = data.id;
                let part = _.clone(data);
                part.listId = part.id;
                part.id = null;
                this.$emit('addPart', part);
                return;
            },
        },
        computed: {
            ...mapState({
                stateParts: state => state.partList.items,
                stateCategories: state => state.partList.categories,
            }),
            partList: function () {
                try {
                    if (this.searchPartName == '') {
                        return this.stateParts[this.selectedCategory];
                    }
                    let search = this.searchPartName;
                    let parts = this.stateParts[this.selectedCategory];
                    return _.filter(parts, function (i) {
                        return i.name.match(new RegExp(search, "i"));
                    });
                } catch (erro) {
                    return [];
                }
            },
            partCategories: function () {
                try {
                    let arr = [];
                    _.forIn(this.partCategoriesObj, function (value, key) {
                        arr.push({id: key, name: value});
                    });
                    return arr;
                } catch (e) {
                    return [];
                }
            },
            partCategoriesObj: function () {
                try {

                    return this.stateCategories;

                } catch (e) {
                    return [];
                }
            },
        },
    };
</script>
<template>
    <div class="row part-list">
        <div class="col" style="padding-left: 0px;padding-right: 0px;">
            <div class="box" style="margin-right: 10px;">
                <ul class="nav nav-4 vh-height-75">
                    <li :class="{'nav-item': true, 'active': selectedCategory == cat.id}" v-bind:key="'partCategory'+cat.id" v-for="cat in partCategories ">
                        <a class="nav-link clickable" @click.prevent="selectCategory(cat)">{{cat.name}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-8" style="padding-left: 0px!important;  padding-right: 0px!important;">
            <div class="box bg-grey" style="margin-right: 10px;">
                <ul class="nav nav-4 vh-height-75" style="display:block;">
                    <li class="nav-item">
                        <div>
                            <input v-model="searchPartName"
                                   type="text"
                                   class="nav-link search-input"
                                   style="height: 3rem;font-weight:normal; border:none !important;"
                                   :placeholder="'Search'"
                            >

                        </div>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link new-part-item clickable" @click.prevent="addPart({id:null, name:''})">
                            <i class="ti-plus"></i>
                            New Item
                        </a>
                    </li>
                    <li :class="{'nav-item': true, 'active': selectedPartName == part.id}" v-bind:key="'partList'+part.id" v-for="part in partList">
                        <a class="nav-link clickable" @click.prevent="addPart(part)">{{part.name}}
                            <div class="float-right"><i class="fa fa-angle-right"></i></div>
                        </a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
