<template>
    <div class="suppliers-suppliers-reports-page">
        <div class="page-header">
            <h4>Reports</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link :to="{ name: 'RepairerDashboard'}">Home</router-link>
                </li>
                <li v-if="isVisibleOrderSummary" class="breadcrumb-item" :class="{'active': !isVisibleOrderSummary}"
                    @click="isVisibleOrderSummary = !isVisibleOrderSummary"
                    
                >
                    View Reports
                </li>
                <li v-else class="breadcrumb-item" :class="{'active': !isVisibleOrderSummary}">
                    View Reports
                </li>
                <li v-if="isVisibleOrderSummary" class="breadcrumb-item active">
                    Order Summary
                </li>
            </ol>
        </div>

        <div class="suppliers-reports-body">
            <div class="report-list-block bg-white">
                <button v-if="!isVisibleOrderSummary" class="btn btn-primary btn-report" @click="isVisibleOrderSummary = !isVisibleOrderSummary">Order Summary</button>
            </div>
        </div>
        <div>
            <supplier-order-summary v-if="isVisibleOrderSummary"></supplier-order-summary>
        </div>
    </div>
</template>

<script>
import SupplierOrderSummary from './charts/supplier-order-summary';

export default {
    name: "reports",
    components: {
        SupplierOrderSummary,
    },
    data() {
        return {
            isVisibleOrderSummary: false,
        }
    },
};
</script>

<style scoped>
.breadcrumb-item {
    cursor: pointer;
}

.active {
    cursor: default;
}

.report-list-block {
    padding: 15px;
}

.btn-report {
    width: 300px;
}
</style>
