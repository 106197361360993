/*eslint-disable */
import _ from 'lodash';

export function getPreferedSuppliers(state) {
    let suppliers = _.filter(state.suppliers.items, function (i) {
        return i.supplierGroup === 'Prefered'
    });
    return _.map(suppliers, itm => _.extend({isPrefered: itm.supplierGroup == 'Prefered'}, itm));
}

export function getPreferedSuppliersFilteredByCompanyState(state, getters, rootState, rootGetters) {
    return _.filter(rootGetters['repairer/supplier/getPreferedSuppliers'], (itm) => {
        return itm.state === rootGetters['currentCompany/getDetails'].address.state;
    })
}

export function getSpecialistSuppliers(state) {
    return [];
}

export function getOtherSuppliers(state) {
    let suppliers = _.filter(state.suppliers.items, function (i) {
        return i.supplierGroup === 'Other'
    });
    return _.map(suppliers, itm => _.extend({isPrefered: itm.supplierGroup == 'Prefered'}, itm));
}

export function getOtherSuppliersFilteredByCompanyState(state, getters, rootState, rootGetters) {
    //console.log(rootGetters, 'getters')
    return _.filter(rootGetters['repairer/supplier/getOtherSuppliers'], (itm) => {
        return itm.state === rootGetters['currentCompany/getDetails'].address.state;
    })
}

export function getFullSuppliers(state) {
    return _.map(state.suppliers.items, itm => _.extend({isPrefered: itm.supplierGroup == 'Prefered'}, itm));
}

export function getTier1(state) {
    return state.tier1
}

export function getInsurers(state) {
    return state.insurers
}


export function getPerPage(state) {
    return state.perPage;
}

export function getPerPage2(state) {
    return state.perPage2;
}

export function getFilter(state) {
    return state.filter;
}

export function getSuppliersAllCurrentPage(state) {
    return state.suppliersAllCurrentPage;
}

export function getSuppliersPreferedCurrentPage(state) {
    return state.suppliersPreferedCurrentPage;
}

export function getSuppliersTierCurrentPage(state) {
    return state.suppliersTierCurrentPage;
}

//export function getSupplierById(state, supplier_id,q,w){
//    console.log('getSupplierById')
//    console.log(state)
//    console.log(supplier_id)
//    console.log(q)
//    console.log(w)
//
//    let supplier = _.find(state.suppliers.itemsFull, { 'id': supplier_id });
//    if (supplier){
//       return supplier;
//    }
//    return null;
//}
