
export function getMessages (state) {
    return state.messages
}

export function getActivityList (state) {
    return state.activityList
}

export function getFiles (state) {
    return state.filesUpdater
}
