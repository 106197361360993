<template>
  <div class="a-b" style="height: 100%">
    <div v-if="banner.mediaType && banner.mediaType == 'youtube' && banner.type == 1" class="video-b-promo-block" :class="{'b-promo-video-display-none': !isBannerActiveSize}"
         style="width: 300px">
      <a class="video-b-promo" href="#" target="_blank">
        <youtube :video-id="banner.src" ref="youtube" :player-vars="playerVars" @ready="readyPlayers"
                 :width="computedVideoWidth" :height="computedVideoHeight"></youtube>
      </a>
    </div>
    <div v-else-if="banner.mediaType && banner.mediaType == 'image' && banner.type == 1" class="medium-b-promo-block"
         style="width:300px">
      <a @click.prevent="clickOnBanner" href="#" target="_blank">
        <img :src="banner.src" alt="" style="width: 300px">
      </a>
    </div>
    <div v-else-if="banner.mediaType && banner.mediaType == 'image' && banner.type == 2"
         class="skysrapper-b-promo-block" style="width: 300px">
      <a @click.prevent="clickOnBanner" href="#" target="_blank">
        <img :src="banner.src" alt="" style="width: 300px">
      </a>
    </div>
    <div v-else-if="banner.mediaType && banner.mediaType == 'image' && banner.type == 3"
         class="skysrapper-b-promo-block" style="width: 300px">
      <a @click.prevent="clickOnBanner" href="#" target="_blank">
        <img :src="banner.src" alt="">
      </a>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import Axios from 'axios'
export default {
  name: 'advert-banner',
  props: {
    advertMaxWidth: {
      type: Number,
      default () {
        return 300
      }
    },
    bannerType: {
      type: String,
      default () {
        return '1'
      }
    },
    rfqId: {
      type: String,
      default () {
        return ''
      }
    },
    pageType: {
      type: String,
      default () {
        return 'rfq-view'
      }
    },
    rfqStatus: {
      type: String,
      default () {
        return ''
      }
    },
    orderedSuppliers: {
      type: Number,
      default () {
        return 0
      }
    },
    childComponentWidth: {
      type: Number,
      default () {
        return 0
      }
    },
  },
  data () {
    return {
      resizeVideoWidth: 640,
      resizeVideoHeight: 360,
      isBannerActiveSize: false,
      banner: {
        link: '',
        src: '',
        type: '',
        mediaType: '',
      },
      playerVars: {
        //autoplay: 1,
        loop: 1,
        modestbranding: 1,
      }
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    computedVideoWidth () {
      this.isBannerActiveSize = false
      console.log('Child Component Width = ' + this.childComponentWidth)
      console.log('Width page - Child Component Width = ' + (document.documentElement.clientWidth - (this.childComponentWidth + 270)))
      if (this.pageType === 'rfq-order') {
        return this.advertMaxWidth
      } else {
        if (this.rfqStatus == 'Priced' && ((document.documentElement.clientWidth - (this.childComponentWidth + 515)) > 300)) {
          this.isBannerActiveSize = true
          return document.documentElement.clientWidth - (this.childComponentWidth + 515)
        } else if (this.rfqStatus == 'Received' && ((document.documentElement.clientWidth - (this.childComponentWidth + 290)) > 300)) {
          this.isBannerActiveSize = true
          return document.documentElement.clientWidth - (this.childComponentWidth + 290)
        } else if (this.rfqStatus == 'Submitted' && ((document.documentElement.clientWidth - (this.childComponentWidth + 270)) > 300)) {
          this.isBannerActiveSize = true
          return document.documentElement.clientWidth - (this.childComponentWidth + 290)
        }
      }
    },
    computedVideoHeight () {
      console.log('----------isRFQPriced = ' + this.rfqStatus)
      console.log ('computedVideoHeight', this.computedVideoWidth / 1.78)
      return this.computedVideoWidth / 1.78
    }
  },
  methods: {
    readyPlayers () {
      console.log('ready players')
      this.player.mute()
      this.player.setLoop(true)
      this.player.playVideo()
    },
    loadBanner () {
      Axios.get('/adv/b?type=' + this.bannerType + '&rfqId=' + this.rfqId)
          .then(response => {
            if (response.data._status && response.data.data.length > 0) {
              this.banner = response.data.data[0]
              // this.banner = banners[0]
            }
          })
    },
    clickOnBanner () {
      if (this.banner.link) {
        window.open(this.banner.link, '_blank').focus()
      }
    },
  },
  mounted () {
    this.loadBanner()
        //let width = 0

  },
  components: {}
}
</script>

<style>
.a-b iframe {
  position: sticky;
  top: 150px;
}

.b-promo-video-display-none {
  display: none !important;
}
</style>
