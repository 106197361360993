<template>
  <div class="dashboard-page">
    <div class="page-header" style="min-height: auto">
      <h4 style="margin-bottom: 0px">Dashboard</h4>
    </div>
    <div class="widget-panel-flex">
      <div class="widget-panel-left">
        <div v-if="!isMobile()" class="widget-panel request-for-quotes-panel">
          <request-for-quotes/>
        </div>
        <div class="widget-panel parts-order-panel">
          <parts-orders/>
        </div>
      </div>
      <div class="widget-panel notifications-panel">
        <dashboard-notifications/>
      </div>
    </div>
  </div>
</template>

<script>
import RequestForQuotes from './dashboard-blocks/request-for-quotes.vue'
import PartsOrders from './dashboard-blocks/parts-orders.vue'
import DashboardNotifications from './dashboard-blocks/dashboard-notifications.vue'
import {isMobile} from "mobile-device-detect";

export default {
  name: 'RepairerDashboard',
  methods: {
    isMobile() {
      return isMobile
    }
  },
  data () {
    return {}
  },
  components: {
    RequestForQuotes,
    PartsOrders,
    DashboardNotifications,
  }
}
</script>

<style scoped>
.dashboard-page {
  background: #f1f1f7;
  height: 100%
}

.white-bg {
  background: #ffffff;
}

.widget-panel {
  border-radius: 5px;
  margin: 0;
}

.parts-order-panel,
.notifications-panel {
  width: 100%;
}

.widget-panel-left {
  width: 820px;
}

.widget-panel-flex {
  display: flex;
  min-height: calc(100vh - 113px);
}

.request-for-quotes-panel >>> .dashboard-panel {
  width: 803.5px;
}

@media (min-width: 1365px) {
  .widget-container {
    flex-direction: row;
  }

  .widget-container .widget-panel:first-child {
    margin-right: 0;
  }

  .w70 {
    width: 70%;
  }

  .w30 {
    width: 30%;
  }

  .widget-panel {
    border-radius: 5px;
    margin: 15px;
    margin-right: 0;
  }

  .request-for-quotes {
    max-width: 1160px;
  }

  .parts-order-panel {
    max-width: 803.5px;
  }

  .notifications-panel {
    max-width: 803.5px;
  }
}

@media (max-width: 1780px) {
  .notifications-panel {
    border-radius: 0;
  }

  .widget-panel-flex {
    display: block;
  }

  .dashboard-page {
    background: #f1f1f7;
    height: 100%;
  }

  .notifications-panel {
    height: 530px;
  }

  .widget-panel-left {
    width: 100%;
  }
}

@media (max-width: 1365px) {
  .request-for-quotes-panel >>> .dashboard-panel {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .notifications-panel >>> .position-notification {
    display: block;
  }

  .notifications-panel >>> .position-notification-item {
    width: 100%;
  }
}
</style>
