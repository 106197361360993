<template>
  <div class="">
    <button :class="{ active: btnPositionScroll }" v-if="!isShowChat && !isShowUnViewedMessages" @click="openChat" type="button" class="btn waves-effect waves-light d-flex align-items-center btn-chat">
     <span v-if="_isEmpty(messages)">Start Chat</span>
     <span v-else>Read Msg</span>
      <i
          class="bx bx-message-rounded-dots"
          style="font-size: 20px; line-height: 2px; padding-left: 7px;"
      ></i></button>
    <button :class="{ active: btnPositionScroll }" v-else-if="!isShowChat && isShowUnViewedMessages" @click="openChat" type="button" class="btn waves-effect waves-light d-flex align-items-center btn-blink_new-message btn-scroll-bottom btn-chat">
      <span style="color: #79B1BB;" :class="{'new-msg-supplier': isUserTypeSupplier}">New Msg</span>
      <i
          class="bx bx-message-rounded-dots"
          style="font-size: 20px; line-height: 2px; padding-left: 7px;"
      ></i></button>

    <button :class="{ active: btnPositionScroll }" v-else type="button" @click="isShowChat = false; $emit('closeChat')" class="btn btn-primary waves-effect waves-light d-flex align-items-center btn-scroll-bottom btn-chat-come" style="box-shadow: none; margin-right: 15px; background-color: #29BBC1;">
      In Chat
      <i
          class="bx bx-message-rounded-dots"
          style="font-size: 20px; line-height: 2px; padding-left: 7px; color: #1c1f39"
      ></i></button>


    <div class="window-chat" :class="{ active: windowChatPosition }" v-if="isShowChat">
      <div class="window-chat-header">
        <div class="d-flex justify-content-between" style="height: 50px; flex-direction: column;">
          <div class="window-chat-company_name">{{userName}}</div>
          <span style="cursor: pointer;font-size: 32px; opacity: 0.5; color: #000000; position: absolute; right: 10px; top: -5px" @click="isShowChat = false">×</span>
          <div class="window-chat-status online mb-0-25" v-if="isChatterOnline">Online</div>
          <div class="window-chat-status mb-0-25" v-else>Offline</div>
        </div>
<!--        <div class="window-chat-username">Test Testovich</div>-->
      </div>
      <div class="window-chat-body" ref="windowChatBody">
        <message v-for="(message, i) in messages" :key="i" :class="{my: isMyMessage(message)}" :message="message">
          <div v-if="getDate(message.createdOn, message.messageId)" class="window-chat-body-date">{{formatDate(message.createdOn)}}</div>
        </message>
      </div>
      <div class="" style="position: relative">
        <span class="text-typing" :class="{ hidden: !typersText }">
          {{ typersText }}
          <span class="typing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </span>
        </span>
        <highlightable-input
            class="form-control chat-msg-input"
            style="height: 44px; overflow: auto; padding: 0px; width: 346px"
            @onHighlight="v =>handleHighlight(v)"
            @onEnter="onChatMessageSubmit"
            v-model="messageText"
            @onIconClick="onChatMessageSubmit"
            :isShowSendIcon="true"
            ref="highlightableInput"
            @input="onChatInputKeydown"
        />
<!--        <textarea type="text"-->
<!--                  class="form-control"-->
<!--                  placeholder="Write a message..."-->
<!--                  ref="chatInput"-->
<!--                  v-model="messageText"-->
<!--                  style="min-height: 42px; resize: none;"-->
<!--                  :disabled="!inputEnabled"-->
<!--                  @keydown.enter="onChatMessageSubmit"-->
<!--        />-->
      </div>
    </div>
  </div>

</template>

<script>
import * as dayjs from "dayjs";
import Message from './chat-message'
import HighlightableInput from '../../../components/HighlightableInput'
import {mapGetters} from "vuex";
export default {
  name: "chat",
  components: {
    Message,
    HighlightableInput
  },
  props: {
    messages: [],
    userName: {
      type: String,
      default: '',
    },
    isShowChatInit: {
      type: Boolean,
      default: false
    },
    isChatterOnline: {
      type: Boolean,
      default: false
    },
    typers: {
      type: Object,
      default: () => ({}),
    }
  },
  data(){
    return {
      lastChatInputMicrotime: 0,
      usedDate: {

      },
      messageText: '',
      inputEnabled: true,
      isShowChat: false,
      isHighlight: false,
      scrollPosition: 0,
      btnPositionToScroll: false,
      microtime: Date.now(),
    }
  },
  watch: {
    messages: {
      handler() {
        this.scrollToBottom()
      },
      immediate: true
    },
  },
  mounted() {
    this.isShowChat = this.isShowChatInit
    window.addEventListener("scroll", () => {
      this.scrollPosition = window.scrollY
    })
  },
  created() {
    this.$nextTick(() => {
      this.microtimeInterval = setInterval(() => {
        this.microtime = Date.now()
      }, 100)
    })
  },
  beforeDestroy() {
    clearInterval(this.microtimeInterval);
  },
  computed: {
    ...mapGetters({
      'currentUser': 'currentUser/getCurrentUserInfo',
      'currentCompany': 'currentCompany/getDetails',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      isMultipleLocations: 'settings/isMultipleLocations',
      getterSettings: 'settings/getSettings',
    }),
    typersText(){
      const names = [];
      for (const [key, value] of Object.entries(this.typers)) {
        if(this.microtime - value.microtime < 1500){
          names.push(value.name)
        }
      }
      if(names.length === 0){
        return this.microtime === 0;
      }
      if(names.length === 1){
        return names[0] + ' is typing';
      }
      return names.join(', ') + ' are typing'
    },
    isShowUnViewedMessages() {
      return _.some(this.messages, (m) => {
        if (this.isUserTypeSupplier && this.isMultipleLocations) {
          return !m.viewed && !_.find(this.getterSettings.multipleBrands, (b) => {
            return Number(m.companyId) === Number(b.id)
          })
        }
        return !m.viewed && Number(m.companyId) !== Number(this.currentCompany.id)
      })
    },
    btnPositionScroll() {
      if (this.scrollPosition > 50) {
        return this.btnPositionToScroll = true
      } else {
        return this.btnPositionToScroll = false
      }
    },
    windowChatPosition(){
      return this.scrollPosition > 50
    }
  },
  methods: {
    isMyMessage(message) {
      if (this.isUserTypeSupplier && this.isMultipleLocations) {
        return _.find(this.getterSettings.multipleBrands, (b) => {
          return Number(message.companyId) === Number(b.id)
        })
      }
      return Number(message.companyId) === Number(this.currentCompany.id)
    },
    formatDate(v) {
      if (dayjs(v).isToday()) {
        return 'Today'
      }
      return dayjs(v).format('DD/MM/YYYY')
    },
    getDate(date, id) {
      let d1 = dayjs(date).format('DD/MM/YYYY')
      if (!this.usedDate[d1] && this.usedDate[d1] != id) {
        this.usedDate[d1] = id;
        return date
      }
      if (this.usedDate[d1] == id) {
        return date
      }
      return ''
    },
    isHasDifference(ind) {
      return true
      if (this.messages.length = 1) {
        return true
      }

      if (!this.messages[ind] || !this.messages[ind - 1]) {
        return  false
      }

      let date1 = dayjs(this.messages[ind].createdOn)
      console.log( date1.diff(this.messages[ind - 1].createdOn, 'day', true), 'diff')
      return  date1.diff(this.messages[ind - 1].createdOn, 'day', true) == 1
    },
    handleHighlight(isHighlight){
      this.isHighlight = isHighlight;
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    openChat() {
      this.isShowChat = true;
      this.scrollToBottom()
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let el = this.$refs.windowChatBody
        if (el) {
          el.scrollTo(0, el.scrollHeight);
        }
      })
    },
    onChatMessageSubmit() {
      if(this.messageText && !this.isHighlight){
        this.inputEnabled = false;
        this.$emit('onChatMessageSubmit', {
          message: this.messageText,
          resolve: (result) => {
            if(result){
              this.messageText = ''
            }
            this.inputEnabled = true;
            setTimeout(() => {
              this.$refs.highlightableInput.focus()
            }, 200)
          }
        })
      }
    },
    onChatInputKeydown() {
      if(!this.messageText.trim()){
        return;
      }
      const microtime = Date.now();
      if(microtime - this.lastChatInputMicrotime > 300) {
        this.$emit('onTyping')
        this.lastChatInputMicrotime = microtime;
      }
    }
  }
}
</script>

<style>
.chat-msg-input .text-input {
  height: auto !important;
  min-height: 42px !important;
  padding: 0.375rem 40px 0.375rem 0.75rem !important;
}
.chat-msg-input .has-error-highlightable-input {
  height: auto !important;
  min-height: 42px !important;
  padding: 0.375rem 0.75rem !important;
}
.chat-msg-input .text-danger {
  position: absolute !important;
  background: white !important;
  bottom: 0px !important;
}
</style>
<style scoped>
.hidden {
  display: none !important;
}
.text-typing{
  font-size: 13px;
  margin: 5px;
  color: grey;
  position: absolute;
  top: -30px;
  left: 0;
  font-weight: bold;
}
.typing {
  align-items: center;
  height: 15px;
  padding-left: 3px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: rgba(20, 178, 185,.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    //background-color:rgba(20, 178, 185,.7);
  }
  28% {
    transform: translateY(-7px);
    //background-color:rgba(20, 178, 185,.4);
  }
  44% {
    transform: translateY(0px);
    //background-color:rgba(20, 178, 185,.2);
  }
}
.btn-chat{
  position: static;
  top: auto;
  box-shadow: none;
  color: rgb(28, 31, 57);
  background-color: white;
  margin-right: 15px;
  border-color: rgb(41, 187, 193);
  font-weight: bold;
  z-index: 10;
}
.btn-chat.active{
  position: fixed;
  bottom: 5px;
  right: 0;
  top: auto;
  z-index: 99999;
  box-shadow: none;
  margin-right: 15px;
}
.btn-chat-come.active{
  position: fixed;
  bottom: 5px;
  right: 0;
  top: auto;
  z-index: 99999;
  box-shadow: none;
  margin-right: 15px;
}

.new-msg-supplier {
  color: #29bbc1 !important;
}
.window-chat{
  position: absolute;
  height: 600px;
  width: 350px;
  border: 2px solid var(--supplier-color);
  border-radius: 5px;
  background-color: rgb(229, 245, 246);
  z-index: 1009;
  right: 11px;
  transition: all 0.2s ease-in-out;
}
.window-chat.active{
  position: fixed;
  bottom: 46px;
  transition: all 0.2s ease-in-out;
  z-index: 10009;
}


.window-chat-header{
  background-color: #fdfdfd;
  padding: 10px 20px;
}
.window-chat-company_name{
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
}
.window-chat-username{
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.window-chat-status{
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 15px;
  color: #9D0025;
}
.window-chat-status.online {
  color: #36bec3;
}
.window-chat-body{
  height: 79%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 15px;
}
.window-chat-body-date{
  border-top: 1px solid #a5a5a5;
  width: 100%;
  font-size: 11px;
  text-align: center;
  padding: 3px 0;
  align-items: center;
}



@keyframes soft-blink  {
  0%, 100% { background-color: white }
  50% { background-color: #36bec3 }
}

.btn-blink_new-message{
  -webkit-animation: soft-blink 2s linear infinite;
  -moz-animation: soft-blink 2s linear infinite;
  -ms-animation: soft-blink 2s linear infinite;
  -o-animation: soft-blink 2s linear infinite;
  animation: soft-blink 2s linear infinite;
}
</style>
