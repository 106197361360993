<script>
import ForgotUserDataPage from './forgot_pass-userdata.vue';
export default {
    name: "SupportFormPassword",
    extends: ForgotUserDataPage,
    methods:{
        getUrl(){
            return '/ir/auth/forgot/password/support';
        },
        getTitle(){
            return 'Password';
        }
    }
}
</script>
