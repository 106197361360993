/*eslint-disable */
import _ from 'lodash';
import {
    SUPPLIER_ORDERS_UPDATED,
    SUPPLIER_ORDERS_FILTER_UPDATE,
    SUPPLIER_ORDERS_ALL_CURRENT_PAGE,
    SUPPLIER_ORDERS_OPEN_CURRENT_PAGE,
    SUPPLIER_ORDERS_PROCESSING_CURRENT_PAGE,
    SUPPLIER_ORDERS_ROUTE_CURRENT_PAGE,
    SUPPLIER_ORDERS_DELIVERED_CURRENT_PAGE,
    SUPPLIER_ORDERS_COMPLETED_CURRENT_PAGE,
    SUPPLIER_ORDERS_PER_PAGE_ALL,
    SUPPLIER_ORDERS_PER_PAGE_OPEN,
    SUPPLIER_ORDERS_PER_PAGE_PROCESSING,
    SUPPLIER_ORDERS_PER_PAGE_ROUTE,
    SUPPLIER_ORDERS_PER_PAGE_DELIVERED,
    SUPPLIER_ORDERS_PER_PAGE_COMPLETED,
    SUPPLIER_ORDERS_SET_TOKEN,
    SUPPLIER_ORDERS_SET_SEARCH_FILTER_OBJ,
    SUPPLIER_ORDERS_SET_TOKENS,
    SUPPLIER_REINSTALLATION,
    SUPPLIER_ORDERS_SHORT_UPDATING,
    SUPPLIER_ORDERS_PARTIAL_DELIVERY_CURRENT_PAGE,
    SUPPLIER_ORDERS_PER_PAGE_PARTIAL_DELIVERY,
    SUPPLIER_ORDERS_SET_ORDER_STATUS,
} from '../../../mutation-types';

export default {
    [SUPPLIER_REINSTALLATION](state) {
        state.items = [];
        state.token = [];
        state.searchFilterObj = {
            search: '',
        };
        state.ordersFromUpdate = [];
        state.tokens = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
        };
        state.filter = '';
        state.perPageAll = 100;
        state.perPageOpen = 100;
        state.perPageProcessing = 100;
        state.perPageRoute = 100;
        state.perPageDelivered = 100;
        state.perPageCompleted = 100;
        state.orderAllCurrentPage = 1;
        state.orderOpenCurrentPage = 1;
        state.orderProcessingCurrentPage = 1;
        state.orderRouteCurrentPage = 1;
        state.orderDeliveredCurrentPage = 1;
        state.orderCompletedCurrentPage = 1;
        state.lastTimeCheck = {
            items: 0,
        }
    },
    [SUPPLIER_ORDERS_SET_TOKEN](state, ui) {
        state.token.splice(0, state.token.length);
        _.forEach(ui, itm => {
            state.token.push(itm)
        })
    },
    [SUPPLIER_ORDERS_SET_TOKENS](state, ui) {
        for (let key in state.tokens) {
            delete state.tokens.key
        }
        for (let key in ui) {
            state.tokens[key] = ui[key];
        }
    },
    [SUPPLIER_ORDERS_SET_SEARCH_FILTER_OBJ](state, ui) {
        state.searchFilterObj = ui;
    },
    [SUPPLIER_ORDERS_SET_ORDER_STATUS](state, ui) {
        let order = _.find(state.items, (itm) => {
            return Number(itm.id) == Number(ui.id)
        })
        if (order) {
            order.status = ui.status
        }
    },
    // [SUPPLIER_ORDER_IDS_UPDATE_OPEN](state, ui){
    //     //Array.prototype.push.apply(state.new, ui);
    //     state.Open       = _.union(state.Open, ui);
    //     state.Processing = _.pullAll(state.Processing, ui);
    //     state.Shipped    = _.pullAll(state.Shipped, ui);
    //     state.Delivered  = _.pullAll(state.Delivered, ui);
    //     state.Completed     = _.pullAll(state.Completed, ui);
    // },
    [SUPPLIER_ORDERS_FILTER_UPDATE](state, ui) {
        state.filter = ui;
    },
    // [SUPPLIER_ORDER_IDS_UPDATE_PROCESSING](state, ui) {
    //     //Array.prototype.push.apply(state.priced, ui);
    //     state.Processing = _.union(state.Processing, ui);
    //     state.Open       = _.pullAll(state.Open, ui);
    //     state.Shipped    = _.pullAll(state.Shipped, ui);
    //     state.Delivered  = _.pullAll(state.Delivered, ui);
    //     state.Completed     = _.pullAll(state.Completed, ui);
    // },
    // [SUPPLIER_ORDER_IDS_UPDATE_PARTS_ON_ROUTE](state, ui) {
    //     //Array.prototype.push.apply(state.lost, ui);
    //     state.Shipped = _.union(state.Shipped, ui);
    //     state.Open       = _.pullAll(state.Open, ui);
    //     state.Processing = _.pullAll(state.Processing, ui);
    //     state.Delivered  = _.pullAll(state.Delivered, ui);
    //     state.Completed     = _.pullAll(state.Completed, ui);
    // },
    // [SUPPLIER_ORDER_IDS_UPDATE_PARTS_DELIVERED](state, ui) {
    //     //Array.prototype.push.apply(state.won, ui);
    //     state.Delivered = _.union(state.Delivered, ui);
    //     state.Open       = _.pullAll(state.Open, ui);
    //     state.Processing = _.pullAll(state.Processing, ui);
    //     state.Shipped    = _.pullAll(state.Shipped, ui);
    //     state.Completed     = _.pullAll(state.Completed, ui);
    // },
    // [SUPPLIER_ORDER_IDS_UPDATE_COMPLETED](state, ui) {
    //     //Array.prototype.push.apply(state.Completed, ui);
    //     state.Completed = _.union(state.Completed, ui);
    //     state.Open       = _.pullAll(state.Open, ui);
    //     state.Processing = _.pullAll(state.Processing, ui);
    //     state.Shipped    = _.pullAll(state.Shipped, ui);
    //     state.Delivered  = _.pullAll(state.Delivered, ui);
    // },
    [SUPPLIER_ORDERS_UPDATED](state, ui) {
        state.items = _.unionBy(ui.data, state.items, 'id');
        if (!_.isEmpty(ui.payload)) {
            state.ordersFromUpdate = ui.data;
        }
    },
    [SUPPLIER_ORDERS_SHORT_UPDATING](state, ui) {
        state.items = _.unionBy(ui.data, [], 'id');
    },
    [SUPPLIER_ORDERS_ALL_CURRENT_PAGE](state, ui) {
        state.orderAllCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_OPEN_CURRENT_PAGE](state, ui) {
        state.orderOpenCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_PROCESSING_CURRENT_PAGE](state, ui) {
        state.orderProcessingCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_PARTIAL_DELIVERY_CURRENT_PAGE](state, ui) {
      state.orderPartialDeliveryCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_ROUTE_CURRENT_PAGE](state, ui) {
        state.orderRouteCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_DELIVERED_CURRENT_PAGE](state, ui) {
        state.orderDeliveredCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_COMPLETED_CURRENT_PAGE](state, ui) {
        state.orderCompletedCurrentPage = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_ALL](state, ui) {
        state.perPageAll = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_PARTIAL_DELIVERY](state, ui) {
      state.perPagePartialDelivery = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_OPEN](state, ui) {
        state.perPageOpen = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_PROCESSING](state, ui) {
        state.perPageProcessing = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_ROUTE](state, ui) {
        state.perPageRoute = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_DELIVERED](state, ui) {
        state.perPageDelivered = ui;
    },
    [SUPPLIER_ORDERS_PER_PAGE_COMPLETED](state, ui) {
        state.perPageCompleted = ui;
    },
};
