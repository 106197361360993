<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                  <div @click.prevent="goToHome" class="logo-text">
                      <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
                  </div>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="active">
                                    <span class="numb">1</span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>Fill in your business details</h1>
                        <br />
                        <form method="POST" @submit.prevent="login">
                            <div class="form-group">
                                <label for="Country">Country</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="Country"
                                    placeholder="Country"
                                    v-model="country"
                                />
                                <div class="w-100 text-right" v-if="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text 2
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Company Type</label>
                                <multiselect
                                    :options="getOptions"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select Company Type"
                                    v-model="type"
                                />
                            </div>
                            <div class="form-group">
                              <label for="entityname">Entity Name</label>
                              <input
                                  autocomplete="off"
                                  type="text"
                                  class="form-control input-field"
                                  name="entityname"
                                  placeholder="Entity Name"
                                  v-model="entityName"
                              />
                            </div>
                            <div class="form-group">
                                <label for="businessname">Registered Business Name</label>
                                <multiselect
                                    v-if="businessNames.length"
                                    :options="businessNames"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select Business Name"
                                    v-model="businessName"
                                />
                                <input
                                    v-else
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="businessname"
                                    placeholder="Registered Business Name"
                                    v-model="businessName"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="businessnumber">Australian Business Number(ABN)</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="businessnumber"
                                    placeholder="Australian Business Number"
                                    v-model="abn"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="companynumber">Australian Company Number(ACN)</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="companynumber"
                                    placeholder="Australian Company Number"
                                    v-model="acn"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="businnesadress">Business Address</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="businnesadress"
                                    placeholder="Business Address"
                                    v-model="businessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="City">City/Suburb</label>
                                <input
                                  autocomplete="off"
                                  type="text"
                                  class="form-control input-field"
                                  name="businnesadress"
                                  placeholder="Select City"
                                  v-model="city"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="State">State</label>
                                <multiselect
                                    :options="getOptionsState"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select state"
                                    v-model="state"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="postcode">Postcode</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field post-code"
                                    name="businessnumber"
                                    placeholder="Postcode"
                                    v-model="postcode"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn input-btn"
                                    :disabled="!nextAllowed"
                                    @click="register"
                                >Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
form {
    max-width: 522px;
    margin: 0 auto;
}
.form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.form h1 {
    text-align: center;
    margin-bottom: 0px;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 30px;
}
.form .post-code {
    width: 109px;
}
</style>

<script>
import Multiselect from "vue-multiselect";
import _ from 'lodash';
export default {
  name: "InviteRepairerFillBusinessDetails",
  components: {
    multiselect: Multiselect
  },
  data(){
    return {
      country: '',
      type: '',
      entityName: '',
      businessName: '',
      abn: '',
      acn: '',
      businessAddress: '',
      city:'',
      state: '',
      postcode: '',
      registrationData: {},
      businessNames: [],
    };
  },
  mounted() {
    if(localStorage.getItem('registrationData')){
      try {
        this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
      } catch (e) {
        localStorage.removeItem('registrationData');
      }
    }

    if(this.registrationData.companyDataFromAbn){
      let data = this.registrationData.companyDataFromAbn;

      this.country = 'Australia';

      if (data.ASICNumber) {
        this.acn = data.ASICNumber;
      }

      if (data.entityType && data.entityType.entityDescription) {
        this.type = data.entityType.entityDescription;
      }

      if(data.ABN.identifierValue){
        this.abn = data.ABN.identifierValue;
      } else if(data.ABN[0].identifierValue) {
        this.abn = data.ABN[0].identifierValue;
      }

      if (data.mainName) {
        this.entityName = data.mainName.organisationName;
      }

      if(data.businessName){
        if(data.businessName.organisationName){
          this.businessName = data.businessName.organisationName;
        } else if(data.businessName[0].organisationName || data.businessName[0].mainName){
          _.forEach(data.businessName, item => {
            if (item.organisationName) {
              this.businessNames.push(item.organisationName)
            } else if (item.mainName) {
              this.businessNames.push(item.mainName)
            }
          });

          if (data.businessName[0].organisationName) {
            this.businessName = data.businessName[0].organisationName;
          } else {
            this.businessName = data.businessName[0].mainName;
          }

        } else if(data.businessName.mainName){
          this.businessName = data.businessName.mainName;
        }
      } else if(data.mainTradingName) {
        if(data.mainTradingName.organisationName){
          this.businessName = data.mainTradingName.organisationName;
        } else if(data.mainTradingName[0].organisationName){
          _.forEach(data.mainTradingName, item => {
            if (item.organisationName) {
              this.businessNames.push(item.organisationName);
            }
          });

          this.businessName = data.mainTradingName[0].organisationName;
        }
      }

      if(data.mainBusinessPhysicalAddress.postcode){
        this.postcode = data.mainBusinessPhysicalAddress.postcode;
        if(data.mainBusinessPhysicalAddress.stateCode){
          this.state = data.mainBusinessPhysicalAddress.stateCode;
        }

      } else if(data.mainBusinessPhysicalAddress[0].postcode){
        this.postcode = data.mainBusinessPhysicalAddress[0].postcode;
        if(data.mainBusinessPhysicalAddress[0].stateCode){
          this.state = data.mainBusinessPhysicalAddress[0].stateCode;
        }
      }

      console.log(data);
    }
    this.scrollToTop()
  },
  methods: {
    register: function(){
      let business = {};

      business.country = this.country;
      business.type = this.type;
      business.name = this.businessName;
      business.abn = this.abn;
      business.acn = this.acn;
      business.address = this.businessAddress;
      business.city = this.city;
      business.state = this.state;
      business.postcode = this.postcode;
      business.entityName = this.entityName;

      this.registrationData.business = business;

      localStorage.registrationData = JSON.stringify(this.registrationData);

      this.$router.push({name: 'RepairerCreateBusinessProfile'});
    },
    goToHome() {
      document.location.replace(appConfig.homeURL + '/');
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },
  computed: {
    getOptions() {
      return ["Australian Public Company", "Australian Private Company"];
    },
    getOptionsCity() {
      return ["LAKEMBA", "Sydney"];
    },
    getOptionsState() {
      return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA',];
    },
    nextAllowed(){
      return !! (
          this.country
          && this.type
          && this.businessName
          && this.abn
          && this.acn
          && this.businessAddress
          && this.city
          && this.state
          && this.postcode
          && this.entityName
      );
    }
  }
};
</script>
