<template>
  <div class="row">
    <div class="col-lg-6">
      <tabs ref="users-tabs" cache-lifetime="0" :options="{ useUrlFragment: false, defaultTabHash: computedDefaultMyAccountHash }" @changed="onMyAccountTabChange">
        <tab name="Details" id="my-account-details" ref="my-account-details" class-name="tab-content-table">
          <div class="detail-section">
            <div class="section-header">Detail</div>

            <div class="detail-body row">
              <div v-if="myAccountAvatar" class="col-md-4 user-avatar-section">
                <div class="user-photo">
                  <img :src="myAccountAvatar" alt="User Image"/>
                </div>
                <button v-show="isEditMode" class="btn btn-danger"
                        @click="onRemoveMyAccountPhoto()">Delete Photo
                </button>
              </div>
              <div v-else class="col-md-4 user-avatar-section">
                <vue-dropzone
                    v-on:vdropzone-file-added="addImgUpload"
                    v-on:vdropzone-complete="completeImgUpload"
                    v-on:vdropzone-sending="sendImgUpload"
                    v-on:vdropzone-success="successImgUploadMyAccount"
                    ref="imgUploadDropMyAccount"
                    id="imgUploadDropMyAccount"
                    class="dropify-wrapper dropzoneContainer"
                    :options="myAccountDropzoneOptions"
                    :useCustomSlot="true"
                >
                  <div class="dropify-message" style="transform: translateY(0%);">
                    <i class="bx bx-cloud-upload"></i>
                    <p class="upload-title">Upload Photo</p>
                  </div>
                </vue-dropzone>
              </div>

              <div class="col-md-8 user-detail-section">
                <div class="form-group row">
                  <h3 class="user-name">{{ currentUser.fullName }}</h3>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label" for="account-status">Account
                    Status</label>
                  <div class="col-lg-9 d-flex">
                    <label v-if="isActiveMyAccount" class="col-form-label form-text">
                      <div class="active-icon"></div>
                      <span class="active-user-text">Active User</span>
                    </label>
                    <label v-else class="col-form-label form-text">
                      <div class="active-icon inactive"></div>
                      <span class="active-user-text">Not Active User</span>
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="username_my_account"
                         class="col-lg-3 col-form-label">Username</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        class="col-form-label form-text"
                    >{{ currentUser.username }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        @input="debouncedCheckUsername(currentUser.username)"
                        v-model="currentUser.username"
                        id="username_my_account"
                    />
                  </div>
                  <template v-if="!isCheckingUserName">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9 mt-1">
                      <div v-if="!isCheckUsername" class="w-100 text-left">
                        <span class="error-text font-12">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This username is in use. Try to another one.
                        </span>
                      </div>
                      <div v-else-if="!isEqualUsernames" class="w-100 text-left">
                        <span style="color:#2DCA73; font-weight: 600" class="font-12 d-flex align-items-center">
                          <i class="bx bx-check-circle" style="width: 24px; height: 24px; font-size: 24px; margin-right: 5px;"></i>&nbsp;Congrats this username is not in use
                        </span>
                      </div>
                    </div>
                  </template>
                </div>

                <div class="form-group row">
                  <label for="first_name_my_account" class="col-lg-3 col-form-label">First
                    Name</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        class="col-form-label form-text"
                    >{{ currentUser.firstName }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="currentUser.firstName"
                        id="first_name_my_account"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="last_name_my_account" class="col-lg-3 col-form-label">Last
                    Name</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        class="col-form-label form-text"
                    >{{ currentUser.lastName }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="currentUser.lastName"
                        id="last_name_my_account"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Email Address</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        class="col-form-label form-text"
                    >{{ currentUser.email }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        :value="currentUser.email"
                        @change="validateMyAccountEmail($event.target.value)"
                    />
                    <small
                        class="col-form-label form-text show-help-for-body"
                        v-if="!$v.currentUser.email.email"
                    >Please enter a valid email address</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="location" class="col-lg-3 col-form-label">Location</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        for="location_my_account"
                        class="col-form-label form-text">
                      <template v-if="currentUserLocation">
                        {{ currentUserLocation.name }}
                      </template>
                    </label>
                    <multiselect
                        v-else
                        v-model="currentUserLocation"
                        :options="locations"
                        track-by="id"
                        label="name"
                        :showLabels="false"
                        :disabled="isEstimator"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :multiple="false"
                        placeholder="Select business location"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="work_phone" class="col-lg-3 col-form-label">Work Phone</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        for="work_phone_my_account"
                        class="col-form-label form-text"
                    >{{ currentUser.workPhone }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="currentUser.workPhone"
                        id="work_phone_my_account"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="mobile_phone_my_account" class="col-lg-3 col-form-label">Mobile
                    Phone</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        for="mobile_phone"
                        class="col-form-label form-text"
                    >{{ currentUser.mobilePhone }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="currentUser.mobilePhone"
                        id="mobile_phone_my_account"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Role</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isEditMode"
                        class="col-form-label form-text"
                    >{{ currentUser.companyRole }}</label>
                    <multiselect
                        v-else
                        ref="account-role-options"
                        v-model="companyRole"
                        :options="getRoles()"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :disabled="isEstimator"
                        :close-on-select="true"
                        placeholder="Select Role"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Last Logged In</label>
                  <div class="col-lg-9">
                    <label
                        class="col-form-label form-text">{{
                        currentUser.lastLoggedIn
                      }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Active sessions</label>
                  <div class="col-lg-9">
                    <label
                        class="col-form-label form-text">{{
                        currentUser.activeSessions
                      }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="detail-footer">
              <div class="form-group w-auto action-btns d-flex justify-content-center m-auto">
                <button class="edit-btn" v-if="!isEditMode" @click="onEditAccount()">Edit
                </button>
                <button class="update-btn" v-if="isEditMode" @click="onUpdateMyAccountInfo()" :disabled="!isCheckUsername">
                  Update
                </button>
                <button class="edit-btn" v-if="isEditMode" @click="onCancelEditAccount()">
                  Cancel
                </button>
                <button v-if="!isEditMode"
                        class="close-active-btn"
                        @click="onCloseActiveSessionsMyAccount()"
                >Close Active Sessions
                </button>
              </div>
            </div>
          </div>

          <div class="update-password-section">
            <div class="section-header">Password</div>

            <div class="form-body">
              <div class="form-group row mt-4">
                <label class="col-lg-4 col-form-label"
                       for="new_password__my_account">Password</label>
                <div class="col-lg-8">
                  <input
                      type="password"
                      class="form-control"
                      v-model="newPassword"
                      id="new_password_my_account"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="confirm_password_of_my_account" class="col-lg-4 col-form-label">Confirm
                  Password</label>
                <div class="col-lg-8">
                  <input
                      type="password"
                      class="form-control"
                      v-model="confirmPassword"
                      id="confirm_password_of_my_account"
                  />
                </div>
              </div>

              <div class="form-group row action-btns justify-content-end">
                <button class="update-btn" @click="onUpdatePasswordMyAccount()">Update</button>
              </div>
            </div>
          </div>
        </tab>

        <tab name="Notifications" id="my-account-notifications" ref="my-account-notifications" class-name="tab-content-table">
          <div class="detail-section form-group row">
            <div class="detail-body">
              <template v-if="isUserTypeSupplier">
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Request for Quote Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time new RFQ is submitted from a Customer to your account email.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isEmailNoticeNewRfq}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isEmailNoticeNewRfq"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isEmailNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Request for Quote Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound notification each time new RFQ is submitted from a Customer.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isSoundNoticeNewRfq}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isSoundNoticeNewRfq"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isSoundNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Update Priced Quote Request Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time a new update priced quote requested submitted from a customer to your account email.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isEmailNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isEmailNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isEmailNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Update Priced Quote Request Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound each time a new update priced quote request submitted from a customer.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isSoundNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isSoundNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isSoundNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Priced Quote Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time new pricing is submitted from supplier in a Priced Quote.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isEmailNoticeNewRfq}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isEmailNoticeNewRfq"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isEmailNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Priced Quote Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound notification each time new pricing is submitted from supplier in a Priced Quote.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isSoundNoticeNewRfq}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isSoundNoticeNewRfq"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isSoundNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Updated Priced Quote Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time after pricing update request submitted from parts supplier.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isEmailNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isEmailNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isEmailNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Updated Priced Quote Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound each time after pricing update request submitted from parts supplier</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"  :class="{'settings-active-toggle-button': currentUser.isSoundNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="onUpdateMyAccountInfo"
                          v-model="currentUser.isSoundNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="currentUser.isSoundNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Multiselect from 'vue-multiselect';
import { email } from 'vuelidate/lib/validators'
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import NProgress from "nprogress";
import Axios from "axios";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    multiselect: Multiselect,
  },
  name: "the-my-account",
  data () {
    return {
      isCheckUsername: true,
      isCheckingUserName: false,
      isEqualUsernames: true,
      username2: '',
      repairerRoleOptions: [
        'Administrator',
        'Estimator',
        'Parts Coordinator'
      ],
      supplierRoleOptions: [
        'Administrator',
        'Parts Interpreter',
        'Parts Manager'
      ],
      myAccountAvatar: '',
      companyRole: '',
      newPassword: '',
      confirmPassword: '',
      isEditMode: false,
      isActiveMyAccount: true,
      currentUserLocation: '',
      locations: [],
      myAccountDropzoneOptions: {
        headers: { Authorization: localStorage.getItem('token') },
        resizeWidth: 400,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/user/photo/upload',
        method: 'post',
        maxFilesize: 5,
        thumbnailWidth: 160,
        thumbnailHeight: 160,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig:
            'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
    }
  },
  mounted() {
    if (!_.isEmpty(this.currentUser)) {
      this.myAccountAvatar = this.currentUser.userPhoto
      this.companyRole = this.currentUser.companyRole
      this.currentUserLocation = this.getLocationById(this.currentUser.businessLocation)
    }
    if (!_.isEmpty(this.initialLocations)) {
      this.setLocations(this.initialLocations)
      this.currentUserLocation = this.getLocationById(this.currentUser.businessLocation)
    }
    this.username2 = _.cloneDeep(this.currentUser.username)
    this.debouncedCheckUsername = _.debounce(function (v) {
      this.checkUsername(v)
    }, 500)
  },
  created() {
    this.onUpdateMyAccountInfo = _.debounce(this.onUpdateMyAccountInfo2, 1000)
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser/getCurrentUserInfo',
      initialLocations: 'currentCompany/getLocations',
      addressStates: 'addressStates',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      isEstimator: 'currentUser/isEstimator',
    }),
    computedDefaultMyAccountHash () {
      if (localStorage.getItem("settings.tabs.defaultMyAccountTab")) {
        return localStorage.getItem("settings.tabs.defaultMyAccountTab");
      }
      return "my-account-details";
    },
  },
  methods: {
    ...mapActions({
      updateUserAvatar: 'currentUser/updateUserAvatar',
    }),
    checkUsername: function (v) {
      this.isCheckingUserName = true;
      this.isEqualUsernames = v === this.username2
      if (this.isEqualUsernames) {
        this.isCheckUsername = true;
        return
      }
      let username = v;
      this.isCheckPassword = true;
      Axios({url: '/ir/auth/check/username', data: {username: username}, method: 'POST'})
          .then(resp => {
            if (resp && resp.data && resp.data.status == false) {
              this.isCheckUsername = true;
            } else {
              this.isCheckUsername = false;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally((() => {
            this.isCheckingUserName = false
          }))
    },
    onMyAccountTabChange (tab) {
      localStorage.setItem("settings.tabs.defaultMyAccountTab", tab.tab.id);
    },
    getRoles: function () {
      return (this.isUserTypeSupplier) ? this.supplierRoleOptions : this.repairerRoleOptions
    },
    getLocationById: function (id) {
      let location = {}
      if (!_.isEmpty(this.locations)) {
        location = _.find(this.locations, (location) => {
          return id === location.id
        })
      }
      return location
    },
    setLocations: function (val) {
      this.locations = val.map(location => ({
        ...location,
        isEditable: false,
        stateValue: _.find(this.addressStates, item => {
          return item.key === location.state
        })
      }))
    },

    onEditAccount: function () {
      this.isEditMode = true
    },
    onCancelEditAccount: function () {
      this.isEditMode = false
    },
    onCloseActiveSessionsMyAccount: function () {
      this.$store.dispatch('currentUser/closeActiveSessions')
          .then(() => {
            this.$toast.success('Active sessions are closed successfully')
            NProgress.start();
            let wrapper = document.getElementById('app-wrapper');
            wrapper.style.opacity = 0;
            setTimeout(() => {
              this.$store.dispatch('auth/logout')
            }, 500)
          })
          .catch(() => {
            this.$toast.error('err')
          })
    },
    onRemoveMyAccountPhoto: function () {
      NProgress.start()
      this.myAccountAvatar = ''
      this.updateUserAvatar('')
      this.$store.dispatch('currentUser/removeUserAvatar')
          .then(() => {
            this.$toast.success('User photo has been removed')
            NProgress.done()
          })
          .catch(error => {
            this.$toast.error(error)
            NProgress.done()
          })
    },
    onUpdateMyAccountInfo2: function () {
      const updatedUserInfo = {
        username: this.currentUser.username,
        email: this.currentUser.email,
        companyRole: this.companyRole,
        isActive: this.isActiveMyAccount,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        businessLocation: this.currentUserLocation && this.currentUser.id ? this.currentUserLocation.id: null,
        workPhone: this.currentUser.workPhone,
        mobilePhone: this.currentUser.mobilePhone,
        isEmailNoticeNewRfq: this.currentUser.isEmailNoticeNewRfq,
        isEmailNoticeUpdatedPriceRequest: this.currentUser.isEmailNoticeUpdatedPriceRequest,
        isSoundNoticeNewRfq: this.currentUser.isSoundNoticeNewRfq,
        isSoundNoticeUpdatedPriceRequest: this.currentUser.isSoundNoticeUpdatedPriceRequest,
      }
      this.$store.dispatch('currentUser/updateCurrentUser', updatedUserInfo)
          .then(() => {
            this.$toast.success('User has been updated successfully')
            this.$store.dispatch('users/fetchAllUsers').catch(err => {
              this.$toast.error(err)
            })
          }).catch(err => {
        this.$toast.error(err)
      })
      this.isEditMode = false
    },
    onUpdatePasswordMyAccount: function (data = {}, onSuccess = () => {}) {
      let password = data
      if (_.isEmpty(password)) {
        password = {
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword
        }
      }

      password.id = this.currentUser.id
      this.$store.dispatch('currentUser/updateCurrentUserPassword', password)
          .then(res => {
            if (res.data._status) {
              this.$toast.success('User\'s password has been updated successfully')
              onSuccess()
            } else {
              if (!_.isEmpty(res.data._err)) {
                _.forEach(res.data._err, text => {
                  this.$toast.error(text)
                })
              }
            }
          }).catch(err => {
        this.$toast.error(err)
      })
    },



    addImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    completeImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].removeAttribute('style')
    },
    sendImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    successImgUploadMyAccount (file, response) {
      if (response.status) {
        this.updateUserAvatar(file.dataURL)
        this.myAccountAvatar = file.dataURL
      } else {
        var vm = this
        setTimeout(function () {
          vm.$refs.imgUploadDropMyAccount.removeFile(file)
        }, 1000)
      }
    },
    validateMyAccountEmail: function (value) {
      this.currentUser.email = value
      this.$v.currentUser.email.$touch()
    },
  },
  watch: {
    currentUser: {
      deep: true,
      handler (val) {
        this.username2 = _.cloneDeep(val.username)
        this.myAccountAvatar = val.userPhoto
        this.companyRole = val.companyRole
        this.currentUserLocation = this.getLocationById(val.businessLocation)
      }
    },
    initialLocations: {
      deep: true,
      handler (val) {
        this.setLocations(val)
        this.currentUserLocation = this.getLocationById(this.currentUser.businessLocation)
      }
    },
  },
  validations: {
    currentUser: {
      email: {
        email
      }
    },
  }
}
</script>

<style scoped>

</style>
