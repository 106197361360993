<template>
    <div class="dashboard-page">
        <div class="page-header" style="min-height: auto">
          <h4 style="margin-bottom: 0px">Dashboard</h4>
        </div>
        <div class="widget-panel-flex">
          <div class="widget-panel-left">
            <div class="widget-panel request-for-quotes-panel">
              <RequestForQuotes :width="calcWidth" />
            </div>
            <div class="widget-panel parts-order-panel">
              <PartsOrders :seriesColor="'#A4EEF1'" />
            </div>
          </div>
          <div class="widget-panel notifications-panel">
            <Notifications />
          </div>
        </div>
    </div>
</template>

<script>
import RequestForQuotes from "./dashboard-block/request-for-quotes.vue";
import PartsOrders from "./dashboard-block/parts-orders.vue";
import Notifications from "./dashboard-block/notifications.vue";

export default {
    name: "Dashboard",
    components: {
        RequestForQuotes,
        PartsOrders,
        Notifications
    },
    data () {
      return {
      }
    },
    computed: {
        calcWidth() {
            return window.innerWidth <= 1024 ? "100%" : "803.5px";
        }
    },
};
</script>
<style scoped>
.dashboard-page {
  background: #f1f1f7;
  height: 100%
}
.white-bg {
  background: #ffffff;
}
.widget-panel {
  border-radius: 5px;
  margin: 0;
}
.parts-order-panel,
.notifications-panel {
  width: 100%;
}
.request-for-quotes-panel{
  width: 100%;
}
.widget-panel-left{
  width: 820px;
}
.widget-panel-flex{
  display: flex;
  min-height: calc(100vh - 113px);
}
@media (min-width: 1365px) {
  .widget-container {
    flex-direction: row;
  }

  .widget-container .widget-panel:first-child {
    margin-right: 0;
  }
  .w70 {
    width: 70%;
  }

  .w30 {
    width: 30%;
  }
  .widget-panel {
    border-radius: 5px;
    margin: 15px;
    margin-right: 0;
  }
  .request-for-quotes {
    max-width: 1160px;
  }
  .parts-order-panel {
    max-width: 803.5px;
  }
  .notifications-panel {
    max-width: 803.5px;
  }
}
@media (max-width: 1440px) {
  .notifications-panel {
    border-radius: 0;
  }
  .widget-panel-flex{
    display: block;
  }
  .dashboard-page {
    background: #f1f1f7;
    height: 100%;
  }
  .notifications-panel {
    height: 530px;
  }
  .widget-panel-left{
    width: 100%;
  }
}
@media (max-width: 1780px) {
  .notifications-panel {
    border-radius: 0;
  }
  .widget-panel-flex{
    display: block;
  }
  .dashboard-page {
    background: #f1f1f7;
    height: 100%;
  }
  .notifications-panel {
    height: 530px;
  }
  .widget-panel-left{
    width: 100%;
  }
}
@media (max-width: 1365px) {

  .request-for-quotes-panel >>>.dashboard-panel{
    max-width: 100% !important;
  }
  .widget-panel{
    max-width: 100%;
  }
}
@media (max-width: 1000px) {
  .notifications-panel >>> .position-notification{
    display: block;
  }
  .notifications-panel >>> .position-notification-item{
    width: 100%;
  }
}
</style>
