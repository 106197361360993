/*eslint-disable */
import * as actions from "./actions";
import mutations from "./mutations";
import * as getters from "./getters";

const state = {
  usersAll: [],
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  getters,
};