/*eslint-disable */
import _ from 'lodash';
import {
    REPAIRER_RFQ_IDS_UPDATE_CANCELLED,
    REPAIRER_RFQ_IDS_UPDATE_ORDERED,
    REPAIRER_RFQ_IDS_UPDATE_PRICED,
    REPAIRER_RFQ_IDS_UPDATE_RECEIVED,
    REPAIRER_RFQ_IDS_UPDATE_SUBMITTED,
    REPAIRER_RFQ_UPDATED,
    REPAIRER_RFQ_FILTER_UPDATE,
    REPAIRER_CURRENT_RFQ_UPDATE,
    REPAIRER_PER_PAGE,
    REPAIRER_RFQ_CANCELLED_CURRENT_PAGE,
    REPAIRER_RFQ_ORDERED_CURRENT_PAGE,
    REPAIRER_RFQ_PRICED_CURRENT_PAGE,
    REPAIRER_RFQ_RECEIVED_CURRENT_PAGE,
    REPAIRER_RFQ_SUBMITTED_CURRENT_PAGE,
    REPAIRER_RFQ_SET_SEARCH_FILTER_OBJ,
    REPAIRER_RFQ_SET_MESSAGE_STATUS,
    REPAIRER_RFQ_SET_TOKEN,
    REPAIRER_RFQ_DELETE,
    REPAIRER_RFQ_SET_ASSIGNED_USER, REPAIRER_RFQ_STATUS
} from '../../../mutation-types';

export default {
    [REPAIRER_RFQ_SET_ASSIGNED_USER] (state, ui) {
        let rfq = _.find(state.items, (itm) => {
            return Number(itm.id) === Number(ui.id);
        });
        if (rfq) {
            rfq.assignedTo = ui.user;
        }
    },
    [REPAIRER_RFQ_SET_MESSAGE_STATUS] (state, ui) {
        let rfq = _.find(state.items, (itm) => {
            return Number(itm.id) === Number(ui.id);
        });
        if (rfq) {
            rfq.hasUnreadMessages = ui.value;
        }
    },
    [REPAIRER_RFQ_DELETE] (state, ui) {
        let index = _.findIndex(state.items, (itm) => {
            return Number(itm.id) === Number(ui.id);
        });
        if (index !== -1) {
            state.items.splice(index, 1)
        }
    },
    [REPAIRER_RFQ_STATUS] (state, ui) {
        let index = _.findIndex(state.items, (itm) => {
            return Number(itm.id) === Number(ui.id);
        });
        if (index !== -1) {
            state.items[index].status = ui.status
        }
    },
    [REPAIRER_RFQ_SET_TOKEN](state, ui) {
      state.token.splice(0, state.token.length);
      _.forEach(ui, itm => {
          state.token.push(itm)
      })
    },
    [REPAIRER_RFQ_SET_SEARCH_FILTER_OBJ](state, ui) {
      state.searchFilterObj = ui;
    },
    [REPAIRER_CURRENT_RFQ_UPDATE](state, ui){
        console.log('LOADED RFQ: %o', ui)
        state.currentRfq = ui;
    },
    [REPAIRER_RFQ_FILTER_UPDATE](state, ui){
        state.filter = ui;
    },
    [REPAIRER_RFQ_UPDATED](state, ui) {
        state.isPlayNotificationSound = false;
        if (!_.isEmpty(state.items)) {
            let submittedRfqs = _.filter(state.items, (itm) => {
                return itm.status === 'Submitted';
            })
            let isHasChanges = _.some(submittedRfqs, (itm) => {
                let newItm = _.find(ui, (uiItm) => {
                    return Number(itm.id) === Number(uiItm.id);
                })
                let isChangedStatus = newItm && newItm.status === 'Priced';
                let isPriceSend = newItm && newItm.quoteCompletion !== itm.quoteCompletion && newItm.status === 'Submitted';
                return isChangedStatus || isPriceSend;
            })
            if (isHasChanges && this.getters['currentUser/isSoundNoticeNewRfq']) {
                state.isPlayNotificationSound = true;
            }
        }
        state.items = _.unionBy(ui, state.items, "id");
    },
    [REPAIRER_RFQ_RECEIVED_CURRENT_PAGE](state, ui) {
        state.receivedRFQsCurrentPage = ui;
    },
    [REPAIRER_RFQ_CANCELLED_CURRENT_PAGE](state, ui) {
        state.cancelledRFQsCurrentPage = ui;
    },
    [REPAIRER_RFQ_ORDERED_CURRENT_PAGE](state, ui) {
        state.orderedRFQsCurrentPage = ui;
    },
    [REPAIRER_RFQ_PRICED_CURRENT_PAGE](state, ui) {
        state.pricedRFQsCurrentPage = ui;
    },
    [REPAIRER_RFQ_SUBMITTED_CURRENT_PAGE](state, ui) {
        state.submittedRFQsCurrentPage = ui;
    },
    [REPAIRER_PER_PAGE](state, ui) {
        state.perPage = ui;
    },
};
