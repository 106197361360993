<template>
  <div class="parts-order-details pt-2 d-flex" @contextmenu.prevent="$emit('openRowContext', $event)">
    <div class="time-line"></div>
    <div>
      <div class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">Provide a Reason {{computedReasonLabel}} : </label>
        <div>
          <b-textarea
              v-model="creditPart.orderPartComment"
              :disabled="true"
              max-rows="10"
              placeholder="Write the reason here"
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>
        </div>
        <div v-if="creditPart.orderPartCommentDate" class="d-flex" style="margin-top: 10px">
          <label class="adaptive-input__label font-inter--bold mr-0-5">Added on : </label>
          <div>
            <span>{{ formatDateTime(creditPart.orderPartCommentDate)}}</span>
          </div>
        </div>
        <div v-if="creditPart.orderPartCommentByName" class="d-flex">
          <label class="adaptive-input__label font-inter--bold mr-0-5" style="margin-bottom: 15px">Added by : </label>
          <div>
            <span>{{ creditPart.orderPartCommentByName }}</span>
          </div>
        </div>
      </div>
<!--      <div class="textarea-input-wrapper mb-2">-->
<!--        <label class="adaptive-input__label font-inter&#45;&#45;bold">Supplier Comment {{computedReasonLabel}} : </label>-->
<!--        <div>-->
<!--          <b-textarea-->
<!--              v-model="creditPart.supplierPartComment"-->
<!--              :disabled="true"-->
<!--              max-rows="10"-->
<!--              placeholder="Supplier Comment"-->
<!--              class="ps-scroll adaptive-input-textarea"-->
<!--          >-->
<!--          </b-textarea>-->
<!--        </div>-->
<!--    </div>-->
      <div class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">Reason for credit : </label>
        <div  @contextmenu.prevent="openContext($event, creditPart)">
          <b-textarea
              v-model="creditPart.comment"
              :disabled="!creditPart.isEditCredit"
              max-rows="10"
              @input="isNeedSaveInCreditContext = true"
              :ref="'reason-for-credit' + creditPart.id"
              @keydown.enter.prevent="saveReason"
              placeholder="Write the reason here"
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>

          <template v-if="creditPart.comment">
            <div v-if="creditPart && creditPart.repairerSide && creditPart.repairerSide.creditReason && creditPart.repairerSide.creditReason.addedAt" class="d-flex" style="margin-top: 10px">
              <label class="adaptive-input__label font-inter--bold mr-0-5">Added on : </label>
              <div>
                <span>{{ formatDateTime(creditPart.repairerSide.creditReason.addedAt)}}</span>
              </div>
            </div>
            <div v-if="creditPart && creditPart.repairerSide && creditPart.repairerSide.creditReason && creditPart.repairerSide.creditReason.addedBy" class="d-flex">
              <label class="adaptive-input__label font-inter--bold mr-0-5" style="margin-bottom: 15px">Added by : </label>
              <div>
                <span>{{ creditPart.repairerSide.creditReason.addedBy }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="textarea-input-wrapper mb-2" v-if="creditPart.cancelCreditReason">
        <label class="adaptive-input__label font-inter--bold">Provide a Reason -
          <span v-if="creditPart.status === 'Denied'" style="color:red"> Deny Credit</span>
          <span v-else> Cancel Credit request</span>
           : </label>
        <div>
          <b-textarea
              v-model="creditPart.cancelCreditReason"
              :disabled="true"
              max-rows="10"
              placeholder="Write the reason here"
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>
          <template v-if="creditPart.status === 'Denied'">
            <div v-if="creditPart.cancelCreditOn" class="d-flex" style="margin-top: 10px">
              <label class="adaptive-input__label font-inter--bold mr-0-5">Added on : </label>
              <div>
                <span>{{ formatDateTime(creditPart.cancelCreditOn)}}</span>
              </div>
            </div>
            <div v-if="creditPart.cancelCreditBy" class="d-flex">
              <label class="adaptive-input__label font-inter--bold mr-0-5" style="margin-bottom: 15px">Added by : </label>
              <div>
                <span>{{ creditPart.cancelCreditBy }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import * as dayjs from 'dayjs'

export default {
  name: 'CreditDetails',
  props: {
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    creditPart: {
      type: Object,
      default () {
        return {
          id: 0,
          amount: '',
          comment:'',
          orderPartComment: '',
          orderPartCommentDate: '',
          orderPartCommentByName: '',
          supplierPartComment: '',
          supplierPartCommentDate: '',
          supplierPartCommentByName: '',
          status: '',
          subStatus: 'None',
          orderPartStatus: '',
          cancelCreditReason: '',
          repairerSide:{
            creditReason:{},
            orderPart:{},
          },
        }
      }
    },
  },
  data () {
    return {
      editCredit: false,
      isDeletedCredit: false,
      isNotSavedChangesInCreditContext: false,
      isNeedSaveInCreditContext: false,
      isNotNeedEditInCreditContext: false,
    }
  },
  computed: {
    computedReasonLabel () {
      if (this.creditPart.orderPartStatus === 'CancelPart') {
        return '- Cancel Part'
      } else if (this.creditPart.orderPartStatus === 'ReturnDamagedPart') {
        return '- Return Damaged Part'
      } else if (this.creditPart.orderPartStatus === 'IncorrectPartDelivered') {
        return '- Incorrect Part'
      } else if (this.creditPart.orderPartStatus === 'IncorrectPartOrdered') {
        return '- Incorrect Part Ordered'
      } else if (this.creditPart.orderPartStatus === 'NoLongerNeeded') {
        return '- No Longer Needed'
      } else if (this.creditPart.orderPartStatus === 'ChangeInRepairMethod') {
        return '- Change in Repair Method'
      }
      return ''
    },
  },
  methods: {
    formatDateTime (val) {
      return dayjs(val).format('DD/MM/YYYY hh:mmA')
    },
    focusInReasonForCredit() {
      if (!this.creditPart.isEditCredit) {
        this.creditPart.isEditCredit = true;
      }
      this.$refs['reason-for-credit' + this.creditPart.id].focus();
    },
    closeContext () {
      this.$emit('closeContext')
    },
    openContext(event, part) {
      this.$emit('openContext',
          {
            event: event,
            part: part,
            isNotSavedChanges: this.isNotSavedChangesInCreditContext,
            isNotNeedEdit: this.isNotNeedEditInCreditContext,
            isNeedSave: this.isNeedSaveInCreditContext,
          })
    },
    saveReason () {
      this.editCredit = false;
      this.isDeletedCredit = false;
      this.$emit('autoSave', [{ creditId: this.creditPart.id, reason: this.creditPart.comment }]);
      this.isNotSavedChangesInCreditContext = false;
      this.isNotNeedEditInCreditContext = false;
      this.isNeedSaveInCreditContext = false
      this.closeContext();
    },
    editReason () {
      console.log('edit reason')
      this.editCredit = true
      this.isNotSavedChangesInCreditContext = true
      this.isNotNeedEditInCreditContext = true
      this.closeContext()
      if (!this.creditPart.isEditCredit) {
        this.creditPart.isEditCredit = true;
      }
      setTimeout(() => {
        this.$refs['reason-for-credit' + this.creditPart.id].focus();
      }, 200)
    },
    deleteReason () {
      this.editCredit = false
      this.isDeletedCredit = true
      this.isNotSavedChangesInCreditContext = false
      this.isNotNeedEditInCreditContext = false
      this.isNeedSaveInCreditContext = false
      this.creditPart.comment = null
      this.$emit('autoSave', [{ creditId: this.creditPart.id, reason: this.creditPart.comment }]);
      // this.$emit('autoSave', [{ creditId: this.credit.id, partId: this.part.id, credit: null }])

      this.closeContext()
    },
  },
  mounted () {},
}
</script>

