<template>
 <div class="">
   <repairer-settings v-if="isUserTypeRepairer"></repairer-settings>
   <supplier-settings v-else></supplier-settings>
 </div>
</template>

<script>
import RepairerSettings from './repairer/the-settings';
import SupplierSettings from './supplier/the-settings'
import {mapGetters} from "vuex";

export default {
  name: "the-settings",
  components: {
    RepairerSettings,
    SupplierSettings
  },
  computed: {
    ...mapGetters({
      isUserTypeRepairer: 'currentUser/isUserTypeRepairer',
    }),
  }
}
</script>

<style scoped>

</style>
