
import RepairerSupplierInvite from '../views/repairers/suppliers/supplier-invite.vue';
import ScannerDemo from '../views/demo/scanner-page';

const demoRoutes = [
  {
    path: '/demo/supplier/invite',
    name: 'RepairerSupplierInvite',
    component: RepairerSupplierInvite
  },
  {
    path: '/demo/scan',
    name: 'ScannerDemo',
    component: ScannerDemo
  },
]

export default demoRoutes;
