/*eslint-disable */
import {appConfig} from "../../../config";
import Axios from "axios";
import {AUTH_LOGIN_FAILD, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS, CURRENTCOMPANY_LOGOUT, CURRENTUSER_LOGOUT} from "../../mutation-types";

export function loginByCode({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
        commit(AUTH_LOGIN_REQUEST);
        var url = appConfig.baseAPIURL + '/ir/auth/by-code';

        Axios({url: url, data: data, method: 'POST'})
            .then(resp => {
                let APIToken = resp.data.token;
                dispatch('updateToken', {APIToken});
                resolve(resp);
            })
            .catch(err => {
                console.log(err);
                commit(AUTH_LOGIN_FAILD);
                localStorage.removeItem('token');
                reject(err);
            });
    });
}

export function login({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
        commit(AUTH_LOGIN_REQUEST);
        var url = appConfig.APILogin;

        Axios({url: url, data: data, method: 'POST'})
            .then(resp => {
                let APIToken = resp.data.token;
                let rememberMe = data.rememberMe
                dispatch('updateToken', {APIToken, rememberMe});
                resolve(resp);
            })
            .catch(err => {
                console.log(err);
                commit(AUTH_LOGIN_FAILD);
                localStorage.removeItem('token');
                reject(err);
            });
    });
};

export function logout({commit, dispatch}) {
    commit(AUTH_LOGOUT_SUCCESS);
    localStorage.removeItem('token');
    delete Axios.defaults.headers.common['Authorization'];
     document.location.replace(appConfig.homeURL + '/');
};

export function updateToken({commit}, payload) {
    let APIToken = payload.APIToken;
    // if (payload.rememberMe) {
        localStorage.setItem('token', APIToken);
    // } else {
    //     sessionStorage.setItem('token', APIToken);
    // }

    Axios.defaults.headers.common['Authorization'] = APIToken;
    commit(AUTH_LOGIN_SUCCESS, APIToken);
}

export function successLogin(context) {
   return context.dispatch('initAppAfterLogin', {}, {root: true});
};
