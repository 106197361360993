/*eslint-disable */
import _ from 'lodash';

export function getAllCredits(state) {
    return state.items;
}

export function getApprovedCredits(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'Approved' || i.status === 'PartialApproved')
    });
}

export function getUnderReviewCredits(state) {
    return _.filter(state.items, function (i) {
        return i.status === 'UnderReview'
    });

}

export function getDeniedCredits(state) {
return  _.filter(state.items, function (i) {
    return i.status === 'Denied'
    });
}
export function getCancelledCredits(state) {
    return  _.filter(state.items, function (i) {
        return i.status === 'Cancelled'
    });
}
export function getFilter(state) {
    return state.filter;
}

export function getCreditAllCurrentPage(state) {
    return state.creditAllCurrentPage;
}

export function getCreditUnderCurrentPage(state) {
    return state.creditUnderCurrentPage;
}

export function getCreditApprovedCurrentPage(state) {
    return state.creditApprovedCurrentPage;
}

export function getCreditDeniedCurrentPage(state) {
    return state.creditDeniedCurrentPage;
}
export function getCreditCancelledCurrentPage(state) {
    return state.creditCancelledCurrentPage;
}
export function getPerPage(state) {
    return state.perPage;
}
export function getIsNeedUpdate(state) {
    return state.isNeedUpdate;
}

export function creditsFromUpdate(state) {
    return state.creditsFromUpdate;
}

