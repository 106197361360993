<template>
  <div class="position-notification supplier-dashboard-notifications">
      <div class="position-notification-item" id="chatMsg" style="padding: 0px"
         :options="{ useUrlFragment: false }"
         ref="rfq-view-tabs">
        <div class="dashboard-panel card">
          <div class="dashboard-panel-title">
            <div class="dashboard-panel-title-text">
              <span>Chat Msg</span>
              <span
                  v-if="computedCustomCountForTab['chatMsg']"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip tabs-tooltip-custom', html: true, title:computedCustomTooltipsForTab['chatMsg'], placement:'rightbottom'}"
                  class="dashboard-tab-number">
                {{ computedCustomCountForTab['chatMsg'] }}
              </span>
            </div>
            <div class="dashboard-panel-title-separator"></div>
          </div>
          <div class="search">
            <div class="input-text">
              <input
                  type="text"
                  v-model="filter"
                  placeholder="Search here"
                  class="form-control search-input form-control"
              />
            </div>
            <div class="card-body">
              <!--          <div class="card-title">-->
              <!--            <h4 class="title-text">Chat Msg</h4>-->
              <!--          </div>-->
              <div class="notifications-list">
                <ul class="notifications-list-scroll">
                  <li v-for="itm in messagesFiltered" @click="redirectToRfq(itm)" :class="{'active': !itm.viewedOn}">
                    <div class="title">
                      <div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span>{{getNumber(itm)}} - {{formatDateTime2(itm.createdOn)}}</span></div>
                      <!--                  <div class="date">Today,2:00 PM</div>-->
                    </div>
                    <div class="text">{{itm.message}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  class="position-notification-item" id="notifications" style="padding: 0px">
        <div class="dashboard-panel card">
          <div class="dashboard-panel-title">
            <div class="dashboard-panel-title-text">
              <span>Notifications</span>
              <span
                  v-if="computedCustomCountForTab['notifications']"
                  class="dashboard-tab-number">
                {{ computedCustomCountForTab['notifications'] }}
              </span>
            </div>
            <div class="dashboard-panel-title-separator"></div>
          </div>
          <div class="search">
            <div class="input-text">
              <input
                  type="text"
                  placeholder="Search here"
                  v-model="filter2"
                  class="form-control search-input form-control"
              />
            </div>
          </div>
          <div class="card-body">
            <div class="notifications-list" style="padding: 0 15px;">

              <notifications-component ref="notificationsComponent" :activity="computedActivityListFiltered"></notifications-component>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>
@import url(../../../../styles/dashboard/style.css);
.position-notification{
  position: fixed;
  width: 700px;
  display: flex;
}
.position-notification >>> .tabs-component{
  border-radius: 5px 5px 0 0;
}
.position-notification-item{
  width: 330px;
  margin: 0 10px;
}
.dashboard-panel{
  border-radius: 0 0 5px 5px;
}
.dashboard-panel-title{
  width: 50%;
  max-width: 165px;
  margin: 10px 1.25rem 0;
  font-weight: bold;
}
.dashboard-panel-title-text{
  padding: 10px 20px;
  text-align: center;
}
.dashboard-panel-title-separator{
  border-top: 5px solid #78b0ba;
  height: 3px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.search .input-text,
input.form-control.search-input {
  height: 31px;
  width: 100%;
}

.search .input-text {
  margin-right: 0;
}

.search {
  width: 100%;
  padding: 10px 1.25rem 0;
  margin-bottom: 15px;
  display: block;
}
.notifications-list{
  height: calc(100vh - 285px);
  overflow-y: scroll;
  border-radius: 0 0 5px 5px;
}

.notifications-list ul {
    padding: 0;
    margin: 0;
    margin-top: 15px;
    list-style: none;
    overflow: auto;
}

.notifications-list ul li {
  padding: 7px 10px;
  border-radius: 3px;
  border: 2px solid #D7D7D7FF;
  min-height: 55px;
  cursor: pointer;
  position: relative;
}
.notifications-list ul li:hover{
  border: 2px solid #bdbdbd;
}

.notifications-list ul li.active{
  border: 2px solid #7bbec0
}
.notifications-list ul li.active:hover{
  border: 2px solid #3E8385FF
}
.li-icon{
  font-size: 14px;
  color: #A1A0A0FF;
}
.li-icon{
  position: absolute;
  right: 5px;
  bottom: 0;
}
.notifications-list ul li.active .li-icon{
  color: #2e6ba3;
}
.li-icon-unread{
  font-size: 10px;
  height: 14px;
  width: 14px;
  text-align: center;
  color: #fafafa;
  background-color: #A1A0A0FF;
  border-radius: 50px;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.notifications-list ul li.active .li-icon-unread{
  background-color: #95cbcc;
}
.li-icon-display-none{
  display: none;
}

.notifications-list ul li.active .title {
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  color: #1c1f39;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notifications-list ul li .title {
  font-weight: 500;
}
.notifications-list ul li .title .numb,
.notifications-list ul li .date {
  font-weight: 500;
}

.notifications-list ul li.active .title .numb,
.notifications-list ul li .date {
  font-weight: 600;
}

.notifications-list ul li .text {
  margin-top: 5px;
  line-height: 16px;
  font-size: 11px;
  font-weight: 500;
  color: #1c1f39;
  padding-right: 13px;
}

.notifications-list ul li + li {
  margin-top: 10px;
}
.card-body{
  padding: 0;
}
@media (max-width: 1365px) {
  .search{
    max-width: 100%;
  }
}
@media (max-width: 1440px) {
  .position-notification {
    position: static;
    width: 100%;
  }
  .notifications-list{
    height: 400px;
  }
  .position-notification{
    padding: 0 30px;
    background-color: #ffffff;
  }
}
@media (max-width: 1780px) {
  .position-notification {
    position: static;
    width: 100%;
  }
  .position-notification-item {
    position: static;
    width: 50%;
  }
  .notifications-list{
    height: 400px;
  }
  .position-notification{
    padding: 0 30px;
    background-color: #ffffff;
  }
}
</style>

<style>
.dashboard-tab-number {
  color: #29bbc1;
  background-color: rgba(41, 187, 193, 0.1);
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  width: 24px;
  height: 20px;
  padding: 0;
  white-space: nowrap;
  vertical-align: baseline;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  margin-left: 8px;
  min-width: 20px;
  cursor: pointer;
  min-height: 20px;
}
</style>

<style>
.supplier-dashboard-notifications .card-body .notifications-list .notification_item {
  margin: 0px;
  min-height: 55px;
  padding: 7px 10px;
}

.supplier-dashboard-notifications .card-body .notifications-list .notification_item+.notification_item {
  margin-top: 10px;
}

.supplier-dashboard-notifications .card-body .notifications-list .notification_item-info {
  margin-top: 5px;
  line-height: 16px;
}
</style>

<script>
import {mapGetters} from "vuex";
import * as dayjs from "dayjs";
import _ from "lodash";
import NotificationsComponent from '../../../../components/utility/notifications/notifications'

export default {
    name: "Notifications",
  data() {
    return {
      filter: '',
      filter2: '',
      isMounted: false,
    }
  },
    mounted() {
      this.isMounted = true
    },
    components: {
      NotificationsComponent
    },
    methods: {
      redirectToRfq2(itm) {
        if (itm?.entity?.type == 'order' && itm?.entity?.id) {
          this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.entity.id}})
        }
      },
      formatDateTime2 (val) {
        let format = 'DD/MM'
        if (dayjs(val).isToday()) {
          format = 'hh:mmA'
        }
        return (val) ? dayjs(val).format(format) : ''
      },
      redirectToRfq(itm) {
        // if (_.isEmpty(itm.viewedOn)) {
        //   Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        //     messageIds: [itm.id]
        //   })
        // }

        if (itm.creditId) {
          this.$router.push({name: 'SuppliersCreditView', params: {creditId: itm.creditId, isNeedOpenChat: true}})
        } else if (itm.orderId) {
          this.$router.push({name: 'SupplierPartsOrderView', params: {orderId: itm.orderId, isNeedOpenChat: true}})
        } else if (itm.rfqToSupplierId) {
          this.$router.push({name: 'SupplierRFQView', params: {rfq_id: itm.rfqToSupplierId, isNeedOpenChat: true}})
        }
      },
      getNumber(itm) {
        if (itm.rfqToSupplierId) {
          return this.getRfqNumber(itm.rfqToSupplierId)
        } else if (itm.orderId) {
          return this.getOrderNumber(itm.orderId)
        }
      },
      getRfqNumber(id) {
        let rfq = _.find(this.gettersAllRFQs, (r) => {
          return Number(r.id) === Number(id)
        })
        if (rfq) {
          return ' - ' + rfq.number
        }
        return ''
      },
      getOrderNumber(id) {
        let order = _.find(this.gettersAllOrders, (o) => {
          return Number(o.id) === Number(id)
        })
        if (order) {
          return ' - ' + order.number
        }
        return ''
      },
      formatDateTime (val) {
        return (val) ? dayjs(val).format('DD/MM/YYYY || hh:mm') : ''
      },
      getNotificationsCount() {
        return this.isMounted ? this?.$refs?.notificationsComponent?.computedActivity?.length : 0
      }
    },
    computed: {
      ...mapGetters({
        gettersAllRFQs: 'supplier/rfq/getAllRfqs',
        gettersAllOrders: 'supplier/order/getAllOrders',
        messages: 'supplier/dashboard/getMessages',
        getActivityList: 'supplier/dashboard/getActivityList',
      }),
      computedActivityList() {
        return this.getActivityList
      },
      computedActivityListFiltered() {
        return _.orderBy(_.filter(this.computedActivityList, (m) => {
          return _.includes(_.trim(_.toLower(m?.company?.name)), _.trim(_.toLower(this.filter2)))
        }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
      },
      messagesFiltered() {
        return _.orderBy(_.filter(this.messages, (m) => {
          return _.includes(_.trim(_.toLower(m.companyFrom)), _.trim(_.toLower(this.filter))) || _.includes(_.trim(_.toLower(m.QPNumber)), _.trim(_.toLower(this.filter)))
        }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
      },
      computedCustomCountForTab() {
        return {
          'notifications': this.getNotificationsCount(),
          'chatMsg': _.filter(this.messages, (m) => {return _.isEmpty(m.viewedOn)}).length
        }
      },
      computedCustomTooltipsForTab() {
        return {
          'chatMsg': this.messagesFiltered.length == 1 ? 'Unread Chat Message': 'Unread Chat Messages'
        }
      },
    }
};
</script>
