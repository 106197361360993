<script>
/* eslint-disable */

import _ from 'lodash'

export default {
  name: 'ComponentAssignTo',
  props: {
    users: {
      type: Array,
      default () {
        return []
      }
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      usersFilter: '',
      dropdownPosition: 0,
    }
  },
  computed: {
    computedUsersForAssigne () {
      return _.filter(this.users, (u) => {
        return _.includes(_.trim(_.toLower(u.name)), _.trim(_.toLower(this.usersFilter)))
      })
    },
  },
  methods: {
    clickOnSearchIcon () {
      this.$nextTick(() => {
        if (this.$refs['search-filter-input']) {
          this.$refs['search-filter-input'].focus()
        }
      })
    },
    clickOnAssignedTo (user) {
      this.$emit('click', user)
    },
    focusOnUserItem() {
      setTimeout(() => {
        this.$refs['users-items'][this.dropdownPosition].focus()
      }, 0)
    },
    focusOnInput() {
      setTimeout(() => {
        this.$refs['users-filter-input'].focus()
      }, 0)
    },
    handleKeyup (e) {
      if (this.dropdownPosition == -1 && (e.keyCode == 38 || e.keyCode == 40)) {
        this.dropdownPosition = 0
        return
      }
      switch (e.keyCode) {
        case 38: // up
        {
          if (this.isReverse) {
            this.dropdownPosition = 0
            this.focusOnUserItem()
          } else {
            this.dropdownPosition = this.$refs['users-items'].length - 1
            this.focusOnUserItem()
          }
          break
        }
        case 40: // down
        {
          if (!this.isReverse) {
            this.dropdownPosition = 0
            this.focusOnUserItem()
          } else {
            this.dropdownPosition = this.$refs['users-items'].length - 1
            this.focusOnUserItem()
          }
          break
        }
        default: {
          break
        }
      }
      e.stopPropagation();
    },
    handleKeydownDropdown (e, user) {
      e.preventDefault()
      switch (e.keyCode) {
        case 38: // up
        {
          if (this.isReverse) {
            this.dropdownPosition++
            if (this.dropdownPosition >= this.$refs['users-items'].length) {
              this.dropdownPosition = -1
              this.focusOnInput()
            } else {
              this.focusOnUserItem()
            }
          } else {
            this.dropdownPosition--
            if (this.dropdownPosition > -1) {
              this.focusOnUserItem()
            } else {
              this.dropdownPosition = -1
              this.focusOnInput()
            }
          }
          break
        }
        case 40: // down
        {
          if (this.isReverse) {
            this.dropdownPosition--
            if (this.dropdownPosition > -1) {
              setTimeout(() => {
                this.focusOnUserItem()
              }, 0)
            } else {
              this.dropdownPosition = -1
              this.focusOnInput()
            }
          } else {
            this.dropdownPosition++
            if (this.dropdownPosition >= this.$refs['users-items'].length) {
              this.dropdownPosition = -1
              this.focusOnInput()
            } else {
              setTimeout(() => {
                this.focusOnUserItem()
              }, 0)
            }
          }
          break
        }
        case 13: // enter
        {
          this.clickOnAssignedTo(user)
          break
        }
        default: {
          this.focusOnInput()
        }
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.focusOnInput()
    })
  },
}
</script>

<template>
  <div :class="[className, {'assigned-to-column-reverse':isReverse}]">
    <input
        v-model="usersFilter"
        style="position: relative;"
        class="search-users-input"
        :class="{'search-users-input-reverse':isReverse}"
        @keyup="handleKeyup"
        @keydown="handleKeyup"
        :disabled="isDisabled"
        ref="users-filter-input"
        placeholder="Search"/>
    <a v-for="user in computedUsersForAssigne"
       :key="'assigne-to-'+user.id"
       :ref="'users-items'"
       href="#"
       :disabled="isDisabled"
       @click.prevent="clickOnAssignedTo(user)"
       @keydown="handleKeydownDropdown($event, user)"
       class="text-left font-12 font-normal assign-to-item">{{ user.name }}
    </a>
  </div>
</template>

<style scoped>
.assigned-to-column-reverse {
  flex-direction: column-reverse !important;
  display: flex !important;
}

.assign-to-context {
  position: absolute;
  top: 0px;
  left: -170px;
  width: 170px;
  padding: 0px;
  box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
  border: 3px;
  border-radius: 3px;
  background-color: #FFFFFF;
  max-height: 460px;
  overflow-y: auto;
  background-clip: border-box;
  overflow-x: hidden;
}

.assign-to-item {
  height: auto;
  min-height: 46px;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding: 15px 5px 15px 15px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.search-users-input {
  margin: 15px;
  display: block;
  font-size: 0.875rem;
  white-space: nowrap;
  width: 140px;
}

.assign-to-item:hover,
.assign-to-item:focus {
  background-color: rgba(41, 187, 193, 0.1);
  color: #070707;
}

.search-users-input {
  padding: 5px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin: 10px 10px;
  top: 10px;
  background-color: #fff;
  z-index: 100;
}

.search-users-input.search-users-input-reverse {
  top: 0px;
  bottom: 5px;
  z-index: 100;
}
</style>
