<template>
  <div class="parts-order-view-page-repairer parts-order-view-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>{{ pageTitle }} <span class="autosave">{{ autoSaveMessage }}</span></h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item home">
              <router-link to="/r">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="'/r/orders'">View Parts Management</router-link>
            </li>
            <li class="breadcrumb-item active">
              <span class="d-none d-lg-inline-block">View Parts Ordered</span>
            </li>
          </ol>
        </div>
        <div class="search-filter-in-header">
          <search-filter
              type="estimates"
              v-model="searchFilter"
              :filterValue="searchFilter"
              :additionalMenu="additionalMenu"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              :placeholder="'Search'"
              :isSearch="true"
              :debug="false"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>

      <!-- mobile options -->
      <div class="page-header-nav-options">
        <b-dropdown ref="parts-order-view-options" id="parts-order-view-options parts-order-view-options-mobile" right
                    text="Options"
                    class="dropdown-options options-mobile"
                    :class="[mobileOptionsClass]">
          <template v-slot:button-content>Options
            <span class="dropdown-arrow">
                            <i class='bx bx-down-arrow dropdown-arrow-media-768px'></i>
                        </span>
          </template>
          <b-dropdown-item
              @click="markOrderCompleted"
              :disabled="!isCanBeCompleted"
              v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: !isAllPartsReceived ? `<b>Mark Parts Arrived</b> first before Completing the Order.` : '', placement:'leftbottom'}"
              :class="{
                  'disabled-button': !isCanBeCompleted,
              }">Mark Order Completed</b-dropdown-item>
          <b-dropdown-item
              :disabled="!isCanMarkOnBackOrder"
              @click="markOrderBack"
              :class="{
                  'disabled-button': !isCanMarkOnBackOrder,
              }">Mark on Back Order</b-dropdown-item>

          <b-dropdown-item v-if="isConnectToIBS" @click="sendToIBS">Send to IBODYSHOP</b-dropdown-item>

          <b-dropdown-item
              v-if="isCanSetEditing"
              @click="editOrder(true)"
          >
            Edit Order
          </b-dropdown-item>

          <b-dropdown-item
              v-if="isCanConfirmEditing"
              @click="editOrder(false)"
          >
            Confirm Edit Order
          </b-dropdown-item>

          <template v-if="lengthSelectedRows<1">
            <b-dropdown-item
                v-if="isConnectToIBS"
                :disabled="!isCanEnableMarkReceivedAll"
                :class="{'disabled-button': !isCanEnableMarkReceivedAll}"
                @click="handlePartReceivedAll">
              Mark All Parts Received
            </b-dropdown-item>

            <b-dropdown-item
                :disabled="!isCanEnableMarkArrivedAll"
                :class="{'disabled-button': !isCanEnableMarkArrivedAll}"
                @click="handlePartArrivedAll">
              Mark All Parts Arrived
            </b-dropdown-item>
<!--            <b-dropdown-item-->
<!--                :disabled="!isCanEnableMarkAll"-->
<!--                :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                @click="handleIncorrectPartAll">-->
<!--              Mark All Incorrect Delivered Parts-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item-->
<!--                :disabled="!isCanEnableMarkAll"-->
<!--                :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                @click="handleIncorrectOrderedPartAll">-->
<!--              Mark All Incorrect Ordered Parts-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item-->
<!--                :disabled="!isCanEnableMarkAll"-->
<!--                :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                @click="handleReturnDamagedPartAll">-->
<!--              Mark All Return Damaged Parts in Transport-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item-->
<!--                :disabled="!isCanEnableMarkAll"-->
<!--                :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                @click="handleDamagedPartExWarehousedPartAll">-->
<!--              Mark All Return Damaged Parts EX Warehouse-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item-->
<!--                :disabled="!isCanEnableMarkAll"-->
<!--                :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                @click="handleNoLongerNeededPartAll">-->
<!--              Mark All No Longer Needed-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item-->
<!--                :disabled="!isCanEnableMarkAll"-->
<!--                :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                @click="handleChangeInRepairMethodPartAll">-->
<!--              Mark All Change in Repair Method-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item class="cancel-option" :disabled="!isCanEnableMarkAll"-->
<!--                             @click="handleCancelPartAll">Cancel All Parts-->
<!--            </b-dropdown-item>-->
          </template>
          <template v-if="lengthSelectedRows>1">
            <b-dropdown-item
                v-if="isConnectToIBS"
                :disabled="!isCanEnableMarkReceivedSelected"
                :class="{'disabled-button': !isCanEnableMarkReceivedSelected}"
                @click="handlePartReceivedButton">
              Mark Selected Parts Received
            </b-dropdown-item>
            <b-dropdown-item
                :disabled="!isCanEnableMarkArrivedSelected"
                :class="{'disabled-button': !isCanEnableMarkArrivedSelected}"
                @click="handlePartArrivedButton">
              Mark Selected Parts Arrived
            </b-dropdown-item>
            <template
                style="width:100%"
            >
              <b-dropdown
                  ref="dropdown-2"
                  :class="{'disabled-button': isOrderDenied || isOrderCancelled}"
                    id="dropdown-2"
                  dropleft
                  style="height: 46px; width: 100%">
                <template v-slot:button-content>
                  <template>
                    <div class="d-flex align-items-center">
                      <div class="">
                        <i style="margin-bottom: 3px;" class='bx bx-chevron-left font-18'></i>
                      </div>
                      <div class="raise-text d-flex align-items-center">Create Credit Request</div>
                    </div>
                  </template>
                </template>
                <template>
                  <div class="">
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(null, $event, null, handleIncorrectPartButton())">
                      Mark Selected Incorrect Delivered Parts
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(null, $event, null, handleIncorrectPartOrderedButton())">
                      Mark Selected Incorrect Ordered Parts
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(null, $event, null, handleReturnDamagedPartButton())">
                      Mark Selected Damaged Parts in Transport
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(null, $event, null, handleDamagedPartExWarehouseButton())">
                      Mark Selected Damaged Parts EX Warehouse
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(null, $event, null, handleNoLongerNeededButton())">
                      Mark Selected No Longer Needed
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(null, $event, null, handleChangeInRepairMethodButton())">
                      Mark Selected Change in Repair Method
                    </b-dropdown-item>
                  </div>
                </template>
              </b-dropdown>
            </template>
            <b-dropdown-item v-if="isCanCancelParts" @click="cancelParts(false)">
              Cancel Selected Parts
            </b-dropdown-item>
          </template>
          <template v-else-if="lengthSelectedRows === 1">
            <b-dropdown-item
                v-if="isConnectToIBS"
                :disabled="!isCanEnableMarkReceivedSelected"
                :class="{'disabled-button': !isCanEnableMarkReceivedSelected}"
                @click="handlePartReceivedButton(null, null, true)">
              Mark Part Received
            </b-dropdown-item>

            <b-dropdown-item
                :disabled="!isCanEnableMarkArrivedSelected"
                :class="{'disabled-button': !isCanEnableMarkArrivedSelected}"
                @click="handlePartArrivedButton(null, null, true)">
              Mark Part Arrived
            </b-dropdown-item>


            <template
                v-if="!(selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id > 0)"
                style="width:100%"
            >

              <b-dropdown
                  ref="dropdown-2"
                  :class="{'disabled-button': isOrderDenied || isOrderCancelled}"
                  id="dropdown-2"
                  dropleft
                  style="height: 46px; width: 100%">
                <template v-slot:button-content>

                  <template v-if="!(selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id > 0)">
                    <div class="d-flex align-items-center">
                      <div class="">
                        <i style="margin-bottom: 3px;" class='bx bx-chevron-left font-18'></i>
                      </div>
                      <div class="raise-text d-flex align-items-center">Create Credit Request</div>
                    </div>
                  </template>
                </template>

                <template>
                  <div class="">
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleIncorrectPartButton(null, null, true))">
                      Mark Incorrect Part Delivered
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleIncorrectPartOrderedButton(null, null, true))">
                      Mark Incorrect Part Ordered
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleReturnDamagedPartButton(null, null, true))">
                      Mark Damaged Part in Transport
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleReturnDamagedPartButton(null, null, true))">
                      Mark Damaged Part EX Warehouse
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleNoLongerNeededButton(null, null, true))">
                      Mark No Longer Needed
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleChangeInRepairMethodButton(null, null, true))">
                      Mark Change in Repair Method
                    </b-dropdown-item>

                    <template v-if="selectedRow[0].qty > 1">
                      <template>
                        <div class="qty-menu-options">
                          <p class="text-center pt-2 font-12 font-inter--semibold">QTY to Return</p>
                          <div class="d-flex px-3 align-items-center">
                            <p @click="minusReturnQty(selectedRow[0])"
                               class="pt-3 pl-2 pointer font-14 font-inter--semibold">
                              -</p>
                            <limited-input
                                ref="qtyInput"
                                class="raised-credit-request-sub-menu-qty-input form-control"
                                :class="{'flashing': selectedRow[0].returnQty == selectedRow[0].qty, 'color-red': selectedRow[0].returnQty != selectedRow[0].qty}"
                                :not-available-value="notAvailableQty(selectedRow[0].qty)"
                                style="text-align: center"
                                :isConversely="true"
                                v-model="selectedRow[0].returnQty"
                                @update="value => selectedRow[0].returnQty = value">
                            </limited-input>
                            <p @click="plusReturnQty(selectedRow[0])"
                               class="pt-3 pr-2 pointer font-14 font-inter--semibold">
                              +</p>
                          </div>
                          <div class="raised-credit-request-qty-btn">
                            <!--                                <button @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty)" class="btn btn-primary mx-0 waves-effect waves-light sec_btn confirm-button">-->
                            <!--                                  Confirm-->
                            <!--                                </button>-->
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>

              </b-dropdown>

            </template>

            <b-dropdown-item
                v-if="selectedRow && selectedRow[0] && selectedRow[0].isUndoPartLineChange"
                :disabled="!!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id) || (isOrderDenied || isOrderCancelled)"
                :class="{'disabled-button': !!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id) || (isOrderDenied || isOrderCancelled)}"
                @click="undoPartLineChange">Undo Part Line Change
            </b-dropdown-item>
            <b-dropdown-item v-if="isCanCancelParts" @click="cancelParts(false)">
              Cancel Selected Part
            </b-dropdown-item>
          </template>
          <b-dropdown-item
              v-if="order.state != 1"
              @click="markOrderCurrent"
          >
            Move to Current
          </b-dropdown-item>
          <b-dropdown-item v-if="isCanCancelOrder" @click="cancelParts(true)" :disabled="isDisabledCancelOrder">
            Cancel Order
          </b-dropdown-item>
          <b-dropdown-item
              v-if="isMobile"
              @click="addPhoto(true)"
          >
            Take Photo
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-if="isShowStickyHeader" class="header-scroll-position-fixed active">
      <div>
        <vin-date
            class="header-scroll-position-fixed-vin-date"
            :code="order.trackingNumber" :vin="order.vehicle.vin ? order.vehicle.vin : ''"
            :invoice-number="order.invoiceNumber"
            :isShowInvoiceNbr="true"
            :isDisabledInvoiceNumber="true"
            :isShowRFQNbr="true"
            :isRepairer="true"
            :isShowOrderNbr="true"
            :status="order.status"
            :orderNumber="order.number"
            :rfq-number="order && order.rfq && order.rfq.number ? order.rfq.number : ''"
            :date="order.vehicle.dom ? order.vehicle.dom: ''"
            :repairerQuoteNumber="order.QPNumber"
            @onChangeRepairerQuoteNumber="onChangeRepairerQuoteNumber"
            @goToRfq="goToRfq"
            @onChangeInvoiceNbr="onChangeInvoiceNbr"
        ></vin-date>
      </div>
      <div>
        <sticky-header
            class="position-sticky-header"
            :isShowNetProfit="order && order.supplier && (order.supplier.partsType === 'OEM-GEN' || order.supplier.partsType === 'OEM-PAR')"
            :isCheckedAll="isCheckedAllValue"
            @onChangedCheckedAll="onChangedCheckedAll"
            @sortBy="sortBy"
        ></sticky-header>
      </div>
    </div>
    <div class="parts-order-view-body">
      <tabs ref="parts-order-view-tabs" :custom-counts="computedCustomCountForTab"  @changed="changedTab">
        <tab name="Ordered Parts" id="ordered-parts" :class-name="'parts-order-view-tab-1'">
          <div class="part-order-additional-block d-flex">
            <div class="parts-order-title col-sm-5">
              <ps-label-value class="ps-label-little-width" label="Supplier">
                {{ order.supplier.name }}
              </ps-label-value>

              <ps-label-value class="ps-label-little-width" label="Type">
                {{ getTypes(order.supplier.types) }}
              </ps-label-value>

              <ps-label-value class="ps-label-little-width" label="Parts Dept PH">
                {{ order.supplier.partsDeptPH }}
              </ps-label-value>

            </div>
            <div class="part-order-additional-block d-flex col-sm-7" style="justify-content: flex-end">
              <div class="parts-order-title ">

                <ps-label-value label="Order Placed">{{
                    formatDateTime(order.dateCreated)
                  }}
                </ps-label-value>

                <ps-label-value label="Parts Delivery Required">
                  {{ order.dateDue && order.dateDue !== 'null' ? order.dateDue : 'Next available run' }}
                </ps-label-value>

                <div class="ps-label-value " :class="{'new-delivery-blinker': isCanBlinking}" v-if="getSupplierDeliveryDate">
                  <span class="ps-label-value__label" style="color: orange">New Delivery ETA:</span>
                  <span class="ps-label-value__value">
                        <span style="color: orange">{{getSupplierDeliveryDate}}</span>
                      </span>
                </div>

                <ps-label-value label="Special Instruction">
                  <div class="ps-scroll font-inter--bold p-0 special-instruction-block special-instruction-block-2" v-html="orderInstruction"></div>
                </ps-label-value>

              </div>
              <div class="parts-order-title ">
                <customer-account-deliver
                    :customer="order.business"
                    :estimator="order.estimator"
                    account=""
                    style="margin-top: 0px;"
                    :delivery-to="deliveryToText">
                </customer-account-deliver>
              </div>
            </div>
          </div>

          <div class="additional-line" id="additional-info">
            <vin-date :code="order.trackingNumber" :vin="order.vehicle.vin ? order.vehicle.vin : ''"
                      :invoice-number="order.invoiceNumber"
                      :isShowInvoiceNbr="true"
                      :isDisabledInvoiceNumber="true"
                      :isShowRFQNbr="true"
                      :isRepairer="true"
                      :isShowOrderNbr="true"
                      :status="order.status"
                      :orderNumber="order.number"
                      :rfq-number="order && order.rfq && order.rfq.number ? order.rfq.number : ''"
                      :date="order.vehicle.dom ? order.vehicle.dom: ''"
                      :repairerQuoteNumber="order.QPNumber"
                      @onChangeRepairerQuoteNumber="onChangeRepairerQuoteNumber"
                      @goToRfq="goToRfq"
                      @onChangeInvoiceNbr="onChangeInvoiceNbr"
                      ></vin-date>
            <div class="repairer-order-view-table">
            <b-table
                responsive
                hover
                selectable
                ref="orderTable"
                :select-mode="'range'"
                :items="orderItems"
                :fields="orderFields"
                :id="'order-table'"
                :primary-key="'id'"
                :tbody-tr-class="rowClass"
                @row-clicked="handleRowClicked"
                @row-selected="handleRowSelected"
                :sort-by.sync="sortByTable"
                :sort-desc.sync="sortDesc"
                @row-contextmenu="handleRightClick2"
                style="margin-bottom: 0px !important; overflow-y: hidden; scrollbar-width: auto !important;"
                class="mb-15 mt-4 parts-order-margin-table repairer-order-part-list">
              <template v-slot:table-colgroup="scope">
                <col class="parts-order-view__row">
                <col class="parts-order-view__row parts-order-view__h45px">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--lg">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--xs">
                <col class="parts-order-view__row parts-order-view__row--sm">
                <col class="parts-order-view__row parts-order-view__row--sm">
                <col class="parts-order-view__row parts-order-view__row--sm">
                <col class="parts-order-view__row parts-order-view__row--lg">
                <col class="parts-order-view__row parts-order-view__row--sm">
              </template>

              <template v-slot:head(line)="data">
                <div class="d-flex">
                  <label class="form-check-inline checkbox">
                    <input class="form-check-input"
                           type="checkbox"
                           v-model="isCheckedAll"
                           id="all-line-check"
                    >
                    <span class="icon clickable clickable-active"><i
                        class='bx bx-check'></i></span>
                  </label>
                  <span style="padding-top: 12px;">Line</span>
                </div>
              </template>

              <template v-slot:cell(line)="row">
                <div class="d-flex">
                  <label class="form-check-inline checkbox">
                    <input class="form-check-input"
                           type="checkbox"
                           @change="onCheckboxChange(row.item, row.item.isChecked)"
                           v-model="row.item.isChecked"
                    >
                    <span class="icon clickable clickable-active"><i
                        class='bx bx-check'></i></span>
                  </label>
                  <span class="part-list-line-number">{{ row.index + 1 }}</span>
                </div>
              </template>

              <template v-slot:cell(supplierDeliveryDate)="row">
                <template v-if="row.item.supplierDeliveryDate">
                  <span class="" :class="{'new-delivery-blinker color-orange': row.item.supplierDeliveryDate !== formatDate(order.repairerDeliveryDate) && isCanBlinking}">{{ row.item.supplierDeliveryDate }}</span>
                </template>
                <template v-else-if="order.supplierDeliveryDate">
                  <span class="" :class="{'new-delivery-blinker': isCanBlinking}" style="color: orange">{{ order.supplierDeliveryDate}}</span>
                </template>
                <template v-else-if="order.repairerDeliveryDate">
                  {{ formatDate(order.repairerDeliveryDate,'DD/MM/YYYY h:iA')}}
                </template>
                <template v-else>
                  Next available run
                </template>
              </template>

              <template v-slot:cell(netProfit)="row">
                <span v-if="isCreditApprovedOrUnderReview(row)"> 0%</span>
                <span v-else>{{ getProfit(row.item) }}</span>
              </template>

              <template v-slot:cell(creditNumber)="row">
                <span v-if="row.item.credit && row.item.credit.id">{{row.item.credit.number}}</span>
                <div
                    v-if="row.item.credit && row.item.credit.id"
                    :class="{'red-icon': row.item.credit.status == 'Denied'}"
                >{{getCreditStatus(row.item.credit.status)}}</div>
              </template>

              <template v-slot:cell(partStock)="row">
                <template v-if="row.item.type !== 'delivery'">

                  <template v-if="row.item.isEdit">
                    <stock-select
                        :id="row.item.id"
                        :options="stockOptions"
                        v-model="row.item.stockValue"
                        @onSelected="selectStockStatus($event, row.item)"
                        @onKeyPressRight="debounceOnKeyPressRight('stock-select-' + row.item.id)"
                        @onKeyPressLeft="debounceOnKeyPressLeft('stock-select-' + row.item.id, $event)"
                        :isNeedDateRange="true"
                        style="width: 135px"
                        :isPartOrderPage="true"
                        :ref="'stock-select-' + row.item.id"
                    >
                    </stock-select>
                  </template>
                  <template v-else>
                    <div class="d-flex align-items-center">
                      <div class="text-nowrap" style="margin-right: 5px">{{ getStockValue(row.item) }}</div>
                      <div class="d-flex justify-content-between">
                        <i class="font-20"
                           v-if="row.item.stock > 0 || row.item.stock <= 7"
                           :class="{
                            // In Stock
                            'bx bx-check-circle green-icon-2': row.item.stock === 1,
                            // EX Warehouse
                            'bx bx-check-circle orange-icon-2': row.item.stock === 6,
                            'bx bx-check-circle icon-orange-blinking': row.item.stock === 6 && row.item.orderStockComment  && isCanBlinking,
                            // Low Stock
                            'bx bx-check-circle blue-icon-2': row.item.stock === 2,
                            // No Stock, No Longer Available, Not Applicable
                            'bx bx-minus-circle gray-icon-2': row.item.stock === 3 || row.item.stock === 5 || row.item.stock === 7,
                            // Back Order
                            'bx bx-check-circle red-icon': row.item.stock === 4,
                            'bx bx-check-circle red-icon icon-red-blinking': row.item.stock === 4 && row.item.orderStockComment  && isCanBlinking,
                          }"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: row.item.orderStockComment  ? row.item.orderStockComment  : '', placement:'leftbottom'}"
                        >
                        </i>
                        <i v-if="row.item.isNonReturnable"  v-b-tooltip.hover="{customClass: 'ps-tooltip order-non-returnable-tooltip', html: true, title: getNRTooltip(row.item), placement:'leftbottom'}" class='bx bx-error font-20 orange-icon'></i>
                      </div>
                      <!--                    <i v-if="row.item.orderStockComment  != ''"-->
                      <!--                       v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getStockComment(row.item), placement:'leftbottom'}"-->
                      <!--                       class='bx bx-note icon-stock-comment text-warning pl-1'-->
                      <!--                       style="font-size: 18px;"></i>-->
                    </div>
                  </template>
                  <div class="" style="text-align: left;" v-if="row.item.isNonReturnable">
                    Non-Returnable Accepted
                  </div>
                  <div class="" style="text-align: left;" v-if="isOrderAccepted(row.item) || isOrderDeclined(row.item)">
                    Back Order <span v-if="isOrderAccepted(row.item)">Accepted</span><span v-else>Declined</span>
                  </div>
                </template>
              </template>

              <template v-slot:cell(qty)="row">
                <template v-if="row.item.type !== 'delivery'">
                  <template v-if="row.item.isEdit">
                    <number-formatter
                        :ref="'qty-' + row.item.id"
                        v-model="row.item.qty"
                        @focus="onFocus('qty-' + row.item.id)"
                        @blur="onBlur(row.item, 'qty', 200)"
                        @changedValue="value => row.item.qty = value"
                        @onKeyPressRight="event => debounceOnKeyPressRight('qty-' + row.item.id, event)"
                        @onKeyPressLeft="event => debounceOnKeyPressLeft('qty-' + row.item.id, event)"
                        @onKeyPressUp="debounceOnKeyPressUp('qty-' + row.item.id)"
                        @onKeyPressDown="debounceOnKeyPressDown('qty-' + row.item.id)"
                        :start-value="row.item.qtyCopy"
                        :isCanOnlyIncrease="true"
                        :isCanBeZero="false"
                        :class="{
                          'green-cell': Number(row.item.originalQty) < Number(row.item.qty),
                          'red-cell': Number(row.item.originalQty) > Number(row.item.qty),
                        }"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart('Qty', row.item), placement:'leftbottom'}"
                        class="form-control numeric"
                        format="0"></number-formatter>
                  </template>
                  <template v-else>
                    <span :class="{ 'green-cell': Number(row.item.originalQty) < Number(row.item.qty),'red-cell': Number(row.item.originalQty) > Number(row.item.qty), }" v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart('Qty', row.item), placement:'leftbottom'}">
                      {{row.item.qty}}
                    </span>
                  </template>

                </template>
              </template>

              <template v-slot:cell(qtyRcv)="row">
                <template v-if="row.item.type !== 'delivery'">
                  <template v-if="row.item.isEdit && !(isCreditApprovedOrUnderReview(row))">
                    <number-formatter
                        :ref="'qtyRcv-' + row.item.id"
                        v-model="row.item.qtyRcv"
                        @focus="onFocus('qtyRcv-' + row.item.id)"
                        @blur="onBlur(row.item, 'qtyRcv')"
                        @changedValue="value => row.item.qtyRcv = value"
                        :isCanBeZero="false"
                        @onKeyPressRight="event => debounceOnKeyPressRight('qtyRcv-' + row.item.id, event)"
                        @onKeyPressLeft="event => debounceOnKeyPressLeft('qtyRcv-' + row.item.id, event)"
                        @onKeyPressUp="debounceOnKeyPressUp('qtyRcv-' + row.item.id)"
                        @onKeyPressDown="debounceOnKeyPressDown('qtyRcv-' + row.item.id)"
                        :class="{
                          'green-cell': Number(row.item.originalQtyRcv) < Number(row.item.qtyRcv),
                          'red-cell': Number(row.item.originalQtyRcv) > Number(row.item.qtyRcv) || (isCreditApprovedOrUnderReview(row)),
                        }"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart2('Qty', row.item), placement:'leftbottom'}"
                        class="form-control numeric"
                        format="0"></number-formatter>
                  </template>
                  <template v-else>
                    <span
                        :class="{ 'green-cell': Number(row.item.originalQtyRcv) < Number(row.item.qtyRcv),
                        'red-cell': Number(row.item.originalQtyRcv) > Number(row.item.qtyRcv) || (isCreditApprovedOrUnderReview(row)), }"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart2('Qty Rcv', row.item), placement:'leftbottom'}">
                      <span v-if="isCreditApprovedOrUnderReview(row)">
                        {{ row.item.qtyRcv - row.item.qty }}
                      </span>
                      <span v-else>{{row.item.qtyRcv}}</span>
                    </span>
                  </template>
                </template>
              </template>

              <template v-slot:cell(rrp)="row">
                <template v-if="row.item.isEdit">
                  <number-formatter
                      :ref="'rrp-' + row.item.id"
                      :isCanBeZero="false"
                      v-model="row.item.rrp"
                      @focus="onFocus('rrp-' + row.item.id)"
                      @blur="onBlur(row.item, 'rrp')"
                      @onKeyPressRight="event => debounceOnKeyPressRight('rrp-' + row.item.id, event)"
                      @onKeyPressLeft="event => debounceOnKeyPressLeft('rrp-' + row.item.id, event)"
                      @onKeyPressUp="debounceOnKeyPressUp('rrp-' + row.item.id)"
                      @onKeyPressDown="debounceOnKeyPressDown('rrp-' + row.item.id)"
                      style="min-width: 70px;"
                      :original-value="row.item.originalRrp"
                      @changedValue="value => row.item.rrp = value"
                      :class="{
                        'color-green': Number(row.item.rrp) > Number(row.item.originalRrp),
                        'color-red': Number(row.item.rrp) < Number(row.item.originalRrp)}"
                      class="form-control numeric money-input"
                      format="$0,0.00"></number-formatter>
                </template>
                <template v-else>
                  <span
                      v-if="row.item.type !== 'delivery'"
                      :class="{
                        'color-green': Number(row.item.rrp) > Number(row.item.originalRrp),
                        'color-red': Number(row.item.rrp) < Number(row.item.originalRrp)}">
                  {{ row.item.rrp | formatMoney }}</span>
                </template>
                <!--              {{row.item.originalRrp}}-->
              </template>

              <template v-slot:cell(price)="row">
                <template v-if="row.item.isEdit && !(isCreditApprovedOrUnderReview(row))">
                  <number-formatter
                      :ref="'price-' + row.item.id"
                      @focus="onFocus('price-' + row.item.id)"
                      @blur="onBlur(row.item, 'price')"
                      @onKeyPressRight="event => debounceOnKeyPressRight('price-' + row.item.id, event)"
                      @onKeyPressLeft="event => debounceOnKeyPressLeft('price-' + row.item.id, event)"
                      @onKeyPressUp="debounceOnKeyPressUp('price-' + row.item.id)"
                      @onKeyPressDown="debounceOnKeyPressDown('price-' + row.item.id)"
                      :isCanBeZero="false"
                      @changedValue="value => row.item.price = value"
                      v-model="row.item.price"
                      style="min-width: 70px;"
                      :original-value="row.item.originalPrice"
                      class="form-control numeric money-input"
                      :class="{
                        'green-cell': Number(row.item.price) > Number(row.item.originalPrice),
                        'red-cell': Number(row.item.price) < Number(row.item.originalPrice) || (isCreditApprovedOrUnderReview(row))}"
                      format="$0,0.00"
                  ></number-formatter>
                </template>
                <template v-else>
                  <span
                      :class="{
                    'color-green': Number(row.item.price) > Number(row.item.originalPrice),
                     'color-red': Number(row.item.price) < Number(row.item.originalPrice) || (isCreditApprovedOrUnderReview(row))}">
                    <span v-if="row.value && isCreditApprovedOrUnderReview(row)">-</span>
                    {{ row.item.price | formatMoney }}</span>
                </template>
                <!--              {{ row.item.originalPrice }}-->
              </template>


                            <template v-slot:cell(partType)="row">
                                <template v-if="row.item.type !== 'delivery'">
                  {{ order.supplier.partsType }}
                </template>
                            </template>
                            <template v-slot:cell(name)="row">
                              <div v-if="row && row.item && row.item.type && row.item.type === 'delivery'" class="">
                                {{ row.item.deliveryName }}
                              </div>
                              <div v-else class="">
                                {{ row.item.name }}
                              </div>
                            </template>
                            <template v-slot:cell(name)="row">
                                <div v-if="row && row.item && row.item.type && row.item.type === 'delivery'" class="">
                                    {{ row.item.deliveryName }}
                                </div>
                                <div v-else class="">
                                    {{ row.item.name }}
                                </div>
                            <div style="color: #85868e">
                  {{row.item.altPartName}}
                </div>
                <div v-if="isOrderCancelled" class="text-danger font-12">
                  Order Cancelled By <span v-if="row.item.cancelStatus === 'CancelledByCustomer'">{{row.item.lastChangeStatusBy}}</span><span v-else>Supplier</span>
                </div>
                <div
                    class=""
                    v-if="row.item.status == 'Received' && !row.item.isReceivedSentToIbodyshop"
                    style="color: #F59345 !important; font-weight: 600; width: auto; white-space: nowrap; text-align: start; padding-top: 5px;">
                  Ready to Update-iBodyshop
                </div>
                <p v-if="row.item.cancelStatus === 'ReinstatedBySupplier' || row.item.cancelStatus === 'ReinstatedByCustomer'"
                   class="text-success py-2 m-0 d-inline-block"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip reinstate-cancel-tooltip', html: true, title: row.item.reinstateComment ? `Comment: <br>${row.item.reinstateComment}` : '', placement:'leftbottom'}">
                  <span v-if="row.item.cancelStatus === 'ReinstatedBySupplier'">Reinstate by Supplier</span>
                  <span v-else>Reinstate by Customer</span>
                </p>
              </template>

              <template v-slot:cell(partNumber)="row">
                <template>
                  {{ row.item.partNumber }}
                </template>

                <div v-if="isHasShortComment(row.item)"
                     class="part-number-stock-gray"
                     style="padding-left: 0px"
                     :class="{
                        'part-number-stock-orange': row.item.stock === 6 && isCanBlinking,
                        'part-number-stock-red': row.item.stock === 4 && isCanBlinking,
                       }">
                  {{ getShortComment(row.item) }}
                </div>
              </template>

              <template v-slot:cell(statusId)="row">
                <div class="d-flex flex-row justify-content-center" v-if="row.item.type !== 'delivery'">
<!--                  <i v-if="row.item.stock === 4 && row.item.status !== 'Shipped' && row.item.status !== 'CancelPart' && row.item.status !== 'Received'" @click="clickOnIcon(row.item.status, row)"-->
<!--                     class="bx bx-purchase-tag-alt processing-icon-orange"-->
<!--                     v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon', title: 'On Back Order', placement:'leftbottom', html: true}"></i>     -->
                  <i v-if="row.item.stock === 4 && row.item.status == 'Created'" @click="clickOnIcon(row.item.status, row)"
                     class="bx bx-purchase-tag-alt processing-icon-orange"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon', title: 'On Back Order', placement:'leftbottom', html: true}"></i>
                  <i v-else
                     @click="clickOnIcon(row.item.status, row)"
                     :class="[getIconClass(row.item),
                     {
                       'gray-icon': getIconClass(row.item).includes('bx-package') && row.item.stock !== 1 && row.item.stock !== 6,
                       'text-success': getIconClass(row.item).includes('bx-package') && row.item.stock === 1,
                       'processing-icon-orange': getIconClass(row.item).includes('bx-package') && row.item.stock === 6
                     }]"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getIconTooltip(row.item), placement:'leftbottom', html: true}"></i>
                  <i @click="clickOnIcon(row.item.status, row)" v-if="showRequestCredit(row.item)"
                     :class="{
                       'red-icon': row.item && row.item.credit && (row.item.credit.creditPartStatus === 'Denied' || row.item.credit.creditPartStatus === 'Cancelled'),
                       'orange-icon':  row.item && row.item.credit && row.item.credit.creditPartStatus === 'UnderReview',
                       'green-icon':  row.item && row.item.credit && row.item.credit.creditPartStatus === 'Approved',
                     }"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getIconTooltipForCredit(row.item), placement:'leftbottom', html: true}"
                     class="ml-1 bx bx-dollar-circle"></i>

                  <i @click.prevent.stop="showModal(row.item)" v-if="isOrderReceived(row.item)"
                     :ref="'icon-' + row.item.id"
                     class='bx bx-detail text-warning' style="font-size: 24px;"></i>
                </div>

                <b-tooltip
                    :target="() => $refs['icon-' + row.item.id]"
                    :customClass="'ps-tooltip-status-icon ps-tooltip-status-icon-changed back-order-tooltip'"
                    :placement="'rightbottom'"
                    triggers="hover">
                  <span v-html="getTooltip(row.item)"></span>
                  <br/><b class="clickable" @click="showModal(row.item)">Click to View</b>
                </b-tooltip>

              </template>
              <template v-slot:cell(status)="row" #cell(show_details)="row">
                <span :class="getNameClass(row.item)">{{ viewPartStatus(row.value, row.item.stock) }}</span>
                <div v-if="row.item.credit && row.item.credit.status" class="font-italic">
                  {{ viewCreditStatus(row.item.credit.status) }}
                </div>
              </template>
              <template v-slot:cell(invoiceNumber)="row">
                <template v-if="row.item.isEdit">
                  <input
                      type="text"
                       :ref="'invoice-' + row.item.id"
                      @focus="onFocus('invoice-' + row.item.id)"
                      @blur="onBlur(row.item, 'invoiceNumber')"
                        @keydown.right="event => debounceOnKeyPressRight('invoice-' + row.item.id, event)"
                        @keydown.left="event => debounceOnKeyPressLeft('invoice-' + row.item.id, event)"
                        @keydown.up="debounceOnKeyPressUp('invoice-' + row.item.id)"
                        @keydown.down="debounceOnKeyPressDown('invoice-' + row.item.id)"
                      class="form-control"
                      v-model="row.item.invoiceNumber">
                </template>
                <template v-else>
                  <div>{{ row.item.invoiceNumber }}</div>
                </template>
              </template>
              <template v-slot:cell(workshopLocation)="row">
                <template v-if="row.item.isEdit">
                  <input
                      type="text"
                       :ref="'location-' + row.item.id"
                      @focus="onFocus('location-' + row.item.id)"
                      @blur="onBlur(row.item, 'location')"
                        @keydown.right="event => debounceOnKeyPressRight('location-' + row.item.id, event)"
                        @keydown.left="event => debounceOnKeyPressLeft('location-' + row.item.id, event)"
                        @keydown.up="debounceOnKeyPressUp('location-' + row.item.id)"
                        @keydown.down="debounceOnKeyPressDown('location-' + row.item.id)"
                      class="form-control"
                      v-model="row.item.location">
                </template>
                <template v-else>
                  <div v-if="row.item.location">{{ row.item.location }}</div>
                  <div v-else>{{ order.deliveryTo.warehouseName }}</div>
                </template>
              </template>
              <template v-slot:cell(total)="row">
                <span
                    :class="{
                      'green-cell': Number(row.item.originalQty) < Number(row.item.qty) && Number(row.value) != 0,
                      'red-cell': Number(row.item.originalQty) > Number(row.item.qty) && Number(row.value) != 0 || (isCreditApprovedOrUnderReview(row)),
                    }"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart('Total', row.item), placement:'leftbottom'}"
                >
                  <span v-if="row.value && isCreditApprovedOrUnderReview(row)">-</span>{{ row.value | formatMoney }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">
                  {{ data.label }}<i></i>
                </div>
              </template>
              <template v-slot:row-details="row">
                <div class="td-wrapper">
                  <parts-order-details
                      :ref="'parts-order-details-' + row.item.id"
                      :part="row.item"
                      :marginCount="computedTableWidth"
                      :minCellWith="computedTableMinCellWidth"
                      @blur="onPartsOrderDetailsBlur"
                      @focus="onPartsOrderDetailsFocus"
                      @autoSave="clickSave"
                      @openRowContext="event => handleRightClick2(row, row.index, event)"
                      @closeContext="closeContextForReasonAction"
                      @openMenuForReasonAction="openMenuForReasonAction"
                  >
                  </parts-order-details>
                </div>
              </template>

              <template v-slot:custom-foot="row">
                <b-tr>
                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>
                  <b-td class="subheader subheader-p-l text-right report-part-order-item" colspan="2"
                        style="padding-left: 0px !important">Parts in Stock Today
                  </b-td>
                  <b-td class="p-c-value report-part-order-left-item text-left pl-4" colspan="1"
                        style="padding-left:20px;">{{
                      order.partsInStock
                    }} %
                  </b-td>
                </b-tr>
                <!--                    Comment by #618-->
<!--                <b-tr>-->
<!--                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>-->
<!--                  <b-td class="subheader subheader-p-l text-right report-part-order-item" colspan="2"-->
<!--                        style="padding-left: 0px !important">Parts Delivery ETA-->
<!--                  </b-td>-->
<!--                  <b-td class="p-c-value report-part-order-left-item text-left pl-4" colspan="1">-->
<!--                                        <span style="padding-left:5px;">  {{-->
<!--                                            order.dateDue && order.dateDue !== 'null' ? formatDate(order.dateDue) : 'Next available run'-->
<!--                                          }}</span>-->
<!--                  </b-td>-->
<!--                </b-tr>-->
                <b-tr>
                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>
                  <b-td class="subheader report-part-order-left-item subheader-p-l text-right report-part-order-item"
                        colspan="2"
                        style="padding-left: 0px !important">Discount Applied
                  </b-td>
                  <b-td class="p-c-value text-left report-part-order-left-item pl-4" colspan="1">
                    <span style="padding-left:5px;">{{ order.discountsApplied }}%</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>
                  <b-td class="subheader subheader-p-l-b text-right report-part-order-item" colspan="2"
                        style="padding-left: 0px !important">Sub Total
                  </b-td>
                  <b-td class="p-c-value b-value text-left pl-4 font-weight-bold" colspan="1">
                    <template v-if="isOrderCancelled">{{0 | formatMoney}}</template>
                    <template v-else>{{ Math.round(order.subtotal * 100) / 100 | formatMoney }}</template>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>
                  <b-td class="subheader subheader-p-l-b text-right report-part-order-item" colspan="2"
                        style="padding-left: 0px !important">GST
                  </b-td>
                  <b-td class="p-c-value b-value text-left pl-4 font-weight-bold" colspan="1">
                    <template v-if="isOrderCancelled">{{0 | formatMoney}}</template>
                    <template v-else>
                      {{
                        Math.round(order.subtotal * 110) / 100 - Math.round(order.subtotal * 100) / 100 | formatMoney
                      }}
                    </template>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>
                  <b-td class="subheader subheader-p-l-b text-right report-part-order-item" colspan="2"
                        style="padding-left: 0px !important">Grand Total
                  </b-td>
                  <b-td class="p-c-value b-value text-left pl-4 font-weight-bold" colspan="1">
                    <template v-if="isOrderCancelled">{{0 | formatMoney}}</template>
                    <template v-else>
                      {{ Math.round(order.subtotal * 110) / 100 | formatMoney }}
                    </template>
                  </b-td>
                </b-tr>
                <b-tr id="last-table-line">
                  <b-td class="border-bottom-0" :colspan="getColspan()"></b-td>
                  <b-td class="subheader report-part-order-left-item subheader-p-l-b text-right report-part-order-item"
                        colspan="2"
                        style="padding-left: 0px !important">Customer Rewards
                  </b-td>
                  <b-td class="p-c-value b-value text-left pl-4 part-values" colspan="1">
                    -
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td style="border-bottom: 0;"></b-td>
                </b-tr>
              </template>
            </b-table>
            </div>

            <div
                class="topscroll"
                :style="{
                  width: getTopScrollWidth()
                }"
                style="bottom: 0px; position: fixed; width: 600px; height: 5px; overflow-x: scroll; z-index: 5; background: white;transform: translateX(15px);"
            >
              <div style="height: 1px" class="fake"></div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="parts-order-footer mb-15 d-none">
                <div class="parts-order-footer__whitespace"></div>
                <span class="parts-order-footer__eta-label">Parts Delivery ETA</span>
                <span class="parts-order-footer__eta-date">  {{
                    order.dateDue && order.dateDue !== 'null' ? formatDate(order.dateDue) : 'Next available run'
                  }}</span>
              </div>
            </div>
          </div>
        </tab>
        <tab name="Files" id="ordered-files" :class-name="'parts-order-view-tab-3 tab-content-table'">
          <div v-if="!isShowPdf" class="part-order-additional-block d-flex">
            <div class="parts-order-title col-sm-5">
              <ps-label-value class="ps-label-little-width" label="Supplier">
                {{ order.supplier.name }}
              </ps-label-value>

              <ps-label-value class="ps-label-little-width" label="Type">
                {{ getTypes(order.supplier.types) }}
              </ps-label-value>

              <ps-label-value class="ps-label-little-width" label="Parts Dept PH">
                {{ order.supplier.partsDeptPH }}
              </ps-label-value>
            </div>
            <div class="part-order-additional-block d-flex col-sm-7" style="justify-content: flex-end">
              <div class="parts-order-title ">
                <ps-label-value label="Order Placed">{{
                    formatDateTime(order.dateCreated)
                  }}
                </ps-label-value>

                <ps-label-value label="Parts Delivery Required">
                  {{ order.dateDue && order.dateDue !== 'null' ? order.dateDue : 'Next available run' }}
                </ps-label-value>

                <div class="ps-label-value " :class="{'new-delivery-blinker': isCanBlinking}" v-if="getSupplierDeliveryDate">
                  <span class="ps-label-value__label" style="color: orange">New Delivery ETA:</span>
                  <span class="ps-label-value__value">
                        <span style="color: orange">{{getSupplierDeliveryDate}}</span>
                      </span>
                </div>

                <ps-label-value label="Special Instruction">
                  <div class="ps-scroll font-inter--bold p-0 special-instruction-block special-instruction-block-2" v-html="orderInstruction"></div>
                </ps-label-value>
              </div>
              <div class="parts-order-title ">
                <customer-account-deliver
                    :customer="order.business"
                    :estimator="order.estimator"
                    account=""
                    style="margin-top: 0px;"
                    :delivery-to="deliveryToText">
                </customer-account-deliver>
              </div>
            </div>
          </div>

          <div class="files-tab-order additional-line">
            <vin-date v-if="!isShowPdf" :code="order.trackingNumber" :vin="order.vehicle.vin ? order.vehicle.vin : ''"
                      :isShowRFQNbr="true"
                      :rfq-number="order && order.rfq && order.rfq.number ? order.rfq.number : ''"
                      @goToRfq="goToRfq"
                      :isShowOrderNbr="true"
                      :orderNumber="order.number"
                      :invoice-number="order.invoiceNumber"
                      :status="order.status"
                      :isShowInvoiceNbr="true"
                      :isRepairer="true"
                      :isDisabledInvoiceNumber="true"
                      @onChangeInvoiceNbr="onChangeInvoiceNbr"
                      @onChangeRepairerQuoteNumber="onChangeRepairerQuoteNumber"
                      :repairerQuoteNumber="order.QPNumber"
                      :date="order.vehicle.dom ? order.vehicle.dom: ''"></vin-date>
            <file-upload
                :object-id="Number(order.id)"
                type="file"
                :isSelectable="true"
                :documentHeight="documentHeight"
                @openPDF="openPdf"
                :api-urls="{uploadFile: '/ir/repairer/order/uploadFile', main: '/ir/repairer/order/'}"
                :files-accepted="'application/pdf, image/jpeg, image/jpg, image/png'"
                @removedFiles="removedFiles"
                ref="fileUpload"
                v-model="order.files"
                class="additional-line">
            </file-upload>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-4">
            <!--            <a v-if="isEditable" class="btn btn-primary clickable d-none d-lg-inline-block"-->
            <!--               @click="clickSave"-->
            <!--               style=" vertical-align: middle; padding-left:25px; padding-right:25px;">-->
            <!--              Save-->
            <!--            </a>-->
          </div>
          <!-- desktop options -->
          <div class="tabs-nav-right-options">
            <chat v-if="true" @closeChat="onCloseChat" :isShowChatInit="!!$route.params.isNeedOpenChat" :userName="order && order.supplier && order.supplier.name ? order.supplier.name : ''" :messages="chatMessages" :is-chatter-online="chatSupplierIsOnline" :typers="chatTypers" @onChatMessageSubmit="onChatMessageSubmit" @onTyping="onChatInputTyping"></chat>
            <b-dropdown ref="parts-order-view-options" id="parts-order-view-options-desktop" text="Options"
                        class="parts-order-view-options dropdown-options severalSelectedRows dropdown-actions"
                        :class="{'narrow-version': lengthSelectedRows >= 1}"
            >
              <template v-slot:button-content>
                Actions
                <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
              </template>
              <b-dropdown-item
                  @click="markOrderCompleted"
                  :disabled="!isCanBeCompleted"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: !isAllPartsReceived ? `<b>Mark Parts Arrived</b> first before Completing the Order.`  : '', placement:'leftbottom'}"
                  :class="{
                    'disabled-button': !isCanBeCompleted,
                  }">
                    Mark Order Completed
              </b-dropdown-item>
              <b-dropdown-item
                  :disabled="!isCanMarkOnBackOrder"
                  @click="markOrderBack"
                  :class="{
                  'disabled-button': !isCanMarkOnBackOrder,
              }">Mark on Back Order</b-dropdown-item>

              <b-dropdown-item v-if="isConnectToIBS" @click="sendToIBS">Send to IBODYSHOP</b-dropdown-item>

              <b-dropdown-item
                  v-if="isCanSetEditing"
                  @click="editOrder(true)"
              >
                Edit Order
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="isCanConfirmEditing"
                  @click="editOrder(false)"
              >
                Confirm Edit Order
              </b-dropdown-item>

              <template v-if="lengthSelectedRows<1">
                <b-dropdown-item
                    v-if="isConnectToIBS"
                    :disabled="!isCanEnableMarkReceivedAll"
                    :class="{'disabled-button': !isCanEnableMarkReceivedAll}"
                    @click="handlePartReceivedAll">
                  Mark All Parts Received
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!isCanEnableMarkArrivedAll"
                    :class="{'disabled-button': !isCanEnableMarkArrivedAll}"
                    @click="handlePartArrivedAll">
                  Mark All Parts Arrived
                </b-dropdown-item>
<!--                <b-dropdown-item-->
<!--                    :disabled="!isCanEnableMarkAll"-->
<!--                    :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                    @click="handleIncorrectPartAll">Mark-->
<!--                  All Incorrect Delivered Parts-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                    :disabled="!isCanEnableMarkAll"-->
<!--                    :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                    @click="handleIncorrectOrderedPartAll">Mark-->
<!--                  All Incorrect Ordered Parts-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                    :disabled="!isCanEnableMarkAll"-->
<!--                    :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                    @click="handleReturnDamagedPartAll">-->
<!--                  Mark All Return Damaged Parts in Transport-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                    :disabled="!isCanEnableMarkAll"-->
<!--                    :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                    @click="handleDamagedPartExWarehousedPartAll">-->
<!--                  Mark All Return Damaged Parts EX Warehouse-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                    :disabled="!isCanEnableMarkAll"-->
<!--                    :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                    @click="handleNoLongerNeededPartAll">-->
<!--                  Mark All No Longer Needed-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                    :disabled="!isCanEnableMarkAll"-->
<!--                    :class="{'disabled-button': !isCanEnableMarkAll}"-->
<!--                    @click="handleChangeInRepairMethodPartAll">-->
<!--                  Mark All Change in Repair Method-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item class="cancel-option" :disabled="!isCanEnableMarkAll"-->
<!--                                 @click="handleCancelPartAll">Cancel All Parts-->
<!--                </b-dropdown-item>-->
              </template>
              <template v-else-if="lengthSelectedRows>1">
                <b-dropdown-item
                    v-if="isConnectToIBS"
                    :disabled="!isCanEnableMarkReceivedSelected"
                    :class="{'disabled-button': !isCanEnableMarkReceivedSelected}"
                    @click="handlePartReceivedButton">
                  Mark Selected Parts Received
                </b-dropdown-item>

                <b-dropdown-item
                    :disabled="!isCanEnableMarkArrivedSelected"
                    :class="{'disabled-button': !isCanEnableMarkArrivedSelected}"
                    @click="handlePartArrivedButton">
                  Mark Selected Parts Arrived
                </b-dropdown-item>

                <template
                    style="width:100%"
                >

                  <b-dropdown
                      ref="dropdown-2"
                      :class="{'disabled-button': isOrderDenied || isOrderCancelled}"
                      id="dropdown-2"
                      dropleft
                      style="height: 46px; width: 100%">
                    <template v-slot:button-content>

                      <template>
                        <div class="d-flex align-items-center">
                          <div class="">
                            <i style="margin-bottom: 3px;" class='bx bx-chevron-left font-18'></i>
                          </div>
                          <div class="raise-text d-flex align-items-center">Create Credit Request</div>
                        </div>
                      </template>
                    </template>

                    <template>
                      <div class="">
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(null, $event, null, handleIncorrectPartButton())">
                          Mark Selected Incorrect Delivered Parts
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(null, $event, null, handleIncorrectPartOrderedButton())">
                          Mark Selected Incorrect Ordered Parts
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(null, $event, null, handleReturnDamagedPartButton())">
                          Mark Selected Damaged Parts in Transport
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(null, $event, null, handleDamagedPartExWarehouseButton())">
                          Mark Selected Damaged Parts EX Warehouse
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(null, $event, null, handleNoLongerNeededButton())">
                          Mark Selected No Longer Needed
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(null, $event, null, handleChangeInRepairMethodButton())">
                          Mark Selected Change in Repair Method
                        </b-dropdown-item>
                      </div>
                    </template>

                  </b-dropdown>

                </template>

                <b-dropdown-item v-if="isCanCancelParts" @click="cancelParts(false)">
                  Cancel Selected Parts
                </b-dropdown-item>
              </template>

              <template v-else-if="lengthSelectedRows === 1">
                <b-dropdown-item
                    v-if="isConnectToIBS"
                    :disabled="!isCanEnableMarkReceivedSelected"
                    :class="{'disabled-button': !isCanEnableMarkReceivedSelected}"
                    @click="handlePartReceivedButton(null, null, true)">
                  Mark Part Received
                </b-dropdown-item>

                <b-dropdown-item
                    :disabled="!isCanEnableMarkArrivedSelected"
                    :class="{'disabled-button': !isCanEnableMarkArrivedSelected}"
                    @click="handlePartArrivedButton(null, null, true)">
                  Mark Part Arrived
                </b-dropdown-item>


                <template
                    v-if="!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id > 0)"
                    style="width:100%"
                >
                  <b-dropdown
                      ref="dropdown-2"
                      :class="{'disabled-button': isOrderDenied || isOrderCancelled}"
                      id="dropdown-2"
                      dropleft
                      style="height: 46px; width: 100%">
                    <template v-slot:button-content>

                      <template v-if="!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id > 0)">
                        <div class="d-flex align-items-center">
                          <div class="">
                            <i style="margin-bottom: 3px;" class='bx bx-chevron-left font-18'></i>
                          </div>
                          <div class="raise-text d-flex align-items-center">Create Credit Request</div>
                        </div>
                      </template>
                    </template>

                    <template>
                      <div class="">
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleIncorrectPartButton(null, null, true))">
                          Mark Incorrect Part Delivered
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleIncorrectPartOrderedButton(null, null, true))">
                          Mark Incorrect Part Ordered
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleReturnDamagedPartButton(null, null, true))">
                          Mark Damaged Part in Transport
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleReturnDamagedPartButton(null, null, true))">
                          Mark Damaged Part EX Warehouse
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleNoLongerNeededButton(null, null, true))">
                          Mark No Longer Needed
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty, handleChangeInRepairMethodButton(null, null, true))">
                          Mark Change in Repair Method
                        </b-dropdown-item>

                        <template v-if="selectedRow[0].qty > 1">
                          <template>
                            <div class="qty-menu-options">
                              <p class="text-center pt-2 font-12 font-inter--semibold">QTY to Return</p>
                              <div class="d-flex px-3 align-items-center">
                                <p @click="minusReturnQty(selectedRow[0])"
                                   class="pt-3 pl-2 pointer font-14 font-inter--semibold">
                                  -</p>
                                <limited-input
                                    ref="qtyInput"
                                    class="raised-credit-request-sub-menu-qty-input form-control"
                                    :class="{'flashing': selectedRow[0].returnQty == selectedRow[0].qty, 'color-red': selectedRow[0].returnQty != selectedRow[0].qty}"
                                    :not-available-value="notAvailableQty(selectedRow[0].qty)"
                                    style="text-align: center"
                                    :isConversely="true"
                                    v-model="selectedRow[0].returnQty"
                                    @update="value => selectedRow[0].returnQty = value">
                                </limited-input>
                                <p @click="plusReturnQty(selectedRow[0])"
                                   class="pt-3 pr-2 pointer font-14 font-inter--semibold">
                                  +</p>
                              </div>
                              <div class="raised-credit-request-qty-btn">
<!--                                <button @click="raiseCreditFromOptions(selectedRow[0], $event, selectedRow[0].returnQty)" class="btn btn-primary mx-0 waves-effect waves-light sec_btn confirm-button">-->
<!--                                  Confirm-->
<!--                                </button>-->
                              </div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </template>

                  </b-dropdown>

                </template>

                <b-dropdown-item
                    v-if="selectedRow && selectedRow[0] && selectedRow[0].isUndoPartLineChange"
                    :disabled="!!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id) || (isOrderDenied || isOrderCancelled)"
                    :class="{'disabled-button': !!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id) || (isOrderDenied || isOrderCancelled)}"
                    @click="undoPartLineChange">Undo Part Line Change
                </b-dropdown-item>
                <b-dropdown-item v-if="isCanCancelParts" @click="cancelParts(false)">
                  Cancel Selected Part
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                  v-if="order.state != 1"
                  @click="markOrderCurrent"
              >
                Move to Current
              </b-dropdown-item>
              <b-dropdown-item v-if="isCanCancelOrder" @click="cancelParts(true)" :disabled="isDisabledCancelOrder">
                Cancel Order
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </tabs>
    </div>
    <vue-context ref="menuPartsOrder"
                 :lazy="true"
                 :close-on-click="false"
                 @close="isOpenContextLevelTwo2 = false; isOpenContextLevelTwo3 = false"
                 :closeOnScroll="true">
      <template slot-scope="item">
        <b-button-toolbar v-if="lengthSelectedRows>1" key-nav>
          <b-button-group vertical class="parts-order-view__context-menu parts-order-view__context-menu-2">
            <b-button
                v-if="isConnectToIBS"
                :disabled="!isCanEnableMarkReceivedSelected"
                @click="handlePartReceivedButton(null,null,true)"
                class="text-left font-12 font-inter--semibold" variant="outline-primary" block
            >
              Mark Selected Parts Received
            </b-button>

            <b-button
                :disabled="!isCanEnableMarkArrivedSelected"
                @click="handlePartArrivedButton(null,null,true)"
                class="text-left font-12 font-inter--semibold" variant="outline-primary" block
            >
              Mark Selected Parts Arrived
            </b-button>

            <b-button
                v-if="isCanMarkNotReceived && isConnectToIBS"
                @click="handlePartNotReceivedButton(item.data, $event)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>Unmark Selected Parts Received
            </b-button>

            <b-button
                v-if="isCanMarkNotArrived"
                @click="handlePartNotArrivedButton(item.data, $event)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>Mark Selected Parts Not Arrived
            </b-button>

            <b-button
                v-if="isCanSetEditing"
                @click="editOrder(true)"
                class="text-left font-12 font-inter--semibold" variant="outline-primary" block
            >
              Edit Order
            </b-button>

            <b-button
                v-if="isCanConfirmEditing"
                @click="editOrder(false)"
                class="text-left font-12 font-inter--semibold" variant="outline-primary" block
            >
              Confirm Edit Order
            </b-button>

            <b-button
                v-if="isCanUndoChanges"
                @click="undoPartLineChange"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>Undo Part Line Change
            </b-button>

            <div
                @mouseover="onMouseOver"
                @mouseleave="isUnderCreditRequest = false"
                style="width:100%"
            >
              <template>
                <b-button
                    class="text-left font-12 font-inter--semibold v-context__sub raise-credit-request-button"
                    variant="outline-primary" block
                    :class="{'disabled-options-item': isOrderDenied || isOrderCancelled}"
                    :disabled="isOrderDenied || isOrderCancelled">
                  Create Credit Request <i style="font-size: 18px; transform: translateY(4px)" class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <template>
                  <div
                      v-if="isUnderCreditRequest"
                      style="right: -295px;"
                      :style="{
                        top: getCreateCreditRequestContextTopTwoItem(item.data.row) + 'px'
                      }"
                      class="part-sub-context-menu create-credit-request-sub-menu"
                  >
                    <b-button
                        :autofocus="false"
                        @click="raiseCreditFromOptions(null, $event, null, handleIncorrectPartButton(null,null,true))"
                        class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                    >
                      Mark Selected Incorrect Delivered Parts
                    </b-button>
                    <b-button
                        :autofocus="false"
                        @click="raiseCreditFromOptions(null, $event, null, handleIncorrectPartOrderedButton(null,null,true))"
                        class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                    >
                      Mark Selected Incorrect Ordered Parts
                    </b-button>
                    <b-button
                        @click="raiseCreditFromOptions(null, $event, null, handleReturnDamagedPartButton(null,null,true))"
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                    >
                      Mark Selected Damaged Parts in Transport
                    </b-button>
                    <b-button
                        @click="raiseCreditFromOptions(null, $event, null, handleDamagedPartExWarehouseButton(null,null,true))"
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                    >
                      Mark Selected Damaged Parts EX Warehouse
                    </b-button>
                    <b-button
                        @click="raiseCreditFromOptions(null, $event, null, handleNoLongerNeededButton(null,null,true))"
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                    >
                      Mark Selected No Longer Needed
                    </b-button>
                    <b-button
                        @click="raiseCreditFromOptions(null, $event, null, handleChangeInRepairMethodButton(null,null,true))"
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                    >
                      Mark Selected Change in Repair Method
                    </b-button>

                  </div>
                </template>
              </template>
            </div>

            <b-button
                v-if="isCanReverse"
                class="text-left font-12 font-inter--semibold"
                @click="reverseParts"
                variant="outline-primary"
                block>Reverse Credit Request
            </b-button>

            <div
                class="w-100"
                @mouseover="isOpenContextLevelTwo2 = true"
                @mouseleave="isOpenContextLevelTwo2 = false">
              <b-button class="text-left font-12 font-inter--semibold context-menu-focus w-100" variant="outline-primary" block>
                Set Invoice Number <i style="font-size: 18px; transform: translateY(4px)"
                                      class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
              </b-button>
              <div
                  v-if="isOpenContextLevelTwo2"
                  :class="{
                    'invoice-number-context-5': (isCanUndoChanges && (isCanMarkNotReceived && isConnectToIBS)),
                    'invoice-number-context-6': (isCanUndoChanges && (isCanMarkNotReceived && isConnectToIBS))
                  }"
                  :style="{
                    top: getSetInvoiceNumberContextTopTwoItem(item.data.row) + 'px'
                  }"
                  style="right: -141px"
                  class="part-sub-context-menu invoice-number-context set-invoice-number-sub-menu"
              >
                <div class="parts-order-view__context-menu context-level-two context-stock-select" style="border: none">
                  <b-form-input @change="saveContextInvoiceNumber" v-model="contextInvoiceNumber"></b-form-input>
                  <div class="invoice-number-text">Invoice Number</div>
                </div>
              </div>
            </div>

            <div
                class="w-100"
                @mouseover="isOpenContextLevelTwo3 = true"
                @mouseleave="isOpenContextLevelTwo3 = false">
              <b-button class="text-left font-12 font-inter--semibold context-menu-focus w-100" variant="outline-primary" block>
                Set Invoice Date <i style="font-size: 18px; transform: translateY(4px)"
                                      class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
              </b-button>
              <div
                  v-if="isOpenContextLevelTwo3"
                  :class="{
                    'invoice-date-context-5': (isCanUndoChanges && (isCanMarkNotReceived && isConnectToIBS)),
                    'invoice-date-context-6': (isCanUndoChanges && (isCanMarkNotReceived && isConnectToIBS))
                  }"
                  :style="{
                    top: getSetInvoiceNumberContextTopTwoItem(item.data.row) + 42 + 'px'
                  }"
                  style="right: -141px"
                  class="part-sub-context-menu invoice-number-context set-invoice-number-sub-menu"
              >
                <div class="parts-order-view__context-menu context-level-two context-stock-select" style="border: none">
                  <datePicker v-model="contextInvoiceDate" @input="saveContextInvoiceDate"></datePicker>
                  <div class="invoice-number-text">Invoice Date</div>
                </div>
              </div>
            </div>

            <b-button
                v-if="!isMobile"
                class="text-left font-12 font-inter--semibold"
                @click="addPhoto(false)"
                variant="outline-primary"
                block>Add Photo
            </b-button>

            <b-button
                @click="markOrderCompleted"
                :disabled="!isCanBeCompleted"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Mark Order Completed
            </b-button>

            <b-button
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block v-if="isCanCancelParts" @click="cancelParts(false)">Cancel Selected Parts
            </b-button>
            <b-button
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block v-if="isCanCancelOrder" @click="cancelParts(true)" :disabled="isDisabledCancelOrder">Cancel Order
            </b-button>

          </b-button-group>
        </b-button-toolbar>
        <b-button-toolbar v-else-if="item && item.data && item.data.row" key-nav>
          <b-button-group vertical class="parts-order-view__context-menu parts-order-view__context-menu-2">
            <b-button v-if="canShowMarkPartReceivedButton && isConnectToIBS"
                      :disabled="item.data.row.credit.id > 0"
                      class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                      @click="handlePartReceivedButton(item.data, $event, true)">
              Mark Part Received
            </b-button>


            <b-button v-if="item.data.row.status === 'Received' && isConnectToIBS"
                      class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                      @click="handlePartNotReceivedButton(item.data, $event)">
              Unmark Received
            </b-button>

            <b-button v-if="isCanEnableMarkArrivedSelected"
                      :disabled="item.data.row.credit.id > 0"
                      class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                      @click="handlePartArrivedButton(item.data, $event)">
              Mark Part Arrived
            </b-button>


            <b-button v-if="item.data.row.status === 'Arrived'"
                      class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                      @click="handlePartNotArrivedButton(item.data, $event)">
              Mark Not Arrived
            </b-button>

            <b-button
                v-if="isCanSetEditing"
                @click="editOrder(true)"
                class="text-left font-12 font-inter--semibold" variant="outline-primary" block
            >
              Edit Order
            </b-button>

            <b-button
                v-if="isCanConfirmEditing"
                @click="editOrder(false)"
                class="text-left font-12 font-inter--semibold" variant="outline-primary" block
            >
              Confirm Edit Order
            </b-button>

            <div
                v-if="!(item.data.row.credit && item.data.row.credit.id)"
                @mouseover="onMouseOver"
                @mouseleave="isUnderCreditRequest = false"
                class="w-100"
            >
              <template>
                <b-button
                    class="text-left font-12 font-inter--semibold v-context__sub raise-credit-request-button"
                    variant="outline-primary" block
                    :class="{'disabled-options-item': isOrderDenied || isOrderCancelled}"
                    :disabled="item.data.row.qty > 1 || isOrderDenied || isOrderCancelled">
                  Create Credit Request <i style="font-size: 18px; transform: translateY(4px)" class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <template>
                  <div
                      v-if="isUnderCreditRequest"
                      :style="{
                        top: getCreateCreditRequestContextTop(item.data.row) + 'px'
                      }"
                      class="part-sub-context-menu create-credit-request-sub-menu"
                  >
                    <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                              :autofocus="false"
                              @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty, handleIncorrectPartButton(item.data, $event))">
                      Mark Incorrect Part Delivered
                    </b-button>
                    <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block
                              :autofocus="false"
                              @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty, handleIncorrectPartOrderedButton(item.data, $event))">
                      Mark Incorrect Part Ordered
                    </b-button>
                    <b-button
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                        @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty, handleReturnDamagedPartButton(item.data, $event))">
                      Mark Damaged Part in Transport
                    </b-button>
                    <b-button
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                        @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty, handleDamagedPartExWarehouseButton(item.data, $event))">
                      Mark Damaged Part EX Warehouse
                    </b-button>
                    <b-button
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                        @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty, handleNoLongerNeededButton(item.data, $event))">
                      Mark No Longer Needed
                    </b-button>
                    <b-button
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary"
                        block
                        @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty, handleChangeInRepairMethodButton(item.data, $event))">
                      Change in Repair Method
                    </b-button>

                    <template v-if="item.data.row.qty > 1">
                      <div
                          class="part-sub-context-menu raised-credit-request-sub-menu"
                          style=""
                      >
                        <div style="width:130px;">
                          <p class="text-center pt-2 font-12 font-inter--semibold">QTY to Return</p>
                          <div class="d-flex px-3 align-items-center">
                            <p @click="minusReturnQty(item.data.row)"
                               class="pt-3 pl-2 pointer font-14 font-inter--semibold">
                              -</p>
                            <limited-input
                                ref="qtyInput"
                                class="raised-credit-request-sub-menu-qty-input form-control"
                                :class="{'flashing': selectedRow[0] && item.data.row.returnQty === selectedRow[0].qty, 'color-red': selectedRow[0] && item.data.row.returnQty !== selectedRow[0].qty}"
                                :not-available-value="notAvailableQty(item.data.row.qty)"
                                style="text-align: center"
                                :isConversely="true"
                                v-model="item.data.row.returnQty"
                                @update="value => item.data.row.returnQty = value">
                            </limited-input>
                            <p @click="plusReturnQty(item.data.row)"
                               class="pt-3 pr-2 pointer font-14 font-inter--semibold">
                              +</p>
                          </div>
                          <div class="raised-credit-request-qty-btn">
<!--                            <button @click="raiseCreditFromOptions(item.data.row, $event, item.data.row.returnQty)" class="btn btn-primary mx-0 waves-effect waves-light sec_btn confirm-button">-->
<!--                              Confirm-->
<!--                            </button>-->
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </template>
            </div>

            <b-button
                v-if="selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id > 0 && selectedRow[0].credit.creditPartStatus === 'UnderReview'"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                @click="reverseParts"
                block>Reverse Credit Request
            </b-button>

            <b-button
                v-if="selectedRow && selectedRow[0] && selectedRow[0].isUndoPartLineChange"
                :disabled="!!(selectedRow && selectedRow[0] && selectedRow[0].credit && selectedRow[0].credit.id) || (isOrderDenied || isOrderCancelled)"
                @click="undoPartLineChange"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>Undo Part Line Change
            </b-button>

            <div
                class="w-100"
                @mouseover="isOpenContextLevelTwo2 = true"
                @mouseleave="isOpenContextLevelTwo2 = false">
              <b-button class="text-left font-12 font-inter--semibold context-menu-focus" variant="outline-primary" block>
                Set Invoice Number <i style="font-size: 18px; transform: translateY(4px)"
                                    class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
              </b-button>
              <div
                  v-if="isOpenContextLevelTwo2"
                  :style="{
                    top: getSetInvoiceNumberContextTop(item.data.row) + 'px'
                  }"
                  style="right: -141px;"
                  class="part-sub-context-menu invoice-number-context set-invoice-number-sub-menu"
              >
                <div class="parts-order-view__context-menu context-level-two context-stock-select" style="border: none">
                  <b-form-input @change="saveInvoiceNumber(item.data.row)" v-model="item.data.row.invoiceNumber"></b-form-input>
                  <div class="invoice-number-text">Invoice Number</div>
                </div>
              </div>
            </div>

            <div
                class="w-100"
                @mouseover="isOpenContextLevelTwo3 = true"
                @mouseleave="isOpenContextLevelTwo3 = false">
              <b-button class="text-left font-12 font-inter--semibold context-menu-focus" variant="outline-primary" block>
                Set Invoice Date <i style="font-size: 18px; transform: translateY(4px)"
                                      class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
              </b-button>
              <div
                  v-if="isOpenContextLevelTwo3"
                  :style="{
                    top: getSetInvoiceNumberContextTop(item.data.row) + 42 + 'px'
                  }"
                  style="right: -141px;"
                  class="part-sub-context-menu invoice-number-context set-invoice-number-sub-menu"
              >
                <div class="parts-order-view__context-menu context-level-two context-stock-select" style="border: none">
                  <datePicker @change="saveInvoiceDate(item.data.row)" v-model="item.data.row.invoiceDate"></datePicker>
                  <div class="invoice-number-text">Invoice Date</div>
                </div>
              </div>
            </div>

            <b-button
                v-if="!isMobile"
                class="text-left font-12 font-inter--semibold"
                @click="addPhoto(false)"
                variant="outline-primary"
                block>Add Photo
            </b-button>

            <b-button
                @click="markOrderCompleted"
                :disabled="!isCanBeCompleted"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Mark Order Completed
            </b-button>

            <b-button
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block
                v-if="isCanCancelParts" @click="cancelParts(false)"
            >Cancel Selected Part
            </b-button>

            <b-button
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block v-if="isCanCancelOrder" @click="cancelParts(true)" :disabled="isDisabledCancelOrder">Cancel Order
            </b-button>

            <b-button
                v-if="!isMobile"
                class="text-left font-12 font-inter--semibold"
                @click="addPhoto(false)"
                variant="outline-primary"
                block>Add Photo
            </b-button>

          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>

    <vue-context ref="menuActionForReason" :closeOnScroll="false"
                 @open="isOpenPartContext = true"
                 @close="isOpenPartContext = false">
      <ul slot-scope="item" style="padding: 0px">
        <template v-if="item.data && item.data.type && item.data.type == 'status'">
          <b-button-group vertical class="parts-order-view__context-menu">
            <b-button
                v-if="item.data
                && item.data.part
                && item.data.isNeedSave
                && item.data.part.comment !== ''"
                @click="saveReason(item.data.part, item.data.type)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Save Reason
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && (item.data.part.comment !== '' && item.data.part.comment !== null)
                && !item.data.isNotNeedEdit"
                @click="editReason(item.data.part, item.data.type)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Edit Reason
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && (item.data.part.comment !== '' && item.data.part.comment !== null)"
                @click="deleteReason(item.data.part, item.data.type)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Delete Reason
            </b-button>
          </b-button-group>
        </template>
        <template v-else-if="item.data && item.data.type && item.data.type == 'credit'">
          <b-button-group vertical class="parts-order-view__context-menu">
            <b-button
                v-if="item.data
                && item.data.part
                && item.data.isNeedSave
                && item.data.part.credit.reason !== ''"
                @click="saveReason(item.data.part, item.data.type)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Save Credit
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && (item.data.part.credit.reason !== '' && item.data.part.credit.reason !== null)
                && !item.data.isNotNeedEdit"
                @click="editReason(item.data.part, item.data.type)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Edit Credit
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && (item.data.part.credit.reason !== '' && item.data.part.credit.reason !== null)"
                @click="deleteReason(item.data.part, item.data.type)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Delete Credit
            </b-button>
          </b-button-group>
        </template>
      </ul>
    </vue-context>

    <b-modal ref="open-modal-undo-part-line-change" size="custom-undo-part-line-change">
      <template v-slot:modal-header>
        <h5 class="modal-title">Undo Parts line change</h5>
      </template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3">This action will remove the comment you added to this line. Do you want to continue?</p>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">No</button>
          <button class="input-btn modal-undo-part-line-change-btn-yes" @click="undoPartLineChange(false, true)">Yes
          </button>
        </div>
      </template>
    </b-modal>

    <template v-if="backOrders">
      <b-modal id="special-order-modal" size="repairer-custom-special-order-modal" @hide="onCloseModal(o.id)" v-for="o in backOrders" :key="o.id"  :ref="'back-order-' + o.id" :id="'back-order-' + o.id"
               title="Back Order - Part Acceptance Agreement">
        <template v-slot:modal-backdrop class="my-backdrop"></template>

        <div class="modal-mark-as-returned special-supplier-order-modal form-container">
          <form>
            <div class="form-group row pr-3">
              <label class="col col-form-label">{{selectedBackOrder.data.description.supplier}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the supply of the following part/s with your order.</span></label>
            </div>
            <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Order Number: </p>
                <p class="">{{selectedBackOrder.data.description.orderNumber}}</p>
              </div>
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Invoice Number: </p>
                <p class="">{{selectedBackOrder.data.description.invoiceNumber}}</p>
              </div>
            </div>
            <div class="d-flex pr-3 pl-3">
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Part/s: </p>
                <p class=""></p>
              </div>
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Make: </p>
                <p class="">{{order.vehicle.make}}</p>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 d-flex"  v-for="(prt, id) in selectedBackOrder.data.parts" :key="prt.orderPartId">
                <div class="col-12 col-md-1" style="padding: 0px">
                  <label style="margin-top: 14px" class="form-check-inline checkbox back-order-checkbox">

                    <input
                       v-if="selectedBackOrder.status !== 'sent'"
                       checked :value="prt.orderPartId"  :disabled="true" class="form-check-input" type="checkbox">
                    <input
                        v-else
                        v-model="backOrderSelectedParts.parts" @change="callComputed2++" :value="prt.orderPartId"  class="form-check-input" type="checkbox">

                    <span  class="icon clickable" ><i class='bx bx-check'></i></span>
                    <span class="direct-text"></span>
                  </label>
                </div>
                <div style="height: 32px; padding-top: 7px;margin-top: 10px;" class="col-12 col-md-11 form-control hover-border-effect">
                  <b>Part: </b> {{prt.partDescription}} <span v-if="prt.partNumber">| {{prt.partNumber}}</span> | QTY {{prt.qty}} | {{prt.buyPrice | formatMoney}}
                </div>
              </div>
            </div>
            <div class="form-group row pr-3 pl-3" v-if="selectedBackOrder.data.settings.displayOrderAcceptanceTICs">
              <label class="col col-form-label pl-0">Terms & Conditions</label>
              <div class="disabled-vue-editor-supp-order">
                <vue-editor
                    :disabled="true"
                    v-model="selectedBackOrder.data.settings.termsAndConditions"
                    :editorToolbar="[]"></vue-editor>
              </div>
              <!--            <div v-html="settings.acceptance" class="col-12 form-control special-order-text-message" type="text" disabled></div>-->
            </div>
            <div class="w-100 text-right row p-3" v-if="selectedBackOrder.data.settings.requireAcceptanceOfOrderTICs">
              <label class="form-check-inline checkbox back-order-checkbox">
                <input @change="callComputed2++" class="form-check-input" type="checkbox" v-model="backOrderSelectedParts.acceptanceOfTics" :disabled="selectedBackOrder.status !== 'sent'">
                <span class="icon clickable"><i style="color: #79B1BB" class='bx bx-check'></i></span>
                <span class="direct-text">I have read & accept the suppliers Terms and Conditions</span>
              </label>
            </div>
            <div v-if="selectedBackOrder.data.settings.requireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature">
              <label class="col col-form-label pl-0">E-Signature</label>
              <template>
                <div class="col-12 p-0 special-order-signature-block" :ref="'specialOrderBlock-' + o.id">
                  <template v-if="selectedBackOrder.status !== 'sent'">
                    <div class="special-order-signature-canvas"> <img :src="selectedBackOrder.eSignature" alt=""></div>
                  </template>
                  <VPerfectSignature v-else :stroke-options="strokeOptions" :ref="'signaturePad-' + o.id"
                                     class="special-order-signature-canvas"/>
                </div>
                <a
                    v-if="selectedBackOrder.status === 'sent' && !isEmptySignature"
                    class="control-elem-1__item"
                    @click.prevent="clearSignature"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                  <i class="bx bx-trash"></i>
                </a>
              </template>
            </div>
            <template v-if="selectedBackOrder.data.settings.requireSigneeToAddNameOrJob">
              <div class="form-group row pr-3 pl-3">
                <label class="col-4 col-form-label pl-0">Accepted By: </label>
                <input
                    type="text"
                    :disabled="selectedBackOrder.status !== 'sent'"
                    v-model="backOrderSelectedParts.acceptedBy"
                    @change="callComputed2++"
                    class="col-8 form-control"
                />
              </div>
              <div class="form-group row pr-3 pl-3">
                <label class="col-4 col-form-label pl-0">Position Held: </label>
                <input
                    type="text"
                    :disabled="selectedBackOrder.status !== 'sent'"
                    v-model="backOrderSelectedParts.positionHeld"
                    @change="callComputed2++"
                    class="col-8 form-control"
                />
              </div>
            </template>
          </form>
        </div>
        <template v-slot:modal-footer="{ confirm, cancel }">
          <template>
            <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
            <template v-if="selectedBackOrder.status === 'sent'">
              <b-button :disabled="!isCanAccept" style="border: 1px solid #29BBC1 !important" class="input-btn decline" @click="acceptNonReturnable('declined', selectedBackOrder.id)">Decline</b-button>
              <b-button :disabled="!isCanAccept" @click="acceptNonReturnable('accepted', selectedBackOrder.id)" class="input-btn">Accept</b-button>
            </template>
          </template>
        </template>
      </b-modal>
    </template>



        <b-modal id="cancelPart" ref="cancelPart" size="custom-undo-part-line-change">
          <template v-slot:modal-backdrop class="my-backdrop"></template>
          <template v-slot:modal-header>
            <h5 class="modal-title">Cancel Order - Selected Part/s</h5>
          </template>
          <div class="modal-invite-customer form-container cancel-order-modal">
            <p>Checking selected part in the order has not been flagged as a non-returnable, shipping status changes being made.</p>
            <p>Status: <b>Check Completed</b></p>
            <div class="cancel-order-modal-checks">
              <div class="d-flex">
                <div class="d-flex">
                  <div class="modal-cancel-order-status-title">Non-Returnable check:&nbsp;</div>
                  <div class="modal-cancel-order-status-text">
                     <span v-if="isCheckedPartsNonReturnable" class="cancel-order-modal-checks-red">
                      <span v-if="partsForCancelling.length > 1">There are one or more Non-Returnable Items in the Order. Contact the supplier. </span>
                      <span v-else>Part is Non-Returnable. Contact the supplier <br></span>
                      <span v-if="partsForCancelling.length == 1" style="color: #1C1F39; font-weight: 500"><span>(Part was accepted </span><span class="" style="display: inline-block">{{partsForCancelling[0].nonReturnableAcceptedOn | formatDatetime}}</span><span> by </span><span style="display: inline-block" class="">{{partsForCancelling[0].nonReturnableAcceptedBy }}</span><span>)</span></span>
                    </span>
                    <span v-else class="cancel-order-modal-checks-green">OK</span>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="d-flex">
                  <div class="modal-cancel-order-status-title">Back Order check:&nbsp;</div>
                  <div class="modal-cancel-order-status-text">
                    <span v-if="isCheckedPartsNonReturnable" class="cancel-order-modal-checks-bold">Stopped</span>
                    <span v-else-if="isCheckedPartsOnBackOrder || isCheckedPartsExWarehouse || isCheckedPartsHasAcceptedBackOrder" class="cancel-order-modal-checks-red">
                       <template v-if="isCheckedPartsHasAcceptedBackOrder">
                           <span v-if="partsForCancelling.length > 1">
                           There are one or more Items in Order have been accepted as a Back Order. Contact the supplier. </span>
                           <span v-else>Part has been accepted as a Back Order. Contact the supplier <br></span>
                           <span v-if="partsForCancelling.length == 1" style="color: #1C1F39; font-weight: 500">
                             <span>(Part was accepted </span><span class="" style="display: inline-block">{{getOrder(partsForCancelling[0]) ? getOrder(partsForCancelling[0]).acceptOrDeclineOn : '' | formatDatetime}}</span>
                             <span> by </span><span style="display: inline-block" class="">{{getOrder(partsForCancelling[0]) ? getOrder(partsForCancelling[0]).acceptOrDeclineBy.name : '' }}</span><span>)</span>
                           </span>
                       </template>
                       <template v-else>
                        <span v-if="partsForCancelling.length > 1">Some parts are</span>
                        <span v-else>Part is</span>
                        <span v-if="isCheckedPartsOnBackOrder"> on Back Order</span>
                        <span v-if="isCheckedPartsExWarehouse"><span v-if="isCheckedPartsOnBackOrder">, </span> <span  class="cancel-order-modal-checks-orange"> EX Warehouse</span></span>
                        <span>. Supplier will be notified.</span>
                       </template>
                    </span>
                    <span v-else class="cancel-order-modal-checks-green">OK</span>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="d-flex">
                  <div class="modal-cancel-order-status-title">Shipping Check: &nbsp;</div>
                  <div class="modal-cancel-order-status-text">
                    <span v-if="isCheckedPartsNonReturnable || isCheckedPartsHasAcceptedBackOrder" class="cancel-order-modal-checks-bold">Stopped</span>
                    <span v-else-if="isCheckedPartsShipped"  class="cancel-order-modal-checks-red">
                      <span v-if="partsForCancelling.length > 1">Some parts</span>
                      <span v-else>Part</span>
                      <span> marked as <span class="cancel-order-modal-checks-blue">Shipped</span>. Contact supplier</span><br>
                      <span  v-if="partsForCancelling.length == 1" class="cancel-order-modal-checks-classic">Set to Shipped {{partsForCancelling[0].lastChangeStatusDate | formatDatetime}} - {{partsForCancelling[0].lastChangeStatusBy }}</span>
                    </span>
                    <span v-else-if="isTodayOrdered || isYesterdayOrdered" class="cancel-order-modal-checks-bold">
                      Order Processed <span class="cancel-order-modal-checks-orange" v-if="isYesterdayOrdered">YESTERDAY</span><span v-else class="cancel-order-modal-checks-orange">TODAY</span>, not marked Shipped. <br/> Contact the supplier. Part may have left the warehouse
                    </span>
                    <span v-else-if="isCheckedPartsCreated" class="cancel-order-modal-checks-bold">
                      Order Processed but not marked Shipped. <br/> Contact the supplier. Part may have left the warehouse
                    </span>
                    <span v-else class="cancel-order-modal-checks-green">OK</span>
                  </div>
                </div>
              </div>
            </div>
            <div  class="cancel-order-modal-message">
              <p><b>Message to supplier</b></p>
              <textarea v-model="cancelMessage" class="form-control" placeholder="Write message here"></textarea>
            </div>

          </div>
          <template v-slot:modal-footer="{ ok, cancel }">
            <template v-if="isCheckedPartsNonReturnable || isCheckedPartsHasAcceptedBackOrder || isCheckedPartsShipped">
              <b-button class="cancel-button photo-for-credit-btn" @click="cancel()">Close</b-button>
            </template>
            <template v-else>
              <b-button class="cancel-button photo-for-credit-btn" @click="cancel()">Cancel Request</b-button>
              <b-button class="attach-btn-cancel-order-modal" @click="ok(); confirmCancellation()">Confirm Cancellation</b-button>
            </template>
          </template>
        </b-modal>

        <b-modal id="imageRequired" ref="customModal" size="custom">
          <template v-slot:modal-backdrop class="my-backdrop"></template>
          <template v-slot:modal-header>
            <h5 class="modal-title">{{order.supplier.name}} requires Photos for this Credit Request</h5>
          </template>
          <div class="modal-invite-customer form-container">
            <p><strong>{{order.supplier.name}}</strong> requires images of the Part to approve your Credit. Include clear photos showing condition of the part and packaging</p>
            <div class="">
              <div class="d-flex justify-content-between">
                <b>Add photo</b>
                <vue-dropzone
                    ref="imgUpDropPinInage"
                    id="imgUploadDropPinImage"
                    class="dropify-wrapper dropzoneContainer mark-as-returned-add-photo" style="width: 80%"
                    :useCustomSlot=true
                    v-on:vdropzone-file-added="addFileUpload"
                    v-on:vdropzone-success="successFileUpload"
                    v-on:vdropzone-complete="completeFileUpload"
                    v-on:vdropzone-sending="sendFileUpload"
                    :options="photoConfig"
                >
                  <div id="clickBrowseImagesBtn" class="dropzone-text">
                    <i class='bx' style="font-size: 42px; color: #79B1BB;" :class="{'bx-camera': isMobile, 'bx-cloud-upload': !isMobile}"></i>
                    <p class="drop-down-text p-3 mb-0">
                      <template v-if="isMobile">Take Photo</template>
                      <template v-else>
                        Drag and Drop or Browse Images
                      </template>
                    </p>
                  </div>
                </vue-dropzone>
              </div>
            </div>

          </div>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button class="cancel-button photo-for-credit-btn" @click="cancel()">Close</b-button>
            <b-button class="cancel-button photo-for-credit-btn" style="width: 100px" @click="cancel()">Add Later</b-button>
            <b-button class="attach-btn" @click="attachPhoto">Attach</b-button>
          </template>
        </b-modal>

        <b-modal id="creditPeriod" ref="creditPeriod" size="custom">
          <template v-slot:modal-backdrop class="my-backdrop"></template>
          <template v-slot:modal-header>
            <h5 class="modal-title">Credit Return Period Notification</h5>
          </template>
          <div class="modal-invite-customer form-container">
            <p>
              <strong>{{ order.supplier.name }}</strong> has the following conditions for Credit returns.
            </p>
            <p>
              <strong>Credit Returns Period:</strong> {{ order.creditProcessingPolicies && order.creditProcessingPolicies.hasOwnProperty('returnPeriod') ? order.creditProcessingPolicies.returnPeriod : ''}} days
            </p>
            <p>
              <strong>Credit Returns Notice:</strong>
            </p>
            <div style="white-space: pre-line; line-height: 16px; font-family: inherit;"> {{order.creditProcessingPolicies && order.creditProcessingPolicies.hasOwnProperty('notice') ? order.creditProcessingPolicies.notice : ''}}</div>

          </div>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button class="cancel-button" @click="cancel()">Close</b-button>
          </template>
        </b-modal>
    </div>
</template>

<script>
/*eslint-disable */
import { VueContext } from 'vue-context'
import PartsOrderDetails from '../../repairers/part-order/parts-order-view/parts-order-details'
import Axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import * as dayjs from 'dayjs'
import VinDate from '../../../components/VinDate'
import Order from '../../../components/order/models/Order'
import FileUpload from '@/components/order/repairer/FileUpload.vue'
import CustomerAccountDeliver from '@/components/CustomerAccountDeliver.vue'
import psLabelValue from '@/components/ps-label-value.vue'
import limitedInput from "../../../components/limitedInput";
import { VPerfectSignature } from 'v-perfect-signature'
import {VueEditor} from 'vue2-editor'
import { isTablet, isMobileOnly} from 'mobile-device-detect'
import {UAParser} from "ua-parser-js";
import {States} from "../../../store/states";
import Chat from '../../../components/utility/chat/chat'
import {subscribeToOrderChannel, subscribeToRfqChannel} from "@/centrifuge";
import vueDropzone from 'vue2-dropzone'
import {appConfig} from "../../../config";
import {isMobile} from "mobile-device-detect";
var isToday = require('dayjs/plugin/isToday')
var isYesterday = require('dayjs/plugin/isYesterday')
dayjs.extend(isToday)
dayjs.extend(isYesterday)
import StickyHeader from "@/views/repairers/part-order/parts-order-view/sticky-header.vue";
import StockSelect from '../../../components/StockSelect'
import NumberFormatter from '../../../components/utility/number-formatter'
import SearchFilter from '../../../components/utility/search-filter/search-filter';
import datePicker from '../../../components/datePicker'


export default {
  name: 'parts-order-view',
  data () {
    return {
      backOrderSelectedParts: {
        parts: [],
      },
      showedModalsForOrders: [],
      openedModalsForOrders: [],
      _chat: null,
      sorting: {
        type: '',
        direction: 'asc'
      },
      isShowStickyHeader: false,
      isCheckedAllValue: false,
      sortByTable: '',
      sortDesc: '',
      cancelMessage: '',
      activeTab: '',
      chatMessages: [],
      backOrders: [],
      partsForCancelling: [],
      chatTypers: {},
      selectedFileId: null,
      selectedCreditId: null,
      onlineStatusIntervalId: null,
      chatSupplierIsOnline: false,
      isShowPdf: false,
      documentHeight: 0,
      isEmptySignature: false,
      interval: null,
      focusedComments: [],
      resizer: 0,
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      isDropdown2Visible: false,
      isOpenPartContext: false,
      isUnderCreditRequest: false,
      autoSaveMessage: '',
      openedRows: [],
      changedPartStatus: [],
      stockOptions: States.stockOptions,
      changedPartComment: {}, // store temporary status comment in object with partId key
      changedPartCredit: {},  // store temporary request credit in object with partId key
      savedPartCredit: {},    // credit data from server
      trackingNumber: '',
      isOpenContextLevelTwo2: false,
      isOpenContextLevelTwo3: false,
      isOpenModal: false,
      selectedBackOrder: {
        "id": "",
        "createdBy": "",
        "data": {
          "parts": {
          },
          "settings": {
            "requireESignature": false,
            "termsAndConditions": null,
            "sendOrderAcceptanceBy": null,
            "displayOrderAcceptanceTICs": false,
            "requireSigneeToAddNameOrJob": false,
            "requireAcceptanceOfOrderTICs": false
          },
          "description": {
            "supplier": "",
            "orderNumber": "",
            "invoiceNumber": null
          }
        },
        "createdOn": "",
        "status": "",
        "sentTo": {
          "id": "",
          "name": ""
        },
        "sentOn": "",
        "sentBy": {
          "id": "",
          "name": ""
        }
      },
      contextInvoiceNumber: '',
      contextInvoiceDate: '',
      focusedElement: null,
      originalParts: [],
      isCanBlinking: true,
      isHoldCtrl: false,
      isHoldShift: false,
      photoConfig: {
        clickable: '#clickBrowseImagesBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        capture: 'camera',
        url: appConfig.baseAPIURL + '/ir/repairer/credit/upload-file',
        //      url: appConfig.baseAPIURL + '/ir/user/photo/upload',
        headers: { 'Authorization': localStorage.getItem('token') },
        method: 'post',
        maxFilesize: 50,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      searchFilter: '',
      order: {
        id: 0,
        supplierDeliveryDate: null,
        supplierOrderPartsDeliveryETA: null,
        repairerDeliveryDate: null,
        creditProcessingPolicies: {
          returnPeriod: null,
          requirePhotos: false,
          notice: '',
        },
        supplier: {
          name: '',
          type: '',
          partsDeptPH: '',
          partsType: '',
        },
        vehicle: {
          make: '',
          model: '',
          series: '',
          rego: '',
        },
        rfq: {
          id: null,
          directOrderInstruction: null,
          name: '',
          extQps: null,
        },
        properties: {
          isSentAsDirectOrder: false
        },
        business: '',
        parts: [],
        files: [],
        QPNumber: '',
        total: '',
        gst: '',
        subtotal: '',
        status: '',
        statusId: 0,
        paymentTerms: '',
        dateCreated: '',
        dateDue: '',
        deliveryTo: {
          address: '',
          postcode: '',
          repairerInstruction: '',
          supplierInstruction: '',
          state: '',
          street: '',
          suburb: '',
        },
        deliveryETA: '',
      },
      isEditable: true,
      isSelectedManualy: false,
      callComputed: 0,
      callComputed2: 0,
      selectedRow: [], // can be multi select
      selectedRowDetail: null, // row detail that open -- object
      input: {
        reasonCancel: '',
        returnReason: '',
        didNotArrive: {
          RepairerReason: 'Part did not arrive on time sourced from another supplier ',
          SupplierReason: 'The part has been sent our courier company has provided photo evidence of delivery'
        }
      },
      detailMode: true,
      isLoadedPartOrder: false,
    }
  },
  computed: {
    ...mapGetters({
      getIsNeedUpdate: 'repairer/order/getIsNeedUpdate',
      ordersFromUpdate: 'repairer/order/ordersFromUpdate',
      companyInfo: 'currentCompany/getDetails',
      currentUser: 'currentUser/getCurrentUserInfo',
      gettersAllOrders: 'repairer/order/getAllOrders',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllOrders, (r) => {
        return _.startsWith(_.trim(_.toLower(r.number)), _.trim(_.toLower(this.searchFilter)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.number
        if (itm.supplierName) {
          label +=  ' - '
        }

        if (itm.supplierName) {
          label += itm.supplierName + ' '
        }
        result.push({
          value: itm.id,
          label: label,
          number: itm.number,
          rfqId: itm.rfqId,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds=[]
      let jobViewOptions = []
      _.forEach(result, (r) => {
        if (!_.includes(addedJobViewIds, r.jobViewId)) {
          let l = 'JOB VIEW'
          if (r.number) {
            l = r.number + ' - JOB VIEW'
          }

          if (r.rfqId != r.jobViewId) {
            if (result.length == 1) {
              jobViewOptions.push({
                value: r.rfqId,
                label: l,
                isJobView: true
              })
              addedJobViewIds.push(r.jobViewId)
            }
          } else {
            jobViewOptions.push({
              value: r.rfqId,
              label: l,
              isJobView: true
            })
            addedJobViewIds.push(r.jobViewId)
          }
        }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    computedAvailableRefs () {
      let result = []
      _.forEach(this.order.parts, (part) => {
        let cells = ['stock-select-' + part.id, 'qty-' + part.id, 'qtyRcv-' + part.id,  'rrp-' + part.id, 'price-' + part.id, 'invoice-' + part.id, 'location-' + part.id]
        if (part.isEdit) {
          result.push(cells)
        }
      })
      return result
    },
    isConnectToIBS() {
      return this.order?.rfq?.extQps?.sourceSystem === 'PARTSBRIDGE_IBODYSHOP'
    },
    isCanAccept() {
      this.callComputed2++
      let b = true;
      if (this.selectedBackOrder.data.settings.requireSigneeToAddNameOrJob) {
        b = b && !_.isEmpty(this.backOrderSelectedParts.acceptedBy) && !_.isEmpty(this.backOrderSelectedParts.positionHeld)
      }
      if (this.selectedBackOrder.data.settings.requireESignature) {
        b = b && !this.isEmptySignature
      }
      if (this.selectedBackOrder.data.settings.requireAcceptanceOfOrderTICs) {
        b = b && this.backOrderSelectedParts.acceptanceOfTics
      }
      b = b && Object.keys(this.selectedBackOrder?.data?.parts)?.length === this.backOrderSelectedParts?.parts?.length

      return b
    },
    isMobileOnly () {
      return isMobileOnly
    },
    isTablet () {
      return isTablet
    },
    isCanCancelParts() {
      return _.some(this.order.parts, prt => {
        return prt.isChecked && prt.status !== 'CancelPart'
      }) && (this.order.status === 'Open' || this.order.status === 'Processing')
    },
    isCanCancelOrder() {
      return (this.order.status === 'Open' || this.order.status === 'Processing')
    },
    isDisabledCancelOrder() {
      return _.filter(this.order.parts, prt => {
        return prt.isChecked
      })?.length !== this.order.parts?.length
    },
    isTodayOrdered() {
      return dayjs(this.order?.dateCreated).isToday()
    },
    isYesterdayOrdered() {
      return dayjs(this.order?.dateCreated).isYesterday()
    },
    isCheckedPartsShipped() {
      return _.some(this.partsForCancelling, (prt) => {
        return prt.status == 'Shipped'
      })
    },
    isCheckedPartsCreated() {
      return _.some(this.partsForCancelling, (prt) => {
        return prt.status == 'Created'
      })
    },
    isCheckedPartsNonReturnable() {
      return _.some(this.partsForCancelling, (prt) => {
        return prt.isNonReturnable
      })
    },
    isCheckedPartsHasAcceptedBackOrder() {
      return _.some(this.partsForCancelling, (prt) => {
        return this.isOrderAccepted(prt)
      })
    },
    isCheckedPartsOnBackOrder() {
      return _.some(this.partsForCancelling, (prt) => {
        return prt.stock == 4
      })
    },
    isCheckedPartsExWarehouse() {
      return _.some(this.partsForCancelling, (prt) => {
        return prt.stock == 6
      })
    },
    getSupplierDeliveryDate() {
      if (_.isEmpty(this.order.supplierDeliveryDate)) {
        return this.order.supplierOrderPartsDeliveryETA
      }
      if (_.isEmpty(this.order.supplierOrderPartsDeliveryETA)) {
        return this.order.supplierDeliveryDate
      }
      let d1 = dayjs(this.order.supplierDeliveryDate,'DD/MM/YYYY')
      let d2 = dayjs(this.order.supplierOrderPartsDeliveryETA,'DD/MM/YYYY')
      if (d1.isBefore(d2)) {
        return this.order.supplierOrderPartsDeliveryETA
      } else if (d1.isAfter(d2)) {
        return this.order.supplierDeliveryDate
      }
      return this.order.supplierOrderPartsDeliveryETA
    },
    isMobile () {
      return isMobile
    },
    unviewedMessagesIds() {
      let ids = [];
      for(let m of this.chatMessages) {
        if(m.messageId && !m.viewed && m.companyId != this.companyInfo.id) {
          ids.push(m.messageId)
        }
      }
      return ids;
    },
    checkedParts() {
      return _.filter(this.order.parts, prt => {
        return prt.isChecked
      })
    },
    isCheckedAll: {
      get: function () {
        if (this.order.parts.length == 0) {
          return false
        }
        let result = _.filter(this.order.parts, function (item) {
          return item.isChecked == false
        })

        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        this.isCheckedAllValue = check
        _.forEach(this.order.parts, (prt) => {
          prt.isChecked = check

          let isSelectedPart = _.find(this.selectedRow, (r) => {
            return Number(r.id) == Number(prt.id)
          })

          if (check && !isSelectedPart) {
            this.selectedRow.push(prt)
          } else if (!check && isSelectedPart) {
            let ind = _.findIndex(this.selectedRow, (r) => {
              return Number(r.id) == Number(prt.id)
            })
            this.selectedRow.splice(ind, 1)
          }
        })
        this.callComputed++
      }
    },
    isCanBeCompleted() {
      return this.order.state != 2 && (this.isAllPartsReceived || this.isOrderCancelled || this.isOrderDenied || !_.some(this.orderItems, (itm) => {return itm.status == 'Created'}))
    },
    isCanMarkOnBackOrder() {
      return _.some(this.orderItems, (itm) => {
        return itm.stock == 4
      }) && this.order.state != 3
    },
    isOrderCancelled () {
      return this.order.status === 'Cancelled'
    },
    isOrderDenied () {
      return this.order.status === 'Denied'
    },
    isAllPartsReceived () {
      return _.filter(this.order.parts, (prt) => {
        return prt.status === 'Received'
      }).length === this.order.parts.length;
    },
    computedCustomCountForTab() {
      let counts = {
        'ordered-parts"': 0,
        'ordered-files': 0,
      }
      counts['ordered-parts'] = this.orderItems.length
      counts['ordered-files'] = this.order.files.length;
      return counts
    },
    orderFields () {
      let result = [
        {
          label: 'Line',
          key: 'line',
          sortable: false,
          tdClass: 'font-12 clickable',
          thClass: 'font-13 column-part-list position-sticky line-column',
          thStyle: 'border: 0 !important; background-color: #e3e4e7 !important; position: sticky !important;',
          class: 'part-list-line text-center font-inter font-inter--bold',
          stickyColumn: true
        },
        {
          label: 'Part Description',
          key: 'name',
          sortable: true,
          tdClass: 'font-12 clickable column-part-list font-weight-bold part-list-position-left',
          thStyle: 'position: sticky !important; top: auto !important;',
          thClass: 'font-13 column-part-list part-list-table-sticky part-list-position-left description-column',
          class: 'parts-order-th--part-list font-inter font-inter--bold',
          stickyColumn: true
        },
        {
          label: '',
          key: 'statusId',
          sortable: false,
          tdClass: 'font-20 clickable  column-status-id font-weight-bold',
          thClass: 'font-13 font-inter--bold  column-status-id status-id-column',
          class: 'font-inter'
        },
        {
          label: 'Part Number',
          key: 'partNumber',
          sortable: true,
          tdClass: 'font-12 clickable  column-part-number font-weight-bold',
          thClass: 'font-13 font-inter--bold  column-part-number number-column',
          class: 'font-inter'
        },
        {
          label: 'Type',
          key: 'partType',
          sortable: true,
          tdClass: 'font-12 clickable column-type font-weight-bold',
          thClass: 'font-13 font-inter--bold column-type type-column',
          class: 'font-inter'
        },
        {
          label: 'Stock',
          key: 'partStock',
          sortable: true,
          tdClass: 'font-12 clickable column-stock font-weight-bold',
          thClass: 'font-13 font-inter--bold column-stock stock-column',
          class: 'font-inter'
        },
        {
          label: 'Cost',
          key: 'total',
          sortable: true,
          tdClass: 'font-12 clickable column-cost font-weight-bold text-right',
          thClass: 'font-13 font-inter--bold column-cost text-right total-column',
          class: 'font-inter'
        },
        // {
        //     label: "List",
        //     key: "rrp",
        //     sortable: true,
        //     tdClass: 'font-12 font-inter--semibold clickable column-list ',
        //     thClass: 'font-13 font-inter--bold column-list',
        //     class: "font-inter"
        // },
        {
          label: 'Qty Order',
          key: 'qty',
          sortable: true,
          tdClass: 'font-12 clickable  column-qty-order font-weight-bold',
          thClass: 'font-13 font-inter--bold column-qty-order qty-column',
          class: 'font-inter text-center'
        },
        {
          label: 'Qty Rcvd',
          key: 'qtyRcv',
          sortable: true,
          tdClass: 'font-12 clickable  column-qty-rcvd font-weight-bold',
          thClass: 'font-13 font-inter--bold column-rcvd qty-rcv-column',
          class: 'font-inter text-center'
        },
        {
          label: 'Unit List',
          key: 'rrp',
          sortable: true,
          tdClass: 'font-12 clickable  column-qty-rcvd font-weight-bold text-right',
          thClass: 'font-13 font-inter--bold column-rcvd text-right rrp-column',
          class: 'font-inter'
        },
        {
          label: 'Unit Net',
          key: 'price',
          sortable: true,
          tdClass: 'font-12 clickable  column-qty-rcvd font-weight-bold text-right',
          thClass: 'font-13 font-inter--bold column-rcvd text-right price-column',
          class: 'font-inter text-center'
        },
        {
          label: 'Credit',
          key: 'creditNumber',
          sortable: true,
          tdClass: 'font-12 clickable  column-credit font-weight-bold',
          thClass: 'font-13 font-inter--bold column-credit credit-column',
          class: 'font-inter'
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: 'font-12 clickable column-status font-weight-bold',
          thClass: 'font-13 font-inter--bold column-status status-column',
          class: 'font-inter'
        },
        {
          label: 'Delivery Date',
          key: 'supplierDeliveryDate',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-right pr-5',
          thClass: 'font-13 font-inter--bold text-center delivery-column',
          class: 'font-inter'
        },
        {
          label: 'Invoice',
          key: 'invoiceNumber',
          sortable: true,
          tdClass: 'font-12 clickable  column-credit font-weight-bold',
          thClass: 'font-13 font-inter--bold column-credit invoice-column',
          class: 'font-inter text-center'
        },
        {
          label: 'Invoice Date',
          key: 'invoiceDate',
          sortable: true,
          tdClass: 'font-12 clickable  column-credit font-weight-bold',
          thClass: 'font-13 font-inter--bold column-credit invoice-column',
          class: 'font-inter text-center'
        },
        {
          label: 'Location',
          key: 'workshopLocation',
          sortable: true,
          tdClass: 'font-12 clickable   column-workshop-location font-weight-200',
          thClass: 'font-13 font-Inter--bold column-workshop-location location-column',
          class: 'font-Inter'
        },
      ];
      if (this.order?.supplier?.partsType === 'OEM-GEN' || this.order?.supplier?.partsType === 'OEM-PAR') {
        let ind = _.findIndex(result, (itm) => {
          return itm.key === 'price'
        })
        result.splice(ind + 1, 0,    {
          label: 'Net Profit',
          key: 'netProfit',
          sortable: true,
          tdClass: 'font-12 clickable  column-qty-rcvd font-inter--bold text-right',
          thClass: 'font-13 font-inter--bold column-rcvd text-right profit-column',
          class: 'font-inter text-center'
        },)
      }
      return result
    },
    computedTableWidth () {
      if (!this.resizer) {
        return 0
      }
      let tr = this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children;
      let summ = tr?.[0]?.offsetWidth + tr?.[1]?.offsetWidth + tr?.[2]?.offsetWidth + tr?.[3]?.offsetWidth + tr?.[4]?.offsetWidth + tr?.[5]?.offsetWidth - 337;
      return summ
    },
    computedTableMinCellWidth () {
      if (!this.resizer) {
        return 0
      }
      return this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[6]?.offsetWidth + 2;
    },
    statusList () {
      return [
        {
          id: 'Created',
          name: 'Ordered',
          nameClass: 'text-success',
          color: 'info',
          iconClass: this.order && this.order.status === 'Processing' ? 'bx bx-package mr-0' : 'bx bxs-check-circle text-success mr-0',
          tooltip: 'Ordered'
        },
        {
          id: 'Shipped',
          name: 'Shipped',
          nameClass: 'text-primary',
          color: 'warning',
          iconClass: 'bx bxs-truck text-primary'
        },
        {
          id: 'Received',
          name: 'Received',
          nameClass: 'text-success',
          color: 'success',
          iconClass: 'bx bx-buildings',
          tooltip: 'Received'
        },
        {
          id: 'Arrived',
          name: 'Arrived',
          nameClass: 'text-success',
          color: 'success',
          iconClass: 'bx bx-buildings',
          tooltip: 'Arrived'
        },
        {
          id: 'CancelPart',
          name: 'Cancelled',
          nameClass: 'cancel-part-class',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger',
          tooltip: 'Cancelled'
        },
        {
          id: 'NoLongerNeeded',
          name: 'No Longer Needed',
          nameClass: 'text-warning',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'No Longer Needed'
        },
        {
          id: 'ChangeInRepairMethod',
          name: 'Change in Repair Method',
          nameClass: 'text-warning',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'Change in Repair Method'
        },
        {
          id: 'ReturnDamagedPart',
          name: 'Returned',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx font-24 bx-transfer-alt text-warning',
          tooltip: 'Returned'
        },
        {
          id: null,
          name: 'Returned',
          description: '"Returned" with credit status pending',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary',
          tooltip: 'Returned'
        },
        {
          id: null,
          name: 'Returned',
          description: '"Returned" with credit status approved',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary',
          tooltip: 'Returned'
        },
        {
          id: 'DamagedPartExWarehouse',
          name: 'Mark Damaged Part Ex Warehouse',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bxs-factory text-warning',
          tooltip: 'Damaged Part Ex Warehouse'
        },
        {
          id: null,
          name: 'Returned',
          description: '"Returned" with credit status denied',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary',
          tooltip: 'Returned'
        },
        {
          id: 'IncorrectPartDelivered',
          name: 'Incorrect Part',
          nameClass: 'text-danger',
          color: 'bg-warning',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'Incorrect'
        },
        {
          id: 'IncorrectPartOrdered',
          name: 'Incorrect Part Ordered',
          nameClass: 'text-danger',
          color: 'bg-warning',
          iconClass: 'bx bxs-flag-alt incorrect-part-ordered-icon',
          tooltip: 'Incorrect Ordered'
        },
        {
          id: 'DidNotArrive',
          name: 'Did Not Arrive',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: null,
          name: 'Did Not Arrive',
          description: '"Did Not Arrive" with credit status pending',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: null,
          name: 'Did Not Arrive',
          description: '"Did Not Arrive" with credit status approved',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: null,
          name: 'Did Not Arrive',
          description: '"Did Not Arrive" with credit status denied',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
      ]
    },
    isCanEnableMarkReceivedAll () {
      return (_.findIndex(this.order.parts, function (el) {
        if (el.status == 'Arrived' && !(el.credit && el.credit.id > 0)) {
          return true
        }
      }) != -1)
    },
    isCanEnableMarkArrivedAll () {
      return (_.findIndex(this.order.parts, function (el) {
        if (el.status != 'Arrived' && el.status !== 'CancelPart' && !(el.credit && el.credit.id > 0)) {
          return true
        }
      }) != -1)
    },
    isCanEnableMarkAll () {
      return (_.findIndex(this.order.parts, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered') {
          return true
        }
      }) != -1)
    },
    canShowMarkPartReceivedButton () {
      return this.isCanEnableMarkReceivedSelected
      console.log(this.isCanEnableMarkReceivedSelected)
    },
    isEnableShowMarkPartCancelPartButton () {
      return this.isCanEnableMarkSelected
    },
    isCanEnableMarkReceivedSelected () {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return false;
      }
      return (_.findIndex(this.selectedRow, function (el) {
        if ((el.status != 'Received') && !(el.credit && el.credit.id > 0)) {
          return true
        }
      }) != -1)
    },
    isCanEnableMarkArrivedSelected () {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return false;
      }
      return (_.findIndex(this.selectedRow, function (el) {
        if ((el.status != 'Arrived') && !(el.credit && el.credit.id > 0)) {
          return true
        }
      }) != -1)
    },
    isCanEnableMarkSelected () {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return false;
      }
      return (_.findIndex(this.selectedRow, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered' && el.status != 'IncorrectPartOrdered' && el.status != 'DamagedPartExWarehouse' && el.status != 'NoLongerNeeded' && el.status != 'ChangeInRepairMethod') {
          return true
        }
      }) != -1)
    },
    deliveryToText () { //with html
      let result = ''
      if (!_.isEmpty(this.order.deliveryTo) && typeof this.order.deliveryTo === 'string') {
        result = this.order.deliveryTo
      } else if (!_.isEmpty(this.order.deliveryTo) && typeof this.order.deliveryTo === 'object') {
        const { address, suburb, state, postcode, unitLevelLot } = this.order.deliveryTo
        result = `
                          ${address}${unitLevelLot ? ', Unit/Level: ' + unitLevelLot : ''}<br>
                          ${suburb}, ${state}, ${postcode}
                      `
      }
      return result
    },
    orderInstruction () {
      let r = ''
      if (this.order?.properties?.isSentAsDirectOrder) {
        if (this.order?.rfq?.directOrderInstruction) {
          r += this.order.rfq.directOrderInstruction
        }
      } else if (this.order?.deliveryTo?.repairerInstruction) {
        r += this.order.deliveryTo.repairerInstruction
      }

      if (this.order?.deliveryTo?.supplierInstruction) {
        r += this.order.deliveryTo.supplierInstruction.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }
      return r
    },
    pageTitle () {
      return `Order - ${this.order.number} | ${this.order.QPNumber || ''} | ${this.order.vehicle.make || ''} ${this.order.vehicle.model || ''} ${this.order.vehicle.series || ''} | ${this.order.vehicle.rego || ''}`
    },
    mobileOptionsClass () {
      let r = ''
      if (this.pageTitle.length >= 62 && this.pageTitle.length <= 69) {
        r += 'top-15'
      }
      // if (this.lengthSelectedRows >= 1) {
        r += ' narrow-version severalSelectedRows'
      // }
      return r
    },
    lengthSelectedRows () {
      return this.selectedRow.length
    },
    isCanMarkNotReceived() {
      return _.some(this.selectedRow, (r) => {
        return r.status === 'Received'
      })
    },
    isCanMarkNotArrived() {
      return _.some(this.selectedRow, (r) => {
        return r.status === 'Arrived'
      })
    },
    isCanUndoChanges() {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return false
      }
      return _.some(this.selectedRow, (r) => {
        return r.isUndoPartLineChange && !r?.credit?.id
      })
    },
    isCanSetEditing() {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return false
      }
      return _.some(this.selectedRow, (r) => {
        return !r.isEdit
      })
    },
    isCanConfirmEditing() {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return false
      }
      return _.some(this.selectedRow, (r) => {
        return r.isEdit
      })
    },
    isCanReverse() {
      return _.some(this.selectedRow, (r) => {
        return r?.credit?.id > 0 && r?.credit?.creditPartStatus === 'UnderReview'
      })
    },
    lengthOrderItems () {
      return this.orderItems.length
    },
    orderItems () {
      this.callComputed++
      return this.order.parts
    },

  },
  methods: {
    redirectToRfqView(itm) {
      if (itm.isJobView) {
        this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
      } else {
        this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.value}});
      }
    },
    onEnter() {
      setTimeout(() => {
        let order = _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })
        if (order && !_.isEmpty(this.searchFilter)) {
          this.$router.push({name: 'RepairerJobView', params: {job_id: order.value}});
          // this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: order.id}});
        }
      }, 500)
    },
    onFocus (ref) {
      this.focusedElement = ref
    },
    isInFocusElement (ref) {
      if (ref === this.focusedElement) {
        return true
      }
      return false
    },
    onBlur (part, field, time = 0) {
      setTimeout(() => {
        let updatedPart = _.find(this.originalParts, prt => {
          return Number(prt.id) === Number(part.id);
        })
        console.log(part, field, updatedPart)
        if (updatedPart) {
          if (updatedPart[field] !== part[field]) {
            let obj = {
              id: part.id,
            }
            obj[field] = part[field]
            this.autoSavePart(obj)
          }
        }
        this.focusedElement = '';
      }, time)
    },
    getElement (ref) {
      let element = null
      if (this.$refs[ref] && this.$refs[ref][0]) {
        element = this.$refs[ref][0]
      } else if (this.$refs[ref] && this.$refs[ref]._isVue) {
        element = this.$refs[ref]
      } else if (this.$refs[ref]) {
        element = this.$refs[ref]
      }
      return element
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    onKeyPressUp (ref) {
      console.log('up', ref)
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow === 0) {
        resultRef = this.computedAvailableRefs[this.computedAvailableRefs.length - 1][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow - 1][indexOfCurrentElement]
      }
      let element = this.getElement(resultRef)

      if (!element || element.isDisabled || element.disabled) {
        this.onKeyPressUp(resultRef)
        return
      }

      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    onKeyPressDown (ref) {
      console.log('down')
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow + 1 === this.computedAvailableRefs.length) {
        resultRef = this.computedAvailableRefs[0][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][indexOfCurrentElement]
      }
      let element = this.getElement(resultRef)

      if (!element || element.isDisabled || element.disabled) {
        this.onKeyPressDown(resultRef)
        return
      }

      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    onKeyPressLeft (ref, e) {
      if (e) {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos > 0) {
          return
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentElement === 0) {
        let resultRow = []
        if (indexOfCurrentRow === 0) {
          resultRow = this.computedAvailableRefs[this.computedAvailableRefs.length - 1]
        } else {
          resultRow = this.computedAvailableRefs[indexOfCurrentRow - 1]
        }
        let resultRowLastIndex = resultRow.length - 1
        resultRef = resultRow[resultRowLastIndex]
        let element = this.getElement(resultRef)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement - 1]
        let element = this.getElement(resultRef)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      }

      let element = this.getElement(resultRef)
      if (!element) {
        return
      }
      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    onKeyPressRight (ref, e) {
      console.log('ref', ref)
      if (e) {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos < elem.value.length) {
          return false
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      console.log(indexOfCurrentElement, indexOfCurrentRow, 'indexes')
      let resultRef = null

      if (indexOfCurrentElement === this.computedAvailableRefs[indexOfCurrentRow].length - 1) {
        if (indexOfCurrentRow === this.computedAvailableRefs.length - 1) {
          resultRef = this.computedAvailableRefs[0][0]
        } else {
          resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][0]
        }
        console.log('result ref', resultRef)
        let element = this.getElement(resultRef)
        console.log('element', element)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement + 1]
        console.log('result ref', resultRef)
        let element = this.getElement(resultRef)
        console.log('element', element)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      }
      let element = this.getElement(resultRef)
      if (!element) {
        return
      }
      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    sendToIBS() {
      window.postMessage({
        type: 'partsbridge-IBODYSHOP_CREATE_ORDER',
        data: {
          rfqId: this.order.rfq.id,
          qpsSystem: this.order?.rfq?.extQps
        }
      })
    },
    editOrder(v) {
      this.$refs.menuPartsOrder.close()
      _.forEach(this.order.parts, (p, ind) => {
        if (p.isChecked) {
          let stock = this.getStockById(Number(p.stock))

          p.stockValue = {
            'label': stock?.label ? stock.label : '',
            'textValue': stock?.textValue ? stock.textValue : '',
            'value': Number(p?.stock),
            'comment': p?.orderStockComment  ? p.orderStockComment  : '',
          }
          this.$set(this.order.parts[ind], 'isEdit', v)
        }
      })
    },
    selectStockStatus ({ comment, label, textValue, value, isHighlight }, part, ) {
      let index = _.findIndex(this.order.parts, (p) => {
        return Number(p.id) === Number(part.id)
      })
      this.$set(this.order.parts[index], 'stock', value)
      this.$set(this.order.parts[index], 'stockComment', comment)
      this.$set(this.order.parts[index], 'partStock', textValue)
      part.stock = value
      part.orderStockComment = comment
      part.partStock = textValue
      // this.callComputed++
      this.autoSavePart({
        id: part.id,
        stock: value,
        partStock: textValue,
        orderStockComment: comment
      })
    },
    autoSavePart (part) {
      NProgress.start()
      Axios.post('/ir/repairer/order/' + this.order.id, {parts: [part]})
          .finally(() => {NProgress.done()})
      console.log(part, 'part')
    },
    getStockById (value) {
      if (value === null) {
        return ''
      }
      let status = _.find(States.stockOptions.slice(), (itm) => {
        return Number(itm.value) === Number(value)
      })
      if (status) {
        return status
      }
      return ''
    },
    addPhoto(isMobile) {
      this.$refs.fileUpload.clickOnBrowseFiles()
      if (isMobile) {
        this.$refs['parts-order-view-tabs'].selectTab('#ordered-files')
      }
    },
    sortBy (type) {
      this.sortByTable = ''
      this.sorting.type = type
      if (this.sorting.direction === 'asc') this.sorting.direction = 'desc'
      else this.sorting.direction = 'asc'

      this.$set(this.order, 'parts', _.orderBy(this.order.parts, [(itm) => {
        if (type == 'credit') {
          return itm?.credit?.number
        }
        return itm[type]
      }], [this.sorting.direction]))
      this.callComputed++
    },
    onChangedCheckedAll() {
      let el = document.getElementById('all-line-check')
      if (el) {
        el.click()
      }
    },
    getTopScrollWidth() {
      return document.getElementsByClassName('repairer-order-view-table')?.[0]?.clientWidth - 23 + 'px'
    },
    setFakeScroll() {
      $('.fake').width($('#order-table').width());
      let width = document.getElementsByClassName('repairer-order-view-table')?.[0]?.clientWidth - 23
      let topscrollEl = document.getElementsByClassName('topscroll')?.[0]
      if (!topscrollEl) {
        return
      }
      topscrollEl.style.width = width + 'px'

      if (document.getElementById('last-table-line')?.getBoundingClientRect()?.bottom <= window.innerHeight) {
        $('.topscroll').hide()
      } else {
        $('.topscroll').show()
      }

      let container = $('.repairer-order-part-list');
      let topscroll = $('.topscroll');

      topscroll.scroll(function(e){
        container.scrollLeft($(this).scrollLeft());
      });
      container.scroll(function(e){
        topscroll.scrollLeft($(this).scrollLeft());
      });
    },
    changedTab: function (obj) {
      this.activeTab = obj.tab.name
    },
    onScroll() {
      this.setFakeScroll()
      if (this.activeTab !== 'Ordered Parts') {
        return
      }
      let element = document.getElementById('additional-info')
      if (!element){
        return
      }
      let rect =  element.getBoundingClientRect()
      if (rect.top <= 150) {

        this.isShowStickyHeader = true

        this.onTableScroll()
      } else {
        this.isShowStickyHeader = false
      }
    },
    onTableScroll() {
      if ( this.$refs.menuActionForReason) {
        this.$refs.menuActionForReason.close()
      }
      if (this.$refs.menuPartsOrder) {
        this.$refs.menuPartsOrder.close()
      }

      this.$nextTick(() => {
        let el = document.getElementsByClassName("repairer-order-part-list")[0];
        let el2 = document.getElementById("order-view-thead-wrapper");
        let topscrollEl = document.getElementsByClassName('topscroll')?.[0]

        if (el2 && el) {
          el2.scrollLeft = el.scrollLeft;
          if (topscrollEl) {
            topscrollEl.scrollLeft = el.scrollLeft;
          }
        }
      })
    },
    getTooltip(item) {
      if (this.isOrderAccepted(item) || this.isOrderDeclined(item)) {
        let r = `Back Order ${this.isOrderAccepted(item) ? 'accepted' : 'declined'} by - ${this.getOrder(item) ? this.getOrder(item)?.acceptOrDeclineBy?.name : ''}`
        return r
      } else {
       return  'Back Order Acceptance Received' + '<br/>' + this.formatDateTime(this.getOrder(item) ? this.getOrder(item).sentOn : '')
      }
    },
    isOrderReceived(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'sent' || o.status == 'accepted' || o.status === 'declined')
      })
    },
    isOrderAccepted(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'accepted')
      })
    },
    isOrderDeclined(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status === 'declined')
      })
    },
    getOrder(itm) {
      return _.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'sent' || o.status == 'accepted' || o.status === 'declined')
      })
    },
    acceptNonReturnable(status, id) {
      const parser = new UAParser();
      let result = parser.getResult()
      let type =  'Desktop'
      if (this.isMobileOnly) {
        type = 'Phone'
      } else if (this.isTablet) {
        type = 'Tablet'
      }

      let signaturePad = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0]
      let signature = null
      if (signaturePad) {
        signature = signaturePad.toDataURL()
      }
      let data = {
        "eSignature": signature,
        status: status,
        acceptanceOfTics: this.backOrderSelectedParts.acceptanceOfTics,
        acceptedBy: this.backOrderSelectedParts.acceptedBy,
        positionHeld: this.backOrderSelectedParts.positionHeld,
        parts: this.backOrderSelectedParts.parts,
        specialOrderId: id,

        type: type,
        system: result?.os?.name + ' ' + result?.os?.version,
        browser: result?.browser?.name + ' version ' + result?.browser?.version,
      }
      NProgress.start()
      Axios.post('/ir/repairer/order/' + this.order.id + '/change-status-back-order',
          data
      )
          .then(response => {
            if (response.data._status) {
              let index = _.findIndex(this.backOrders, (o) => {
                return Number(o.id) === Number(id)
              })
              if (index !== -1) {
                this.backOrders[index].status = status
                this.backOrders[index].signedBy = this.backOrderSelectedParts.acceptedBy
                this.backOrders[index].signedByPositionHeld = this.backOrderSelectedParts.positionHeld
                this.backOrders[index].eSignature = signature
              }

              if (status === 'declined' && !_.isEmpty(data.parts)) {
                _.forEach(data.parts, (id) => {
                  let prtInd = _.findIndex(this.order.parts, (ordPrt) => {
                    return Number(ordPrt.id) == Number(id)
                  })
                  if (prtInd !== -1) {
                    this.$set(this.order.parts[prtInd], 'status', 'CancelPart')
                    this.$set(this.order.parts[prtInd], 'lastChangeStatusBy', this.currentUser.fullName)
                    this.$set(this.order.parts[prtInd], 'lastChangeStatusDate', new Date())
                    this.$set(this.order.parts[prtInd], 'cancelStatus', 'CancelledByCustomer')
                    this.$set(this.order.parts[prtInd], 'price', 0)
                  }
                })
              }

              if (this.$refs['back-order-' + this.selectedBackOrder.id]?.[0]) {
                this.$refs['back-order-' + this.selectedBackOrder.id]?.[0].hide();
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    openPdf(v) {
      this.isShowPdf = v;
      this.handleResize()
    },
    confirmCancellation() {
      let data = [{
        id: this.order.id,
        partsIds: [],
        message: this.cancelMessage,
      }]
      _.forEach(this.partsForCancelling, (p) => {
        data[0].partsIds.push(p.id)
      })
      NProgress.start()
      let successText =  data[0].partsIds.length == 1 ? 'Part has been cancelled' : 'Parts have been cancelled'
      let errorText =  data[0].partsIds.length == 1 ? 'Part hasn`t been cancelled' : 'Parts haven`t been cancelled'

      Axios.post('/ir/repairer/order/request-cancellation', data)
          .then(response => {
            if (response.data._status) {
              this.$toast.success(successText)

              _.forEach(data[0].partsIds, (prt) => {
                let partIndex = _.findIndex(this.order.parts, (part) => {
                  return Number(part.id) === Number(prt)
                })
                if (partIndex !== -1) {
                  this.$set(this.order.parts[partIndex], 'status', 'CancelPart')
                  this.$set(this.order.parts[partIndex], 'lastChangeStatusBy', this.currentUser.fullName)
                  this.$set(this.order.parts[partIndex], 'lastChangeStatusDate', new Date())
                  this.$set(this.order.parts[partIndex], 'cancelStatus', 'CancelledByCustomer')
                  this.$set(this.order.parts[partIndex], 'price', 0)
                }
              })
            } else {
              this.$toast.error(errorText)
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error(errorText)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    cancelParts(v = false) {
      this.partsForCancelling = v ? this.order.parts : _.filter(this.order.parts, (prt) => {
        return prt.isChecked
      });
      this.$refs.menuPartsOrder.close();
      this.$refs.cancelPart.show();
    },
    addPhoto(isMobile) {
      this.$refs.fileUpload.clickOnBrowseFiles()
      if (isMobile) {
        this.$refs['parts-order-view-tabs'].selectTab('#ordered-files')
      }
    },
    onCloseChat() {
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
    },
    getNRTooltip(item) {
      let r = '<b>Part accepted as a Non-Returnable</b>'
      if (item.nonReturnableAcceptedBy) {
        r += '<br/> Accepted by ' + item.nonReturnableAcceptedBy
      }
      if (item.nonReturnableAcceptedOn) {
        r += '<br/> ' + this.formatDateTime(item.nonReturnableAcceptedOn)
      }
      return r
    },
    onCloseModal(id) {
      this.isOpenModal = false;
      // this.backOrderSelectedParts = {}
      let index = _.findIndex(this.openedModalsForOrders, (o) => {
        return Number(o) == Number(id)
      })
      if (index !== -1) {
        this.openedModalsForOrders.splice(index, 1)
      }
      if (!_.isEmpty(this.openedModalsForOrders)) {
        this.isOpenModal = true;
        this.isEmptySignature = true;
        let self = this;
        this.selectedBackOrder = _.find(this.backOrders, (o) => {
          return Number(o.id) == Number(self.openedModalsForOrders[self.openedModalsForOrders.length - 1])
        })

        if (this.selectedBackOrder.status !== 'sent') {
          this.backOrderSelectedParts.acceptanceOfTics = true
          this.backOrderSelectedParts.acceptedBy = this.selectedBackOrder.signedBy
          this.backOrderSelectedParts.positionHeld = this.selectedBackOrder.signedByPositionHeld
        } else {
          this.backOrderSelectedParts.acceptanceOfTics = false
          this.backOrderSelectedParts.acceptedBy = ''
          this.backOrderSelectedParts.positionHeld = ''
          this.backOrderSelectedParts.parts = []
        }
        this.callComputed2++
        this.$nextTick(() => {
          setTimeout(() => {
            let signatureCanvas = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0]?.$el
            if (signatureCanvas) {
              let backOrderSignatureBlock = this.$refs['specialOrderBlock-' + this.selectedBackOrder.id]?.[0]
              if (backOrderSignatureBlock) {
                let width = backOrderSignatureBlock.offsetWidth;
                signatureCanvas.width = width
                signatureCanvas.style.width = width + 'px'
                signatureCanvas.height = 100
                signatureCanvas.style.height = '100px'
              }
            }
          }, 0)
        })
      }

    },
    reverseParts() {
      let data  = _.filter(this.selectedRow, (r) => {
        return r?.credit?.id > 0 && r?.credit?.creditPartStatus === 'UnderReview'
      })
      let payload = {
        partsIds: []
      }
      _.forEach(data, (r) => {
        payload.partsIds.push(r.id)
      })
      Axios.post('/ir/repairer/credit/' + data[0].credit.id + '/reverse-credit', payload)
          .then(response => {
            if (response.data._status) {
              this.$toast.success('Order changes have been saved')
              _.forEach(data, (itm) => {
                let index = _.findIndex(this.orderItems, (part) => {
                  return Number(itm.id) === Number(part.id)
                });
                if (index !== -1) {
                  this.$set(this.order.parts[index], 'credit', [])

                  let responsePart = _.find(response.data.parts, (p) => {
                    return Number(p.partId) === Number(itm.id)
                  })
                  if (responsePart) {
                    this.$set(this.order.parts[index], 'status', responsePart.status)
                  }
                }
              })
            } else {
              this.$toast.error('Order changes have not been saved')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Order changes have not been saved')
          })
      this.$refs.menuPartsOrder.close()
    },
    addFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    successFileUpload (file, response) {
      if (response.status || response._status) {
        this.selectedFileId = response.fileId
      }
    },
    sendFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    attachPhoto() {
      Axios.post('/ir/repairer/credit/' + this.selectedCreditId + '/add-file/' + this.selectedFileId)
          .then(response => {
            if (response.data.status || response.data._status) {
              // this.$toast.success('The Order has been completed')
              this.$refs.customModal.hide();
            } else {
              // this.$toast.error('The Order hasn`t been completed')
            }
          })
          .catch(error => {
            console.log(error)
            // this.$toast.error('The Order hasn`t been completed')
          })
    },
    onChatMessage (data) {
      if(data.type === 'newMessage'){
        this.chatMessages.push(data)
        if(data.messageId && data.companyId != this.companyInfo.id){
          this.markMessagesAsViewed([data.messageId]);
        }
      }
      if(data.type === 'supplierIsOnline'){
        this.chatSupplierIsOnline = true;
        this.supplierLeavedTheChat();
      }
      if(data.type === 'typingInChat' && data.id !== this.currentUser.id){
        this.chatTypers[data.id] = {
          name: data.name,
          microtime: Date.now(),
        }
      }
    },
    sendChatMessage(text){
      if(!text || !text.trim()){
        return new Promise((resolve) => {
          resolve(false)
        });
      }
      return Axios.post('/centrifuge/send-message-to-order', {
        orderId: this.order.id,
        message: text.trim(),
      })
    },
    onChatMessageSubmit({message,resolve}){
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
      this.sendChatMessage(message).then(res => {
        resolve(res.data?._status)
      }).catch(() => {
        resolve(false)
      })
    },
    markMessagesAsViewed(messageIds = []){
      return Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        messageIds
      })
    },
    onChatInputTyping(){
      this._chat.publish({
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      })
    },
    getTypes(types) {
      if (types?.length == 1) {
        return types[0]
      } else if (types?.length > 1) {
        return 'Multiple'
      }
      return ''
    },
    sendExtQpsRfqData (is_init = false) {
      console.log('CONNECTED_ESTIMATE_DATA', this.order?.rfq?.extQps)
      let type = 'CONNECTED_ESTIMATE_DATA';
      if(is_init){
        type += '_INIT';
      }
      window.postMessage({
        type,
        data: this.order?.rfq?.extQps || null
      })
    },
    getTooltipForPart(text, item) {
      if (text == 'Total' && Number(item.total) == 0) {
        return ''
      }
      if (Number(item.originalQty) < Number(item.qty)) {
        return text + ' Increased'
      } else if (Number(item.originalQty) > Number(item.qty)) {
        return text + ' Decreased'
      }
      return ''
    },
    getTooltipForPart2(text, item) {
      if (Number(item.originalQtyRcv) < Number(item.qtyRcv)) {
        return text + ' Increased'
      } else if (Number(item.originalQtyRcv) > Number(item.qtyRcv)) {
        return text + ' Decreased'
      }
      return ''
    },
    onCheckboxChange(item, value) {
      let index = _.findIndex(this.order.parts, (prt) => {
        return Number(prt.id) == Number(item.id)
      })
      if (index !== -1) {
        // if (value) {
        //   this.$refs.orderTable.selectRow(index)
        // } else {
        //   this.$refs.orderTable.unselectRow(index)
        // }

        this.$set(this.order.parts[index], 'isChecked', value)

        let isSelectedPart = _.find(this.selectedRow, (r) => {
          return Number(r.id) == Number(item.id)
        })

        if (value && !isSelectedPart) {
          this.selectedRow.push(item)
        } else if (!value && isSelectedPart) {
          let ind = _.findIndex(this.selectedRow, (r) => {
            return Number(r.id) == Number(item.id)
          })
          this.selectedRow.splice(ind, 1)
        }

      }
    },
    rowClass(item) {
      if (item.isChecked) {
        return 'drag-selectable checked-row'
      }
      return 'drag-selectable'
    },
    checkCtrlAndShift(e) {
      if (e.which == 17) {
        this.isHoldCtrl = true
      } else if (e.which == 16) {
        this.isHoldShift = true
      }
    },
    removeCtrlAndShift() {
      this.isHoldCtrl = false
      this.isHoldShift = false
    },
    getColspan() {
      if (this.order?.supplier?.partsType === 'OEM-GEN' || this.order?.supplier?.partsType === 'OEM-PAR') {
        return 14
      }
      return 13
    },
    checkSignature () {
      if (this.$refs?.['signaturePad-' + this.selectedBackOrder.id]?.[0]) {
        this.isEmptySignature = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0].isEmpty()
        this.callComputed2++
      }
    },
    clearSignature () {
      let signaturePad = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0]
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    showModal (item, id = null) {
      this.$root.$emit('bv::hide::tooltip')
      this.isOpenModal = true;
      if (id) {
        this.selectedBackOrder = _.find(this.backOrders, (o) => {
          return Number(o.id) == Number(id)
        })
      } else {
        this.selectedBackOrder = this.getOrder(item)
      }
      this.$nextTick(() => {
        if (this.$refs['back-order-' + this.selectedBackOrder.id]?.[0]) {
          this.$refs['back-order-' + this.selectedBackOrder.id][0].show();
        }
      })
      if (this.selectedBackOrder.status !== 'sent') {
         this.backOrderSelectedParts.acceptanceOfTics = true
         this.backOrderSelectedParts.acceptedBy = this.selectedBackOrder.signedBy
         this.backOrderSelectedParts.positionHeld = this.selectedBackOrder.signedByPositionHeld
      } else {
        this.backOrderSelectedParts.acceptanceOfTics = false
        this.backOrderSelectedParts.acceptedBy = ''
        this.backOrderSelectedParts.positionHeld = ''
        this.backOrderSelectedParts.parts = []
      }
      this.callComputed2++
      this.$nextTick(() => {
        setTimeout(() => {
          let signatureCanvas = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0]?.$el
          if (signatureCanvas) {
            let backOrderSignatureBlock = this.$refs['specialOrderBlock-' + this.selectedBackOrder.id]?.[0]
            if (backOrderSignatureBlock) {
              let width = backOrderSignatureBlock.offsetWidth;
              signatureCanvas.width = width
              signatureCanvas.style.width = width + 'px'
              signatureCanvas.height = 100
              signatureCanvas.style.height = '100px'
            }
          }
        })
      })
    },
    getSetInvoiceNumberContextTop(item) {
      let count = 0;
      if (this.canShowMarkPartReceivedButton && this.isConnectToIBS) {
        count++;
      }
      if (item.credit.creditPartStatus === 'UnderReview') {
        count++;
      }
      if (item.status === 'Received') {
        count++;
      }

      if (!(item.credit && item.credit.id)) {
        count++;
      }
      if (this.selectedRow && this.selectedRow[0] && this.selectedRow[0].isUndoPartLineChange) {
        count++;
      }
      if (this.isCanSetEditing) {
        count++;
      }
      if (item.status === 'Arrived') {
        count++;
      }
      if (this.isCanConfirmEditing){
        count++;
      }
      if (this.isCanEnableMarkArrivedSelected){
        count++;
      }


      return count * 42
    },
    getSetInvoiceNumberContextTopTwoItem() {
      let count = 0;
      if(this.isCanUndoChanges){
        count++
      }
      if (this.selectedRow.length > 1) {
        let countSelectedRow = 0
        this.selectedRow.forEach((element) => {
          if(element.status){
            countSelectedRow++
          }
        });
        if(this.selectedRow.length === countSelectedRow){
          count++
        }
      }
      if(this.isCanMarkNotReceived){
        count++
      }
      if(this.isCanEnableMarkReceivedSelected || !this.isCanEnableMarkReceivedSelected){
        count++
      }
      if(this.isCanReverse){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isConnectToIBS){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanMarkNotReceived && this.isConnectToIBS){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanMarkNotArrived){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanSetEditing){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanConfirmEditing){
        count++
      }

      return count * 42
    },
    getCreateCreditRequestContextTop(item) {
      let count = 0;
      if (this.canShowMarkPartReceivedButton && this.isConnectToIBS) {
        count++;
      }
      if (item.credit.creditPartStatus === 'UnderReview') {
        count++;
      }
      if (item.status === 'Received') {
        count++;
      }
      if (this.isCanSetEditing) {
        count++;
      }
      if (item.status === 'Arrived') {
        count++;
      }
      if (this.isCanConfirmEditing){
        count++;
      }
      if (this.isCanEnableMarkArrivedSelected){
        count++;
      }
      console.log(count)
      return count * 42
    },
    getCreateCreditRequestContextTopTwoItem() {
      let count = 0;
      if(this.isCanUndoChanges){
        count++
      }
      if(this.isCanMarkNotReceived){
        count++
      }
      if(this.isCanEnableMarkReceivedSelected || !this.isCanEnableMarkReceivedSelected){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isConnectToIBS){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanMarkNotReceived && this.isConnectToIBS){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanMarkNotArrived){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanSetEditing){
        count++
      }
      if(this.lengthSelectedRows > 1 && this.isCanConfirmEditing){
        count++
      }
      return count * 42
    },
    isHasShortComment (part) {
      return (part.stock === 4 || part.stock === 6) && _.trim(part?.orderStockComment )
    },
    getShortComment (part) {
      let r = ''
      let stockComment = _.trim(part?.orderStockComment )

      if (stockComment) {
        r += stockComment.substr(0, 15)
        if (stockComment.length > 15) {
          r += '...'
        }
      }
      return r
    },
    onPartsOrderDetailsBlur(v) {
      if (_.includes(this.focusedComments, v)) {
        this.focusedComments.splice(this.focusedComments.indexOf(v), 1)
      }
    },
    onPartsOrderDetailsFocus(v) {
      if (!_.includes(this.focusedComments, v)) {
        this.focusedComments.push(v)
      }
    },
    removedFiles(ids) {
      this.order.files = _.filter(this.order.files, (file) => {
        return !ids.includes(file.id)
      })
    },
    getProfit (item) {
      let result = '';
      if (item.rrp && item.price) {
        let profit = parseFloat(((item.rrp - item.price) * 100) / item.rrp).toFixed(2)
        if (profit !== 'Infinity' && (profit) > 0) {
          result += `${profit}%`;
        }
      }
      return  result
    },
    handleResize () {
      this.resizer++;
      this.onScroll()
      this.setFakeScroll()
      if (this.isOpenModal) {
        setTimeout(() => {
          let signatureCanvas = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0]?.$el
          if (signatureCanvas) {
            let backOrderSignatureBlock = this.$refs['specialOrderBlock-' + this.selectedBackOrder.id]?.[0]
            if (backOrderSignatureBlock) {
              let width = backOrderSignatureBlock.offsetWidth;
              signatureCanvas.width = width
              signatureCanvas.style.width = width + 'px'
              signatureCanvas.height = 100
              signatureCanvas.style.height = '100px'
            }
          }

          let signaturePad = this.$refs['signaturePad-' + this.selectedBackOrder.id]?.[0]
          if (signaturePad) {
            signaturePad.inputPointsHandler()
          }
        }, 100)
      }
      this.handleScroll();

      this.documentHeight =  window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight -
          document.getElementsByClassName('tabs-component')?.[0]?.clientHeight - 14
    },
    handleScroll() {
      this.onScroll()
      let height = window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight -
          document.getElementsByClassName('part-order-additional-block')?.[0]?.clientHeight -
          document.getElementsByClassName('wrapper-vin-date')?.[0]?.clientHeight -
          document.getElementsByClassName('mobile-version-margin')?.[0]?.clientHeight -
          document.getElementsByClassName('tabs-component')?.[0]?.clientHeight - 22
      if (height < 300) {
        height = 300
      }
      let table = document.getElementsByClassName('parts-order-margin-table')?.[0]
      if (table) {
        // table.style.height = height + 'px'
      }
    },
    notAvailableQty (qty) {
      let result = [];
      for (let i = 1; i <= qty; i++) {
        result.push(String(i))
      }
      return result
    },
    raiseCreditFromOptions (row, event, returnQty = null, jsonData) {
      if (this.isOrderDenied || this.isOrderCancelled) {
        return
      }
      let json = []
      if (jsonData) {
        json = jsonData
      }
      setTimeout(() => {
        if (this.$refs['dropdown-2']) {
          this.$refs['dropdown-2'].hide()
        }
        if (this.$refs['parts-order-view-options']) {
          this.$refs['parts-order-view-options'].hide()
        }
      }, 10)


      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach((el) => {
          if (!el?.credit?.id > 0) {
            let partId = null
            if (el.id) {
              partId = el.id
            } else if (el.row.id) {
              partId = el.row.id
            } else if (el.row.item.id) {
              partId = el.row.item.id
            }

            let jsonItem = _.find(json, (i) => {
              return Number(i.partId) === Number(partId)
            })
            if (jsonItem) {
              jsonItem.createCredit = {
                returnQty: null
              }
            } else {
              json.push({
                partId: partId,
                createCredit: {
                  returnQty: null
                }
              })
            }
          }
        })
      } else {
        let partId = null
        if (row.id) {
          partId = row.id
        } else if (row.row.id) {
          partId = row.row.id
        } else if (row.row.item.id) {
          partId = row.row.item.id
        }

        let jsonItem = _.find(json, (i) => {
          return Number(i.partId) === Number(partId)
        })
        if (jsonItem) {
          jsonItem.createCredit = {
            returnQty: returnQty
          }
        } else {
          json.push({
            partId: partId,
            createCredit: {
              returnQty: returnQty
            }
          })
        }
      }
      console.log(json, 'json')
      this.handleRequestCreditButton(json, event)
      // this.selectedRow = [];
      // this.$refs.orderTable.clearSelected()
    },
    initMenu () {
      this.$root.$on('bv::dropdown::show', bvEvent => {
        if (bvEvent.componentId === 'dropdown-2') {
          this.isDropdown2Visible = true
        }
      })
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        if (bvEvent.componentId === 'dropdown-2') {
          this.isDropdown2Visible = false
          console.log(this.$refs['parts-order-view-options'])
          if (!_.isEmpty(this.$refs['parts-order-view-options'])) this.$refs['parts-order-view-options'].hide()
        }
        if (this.isDropdown2Visible) {
          bvEvent.preventDefault()
        }
      })
    },
    focusOnComment (row) {
      this.$nextTick(() => {
        let el = this.$refs['parts-order-details-' + row.id]
        if (!el) {
          return
        }
        el.focusOnComment()
      })
    },
    goToRfq () {
      if (!(this.order && this.order.rfq && this.order.rfq.id)) {
        return
      }
      // window.open(window.location.origin + '/r/rfq/' + this.order.rfq.id, '_blank')
      this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: this.order.rfq.id } })
    },
    undoPartLineChange (isContext = false, isFromModal = false) {
      if (isFromModal) {
        let data = [{ partId: this.selectedRow[0].id, undoPartLineChange: true }]
        if (this.lengthSelectedRows > 1) {
          data = [];
          _.forEach(this.selectedRow, (r) => {
            if (r.isUndoPartLineChange && !r?.credit?.id) {
              data.push({
                partId: r.id,
                undoPartLineChange: true
              })
            }
          })
        }
        this.clickSave(data)
        this.$refs['open-modal-undo-part-line-change'].hide()
        return
      }
      if (isContext) {
        this.$refs.menuPartsOrder.close()
      }
      this.$refs['open-modal-undo-part-line-change'].show()
    },
    formatDateTime (val, parseFormat=false) {
      if (!val){
        return '';
      }
      let date = (parseFormat)?dayjs(val,parseFormat):dayjs(val)
      return date.format('DD/MM/YYYY || hh:mmA')
    },
    formatDate (val, parseFormat=false) {
      if (!val){
        return '';
      }
      let date = (parseFormat)?dayjs(val,parseFormat):dayjs(val)
      return date.format('DD/MM/YYYY')
    },
    minusReturnQty (item) {
      if (item.returnQty > 1) {
        item.returnQty--
        item.returnQty = String(item.returnQty)
      }
    },
    plusReturnQty (item) {
      if (item.returnQty < item.qty) {
        item.returnQty++
        item.returnQty = String(item.returnQty)
      }
    },
    onMouseOver () {
      this.isUnderCreditRequest = true
      if (this.$refs.qtyInput) {
        this.$refs.qtyInput.focus()
      }
    },
    getStockComment (part) {
      return '<div>' + part.orderStockComment  + '</div>'
    },
    getStockValue (part) {
      if (part && part.partStock) {
        if (part.partStock == 'InStock') {
          return 'In Stock'
        } else if (part.partStock == 'ExStock') {
          return 'EX Warehouse'
        } else if (part.partStock == 'LowStock') {
          return 'Low Stock'
        } else if (part.partStock == 'NoStock') {
          return 'No Stock'
        } else if (part.partStock == 'OnBackOrder') {
          return 'On Back Order'
        } else if (part.partStock == 'NoLongerAvailable') {
          return 'No Longer Available'
        }
      }
      return ''
    },
    disableDefaultRightClickOn (elSelector) {
      const noContext = document.querySelector(elSelector)
      noContext.addEventListener('contextmenu', e => {
        e.preventDefault()
      })
    },
    closeContextForReasonAction () {
      this.$refs.menuActionForReason.close()
    },
    openMenuForReasonAction (data) {
      this.isOpenPartContext = true
      let partContext = this.$refs.menuPartsOrder
      if (partContext && partContext.show) {
        partContext.close()
      }

      if (data.type === 'status'
          && !(data.part.comment !== '' && data.isNeedSave)
          && !(data.part.comment !== '' && data.part.comment !== null && !data.isNotNeedEdit)
          && !(data.part.comment !== '' && data.part.comment !== null)) {
        setTimeout(() => {this.isOpenPartContext = false}, 100)
        return
      } else if (data.type === 'credit'
          && !(data.part.credit.reason !== '' && data.isNeedSave)
          && !(data.part.credit.reason !== '' && data.part.credit.reason !== null && !data.isNotNeedEdit)
          && !(data.part.credit.reason !== '' && data.part.credit.reason !== null)) {
        setTimeout(() => {this.isOpenPartContext = false}, 100)
        return
      }

      this.isOpenPartContext = true

      console.log('menuActionForReason', data)
      this.$nextTick(() => {
        this.$refs.menuActionForReason.open(data.event, {
          part: data.part,
          type: data.type,
          isNotSavedChanges: data.isNotSavedChanges,
          isNeedSave: data.isNeedSave,
          isNotNeedEdit: data.isNotNeedEdit
        })
      })
    },
    saveReason (part, type) {
      let el = this.$refs['parts-order-details-' + part.id]
      if (el) {
        el.saveReason(type)
      }
    },
    editReason (part, type) {
      let el = this.$refs['parts-order-details-' + part.id]
      if (el) {
        el.editReason(type)
      }
    },
    deleteReason (part, type) {
      let el = this.$refs['parts-order-details-' + part.id]
      if (el) {
        el.deleteReason(type)
      }
    },
    autoSave (selectedRow) {
      return
      this.autoSaveMessage = 'Saving...'
      console.log(selectedRow)
      setTimeout(() => {
        this.autoSaveMessage = 'Saved'
      }, 5000)
    },
    autoUpdate () {
      return
      this.loadOrder()
    },
    openRowDetails () {
      _.forEach(this.openedRows, (row) => {
        let partIndex = _.findIndex(this.order.parts, (p) => {
          let id = row.item && row.item.id ? row.item.id : row.id
          return Number(p.id) === Number(id)
        })
        if (partIndex !== -1) {
          let status = this.order.parts[partIndex].status
          if (status === 'IncorrectPartOrdered' || status === 'IncorrectPartDelivered' || status === 'CancelPart' || status === 'ReturnDamagedPart' || status === 'DamagedPartExWarehouse' || status === 'NoLongerNeeded' || status === 'ChangeInRepairMethod') {
            this.$set(this.order.parts[partIndex], '_showDetails', true)
          }
        }
      })
    },
    clickOnIcon (status, row) {
      if (status === 'IncorrectPartOrdered' || status === 'IncorrectPartDelivered' || status === 'CancelPart' || status === 'ReturnDamagedPart' || status === 'DamagedPartExWarehouse' || status === 'NoLongerNeeded' || status === 'ChangeInRepairMethod') {
        let openedRow = _.find(this.openedRows, (r) => {
          let id = r.item && r.item.id ? r.item.id : r.id
          return Number(id) === Number(row.item.id)
        })

        if (row.detailsShowing) {
          if (openedRow) {
            let ind = this.openedRows.indexOf(openedRow)
            this.openedRows.splice(ind, 1)
          }
        } else if (!openedRow) {
          this.openedRows.push(row)
        }
        row.toggleDetails()
      }
    },
    viewPartStatus (status, stock) {
      if (status === 'Created' && this.order.status === 'Processing') {
        return 'Order Processed'
      } else if (status === 'Created') {
        return 'Created'
      } else if (status === 'Shipped' && Number(stock) === 4) {
        return 'On Back Order'
      } else if (status === 'Shipped') {
        return 'Shipped'
      } else if (status === 'Arrived') {
        if (this.order.state == 2) {
          return 'Order Completed'
        }
        return 'Arrived'
      } else if (status === 'Received') {
        return 'Received'
      } else if (status === 'DidNotArrive') {
        return 'Did Not Arrive'
      } else if (status === 'CancelPart') {
        if (this.isOrderDenied) {
          return 'Order Denied'
        }
        return 'Cancel Part'
      } else if (status === 'ReturnDamagedPart') {
        return 'Damaged Part in Transport'
      } else if (status === 'DamagedPartExWarehouse') {
        return 'Damaged Part EX Warehouse'
      } else if (status === 'NoLongerNeeded') {
        return 'No Longer Needed'
      } else if (status === 'ChangeInRepairMethod') {
        return 'Change in Repair Method'
      } else if (status === 'RequestCredit') {
        return 'Request Credit'
      } else if (status === 'IncorrectPartDelivered') {
        return 'Incorrect Part Delivered'
      } else if (status === 'IncorrectPartOrdered') {
        return 'Incorrect Part Ordered'
      } else if (status === 'IncorrectPartOrdered') {
        return 'Incorrect Part Ordered'
      } else if (status === 'PartialDelivered') {
        return 'Partial Delivery'
      } else {
        return ''
      }
    },
    viewCreditStatus (status) {
      return ''
      if (status === 'Approved') {
        //    return 'Credit Approved';
      } else if (status === 'UnderReview') {
        return ''
        //return 'Credit Under Review';
      } else if (status === 'Denied') {
        //    return 'Credit Denied';
      } else {
        return ''
      }
    },
    getCreditStatus(status) {
      if (status === 'Approved') {
        return 'Approved';
      } else if (status === 'UnderReview') {
        return 'Under Review';
      } else if (status === 'Denied') {
           return 'Denied';
      } else {
        return ''
      }
    },
    isCreditApprovedOrUnderReview(row) {
      return row?.item?.credit?.id && (row?.item?.credit?.status === 'Approved' || row?.item?.credit?.status === 'UnderReview')
    },
    onChangeInvoiceNbr(nbr) {
      NProgress.start()
      this.order.invoiceNumber = nbr;
      this.clickSave({
        invoiceNumber: nbr,
      })
      NProgress.done()
    },
    onChangeRepairerQuoteNumber(nbr) {
      NProgress.start()
      this.clickSave({
        QPNumber: nbr,
      })
      this.order.QPNumber = nbr;
      NProgress.done()
    },
    clickSave (data, isNeedSendToIBS) {
      let id = this.order.id
      Axios.post('/ir/repairer/order/' + id, data)
          .then(response => {
            if (response.data._status) {
              this.$toast.success('Order changes have been saved')
              let partIndex = -1
              if (data[0] && data[0].partId) {
                partIndex = _.findIndex(this.order.parts, (prt) => {
                  return Number(prt.id) === Number(data[0].partId)
                })
              }

              if (response.data.update && response.data.update.isPartStatusesChanged) {
                //    this.loadOrder(id)

              }
              if (response.data.status && _.isObject(response.data.status) && partIndex !== -1 && data?.length == 1) {
                this.$set(this.order.parts[partIndex], 'isUndoPartLineChange', response.data.status.isUndoPartLineChange)
                if (_.isEmpty(this.order.parts[partIndex].comment)) {
                  this.$set(this.order.parts[partIndex], 'comment', response.data.status.comment)
                }
                this.$set(this.order.parts[partIndex], 'commentByName', response.data.status.commentByName)
                this.$set(this.order.parts[partIndex], 'commentDate', response.data.status.commentDate)
                this.$set(this.order.parts[partIndex], 'status', response.data.status.status)
                if (response.data.status.hasOwnProperty('receivedDaysAgo')) {
                  this.$set(this.order.parts[partIndex], 'receivedDaysAgo', response.data.status.receivedDaysAgo)
                }

              }

              if (_.isArray(response.data.status) && !_.isEmpty(response.data.status)) {
                _.forEach(response.data.status, (p) => {
                  let ind = _.findIndex(this.order.parts, (ordP) => {
                    return Number(ordP.id) == Number(p.partId)
                  })
                  if (ind !== -1) {
                    this.$set(this.order.parts[ind], 'isUndoPartLineChange', p.isUndoPartLineChange)
                    if (_.isEmpty(this.order.parts[ind].comment)) {
                      this.$set(this.order.parts[ind], 'comment', p.comment)
                    }
                    this.$set(this.order.parts[ind], 'commentByName', p.commentByName)
                    this.$set(this.order.parts[ind], 'commentDate', p.commentDate)
                    this.$set(this.order.parts[ind], 'status', p.status)
                    if (p.hasOwnProperty('receivedDaysAgo')) {
                      this.$set(this.order.parts[ind], 'receivedDaysAgo', p.receivedDaysAgo)
                    }
                  }
                })
              }

              if (response.data.undo && _.isObject(response.data.undo)) {
                _.forEach(data, (p) => {
                  let index = _.findIndex(this.order.parts, (prt) => {
                    return Number(prt.id) === Number(p.partId)
                  })

                  if (index !== -1) {
                    this.$set(this.order.parts[index], 'isUndoPartLineChange', response.data.undo.isUndoPartLineChange)
                    this.$set(this.order.parts[index], 'comment', response.data.undo.comment)
                    this.$set(this.order.parts[index], 'commentByName', response.data.undo.commentByName)
                    this.$set(this.order.parts[index], 'commentDate', response.data.undo.commentDate)
                    this.$set(this.order.parts[index], 'status', response.data.undo.status)
                    let status = response.data.undo.status
                    if (status) {
                      let self = this
                      let ind = _.findIndex(this.openedRows, (r) => {
                        let id = r.item && r.item.id ? r.item.id : r.id
                        return Number(id) === Number(self.order.parts[index].id)
                      })
                      if (ind !== -1) {
                        this.openedRows.splice(ind, 1)
                      }
                      this.$set(this.order.parts[index], '_showDetails', false)
                    }
                  }
                })
              }

              if (_.isArray(response.data.undo) && !_.isEmpty(response.data.undo)) {
                _.forEach(response.data.undo, (p) => {
                  let ind = _.findIndex(this.order.parts, (ordP) => {
                    return Number(ordP.id) == Number(p.partId)
                  })
                  if (ind !== -1) {
                    this.$set(this.order.parts[ind], 'isUndoPartLineChange', p.isUndoPartLineChange)
                    this.$set(this.order.parts[ind], 'comment', p.comment)
                    this.$set(this.order.parts[ind], 'commentByName', p.commentByName)
                    this.$set(this.order.parts[ind], 'commentDate', p.commentDate)
                    let status = p.status
                    if (status) {
                      let self = this
                      let ind1 = _.findIndex(this.openedRows, (r) => {
                        let id = r.item && r.item.id ? r.item.id : r.id
                        return Number(id) === Number(self.order.parts[ind].id)
                      })
                      if (ind1 !== -1) {
                        this.openedRows.splice(ind1, 1)
                      }
                      this.$set(this.order.parts[ind], '_showDetails', false)
                    }
                    this.$set(this.order.parts[ind], 'status', p.status)
                  }
                })
              }

              if (response.data.comments) {
                _.forEach(response.data.comments, (p) => {
                  let originalPart = _.find(this.order.parts, (prt) => {
                    return Number(prt.id) === Number(p.id)
                  })
                  if (originalPart) {
                    if (!_.isEqual(p.isChecked, originalPart.isChecked)) {
                      p.isChecked = originalPart.isChecked;
                    }
                  }
                })
                let parts = response.data.comments
                for (let partId in parts) {
                  let orderPart = _.find(this.order.parts, (prt) => {
                    return Number(prt.id) === Number(partId)
                  })

                  if (orderPart) {
                    let newPart = parts[partId]
                    orderPart.commentByName = newPart.commentByName
                    orderPart.commentDate = newPart.commentDate
                  }
                }
              }

              if (response.data.credit && partIndex !== -1 && this.order.parts[partIndex].credit) {
                this.$set(this.order.parts[partIndex].credit, 'creditByName', response.data.credit.creditByName)
                this.$set(this.order.parts[partIndex].credit, 'creditDate', response.data.credit.creditDate)
              }
              if (response.data.files) {
                this.order.files = response.data.order.files
              }

              if (data[0] && data[0].partId && data.length == 1) {
                setTimeout(() => {
                  this.openRowDetails()
                  if (this.selectedRow.length) {
                    let self = this
                    let index = _.findIndex(this.order.parts, (prt) => {
                      return Number(prt.id) === Number(self.selectedRow[0].id)
                    })
                    if (index !== -1) {
                      this.$nextTick(() => {
                        this.$refs.orderTable.selectRow(index)
                      })
                    }
                  }
                  if (data.length === 1) {
                    this.$nextTick(() => {
                      let el = this.$refs['parts-order-details-' + data[0].partId]
                      if (!el) {
                        return
                      }
                      el.focusOnComment()
                    })
                  }
                }, 100)
              }

              if (isNeedSendToIBS) {
                if (this.order?.properties?.ordersReceivedAutosendToQps) {
                  window.postMessage({
                    type: 'partsbridge-IBODYSHOP_CREATE_ORDER',
                    data: {
                      rfqId: this.order.rfq.id,
                      qpsSystem: this.order?.rfq?.extQps
                    }
                  })
                }
              }
            } else {
              this.$toast.error('Order changes have not been saved')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Order changes have not been saved')
          })
    },
    async saveChangedPartCredit (orderId) {
      return
      let partIds = Object.keys(this.changedPartCredit)

      if (!partIds.length > 0) return Promise.resolve()

      return Promise.all(partIds.map(partId => {
        if (!this.changedPartCredit[partId].creditId) {
          return Axios.post('/ir/repairer/credit/add', { orderId: orderId, orderPartId: partId })
        } else {
          return Promise.resolve({ data: this.changedPartCredit[partId] })
        }
      }))
          // 1. get creditId and update each changedPartCredit
          .then(results => {
            results.forEach((response, index) => {
              this.changedPartCredit[partIds[index]].creditId = response.data.creditId
            })
            return Promise.resolve()
          })
          .catch(error => {
            console.log(error)
            Promise.reject()
          })
          // 2. save the reasons
          .then(() => {
            let parts = []
            partIds.forEach(partId => {
              parts.push(Axios.post('/ir/repairer/credit/update', this.changedPartCredit[partId]))
            })
            return Promise.all(parts)
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Request Credit have not been saved')
          })
    },
    markOrderCompleted () {
      this.$refs.menuPartsOrder.close()
      let id = this.order.id
      Axios.post('/ir/repairer/order/' + id + '/completed')
          .then(response => {
            if (response.data.status) {
              this.order.status = 'Completed'
              this.$toast.success('The Order has been completed')
              // this.$store.dispatch('repairer/order/init', {}, { root: true })
              // this.$router.push('/r/orders')
            } else {
              this.$toast.error('The Order hasn`t been completed')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('The Order hasn`t been completed')
          })
    },
    markOrderCurrent () {
      let id = this.order.id
      Axios.post('/ir/repairer/order/' + id + '/mark-as-current')
          .then(response => {
            if (response.data.status) {
              this.$toast.success('The Order has been marked as current')
              // this.$store.dispatch('repairer/order/init', {}, { root: true })
              // this.$router.push('/r/orders')
            } else {
              this.$toast.error('The Order hasn`t been marked as current')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('The Order hasn`t been marked as current')
          })
    },
    markOrderBack () {
      let id = this.order.id
      Axios.post('/ir/repairer/order/' + id + '/mark-as-backorder')
          .then(response => {
            if (response.data.status) {
              this.$toast.success('The Order has been marked as Bask Order')
              // this.$store.dispatch('repairer/order/init', {}, { root: true })
              // this.$router.push('/r/orders')
            } else {
              this.$toast.error('The Order hasn`t been marked as Bask Order')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('The Order hasn`t been marked as Bask Order')
          })
    },
    getNameClass: function (item) {
      let status = item.status
      let elem = _.find(this.statusList, { id: status })
      if (elem) {
        return elem.nameClass
      }
      return ''
    },
    getIconClass: function (item) {
      let status = item.status
      let elem = _.find(this.statusList, { id: status })
      if (elem) {
        return elem.iconClass
      }
      return ''
    },
    getIconTooltipForCredit: function (item) {
      let status = item.credit.creditPartStatus === 'UnderReview' ? 'Under Review' : item.credit.creditPartStatus
      let text = 'Credit Request: ' + status
      if (text == '') {
        return text
      }
      text = '<p>' + text + '</p>'

      if (item.credit.creditDate) {
        text += '<p style="white-space: nowrap !important;">Changed on: ' + dayjs(item.credit.creditDate).format('DD/MM/YYYY - hh:mmA') + '</p>'
      }
      if (item.credit.creditByName) {
        text += '<p>Changed by: ' + item.credit.creditByName + '</p>'
      }
      return text
    },
    getIconTooltip: function (item) {
      let text = this.viewPartStatus(item.status)
      if (text == '') {
        return text
      }
      text = '<p>' + text + '</p>'
      if (item.lastChangeStatusDate) {
        text += '<p style="white-space: nowrap !important;">Changed on: ' + dayjs(item.lastChangeStatusDate).format('DD/MM/YYYY - hh:mmA') + '</p>'
      }
      if (item.lastChangeStatusBy) {
        text += '<p>Changed by: ' + item.lastChangeStatusBy + '</p>'
      }
      return text
    },
    loadOrder: function (id, callback = false,) {
      Axios.get('/ir/repairer/order/' + id,)
          .then(response => {
            if (callback) {
              callback(response.data)
              return
            }
            this.order = new Order(response.data.order)
            // this.$store.dispatch('repairer/order/init', {}, { root: true })
            //this.rfq.parts_to_suppliers = new SupplierPrice(this.rfq.parts_to_suppliers);

            // load comment data
            this.originalParts = _.cloneDeep(response.data.order.parts)
            let partIds = []
            let partCredits = []
            this.order.parts.forEach(part => {
              if (part && part.qty > 1) {
                this.$set(part, 'returnQty', part.qty)
              }
              this.$set(part, 'isChecked', false)
              this.$set(part, 'qtyCopy', part.qty)
            })

            if(response.data.chatMessages){
              this.chatMessages = response.data.chatMessages
              if(this.unviewedMessagesIds.length > 0){
                this.markMessagesAsViewed(this.unviewedMessagesIds);
              }
            }

            setTimeout(() => {
              let el = document.getElementsByClassName("repairer-order-part-list")?.[0];

              if (el) {
                el.addEventListener('scroll', this.onTableScroll)
              }
              this.setFakeScroll()
            }, 0)

          this.$store.commit('repairer/order/repairerSetIsNeedUpdate', false)

            if(response.data.backOrders){
              this.backOrders = response.data.backOrders

              _.forEach(this.backOrders, (o, index) => {
                if (o.status == 'sent' && !_.includes(this.showedModalsForOrders, (o.id))) {
                  this.showedModalsForOrders.push(o.id)
                  this.openedModalsForOrders.push(o.id)
                  setTimeout(() => {
                    this.showModal(null, o.id)
                  }, index * 10)
                }
              })
            }
        })
        .catch(error => {
          this.$toast.error('Page not found')
          this.$router.push('/r')
          console.log(error)
        })
        .finally(() => {
          this.isLoadedPartOrder = true
          this.handleResize()
          setTimeout(() => {
            this.sendExtQpsRfqData(true);
          }, 500)
        })
    },
    handleRightClick (row, index, e) {
      if (this.isOpenPartContext) {
        return
      }
      e.preventDefault()
      let part = row
      if (row.item && row.item.id) {
        part = row.item
      }
      this.$refs.menuPartsOrder.open(e, { row: part, index: index })
      // when right click selected, only a single row can be selected at one time
      // this.$refs.orderTable.clearSelected()
      // this.$refs.orderTable.selectRow(index)
    },
    handleRightClick2 (row, index, e) {
      this.$nextTick(() => {
        if (!row.isChecked) {
          this.$set(this.order.parts[index], 'isChecked', true)
          this.callComputed++

          let isSelectedPart = _.find(this.selectedRow, (r) => {
            return Number(r.id) == Number(row.id)
          })
          if (!isSelectedPart) {
            this.selectedRow.push(row)
          }
        }
      })
      if (this.isOpenPartContext) {
        return
      }
      e.preventDefault()
      if (!(this.lengthSelectedRows > 1)) {
        this.handleRightClick(row, index, e)
      } else {
        this.$refs.menuPartsOrder.open(e, {})
      }
    },
    handleRowSelected (rows) {
      if (this.isSelectedManualy) {
        this.isSelectedManualy = false;
        return
      }
      this.selectedRow = rows
      _.forEach(rows, row => {
        let index = _.findIndex(this.order.parts, (prt) => {
          return Number(prt.id) == Number(row.id)
        })
        if (index !== -1) {
          this.$nextTick(() => {
            if (this.order.parts[index].isChecked && (!this.isHoldShift && !this.isHoldCtrl)) {
              this.$set(this.order.parts[index], 'isChecked', false)
            } else {
              this.$set(this.order.parts[index], 'isChecked', true)
            }
          })
        }
      })
      this.$refs.orderTable.clearSelected()

      this.callComputed++

      setTimeout(() => {
        _.forEach(this.order.parts, (part, index) => {
          if (part.isChecked) {
            let isSelectedPart = _.find(this.selectedRow, (r) => {
              return Number(r.id) == Number(part.id)
            })
            if (!isSelectedPart) {
              this.selectedRow.push(part)
            }
          }
        })
      }, 10)
    },
    handleRowClicked (row, index, event) {
      setTimeout(() => {
        let checkedRows = _.filter(this.order.parts, (prt) => {
          return prt.isChecked
        })
        if (checkedRows.length == 1) {
          let ind = _.findIndex(this.order.parts, (prt) => {
            return Number(prt.id) == Number(checkedRows[0].id)
          })
          if (ind !== 1) {
            if (!this.$refs.orderTable.selectedRows[ind]) {
              this.isSelectedManualy = true
              this.$refs.orderTable.selectRow(ind)
              this.$set(this.order.parts[ind], 'isChecked', true)
            } else {
              this.$refs.orderTable.unselectRow(ind)
              this.$set(this.order.parts[ind], 'isChecked', false)
            }
          }
        }
      }, 100)

      const orderTable = this.$refs.orderTable
      const rowSelected = () => orderTable.isRowSelected(index)
      const hasDetail = this.partCanRequestCredit(this.order.parts[index])

      const el = event.srcElement
      if (el && (el.cellIndex === 2 || el.parentElement.cellIndex === 2 || el.parentElement.parentElement.cellIndex === 2)) {
        //console.log('icon cell detected')
        this.$nextTick(() => {
          orderTable.unselectRow(index)
        })
      }
      if (!hasDetail) {
        return
      }

      if (rowSelected() && this.detailMode || !rowSelected() && !this.detailMode) {
        // this.togglePartDetails(index);
        // this.detailMode = false;
      } else if (!this.detailMode) {
        this.detailMode = true
      }
    },
    saveReturnQty (part) {
      if (part.returnQty > 0) {
        this.clickSave([{
          partId: part.id,
          returnQty: part.returnQty
        }])
      } else {
        this.clickSave([{ returnQty: part.qty }])
      }
      let prt = _.find(this.order.parts, (p) => {
        return Number(p.id) === Number(part.id)
      })
      if (prt) {
        prt.credit.subStatus = 'Return'
      }
      this.isUnderCreditRequest = false
      this.$refs.menuPartsOrder.close()
    },
    handleCancelReturnButton (part) {
      this.clickSave([{ creditId: part.credit.id, partId: part.id, subStatus: 'Cancellation' }])
      let prt = _.find(this.order.parts, (p) => {
        return Number(p.id) === Number(part.id)
      })
      if (prt) {
        prt.credit.subStatus = 'Cancellation'
      }
      this.isUnderCreditRequest = false
      this.$refs.menuPartsOrder.close()
    },
    saveCancelPartButton () {
      this.handleCancelPartButton()
    },
    handleCancelPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered') {
          _save.push({ partId: el.id, status: 'CancelPart' })
          el.status = 'CancelPart'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleCancelPartButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el.status != 'CancelPart') {
            _save.push({ partId: el.id, status: 'CancelPart' })
            el.status = 'CancelPart'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row.status = 'CancelPart'
        data.row._showDetails = true
        this.openedRows.push(data.row)
        _save.push({ partId: data.row.id, status: 'CancelPart' })
        this.focusOnComment(data.row)
      }
      this.clickSave(_save)
      this.$refs.menuPartsOrder.close()
    },
    saveReturnPartButton () {
      this.handleReturnDamagedPartButton()
    },
    handleChangeInRepairMethodPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'ChangeInRepairMethod') {
          _save.push({ partId: el.id, status: 'ChangeInRepairMethod' })
          el.status = 'ChangeInRepairMethod'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleNoLongerNeededPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'NoLongerNeeded') {
          _save.push({ partId: el.id, status: 'NoLongerNeeded' })
          el.status = 'NoLongerNeeded'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleDamagedPartExWarehousedPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered' && el.status != 'DamagedPartExWarehouse') {
          _save.push({ partId: el.id, status: 'DamagedPartExWarehouse' })
          el.status = 'DamagedPartExWarehouse'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleReturnDamagedPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered') {
          _save.push({ partId: el.id, status: 'ReturnDamagedPart' })
          el.status = 'ReturnDamagedPart'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleReturnDamagedPartButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach((el) => {
          if (el.status != 'ReturnDamagedPart') {
            _save.push({ partId: el.id, status: 'ReturnDamagedPart' })
            el.status = 'ReturnDamagedPart'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row.status = 'ReturnDamagedPart'
        data.row._showDetails = true
        this.openedRows.push(data.row)
        _save.push({ partId: data.row.id, status: 'ReturnDamagedPart' })
        this.focusOnComment(data.row)
      }
      this.$refs.menuPartsOrder.close()
      return _save
    },
    handleChangeInRepairMethodButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach((el) => {
          if (el.status != 'ChangeInRepairMethod') {
            _save.push({ partId: el.id, status: 'ChangeInRepairMethod' })
            el.status = 'ChangeInRepairMethod'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row.status = 'ChangeInRepairMethod'
        data.row._showDetails = true
        this.openedRows.push(data.row)
        _save.push({ partId: data.row.id, status: 'ChangeInRepairMethod' })
        this.focusOnComment(data.row)
      }
      this.$refs.menuPartsOrder.close()
      return _save
    },
    handleNoLongerNeededButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach((el) => {
          if (el.status != 'NoLongerNeeded') {
            _save.push({ partId: el.id, status: 'NoLongerNeeded' })
            el.status = 'NoLongerNeeded'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row.status = 'NoLongerNeeded'
        data.row._showDetails = true
        this.openedRows.push(data.row)
        _save.push({ partId: data.row.id, status: 'NoLongerNeeded' })
        this.focusOnComment(data.row)
      }
      this.$refs.menuPartsOrder.close()
      return _save
    },

    saveInvoiceNumber (part) {
      this.$refs.menuPartsOrder.close()
      this.clickSave([{
        partId: part.id,
        invoiceNumber: part.invoiceNumber
      }])
    },
    saveInvoiceDate (part) {
      this.$refs.menuPartsOrder.close()
      this.clickSave([{
        partId: part.id,
        invoiceDate: part.invoiceDate
      }])
    },
    saveContextInvoiceNumber () {
      this.$refs.menuPartsOrder.close()
      let data = []

      _.forEach(this.selectedRow, (itm) => {
        let index = _.findIndex(this.orderItems, (part) => {
          return Number(itm.id) === Number(part.id)
        });
        if (index !== -1) {
          this.$set(this.order.parts[index], 'invoiceNumber', this.contextInvoiceNumber)
        }
        data.push({
          partId: itm.id,
          invoiceNumber: this.contextInvoiceNumber
        })
      })

      this.clickSave(data)

      this.contextInvoiceNumber = ''
    },
    saveContextInvoiceDate () {
      this.$refs.menuPartsOrder.close()
      let data = []

      _.forEach(this.selectedRow, (itm) => {
        let index = _.findIndex(this.orderItems, (part) => {
          return Number(itm.id) === Number(part.id)
        });
        if (index !== -1) {
          this.$set(this.order.parts[index], 'invoiceDate', this.contextInvoiceDate)
        }
        data.push({
          partId: itm.id,
          invoiceDate: this.contextInvoiceDate
        })
      })

      this.clickSave(data)

      this.contextInvoiceDate = ''
    },
    handleDamagedPartExWarehouseButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach((el) => {
          if (el.status != 'DamagedPartExWarehouse') {
            _save.push({ partId: el.id, status: 'DamagedPartExWarehouse' })
            el.status = 'DamagedPartExWarehouse'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row.status = 'DamagedPartExWarehouse'
        data.row._showDetails = true
        this.openedRows.push(data.row)
        _save.push({ partId: data.row.id, status: 'DamagedPartExWarehouse' })
        this.focusOnComment(data.row)
      }
      this.$refs.menuPartsOrder.close()
      return _save
    },
    getStatus(status) {
      if (status === 'ReturnDamagedPart') {
        return 'Damaged Parts In Transport'
      } else if (status === 'DamagedPartExWarehouse') {
        return 'Damaged Aprts Ex Warehouse'
      } else if (status === 'NoLongerNeeded') {
        return 'No Longer Needed'
      } else if (status === 'ChangeInRepairMethod') {
        return 'Change In Repair Method'
      } else if (status === 'IncorrectPartDelivered') {
        return 'Incorrect Parts Delivered'
      } else if (status === 'IncorrectPartOrdered') {
        return 'Incorrect Parts Ordered'
      } else {
        return status
      }

    },
    handleRequestCreditButton (json, event) {
      if (this.order?.creditProcessingPolicies?.requirePhotos) {
        if (!_.isEmpty(this.order?.creditProcessingPolicies?.reasonForReturnToDisplayPhoto)) {
          let isShowModal = _.some(json, (part) => {
            return _.includes(this.order.creditProcessingPolicies.reasonForReturnToDisplayPhoto, this.getStatus(part.status))
          })
          if (isShowModal) {
            this.$refs.customModal.show();
          }
        }
      }


      _.forEach(json, (part) => {
        let partIndex = _.findIndex(this.order.parts, prt => {
          return Number(prt.id) === Number(part.orderPartId)
        })
        if (partIndex !== -1) {
          let prt = this.order.parts[partIndex]
          let isNeedShowModal = prt.hasOwnProperty('receivedDaysAgo') && this?.order?.creditProcessingPolicies?.returnPeriod && Number(this?.order?.creditProcessingPolicies?.returnPeriod) - Number(prt.receivedDaysAgo) <= 1;
          if (isNeedShowModal) {
            this.$refs.creditPeriod.show();
          }
        }
      })

      Axios.post('/ir/repairer/order/' + this.order.id, json)
          .then(response => {
            if (response.data._status) {
              if (response.data.creditsCreated) {
                _.forEach(json, (part) => {
                  let partIndex = _.findIndex(this.order.parts, prt => {
                    return Number(prt.id) === Number(part.partId)
                  })
                  this.selectedCreditId = response.data.creditsCreated.id
                  if (partIndex !== -1) {

                    if (Number(response.data.creditsCreated.orderPartId) === Number(part.partId) || _.includes(response.data.creditsCreated.orderPartIds, part.partId)) {
                      this.order.parts[partIndex].credit = response.data.creditsCreated
                      this.order.parts[partIndex].credit.reason = ''
                      this.order.parts[partIndex].credit.reasonDenied = ''
                      // if (_.isString(part.returnQty) || _.isNumber(part.returnQty)) {
                      //   this.order.parts[partIndex].qtyRcv = this.order.parts[partIndex].qty - part.returnQty;
                      // } else {
                      //   this.order.parts[partIndex].qtyRcv = this.order.parts[partIndex].qtyRcv - this.order.parts[partIndex].qty
                      // }
                    }
                  }
                })
              }
            } else {
              // this.$toast.error('Request Credit have not been saved')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Request Credit have not been saved')
          })
      this.$refs.menuPartsOrder.close()
    },

    partCanRequestCredit (part) {
      return part.status === 'CancelPart'
          || part.status === 'ReturnDamagedPart'
          || part.status === 'IncorrectPartOrdered'
          || part.status === 'IncorrectPartDelivered'
          || part.status === 'DamagedPartExWarehouse'
          || part.status === 'NoLongerNeeded'
          || part.status === 'ChangeInRepairMethod'
    },
    showRequestCredit (part) {
      return this.partCanRequestCredit(part) && part.credit && part.credit.id
    },
    saveIncorrectPartButton () {
      this.handleIncorrectPartButton()
    },
    handleIncorrectPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered') {
          _save.push({ partId: el.id, status: 'IncorrectPartDelivered' })
          el.status = 'IncorrectPartDelivered'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleIncorrectOrderedPartAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el.status != 'CancelPart' && el.status != 'ReturnDamagedPart' && el.status != 'IncorrectPartDelivered' && el.status != 'IncorrectPartOrdered') {
          _save.push({ partId: el.id, status: 'IncorrectPartOrdered' })
          el.status = 'IncorrectPartOrdered'
          el._showDetails = true
          self.openedRows.push(el)
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save)
      }
    },
    handleIncorrectPartOrderedButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el.status != 'IncorrectPartOrdered') {
            _save.push({ partId: el.id, status: 'IncorrectPartOrdered' })
            el.status = 'IncorrectPartOrdered'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row._showDetails = true
        this.openedRows.push(data.row)
        data.row.status = 'IncorrectPartOrdered'
        _save.push({ partId: data.row.id, status: 'IncorrectPartOrdered' })
        this.focusOnComment(data.row)
      }
      this.$refs.menuPartsOrder.close()
      return _save
    },
    handleIncorrectPartButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el.status != 'IncorrectPartDelivered') {
            _save.push({ partId: el.id, status: 'IncorrectPartDelivered' })
            el.status = 'IncorrectPartDelivered'
            el._showDetails = true
            self.openedRows.push(el)
            self.focusOnComment(el)
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        data.row._showDetails = true
        this.openedRows.push(data.row)
        data.row.status = 'IncorrectPartDelivered'
        _save.push({ partId: data.row.id, status: 'IncorrectPartDelivered' })
        this.focusOnComment(data.row)
      }
      this.$refs.menuPartsOrder.close()
      return _save
    },
    savePartReceivedButton () {
      this.handlePartReceivedButton()
    },
    handlePartReceivedAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el._showDetails) {
          el._showDetails = false
          let ind = _.findIndex(self.openedRows, (r) => {
            let id = r.item && r.item.id ? r.item.id : r.id
            return Number(id) === Number(el.id)
          })
          if (ind !== -1) {
            self.openedRows.splice(ind, 1)
          }
        }
        if (el.status === 'Arrived' && !(el.credit && el.credit.id > 0)) {
          _save.push({ partId: el.id, status: 'Received' })
          el.status = 'Received'
          el.comment = null
          el.commentByName = null
          el.commentDate = null
          el.qtyRcv = el.qty
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save, )
      }
    },
    handlePartArrivedAll () {
      let _save = []
      let self = this
      _.forEach(this.order.parts, function (el) {
        if (el._showDetails) {
          el._showDetails = false
          let ind = _.findIndex(self.openedRows, (r) => {
            let id = r.item && r.item.id ? r.item.id : r.id
            return Number(id) === Number(el.id)
          })
          if (ind !== -1) {
            self.openedRows.splice(ind, 1)
          }
        }
        if (el.status != 'Arrived' && !(el.credit && el.credit.id > 0)) {
          _save.push({ partId: el.id, status: 'Arrived' })
          el.status = 'Arrived'
          el.comment = null
          el.commentByName = null
          el.commentDate = null
          el.qtyRcv = el.qty
        }
      })
      if (_save.length > 0) {
        this.clickSave(_save, true)
      }
    },
    handlePartReceivedButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el._showDetails) {
            el._showDetails = false
            let ind = _.findIndex(self.openedRows, (r) => {
              let id = r.item && r.item.id ? r.item.id : r.id
              return Number(id) === Number(el.id)
            })
            if (ind !== -1) {
              self.openedRows.splice(ind, 1)
            }
          }
          if ((el.status != 'Received') && !(el.credit && el.credit.id > 0)) {
            _save.push({ partId: el.id, status: 'Received' })
            el.status = 'Received'
            el.comment = null
            el.commentByName = null
            el.commentDate = null
            el.qtyRcv = el.qty
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        console.log('data not selected handlePartReceivedButton', data, data.row.detailsShowing)
        if (data.row._showDetails) {
          data.row._showDetails = false
          let ind = _.findIndex(this.openedRows, (r) => {
            let id = r.item && r.item.id ? r.item.id : r.id
            return Number(id) === Number(data.row.id)
          })
          if (ind !== -1) {
            this.openedRows.splice(ind, 1)
          }
        }
        if (data.row.status === 'Arrived') {
          data.row.status = 'Received'
          data.row.comment = null
          data.row.commentByName = null
          data.row.commentDate = null
          data.row.qtyRcv = data.row.qty
          _save.push({ partId: data.row.id, status: 'Received' })
        }
      }
      if (_save.length > 0) {
        this.clickSave(_save, )
      }
      this.$refs.menuPartsOrder.close()
    },
    handlePartArrivedButton (data, event, isFromOptions = false) {
      let _save = []
      if (this.selectedRow.length > 1 || (isFromOptions && this.selectedRow.length === 1)) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el._showDetails) {
            el._showDetails = false
            let ind = _.findIndex(self.openedRows, (r) => {
              let id = r.item && r.item.id ? r.item.id : r.id
              return Number(id) === Number(el.id)
            })
            if (ind !== -1) {
              self.openedRows.splice(ind, 1)
            }
          }
          if ((el.status != 'Arrived') && !(el.credit && el.credit.id > 0)) {
            _save.push({ partId: el.id, status: 'Arrived' })
            el.status = 'Arrived'
            el.comment = null
            el.commentByName = null
            el.commentDate = null
            el.qtyRcv = el.qty
          }
        })
        // this.selectedRow = []
        // this.$refs.orderTable.clearSelected()
      } else {
        console.log('data not selected handlePartReceivedButton', data, data.row.detailsShowing)
        if (data.row._showDetails) {
          data.row._showDetails = false
          let ind = _.findIndex(this.openedRows, (r) => {
            let id = r.item && r.item.id ? r.item.id : r.id
            return Number(id) === Number(data.row.id)
          })
          if (ind !== -1) {
            this.openedRows.splice(ind, 1)
          }
        }
        data.row.status = 'Arrived'
        data.row.comment = null
        data.row.commentByName = null
        data.row.commentDate = null
        data.row.qtyRcv = data.row.qty
        _save.push({ partId: data.row.id, status: 'Arrived' })
      }
      if (_save.length > 0) {
        this.clickSave(_save, true)
      }
      this.$refs.menuPartsOrder.close()
    },
    handlePartNotArrivedButton (data, event) {
      let _save = []
      if (this.lengthSelectedRows > 1) {
        _.forEach(this.selectedRow, (p) => {
          if (p.status === 'Arrived') {
            let partIndex = _.findIndex(this.order.parts, (prt) => {
              return Number(prt.id) === Number(p.id)
            })
            if (partIndex !== -1) {
              // this.$set(this.order.parts[partIndex], 'status', 'New')
              this.$set(this.order.parts[partIndex], 'qtyRcv', 0)
            }
            _save.push({ partId: p.id, undoPartLineChange: true})
          }
        })
      } else {
        // data.row.status = 'New'
        data.row.qtyRcv = 0
        _save.push({ partId: data.row.id,  undoPartLineChange: true })
      }
      this.clickSave(_save)
      this.$refs.menuPartsOrder.close()
    },
    handlePartNotReceivedButton (data, event) {
      let _save = []
      if (this.lengthSelectedRows > 1) {
        _.forEach(this.selectedRow, (p) => {
          if (p.status === 'Received') {
            let partIndex = _.findIndex(this.order.parts, (prt) => {
              return Number(prt.id) === Number(p.id)
            })
            if (partIndex !== -1) {
              // this.$set(this.order.parts[partIndex], 'status', 'New')
              this.$set(this.order.parts[partIndex], 'qtyRcv', 0)
            }
            _save.push({ partId: p.id, undoPartLineChange: true})
          }
        })
      } else {
        // data.row.status = 'New'
        data.row.qtyRcv = 0
        _save.push({ partId: data.row.id,  undoPartLineChange: true })
      }
      this.clickSave(_save)
      this.$refs.menuPartsOrder.close()
    },
    showRequestCreditMenu (part) {
      if (!part) return false
      return this.partCanRequestCredit(part)
    },
  },
  mounted () {
    this.disableDefaultRightClickOn('.parts-order-view-page .table')
    this.$store.commit('repairer/order/repairerSetCurrentOrderId', this.$route.params.orderId)
    this.initMenu()
    this.resizer++;
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this.checkCtrlAndShift)
    window.addEventListener('keyup', this.removeCtrlAndShift)

    let table = document.getElementsByClassName('parts-order-margin-table')[0]
    table.addEventListener('scroll', this.onTableScroll)

    this.handleResize()
    this.supplierLeavedTheChat = _.debounce(function () {
      this.chatSupplierIsOnline = false;
    }, 5000)
     // this.$refs['open-modal-undo-part-line-change'].show() //this came from #444 and removed by #491

    this.debounceOnKeyPressUp = _.debounce(function (v) {
      this.onKeyPressUp(v);
    }, 10);

    this.debounceOnKeyPressDown = _.debounce(function (v) {
      this.onKeyPressDown(v);
    }, 10);

    this.debounceOnKeyPressLeft = _.debounce(function (v, e) {
      this.onKeyPressLeft(v, e);
    }, 10);

    this.debounceOnKeyPressRight = _.debounce(function (v, e) {
      this.onKeyPressRight(v, e);
    }, 10);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('keydown', this.checkCtrlAndShift)
    window.removeEventListener('keyup', this.removeCtrlAndShift)
    if(this._chat){
      console.log('UNSUBSCRIBING!',this._chat);
      this._chat.unsubscribe();
      console.log('UNSUBSCRIBED!');
    }
    clearInterval(this.interval);
    this.$eventBus.$off('message-GET_CONNECTED_ESTIMATE_DATA', this.sendExtQpsRfqData)
  },
  unmounted () {
    this.$store.commit('repairer/order/repairerSetCurrentOrderId', null)
  },
  created: function () {
    this.$eventBus.$on('message-GET_CONNECTED_ESTIMATE_DATA', this.sendExtQpsRfqData);
    this.loadOrder(this.$route.params.orderId, false, );
    (async () => {
      this._chat = await subscribeToOrderChannel(this.$route.params.orderId, {
        onPublication: this.onChatMessage,
        onSubscribed: () => {
          this.onlineStatusIntervalId = setInterval(() => {
            this._chat.publish({ type: 'repairerIsOnline' })
          }, 2000)
        },
        onUnsubscribed: () => {
          if(this.onlineStatusIntervalId !== null){
            clearInterval(this.onlineStatusIntervalId)
          }
        },
      })
    })();
  },
  watch: {
    getIsNeedUpdate: {
      handler (val) {
        console.log('watch.getIsNeedUpdate', val)
        // if (val) {
        //   this.loadOrder(this.$route.params.orderId)
        // }
      },
      immediate: true,
    },
    ordersFromUpdate: {
      handler (val) {
        let orderUpdate = _.find(val, (itm) => {
          return Number(itm.id) === Number(this.$route.params.orderId)
        })
        if (orderUpdate) {
          this.loadOrder(this.$route.params.orderId, (data) => {
            if (this.order.status !== data.order.status) {
              this.order.status = data.order.status
            }
            if (this.order.state != data.order.state) {
              this.order.state = data.order.state
            }
            if (!_.isEqual(this.order.creditProcessingPolicies, data.order.creditProcessingPolicies)) {
              this.order.creditProcessingPolicies = data.order.creditProcessingPolicies;
            }

            if (!_.isEqual(this.order.deliveryTo, data.order.deliveryTo)) {
              this.order.deliveryTo = data.order.deliveryTo;
            }

            this.originalParts = _.cloneDeep(data.order.parts)
            _.forEach(data.order.parts, (p) => {
              let originalPart = _.find(this.order.parts, (prt) => {
                return Number(prt.id) === Number(p.id)
              })
              if (originalPart) {
                if (this.focusedComments.includes('part-comment-' + p.id)) {
                  p.comment = originalPart.comment;
                }

                if (this.focusedComments.includes('credit-comment-' + p.id)) {
                  p.credit.reason = originalPart.credit.reason;
                }
                if (!_.isEqual(p.isChecked, originalPart.isChecked)) {
                  p.isChecked = originalPart.isChecked;
                }

                if (!_.isEqual(p.isEdit, originalPart.isEdit)) {
                  p.isEdit = originalPart.isEdit;
                }

                if (this.isInFocusElement('qty-' + p.id)) {
                  p.qty = originalPart.qty
                }

                if (this.isInFocusElement('qtyRcv-' + p.id)) {
                  p.qtyRcv = originalPart.qtyRcv
                }

                if (this.isInFocusElement('rrp-' + p.id)) {
                  p.rrp = originalPart.rrp
                }

                if (this.isInFocusElement('price-' + p.id)) {
                  p.price = originalPart.price
                }

                if (this.isInFocusElement('invoiceNumber-' + p.id)) {
                  p.invoiceNumber = originalPart.invoiceNumber
                }

                if (this.isInFocusElement('location-' + p.id)) {
                  p.location = originalPart.location
                }

                if (!_.isEqual(p.stockValue, originalPart.stockValue)) {
                  let stock = this.getStockById(Number(p.stock))

                  p.stockValue = {
                    'label': stock?.label ? stock.label : '',
                    'textValue': stock?.textValue ? stock.textValue : '',
                    'value': Number(p?.stock),
                    'comment': p?.orderStockComment  ? p.orderStockComment  : '',
                  }
                }
              }
            })

            this.order.parts = data.order.parts

            this.order.parts.forEach(part => {
              if (part && part.qty > 1) {
                this.$set(part, 'returnQty', part.qty)
              }
            })
            if (!_.isEmpty(this.selectedRow)) {
              let part = _.find(this.order.parts, (p) => {
                return Number(p.id) === Number(this.selectedRow[0].id)
              });
              if (part) {
                this.selectedRow[0].credit = part.credit;
                this.selectedRow[0].status = part.status;
              }
            }
            this.order.files = data.order.files
            if (this.order.trackingNumber !== data.order.trackingNumber) {
              this.order.trackingNumber = data.order.trackingNumber
            }
            if (this.order.invoiceNumber !== data.order.invoiceNumber) {
              this.order.invoiceNumber = data.order.invoiceNumber
            }
            if (this.order.QPNumber !== data.order.QPNumber) {
              this.order.QPNumber = data.order.QPNumber
            }

            if (!_.isEqual(this.backOrders, data.backOrders)) {
              this.backOrders = data.backOrders
              _.forEach(this.backOrders, (o, index) => {
                if (o.status == 'sent' && !_.includes(this.showedModalsForOrders, (o.id))) {
                  this.showedModalsForOrders.push(o.id)
                  this.openedModalsForOrders.push(o.id)
                  setTimeout(() => {
                    this.showModal(null, o.id)
                  }, index * 100)
                }
              })
            }

            if (this.order.subtotal !== data.order.subtotal) {
              this.order.subtotal = data.order.subtotal
            }

            if (this.order.supplierDeliveryDate !== data.order.supplierDeliveryDate) {
              this.order.supplierDeliveryDate = data.order.supplierDeliveryDate
            }

            if (this.order.supplierOrderPartsDeliveryETA !== data.order.supplierOrderPartsDeliveryETA) {
              this.order.supplierOrderPartsDeliveryETA = data.order.supplierOrderPartsDeliveryETA
            }

            let selectedRowCopy = _.cloneDeep(this.selectedRow)
            _.forEach(this.order.parts, (part, index) => {
              if (part.isChecked) {
                let selectedPartIndex = _.findIndex(this.selectedRow, (r) => {
                  return Number(r.id) == Number(part.id)
                })
                if (selectedPartIndex == -1) {
                  selectedRowCopy.push(part)
                } else {
                  selectedRowCopy[selectedPartIndex] = part
                }
              }
            })
            this.selectedRow.splice(0, this.selectedRow.length)
            _.forEach(selectedRowCopy, (p) => {
              this.selectedRow.push(p)
            })

            this.openRowDetails()
            // if (this.selectedRow.length == 1) {
            //   let self = this
            //   let index = _.findIndex(this.order.parts, (prt) => {
            //     return Number(prt.id) === Number(self.selectedRow[0].id)
            //   })
            //   if (index !== -1) {
            //     this.$nextTick(() => {
            //       this.$refs.orderTable.selectRow(index)
            //     })
            //   }
            // }

            this.isCanBlinking = false

            this.handleResize()
            setTimeout(() => {
              this.isCanBlinking = true
            }, 200)
          })
        }
      },
      deep: true,
    }
  },
  components: {
    StickyHeader,
    NumberFormatter,
    FileUpload,
    VueContext,
    PartsOrderDetails,
    dayjs,
    vueDropzone,
    VinDate,
    psLabelValue,
    CustomerAccountDeliver,
    limitedInput,
    VueEditor,
    VPerfectSignature,
    Chat,
    StockSelect,
    SearchFilter,
    datePicker
  },
}
</script>

<style lang="scss" scoped>
.ps-label-value {
  min-width: 360px;
  font-size: 13px;
  span {
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: top;
  }
  &__label {
    width: 170px;
    white-space: nowrap;
    color: #a0a1a7;
    font-weight: 700;
  }
  &__colon {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
  &__value {
    font-weight: 700;
  }

}

</style>

<style>
.parts-order-view-page-repairer .custom-dropdown {
  min-width: 21rem !important;
}

.search-filter-in-header .custom-dropdown {
  left: auto !important;
}

.parts-order-view-page-repairer .custom-dropdown .dropdown-item {
  padding: 7px 15px !important;
}

.back-order-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}

.order-non-returnable-tooltip .tooltip-inner {
  max-width: 243px;
}
/*.back-order-checkbox > input[type="checkbox"]:checked + span.icon i {*/
/*  transform: translate(-2px, -2px) !important;*/
/*  color: #79B1BB !important;*/
/*}*/

.V3 .back-order-checkbox .icon {
  border-color: #d3d3d3 !important;
}

.V3 .back-order-checkbox span.icon {
  border-color: #d3d3d3 !important;
}

.V3 .back-order-checkbox > input[type="checkbox"]:checked + span.icon {
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}


.V3 .back-order-checkbox > input[type="checkbox"]:checked + span.icon i{
  color: white !important;
  /*transform: translate(-2px, -2px) !important;*/
}

.modal-repairer-custom-special-order-modal .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-repairer-custom-special-order-modal {
  max-width: 650px !important;
  margin: 15rem auto !important;
}

.special-order-text-message {
  height: 100px !important;
}

.modal-repairer-custom-special-order-modal .modal-body {
  padding-bottom: 0;
}

.modal-repairer-custom-special-order-modal .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-repairer-custom-special-order-modal .modal-header {
  background-color: #79B1BB;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-repairer-custom-special-order-modal .modal-content {
  border-radius: 3px !important;
}

.modal-repairer-custom-special-order-modal .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #29BBC1 !important;
  border-color: #29BBC1 !important;
}

.modal-repairer-custom-special-order-modal .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-repairer-custom-special-order-modal .input-btn:focus {
  box-shadow: none !important;
}

.modal-repairer-custom-special-order-modal .special-order-signature-canvas {
  height: 100px !important;
  width: 100% !important;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
}

.modal-repairer-custom-special-order-modal .dropzone-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .modal-repairer-custom-special-order-modal {
    margin: 8rem auto !important;
  }
}

.modal-repairer-custom-special-order-modal .control-elem-1__item {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px;
  position: absolute;
  top: 47px;
  right: 20px;
  z-index: 9;
  height: 40px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transition: opacity 150ms ease-in;
  border: 1px solid rgba(27, 30, 56, 0.25);
  cursor: pointer;
  opacity: 0;
}

.modal-repairer-custom-special-order-modal .control-elem-1__item:hover {
  opacity: 1;
}

.modal-repairer-custom-special-order-modal .control-elem-1__item i {
  font-size: 18px;
  color: #FF5E5E;
}

.attach-btn {
  width: 96px !important;
  margin-bottom: 18px !important;
  border-color: #79B1BB !important;
  background-color: #79B1BB !important;
  color: white !important;
}

.photo-for-credit-btn {
  border-color: #79B1BB !important;
  background-color: white !important;
  color: #79B1BB !important;
}
.theme-repairer .parts-order-view-page-repairer .parts-order-view__context-menu-2 .btn:hover {
  color: #000000 !important;
  background-color: rgba(41,187,193,.1) !important;
}
.theme-repairer .parts-order-view-page-repairer .checked-row td {
  background-color: #e5f6f5 !important;
}

.special-instruction-block-2 .send-direct-order-comment-block {
  border: none !important;
  min-height: auto;
}

.special-instruction-block-2 .send-direct-order-comment-block .p-2 {
  padding: 5px 0px !important;
}

.qty-menu-options {
  width: 130px;
  position: absolute;
  top: 0px;
  background: white;
  left: -130px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.invoice-number-context {
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  top: 86px;
  width: 141px;
}

.invoice-number-context {
  top: 292px;
}

.invoice-number-context {
  padding: 15px 15px 0px 15px;
}

.invoice-number-context-2 {
  left: 228px;
  top: 88px;
}

.invoice-number-context-5 {
  top: 128px !important;
}

.invoice-number-context-6 {
  top: 168px !important;
}

.invoice-date-context-5 {
  top: 170px !important;
}

.invoice-date-context-6 {
  top: 210px !important;
}

.invoice-number-context .invoice-number-text {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 600;
  letter-spacing: normal;
  margin-top: 13px;
  margin-left: 7px;
  margin-bottom: 13px;
  color: #1C1F39;
}

.V3 .parts-order-view-page-repairer #dropdown-2.disabled-button .dropdown-toggle:hover {
  background-color: unset !important;
  color: #1C1F39 !important;
  cursor: default;
  /*background-color: #bdbdbd4d !important;*/
}
.V3 .parts-order-view-page-repairer #dropdown-2.disabled-button .dropdown-toggle {
  opacity: 0.7;
}
.V3 .parts-order-view-page-repairer #dropdown-2.disabled-button .dropdown-toggle:hover .raise-text {
  color: #1C1F39 !important;
  cursor: default;
  /*opacity: 0.7;*/
}

#imageRequired .modal-custom.modal-dialog,
#creditPeriod .modal-custom.modal-dialog {
  margin-top: 350px !important;
  width: 600px !important;
  font-weight: normal !important;
}

#imageRequired .modal-custom .modal-body,
#creditPeriod .modal-custom .modal-body {
  padding: 38px 38px 25px 38px;
}

#imageRequired .modal-custom.modal-dialog .cancel-button,
#creditPeriod .modal-custom.modal-dialog .cancel-button {
  height: 38px;
  width: 83px;
  font-size: 13px;
  font-weight: bold;
  color: white;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  border-color: #79B1BB;
  background-color: #79B1BB;
  padding: 0.75rem 1rem;
  margin-bottom: 18px;
}
#imageRequired .modal-custom.modal-dialog .modal-title,
#creditPeriod .modal-custom.modal-dialog .modal-title {
  border-color: #79B1BB;
  background-color: #79B1BB;
  padding: 23px 24px 21px 38px;
  color: white;
}
#imageRequired .modal-custom.modal-dialog .modal-header,
#creditPeriod .modal-custom.modal-dialog .modal-header {
  display: block !important;
  padding: 0px !important;
  margin: 0px !important;
}

@media (max-width: 1080px) {
  #imageRequired .modal-custom.modal-dialog,
  #creditPeriod .modal-custom.modal-dialog {
    margin-left: 368px !important;
  }
}

@media (max-width: 918px) {
  #imageRequired .modal-custom.modal-dialog,
  #creditPeriod .modal-custom.modal-dialog {
    margin-left: 304px !important;
  }
}

@media (max-width: 835px) {
  #imageRequired .modal-custom.modal-dialog,
  #creditPeriod .modal-custom.modal-dialog {
    margin-left: 254px !important;
  }
}

@media (max-width: 768px) {
  #imageRequired .modal-custom.modal-dialog,
  #creditPeriod .modal-custom.modal-dialog {
    margin-left: 17% !important;
  }
}

@media (max-width: 654px) {
  #imageRequired .modal-custom.modal-dialog,
  #creditPeriod .modal-custom.modal-dialog {
    width: 400px !important;
  }
}
@media (max-width: 654px) {
  #imageRequired .modal-custom.modal-dialog,
  #creditPeriod .modal-custom.modal-dialog {
    margin-left: 10% !important;
  }
}
@media (max-width: 654px) {
  #imageRequired .modal-custom.modal-dialog {
    width: 300px !important;
  }
}

@media (max-width: 654px) {
  #creditPeriod .modal-custom.modal-dialog {
    width: 400px !important;
  }
}

@media (max-width: 475px) {
  #creditPeriod .modal-custom.modal-dialog {
    width: 300px !important;
    margin-top: 250px !important;
  }
}

.parts-order-view-page-repairer .confirm-button {
  padding: 7px;
}
.parts-order-view-page-repairer .confirm-button:hover {
  color: white !important
}
.parts-order-view-page-repairer .raise-credit-request-button {
  opacity: 1 !important;
}
.parts-order-view-page-repairer #dropdown-2 .dropdown-menu {
  width: 248px;
  min-width: 130px !important;
  top: -41px !important;
  left: -209px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.parts-order-view-page-repairer #dropdown-2.mobile-dropdown .dropdown-menu {
  left: 72px !important;
  top: -118px !important;
}

.parts-order-view-page-repairer #dropdown-2 .request-credit-return-item {
  width: 121px;
}

.parts-order-view-page-repairer #dropdown-2 .dropdown-toggle {
  border: none;
  border-radius: 0px;
  padding: 0px;
}


.parts-order-view-page-repairer #dropdown-2:hover {
  border-bottom: 1px solid #dcdcdc !important;
}

.parts-order-view-page-repairer #dropdown-2 .dropdown-toggle:hover {
  background-color: #e9f8f9 !important;
}

.parts-order-view-page-repairer #dropdown-2.show {
  border-bottom: 1px solid #dcdcdc !important;
}

.parts-order-view-page-repairer #dropdown-2.show .dropdown-toggle {
  background-color: #e9f8f9 !important;
}

.parts-order-view-page-repairer #dropdown-2.show .dropdown-toggle .raise-text,
.parts-order-view-page-repairer #dropdown-2.show .dropdown-toggle .bx-chevron-left {
  color: #192627 !important;
  font-width: 700;
}

.parts-order-view-page-repairer #dropdown-2 .dropdown-toggle:hover .raise-text,
.parts-order-view-page-repairer #dropdown-2 .dropdown-toggle:hover .bx-chevron-left {
  color: #1C1F39 !important;
  font-weight: bold;
}

.parts-order-view-page-repairer #dropdown-2 .dropdown-toggle::before {
  content: none !important;
  height: 0px !important;
  width: 0px !important;
}


.parts-order-view-page-repairer #dropdown-2 .raise-text {
  height: 46px;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.font-24 {
  font-size: 24px !important;
}

.incorrect-part-ordered-icon {
  color: red !important;
}

.ps-label-little-width .ps-label-value__label {
  width: 118px;
}

.cancel-option .dropdown-item {
  padding-top: 17px !important;
}
#cancelPart .modal-dialog.modal-custom-undo-part-line-change {
  margin-top: 15rem !important;
}
@media (min-width: 576px) {
  .modal-dialog.modal-custom-undo-part-line-change {
    margin: 32.75rem auto !important;
  }
}

.modal-custom-undo-part-line-change .modal-header {
  background-color: #79B1BB !important;
  color: white !important;
}

.modal-custom-undo-part-line-change .modal-content {
  border-radius: 5px;
}

.modal-custom-undo-part-line-change .modal-title {
  /*height: 35px;*/
  margin-left: 15px;
}

.modal-undo-part-line-change-btn-no {
  height: 38px !important;
  width: 83px !important;
  font-size: 13px !important;
  font-weight: bold;
  color: #1C1F39 !important;
  border: 1px solid #29BBC1;
  border-radius: 3px;
  background-color: white !important;
  margin-right: 10px;
}

.modal-undo-part-line-change-btn-no:hover {
  border: 1px solid #29BBC1 !important;
}

.modal-undo-part-line-change-btn-yes {
  height: 38px !important;
  width: 83px !important;
  font-size: 13px !important;
  font-weight: bold;
  color: #eee !important;
  border: 1px solid #79B1BB;
  border-radius: 3px;
  background-color: #79B1BB !important;
  margin-right: 10px;
}

.modal-undo-part-line-change-btn-yes:hover {
  color: #1C1F39 !important;
}
</style>
<style scoped>
.dropdown-actions >>> button span{
  border-color: #7bbec0 !important;
}
.dropdown-actions >>> button{
  background-color: #7bbec0 !important;
  color: #fdfdfd !important;
}
.dropdown-actions >>> button:hover{
  background-color: #afd7d8 !important;
  color: #3f3f3f !important;
}
.clickable-active >>> i{
  height: 25px;
  color: #fdfdfd !important;
  border-radius: 3px;
  background-color: #77afb9;
}
.position-sticky-header{
  /*position: absolute;*/
  /*left: 15px;*/
}
.header-scroll-position-fixed-parts-order-height >>> thead{
  position: sticky;
  top: 197px;
  z-index: 99;
}
.header-scroll-position-fixed-parts-order-height >>> th{
  position: relative !important;
}
.header-scroll-position-fixed-parts-order-height >>> th:nth-child(1){
  position: sticky !important;
  left: 240px !important;
  z-index: 100 !important;
}
.header-scroll-position-fixed-parts-order-height >>> th:nth-child(2){
  position: sticky !important;
  left: 321px !important;
  z-index: 100 !important;
}
.parts-order-height >>> tr td:nth-child(1){
  left: 240px !important;
}
.parts-order-height >>> tr td:nth-child(2){
  left: 321px !important;
}
.header-scroll-position-fixed{
  position: fixed;
  top: 135px;
  height: 75px;
  z-index: 999;
  display: none;
}
.header-scroll-position-fixed.active{
  display: block;
}
.header-scroll-position-fixed-vin-date{
  background-color: #fdfdfd;
  padding: 15px;
  margin: 0 !important;
}
.parts-order-margin-table{
  /*overflow: visible;*/
}
.invoice-number-context-2{
  top: 120px;
}
.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
}
.modal-cancel-order-status-title{
  font-weight: 600;
  width: 150px;
}
.modal-cancel-order-status-text{
  width: 300px;
}
.attach-btn-cancel-order-modal{
  background-color: #7BBEC0FF;
  color: #f6f6f6;
  border-color: #7BBEC0FF;
}
.attach-btn-cancel-order-modal:hover{
  background-color: #70bbbd;
  color: #f6f6f6;
}
.cancel-order-modal-checks-bold{
  font-weight: bold;
  display: block;
}
.cancel-order-modal-checks-orange{
  font-weight: bold;
  color: #EC823BFF;
}
.cancel-order-modal-checks-blue{
  color: #1FB9F0FF;
}
.cancel-order-modal-checks-red{
  font-weight: bold;
  color: #FD0202FF;
  display: block;
}
.cancel-order-modal-checks-green{
  font-weight: bold;
  color: #04B051FF;
}
.cancel-order-modal-checks-classic{
  font-weight: 500;
  color: rgb(28, 31, 57);
}
.cancel-order-modal-message{
  margin-top: 1rem;
}
.cancel-order-modal-message textarea{
  resize: none;
  height: 100px;
}
.cancel-order-modal-checks{
  border: 1px solid #cccccc;
  padding: 5px;
  border-radius: 3px;
}
.cancel-order-modal{
  padding: 7px;
}
.color-orange {
  color: orange !important;
}

.parts-order-view__h45px{
  width: 45px;
}

.green-cell {
  color: #0cb458 !important;
}

.red-cell {
  color: red !important;
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.icon-red-blinking {
  color: red;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.icon-orange-blinking {
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.orange-icon-2 {
  color: orange;
}

.gray-icon-2 {
  color: rgb(121, 121, 121) !important;
}

.green-icon-2 {
  color: rgb(60, 199, 169) !important;
}

.blue-icon-2 {
  color: #2f6da5 !important;
}

.part-number-stock-orange {
  color: #F59345 !important;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  text-align: start;
  padding-top: 5px;
  padding-left: 10px;
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.part-number-stock-red {
  color: red;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  text-align: start;
  padding-top: 5px;
  padding-left: 10px;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.cancel-part-class {
  border-radius: 8px;
  padding: 7px;
  font-weight: 400;
  background: #fee9e7;
  color: #f44336;
}

.disabled-options-item {
  opacity: 0.65;
}
.disabled-button {
  opacity: 0.7 !important;
}

.disabled-button:hover {
  background-color: #bdbdbd4d !important;
}

.gray-icon {
  color: rgb(121,121,121) !important
}
.orange-icon {
  color: #f0710f !important;
}
.red-icon {
  color: red !important;
}
.green-icon {
  color: green !important;
}

.processing-icon-orange {
  color: orange !important;
}

.additional-line {
  /*border-top: 10px solid #e3e4e7;*/
}

.report-part-order-item {
  border-right: 1px solid rgba(27, 30, 56, 0.25);
  color: #a0a1a7;
  font-weight: 700;
}

.report-part-order-left-item {
  font-weight: 700;
}

.color-green {
  color: #0cb458 !important;
}

.color-red {
  color: red !important
}

.parts-order-view-body >>> .dropdown-item.disabled {
  opacity: 0.65;
}

.parts-order-view-body >>> .icon-stock-comment {
  font-size: 18px;
  margin-top: -2px;
}

.parts-order-view-body >>> td.column-stock {
  min-width: 122px;
}

.raised-credit-request-sub-menu {
  right: -131px;
  top: 0px;
}

.create-credit-request-sub-menu {
  right: -234px;
  top: 43px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.set-invoice-number-sub-menu {
  right: -234px;
  top: 85px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.create-credit-request-sub-menu-2 {
  top: -1px !important;
}

.part-sub-context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.autosave {
  font-size: 13px;
  margin-left: 100px;
}

.parts-order-view-body >>> .column-part-list {
  width: auto;
}

.parts-order-view-body >>> .column-status-id {
  width: 30px;
  max-width: 30px;
}

.parts-order-view-body >>> .column-part-number {
  max-width: 100px;
  width: 100px;
}

.parts-order-view-body >>> .column-type {
  max-width: 55px;
  width: 55px;
}

.parts-order-view-body >>> .column-cost {
  max-width: 55px;
  width: 55px;
}

.parts-order-view-body >>> .column-list {
  max-width: 55px;
  width: 55px;
}

.parts-order-view-body >>> .column-qty-order {
  max-width: 66px;
  width: 66px;
}

.parts-order-view-body >>> .column-qty-rcvd {
  max-width: 66px;
  width: 66px;
}

.parts-order-view-body >>> .column-credit {
  max-width: 80px;
  width: 80px;
}

.parts-order-view-body >>> .column-status {
  max-width: 85px;
  width: 85px;
}

.parts-order-view-body >>> .column-workshop-location {
  width: auto;
}

.options-mobile .bx-down-arrow {
  padding-top: 10px;
  padding-left: 5px;
}

.options-mobile.dropdown-options.show .dropdown-arrow .bx-down-arrow {
  transform: rotate(180deg) translateX(-5px) translateY(-10px) !important;
}

.raised-credit-request-sub-menu-qty-input {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.raised-credit-request-sub-menu-qty-input.flashing {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0px 0px 1px 1px #79B1BB;
  }
}

.pointer {
  cursor: pointer;
}

.bxs-truck {
  mask-size: contain;
  -webkit-mask-size: contain;
  height: 20px;
  width: 20px;
}
.bxs-truck.text-primary {
  color: #43B968 !important;
}


.raised-credit-request-qty-btn  {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.raised-credit-request-qty-btn .confirm-button {
  min-width: auto;
  border-color: #79B1BB !important;
  background-color: #79B1BB !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .dropdown-arrow-media-768px{
    position: relative;
    bottom: 8px;
    right: 5px;
  }
}
</style>
