<template>
  <div class="">
    <span @click="$emit('closePdf')" class="pdf-viewer-close-button">×</span>
    <ejs-pdfviewer
        id="pdfViewer"
        :resourceUrl="resourceUrl"
        :printMode="printMode"
        :documentPath="documentPath"
        :style="{'height': documentHeight + 'px'}"
    ></ejs-pdfviewer>
  </div>
</template>

<script>
import { PdfViewerComponent, Toolbar, Magnification, Navigation, Print } from "@syncfusion/ej2-vue-pdfviewer"
import { browserName } from 'mobile-device-detect';
export default {
  name: "pdf-viewer",
  components: {
    'ejs-pdfviewer': PdfViewerComponent
  },
  provide: {
    PdfViewer: [Toolbar, Navigation,  Print, Magnification]
  },
  props: {
    documentHeight: {
      type: Number,
      default: 500
    },
    documentPath: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (browserName === 'Chrome') {
      this.printMode = 'NewWindow'
    }
  },
  data () {
    return {
      resourceUrl:'https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib',
      printMode: null,
    }
  }
}
</script>

<style>
#pdfViewer_sideBarToolbar,
#pdfViewer_open,
#pdfViewer_handTool,
#pdfViewer_selectToolContainer,
#pdfViewer_handToolContainer,
#pdfViewer_redo,
#pdfViewer_undo,
#pdfViewer_comment,
#pdfViewer_submitForm,
#pdfViewer_search,
#pdfViewer_annotation,
#pdfViewer_formdesigner,
.e-separator {
  display: none !important;
}
#pdfViewer_currentPageInput {
  pointer-events: none;
}
.e-toolbar-right {
  margin-right: 40px;
}
.pdf-viewer-close-button {
  position: absolute;
  right: 21px;
  z-index: 10000;
  transform: translate(2px, 3px);
  font-size: 30px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54)
}
#pdfViewer {
  /*height: 700px;*/
}
#pdfViewer_toolbarContainer {
  z-index: 9999;
}
</style>
