<template>
  <div class="special-order-page">
    <tabs ref="settings-rfq-tabs" cache-lifetime="0"
          :options="{ useUrlFragment: false, defaultTabHash: computedDefaultOrderHash }" @changed="onOrderTabChange">
      <tab name="Back Order Acceptance" id="specialOrder" ref="specialOrder" class-name="tab-content-table">
        <div class="settings-rfq-page row">
          <div class="col-md-6">
            <div class="group-title mt-3">
              Customer Order From Settings
            </div>
            <div class="row mt-3">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Back Order - Acceptance Agreement</span> <br/>
                <p class="notifications-info-text">Define a part acceptance agreement to apply for all customers to
                  acknowledge as a digital acceptance record for parts.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      @change="changedBrand"
                      v-model="settings.orders.specialOrder.enableAcceptanceAgreement"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.orders.specialOrder.enableAcceptanceAgreement" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Apply to All Customers</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> applies to all customers across all RFQ's
                  (Default). Selecting <b>No</b> allows you set this by customer in the customers settings page.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             @change="changedBrand"
                             :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                             v-model="settings.orders.specialOrder.customerToApply"
                             :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.orders.specialOrder.customerToApply"
                          :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                          @change="changedBrand"
                          class="form-check-input" type="radio"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div style="height: 5px; background-color: rgba(27, 30, 56, 0.12) !important; margin: 10px 0px"></div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Display Order Acceptance - T&C's</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> applies and displays your T&c'S to every part
                  marked with this status to the Customer. No, excludes the display of your T&C's</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             @change="changedBrand"
                             :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                             v-model="settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable"
                             :value="true">
                      <span :class="{'empty-field': isClickedSave && settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null}" class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable"
                          @change="changedBrand"
                          class="form-check-input" type="radio"
                          :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                          :value="false">
                      <span :class="{'empty-field': isClickedSave && settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null}" class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div :class="{'empty-editor': isClickedSave && settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable && isEmpty(settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription) }" class="ml-3 mb-3">
              <vue-editor
                  v-model="settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription"
                  @input="changedBrand"
                  :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Require - Acceptance of Order T&c's</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to tick for acceptance of
                  the agreement and prevents them Ordering if not ticked.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input class="form-check-input" :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement" type="radio" v-model="settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs" @change="changedBrand"
                             :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          class="form-check-input" :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement" type="radio" v-model="settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs" @change="changedBrand"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Require - Signee to Add Name/Job Position</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to type their Job
                  position/role in your customers company.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             @change="changedBrand"
                             :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                             v-model="settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition"
                             :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          class="form-check-input" type="radio"
                          @change="changedBrand"
                          :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                          :value="false" v-model="settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Require E-Signature</span> <br/>
                <p class="notifications-info-text">Get your customer to E-Sign the acceptance on their Desktop, Mobile
                  or Tablet device.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             @change="changedBrand"
                             :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                             v-model="settings.orders.specialOrder.enableRequireESignature"
                             :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          class="form-check-input" type="radio"
                          @change="changedBrand"
                          :disabled="!settings.orders.specialOrder.enableAcceptanceAgreement"
                          v-model="settings.orders.specialOrder.enableRequireESignature"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>


            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Send Order Acceptance by</span> <br/>
                <p class="notifications-info-text">Sends the form for your customer to accept & sign by the following
                  default method.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 220px;">
                  <multiselect
                      :showLabels="false"
                      v-model="settings.orders.specialOrder.sendOrderAcceptanceBy"
                      @input="changedBrand"
                      :options="['Email']"
                      :disabled="true"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                  />
                </div>
              </div>
            </div>

            <p v-if="((isEmpty(settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription) && settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable) || settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null) && isClickedSave" style="padding-left: 15px;" class="error-text">Check missing highlighted fields to save</p>

            <div class="d-flex mb-2-7 mt-15 d-flex justify-content-end align-items-center">
              <button @click="showModal" class="btn btn-secondary primary-show-example-btn mr-lg-2">Show Me an Example</button>
              <button @click="onSave" class="btn btn-primary primary-save-btn">Save</button>
            </div>

          </div>
        </div>
      </tab>
    </tabs>

    <b-modal @hide="onCloseModal" ref="special-order-example" id="special-order-modal" size="custom-special-order-modal" title="Back Order - Part Acceptance Agreement">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned special-supplier-order-modal form-container">
        <form>
          <div class="form-group row pr-3">
            <label class="col col-form-label">{{companyName}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the supply of the following part/s with your order.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Order Number: </p>
              <p class="">220785</p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Invoice Number: </p>
              <p class="">887317P</p>
            </div>
          </div>
          <div class="d-flex pr-3 pl-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">LEXUS</p>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3">
            <div class="col-12 col-md-1" style="padding: 0px">
              <label class="input-checkbox col-form-label font-weight-bold pl-5">
                <input
                    name="part"
                    type="checkbox"
                />
                <div class="checkmark"></div>
              </label>
            </div>
            <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control">
              <b>Part: </b> Front Bumper Bar | MAXU9898911 | QTY 1 | $0,000.00
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-supp-order">
              <vue-editor
                  v-model="settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription"
                  :disabled="true"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>
          </div>
          <div v-if="settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs" class="w-100 text-right row p-3">
            <label class="input-checkbox col-form-label font-weight-bold pl-5">
              <input
                  name="remember"
                  type="checkbox"
              />
              <div class="checkmark"></div>I have read & accept the suppliers Terms and Conditions
            </label>
          </div>
          <div v-if="settings.orders.specialOrder.enableRequireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 special-order-signature-block" ref="specialOrderBlock">
                <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad"
                                   class="special-order-signature-canvas"/>
              </div>
              <a
                  v-if="!isEmptySignature"
                  class="control-elem-1__item"
                  @click.prevent="clearSignature"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                <i class="bx bx-trash"></i>
              </a>
            </template>
          </div>
          <template v-if="settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
          </template>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
          <b-button class="input-btn decline">Decline</b-button>
          <b-button class="input-btn">Accept</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {VueEditor} from 'vue2-editor'
import {mapGetters} from "vuex";
import { VPerfectSignature } from 'v-perfect-signature'
import Axios from "axios";

export default {
  name: 'the-supplier-orders',
  components: {
    multiselect: Multiselect,
    VueEditor,
    VPerfectSignature,
  },
  data() {
    return {
      isClickedSave: false,
      isEmptySignature: true,
      isOpenModal: false,
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      signature: '',
      settings: {
        orders: {
          specialOrder: {
            customerToApply: false,
            displayOrderAcceptanceTaCsDescription: null,
            displayOrderAcceptanceTaCsEnable: false,
            enableAcceptanceAgreement: false,
            enableRequireAcceptanceOfOrderTaCs: false,
            enableRequireESignature: false,
            enableRequireSigneeToAddNameOrJobPosition: false,
            sendOrderAcceptanceBy: 'Email'
          }
        },
      },
      editorToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }], [{
          list: "ordered"
        }, {
          list: "bullet"
        },], [{
          indent: "-1"
        }, {
          indent: "+1"
        }], // outdent/indent
          [{
            color: []
          }],
        ["clean"] // remove formatting button
      ],
    }
  },
  props: {
    propsSettings: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyName: {
      type: String,
      default: () => {
        return ''
      }
    },
    companyId: {
      type: [Number, String],
      default: () => {
        return null
      }
    },
  },
  computed: {
    ...mapGetters({
      getActiveCustomers: 'supplier/customers/getActiveCustomers',
      companyInfo: 'currentCompany/getDetails',
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
    }),
    getSettings () {
      return this.isMultipleLocations ? _.cloneDeep(this.propsSettings) : _.cloneDeep(this.getterSettings)
    },
    computedDefaultOrderHash() {
      if (localStorage.getItem('settings.tabs.defaultRfqSettingsTab')) {
        return localStorage.getItem('settings.tabs.defaultRfqSettingsTab')
      }
      return 'specialOrder'
    },
  },
  methods: {
    handleResize() {
      if (!this.isOpenModal) {
        return
      }
      setTimeout(() => {
        let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
        if (signatureCanvas && signatureCanvas[0]) {
          let width = this.$refs.specialOrderBlock.offsetWidth;
          signatureCanvas[0].width = width
          signatureCanvas[0].style.width = width + 'px'
          signatureCanvas[0].height = 100
          signatureCanvas[0].style.height = '100px'
        }
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.inputPointsHandler()
        }
      }, 100)
    },
    loadSettings () {
      this.settings = {
        orders: {
          specialOrder: {
            customerToApply: this.getSettings?.orders?.specialOrder?.customerToApply ? true : false,
            displayOrderAcceptanceTaCsDescription: this.getSettings?.orders?.specialOrder?.displayOrderAcceptanceTaCsDescription ? this.getSettings.orders.specialOrder.displayOrderAcceptanceTaCsDescription : null,
            displayOrderAcceptanceTaCsEnable: this.getSettings?.orders?.specialOrder?.displayOrderAcceptanceTaCsEnable ? true : false,
            enableAcceptanceAgreement: this.getSettings?.orders?.specialOrder?.enableAcceptanceAgreement ? true : false,
            enableRequireAcceptanceOfOrderTaCs: this.getSettings?.orders?.specialOrder?.enableRequireAcceptanceOfOrderTaCs ? true : false,
            enableRequireESignature: this.getSettings?.orders?.specialOrder?.enableRequireESignature ? true : false,
            enableRequireSigneeToAddNameOrJobPosition: this.getSettings?.orders?.specialOrder?.enableRequireSigneeToAddNameOrJobPosition ? true : false,
            sendOrderAcceptanceBy: 'Email'
          }
        },
      }
    },
    changedBrand() {
      let result = _.cloneDeep(this.propsSettings)
      result.orders = this.settings.orders
      this.$emit('changedBrand', {value: result, isSettings: true, companyId: this.companyId})
    },
    showModal () {
      this.$refs['special-order-example'].show();
      this.isOpenModal = true;
      this.$nextTick(() => {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.specialOrderBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
        })
      })
    },
    onSave () {
      this.isClickedSave = true;
      if ((_.isEmpty(this.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription) && this.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable) || this.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable === null) {
        return
      }

      let newData = {
        settings: {
          orders: {
            specialOrder: {
              customerToApply: this.settings.orders.specialOrder.customerToApply,
              displayOrderAcceptanceTaCsDescription: this.settings.orders.specialOrder.displayOrderAcceptanceTaCsDescription,
              displayOrderAcceptanceTaCsEnable: this.settings.orders.specialOrder.displayOrderAcceptanceTaCsEnable,
              enableAcceptanceAgreement: this.settings.orders.specialOrder.enableAcceptanceAgreement,
              enableRequireAcceptanceOfOrderTaCs: this.settings.orders.specialOrder.enableRequireAcceptanceOfOrderTaCs,
              enableRequireESignature: this.settings.orders.specialOrder.enableRequireESignature,
              enableRequireSigneeToAddNameOrJobPosition: this.settings.orders.specialOrder.enableRequireSigneeToAddNameOrJobPosition,
              sendOrderAcceptanceBy: 'Email'
            }
          },
        }
      }
      console.log(newData)

      Axios.post('/ir/company/update-settings/' + this.companyId,
          newData
      )
          .then(response => {
            if (response.data && response.data._status) {
              if (Number(this.companyId) === Number(this.companyInfo.id)) {
                this.$store.dispatch('currentCompany/loadCompanyInfo')
                this.$store.dispatch('settings/loadSettings')
              }
              this.$toast.success('The RFQ Settings have been updated')
            } else {
              this.$toast.error('The RFQ Settings haven\'t been updated')
            }
          })
          .catch(error => {
            this.$toast.error('The RFQ Settings haven\'t been updated')
            console.log(error)
          })
    },
    isEmpty(val) {
      return _.isEmpty(val)
    },
    onOrderTabChange(tab) {
      localStorage.setItem('settings.tabs.defaultSupplierOrderSettingsTab', tab.tab.id)
    },
    onCloseModal () {
      this.isOpenModal = false;
    },
    checkSignature () {
      if (this?.$refs?.signaturePad) {
        this.isEmptySignature = this.$refs.signaturePad.isEmpty()
      }
    },
    clearSignature () {
      let signaturePad = this.$refs.signaturePad
      if (signaturePad) {
        signaturePad.clear()
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  watch: {
    getterSettings: {
      handler() {
        if (!this.isMultipleLocations) {
          this.loadSettings();
        }
      },
      immediate: true,
    },
    propsSettings: {
      handler() {
        if (this.isMultipleLocations) {
          this.loadSettings();
        }
      },
      immediate: true,
    },
  }
}
</script>

<style>

.special-supplier-order-modal .disabled-vue-editor-supp-order .ql-toolbar {
  display: none !important;
}
.disabled-vue-editor-supp-order,
.disabled-vue-editor-supp-order .quillWrapper{
  width:100%;
}

.special-supplier-order-modal .disabled-vue-editor-supp-order .ql-container {
 /*width: 650px !important;*/
  border-top: 1px solid #ccc !important;
}

.theme-supplier .special-order-page .empty-editor .ql-editor {
  background-color: rgb(255,201,201) !important;
}

.theme-supplier .special-order-page .ql-toolbar.ql-snow {
  border-radius: 3px 3px 0 0;
}

.theme-supplier .special-order-page .ql-container.ql-snow {
  border-radius: 0 0 3px 3px;
}

.theme-supplier .special-order-page .form-check-inline.radio {
  width: 80px;
}

.theme-supplier .special-order-page .multiselect__tags {
  height: auto;
}

.modal-custom-special-order-modal .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-custom-special-order-modal {
  max-width: 650px !important;
  margin: 15rem auto !important;
}

.special-order-text-message {
  height: 100px !important;
}

.modal-custom-special-order-modal .modal-body {
  padding-bottom: 0;
}

.modal-custom-special-order-modal .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-custom-special-order-modal .modal-header {
  background-color: #29BBC1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-custom-special-order-modal .modal-content {
  border-radius: 3px !important;
}

.modal-custom-special-order-modal .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #29BBC1 !important;
  border-color: #29BBC1 !important;
}

.modal-custom-special-order-modal .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-custom-special-order-modal .input-btn:focus {
  box-shadow: none !important;
}

.mark-as-returned-signature {
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.mark-as-returned-signature:hover + .control-elem-1__item {
  opacity: 1;
}

.modal-custom-special-order-modal .special-order-signature-canvas {
  height: 100px !important;
  width: 100% !important;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
}

.special-order-signature-canvas:hover + .control-elem-1__item,
.special-order-signature-block:hover + .control-elem-1__item {
  opacity: 1;
}

.modal-custom-special-order-modal .dropzone-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .modal-custom-special-order-modal {
    margin: 8rem auto !important;
  }
}

.modal-custom-special-order-modal .control-elem-1__item {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px;
  position: absolute;
  top: 47px;
  right: 20px;
  z-index: 9;
  height: 40px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transition: opacity 150ms ease-in;
  border: 1px solid rgba(27, 30, 56, 0.25);
  cursor: pointer;
  opacity: 0;
}

.modal-custom-special-order-modal .control-elem-1__item:hover {
  opacity: 1;
}

.modal-custom-special-order-modal .control-elem-1__item i {
  font-size: 18px;
  color: #FF5E5E;
}

.theme-repairer .credit-view-page .bx-reset {
  padding-right: 15px;
  font-size: 20px;
  color: #2e6ca5;
}
</style>

<style scoped>
.theme-supplier .special-order-page .empty-field {
  background-color: rgb(255,201,201) !important;
}

.primary-show-example-btn {
  border: 2px solid #29BBC1;
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
}

label.checkbox>input[type="checkbox"]:checked+span.icon {
  border: 2px solid #29BBC1 !important;
  background-color: rgba(27, 30, 56, 0.03) !important;
}

label.checkbox>input[type="checkbox"]:checked+span.icon i {
    transform: translate(-2px, -2px);
    color: #29BBC1;
}

label.checkbox>input[type="checkbox"]+span.icon {
  border: 2px solid #29BBC1 !important;
  background-color: rgba(27, 30, 56, 0.03) !important;
}

label.checkbox>span.text {
  font-size: 12px;
}
</style>

<style scoped>
.theme-supplier label.checkbox > input[type="checkbox"]:disabled + span.icon {
  border-color: lightgray !important;
  cursor: not-allowed;
}

label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
  color: lightgray !important;
  cursor: not-allowed;
}
.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon i,
.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon,
.theme-supplier label.radio>input[type="radio"]:disabled+span.icon i,
.theme-supplier label.radio>input[type="radio"]:disabled+span.icon {
  border-color: lightgray !important;
  color: lightgray;
  cursor: not-allowed;
}
</style>
