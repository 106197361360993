<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <div @click.prevent="goToHome" class="logo-text">
                        <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
                    </div>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="done" style="cursor: pointer" @click="$router.push({path: '/register/repairer/find-business'})">
                                    <span class="numb">
                                        <i class="bx bxs-check-circle" style="color:#29bbc1"></i>
                                    </span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line active"></li>
                                <li class="active">
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>Create your business profile contact details</h1>
                        <br />
                        <form method="POST" @submit.prevent="login">
                            <div class="form-group">
                                <label for="phonenumber">Primary Phone Number</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="phonenumber"
                                    @keypress="onlyNumber"
                                    placeholder="Primary Phone Number"
                                    v-model="phone"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="fax">Primary Fax Number</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="fax"
                                    placeholder="Primary Fax Number"
                                    v-model="fax"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email">Primary Email Address</label>
                                <input
                                    autocomplete="off"
                                    type="email"
                                    class="form-control input-field"
                                    name="email"
                                    placeholder="Primary Email Address"
                                    v-model="email"
                                    @blur="checkEmail"
                                    @focus="isCheckEmail = true;"
                                />
                                <div class="w-100 text-right" v-show="!isCheckEmail && email !== ''">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is incorrect
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="address">Primary Business Address</label>
                                <google-location
                                    v-if="!sameAsBusinessAddress"
                                    type="text"
                                    v-model="address"
                                    id="location_my_account"
                                    placeholder="Search Address"
                                    class="register-google-location"
                                    @onPlaceChange="addressChanged"
                                ></google-location>
                                <div v-else class="">
                                  <i class="bx bx-map google-location-map-icon"></i>
                                  <input
                                      autocomplete="off"
                                      type="text"
                                      class="form-control input-field"
                                      placeholder="Primary Business Address"
                                      v-model="address"
                                      :disabled="sameAsBusinessAddress"
                                  />
                                </div>
                                <div class="w-100 text-right" v-show="false">
                                      <span class="error-text">
                                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                      </span>
                                </div>
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Suburb">Suburb</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="Suburb"
                                    placeholder="Suburb"
                                    v-model="suburb"
                                    :disabled="sameAsBusinessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="State">State</label>
                                <multiselect
                                    :options="getOptionsState"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Choose your state"
                                    v-model="state"
                                    :disabled="sameAsBusinessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="postcode">Postcode</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field post-code"
                                    name="businessnumber"
                                    placeholder="Postcode"
                                    v-model="postcode"
                                    :disabled="sameAsBusinessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="w-100 text-right row justify-content-end">
                                <label class="input-checkbox">
                                    <input
                                        name="remember"
                                        type="checkbox"
                                        aria-label="Same as registered business address"
                                        v-model="sameAsBusinessAddress"
                                        @change="checkboxChange"
                                    />
                                    <div class="checkmark"></div>Same as registered business address
                                </label>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn input-btn"
                                    :disabled="!nextAllowed"
                                    @click="register"
                                >Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
form {
    max-width: 522px;
    margin: 0 auto;
}
.form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.form h1 {
    text-align: center;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 23px;
}
.form .post-code {
    width: 109px;
}
</style>
<style>
.invite-repairer .register-google-location .form-control {
  height: 43px;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 4px;
  width: 100%;
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
}
.invite-repairer .register-google-location .bx-map {
  top: 6px;
  left: 3px;
}

.V3 .invite-repairer i.google-location-map-icon {
  font-size: 16px;
  width: 26px;
  text-align: center;
  margin-right: -26px;
  position: relative;
  z-index: 1;
  float: left;
  padding-top: 7px;
  opacity: 0.5;
  top: 6px;
  left: 3px;
}

.V3 .invite-repairer i.google-location-map-icon + input {
  padding-left: 26px;
}
</style>

<script>
import Multiselect from "vue-multiselect";
import GoogleLocation from "../../../../components/utility/google-location";
export default {
  name: "InviteRepairerCreateBusinessProfile",
  components: {
    multiselect: Multiselect,
    GoogleLocation,
  },
  data: function (){
    return {
      phone: '',
      fax: '',
      email: '',
      address: '',
      suburb: '',
      state: '',
      postcode: '',
      sameAsBusinessAddress: false,
      registrationData: {},
      isCheckEmail: true
    };
  },
  mounted() {
    if(localStorage.getItem('registrationData')){
      try {
        this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
      } catch (e) {
        localStorage.removeItem('registrationData');
      }
    }
    this.scrollToTop()
  },
  methods: {
    checkboxChange() {
      if (this.sameAsBusinessAddress) {
        this.address = this.registrationData.business.address;
        this.suburb = this.registrationData.business.city;
        this.state = this.registrationData.business.state;
        this.postcode = this.registrationData.business.postcode;
      }
    },
    addressChanged (addr) {
      this.address = addr["address"];
      this.suburb = addr["suburb"];
      this.state = addr["state"];
      this.postcode = addr["postcode"];
    },
    checkEmail() {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isCheckEmail = re.test(this.email);
    },
    onlyNumber (event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 32 && keyCode !== 45 && keyCode !== 43 && keyCode !== 40 && keyCode !== 41) {
        event.preventDefault();
      }
    },
    register: function(){
      let businessForSuppliers = {};

      businessForSuppliers.phone = this.phone;
      businessForSuppliers.fax = this.fax;
      businessForSuppliers.email = this.email;
      businessForSuppliers.address = this.address;
      businessForSuppliers.suburb = this.suburb;
      businessForSuppliers.state = this.state;
      businessForSuppliers.postcode = this.postcode;
      businessForSuppliers.sameAsBusinessAddress = this.sameAsBusinessAddress;

      if(this.sameAsBusinessAddress){
        businessForSuppliers.address = this.registrationData.business.address;
        businessForSuppliers.suburb = this.registrationData.business.city;
        businessForSuppliers.state = this.registrationData.business.state;
        businessForSuppliers.postcode = this.registrationData.business.postcode;
      }

      this.registrationData.businessForSuppliers = businessForSuppliers;

      localStorage.registrationData = JSON.stringify(this.registrationData);

      this.$router.push({name: 'RepairerAddWorkshoplocation'});
    },
    goToHome() {
      document.location.replace(appConfig.homeURL + '/');
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },
  computed: {
    getOptions() {
      return ["Australian Public Company", "Australian Private Company"];
    },
    getOptionsCity() {
      return ["LAKEMBA", "Sydney"];
    },
    getOptionsState() {
      return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA',];
    },
    nextAllowed(){
      return !!(
          this.phone
          && this.fax
          && this.email
          && (
              this.sameAsBusinessAddress
              || (this.address
                  && this.suburb
                  && this.state
                  && this.postcode)
          )
      );
    }
  }
};
</script>
