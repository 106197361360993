<template>
    <div>
        <input
            type="email"
            class="input-field"
            :class="{'error': !isCheckEmail}"
            placeholder="Enter your email address"
            name="userEmail"
            v-model="userEmail"
            @change="checkEmail()"
        />
        <div class="w-100 text-right">
            <span v-if="!isCheckEmail" class="error-text">
                <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;We could not find an account with that email address.
            </span>
        </div>
    </div>
</template>

<script>
import Axios from "axios";
import NProgress from "nprogress";

export default {
    name: "Email",
    data() {
        return {
            userEmail: this.value,
            isCheckEmail: true
        };
    },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    methods: {
        checkEmail() {
            NProgress.start();
            Axios({url: '/ir/auth/check/email', data: {email: this.userEmail}, method: 'POST'})
                .then(resp => {
                    this.isCheckEmail = !(resp && resp.data && resp.data.status === false);
                    let email = (this.isCheckEmail)?this.userEmail:'';
                    this.$emit('input', email);
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                NProgress.done();
            });
        }
    }
}
</script>

<style scoped>

</style>