/*eslint-disable */
import Axios from 'axios'
import {
    SETTINGS_UPDATED,
    SETTINGS_PRICE_SELECTORS_UPDATED,
    SETTINGS_REINSTALLATION,
    SETTINGS_RFQ_REMOVE_PART_FROM_NIL_SUPPLY_LIST,
    SETTINGS_RFQ_ADD_PART_FROM_NIL_SUPPLY_LIST
} from '../../mutation-types'

export function loadSettings (context) {
  return new Promise((resolve, reject) => {
    context.dispatch('settings/loadPriceSelectors', {}, { root: true })
    Axios.get('/ir/company/get-settings')
      .then(response => {
        if (response.data && response.data._status) {
          context.commit(SETTINGS_UPDATED, response.data.settings)
        }
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })

}

export function loadPriceSelectors (context) {
  Axios.get('/ir/purchase-price-selector')
    .then(response => {
      if (response.data && response.data._status) {
        context.commit(SETTINGS_PRICE_SELECTORS_UPDATED, response.data.priceSelectors)
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export function removePartFromNilSupplyList (context, part) {
  context.commit(SETTINGS_RFQ_REMOVE_PART_FROM_NIL_SUPPLY_LIST, part)
}

export function addPartFromNilSupplyList (context, part) {
    context.commit(SETTINGS_RFQ_ADD_PART_FROM_NIL_SUPPLY_LIST, part)
}

export function reinstallation (context) {
  context.commit(SETTINGS_REINSTALLATION)
}
