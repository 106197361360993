import Axios from "axios";

import {
    SUPPLIER_DASHBOARD_REINSTALLATION,
    SUPPLIER_SET_MESSAGES,
    SUPPLIER_SET_ACTIVITY
} from "../../../mutation-types";

export function init(context, payload = 0) {
    Axios.get('/ir/supplier/dashboard')
        .then(response => {
            if (response?.data?.chatMessages) {
                context.commit(SUPPLIER_SET_MESSAGES, response.data.chatMessages);
            }
            if (response?.data?.activityList) {
                context.commit(SUPPLIER_SET_ACTIVITY, response.data.activityList);
            }
        })
}

export function reinstallation(context, payload = 0) {
    context.commit(SUPPLIER_DASHBOARD_REINSTALLATION);
}
