<template>
<div class="multiple-brand-toggle-page" style="position:relative">

  <b-dropdown id="supplier-rfq-view-options" ref="optionMenu" text="Options" class="dropdown-options" checkbox-menu>
    <template v-slot:button-content>{{ computedOptionsName }}
      <span v-if="isShowDropdown" class="dropdown-arrow">
        <i class='bx bx-down-arrow'></i>
      </span>
    </template>

    <template>
      <b-dropdown-form v-for="(multiple, index) in multipleBrands" :key="multiple && multiple.id ? multiple.id : getRandom()" :style="{cursor: (multiple.id == currentCompany.id)?'default':'pointer'}" class="dropdown-item p-0 mr-0">
        <div @click.prevent="changeMultipleBrand(multiple)" class="pt-3 font-weight-bold w-100">{{ multiple.brandLocation }}</div>
        <b-form-checkbox
            v-model="multiple.isCheckViewData"
            class="form-check-input mb-0"
            name="checkbox"
            :value="true"
            @change="changeMultipleBrandView(multiple)"
            :unchecked-value="false">
        </b-form-checkbox>

      </b-dropdown-form>
    </template>
  </b-dropdown>
</div>
</template>

<script>
/*eslint-disable*/
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import Axios from 'axios'
import _ from 'lodash'
import {uuid} from "vue-uuid";

export default {
  name: "multiple-brand-toggle",
  props: {
    company: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      isLoading: false,
      isLoading2: false,
      multipleBrands: [],
    }
  },
  computed:{
    ...mapGetters({
      getterMultipleBrands: 'currentUser/getMultipleBrands',
      currentUser: 'currentUser/getCurrentUserInfo',
      currentCompany: 'currentCompany/getDetails',
    }),
    computedOptionsName() {
      let checkedCompaniesLength = _.filter(this.multipleBrands, (b) => {
        return b.isCheckViewData;
      })?.length
      if (checkedCompaniesLength > 1) {
        return 'Multi View'
      }
      let checkedCompany = _.find(this.multipleBrands, (b) => {
        return b.isCheckViewData;
      })
      return checkedCompany?.brandLocation || checkedCompany?.brand
    },
    isShowDropdown(){
      return  this.multipleBrands?.length > 1
    }
  },
  methods: {
    getRandom() {
      return uuid.v1()
    },
    changeMultipleBrandView(brand) {

      if (!brand.isCheckViewData) {
        let checkedCompaniesLength = _.filter(this.multipleBrands, (b) => {
          return b.isCheckViewData;
        })?.length
        if (checkedCompaniesLength === 0) {
          brand.isCheckViewData = true;
          return
        }
      }

      this.isLoading = true;
      let siteContent = document.getElementById('site-content');
      let siteContentArea = document.getElementById('site-content-area');
      siteContent.classList.add('loading-company');
      siteContentArea.style.opacity = 0;
      let data = [];
      _.forEach(this.multipleBrands, (b) => {
        if (b.isCheckViewData) {
          data.push(b.id)
        }
      })
      Axios.post(`/ir/user/${this.currentUser.id}`, {checkedViewData: data})
          .then((r) => {
            console.log(r, 'response')
            if (r?.data?.status) {
              this.$store.dispatch('currentUser/loadUserInfo')
              this.$store.dispatch('shortReinstallation').then(() => {
                this.isLoading = false
              })
            } else {
              this.isLoading = false
            }
          })
          .catch(() => {
            this.isLoading = false
          })
          .finally(()=>{
            siteContent.classList.remove('loading-company');
            siteContentArea.style.opacity = 1;
          })
    },
    changeMultipleBrand(brand) {
      if (brand.id == this.currentCompany.id || this.isLoading || this.isLoading2){
        return;
      }
      this.isLoading2 = true
      let siteContent = document.getElementById('site-content');
      let siteContentArea = document.getElementById('site-content-area');
      siteContent.classList.add('loading-company');
      siteContentArea.style.opacity = 0;
      Axios.post(`/ir/auth/change-brand/${brand.id}`)
          .then((r) => {
            if (r?.data?._status) {
              let APIToken = r?.data?.token
              this.$store.dispatch('auth/updateToken', { APIToken })
                  .then(() => {this.$store.dispatch('reinstallationApp')})
                  .then(() => {this.$router.push('/s').catch(err => err)})
                  .then(() => {this.$store.dispatch('initAppAfterLogin').then(() => {
                    this.isLoading2 = false
                  })})
              console.log('token!!!',r?.data?.token)
            } else {
              this.isLoading2 = false
            }
          })
          .catch(() => {
            this.isLoading2 = false
          })
          .finally(()=>{
            siteContent.classList.remove('loading-company');
            siteContentArea.style.opacity = 1;
      })
    }
  },
  mounted() {
    this.multipleBrands = _.cloneDeep(this.getterMultipleBrands);
  },
  watch: {
    getterMultipleBrands: {
      handler(v) {
        this.multipleBrands.splice(0, this.multipleBrands.length);
        _.forEach(_.cloneDeep(v), (b) => {
          this.multipleBrands.push(b);
        })
      },
      deep: true,
    },
  },
  components: {
    Multiselect
  },
}
</script>

<style>
.multiple-brand-toggle-page .b-dropdown .dropdown-toggle.disabled {
  opacity: 1 !important;
}
.multiple-brand-toggle-page .dropdown-menu  {
  margin-top: 6px !important;
}

.multiple-brand-toggle-page .dropdown-options .btn {
  border: none;
}

.multiple-brand-toggle-page .dropdown-options .dropdown-arrow {
  position: static !important;
  border-left: none !important;
}

.multiple-brand-toggle-page .dropdown-options ul.dropdown-menu {
  border: 1px solid #DDDDDD !important;
  box-shadow: none !important;
}

.multiple-brand-toggle-page .dropdown-options .dropdown-item {
  white-space: nowrap !important;
  height: auto !important;
}

.multiple-brand-toggle-page .b-dropdown-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multiple-brand-toggle-page .form-check-input {
  margin-left: 30px;
  padding-left: 0;
}

.multiple-brand-toggle-page .custom-control-input {
  height: 20px;
  width: 20px;
  left: -20px;
  top: 3px;
}

.multiple-brand-toggle-page .dropdown-options .dropdown-menu .dropdown-item:hover {
  background-color: rgba(41,187,193,.1) !important;
  color: #79b1bb !important;
}

.multiple-brand-toggle-page .custom-checkbox .custom-control-label::before {
  display: inline-block;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 2px solid #29bbc1!important;
  background-color: rgba(27,30,56,.03)!important;
}

.multiple-brand-toggle-page .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-top: 2px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMjliYmMxIiBkPSJtOS41NSAxOGwtNS43LTUuN2wxLjQyNS0xLjQyNUw5LjU1IDE1LjE1bDkuMTc1LTkuMTc1TDIwLjE1IDcuNEw5LjU1IDE4WiIvPjwvc3ZnPg==");
  background-size: cover;
}
</style>

<style scoped>
.company-name {
  cursor: pointer;
}
.company-name-list {
  position: absolute;
  border: 1px solid black;

}
.company-name-list-option {
  cursor: pointer;
}
</style>
