<template>
  <div class="parts-order-details pt-2 d-flex" @contextmenu.prevent="$emit('openRowContext', $event)">
    <div class="time-line"></div>
    <div style="width: 300px;">
      <div class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">Provide a Reason {{computedReasonLabel}} : </label>
        <div>
          <b-textarea
              v-model="creditPart.orderPartComment"
              :disabled="true"
              max-rows="10"
              placeholder=""
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>

          <div v-if="creditPart.orderPartCommentDate" class="d-flex" style="margin-top: 10px">
            <label class="adaptive-input__label font-inter--bold mr-0-5">Added on : </label>
            <div>
              <span>{{ formatDateTime(creditPart.orderPartCommentDate) }}</span>
            </div>
          </div>
          <div v-if="creditPart.orderPartCommentByName" class="d-flex">
            <label class="adaptive-input__label font-inter--bold mr-0-5" style="margin-bottom: 15px">Added by : </label>
            <div>
              <span>{{ creditPart.orderPartCommentByName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">Repairer Comment - Reason for Credit : </label>
        <div>
          <b-textarea
              v-model="creditPart.repairerCreditComment"
              :disabled="true"
              max-rows="10"
              placeholder=""
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>
        </div>

        <div v-if="creditPart.repairerCreditComment">
          <div v-if="creditPart && creditPart.repairerSide && creditPart.repairerSide.creditReason && creditPart.repairerSide.creditReason.addedAt" class="d-flex" style="margin-top: 10px">
            <label class="adaptive-input__label font-inter--bold mr-0-5">Added on : </label>
            <div>
              <span>{{ formatDateTime(creditPart.repairerSide.creditReason.addedAt) }}</span>
            </div>
          </div>
          <div v-if="creditPart && creditPart.repairerSide && creditPart.repairerSide.creditReason && creditPart.repairerSide.creditReason.addedBy" class="d-flex">
            <label class="adaptive-input__label font-inter--bold mr-0-5" style="margin-bottom: 15px">Added by : </label>
            <div>
              <span>{{ creditPart.repairerSide.creditReason.addedBy }}</span>
            </div>
          </div>
        </div>
    </div>
      <div v-if="creditPart.preStatus === 'Denied' || creditPart.status === 'Denied'" class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">
          Supplier Reason - <span style="color: red">Deny Credit</span> :
        </label>
        <div  @contextmenu.prevent="openContext($event, creditPart)">
          <b-textarea
              v-model="creditPart.cancelCreditReason"
              :disabled="!creditPart.isEditCredit"
              max-rows="10"
              @input="isNeedSaveInCreditContext = true"
              :ref="'reason-for-credit' + creditPart.id"
              @keydown.enter.prevent="saveReason(true)"
              placeholder=""
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>
        </div>
      </div>
      <div v-if="creditPart.status === 'Cancelled'" class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">Supplier Reason - Cancel Credit request : </label>
        <div @contextmenu.prevent="openContext($event, creditPart)">
          <b-textarea
              v-model="creditPart.cancelCreditReason"
              :disabled="!creditPart.isEditCredit"
              @input="isNeedSaveInCreditContext = true"
              :ref="'reason-for-cancel' + creditPart.id"
              @keydown.enter.prevent="saveReason(false)"
              max-rows="10"
              placeholder=""
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>
        </div>
      </div>

      <div v-if="creditPart.cancelCreditReason || creditPart.cancelCreditOn">
        <div v-if="creditPart.cancelCreditOn" class="d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Added on :</p>
          <p class="mb-0" style="padding-left: 15px">{{ formatDateTime(creditPart.cancelCreditOn) }}</p>
        </div>

        <div v-if="creditPart.cancelCreditBy" class="d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Added by :</p>
          <p class="mb-0" style="padding-left: 12px">{{ creditPart.cancelCreditBy }}</p>
        </div>
      </div>
      <div v-else-if="creditPart.supplierCreditPartCommentByName || creditPart.supplierCreditPartCommentDate">
        <div v-if="creditPart.supplierCreditPartCommentDate" class="d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Added on :</p>
          <p class="mb-0" style="padding-left: 15px">{{ formatDateTime(creditPart.supplierCreditPartCommentDate) }}</p>
        </div>

        <div v-if="creditPart.supplierCreditPartCommentByName" class="d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Added by :</p>
          <p class="mb-0" style="padding-left: 12px">{{ creditPart.supplierCreditPartCommentByName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import * as dayjs from 'dayjs'
import accounting from "accounting";

export default {
  name: 'CreditDetails',
  props: {
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    creditPart: {
      type: Object,
      default () {
        return {
          id: 0,
          amount: '',
          comment:'',
          orderPartComment: '',
          orderPartCommentDate: '',
          orderPartCommentByName: '',
          repairerCreditComment: '',
          supplierCreditPartComment: '',
          supplierCreditPartCommentDate: '',
          supplierCreditPartCommentByName: '',
          status: '',
          subStatus: 'None',
          orderPartStatus: '',
          cancelCreditReason: '',
          cancelCreditOn: null,
          cancelCreditBy: null,
        }
      }
    },
  },
  data () {
    return {
      editCredit: false,
      isDeletedCredit: false,
      isNotSavedChangesInCreditContext: false,
      isNeedSaveInCreditContext: false,
      isNotNeedEditInCreditContext: false,
    }
  },
  computed: {
    computedReasonLabel () {
      if (this.creditPart.orderPartStatus === 'CancelPart') {
        return '- Cancel Part'
      } else if (this.creditPart.orderPartStatus === 'ReturnDamagedPart') {
        return '- Return Damaged Part'
      } else if (this.creditPart.orderPartStatus === 'IncorrectPartDelivered') {
        return '- Incorrect Part'
      } else if (this.creditPart.orderPartStatus === 'IncorrectPartOrdered') {
        return '- Incorrect Part Ordered'
      } else if (this.creditPart.orderPartStatus === 'NoLongerNeeded') {
        return '- No Longer Needed'
      } else if (this.creditPart.orderPartStatus === 'ChangeInRepairMethod') {
        return '- Change in Repair Method'
      }
      return ''
    },
  },
  methods: {
    focusInReasonForCredit() {
      if (!this.creditPart.isEditCredit) {
        this.creditPart.isEditCredit = true;
      }
      if (this.creditPart.status === 'Denied' || this.creditPart.preStatus === 'Denied') {
        this.$refs['reason-for-credit' + this.creditPart.id].focus();
      } else if (this.creditPart.status === 'Cancelled') {
        this.$refs['reason-for-cancel' + this.creditPart.id].focus();
      }
    },
    closeContext () {
      this.$emit('closeContext')
    },
    openContext(event, part) {
      if (this.creditPart.status === 'Cancelled' || this.creditPart.status === 'Denied') {
        part.reason = part.cancelCreditReason;
      } else {
        part.reason = part.comment;
      }
      this.$emit('openContext',
          {
            event: event,
            part: part,
            isNotSavedChanges: this.isNotSavedChangesInCreditContext,
            isNotNeedEdit: this.isNotNeedEditInCreditContext,
            isNeedSave: this.isNeedSaveInCreditContext,
          })
    },
    saveReason (isDenied = false) {
      this.editCredit = false;
      this.isDeletedCredit = false;
      let saveData= {creditPart: this.creditPart, creditPartId: this.creditPart.id}
      this.$emit('auto-save', {saveData: saveData, isDenied: isDenied});
      this.isNotSavedChangesInCreditContext = false;
      this.isNotNeedEditInCreditContext = false;
      this.isNeedSaveInCreditContext = false
      this.closeContext();
    },
    editReason () {
      console.log('edit reason')
      this.editCredit = true
      this.isNotSavedChangesInCreditContext = true
      this.isNotNeedEditInCreditContext = true
      this.closeContext()
      if (!this.creditPart.isEditCredit) {
        this.creditPart.isEditCredit = true;
      }
      setTimeout(() => {
        this.$refs['reason-for-credit' + this.creditPart.id].focus();
      }, 200)
    },
    deleteReason () {
      this.editCredit = false
      this.isDeletedCredit = true
      this.isNotSavedChangesInCreditContext = false
      this.isNotNeedEditInCreditContext = false
      this.isNeedSaveInCreditContext = false
      if (this.creditPart.status === 'Cancelled' || this.creditPart.status === 'Denied') {
        this.creditPart.cancelCreditReason = null
        this.creditPart.cancelCreditOn = null
        this.creditPart.cancelCreditBy = null
      } else {
        this.creditPart.comment = null
      }
      this.creditPart.reason = null
      this.$emit('autoSave', [{ creditId: this.creditPart.id, reason: this.creditPart.reason }]);
      // this.$emit('autoSave', [{ creditId: this.credit.id, partId: this.part.id, credit: null }])

      this.closeContext()
    },
    formatDateTime (val) {
      return dayjs(val).format('DD/MM/YYYY hh:mmA')
    },
  },
  mounted () {},
}
</script>

