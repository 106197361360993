/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {
    SUPPLIER_CURRENT_RFQ_UPDATE,
    SUPPLIER_RFQ_UPDATED,
    SUPPLIER_RFQ_FILTER_UPDATE,
    SUPPLIER_RFQ_SET_VIEWING_USERS,
    SUPPLIER_RFQ_QUOTE_NBRS_UPDATE,
    SUPPLIER_RFQ_QUOTE_NBRS_CLEAR,
    SUPPLIER_RFQ_REINSTALLATION, SUPPLIER_RFQ_SHORT_UPDATING
} from '../../../mutation-types';

export function reinstallation(context) {
  context.commit(SUPPLIER_RFQ_REINSTALLATION)
}

export function getRFQById(context, rfqId) {
    return new Promise((resolve, reject) => {
        Axios.get('/ir/supplier/rfq/' + rfqId)
            .then((response) => {
                context.commit(SUPPLIER_CURRENT_RFQ_UPDATE, response.data.rfq);
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getQuoteNbrs(context, payload = 0) {
    let url = '/ir/supplier/rfq/get-email-pdf-quote-numbers';
    if (!_.isEmpty(payload)) {
        url = '/ir/supplier/rfq/get-email-pdf-quote-numbers/' + payload;
    }
    Axios.get(url)
        .then(response => {
            if (response?.data?._status) {
                if (_.isEmpty(payload)) {
                    context.commit(SUPPLIER_RFQ_QUOTE_NBRS_CLEAR);
                }
                context.commit(SUPPLIER_RFQ_QUOTE_NBRS_UPDATE, response.data);
            }
        })
}

// export function getRFQByIds(context, payload) {
//     Axios.post('/ir/supplier/rfqs', {ids: payload})
//         .then(response => {
//             context.commit(SUPPLIER_RFQ_UPDATED, response.data.rfqs);
//         })
//         .catch(error => {
//             console.log(error);
//         });
// }

export function setFilter(context, filter) {
    context.commit(SUPPLIER_RFQ_FILTER_UPDATE, filter);
}

export function getRFQsForTableView(context, payload= 0) {
    let url = '/ir/supplier/rfqs/init';
    if(!_.isEmpty(payload)) url = '/ir/supplier/rfqs/'+payload;
    Axios.get(url)
        .then(response => {
          if (response.data._status) {
            context.commit(SUPPLIER_RFQ_UPDATED, response.data.rfqs);
            context.commit(SUPPLIER_RFQ_SET_VIEWING_USERS, context.state.rfqToSupplierCurrentlyViewing);
            if (response.data.rfqs.length > 0) {
              if (!_.isEmpty(context.state.currentRfq) && _.some(response.data.rfqs, (rfq) => {
                return (Number(rfq.id) === Number(context.state.currentRfq.id))
              })) {
                  if (!_.isEmpty(payload)) {
                      console.log('payload', payload)
                      getRFQById(context, context.state.currentRfq.id).catch(e => {
                          console.log(e)
                      })
                  }
              }
            }
          }
            // let ids = [];
            // if (response.data.New) {
            //     context.commit(SUPPLIER_RFQ_IDS_UPDATE_NEW, response.data.New);
            //     ids=_.concat(ids, response.data.New);
            // }
            // if (response.data.Priced) {
            //     context.commit(SUPPLIER_RFQ_IDS_UPDATE_PRICED, response.data.Priced);
            //     ids=_.concat(ids, response.data.Priced);
            // }
            // if (response.data.Lost) {
            //     context.commit(SUPPLIER_RFQ_IDS_UPDATE_LOST, response.data.Lost);
            //     ids=_.concat(ids, response.data.Lost);
            // }
            // if (response.data.Won) {
            //     context.commit(SUPPLIER_RFQ_IDS_UPDATE_WON, response.data.Won);
            //     ids=_.concat(ids, response.data.Won);
            // }
            // if (response.data.Closed) {
            //     context.commit(SUPPLIER_RFQ_IDS_UPDATE_CLOSED, response.data.Closed);
            //     ids=_.concat(ids, response.data.Closed);
            // }
            // if (ids.length > 0) {
            //     if(!_.isEmpty(context.state.currentRfq) && ids.includes(String(context.state.currentRfq.id))){
            //         getRFQById(context, context.state.currentRfq.id).catch(e => {
            //             console.log(e);
            //         });
            //     }
            //     getRFQByIds(context, ids);
            // }
        })
        .catch(error => {
            console.log(error);
        });
}

export function init(context, payload = 0) {
    getQuoteNbrs(context, payload);
    return getRFQsForTableView(context, payload);
}

export function shortUpdating(context, payload = 0) {
    getQuoteNbrs(context, 0);
    return updatingRfqs(context, payload);
}

export function updatingRfqs(context, payload= 0) {
    let url = '/ir/supplier/rfqs/init';
    if(!_.isEmpty(payload)) url = '/ir/supplier/rfqs/'+payload;
    Axios.get(url)
        .then(response => {
            if (response.data._status) {
                context.commit(SUPPLIER_RFQ_SHORT_UPDATING, response.data.rfqs);
                context.commit(SUPPLIER_RFQ_SET_VIEWING_USERS, context.state.rfqToSupplierCurrentlyViewing);
                if (response.data.rfqs.length > 0) {
                    if (!_.isEmpty(context.state.currentRfq) && _.some(response.data.rfqs, (rfq) => {
                        return (Number(rfq.id) === Number(context.state.currentRfq.id))
                    })) {
                        getRFQById(context, context.state.currentRfq.id).catch(e => {
                            console.log(e)
                        })
                    }
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
}

