<template>
    <div :class="className" v-show="isActive">
    <section
             :aria-hidden="! isActive"
             class="tab-pane tabs-component-panel"
             :class="{'active': isActive}"
             :id="hash"
             role="tabpanel"
    >
        <slot />
    </section>
    </div>
</template>

<script>
    export default {
        props: {
            id: { default: null },
            name: { required: true },
            prefix: { default: '' },
            suffix: { default: '' },
            isDisabled:{ default: false },
            className: {default: 'tab-content'},
            order: {
              type: Number,
              default: 0,
            },
            isShow: {
              type: Boolean,
              default: true,
              required: false,
            },
        },

        data: () => ({
            isActive: false,
            isVisible: true,
            isTab: true,
        }),
        computed: {
            header() {
                return this.prefix + this.name + this.suffix;
            },

            computedId() {
                return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
            },

            hash() {
                if (this.isDisabled) {
                    return '#';
                }

                return '#' + this.computedId;
            },
        },
    };
</script>
