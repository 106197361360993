<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <router-link to="/login" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></router-link>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="done" style="cursor: pointer" @click="$router.push({path: '/register/supplier/find-business'})">
                                    <span class="numb">
                                        <i class="bx bxs-check-circle" style="color:#29bbc1"></i>
                                    </span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line active"></li>
                                <li class="active">
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>
                            Add your Parts warehouse location/s for repairers
                            <br />to find & order from
                        </h1>
                        <br />
                        <form method="POST" @submit.prevent="login">
                            <transition-group tag="div" class="slides" name="slide-fade">
                                <div class="location-slide" :key="item" v-for="item in locations">
                                    <div class="slide-container">
                                        <div class="form-group">
                                            <label
                                                for="locationname"
                                            >{{item}} Warehouse Location Name</label>
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                class="form-control input-field"
                                                name="locationname"
                                                placeholder="Warehouse Name"
                                                v-model="currentLocation.name"
                                            />
                                            <div class="w-100 text-right" v-show="false">
                                                <span class="error-text">
                                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                          <label for="address">Workshop Location</label>
                                          <google-location
                                              v-if="!currentLocation.sameAsBusinessAddress"
                                              type="text"
                                              v-model="currentLocation.address"
                                              id="location_my_account"
                                              placeholder="Search Address"
                                              class="register-google-location"
                                              @onPlaceChange="addressChanged"
                                          ></google-location>
                                          <div v-else class="">
                                            <i class="bx bx-map google-location-map-icon"></i>
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                class="form-control input-field"
                                                placeholder="Primary Business Address"
                                                v-model="currentLocation.address"
                                                :disabled="currentLocation.sameAsBusinessAddress"
                                            />
                                          </div>
                                          <div class="w-100 text-right" v-show="false">
                                            <span class="error-text">
                                                <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                            </span>
                                          </div>
                                          <div class="w-100 text-right" v-show="false">
                                            <span class="error-text">
                                                <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                            </span>
                                          </div>
                                        </div>

                                        <div class="form-group">
                                          <label for="fax">City/Suburb</label>
                                          <input
                                              autocomplete="off"
                                              type="text"
                                              class="form-control input-field"
                                              name="location"
                                              v-model="currentLocation.suburb"
                                              :disabled="currentLocation.sameAsBusinessAddress"
                                              placeholder="Suburb"
                                          />
                                        </div>

                                        <div class="form-group">
                                          <label for="State">State</label>
                                          <multiselect
                                              :options="['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA',]"
                                              :showLabels="false"
                                              :max-height="203"
                                              :close-on-select="true"
                                              placeholder="Select state"
                                              v-model="currentLocation.state"
                                              :disabled="currentLocation.sameAsBusinessAddress"
                                          />
                                        </div>

                                        <div class="form-group">
                                          <label for="postcode">Postcode</label>
                                          <input
                                              autocomplete="off"
                                              type="text"
                                              class="form-control input-field post-code"
                                              name="businessnumber"
                                              placeholder="Postcode"
                                              :disabled="currentLocation.sameAsBusinessAddress"
                                              v-model="currentLocation.postcode"
                                          />
                                          <div class="w-100 text-right row justify-content-end">
                                            <label class="input-checkbox">
                                              <input
                                                  name="remember"
                                                  type="checkbox"
                                                  aria-label="Same as registered business address"
                                                  v-model="currentLocation.sameAsBusinessAddress"
                                                  @change="changeSameAsBusinessAddress"
                                              />
                                              <div class="checkmark"></div>Same as registered business address
                                            </label>
                                          </div>
                                        </div>

                                        <div class="form-group">
                                              <label for="phonenumber">Warehouse Phone Number</label>
                                              <input
                                                  autocomplete="off"
                                                  type="text"
                                                  class="form-control input-field"
                                                  name="phonenumber"
                                                  placeholder="Phone Number"
                                                  v-model="currentLocation.phone"
                                              />
                                              <div class="w-100 text-right" v-show="false">
                                                  <span class="error-text">
                                                      <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                                  </span>
                                              </div>
                                              <div class="w-100 text-right row justify-content-end">
                                                  <label class="input-checkbox">
                                                      <input
                                                          name="remember"
                                                          type="checkbox"
                                                          aria-label="Same as registered business address"
                                                          @change="changeSameAsPrimaryBusinessPhone"
                                                          v-model="currentLocation.sameAsBusinessPhone"
                                                      />
                                                      <div class="checkmark"></div>Same as primary business phone
                                                  </label>
                                              </div>
                                          </div>
                                        <div class="form-group">
                                            <label for="openhours">Opening Hour - Mon to Fri</label>
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                class="form-control input-field"
                                                name="openhours"
                                                placeholder="Opening Hour"
                                                v-model="currentLocation.openingHour"
                                            />
                                            <div class="w-100 text-right" v-show="false">
                                                <span class="error-text">
                                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="closehours">Closing Hour - Mon to Fri</label>
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                class="form-control input-field"
                                                name="closehours"
                                                placeholder="Closing Hour"
                                                v-model="currentLocation.closingHour"
                                            />
                                            <div class="w-100 text-right" v-show="false">
                                                <span class="error-text">
                                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="State">Delivery</label>
                                            <multiselect
                                                :options="getOptionsDelivery"
                                                :showLabels="false"
                                                :max-height="203"
                                                :close-on-select="true"
                                                placeholder="Select Delivery Type"
                                                v-model="currentLocation.delivery"
                                            />
                                            <div class="w-100 text-right" v-show="false">
                                                <span class="error-text">
                                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                                </span>
                                            </div>
                                            <div class="w-100 text-right row justify-content-end">
                                                <button
                                                    @click="addNewLocation"
                                                    class="another-location"
                                                    v-bind:class="{ disabled: !nextAllowed}"
                                                >
                                                    <i class="bx bx-plus" style="color:#29BBC1;"></i>&nbsp;Add Another Warehouse Location
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn input-btn"
                                    :disabled="!nextAllowed"
                                    @click="register"
                                >Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
.form .post-code {
  width: 109px;
}

form {
    max-width: 522px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 903px 48px;
}
@media (min-width:321px) {
  form {
    grid-template-rows: 903px 48px;
  }
}
.form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.form h1 {
    text-align: center;
    margin-bottom: 0px;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 20px;
}
.form .post-code {
    width: 109px;
}
.form .another-location {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    padding: 0;
    color: rgb(41, 187, 193);
}
.form .another-location i {
    font-size: 23px;
    margin-right: 5px;
    line-height: 0;
}
.form .input-checkbox {
    margin-top: -5px;
}

.form .another-location.disabled i,
.form .another-location.disabled {
    pointer-events: none;
    color: #ccc !important;
}
.form .another-location i {
    font-size: 23px;
    margin-right: 5px;
    line-height: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 1s;
}

.slide-fade-enter {
    transform: translateX(100%);
    opacity: 1;
}

.slide-fade-leave-to {
    transform: translateX(-100%);
    transition: all 0.5s;
    opacity: 0;
}

.slide-container {
    flex-shrink: 0;
}

.location-slide {
    width: 100%;
    max-width: 522px;
    position: absolute;
    flex-shrink: 0;
    flex-basis: 100%;
    margin-bottom: 50px;
}

.slides {
    display: flex;
}
</style>

<script>
import Multiselect from "vue-multiselect";
import GoogleLocation from "../../../../components/utility/google-location";

export default {
    name: "InviteRepairerAddWorkshoplocation",
    components: {
        multiselect: Multiselect,
        GoogleLocation,
    },
    computed: {
        getOptions() {
            return ["Australian Public Company", "Australian Private Company"];
        },
        getOptionsCity() {
            return ["LAKEMBA", "Sydney"];
        },
        getOptionsState() {
            return ["NSW", "Western Australia"];
        },
        getOptionsDelivery() {
            return ["Road"];
        },
        nextAllowed(){
          return !!(
            this.currentLocation.name
            && (this.currentLocation.address || this.currentLocation.sameAsBusinessAddress)
            && (this.currentLocation.phone || this.currentLocation.sameAsBusinessPhone)
            && (this.currentLocation.suburb || this.currentLocation.sameAsBusinessAddress)
            && (this.currentLocation.state || this.currentLocation.sameAsBusinessAddress)
            && (this.currentLocation.postcode || this.currentLocation.sameAsBusinessAddress)
            && this.currentLocation.openingHour
            && this.currentLocation.closingHour
            && this.currentLocation.delivery
          );
        }
    },
    data() {
        return {
            locationName: "",
            location: "",
            locations: [1],
            currentSlideIndex: 0,
            locationsData: [],
            currentLocation: {
              name: '',
              address: '',
              sameAsBusinessAddress: false,
              phone: '',
              sameAsBusinessPhone: false,
              openingHour: '',
              closingHour: '',
              delivery: '',
            },
            registrationData: {},
        };
    },
    mounted() {
      if(localStorage.getItem('registrationData')){
        try {
          this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
        } catch (e) {
          localStorage.removeItem('registrationData');
        }
      }
      this.locationsData.push(this.currentLocation)
      console.log(this.currentLocation)
      this.scrollToTop()
    },
    methods: {
        changeSameAsBusinessAddress() {
          if (this.currentLocation.sameAsBusinessAddress) {
            this.currentLocation.address = this.registrationData.business.address;
            this.currentLocation.suburb = this.registrationData.business.city;
            this.currentLocation.state = this.registrationData.business.state;
            this.currentLocation.postcode = this.registrationData.business.postcode;
          }
        },
        addressChanged (addr) {
          this.currentLocation.address = addr["address"];
          this.currentLocation.suburb = addr["suburb"];
          this.currentLocation.state = addr["state"];
          this.currentLocation.postcode = addr["postcode"];
        },
        changeSameAsPrimaryBusinessPhone() {
          if (this.currentLocation.sameAsBusinessPhone) {
            this.currentLocation.phone = this.registrationData.businessForRepairers.phone;
          }
        },
        register(){

          let locations = [];

          this.locationsData.forEach(location => {
            if((location.address || location.sameAsBusinessAddress) && location.name){
              locations.push(location);
            }
          });

          if(locations.length === 0){
            return;
          }

          this.registrationData.locations = locations;

          localStorage.registrationData = JSON.stringify(this.registrationData);

          this.$router.push({name: 'SupplierAddPersonalUserAccount'});
        },
        addNewLocation() {
            if (!this.nextAllowed) {
              return;
            }
            this.locations.splice(0, 1);
            this.currentSlideIndex++;
            this.locations.push(this.currentSlideIndex);
            this.currentLocation = {
              name: '',
              address: '',
              sameAsBusinessAddress: false,
              phone: '',
              sameAsBusinessPhone: false,
              openingHour: '',
              closingHour: '',
              delivery: '',
          }
          this.locationsData.push(this.currentLocation);
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
</script>
