<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <router-link to="/login" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></router-link>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="done" style="cursor: pointer" @click="$router.push({path: '/register/supplier/find-business'})">
                                    <span class="numb">
                                        <i class="bx bxs-check-circle" style="color:#29bbc1"></i>
                                    </span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line active"></li>
                                <li class="active">
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>
                            Create your business profile for Repairers
                            <br />to find you in PartsSearch
                        </h1>
                        <br />
                        <form method="POST" @submit.prevent="login">
                            <div class="form-group">
                                <label for="phonenumber">Primary Parts Dept Phone Number</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="phonenumber"
                                    placeholder="Primary Phone Number"
                                    @keypress="onlyNumber"
                                    v-model="phone"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="fax">Primary Parts Dept Fax Number</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="fax"
                                    placeholder="Primary Fax Number"
                                    v-model="fax"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email">Primary Parts Dept Email Address</label>
                                <input
                                    autocomplete="off"
                                    type="email"
                                    class="form-control input-field"
                                    name="email"
                                    @blur="checkEmail"
                                    @focus="isCheckEmail = true;"
                                    placeholder="Primary Email Address"
                                    v-model="email"
                                />
                                <div class="w-100 text-right" v-show="!isCheckEmail && email !== ''">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is incorrect
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="address">Primary Parts Dept Business Address</label>
                                <google-location
                                    v-if="!sameAsBusinessAddress"
                                    type="text"
                                    v-model="address"
                                    id="location_my_account"
                                    placeholder="Search Address"
                                    class="register-google-location"
                                    @onPlaceChange="addressChanged"
                                ></google-location>
                                <div v-else class="">
                                  <i class="bx bx-map google-location-map-icon"></i>
                                  <input
                                      autocomplete="off"
                                      type="text"
                                      class="form-control input-field"
                                      placeholder="Primary Business Address"
                                      v-model="address"
                                      :disabled="sameAsBusinessAddress"
                                  />
                                </div>
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Suburb">Suburb</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="Suburb"
                                    placeholder="Suburb"
                                    v-model="suburb"
                                    :disabled="sameAsBusinessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="State">State</label>
                                <multiselect
                                    :options="getOptionsState"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select State"
                                    v-model="state"
                                    :disabled="sameAsBusinessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="postcode">Postcode</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field post-code"
                                    name="businessnumber"
                                    placeholder="Postcode"
                                    v-model="postcode"
                                    :disabled="sameAsBusinessAddress"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="w-100 text-right row justify-content-end">
                                <label class="input-checkbox">
                                    <input
                                        name="remember"
                                        type="checkbox"
                                        aria-label="Same as registered business address"
                                        @change="checkboxChange"
                                        v-model="sameAsBusinessAddress"
                                    />
                                    <div class="checkmark"></div>Same as registered business address
                                </label>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn input-btn"
                                    :disabled="!nextAllowed"
                                    @click="register"
                                >Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
form {
    max-width: 522px;
    margin: 0 auto;
}
.form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.form h1 {
    text-align: center;
    margin-bottom: 0;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 23px;
}
.form .post-code {
    width: 109px;
}
</style>

<script>
import Multiselect from "vue-multiselect";
import GoogleLocation from "../../../../components/utility/google-location";
export default {
    name: "InviteRepairerCreateBusinessProfile",
    components: {
        multiselect: Multiselect,
        GoogleLocation,
    },
    data: function (){
      return {
        phone: '',
        fax: '',
        email: '',
        address: '',
        suburb: '',
        state: '',
        postcode: '',
        sameAsBusinessAddress: false,
        registrationData: {},
        isCheckEmail: true,
      };
    },
    mounted() {
      if(localStorage.getItem('registrationData')){
        try {
          this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
        } catch (e) {
          localStorage.removeItem('registrationData');
        }
      }
      if(localStorage.getItem('supplierInvite')){
        let invite = JSON.parse(localStorage.getItem('supplierInvite'))
        this.email = invite.email;
        this.phone = invite.phone;
      }
      this.scrollToTop()
    },
    methods: {
      checkboxChange() {
        if (this.sameAsBusinessAddress) {
          this.address = this.registrationData.business.address;
          this.suburb = this.registrationData.business.city;
          this.state = this.registrationData.business.state;
          this.postcode = this.registrationData.business.postcode;
        }
      },
      addressChanged (addr) {
        this.address = addr["address"];
        this.suburb = addr["suburb"];
        this.state = addr["state"];
        this.postcode = addr["postcode"];
      },
      checkEmail() {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.isCheckEmail = re.test(this.email);
      },
      onlyNumber (event) {
        let keyCode = (event.keyCode ? event.keyCode : event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 32 && keyCode !== 45 && keyCode !== 43 && keyCode !== 40 && keyCode !== 41) {
          event.preventDefault();
        }
      },
      register: function(){
        let businessForRepairers = {};

        businessForRepairers.phone = this.phone;
        businessForRepairers.fax = this.fax;
        businessForRepairers.email = this.email;
        businessForRepairers.address = this.address;
        businessForRepairers.suburb = this.suburb;
        businessForRepairers.state = this.state;
        businessForRepairers.postcode = this.postcode;
        businessForRepairers.sameAsBusinessAddress = this.sameAsBusinessAddress;

        if(this.sameAsBusinessAddress){
          businessForRepairers.address = this.registrationData.business.address;
          businessForRepairers.suburb = this.registrationData.business.city;
          businessForRepairers.state = this.registrationData.business.state;
          businessForRepairers.postcode = this.registrationData.business.postcode;
        }

        this.registrationData.businessForRepairers = businessForRepairers;

        localStorage.registrationData = JSON.stringify(this.registrationData);

        this.$router.push({name: 'SupplierSelectBrands'});
      },
      scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
     },
    },
    computed: {
        getOptions() {
            return ["Australian Public Company", "Australian Private Company"];
        },
        getOptionsCity() {
            return ["LAKEMBA", "Sydney"];
        },
        getOptionsState() {
            return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA',];
        },
        nextAllowed(){
            return !!(
                this.phone
                && this.fax
                && this.email
                && (
                    this.sameAsBusinessAddress
                    || (this.address
                      && this.suburb
                      && this.state
                      && this.postcode)
                )
            );
        }
    }
};
</script>
