<script>
import Axios from 'axios';

import Editor from 'vue-image-markup';

export default {
  name: 'image-editor',
  components: {
    Editor,
  },
  props: [
    'imageUrl',
    'imageId',
    'rfqId'
  ],
  data: () => {
    return {
      canvasWidth: 800,
      canvasHeight: 600,
      isCrop: true,
      selectedColor: '#ff1ad7',
      isSaving: false,
      isSelectedDraw: false,
      isSelectedCircle: false,
      isSelectedRectangle: false,
      isSelectedArrow: false,
      isSelectedText: false,
      isCalculatedSize: false,
    }
  },
  methods: {
    setCurrentAction(type) {
      if (type === 'draw') {
        this.isSelectedDraw = true;
        this.isSelectedCircle = false;
        this.isSelectedRectangle = false;
        this.isSelectedArrow = false;
        this.isSelectedText = false;
      } else if (type === 'circle') {
        this.isSelectedDraw = false;
        this.isSelectedCircle = true;
        this.isSelectedRectangle = false;
        this.isSelectedArrow = false;
        this.isSelectedText = false;
      } else if (type === 'rectangle') {
        this.isSelectedDraw = false;
        this.isSelectedCircle = false;
        this.isSelectedRectangle = true;
        this.isSelectedArrow = false;
        this.isSelectedText = false;
      } else if (type === 'arrow') {
        this.isSelectedDraw = false;
        this.isSelectedCircle = false;
        this.isSelectedRectangle = false;
        this.isSelectedArrow = true;
        this.isSelectedText = false;
      } else if (type === 'text') {
        this.isSelectedDraw = false;
        this.isSelectedCircle = false;
        this.isSelectedRectangle = false;
        this.isSelectedArrow = false;
        this.isSelectedText = true;
      }
    },
    setColorForCurrentAction() {
      if (this.isSelectedDraw) {
        this.freeDrawing();
      } else if (this.isSelectedCircle) {
        this.addCircle();
      } else if (this.isSelectedRectangle) {
        this.addSquare()
      } else if (this.isSelectedArrow) {
        this.addArrow();
      } else if (this.isSelectedText) {
        toastr.success('Color changed successfully!\n' +
            'Please choose another icon and draw something and then you can undo it with the back icon.\n' +
            '  Then go back to the text icon again to update the color');
      }
    },
    undo() {
      this.$refs.editor.undo();
    },
    redo() {
      this.$refs.editor.redo();
    },
    deleteEditable() {
      this.$refs.editor.clear();
      this.$refs.editor.setBackgroundImage(this.imageUrl);
    },
    freeDrawing() {
      let customizeFreeDrawing = { stroke: this.selectedColor};
      this.$refs.editor.set("freeDrawing", customizeFreeDrawing);
      this.setCurrentAction('draw');
    },
    addText() {
      if (this.isSelectedText) {
        return;
      }
      let textModeOptions = {
        id: 'title' + Math.random(),
        fill: this.selectedColor,
        fontFamily: 'Verdana',
        fontSize: 20,
        placeholder: 'Add text'
      }

      this.$refs.editor.set("text", textModeOptions);
      this.setCurrentAction('text');
    },
    addCircle() {
      let circleModeParams = {  stroke: this.selectedColor };
      this.$refs.editor.set("circle", circleModeParams);
      this.setCurrentAction('circle');
    },
    addSquare() {
      let customizeRectangle = {
        stroke: this.selectedColor,
      };
      this.$refs.editor.set("rect", customizeRectangle);
      this.setCurrentAction('rectangle');
    },
    addArrow() {
      let customizeArrow = { stroke: this.selectedColor};
      this.$refs.editor.set("arrow", customizeArrow);
      this.setCurrentAction('arrow');
    },
    crop() {
      let cropModeOptions = {
        width: "500",
        height: "300",
        overlayOpacity: "0.9"
      };
      this.$refs.editor.set("crop", cropModeOptions);
      this.isCrop = false;
    },
    applyCrop() {
      this.isCrop = true;
      this.$refs.editor.applyCropping();
      this.isCrop = true;
    },
    clickColor(color) {
      this.selectedColor = color;
      this.setColorForCurrentAction();
    },
    onClickSave: function () {
      if (this.isSaving) {
        return
      }

      this.isSaving = true
      Axios({
        method: 'post',
        responseType: 'json',
        url: '/ir/supplier/rfq/replace-scheme',
        data: {
          fileId: this.imageId,
          file: this.$refs.editor.saveImage(),
          rfqId: this.rfqId,
        }
      })
          .then(response => {
            this.isSaving = false
            if (response.status === 200 && response.data._status) {
              this.$emit('saved-image')
              this.$emit('closeEditMode')
              this.$toast.success('The image was saved!')
            } else {
              this.$toast.error(response.data.msg)
            }
          })
          .catch(error => {
            this.isSaving = false
            this.$toast.error(error)
          })
    },
  },
  mounted: function () {
    let interval = setInterval(() => {
      let img = document.getElementById('img1')
      let wrapperSize = document.getElementById('wrapperSize')
      // let editorColumn = document.getElementById('imageEditorColumn')
      // let editorContent = document.getElementById('imageEditorContent')
      if (img.clientHeight > 0 && img.clientWidth > 0) {
        this.canvasWidth = wrapperSize.clientWidth - 50
        this.canvasHeight = img.clientHeight / (img.clientWidth / this.canvasWidth)
        this.isCalculatedSize = true;
        setTimeout(() => {
          this.$refs.editor.setBackgroundImage(this.imageUrl);
          // console.log(editorColumn, editorColumn.clientHeight, this.canvasHeight, editorContent, 'mounted')
          // if (editorColumn?.clientHeight - this.canvasHeight > 0 && this.canvasHeight > 1000) {
          //   editorContent.style.transform = 'translateY(' + ( -((editorColumn?.clientHeight - this.canvasHeight + 25))) + 'px)';
          //   editorContent.style.height = editorColumn.clientHeight + (editorColumn?.clientHeight - this.canvasHeight + 25) + 'px';
          // }
        }, 10)
        clearInterval(interval)
      }
    }, 10)
  },
}
</script>

<template>
  <div class="w-100" :style="{width: this.canvasWidth + 'px !important', height: this.canvasHeight + 'px !important'}">
    <div class="image-editor-editor-menu">
      <div class="color-item cursor-ptr"  :style="{backgroundColor: this.selectedColor}"></div>
      <div @click="undo" class=""><i class='bx bx-undo cursor-ptr'></i></div>
      <div @click="redo" class=""><i class='bx bx-redo cursor-ptr'></i></div>
      <div @click="deleteEditable" class=""><i class='bx bxs-trash cursor-ptr'></i></div>
      <div @click="freeDrawing" class=""><i class='bx bxs-pencil cursor-ptr'></i></div>
      <div @click="addText" class=""><i class='bx bx-font cursor-ptr'></i></div>
      <div @click="addCircle" class=""><i class='bx bx-circle cursor-ptr'></i></div>
      <div @click="addSquare" class=""><i class='bx bx-rectangle cursor-ptr'></i></div>
      <div @click="addArrow" class=""><i class='bx bxs-down-arrow-alt cursor-ptr' ></i></div>
      <div v-if="isCrop" @click="crop()"><i class='bx bxs-crop cursor-ptr'></i></div>
      <div v-else @click="applyCrop()"><i class='bx bx-check-circle cursor-ptr'></i></div>
    </div>

    <div class="d-flex" style="justify-content: center">
      <Editor v-if="isCalculatedSize" :canvasWidth="canvasWidth * 0.8" :canvasHeight="canvasHeight * 0.8" ref="editor"/>

      <div class="image-editor-color-menu">
        <div @click="clickColor('#fe0000')" style="background-color: #fe0000" class="color-item"></div>
        <div @click="clickColor('#ebeb3f')" style="background-color: #ebeb3f" class="color-item"></div>
        <div @click="clickColor('#bc2ce6')" style="background-color: #bc2ce6" class="color-item"></div>
        <div @click="clickColor('#2ec634')" style="background-color: #2ec634" class="color-item"></div>
        <div @click="clickColor('#00baea')" style="background-color: #00baea" class="color-item"></div>
        <div @click="clickColor('#ff1ad7')" style="background-color: #ff1ad7" class="color-item"></div>
        <div @click="clickColor('#1216a5')" style="background-color: #1216a5" class="color-item"></div>
        <div @click="clickColor('#000000')" style="background-color: #000000" class="color-item"></div>
      </div>
    </div>

    <div class="d-flex mt-3 w-100 justify-content-end">
      <button class="btn btn-primary mx-0 waves-effect waves-light sec_btn" :disabled="isSaving" @click="onClickSave">
        {{isSaving ? 'Saving ...' : 'Save'}}
      </button>
    </div>

    <img id="img1"  style="visibility: hidden;" :src="imageUrl">

  </div>
</template>

<style scoped>
.color-item {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.image-editor-editor-menu {
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 20px;
}

.image-editor-color-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
</style>
