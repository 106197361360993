import moment from "moment";

export default class Order{

    /**
     * Order class constructor
     */
    constructor() {
        let date = moment();

        this.datetime = {
            date: date.format('DD/MM/YYYY'),
            time: date.format('LT')
        };
        this.nextAvailableRun = false;
        this.location = {
            address: '',
           // street: '', TODO checkо сделаю тоже сейчас
            unit_level_lot: '',
            suburb: '',
            postcode: '',
            state: '',
            country: ''
        }
        this.instruction = '';
        this.paymentTerms = {};
        this.rfqId= 0;
        this.orderNumbers = {};
    }

    /**
     * Check is order Valid
     * @return {boolean}
     */
    isValid(){
        let result = true;
        if(( !this.nextAvailableRun && (_.isEmpty(this.datetime.date) || _.isEmpty(this.datetime.time))) || _.isEmpty(this.paymentTerms)) result = false;
        _.forEach(this.location, (item, key) => {
            if(_.isEmpty(item) && key!=="unit_level_lot") {
                result = false;
            }
        });
        return result;
    }

    /**
     * Set payment terms
     * @param supplierId
     * @param term
     */
    setPaymentTerms(supplierId = 0, term = 'credit'){
        this.paymentTerms[supplierId] = term;
    }

    /**
     * Set order number
     * @param orderId
     * @param number
     */
    setOrderNumber(orderId = 0, number = ''){
        this.orderNumbers[orderId] = number;
    }


    /**
     * Set rfq id
     * @param id
     */
    setRfqId(id){
        this.rfqId=id;
    }

    /**
     * Set location
     * @param location
     */
    setLocation(location = {
        address: '',
        street: '',
        unit_level_lot: '',
        suburb: '',
        postcode: '',
        state: '',
        country: 'AU'
    }){
        let country = location.country
        if (_.isEmpty(country)){
            country = 'AU';
        }
        this.location = {
            address: location.address,
            //street: location.street,
            unit_level_lot: this.location.unit_level_lot,
            suburb: location.suburb,
            postcode: location.postcode,
            state: location.state,
            country: country
        }
    }
}
