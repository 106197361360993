/*eslint-disable */

export function isStoresCategory(state) {
    return state.isStoresCategory;
}

export function isStoresCategory2(state) {
    return state.isStoresCategory2;
}

export function selectedStore(state) {
    return state.selectedStore;
}
