//TODO разделить на 4 файла router (default, repairer, supplier, demo)
/*eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import demoRoutes from './demo.routes';
import defaultRoutes from './default.routes';
import repairerRoutes from "./repairer.routes";
import supplierRoutes from "./supplier.routes";

Vue.use(VueRouter);

const routes = [
    ...defaultRoutes,
    ...demoRoutes,
    ...repairerRoutes,
    ...supplierRoutes
]

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;