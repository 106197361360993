<template>
  <!-- eslint-disable -->
  <div class="purchase-price-selector-page">
    <tabs ref="settings-rfq-tabs" cache-lifetime="0"
          :options="{ useUrlFragment: false, defaultTabHash: computedDefaultRFQHash }" @changed="onRFQTabChange">
      <tab name="Table Display" id="tableDisplay" ref="tableDisplay" class-name="tab-content-table">
        <div class="settings-repairer-rfq-page row">
          <div class="col-md-6">
            <div class="group-title mt-3">
              Pin Image to Quote List
            </div>
            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Pin Vehicle Image to Quote List</span> <br/>
                <p class="notifications-info-text">This adds a column in the quotes list table to allow you to pin an
                  image of the vehicle with the Quote in the list.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      v-model="settings.rfq.tableDisplayPinnedImages.active"
                      @change="updateSettings"
                      name="check-button"
                      switch
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.tableDisplayPinnedImages.active" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>

      <tab name="Resale Price Selector" id="purchasePriceSelector" class-name="tab-content-table">
        <div class="purchase-price-selector row">
          <div class="col-md-6">
            <div class="group-title" id="purchaseTitle">
              Configurator
            </div>
            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Enable Resale Price Selector</span> <br/>
                <p class="notifications-info-text">Enable the resale price selector to run in alignment with pricing
                  Matrix on Parts Quotes.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      v-model="settings.rfq.enablePurchasePriceSelector"
                      @change="updateSettings"
                      :disabled="!isAdministrator"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.enablePurchasePriceSelector" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
              <div class="block-line"></div>
              <div class="row w-100 align-items-center mb-3">
                <div class="col-6">
                  <b-form-input v-model="ruleSearch" autocomplete="one-time-code" @input="selectedIndex = -1"
                                :disabled="!isAdministrator || isShowErrorName" class="form-control"
                                placeholder="Search"></b-form-input>
                </div>
                <div class="col-6 add-new-rule-btn d-flex justify-content-center" @click="addNewRule">
                  + Add New Rule
                </div>
              </div>
              <div v-for="(item, index) in priceSelectors" :key="item.id" v-if="isShowRule(item, index)"
                   class="col-12 mb-3 purchase-price-selector-client-list">
                <div @click="toggleClientInformation(index)" style="cursor: pointer" class="client-block">
                  <span>{{ item.name }}</span>
                  <i v-if="selectedIndex !== index" class='bx bx-down-arrow'></i>
                  <i v-else class='bx bx-up-arrow'></i>
                </div>
                <div v-if="selectedIndex === index" class="client-information mt-3">
                  <div class="form-group row">
                    <label class="col col-form-label">Name</label>
                    <div class="col-6 pr-0">
                      <input @input="checkName(item.name)" @change="orderPurchaces(index)"
                             @focus="setIsEdit(true, index)" @blur="setIsEdit(false, index)"
                             :disabled="!isAdministrator" v-model="item.name" type="text" class="form-control"
                             :class="{ 'border-for-name': isDefaultNameBorder}">
                      <span class="error-text" v-if="isShowErrorName">
                        This name is already in use
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col col-form-label">Category</label>
                    <div class="col-6 pr-0">
                      <multiselect
                          v-model="item.category"
                          :options="clientCategoryOptions"
                          :disabled="!isAdministrator || true"
                          :showLabels="false"
                          class="client-category-multiselect"
                          :option-height="29"
                          :max-height="203"
                          track-by="key"
                          label="value"
                          :close-on-select="true"
                          placeholder="Select Category"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col col-form-label">Apply to Priced Quotes</label>
                    <div class="col-6 pr-0 d-flex justify-content-end">
                      <div class="d-flex justify-content-between" style="width: 140px;">
                        <b-form-checkbox
                            name="check-button"
                            switch
                            :disabled="!isAdministrator"
                            @change="updatePurchases(selectedIndex)"
                            v-model="item.applyToPricedQuotes"
                            class="switch-checkbox"
                        ></b-form-checkbox>
                        <div class="status-text">
                          <span v-if="item.applyToPricedQuotes" class="form-text">Active</span>
                          <span v-else class="form-text">Not Active</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <tabs ref="rules" cache-lifetime="0"
                        :options="{ useUrlFragment: false, defaultTabHash: defaultRepairerRfqRulesHash }"
                        id="tableDisplayAddNewRule" @changed="onRFQTabRulesChange" class="rules-tabs">
                    <tab name="Rule Parameters" id="ruleParameters" class-name="tab-content-table">
                      <div class="rule-parameters-block">
                        <div class="tooltip-each-price-information-line">
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForAllEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForAllEnabled,
                                'selected-element': (selectedElement === 'listPricePercentForAllEnabled' && item.listPricePercentForAllEnabled)
                              }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentAll"
                                @onKeyPressUp="debounceOnKeyPressUp('percentAll')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentAll')"
                                type="text"
                                @focus="selectedElement = 'listPricePercentForAllEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForAllEnabled"
                                v-model="item.listPricePercentForAll"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="ALL" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForOemGenEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForOemGenEnabled,
                                'selected-element': selectedElement === 'listPricePercentForOemGenEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentOemGen"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentOemGen')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentOemGen')"
                                @focus="selectedElement = 'listPricePercentForOemGenEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForOemGenEnabled"
                                v-model="item.listPricePercentForOemGen"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="OEM-GEN" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForOemParEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForOemParEnabled,
                                'selected-element': selectedElement === 'listPricePercentForOemParEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentOemPar"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentOemPar')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentOemPar')"
                                @focus="selectedElement = 'listPricePercentForOemParEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForOemParEnabled"
                                v-model="item.listPricePercentForOemPar"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="OEM-PAR" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForOemAltEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForOemAltEnabled,
                                'selected-element': selectedElement === 'listPricePercentForOemAltEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentOemAlt"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentOemAlt')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentOemAlt')"
                                @focus="selectedElement = 'listPricePercentForOemAltEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForOemAltEnabled"
                                v-model="item.listPricePercentForOemAlt"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="OEM-ALT" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForCertAftmEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForCertAftmEnabled,
                                'selected-element': selectedElement === 'listPricePercentForCertAftmEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentCertAftm"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentCertAftm')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentCertAftm')"
                                @focus="selectedElement = 'listPricePercentForCertAftmEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForCertAftmEnabled"
                                v-model="item.listPricePercentForCertAftm"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="CERT-AFTM" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForAftmEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForAftmEnabled,
                                'selected-element': selectedElement === 'listPricePercentForAftmEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentAftm"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentAftm')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentAftm')"
                                @focus="selectedElement = 'listPricePercentForAftmEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForAftmEnabled"
                                v-model="item.listPricePercentForAftm"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="ATFM" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForUsedEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForUsedEnabled,
                                'selected-element': selectedElement === 'listPricePercentForUsedEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentUsed"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentUsed')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentUsed')"
                                @focus="selectedElement = 'listPricePercentForUsedEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForUsedEnabled"
                                v-model="item.listPricePercentForUsed"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="USED" :readonly="true"></b-form-input>
                          </div>
                          <div
                              @contextmenu.prevent="openMenu($event, item, 'listPricePercentForRecoEnabled', index)"
                              class="rule-parameters-row"
                              :class="{
                                'disabled-block': !item.listPricePercentForRecoEnabled,
                                'selected-element': selectedElement === 'listPricePercentForRecoEnabled'
                                }">
                            <span class="rule-parameters-first-text">Set</span>
                            <number-formatter
                                ref="percentReco"
                                type="text"
                                @onKeyPressUp="debounceOnKeyPressUp('percentReco')"
                                @onKeyPressDown="debounceOnKeyPressDown('percentReco')"
                                @focus="selectedElement = 'listPricePercentForRecoEnabled'"
                                @blur="selectedElement = ''"
                                :is-disabled="!isAdministrator || !item.listPricePercentForRecoEnabled"
                                v-model="item.listPricePercentForReco"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">of List Price for Part Type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="RECO" :readonly="true"></b-form-input>
                          </div>
                        </div>
                        <div
                            class="tooltip-each-price-information-line mt-2"
                            :class="{
                                'selected-element': selectedElement === 'markUp'
                            }">
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-set-mark-up">Set Target Cost Mark-up</span>
                            <number-formatter
                                type="text"
                                :is-disabled="!isAdministrator"
                                @focus="selectedElement = 'markUp'"
                                @blur="selectedElement = ''"
                                v-model="item.markUp"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                            <span class="rule-parameters-second-text">plus Cost (Buy) Price</span>
                          </div>
                        </div>
                        <div class="add-new-rule-btn d-flex justify-content-end" @click="addRebate"
                             style="margin: 10px">
                          + Add Rebate
                        </div>
                        <div
                            v-for="(rebate, ind) in item.rebates"
                            class="mt-2"
                            @contextmenu.prevent="openMenuForRebate($event, rebate.id, ind)"
                            :class="{
                               'selected-element': selectedElement === 'acceptRebateSupplier'
                            }">
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-accept-rebate">Accept Rebate - Supplier</span>
                            <multiselect
                                v-model="rebate.supplierId"
                                :options="computedSuppliers"
                                track-by="id"
                                label="name"
                                @open="selectedElement = 'acceptRebateSupplier'"
                                @close="selectedElement = ''"
                                @input="updateRebate(rebate)"
                                :showLabels="false"
                                :disabled="!isAdministrator"
                                class="accept-rebate-supplier-multiselect cursor-ptr"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                placeholder="Choose Suppliers"
                            />
                            <span class="rule-parameters-first-text">at</span>
                            <number-formatter
                                ref="mup"
                                v-model="rebate.percent"
                                type="text"
                                :is-disabled="!isAdministrator"
                                @focus="selectedElement = 'acceptRebateSupplier'"
                                @blur="selectedElement = ''"
                                @change="updateRebate(rebate)"
                                :percentage="true"
                                class="form-control rule-parameters-firts-input"
                                format="0%"></number-formatter>
                          </div>
                        </div>

                        <div class="" style="float: right;">
                          <b-button style="margin: 10px 0px 0px 0px" class="remove-rule-btn"
                                    @click="$refs.removeRule.show()">Remove Rule
                          </b-button>
                        </div>
                      </div>
                    </tab>

                    <tab name="Rule Builder" id="ruleBuilder" class-name="tab-content-table">
                      <div class="row">
                        <label class="col-12 col-form-label">
                          <p class="notifications-info-text">Add a rule to decide which supplieer/s to pick as the best
                            option for your Order</p>
                        </label>
                      </div>
                      <div class="form-group row">
                        <div class="col-12">
                          <input :disabled="!isAdministrator" placeholder="Enter New Rule Name" v-model="ruleName"
                                 class="form-control mr-3" style="max-width: 710px">
                        </div>
                      </div>
                      <div class="row w-100 align-items-center mb-3" style="max-width: 710px">
                        <div class="col-6">
                        </div>
                        <div class="col-6 add-new-rule-btn d-flex justify-content-end" @click="addRuleBuilder">
                          + Add Rule
                        </div>
                      </div>
                      <div v-for="(itm, ind) in item.rules" :key="itm.id" :id="'rule-item-' + item.id + '-' + itm.id"
                           @contextmenu.prevent="openMenuForRule($event, itm.id, ind)" class="rule-builder-block mb-3"
                           style="margin-left: 15px">
                        <div class="form-group row">
                          <label class="col col-form-label" style="padding-left: 0px">Rule Name</label>
                          <div class="col-6 pr-0">
                            <input :disabled="!isAdministrator" placeholder="Rule Name" v-model="itm.name"
                                   @change="updateRuleBuilder(itm)" type="text" class="form-control">
                          </div>
                        </div>
                        <div class="d-flex align-items-cente justify-content-between mb-3">
                          <span class="rule-builder-text">If Minimum Mark Up Target is</span>
                          <b-form-input :disabled="!isAdministrator || true" class="form-control mr-3"
                                        value="Equal To or Greater Than"></b-form-input>
                          <span class="rule-builder-text">then select Supplier</span>
                          <b-form-input :disabled="!isAdministrator || true" class="form-control"
                                        value="Highest % Mark Up (Profit)"></b-form-input>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                          <span class="rule-builder-text">If Profit difference is</span>
                          <multiselect
                              v-model="itm.rules.action"
                              :options="proffitDifferenceOptions"
                              :showLabels="false"
                              :disabled="!isAdministrator"
                              class="profit-difference-multiselect cursor-ptr"
                              :option-height="29"
                              @input="updateRuleBuilder(itm)"
                              :max-height="203"
                              :close-on-select="true"
                              autocomplete="off"
                              placeholder="Choose"
                          />
                          <number-formatter
                              format="$0.00"
                              class="rule-builder-input form-control"
                              :isDisabled="!isAdministrator"
                              @change="updateRuleBuilder(itm)"
                              v-model="itm.rules.value">
                          </number-formatter>

                          <span class="rule-builder-text">Select Supplier</span>
                          <multiselect
                              v-model="itm.rules.suppType"
                              :disabled="!isAdministrator"
                              autocomplete="off"
                              :options="choiseSupplierOptions"
                              :showLabels="false"
                              @input="updateRuleBuilder(itm)"
                              class="profit-difference-multiselect cursor-ptr"
                              :option-height="29"
                              :max-height="203"
                              :close-on-select="true"
                              placeholder="Choose"
                          />
                        </div>
                      </div>
                    </tab>

                    <tab name="Part Type" id="partType" class-name="tab-content-table">
                      <div style="overflow-x: auto" :style="{width: partTypeBlockWidth + 'px'}">
                      <div class="rule-parameters-block" :style="{maxWidth: partTypeBlockMaxWidth + 'px'}" style="max-width: 715px; min-width: 715px; min-height: 232px;">

                        <div
                            class="tooltip-each-price-information-line mt-2">
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-set-mark-up">Set the Bodyshop System</span>
                            <span class="np d-flex justify-content-end" style="padding-right: 15px">
                              <multiselect
                                  :options="['Repair-Shop', 'iBodyshop']"
                                  :disabled="!isAdministrator"
                                  :showLabels="false"
                                  open-direction="bottom"
                                  v-model="item.selectedExtSystem"
                                  :option-height="29"
                                  style="width: 150px;"
                                  :max-height="203"
                                  @input="changedBodyShopSystem"
                                  placeholder="None"
                                  :close-on-select="true"
                              />
                              </span>
                          </div>
                        </div>

                        <div v-if="item.selectedExtSystem" class="tooltip-each-price-information-line" style="padding-bottom: 232px; margin-top: 5px; border-bottom: none !important;">
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['OEM-GEN']"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                class="part-type-matching-select"
                                :allowEmpty="false"
                                placeholder=""
                                @input="savePartsType"
                                :close-on-select="true"
                            />
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="OEM-GEN" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                :disabled="!isAdministrator"
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['OEM-GEN']"
                                :showLabels="false"
                                :option-height="29"
                                class="part-type-number-select"
                                placeholder=""
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['OEM-GEN']"
                                @change="savePartsType"
                                :disabled="!isAdministrator"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['OEM-PAR']"
                                :showLabels="false"
                                class="part-type-matching-select"
                                :option-height="29"
                                :max-height="203"
                                placeholder=""
                                :allowEmpty="false"
                                @input="savePartsType"
                                :close-on-select="true"
                            />
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="OEM-PAR" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                :disabled="!isAdministrator"
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['OEM-PAR']"
                                :showLabels="false"
                                :option-height="29"
                                class="part-type-number-select"
                                placeholder=""
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['OEM-PAR']"
                                @change="savePartsType"
                                :disabled="!isAdministrator"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>

                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['OEM-ALT']"
                                :disabled="!isAdministrator"
                                class="part-type-matching-select"
                                :showLabels="false"
                                :option-height="29"
                                placeholder=""
                                :max-height="203"
                                @input="savePartsType"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="OEM-ALT" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['OEM-ALT']"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                :option-height="29"
                                class="part-type-number-select"
                                placeholder=""
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['OEM-ALT']"
                                @change="savePartsType"
                                :disabled="!isAdministrator"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>

                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['CERT-AFTM']"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="part-type-matching-select"
                                :option-height="29"
                                @input="savePartsType"
                                placeholder=""
                                :max-height="203"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="CERT-AFTM" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                :disabled="!isAdministrator"
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['CERT-AFTM']"
                                :showLabels="false"
                                :option-height="29"
                                placeholder=""
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                class="part-type-number-select"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['CERT-AFTM']"
                                @change="savePartsType"
                                :disabled="!isAdministrator"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>

                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['AFTM']"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                :option-height="29"
                                class="part-type-matching-select"
                                placeholder=""
                                @input="savePartsType"
                                :max-height="203"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="AFTM" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                :option-height="29"
                                placeholder=""
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['AFTM']"
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                :allowEmpty="false"
                                class="part-type-number-select"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['AFTM']"
                                @change="savePartsType"
                                :disabled="!isAdministrator"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>


                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['USED']"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="part-type-matching-select"
                                :option-height="29"
                                :max-height="203"
                                @input="savePartsType"
                                placeholder=""
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span  class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="USED" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                :option-height="29"
                                placeholder=""
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['USED']"
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                class="part-type-number-select"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['USED']"
                                @change="savePartsType"
                                :disabled="!isAdministrator"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>

                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text">Set</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partsTypeOptions[currentPartTypeValue]"
                                v-model="partsTypes[currentPartTypeValue].partTypeMatching['RECO']"
                                :disabled="!isAdministrator || currentPartTypeValue === 'ibodyshop'"
                                :showLabels="false"
                                class="part-type-matching-select"
                                @input="savePartsType"
                                :option-height="29"
                                placeholder=""
                                :max-height="203"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">for part type</span>
                            <b-form-input :disabled="!isAdministrator" class="form-control rule-parameters-second-input"
                                          value="RECO" :readonly="true">
                            </b-form-input>
                            <span class="rule-parameters-second-text rule-parameters-second-text-center">and Part nbr is</span>
                            <multiselect
                                open-direction="bottom"
                                :options="partNbrOptions"
                                :disabled="!isAdministrator || currentPartTypeValue === 'ibodyshop'"
                                :showLabels="false"
                                @input="savePartsType"
                                v-model="partsTypes[currentPartTypeValue].partTypeNumbers['RECO']"
                                :option-height="29"
                                placeholder=""
                                class="part-type-number-select"
                                :max-height="203"
                                style="min-width: 150px; width: 150px;"
                                :allowEmpty="false"
                                :close-on-select="true"
                            />
                            <span style="width: 8px; padding: 0px 15px;" class="rule-parameters-second-text rule-parameters-second-text-center">-</span>
                            <b-form-input
                                v-model="partsTypes[currentPartTypeValue].partTypePostfixes['RECO']"
                                @change="savePartsType"
                                :disabled="!isAdministrator || currentPartTypeValue === 'ibodyshop'"
                                class="form-control rule-parameters-second-input rule-parameters-second-input-margin">
                            </b-form-input>
                          </div>
                        </div>

                      </div>
                      </div>
                    </tab>

                    <tab name="Display" id="display" class-name="tab-content-table">
                      <div class="position-relative">
                        <div class="rule-parameters-block" style="margin-bottom: 76px;">
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-accept-rebate font-weight-bold">Stock Status</span>
                            <multiselect
                                v-model="item.displayStockStatus"
                                :options="displayModeOptions"
                                :ref="'stock-status-multiselect-' + item.id"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="display-mode-multiselect cursor-ptr"
                                :option-height="29"
                                :max-height="203"
                                :allowEmpty="false"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Make a choice"
                            />
                          </div>
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-accept-rebate font-weight-bold">List Price</span>
                            <multiselect
                                v-model="item.displayListPrice"
                                :options="displayModeOptions"
                                :ref="'list-price-multiselect-' + item.id"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="display-mode-multiselect cursor-ptr"
                                :option-height="29"
                                :allowEmpty="false"
                                :max-height="203"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Make a choice"
                            />
                          </div>
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-accept-rebate font-weight-bold">Charge Price</span>
                            <multiselect
                                v-model="item.displayChargePrice"
                                :options="displayModeOptions"
                                :ref="'charge-price-multiselect-' + item.id"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="display-mode-multiselect cursor-ptr"
                                :option-height="29"
                                :max-height="203"
                                :allowEmpty="false"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Make a choice"
                            />
                          </div>
                          <div class="rule-parameters-row">
                            <span
                                class="rule-parameters-first-text-accept-rebate font-weight-bold">Mark Up Percentage</span>
                            <multiselect
                                v-model="item.displayMarkUpPercentage"
                                :options="displayModeOptions"
                                :ref="'mark-up-multiselect-' + item.id"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="display-mode-multiselect cursor-ptr"
                                :option-height="29"
                                :max-height="203"
                                :allowEmpty="false"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Make a choice"
                            />
                          </div>
                          <div class="rule-parameters-row">
                            <span class="rule-parameters-first-text-accept-rebate font-weight-bold">Profit Value</span>
                            <multiselect
                                v-model="item.displayProfitValue"
                                :ref="'profit-value-multiselect-' + item.id"
                                :options="displayModeOptions"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="display-mode-multiselect cursor-ptr"
                                :option-height="29"
                                :max-height="203"
                                :allowEmpty="false"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Make a choice"
                            />
                          </div>
                          <div class="rule-parameters-row">
                            <span
                                class="rule-parameters-first-text-accept-rebate font-weight-bold">Cost (Buy) Price</span>
                            <multiselect
                                v-model="item.displayBuyPrice"
                                :ref="'cost-price-multiselect-' + item.id"
                                :options="displayModeOptions"
                                :disabled="!isAdministrator"
                                :showLabels="false"
                                class="display-mode-multiselect cursor-ptr"
                                :option-height="29"
                                :max-height="203"
                                :allowEmpty="false"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                placeholder="Make a choice"
                            />
                          </div>
                          <div class="rule-parameters-row">
                            <span
                                class="rule-parameters-first-text-accept-rebate font-weight-bold">Discounted Price</span>
                            <multiselect
                                v-model="item.displayDiscountedPrice"
                                :options="dicsPriceOptions"
                                :ref="'disc-price-multiselect-' + item.id"
                                :disabled="!isAdministrator"
                                :multiple="true"
                                :showLabels="false"
                                :taggable="false"
                                class="display-mode-multiselect rule-discounted-price cursor-ptr"
                                :option-height="29"
                                @select="val => addTag(val, item.displayDiscountedPrice)"
                                :allowEmpty="false"
                                :max-height="203"
                                track-by="key"
                                label="value"
                                :close-on-select="true"
                                style="width: 140px;"
                                autocomplete="off"
                                placeholder=""
                            >
                              <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <template v-if="!isOpen">
                                  <span v-if="values.length === 1" class="multiselect__single">{{
                                      values[0].value
                                    }}</span>
                                  <span v-else-if="values.length" class="multiselect__single">{{ values.length }} roles selected</span>
                                </template>
                                <template v-else>
                                  <div class=""></div>
                                </template>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="tooltip-each-price-information-block">
                          <div class="d-flex">
                            <p class="col-4 p-0 font-weight-600">Part Number </p>
                            <p class="col-8 p-0">9115165684</p>
                          </div>
                          <div v-if="item.displayStockStatus.key === true" class="d-flex">
                            <p class="col-4 p-0 font-weight-600">Stock Status </p>
                            <p class="col-8 p-0">In Stock</p>
                          </div>
                          <div v-if="item.displayListPrice.key === true" class="d-flex align-items-center mb-2">
                            <p class="col-4 p-0 mb-0 font-weight-600">List Price</p>
                            <b-form-input :value="'$1,179.32'" :disabled="!isAdministrator || true"
                                          class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                          </div>
                          <div v-if="item.displayChargePrice.key === true" class="d-flex align-items-center mb-2">
                            <p class="col-4 p-0 mb-0 font-weight-600">Charge Price</p>
                            <b-form-input
                                :value="accounting.formatMoney(accounting.toFixed((getListPercent(item) * 1179.32)/100,2))"
                                :disabled="!isAdministrator || true"
                                class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                            <p class="col-4 p-0 mb-0 pl-3 font-weight-600">{{ getListPercent(item) }}% of List</p>
                          </div>
                          <div v-if="item.displayBuyPrice.key === true" class="d-flex mt-3 mb-2"
                               :class="{'justify-content-end': item.displayMarkUpPercentage.key === true && item.displayBuyPrice.key !== true }">
                            <p class="col-4 p-0"></p>
                            <p v-if="item.displayBuyPrice.key === true"
                               class="col-4 p-0 pb-2 tooltip-each-price-information-line font-weight-600">Purchase
                              Price</p>
                            <p v-if="item.displayMarkUpPercentage.key === true"
                               class="col-4 p-0 pb-2 pl-3 tooltip-each-price-information-line font-weight-600">
                              Markup</p>
                          </div>
                          <div v-if="item.displayBuyPrice.key === true" class="d-flex align-items-center mb-2">
                            <p class="col-4 p-0 mb-0 font-weight-900">Cost Price</p>
                            <b-form-input :value="'$737.08'" :disabled="!isAdministrator || true"
                                          class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900"></b-form-input>
                            <p v-if="item.displayMarkUpPercentage.key === true"
                               class="col-4 p-0 mb-0 pl-3 font-weight-900"
                               :class="{'color-green':getDisplayMarkupPercentageForCostPrice(item)>=item.markUp,'color-red':getDisplayMarkupPercentageForCostPrice(item)<item.markUp }">
                              {{ getDisplayMarkupPercentageForCostPrice(item) }}%</p>
                          </div>

                          <div v-if="item.displayBuyPrice.key === true && item.displayProfitValue.key === true"
                               class="d-flex align-items-center mb-2">
                            <p class="col-4 p-0 mb-0 font-weight-600">Profit Value</p>
                            <b-form-input
                                :value="accounting.formatMoney(accounting.toFixed(accounting.toFixed((getListPercent(item) * 1179.32)/100,2)-737.08,2))"
                                autocomplete="one-time-code" :disabled="!isAdministrator || true"
                                class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                            <p class="col-4 p-0 mb-0 pl-3 font-weight-600"></p>
                          </div>

                          <div v-if="item.displayProfitValue.key === true && isDescPriceEnable(item)"
                               class="d-flex justify-content-end align-items-center">
                            <div
                                style="height: 3px; background-color: rgba(27, 30, 56, 0.22); margin-bottom: 11px; margin-top: 6px; width: 239px;"
                                class=""></div>
                          </div>

                          <div v-if="isDescPriceEnable(item)" class="d-flex align-items-center mb-2">
                            <p class="col-4 p-0 mb-0 font-weight-600"
                               :class="{'font-weight-600': item.displayProfitValue.key === true}">Disc Price</p>
                            <template v-if="_isEmpty(item.rebates)">
                              <b-form-input :value="'$663.37'" :disabled="!isAdministrator || true"
                                            class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                              <p v-if="item.displayMarkUpPercentage.key === true" class="col-4 p-0 mb-0 pl-3">
                                {{
                                  accounting.toFixed(((accounting.toFixed((getListPercent(item) * 1179.32) / 100, 2) - 663.37) / 663.37) * 100, 2)
                                }}%</p>
                            </template>
                            <template v-else>
                              <b-form-input
                                  :value="'$' + accounting.toFixed(737.08 * ((100 - item.rebates[0].percent) / 100), 2)"
                                  :disabled="!isAdministrator || true"
                                  class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                              <!--                              <p v-if="item.displayMarkUpPercentage.key === true" class="col-4 p-0 mb-0 pl-3">{{ 100 - Number(item.rebates[0].percent) }}%</p>-->
                              <p v-if="item.displayMarkUpPercentage.key === true"
                                 class="col-4 p-0 mb-0 pl-3 font-weight-900">
                                {{
                                  accounting.toFixed(((accounting.toFixed((getListPercent(item) * 1179.32) / 100, 2) - (accounting.toFixed(737.08 * ((100 - item.rebates[0].percent) / 100), 2))) / (accounting.toFixed(737.08 * ((100 - item.rebates[0].percent) / 100), 2))) * 100, 2)
                                }}%</p>
                            </template>
                          </div>

                          <div v-if="item.displayProfitValue.key === true && isDescPriceEnable(item)"
                               class="d-flex align-items-center mb-2">
                            <p class="col-4 p-0 mb-0 font-weight-600">Disc Profit Value</p>
                            <template v-if="_isEmpty(item.rebates)">
                              <b-form-input
                                  :value="accounting.formatMoney(accounting.toFixed(accounting.toFixed((getListPercent(item) * 1179.32)/100,2)-663.37,2))"
                                  :disabled="!isAdministrator || true"
                                  class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                            </template>
                            <template v-else>
                              <b-form-input
                                  :value="accounting.formatMoney(accounting.toFixed( 737.08 -accounting.toFixed(737.08 * ((100 - item.rebates[0].percent) / 100), 2), 2))"
                                  :disabled="!isAdministrator || true"
                                  class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"></b-form-input>
                            </template>
                            <p class="col-4 p-0 mb-0 pl-3 font-weight-600"></p>
                          </div>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
    </tabs>

    <vue-context @close="closeMenu" ref="partContext" class="rule-parameters-menu-context">
      <template slot-scope="item">
        <ul v-if="item && item.data">
          <li v-if="!item.data.item" @click="enableOrDisableItem(item.data.field, true, item.data.index)">Enable</li>
          <li v-else @click="enableOrDisableItem(item.data.field, false, item.data.index)">Disable</li>
        </ul>
      </template>
    </vue-context>


    <vue-context ref="removeRebate" class="rule-parameters-menu-context" style="width: 182px;">
      <template slot-scope="item">
        <ul v-if="item && item.data && item.data.id">
          <li @click="removeRebate(item.data.id,  item.data.index)">Remove Rebate Rule</li>
        </ul>
      </template>
    </vue-context>

    <vue-context ref="removeRuleContext" class="rule-parameters-menu-context" style="width: 134px">
      <template slot-scope="item">
        <ul v-if="item && item.data && item.data.id">
          <li @click="removeRuleBuilder(item.data.id,  item.data.index)">Remove Rule</li>
        </ul>
      </template>
    </vue-context>

    <b-modal ref="removeRule" id="remove-rule" size="custom-mark-as-returned remove-rule-modal"
             title="Delete a Rule">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned form-container">
        <p class="pl-3 pr-3">Are you sure you want to continue?</p>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Cancel Action</b-button>
          <b-button class="input-btn" @click="removePriceSelector">Continue</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import Axios from 'axios'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import { VueContext } from 'vue-context'
import NumberFormatter from '../../../../components/utility/number-formatter'
import accounting from 'accounting'
import _ from 'lodash'

export default {
  name: 'repairer-rfq',
  components: {
    Multiselect,
    VueContext,
    NumberFormatter
  },
  data () {
    return {
      ruleName: '',
      isShowErrorName: false,
      isNameInFocus: false,
      priceSelectors: [],
      priceSelectorsCopy: [],
      selectedElement: '',
      clientNames: [],
      selectedIndex: -1,
      partNbrOptions: ['Dealer Part Nbr', 'Supplier Part Nbr', 'OE Reference Nbr', 'None'],
      partsTypeOptions: {
        'repairshop': ['New', 'Parallel', 'Alternate', 'Aftermarket', 'Used', 'Recondition', 'Exchange',],
        'ibodyshop': ['New', 'Used', 'Aft', 'Exch', 'Non Gen', 'Net']
      },
      currentPartTypeValue: 'repairshop',
      partsTypes: {
        'repairshop':  {
          "system": "repairshop",
          "partTypeMatching":{
            "OEM-GEN": "",
            "OEM-PAR": "",
            "OEM-ALT": "",
            "CERT-AFTM": "",
            "AFTM": "",
            "USED": "",
            "RECO": "",
          },
          "partTypePostfixes":{
            "OEM-GEN": "",
            "OEM-PAR": "",
            "OEM-ALT": "",
            "CERT-AFTM": "",
            "AFTM": "",
            "USED": "",
            "RECO": "",
          },
          "partTypeNumbers":{
            "OEM-GEN": "",
            "OEM-PAR": "",
            "OEM-ALT": "",
            "CERT-AFTM": "",
            "AFTM": "",
            "USED": "",
            "RECO": "",
          }
        },
        'ibodyshop': {
          "system": "ibodyshop",
          "partTypeMatching":{
            "OEM-GEN": "",
            "OEM-PAR": "",
            "OEM-ALT": "",
            "CERT-AFTM": "",
            "AFTM": "",
            "USED": "",
            "RECO": "",
          },
          "partTypePostfixes":{
            "OEM-GEN": "",
            "OEM-PAR": "",
            "OEM-ALT": "",
            "CERT-AFTM": "",
            "AFTM": "",
            "USED": "",
            "RECO": "",
          },
          "partTypeNumbers":{
            "OEM-GEN": "",
            "OEM-PAR": "",
            "OEM-ALT": "",
            "CERT-AFTM": "",
            "AFTM": "",
            "USED": "",
            "RECO": "",
          }
        },

      },
      statusPricedQuotes: false,
      isDefaultNameBorder: false,
      showRuleList: [],
      showRuleBuilderList: [],
      clientCategory: {},
      clientCategoryOptions: [
        { key: 'insurer', value: 'Insurer', }
      ],
      proffitDifferenceOptions: ['Less Than', 'Equal To', 'Greater Than'],
      choiseSupplier: [],
      choiseSupplierOptions: ['OEM-GEN', 'OEM-PAR', 'OEM-ALT', 'CERT-AFTM', 'AFTM', 'USED', 'RECO'],
      acceptRebateSupplier: [],
      acceptRebateSupplierOptions: ['Auto Parts Group', 'ACM Parts'],
      displayModeOptions: [
        { key: true, value: 'Yes', },
        { key: false, value: 'No', },
      ],
      partTypeBlockWidth: 0,
      partTypeBlockMaxWidth: 0,
      dicsPriceOptions: [
        { key: 'administrator', value: 'Administrator', },
        { key: 'estimator', value: 'Estimator', },
        { key: 'parts coordinator', value: 'Parts Coordinator', },
        { key: 'none', value: 'None', }
      ],
      ruleSearch: '',
      settings: {
        rfq: {
          enablePurchasePriceSelector: false,
          tableDisplayPinnedImages: {
            active: true,
          }
        }
      },
    }
  },
  computed: {
    accounting () {
      return accounting
    },
    ...mapGetters({
      getterSettings: 'settings/getSettings',
      getterPriceSelectors: 'settings/getPriceSelectors',
      isLoadedPriceSelectors: 'settings/isLoadedPriceSelectors',
      isAdministrator: 'currentUser/isAdministrator',
      gettersPreferredSuppliers: 'repairer/supplier/getPreferedSuppliers',
    }),
    computedSuppliers () {
      let result = []
      _.forEach(_.cloneDeep(this.gettersPreferredSuppliers), (itm) => {
        result.push({
          name: itm.name,
          id: itm.id
        })
      })
      return _.orderBy(result, [item => _.trim(_.toLower(item))], 'asc')
    },
    getSettings () {
      return _.cloneDeep(this.getterSettings)
    },
    computedDefaultRFQHash () {
      if (localStorage.getItem('settings.tabs.defaultRepairerRfqSettingsTab')) {
        return localStorage.getItem('settings.tabs.defaultRepairerRfqSettingsTab')
      }
      return 'tableDisplay'
    },
    defaultRepairerRfqRulesHash () {
      if (localStorage.getItem('settings.tabs.defaultRepairerRfqSettingsTabNewRule')) {
        return localStorage.getItem('settings.tabs.defaultRepairerRfqSettingsTabNewRule')
      }
      return 'tableDisplayAddNewRule'
    }
  },
  mounted () {
    // this.loadSettings()
    this.loadPurchases()

    this.debounceOnKeyPressUp = _.debounce(function (v) {
      this.onKeyPressUp(v)
    }, 10)

    this.debounceOnKeyPressDown = _.debounce(function (v) {
      this.onKeyPressDown(v)
    }, 10)
    window.addEventListener("scroll", this.setWidth);
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setWidth);
    window.removeEventListener("resize", this.setWidth);
  },
  watch: {
    isLoadedPriceSelectors: {
      handler () {
        this.loadPurchases()
      },
      immediate: true
    },
    getterSettings: {
      handler () {
        this.loadSettings()
      },
      immediate: true,
    },
    gettersPreferredSuppliers: {
      handler () {
        this.modifyPriceSelectors()
      },
      immediate: true,
    }
  },
  methods: {
    setWidth() {
      this.partTypeBlockWidth = window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth - 55
      this.partTypeBlockMaxWidth = document.getElementById('purchaseTitle')?.clientWidth > 715 ? document.getElementById('purchaseTitle')?.clientWidth - 30 : 'none'
    },
    changedBodyShopSystem(v) {
      if (v == 'Repair-Shop') {
        this.currentPartTypeValue = 'repairshop';
      } else {
        this.currentPartTypeValue = 'ibodyshop';
      }
    },
    setPartsTypes() {
      let rule = this.priceSelectors[this.selectedIndex]
      if (!rule) {
        return
      }
      if (_.isEmpty(rule.partsTypes)) {
        rule.selectedExtSystem = 'Repair-Shop'
        this.currentPartTypeValue = 'repairshop';
        this.partsTypes = {
          'repairshop':  {
            "system": "repairshop",
            "partTypeMatching":{
              "OEM-GEN": "",
                  "OEM-PAR": "",
                  "OEM-ALT": "",
                  "CERT-AFTM": "",
                  "AFTM": "",
                  "USED": "",
                  "RECO": "",
            },
            "partTypePostfixes":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            },
            "partTypeNumbers":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            }
          },
          'ibodyshop': {
            "system": "ibodyshop",
            "partTypeMatching":{
              "OEM-GEN": "",
                  "OEM-PAR": "",
                  "OEM-ALT": "",
                  "CERT-AFTM": "",
                  "AFTM": "",
                  "USED": "",
                  "RECO": "",
            },
            "partTypePostfixes":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            },
            "partTypeNumbers":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            }
          },

        };
      } else {
        let rsTypes = _.find(rule.partsTypes, (r) => {
          return r.system == 'repairshop'
        })
        let ibsTypes = _.find(rule.partsTypes, (r) => {
          return r.system == 'ibodyshop'
        })
        this.partsTypes = {
          'repairshop':  {
            "system": "repairshop",
            "partTypeMatching":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            },
            "partTypePostfixes":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            },
            "partTypeNumbers":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            }
          },
          'ibodyshop': {
            "system": "ibodyshop",
            "partTypeMatching":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            },
            "partTypePostfixes":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            },
            "partTypeNumbers":{
              "OEM-GEN": "",
              "OEM-PAR": "",
              "OEM-ALT": "",
              "CERT-AFTM": "",
              "AFTM": "",
              "USED": "",
              "RECO": "",
            }
          },

        };
        if (rule.selectedExtSystem === 'Repair-Shop' && rsTypes) {
          this.currentPartTypeValue = 'repairshop';
        } else if (rule.selectedExtSystem === 'iBodyshop' && ibsTypes) {
          this.currentPartTypeValue = 'ibodyshop';
        }
        if (ibsTypes) {
          this.partsTypes.ibodyshop = ibsTypes
        }
        if (rsTypes) {
          this.partsTypes.repairshop = rsTypes
        }
      }
    },
    savePartsType() {
      let rule = this.priceSelectors[this.selectedIndex]
      Axios.post('/ir/purchase-price-selector/' + rule.id + '/part-type', {
        system: this.currentPartTypeValue,
        partTypeMatching: this.partsTypes[this.currentPartTypeValue].partTypeMatching,
        partTypePostfixes: this.partsTypes[this.currentPartTypeValue].partTypePostfixes,
        partTypeNumbers: this.partsTypes[this.currentPartTypeValue].partTypeNumbers,
      })
          .then(response => {
            if (response.data && response.data._status) {
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    getDisplayMarkupPercentageForCostPrice (item) {
      return accounting.toFixed(((accounting.toFixed((this.getListPercent(item) * 1179.32) / 100, 2) - 737.08) / 737.08) * 100, 2)
    },
    getListPercent (item) {
      if (item.listPricePercentForAllEnabled) {
        return item.listPricePercentForAll
      } else if (item.listPricePercentForOemGenEnabled) {
        return item.listPricePercentForOemGen
      } else if (item.listPricePercentForOemParEnabled) {
        return item.listPricePercentForOemPar
      } else if (item.listPricePercentForOemAltEnabled) {
        return item.listPricePercentForOemAlt
      } else if (item.listPricePercentForCertAftmEnabled) {
        return item.listPricePercentForCertAftm
      }  else if (item.listPricePercentForAftmEnabled) {
        return item.listPricePercentForAftm
      } else if (item.listPricePercentForUsedEnabled) {
        return item.listPricePercentForUsed
      } else if (item.listPricePercentForRecoEnabled) {
        return item.listPricePercentForReco
      }
      return 0
    },
    _isEmpty (v) {
      return _.isEmpty(v)
    },
    checkName (name) {
      this.isDefaultNameBorder = false
      this.isShowErrorName = _.filter(this.priceSelectors, (itm) => {
        return itm.name === name
      }).length > 1
    },
    isDescPriceEnable (item) {
      let index = _.findIndex(item.displayDiscountedPrice, (i) => {
        return i.key === 'none'
      })
      return item.displayDiscountedPrice.length && index === -1
    },
    addTag (v, item) {
      let index = _.findIndex(item, (i) => {
        return i.key === 'none'
      })
      if (v.key === 'none' && !(item.length === 1 && item.includes(v))) {
        item.splice(0, item.length)
        // item.push(item);
      } else if (v.key !== 'none' && index !== -1) {
        item.splice(index, 1)
      }
    },
    setIsEdit (v, ind) {
      this.$nextTick(() => {
        this.$set(this.priceSelectors[ind], 'isEdit', v)
        if (!v && !this.isShowRule(this.priceSelectors[ind], ind)) {
          this.updatePurchases(ind)
        }
      })
    },
    getRefs () {
      let list = []
      let rule = this.priceSelectors[this.selectedIndex]

      if (rule.listPricePercentForAllEnabled) {
        list.push('percentAll')
      }
      if (rule.listPricePercentForOemGenEnabled) {
        list.push('percentOemGen')
      }
      if (rule.listPricePercentForOemAltEnabled) {
        list.push('percentOemAlt')
      }
      if (rule.listPricePercentForOemParEnabled) {
        list.push('percentOemPar')
      }
      if (rule.listPricePercentForCertAftmEnabled) {
        list.push('percentCertAftm')
      }
      if (rule.listPricePercentForAftmEnabled) {
        list.push('percentAftm')
      }
      if (rule.listPricePercentForUsedEnabled) {
        list.push('percentUsed')
      }
      if (rule.listPricePercentForRecoEnabled) {
        list.push('percentReco')
      }
      return list
    },
    onKeyPressDown (ref) {
      let resultRef = ''
      let list = this.getRefs()
      let index = list.indexOf(ref)
      if (index === -1) {
        return
      }
      if (index + 1 === list.length) {
        resultRef = list[0]
      } else {
        resultRef = list[++index]
      }
      if (this.$refs[resultRef]?.[0]) {
        this.$refs[resultRef][0].focus()
      }
    },
    onKeyPressUp (ref) {
      let resultRef = ''
      let list = this.getRefs()
      let index = list.indexOf(ref)
      if (index === -1) {
        return
      }
      if (index === 0) {
        resultRef = list[list.length - 1]
      } else {
        index = index - 1
        resultRef = list[index]
      }
      if (this.$refs[resultRef]?.[0]) {
        this.$refs[resultRef][0].focus()
      }
    },
    isShowRule (item, ind) {
      if (ind > this.selectedIndex && this.selectedIndex !== -1) {
        return false
      }
      return (_.trim(_.toLower(item.name)).includes(_.toLower(this.ruleSearch))) || item.isEdit
    },
    orderPurchaces (index = null) {
      let item = null
      if (index !== null) {
        item = this.priceSelectors[index]
      }
      this.priceSelectors = _.orderBy(this.priceSelectors, [item => _.trim(_.toLower(item.name))], 'asc')
      if (item) {
        let newIndex = this.priceSelectors.indexOf(item)
        this.selectedIndex = newIndex
        this.disableAutoComplete()
        this.setPartsTypes()
      }
    },
    enableOrDisableItem (field, v, ind) {
      this.$nextTick(() => {
        if (field === 'listPricePercentForAllEnabled' && v) {
          this.$set(this.priceSelectors[ind], 'listPricePercentForOemGenEnabled', false)
          this.$set(this.priceSelectors[ind], 'listPricePercentForOemAltEnabled', false)
          this.$set(this.priceSelectors[ind], 'listPricePercentForOemParEnabled', false)
          this.$set(this.priceSelectors[ind], 'listPricePercentForCertAftmEnabled', false)
          this.$set(this.priceSelectors[ind], 'listPricePercentForAftmEnabled', false)
          this.$set(this.priceSelectors[ind], 'listPricePercentForUsedEnabled', false)
          this.$set(this.priceSelectors[ind], 'listPricePercentForRecoEnabled', false)
        } else if (field === 'listPricePercentForAllEnabled') {
          this.$set(this.priceSelectors[ind], 'listPricePercentForOemGenEnabled', true)
          this.$set(this.priceSelectors[ind], 'listPricePercentForOemAltEnabled', true)
          this.$set(this.priceSelectors[ind], 'listPricePercentForOemParEnabled', true)
          this.$set(this.priceSelectors[ind], 'listPricePercentForCertAftmEnabled', true)
          this.$set(this.priceSelectors[ind], 'listPricePercentForAftmEnabled', true)
          this.$set(this.priceSelectors[ind], 'listPricePercentForUsedEnabled', true)
          this.$set(this.priceSelectors[ind], 'listPricePercentForRecoEnabled', true)
        }

        this.$set(this.priceSelectors[ind], field, v)
      })
      this.$refs.partContext.close()
    },
    loadPurchases () {
      this.priceSelectors = _.cloneDeep(this.getterPriceSelectors)
      _.forEach(this.priceSelectors, (item) => {
        _.forEach(item.rules, (itm) => {
          if (_.isEmpty(itm.rules)) {
            itm.rules = {
              action: '',
              suppType: '',
              value: ''
            }
          }
        })
      })

      this.modifyPriceSelectors()

      this.orderPurchaces()

      this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
    },
    getValueFromDisplayOptions (v) {
      return _.find(this.displayModeOptions, (opt) => {
        return opt.key === v
      })
    },
    modifyPriceSelectors () {
      _.forEach(this.priceSelectors, (rule) => {
        let category = _.find(this.clientCategoryOptions, (opt) => {
          return opt.key === rule.category
        })
        if (category) {
          rule.category = category
        }
        if (this.getValueFromDisplayOptions(rule.displayStockStatus)) {
          rule.displayStockStatus = this.getValueFromDisplayOptions(rule.displayStockStatus)
        }
        if (this.getValueFromDisplayOptions(rule.displayListPrice)) {
          rule.displayListPrice = this.getValueFromDisplayOptions(rule.displayListPrice)
        }
        if (this.getValueFromDisplayOptions(rule.displayChargePrice)) {
          rule.displayChargePrice = this.getValueFromDisplayOptions(rule.displayChargePrice)
        }
        if (this.getValueFromDisplayOptions(rule.displayMarkUpPercentage)) {
          rule.displayMarkUpPercentage = this.getValueFromDisplayOptions(rule.displayMarkUpPercentage)
        }
        if (this.getValueFromDisplayOptions(rule.displayProfitValue)) {
          rule.displayProfitValue = this.getValueFromDisplayOptions(rule.displayProfitValue)
        }

        if (_.isArray(rule.displayDiscountedPrice) && rule.displayDiscountedPrice.length) {
          if (!rule.displayDiscountedPrice?.[0]?.key) {
            let result = []
            _.forEach(rule.displayDiscountedPrice, (r) => {
              let item = _.find(this.dicsPriceOptions, (opt) => {
                return opt.key === r
              })
              if (item) {
                result.push(item)
              }
            })
            rule.displayDiscountedPrice = result
          }
        } else {
          rule.displayDiscountedPrice = [{ key: 'none', value: 'None', }]
        }

        if (this.getValueFromDisplayOptions(rule.displayBuyPrice)) {
          rule.displayBuyPrice = this.getValueFromDisplayOptions(rule.displayBuyPrice)
        }
        _.forEach(rule.rebates, (rebate) => {
          let item = _.find(this.computedSuppliers, (opt) => {
            return Number(opt.id) === Number(rebate.supplierId)
          })
          if (item) {
            rebate.supplierId = item
          }
        })
      })
    },
    getPriceSelectorsForSaving () {
      let data = _.cloneDeep(this.priceSelectors)
      _.forEach(data, (item) => {
        item = this.modifyRule(item)
      })
      return data
    },
    modifyRule (rule) {
      if (rule?.category?.hasOwnProperty('key')) {
        rule.category = rule.category.key
      }
      if (rule?.displayStockStatus?.hasOwnProperty('key')) {
        rule.displayStockStatus = rule.displayStockStatus.key
      }
      if (rule?.displayListPrice?.hasOwnProperty('key')) {
        rule.displayListPrice = rule.displayListPrice.key
      }
      if (rule?.displayChargePrice?.hasOwnProperty('key')) {
        rule.displayChargePrice = rule.displayChargePrice.key
      }
      if (rule?.displayMarkUpPercentage?.hasOwnProperty('key')) {
        rule.displayMarkUpPercentage = rule.displayMarkUpPercentage.key
      }
      if (rule?.displayProfitValue?.hasOwnProperty('key')) {
        rule.displayProfitValue = rule.displayProfitValue.key
      }
      if (rule?.displayBuyPrice?.hasOwnProperty('key')) {
        rule.displayBuyPrice = rule.displayBuyPrice.key
      }
      if (rule?.displayDiscountedPrice.length) {
        if (rule.displayDiscountedPrice?.[0]?.key) {
          let result = []
          _.forEach(rule.displayDiscountedPrice, (i) => {
            result.push(i.key)
          })
          rule.displayDiscountedPrice = result
        }
      }
      _.forEach(rule.rebates, (rebate) => {
        if (rebate.supplierId && rebate.supplierId.id) {
          rebate.supplierId = rebate.supplierId.id
        }
      })

      delete rule.isEdit

      return rule
    },
    removePriceSelector () {
      let rule = this.priceSelectors[this.selectedIndex]
      Axios.post('/ir/purchase-price-selector/' + rule.id + '/delete', {
        id: rule.id
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success('Deleted')
              this.priceSelectors.splice(this.selectedIndex, 1)
              this.selectedIndex = -1
              this.ruleName = ''
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            } else {
              this.$toast.error('Not deleted')
            }
          })
          .catch(error => {
            this.$toast.error('Not deleted')
            console.log(error)
          })
      this.$refs.removeRule.hide()
    },
    updatePurchase (rule) {
      rule = this.modifyRule(rule)
      Axios.post('/ir/purchase-price-selector/' + rule.id, rule)
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success('The RFQ Settings have been updated')
              this.modifyPriceSelectors()
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            } else {
              this.$toast.error('The RFQ Settings haven\'t been updated')
            }
          })
          .catch(error => {
            this.$toast.error('The RFQ Settings haven\'t been updated')
            console.log(error)
          })
    },
    updatePurchases (index = null) {
      if (!this.isAdministrator || this.selectedIndex === -1) {
        return
      }

      let resultIndex = index !== null ? index : this.selectedIndex
      let rule = this.priceSelectors[resultIndex]

      rule = this.modifyRule(rule)

      let itmCopy = _.find(this.priceSelectorsCopy, (i) => {
        return Number(i.id) === Number(rule.id)
      })

      if (itmCopy) {
        itmCopy = this.modifyRule(itmCopy)
        if (!_.isEqual(itmCopy, rule)) {
          this.updatePurchase(rule)
        }
      }

      this.modifyPriceSelectors()

    },
    openMenu (event, item, field, index) {
      this.closeMenu()
      this.selectedElement = field
      this.$refs.partContext.open(event, { item: item[field], field: field, index: index, })
    },
    closeMenu () {
      this.selectedElement = ''
    },
    openMenuForRebate (event, id, index) {
      this.$refs.removeRebate.open(event, { id: id, index: index })
    },
    openMenuForRule (event, id, index) {
      this.$refs.removeRuleContext.open(event, { id: id, index: index })
    },
    addNewRule () {
      if (!this.isAdministrator || this.isShowErrorName) {
        return
      }
      this.isDefaultNameBorder = true
      let isHasEmptyRule = _.some(this.priceSelectors, (itm) => {
        return _.isEmpty(_.trim(itm.name))
      })
      if (isHasEmptyRule) {
        return
      }

      Axios.post('/ir/purchase-price-selector')
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success('The RFQ Settings have been updated')
              this.priceSelectors.unshift(response.data.priceSelector)
              this.selectedIndex = 0
              this.setPartsTypes()
              this.disableAutoComplete()
              this.modifyPriceSelectors()
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            } else {
              this.$toast.error('The RFQ Settings haven\'t been updated')
            }
          })
          .catch(error => {
            this.$toast.error('The RFQ Settings haven\'t been updated')
            console.log(error)
          })

    },
    addRebate () {
      if (!this.isAdministrator) {
        return
      }

      let rule = this.priceSelectors[this.selectedIndex]
      Axios.post('/ir/purchase-price-selector/' + rule.id + '/add-rebate')
          .then(response => {
            if (response.data && response.data._status) {
              this.priceSelectors[this.selectedIndex].rebates.push(response.data.rebate)
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    updateRebate (rebate) {
      if (!this.isAdministrator) {
        return
      }
      Axios.post('/ir/purchase-price-selector/rebate/' + rebate.id, {
        supplierId: rebate.supplierId.id,
        percent: rebate.percent,
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    removeRebate (id, index) {
      if (!this.isAdministrator) {
        return
      }

      Axios.post('/ir/purchase-price-selector/rebate/' + id + '/delete', {
        id: id
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.priceSelectors[this.selectedIndex].rebates.splice(index, 1)
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    addRuleBuilder () {
      if (!this.isAdministrator) {
        return
      }

      let rule = this.priceSelectors[this.selectedIndex]
      Axios.post('/ir/purchase-price-selector/' + rule.id + '/add-rule')
          .then(response => {
            if (response.data && response.data._status) {
              response.data.rule.rules = {
                action: '',
                suppType: '',
                value: ''
              }
              this.priceSelectors[this.selectedIndex].rules.push(response.data.rule)
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))

              if (!_.isEmpty(this.ruleName)) {
                let rule = _.last(this.priceSelectors[this.selectedIndex].rules)
                rule.name = this.ruleName
                this.updateRuleBuilder(rule)
                this.ruleName = ''
                this.$nextTick(() => {
                  let el = document.getElementById('rule-item-' + this.priceSelectors[this.selectedIndex].id + '-' + rule.id)
                  el.scrollIntoView({ block: 'center', inline: 'center' })
                })
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    updateRuleBuilder (rule) {
      if (!this.isAdministrator) {
        return
      }

      Axios.post('/ir/purchase-price-selector/rule/' + rule.id, {
        name: rule.name,
        rules: rule.rules
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    removeRuleBuilder (ruleId, index) {
      if (!this.isAdministrator) {
        return
      }

      Axios.post('/ir/purchase-price-selector/rule/' + ruleId + '/delete', {
        id: ruleId
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.priceSelectors[this.selectedIndex].rules.splice(index, 1)
              this.priceSelectorsCopy = _.cloneDeep(this.priceSelectors)
              this.$store.commit('settings/allSettingsPriceSelectorsUpdated', _.cloneDeep(this.getPriceSelectorsForSaving()))
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    toggleClientInformation (index) {
      this.setWidth()
      if (this.isShowErrorName) {
        return
      }
      if (this.selectedIndex === index) {
        this.updatePurchases()
        this.selectedIndex = -1
      } else {
        if (this.selectedIndex !== -1 && this.selectedIndex !== null) {
          this.updatePurchases(this.selectedIndex)
        }
        this.selectedIndex = index
      }
      this.setPartsTypes()
      this.disableAutoComplete()
      this.ruleName = ''
    },
    disableAutoComplete () {
      if (this.selectedIndex !== -1) {
        this.$nextTick(() => {
          this.disableAutoCompleteForMultiselect('disc-price-multiselect-')
          this.disableAutoCompleteForMultiselect('cost-price-multiselect-')
          this.disableAutoCompleteForMultiselect('profit-value-multiselect-')
          this.disableAutoCompleteForMultiselect('mark-up-multiselect-')
          this.disableAutoCompleteForMultiselect('charge-price-multiselect-')
          this.disableAutoCompleteForMultiselect('list-price-multiselect-')
          this.disableAutoCompleteForMultiselect('stock-status-multiselect-')
        })
      }
    },
    disableAutoCompleteForMultiselect (ref) {
      if (this.$refs[ref + this.priceSelectors[this.selectedIndex]?.id]?.[0]?.$el?.children?.[1]?.children?.[2]?.getAttribute('autocomplete') !== 'one-time-code') {
        this.$refs[ref + this.priceSelectors[this.selectedIndex].id][0].$el.children[1].children[2].setAttribute('autocomplete', 'one-time-code')
      }
    },
    updateSettings () {
      let newData = {
        settings: {
          'rfq': {
            enablePurchasePriceSelector: this.settings.rfq.enablePurchasePriceSelector,
            tableDisplayPinnedImages: {
              active: this.settings.rfq.tableDisplayPinnedImages.active,
            }
          }
        }
      }

      Axios.post('/ir/company/update-settings',
          newData
      )
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success('The RFQ Settings have been updated')
              this.$store.dispatch('settings/loadSettings')
            } else {
              this.$toast.error('The RFQ Settings haven\'t been updated')
            }
          })
          .catch(error => {
            this.$toast.error('The RFQ Settings haven\'t been updated')
            console.log(error)
          })
    },
    loadSettings () {
      this.settings = {
        rfq: {
          enablePurchasePriceSelector: this.getSettings?.rfq?.enablePurchasePriceSelector ? true : false,
          tableDisplayPinnedImages: {
            active: this.getSettings?.rfq?.tableDisplayPinnedImages?.active ? this.getSettings.rfq.tableDisplayPinnedImages.active : false,
          }
        }
      }
    },
    onRFQTabChange (tab) {
      this.$emit('changedRfqTab', tab.tab.id)
      localStorage.setItem('settings.tabs.defaultRepairerRfqSettingsTab', tab.tab.id)
    },
    onRFQTabRulesChange (tab) {
      this.$emit('changedRfqTabNewRule', tab.tab.id)
      localStorage.setItem('settings.tabs.defaultRepairerRfqSettingsTabNewRule', tab.tab.id)
    },
  }
}
</script>

<style scoped>
.color-green {
  color: #2DCA73 !important;
}

.color-red {
  color: red !important
}

.remove-rule-btn {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 700;
  background: none;
  margin-left: 5px;
  margin-right: 5px;
}

.remove-rule-btn:hover {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
  background: none;
}

.selected-element {
  background-color: rgb(233, 248, 249) !important;
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.theme-repairer label.checkbox > input[type="checkbox"]:disabled + span.icon {
  border-color: lightgray !important;
  cursor: not-allowed;
}

label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
  color: lightgray !important;
  cursor: not-allowed;
}

.rule-builder-block {
  width: 710px;
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 3px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.tooltip-each-price-information-block {
  position: absolute;
  width: 390px;
  right: -420px;
  top: 0;
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 3px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.tooltip-each-price-information-input {
  max-width: 120px;
  min-width: 120px;
  padding-left: 15px !important;
}

.tooltip-each-price-information-line {
  border-bottom: 3px solid rgba(27, 30, 56, 0.22) !important;
}

.tooltip-each-price-information-block .font-weight-600 {
  font-weight: 600;
}

.tooltip-each-price-information-block .font-weight-900 {
  font-weight: 900;
}

.purchase-price-selector .block-line {
  width: 100%;
  border-bottom: 3px solid rgba(27, 30, 56, 0.22) !important;
  margin-bottom: 15px;
}

.display-mode-multiselect {
  width: 140px;
  margin-right: 15px;
}

.profit-difference-multiselect {
  width: 120px;
  margin-right: 15px;
}

.add-new-rule-btn {
  font-size: 1rem;
  font-weight: bold;
  color: #79B1BB;
  cursor: pointer;
}

.purchase-price-selector-client-list {
  width: 100%;
}

.purchase-price-selector-client-list .client-block {
  display: flex;
  justify-content: space-between;
  border: 1px solid #79B1BB;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  color: #1C1F39;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  padding: 10px;
}

.purchase-price-selector-client-list .client-block span {
  font-size: 13px !important;
}

.purchase-price-selector-client-list .bx-up-arrow,
.purchase-price-selector-client-list .bx-down-arrow {
  font-size: 20px;
}

.rule-parameters-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 5px;
  border: 1px solid rgba(28, 31, 57, 0.25);
  cursor: context-menu;
}

.rule-parameters-first-text {
  width: 20px;
  margin-right: 7px;
  margin-left: 7px;
}

.rule-parameters-first-text-set-mark-up {
  width: 271px;
  margin-right: 15px;
  margin-left: 15px;
}

.rule-parameters-first-text-accept-rebate {
  width: 200px;
  margin-right: 15px;
  margin-left: 15px;
}

.rule-parameters-second-text {
  width: 100%;
}
.rule-parameters-second-text-center{
  text-align: center;
}
.rule-parameters-second-input-margin{
  margin: 0 5px;
}

.rule-parameters-firts-input {
  width: 80px;
  height: 27px;
  margin-right: 15px;
  text-align: center;
}

.rule-builder-input {
  width: 75px;
  margin-right: 15px;
  text-align: center;
}

.rule-builder-text {
  margin-right: 15px;
  white-space: nowrap;
}

.rule-parameters-second-input {
  width: 92px;
  height: 27px;
}

.rule-parameters-supplier-input {
  width: 200px;
  height: 27px;
  margin-right: 15px;
  margin-left: 15px;
}

.purchase-price-selector-page .rule-parameters-menu-context {
  width: 100px;
}

.purchase-price-selector-page .rule-parameters-menu-context ul {
  padding: 0px !important;
}

.purchase-price-selector-page .rule-parameters-menu-context li {
  padding-left: 15px !important;
  font-weight: bold;
}

.purchase-price-selector-page .rule-parameters-menu-context ul li:hover {
  background-color: rgba(41, 187, 193, 0.1) !important;
  color: #79B1BB !important;
}

.purchase-price-selector-page .rule-parameters-row.disabled-block,
.purchase-price-selector-page .rule-parameters-row.disabled-block input {
  background-color: #f2f2f2 !important;
  color: rgba(0, 0, 0, .5);
}

.accept-rebate-supplier-multiselect {
  width: 200px;
  margin-right: 15px;
}

.border-for-name {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0 0px 1px 0px #79B1BB, 0 0 3px 1px #79B1BB;
  }
}

@media screen and (max-width: 768px) {
  .rule-builder-block {
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .rule-builder-block div {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .rule-builder-block div span,
  .rule-builder-block div div,
  .rule-builder-block div input {
    margin-top: 5px;
  }
  .tooltip-each-price-information-block {
    position: static;
    width: auto;
  }
}
</style>

<style>
.part-type-number-select .multiselect__content-wrapper {
  min-height: 162px;
}
.part-type-matching-select .multiselect__content-wrapper {
  min-height: 202px;
}
.rule-discounted-price .multiselect__tags {
  height: auto;
}

.remove-rule-modal {
  width: 360px !important;
}

.settings-page .purchase-price-selector .purchase-price-selector-client-list .multiselect--active {
  z-index: 99999;
}

.settings-page .purchase-price-selector .purchase-price-selector-client-list .rules-tabs .nav-tabs.nav-tabs-2 {
  padding: 0;
}

.accept-rebate-supplier-multiselect .multiselect__input, .multiselect__single {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
