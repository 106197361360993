/*eslint-disable */
import Axios from 'axios'
import _ from 'lodash'
import {
  SUPPLIER_ORDER_IDS_UPDATE_OPEN,
  SUPPLIER_ORDER_IDS_UPDATE_PROCESSING,
  SUPPLIER_ORDER_IDS_UPDATE_PARTS_ON_ROUTE,
  SUPPLIER_ORDER_IDS_UPDATE_PARTS_DELIVERED,
  SUPPLIER_ORDER_IDS_UPDATE_COMPLETED,
  SUPPLIER_ORDERS_UPDATED,
  SUPPLIER_REINSTALLATION,
  SUPPLIER_ORDERS_FILTER_UPDATE,
  SUPPLIER_ORDERS_SHORT_UPDATING
} from '../../../mutation-types'

export function getOrdersByIds (context, payload) {
  Axios.post('/ir/supplier/orders', { ids: payload })
    .then(response => {
      context.commit(SUPPLIER_ORDERS_UPDATED, response.data.orders)
    })
    .catch(error => {
      console.log(error)
    })
}

export function setFilter (context, filter) {
  context.commit(SUPPLIER_ORDERS_FILTER_UPDATE, filter)
}

export function reinstallation (context) {
  context.commit(SUPPLIER_REINSTALLATION)
}

export function getOrdersForTableView (context, payload = 0) {

  let url = '/ir/supplier/orders/init'
  if (!_.isEmpty(payload)) url = '/ir/supplier/orders/' + payload

  Axios.get(url)
    .then(response => {
      if (response.data._status) {
        context.commit(SUPPLIER_ORDERS_UPDATED, {data: response.data.orders, payload: payload})
      }
      //  let ids = [];
      //  if (response.data.ids.Open) {
      //     // context.commit(SUPPLIER_ORDER_IDS_UPDATE_OPEN, response.data.ids.Open);
      //      ids=_.concat(ids, response.data.ids.Open);
      //  }
      //  if (response.data.ids.Processing) {
      //  //    context.commit(SUPPLIER_ORDER_IDS_UPDATE_PROCESSING, response.data.ids.Processing);
      //      ids=_.concat(ids, response.data.ids.Processing);
      //  }
      //  if (response.data.ids.Shipped) {
      // //     context.commit(SUPPLIER_ORDER_IDS_UPDATE_PARTS_ON_ROUTE, response.data.ids.Shipped);
      //      ids=_.concat(ids, response.data.ids.Shipped);
      //  }
      //  if (response.data.ids.Delivered) {
      //    //  context.commit(SUPPLIER_ORDER_IDS_UPDATE_PARTS_DELIVERED, response.data.ids.Delivered);
      //      ids=_.concat(ids, response.data.ids.Delivered);
      //  }
      //  if (response.data.ids.Completed) {
      //  //    context.commit(SUPPLIER_ORDER_IDS_UPDATE_COMPLETED, response.data.ids.Completed);
      //      ids=_.concat(ids, response.data.ids.Completed);
      //  }
      //  if (ids.length > 0) {
      //      getOrdersByIds(context, ids);
      //  }
      // console.log('ids', ids);
    })
    .catch(error => {
      console.log(error)
    })
}

export function init (context, payload = 0) {
  return getOrdersForTableView(context, payload)
}

export function shortUpdating (context, payload = 0) {
  return updatingOrders(context, payload)
}

export function updatingOrders (context, payload = 0) {
  let url = '/ir/supplier/orders/init'
  if (!_.isEmpty(payload)) url = '/ir/supplier/orders/' + payload

  Axios.get(url)
      .then(response => {
        if (response.data._status) {
          context.commit(SUPPLIER_ORDERS_SHORT_UPDATING, {data: response.data.orders, payload: payload})
        }
      })
      .catch(error => {
        console.log(error)
      })
}

