/*eslint-disable */
import Axios from "axios";
import {PART_LIST_LOAD_INFO, PART_LIST_REINSTALLATION} from '../../mutation-types';

export function loadPartList(context) {
    try {
        Axios.get('/ir/common/partlist')
            .then(response => {
                if (response.data && response.data.partList) {
                    context.commit(PART_LIST_LOAD_INFO, response.data.partList);
                }
            })
            .catch(error => {
                console.log(error);
            });
    } catch (e) {
        console.log(e);
    }
};

export function reinstallation (context) {
    context.commit(PART_LIST_REINSTALLATION)
}
