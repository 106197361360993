<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <h1 style="cursor: pointer; width: 200px" @click="$router.push('/')" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></h1>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="big-icon">
                        <i class="bx bxs-sad" style="color:#FF5E5E"></i>
                    </div>
                    <h1>We Found a Problem</h1>
                    <p>The account you are trying to setup may already exist under this email address,</p>
                </div>
                <div class="frame">
                    Think you may registered an account with us before?
                    <router-link
                        :to="'/register/supplier/find-account'"
                    >Help me find my account</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style scoped>
.frame {
    margin-top: 15px;
    line-height: 26px;
}

.intro-text {
    line-height: 26px;
}
.big-icon {
    margin: 0 auto;
    margin-bottom: 5px;
}
h1 {
    font-weight: bold;
    margin-bottom: 15px;
}
</style>
<script>
export default {
    name: "SupplierFoundProblem",
    components: {}
};
</script>
