<template>
  <div class="position-notification repairer-dashboard-notifications">
      <div class="position-notification-item" id="chatMsg" style="padding: 0px"
           ref="rfq-view-tabs">
        <div class="dashboard-panel card">
          <div class="dashboard-panel-title">
            <div class="dashboard-panel-title-text">
              <span>Chat Msg</span>
              <span
                  v-if="computedCustomCountForTab['chatMsg']"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip tabs-tooltip-custom', html: true, title:computedCustomTooltipsForTab['chatMsg'], placement:'rightbottom'}"
                  class="dashboard-tab-number">
                {{ computedCustomCountForTab['chatMsg'] }}
              </span>
            </div>
            <div class="dashboard-panel-title-separator"></div>
          </div>
          <div class="search">
            <div class="input-text">
              <input
                  type="text"
                  placeholder="Search here"
                  v-model="filter"
                  class="form-control search-input form-control"
              />
            </div>
          </div>
          <div class="card-body">
            <!--          <div class="card-title">-->
            <!--            <h4 class="title-text">Chat Msg</h4>-->
            <!--          </div>-->
            <div class="notifications-list">

              <ul class="notifications-list-scroll">
                <li v-for="itm in messagesFiltered" @click="redirectToRfq(itm)" :class="{'active': !itm.viewedOn}">
                  <div class="title">
                    <div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span>{{getOrderNumber(itm.orderId)}} - {{formatDateTime2(itm.createdOn)}}</span></div>
                    <!--                  <div class="date">Today,2:00 PM</div>-->
                  </div>
                  <div class="text">{{itm.message}}</div>
<!--                  <div class="li-icon li-icon-pdf li-icon-display-none"><i class='bx bxs-file-pdf'></i></div>-->
<!--                  <div class="li-icon li-icon-bell li-icon-display-none"><i class='bx bx-bell'></i></div>-->
<!--                  <div class="li-icon-unread">3</div>-->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="position-notification-item" id="notifications" style="padding: 0px">
        <div class="dashboard-panel card">
          <div class="dashboard-panel-title">
            <div class="dashboard-panel-title-text">
              <span>Notifications</span>
              <span
                  v-if="computedCustomCountForTab['notifications']"
                  class="dashboard-tab-number">
                {{ computedCustomCountForTab['notifications'] }}
              </span>
            </div>
            <div class="dashboard-panel-title-separator"></div>
          </div>
          <div class="search">
            <div class="input-text">
              <input
                  type="text"
                  placeholder="Search here"
                  v-model="filter2"
                  class="form-control search-input form-control"
              />
            </div>
          </div>
          <div class="card-body">
            <div class="notifications-list" style="padding: 0 15px;">
              <notifications-component ref="notificationsComponent" :activity="computedActivityListFiltered"></notifications-component>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>
@import url(../../../../styles/dashboard/style.css);

.position-notification{
  position: fixed;
  width: 700px;
  display: flex;
}
.position-notification >>> .tabs-component{
  border-radius: 5px 5px 0 0;
}
.position-notification-item{
  width: 330px;
  margin: 0 10px;
}
.dashboard-panel{
  border-radius: 0 0 5px 5px;
}
.dashboard-panel-title{
  width: 50%;
  max-width: 165px;
  margin: 10px 1.25rem 0;
  font-weight: bold;
}
.dashboard-panel-title-text{
  padding: 10px 20px;
  text-align: center;
}
.dashboard-panel-title-separator{
  border-top: 5px solid #78b0ba;
  height: 3px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.search .input-text,
input.form-control.search-input {
    height: 31px;
    width: 100%;
}

.search .input-text {
    margin-right: 0;
}
.search {
    width: 100%;
    padding: 10px 1.25rem 0;
    margin-bottom: 15px;
}
.notifications-list{
    max-height: calc(100vh - 285px);
    overflow-y: scroll;
    border-radius: 0 0 5px 5px;
}
.notifications-list ul {
    padding: 0 15px;
    margin: 0;
    list-style: none;

}

.notifications-list ul li {
    padding: 7px 10px;
    border-radius: 3px;
    border: 2px solid #D7D7D7FF;
    min-height: 55px;
    cursor: pointer;
    position: relative;
}
.notifications-list ul li:hover{
  border: 2px solid #bdbdbd;
}

.notifications-list ul li.active{
  border: 2px solid #7bbec0
}
.notifications-list ul li.active:hover{
  border: 2px solid #3E8385FF
}
.li-icon{
  font-size: 14px;
  color: #A1A0A0FF;
}
.li-icon{
  position: absolute;
  right: 5px;
  bottom: 0;
}
.notifications-list ul li.active .li-icon{
  color: #2e6ba3;
}
.li-icon-unread{
  font-size: 10px;
  height: 14px;
  width: 14px;
  text-align: center;
  color: #fafafa;
  background-color: #A1A0A0FF;
  border-radius: 50px;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.notifications-list ul li.active .li-icon-unread{
  background-color: #95cbcc;
}
.li-icon-display-none{
  display: none;
}

.notifications-list ul li.active .title {
    font-size: 11px;
    line-height: 16px;
    font-weight: bold;
    color: #1c1f39;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notifications-list ul li .title {
  font-weight: 500;
}
.notifications-list ul li .title .numb,
.notifications-list ul li .date {
  font-weight: 500;
}

.notifications-list ul li.active .title .numb,
.notifications-list ul li .date {
    font-weight: 600;
}

.notifications-list ul li .text {
    margin-top: 5px;
    line-height: 16px;
    font-size: 11px;
    font-weight: 500;
    color: #1c1f39;
    padding-right: 13px;
}

.notifications-list ul li + li {
    margin-top: 10px;
}
.card-body{
    padding: 0;
}
@media (max-width: 1780px) {
  .position-notification {
    position: static;
    width: 100%;
  }
  .position-notification-item {
    position: static;
    width: 50%;
  }
  .notifications-list{
    max-height: 400px;
    min-height: 150px;
  }
  .position-notification{
    padding: 0 30px;
    background-color: #ffffff;
  }
}
</style>

<style>
.repairer-dashboard-notifications .card-body .notifications-list .notification_item {
  margin: 0px;
  min-height: 55px;
  padding: 7px 10px;
}

.repairer-dashboard-notifications .card-body .notifications-list .notification_item+.notification_item {
  margin-top: 10px;
}

.repairer-dashboard-notifications .card-body .notifications-list .notification_item-info {
  margin-top: 5px;
  line-height: 16px;
}
</style>

<script>
import {mapGetters} from "vuex";
import * as dayjs from "dayjs";
import _ from "lodash";
import NotificationsComponent from '../../../../components/utility/notifications/notifications'

export default {
    name: "DashboardNotifications",
    mounted() {
      this.isMounted = true
    },
    components: {
      NotificationsComponent
    },
  data() {
    return {
      filter: '',
      filter2: '',
      isMounted: false,
    }
  },
  methods: {
    getOrderNumber(id) {
      let order = _.find(this.gettersAllOrders, (o) => {
        return Number(o.id) === Number(id)
      })
      if (order) {
        return ' - ' + order.number
      }
      return ''
    },
    formatDateTime2 (val) {
      let format = 'DD/MM'
      if (dayjs(val).isToday()) {
        format = 'hh:mmA'
      }
      return (val) ? dayjs(val).format(format) : ''
    },
    redirectToRfq(itm) {
      // if (_.isEmpty(itm.viewedOn)) {
      //   console.log(itm.viewedOn, itm.id, 'viewed on')
      //   Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
      //     messageIds: [itm.id]
      //   })
      // }

      if (itm.creditId) {
        this.$router.push({name: 'CreditManagementView', params: {creditId: itm.creditId, isNeedOpenChat: true}})
      } else if (itm.orderId) {
        this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.orderId, isNeedOpenChat: true}})
      } else if (itm.rfqId) {
        this.$router.push({name: 'RepairerRFQView', params: {rfq_id: itm.rfqId, isNeedOpenChat: true}})
      }
    },
    getRfqNumber(id) {
      let rfq = _.find(this.gettersAllRFQs, (r) => {
        return Number(r.id) === Number(id)
      })
      if (rfq) {
        return ' - ' + rfq.number
      }
      return ''
    },
    formatDateTime (val) {
      return (val) ? dayjs(val).format('DD/MM/YYYY || hh:mm') : ''
    },
    getNotificationsCount() {
      return this.isMounted ? this?.$refs?.notificationsComponent?.computedActivity?.length : 0
    }
  },
  computed: {
    ...mapGetters({
      gettersAllRFQs: 'repairer/rfq/getAllRFQs',
      messages: 'repairer/dashboard/getMessages',
      getActivityList: 'repairer/dashboard/getActivityList',
      gettersAllOrders: 'repairer/order/getAllOrders',
    }),
    computedActivityList() {
      return this.getActivityList
    },
    computedActivityListFiltered() {
      return _.orderBy(_.filter(this.computedActivityList, (m) => {
        return _.includes(_.trim(_.toLower(m?.company?.name)), _.trim(_.toLower(this.filter2)))
      }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
    },
    messagesFiltered() {
      return _.orderBy(_.filter(this.messages, (m) => {
        return _.includes(_.trim(_.toLower(m.companyFrom)), _.trim(_.toLower(this.filter))) || _.includes(_.trim(_.toLower(m.QPNumber)), _.trim(_.toLower(this.filter)))
      }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
    },
    computedCustomCountForTab() {
      return {
        'notifications': this.getNotificationsCount(),
        'chatMsg': _.filter(this.messages, (m) => {return _.isEmpty(m.viewedOn)}).length
      }
    },
    computedCustomTooltipsForTab() {
      return {
        'chatMsg': this.messagesFiltered.length == 1 ? 'Unread Chat Message': 'Unread Chat Messages'
      }
    },
  }
};
</script>
