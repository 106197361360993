/*eslint-disable */
import {
    REPAIRER_ORDERS_FILTER_UPDATE,
    REPAIRER_ORDERS_UPDATE_ALL,
    REPAIRER_ORDERS_PER_PAGE,
    REPAIRER_SET_CURRENT_ORDER_ID,
    REPAIRER_SET_IS_NEED_UPDATE,
    REPAIRER_UPDATE_CURRENT_ORDER,
    REPAIRER_ORDERS_SET_TOKEN,
    REPAIRER_ORDERS_SET_SEARCH_FILTER_OBJ,
    REPAIRER_ORDERS_SET_TOKENS,
    REPAIRER_CURRENT_ORDERS_CURRENT_PAGE,
    REPAIRER_COMPLETED_ORDERS_CURRENT_PAGE,
    REPAIRER_BACK_ORDERS_CURRENT_PAGE
} from '../../../mutation-types';
import _ from "lodash";

export default {
    [REPAIRER_ORDERS_SET_TOKEN](state, ui) {
        state.token.splice(0, state.token.length);
        _.forEach(ui, itm => {
            state.token.push(itm)
        })
    },
    [REPAIRER_ORDERS_SET_TOKENS](state, ui) {
        for (let key in state.tokens) {
            delete state.tokens.key
        }
        for (let key in ui) {
            state.tokens[key] = ui[key];
        }
    },
    [REPAIRER_ORDERS_SET_SEARCH_FILTER_OBJ](state, ui) {
        state.searchFilterObj = ui;
    },
    [REPAIRER_UPDATE_CURRENT_ORDER](state, ui) {
      if (!state.currentOrderId) {
          return;
      }
      let currentOrder = _.find(ui, (ord) => {
          return Number(ord.id) === Number(state.currentOrderId)
      });
        console.log('set.mutation.currentOrder', currentOrder )
      if (currentOrder) {
          state.isNeedUpdate = true;
      }
    },
    [REPAIRER_SET_IS_NEED_UPDATE](state, ui) {
        console.log('set.mutation.isNeedUpdate', ui )
        state.isNeedUpdate = ui;
    },
    [REPAIRER_SET_CURRENT_ORDER_ID](state, ui) {
        console.log('set.mutation.order.id', ui )
        state.currentOrderId = ui;
    },
    [REPAIRER_ORDERS_UPDATE_ALL](state, ui) {
        state.items = _.unionBy(ui, state.items, 'id');
        if (!_.isEmpty(ui)) {
            state.ordersFromUpdate = ui;
        }
    },
    [REPAIRER_ORDERS_FILTER_UPDATE](state, ui){
        state.filter = ui;
    },
    [REPAIRER_CURRENT_ORDERS_CURRENT_PAGE](state, ui) {
        state.currentPageCurrent = ui;
    },
    [REPAIRER_BACK_ORDERS_CURRENT_PAGE](state, ui) {
        state.currentPageBackOrder = ui;
    },
    [REPAIRER_COMPLETED_ORDERS_CURRENT_PAGE](state, ui) {
        state.currentPageCompleted = ui;
    },
    [REPAIRER_ORDERS_PER_PAGE](state, ui) {
        state.perPage = ui;
    },
};
