<template>
  <div class="supplier-credit-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>Credit Management</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'SupplierDashboard'}">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active">View Credit Management</li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <window-notification></window-notification>
          <search-filter
              type="estimates"
              v-model="searchFilter2"
              :filterValue="searchFilter2"
              :additional-menu="additionalMenu"
              :placeholder="'Search'"
              :isSearch="true"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :isMyltipleOptions="true"
              :isMyltiple="true"
              :optionsLength="8"
          />
        </div>
      </div>
    </div>
    <div class="supplier-credit-body">
      <tabs ref="supplier-credit-table" cache-lifetime="0" :options="{useUrlFragment: false}" @changed="onCreditsTabChange"
            :custom-counts="computedCustomCountForTab">
        <tab name="All" id="all" :class-name="'tab-content-table'">
          <credit-table-view :items="getterAllCredits"
                             :propsPerPage="getterPerPage"
                             :propsCurrentPage="getterAllCreditsCurrentPage"
                             :filter="filter"
                             :searchFilterObj="searchFilterObj"
                             @changedPerPage="value => $store.commit('supplier/credit/supplierCreditPerPage', value)"
                             @changedCurrentPage="value => $store.commit('supplier/credit/supplierCreditAllCurrentPage', value)"
                             @updated="loadData()"
          />
        </tab>
        <tab name="Under Review" id="review" :class-name="'tab-content-table'">
          <credit-table-view :items="getterReviewCredits"
                             :propsPerPage="getterPerPage"
                             :propsCurrentPage="getterUnderCreditsCurrentPage"
                             :filter="filter"
                             :searchFilterObj="searchFilterObj"
                             @changedPerPage="value => $store.commit('supplier/credit/supplierCreditPerPage', value)"
                             @changedCurrentPage="value => $store.commit('supplier/credit/supplierCreditUnderCurrentPage', value)"
                             @updated="loadData()"
          />
        </tab>
        <tab name="Approved" id="approved" :class-name="'tab-content-table'">
          <credit-table-view :items="getterApprovedCredits"
                             :propsPerPage="getterPerPage"
                             :propsCurrentPage="getterApprovedCreditsCurrentPage"
                             :filter="filter"
                             :searchFilterObj="searchFilterObj"
                             @changedPerPage="value => $store.commit('supplier/credit/supplierCreditPerPage', value)"
                             @changedCurrentPage="value => $store.commit('supplier/credit/supplierCreditApprovedCurrentPage', value)"
                             @updated="loadData()"
          />
        </tab>
        <tab name="Denied" id="denied" :class-name="'tab-content-table'">
          <credit-table-view :items="getterDeniedCredits"
                             :propsPerPage="getterPerPage"
                             :propsCurrentPage="getterDeniedCreditsCurrentPage"
                             :filter="filter"
                             :searchFilterObj="searchFilterObj"
                             @changedPerPage="value => $store.commit('supplier/credit/supplierCreditPerPage', value)"
                             @changedCurrentPage="value => $store.commit('supplier/credit/supplierCreditDeniedCurrentPage', value)"
                             @updated="loadData()"
          />
        </tab>
        <tab name="Cancelled" id="cancelled" :class-name="'tab-content-table'">
          <credit-table-view :items="getterCancelledCredits"
                             :propsPerPage="getterPerPage"
                             :propsCurrentPage="getterCancelledCreditsCurrentPage"
                             :filter="filter"
                             :searchFilterObj="searchFilterObj"
                             @changedPerPage="value => $store.commit('supplier/credit/supplierCreditPerPage', value)"
                             @changedCurrentPage="value => $store.commit('supplier/credit/supplierCreditCancelledCurrentPage', value)"
                             @updated="loadData()"
          />
        </tab>

        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :filterValue="filter"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="4"
                :isShowSearIcon="false"
                :placeholder="'Filter By'"
                @change="onSearchFilterChange"
                @updateToken="value => $store.commit('supplier/credit/setToken', value)"
                @updateTokens="value => $store.commit('supplier/credit/setTokens', value)"
                :tokenCopy="computedToken"
                :tokensCopy="getterTokens"
                :debug="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
            <!--                        <b-form-input v-model="filter" type="text" class="form-control search-input"-->
            <!--                                      placeholder="Search or Filter results"-->
            <!--                                      debounce="500"></b-form-input>-->
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CreditTableView from './credits/tab-table-view.vue'
import NProgress from 'nprogress'
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import _ from 'lodash'
import WindowNotification from "@/components/utility/window-notification.vue";

export default {
  name: 'SuppliersCreditManagement',
  components: {
    WindowNotification,
    CreditTableView,
    SearchFilter,
  },
  data: function () {
    return {
      searchFilter: '',
      searchFilter2: '',
      currentTab: '',
      // filter: '',
      showDenyReasonBox: false,
      perPage: 20,
    }
  },
  methods: {
    ...mapActions({
      changeCreditGroup: 'supplier/credit/changeCreditGroup',
      setFilter: 'supplier/credit/setFilter'
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter2)) {
          this.setFilter('')
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    onCreditsTabChange (selectedTab) {
      this.currentTab = selectedTab.tab.name;
    },
    onSearchFilterChange (filters) {
      this.$store.commit('supplier/credit/setSearchFilterObj', filters)
      if (filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
        // this.filter = filters.search;
      } else {
        this.setFilter('')
        // this.filter = '';
      }
      // console.log('onSearchFilterChange', filters);
    },
    loadData () {
      NProgress.start()
      this.$store.dispatch('supplier/credit/init').then(() => {
        NProgress.done()
      })
    }
  },
  mounted () {
    if (!this.filter) {
      this.filter = this.getterFilter
    }
  },
  watch: {
    filter (val) {
      this.$store.commit('supplier/credit/supplierCreditFilter', val)
    }
  },
  computed: {
    ...mapGetters({
      getterAllCredits: 'supplier/credit/getAllCredits',
      getterReviewCredits: 'supplier/credit/getUnderReviewCredits',
      getterApprovedCredits: 'supplier/credit/getApprovedCredits',
      getterDeniedCredits: 'supplier/credit/getDeniedCredits',
      getterCancelledCredits: 'supplier/credit/getCancelledCredits',
      getterFilter: 'supplier/credit/getFilter',
      getterPerPage: 'supplier/credit/getPerPage',
      getterAllCreditsCurrentPage: 'supplier/credit/getAllCreditsCurrentPage',
      getterUnderCreditsCurrentPage: 'supplier/credit/getUnderCreditsCurrentPage',
      getterApprovedCreditsCurrentPage: 'supplier/credit/getApprovedCreditsCurrentPage',
      getterDeniedCreditsCurrentPage: 'supplier/credit/getDeniedCreditsCurrentPage',
      getterCancelledCreditsCurrentPage: 'supplier/credit/getCancelledCreditsCurrentPage',
      getterSearchFilterObj: 'supplier/credit/getSearchFilterObj',
      getterToken: 'supplier/credit/getToken',
      gettersFilter: 'supplier/credit/getFilter',
      getterTokens: 'supplier/credit/getTokens',
      gettersAllRFQs: 'supplier/rfq/getAllRfqs',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter2)) {
        return []
      }
      let data = _.filter(this.gettersAllRFQs, (r) => {
        if (_.includes(this.searchFilter2, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter2.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter2.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter2)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    filter: {
      get () {
        return this.gettersFilter
      },
      set (value) {
        this.setFilter(value)
      }
    },
    computedAllCredits(){
       return this.getterAllCredits
    },
    computedCustomCountForTab () {
      let counts = {
        all: 0,
        review: 0,
        approved: 0,
        denied: 0,
        cancelled: 0,
      }
      counts.all = this.getterAllCredits.length
      counts.review = this.getterReviewCredits.length
      counts.approved = this.getterApprovedCredits.length
      counts.denied = this.getterDeniedCredits.length
      counts.cancelled = this.getterCancelledCredits.length
      return counts
    },
    computedToken () {
      return this.getterToken ? this.getterToken : []
    },
    computedSearchFilters () {
      let partNumbers = {}
      let customers = {}
      let orderNumbers = {}
      let credits = this.getterAllCredits.slice()

      credits.forEach(credit => {
        if (credit.partNumbers) {
          let numbers = credit.partNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }

      })

      credits.forEach(credit => {
        if (credit.orderNumber) {
          orderNumbers[credit.orderNumber.trim().toLowerCase()] = { label: credit.orderNumber.trim() }
        }
      })

      _.forEach(credits, (credit) => {
        if (customers.length >= 10){
          return false;
        }
        if (credit.repairerName) {
          customers[credit.repairerName.trim().toLowerCase()] = { label: credit.repairerName.trim() }
        }
      })

      customers = _.orderBy(Object.values(customers), ['label'], ['asc'])
      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      orderNumbers = _.orderBy(Object.values(orderNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      let statusOptions = [
        { label: 'Under Review' },
        { label: 'Partially Approved' },
        { label: 'Approved' },
        { label: 'Denied' },
        { label: 'Cancelled' },
      ]
      let result = [
        {
          id: 'customer',
          label: 'Customer',
          iconClass: '',
          isNeedSearch: true,
          options: [
            customers
          ]
        },
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: '',
          isNeedSearch: true,
          options: [
            partNumbers,
          ]
        },
        {
          id: 'orderNumber',
          label: 'Order Number',
          iconClass: '',
          isNeedSearch: true,
          options: [
            orderNumbers
          ]
        },
      ]
      if (this.currentTab === 'All') {
        result.push(  {
          id: 'creditStatus',
          label: 'Credit Status',
          iconClass: '',
          options: [
            statusOptions
          ]
        },)
      }
      return result;
    },
    searchFilterObj () {
      return this.getterSearchFilterObj
    },
  }
}
</script>
