/*eslint-disable */
import {USER_TYPE} from './currentUser';
import _ from "lodash";

export function isUserTypeRepairer(state) {
    if (state.type && state.type === USER_TYPE.REPAIRER) {
        return true;
    }
    if (state.type && state.type === 'repair') {
        return true;
    }
    return false;
}

export function getLocation(state, getters, rootState, rootGetters) {
    return _.find(rootGetters['currentCompany/getLocations'], (itm) => {
        return Number(itm.id) === Number(state.businessLocation);
    })
}

export function isPlayNotificationSound (state, getters, rootState, rootGetters) {
    if (getters.isUserTypeSupplier) {
        return rootGetters['supplier/rfq/getIsPlayNotificationSound']
    } else {
        return rootGetters['repairer/rfq/getIsPlayNotificationSound']
    }
}

export function isUserTypeSupplier(state) {
    return (state.type && state.type === USER_TYPE.SUPPLIER);
}

export function apiKeys(state) {
    return state.apiKeys;
}

export  function isHasActiveApiKey(state) {
    let isHasActiveKey = false;
    _.forEach(state.apiKeys, (k) => {
        if (k.hash && k.status) {
            isHasActiveKey = true;
        }
    });
    return isHasActiveKey;
}

export function isSoundNoticeNewRfq (state) {
    return state.isSoundNoticeNewRfq;
}

export function isSoundNoticeUpdatedPriceRequest (state) {
    return state.isSoundNoticeUpdatedPriceRequest;
}

export function isSoundNoticeQuoteAssigned (state) {
    return state.isSoundNoticeQuoteAssigned;
}

export function getMultipleBrandIds (state) {
    let ids = [];
    _.forEach(state.multipleBrands || [], (m) => {
        ids.push(m.id)
    })
    return ids
}

export function getCurrentUserInfo(state) {
    return {
        id: state.id,
        username: state.username,
        firstName: state.firstName,
        lastName: state.lastName,
        fullName: state.fullName,
        role: state.role,
        type: state.type,
        companyRole: state.companyRole,
        franchises: state.franchises,
        isActive: state.isActive,
        businessLocation: state.businessLocation,
        workPhone: state.workPhone,
        mobilePhone: state.mobilePhone,
        userPhoto: state.userPhoto,
        email: state.email,
        dmsId: state.dmsId,
        activeSessions: state.activeSessions,
        lastLoggedIn: state.lastLoggedIn,
        isEmailNoticeNewRfq: state.isEmailNoticeNewRfq,
        isEmailNoticeUpdatedPriceRequest: state.isEmailNoticeUpdatedPriceRequest,
        isEmailNoticeNewOrder: state.isEmailNoticeNewOrder,
        isSoundNoticeNewRfq: state.isSoundNoticeNewRfq,
        isSoundNoticeUpdatedPriceRequest: state.isSoundNoticeUpdatedPriceRequest,
        isEmailNoticeQuoteAssigned: state.isEmailNoticeQuoteAssigned,
        isSoundNoticeQuoteAssigned: state.isSoundNoticeQuoteAssigned,
        multipleBrands: state.multipleBrands,
    }
}

export function getCurrentUserFullName(state) {
    return state.firstName + ' ' + state.lastName;
}

export function getCurrentUserAvatar(state) {
    return state.userPhoto;
}

export function isAdministrator (state) {
    return state.role === "Administrator";
}

export function isPartsManager (state) {
    return state.role === 'Parts Manager';
}

export function isPartsInterpreter (state) {
    return state.role === 'Parts Interpreter';
}

export function isPartsCoordinator (state) {
    return state.role === 'Parts Coordinator';
}

export function isEstimator (state) {
    return state.role === 'Estimator';
}

export function getMultipleBrands(state){
    return (state.multipleBrands?.length>1)?state.multipleBrands:[]
}


