/*eslint-disable */
import {
    PART_LIST_LOAD_INFO,
    PART_LIST_REINSTALLATION
} from '../../mutation-types';

export default {
    [PART_LIST_REINSTALLATION](state) {
        state.items = [];
        state.categories = [];
    },
    [PART_LIST_LOAD_INFO](state, ui) {
        state.items = ui.names;
        state.categories= ui.categories;
    },
};
