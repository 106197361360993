/*eslint-disable */
import _ from "lodash"
export function getAllUsers(state) {
  return state.usersAll;
}

export function getAllUsersForAssignedTo(state){
  let users = [];
  _.forEach(state.usersAll, function(u){
    users.push({id: u.id, name: u.firstName+' '+u.lastName, isActive: u.isActive, businessLocation: u.businessLocation})
  })
  users = _.orderBy(users,['name'],['asc'])
  return users;
}
