/*eslint-disable */
import Axios from 'axios';
import _ from 'lodash'

import {
  SETTINGS_FETCH_ALL_USERS,
  REINSTALLATION_USERS,
  SETTINGS_SET_SELECTED_USER,
  SETTINGS_APPEND_NEW_USER,
  SETTINGS_CLEAR_USERS
} from '../../mutation-types';

export function reinstallation (context, payload) {
  context.commit(REINSTALLATION_USERS)
}

export function setSelectedUser(context, payload) {
  // context.commit(SETTINGS_SET_SELECTED_USER, payload)
}

export function appendNewUser(context) {

}

export function removeEmptyUser(context) {

}

export function fetchAllUsers(context) {
  Axios.get('/ir/users')
    .then((response) => {
      if (response.data && response.data.users)
        context.commit(SETTINGS_FETCH_ALL_USERS, response.data.users);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function fetchUserById(context, payload) {
  const userId = payload;
  Axios.get(`ir/user/${userId}`)
  .then(response => {
    console.log(response)
  }).catch(error => {
    console.log(error)
  })
}

export function createNewUser(context, payload) {
  return new Promise((resolve, reject) => {
    Axios.post('/ir/user/new', payload)
    .then(response => {
      if(response.data.status) {
        fetchAllUsers(context);
      }
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  })

}

export function updateUser(context, payload) {
  const userId = payload.id;
  const updatedUser = payload.updatedUser;

  return new Promise((resolve, reject) => {
    Axios({
      method: 'post',
      responseType: 'json',
      url: `/ir/user/${userId}`,
      validateStatus: function (status) {
        return status < 500;
      },
      data: _.cloneDeep({
        username: updatedUser.username,
        email: updatedUser.email,
        companyRole: updatedUser.companyRole,
        isActive: updatedUser.isActive,
        isVisible: updatedUser.isVisible,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        businessLocation: updatedUser.businessLocation,
        workPhone: updatedUser.workPhone,
        mobilePhone: updatedUser.mobilePhone,
        userPhoto: updatedUser.userPhoto,
        groupId: updatedUser.groupId,
        dmsId: updatedUser.dmsId,
        isEmailNoticeNewRfq: updatedUser.isEmailNoticeNewRfq,
        isEmailNoticeQuoteAssigned: updatedUser.isEmailNoticeQuoteAssigned,
        isSoundNoticeQuoteAssigned: updatedUser.isSoundNoticeQuoteAssigned,
        isEmailNoticeUpdatedPriceRequest: updatedUser.isEmailNoticeUpdatedPriceRequest,
        isEmailNoticeNewOrder: updatedUser.isEmailNoticeNewOrder,
        isSoundNoticeNewRfq: updatedUser.isSoundNoticeNewRfq,
        franchisesId: updatedUser.franchises,
        isSoundNoticeUpdatedPriceRequest: updatedUser.isSoundNoticeUpdatedPriceRequest,
      })
    })
    .then(response => {
      if (response.data.status) {
        fetchAllUsers(context);
        resolve(response);
      }
    })
    .catch(error => {
      reject(error);
    });
  })
}

export function removeUserById(context, payload) {
  const userId = payload;

  return new Promise((resolve, reject) => {
    Axios.post('/ir/user/delete', {
      id: userId,
      status: 'delete'
    })
    .then(response => {
      if(response.data.status) {
        fetchAllUsers(context);
        resolve(response);
      }
    })
    .catch(error => {
      reject(error);
    });
  })

}

export function closeActiveSessions(context, payload) {
  const userId = payload;

  return new Promise((resolve, reject) => {
    Axios.post('/ir/user/' + userId + '/closeActiveSessions')
    .then(response => {
      fetchAllUsers(context);
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export function updatePasswordById(context, payload) {
  const id = payload.id;
  const password = payload.password;
  const confirmNewPassword = payload.confirmNewPassword;

  return new Promise((resolve, reject) => {
    Axios({
      method: 'post',
      responseType: 'json',
      url: `/ir/user/${id}/changePassword`,
      validateStatus: function (status) {
        return status < 500;
      },
      data: _.cloneDeep({
        newPassword: password,
        confirmNewPassword: confirmNewPassword,
        id: id
      })
    })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export function uploadImageByPhotoId(context, payload) {
  return new Promise((resolve, reject) => {
    Axios.post(`/ir/user/${payload}/photo/upload`)
    .then((response) => {
      if(response.status === 200) {
        fetchAllUsers(context);
        resolve(response);
      }
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function removeUserPhotoById(context, payload) {
  return new Promise((resolve, reject) => {
    Axios.post(`/ir/user/${payload}/photo/delete`)
        .then((response) => {
          if(response.status === 200) {
            fetchAllUsers(context);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
  })
}

