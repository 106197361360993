<template>
  <div class="row settings-business-profile-tab business-profile-page">
    <tabs ref="business-profile-tabs"
          :options="{ useUrlFragment: false, defaultTabHash: computedDefaultBusinessProfileHash }"
          class="business-profile-tabs" @changed="onBusinessTabChange">
      <tab name="Details" id="business-details" class-name="content-table">
        <div class="row business-details">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">Details</div>
              <div class="card-block mt-3">
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Account ID</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.displayId" :readonly="true" :disabled="true" type="text"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Entity Name</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.name" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Business Name</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.businessName" type="text" @input="changedBrand(false)"
                           @change="changedBrand(false)"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">ABN</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.abn" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">ACN</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.acn" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder="ACN"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Supplier Vehicle Parts Category</label>
                  <div class="col-lg-8">
                    <multiselect
                        v-model="companyInfo.supplierVehicleCategory"
                        :options="['CAR', 'TRUCK']"
                        :showLabels="false"
                        :option-height="29"
                        :disabled="true"
                        :value="'CAR'"
                        :max-height="203"
                        :close-on-select="true"
                        placeholder="Supplier Vehicle Parts Category"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">
                    <span v-if="companyInfo.supplierTypes.length > 1">Business Types</span>
                    <span v-else>Business Type</span>
                  </label>
                  <div class="col-lg-8">
                    <input
                        v-for="(type, index) in companyInfo.supplierTypes"
                        :key="index"
                        :value="getType(type)"
                        :class="{
                          'business-type-input': index + 1 !== companyInfo.supplierTypes.length
                        }"
                        type="text"
                        class="form-control"
                        readonly
                        disabled/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Phone</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.phone" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">Fax</label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.fax" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label col-lg-4">
                    <span v-if="isUserTypeSupplier">
                      Parts Email
                    </span>
                    <span v-else>
                      Email
                    </span>
                  </label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.email" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                    <small
                        class="col-form-label form-text show-help-for-body"
                        v-if="!$v.companyInfo.email.email"
                    >Please enter a valid email address</small>
                  </div>
                </div>
                <div class="form-group row" v-if="companyInfo && isUserTypeSupplier">
                  <label class="col col-form-label col-lg-4">
                    <span v-if="isUserTypeSupplier">
                      Orders Email
                    </span>
                  </label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.emailOrders" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                    <small
                        class="col-form-label form-text show-help-for-body"
                        v-if="!$v.companyInfo.emailOrders.email"
                    >Please enter a valid email address</small>
                  </div>
                </div>
                <div class="form-group row" v-if="companyInfo && isUserTypeSupplier">
                  <label class="col col-form-label col-lg-4">
                    <span>
                      Credits Email
                    </span>
                  </label>
                  <div class="col-lg-8">
                    <input v-model="companyInfo.emailCredits" type="text" @change="changedBrand(false)"
                           class="form-control" placeholder/>
                    <small
                        class="col-form-label form-text show-help-for-body"
                        v-if="!$v.companyInfo.emailCredits.email"
                    >Please enter a valid email address</small>
                  </div>
                </div>
                <div v-if="isUserTypeSupplier" class="form-group row">
                  <label class="col col-form-label col-lg-4">Description</label>
                  <div class="col-lg-8">
                    <textarea class="form-control" rows="4" v-model="companyInfo.about"
                              @change="changedBrand(false)"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <tabs ref="addresses" :options="{ useUrlFragment: false }" class="business-profile-address-tabs">
              <tab name="Primary Business Address" id="primary-business-address" ref="primaryBusinessAddress"
                   class-name="tab-content-table">
                <div class="card">
                  <div class="card-header">Primary Business Address</div>
                  <div class="card-block mt-3">
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Search Address</label>
                      <div class="col-lg-8">
                        <google-location
                            type="text"
                            @onPlaceChange="addressChanged"
                            v-model="companyInfo.address.address"
                            id="location_my_account"
                            placeholder="Search for an Address"
                        ></google-location>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Unit/Level</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.unitLevelLot" type="text" @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Address Line</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.street" type="text" @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Suburb</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.suburb" type="text" @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">State</label>
                      <div class="col-lg-8">
                        <multiselect
                            v-model="companyInfo.address.state"
                            :options="addressStates"
                            track-by="value"
                            label="value"
                            :showLabels="false"
                            :option-height="29"
                            @input="changedBrand(false)"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select state"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Postcode</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.address.postcode" type="text" @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Country</label>
                      <div class="col-lg-8">
                        <multiselect
                            v-model="companyInfo.address.country"
                            :options="addressCountries"
                            track-by="value"
                            label="value"
                            @input="changedBrand(false)"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select country"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
              <tab name="Primary Parts Dept Address"
                   id="primary-parts-dept-address" ref="primaryPartsDeptAddress" class-name="tab-content-table">
                <div class="card">
                  <div class="card-header">Primary Parts Dept Address</div>
                  <div class="card-block mt-3">
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Primary Parts Dept Business Address</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.primaryBusinessAddress.address" type="text"
                               @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Suburb</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.primaryBusinessAddress.suburb" type="text"
                               @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">State</label>
                      <div class="col-lg-8">
                        <multiselect
                            v-model="companyInfo.primaryBusinessAddress.state"
                            :options="addressStates"
                            track-by="value"
                            label="value"
                            @input="changedBrand(false)"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select state"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col col-form-label col-lg-4">Postcode</label>
                      <div class="col-lg-8">
                        <input v-model="companyInfo.primaryBusinessAddress.postcode" type="text"
                               @change="changedBrand(false)"
                               class="form-control" placeholder/>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
              <div class="row action-btns mb-4">
                <button v-if="initialCompanyInfo && initialCompanyInfo.id && isCanDelete" class="delete-user"
                        @click="openDeleteProfileModal">Delete Profile
                </button>
              </div>
            </tabs>
          </div>
          <div class="col-md-6">
            <div v-if="isUserTypeSupplier" class="card">
              <div class="card-header">Supplier Brands</div>
              <div class="card-block mt-3">
                <div class="form-group row">
                  <label class="col col-lg-4 col-form-label">Search and add Makes of
                    Cars
                    supplied</label>
                  <div class="col-lg-8">
                    <multiselect
                        v-model="selectedBrand"
                        :options="computedBrands"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        :resetAfter="true"
                        placeholder="Type to search and add a make"
                        :preselect-first="false"
                    >
                      <template
                          v-slot:cell(selection)="{ values, search, isOpen }"></template>
                    </multiselect>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Makes of Parts
                    Supplied</label>
                  <div class="col-lg-8">
                    <supplier-makes :chips="companyInfo.brands"
                                    @remove="removeBrand"></supplier-makes>
                  </div>
                </div>
              </div>
            </div>
            <div class="pr-3 pl-3">
              <div v-if="companyInfo.logoImgPath" class="mt-3">
                <div class="company-logo w-100">
                  <img :src="companyInfo.logoImgPath" class="company-logo w-100"
                       alt="Company Logo"/>
                </div>
                <button
                    @click="clickDeleteCompanyLogo"
                    type="button"
                    class="btn btn-danger w-100 mt-2"
                >Delete Logo
                </button>
              </div>
              <vue-dropzone
                  v-else-if="initialCompanyInfo && initialCompanyInfo.id"
                  v-on:vdropzone-file-added="addImgUpload"
                  v-on:vdropzone-success="successImgUploadBusinessProfile"
                  v-on:vdropzone-complete="completeImgUpload"
                  v-on:vdropzone-sending="sendImgUpload"
                  :ref="'imgUploadDropBusiness'+initialCompanyInfo.id"
                  :id="'imgUploadDropBusiness'+initialCompanyInfo.id"
                  class="dropify-wrapper dropzoneContainer mt-3"
                  style="height: auto;"
                  :options="companyLogoDropzoneOptions"
                  :useCustomSlot="true"
              >
                <div class="dropify-message" style="transform: translateY(0%);">
                  <i class="bx bx-cloud-upload"></i>
                  <p class="drop-down-text">Drag and Drop Logo Here</p>
                  <p class="text-or mb-0-5">Or</p>
                  <div class="text-sm-center mb-1 ex-image-upload-buttons">
                    <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light browse-btn"
                    >Browse Logo
                    </button>
                  </div>
                </div>
              </vue-dropzone>
            </div>
          </div>
        </div>
      </tab>
      <tab name="Contacts" id="business-contacts" class-name="content-table">
        <div class="mr-3 ml-3">
          <b-table responsive hover :items="computedContacts" :fields="contactFields">
            <template v-slot:cell(username)="data">
              {{ data.item.firstName }} {{ data.item.lastName }}
            </template>

            <template v-slot:cell(businessLocation)="data">
              <multiselect
                  v-model="data.item.location"
                  :options="locations"
                  track-by="id"
                  label="name"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select your business location"
                  :multiple="false"
                  @input="businessLocationUpdate(data.item)"
              />
            </template>
            <template v-slot:cell(action)="data">
              <div v-if="!data.item.isVisible"
                  class="eye hide-icon"
                  id="hide-icon-tooltip"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show users as a contact to suppliers', placement:'leftbottom'}"
                  @click="onClickHide(data.index)">
                <i class="bx bx-hide"></i>
              </div>
              <div v-else
                  class="eye show-icon"
                  id="show-icon-tooltip"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Hide users as a contact to suppliers', placement:'leftbottom'}"
                  @click="onClickShow(data.index)">
                <i class="bx bx-show"></i>
              </div>
            </template>
          </b-table>
        </div>
      </tab>
      <tab name="Locations" id="business-locations" class-name="content-table">
        <div class="mr-3 ml-3">
          <b-table ref="locationTable" hover :items="locations"
                 responsive
                 :fields="locationFields">
            <template v-slot:cell(name)="data">
              <b-form-input
                  v-if="data.item.isEditable"
                  v-model="selectedLocation.name"
                  type="text"/>
              <span v-else>{{ data.item.name }}</span>
            </template>
            <template v-slot:cell(address)="data">
              <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.address" class="form-control"/>
              </div>
              <div v-else>{{ data.item.address }}</div>
            </template>
            <template v-slot:cell(suburb)="data">
              <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.suburb" class="form-control"/>
              </div>
              <div v-else>{{ data.item.suburb }}</div>
            </template>
            <template v-slot:cell(state)="data">
              <div v-if="data.item.isEditable">
                <multiselect
                    v-model="selectedLocation.stateValue"
                    :options="addressStates"
                    class="settings-multiple-brand-add-location-multiselect"
                  track-by="value"
                  label="value"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select state"
              />
            </div>
            <div v-else-if="data.item.stateValue">{{ data.item.stateValue.value }}</div>
          </template>
          <template v-slot:cell(postcode)="data">
            <div v-if="data.item.isEditable">
              <input type="text" v-model="selectedLocation.postcode"
                     class="form-control"/>
            </div>
            <div v-else>{{ data.item.postcode }}</div>
          </template>
          <template v-slot:cell(phone)="data">
            <div v-if="data.item.isEditable">
              <input type="text" v-model="selectedLocation.phone" class="form-control"/>
            </div>
            <div v-else>{{ data.item.phone }}</div>
          </template>
          <template v-slot:cell(openHours)="data">
            <div v-if="data.item.isEditable">
              <date-picker v-model="selectedLocation.openHours" :place-holder-text="'hh:mm'"
                           :isTime="true"></date-picker>
              <!--              <input type="text" v-model="selectedLocation.openHours"-->
               <!--                     class="form-control"/>-->
              </div>
              <div v-else>{{ data.item.openHours }}</div>
            </template>
            <template v-slot:cell(openDays)="data">
            <div v-if="data.item.isEditable">
              <multiselect
                  v-model="selectedLocation.openDays"
                  :options="['Mon to Friday', 'Mon to Saturday']"
                  class="settings-multiple-brand-add-location-multiselect"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select delivery"
              />
            </div>
            <div v-else>{{ data.item.openDays }}</div>
          </template>
          <template v-slot:cell(closingHours)="data">
            <div v-if="data.item.isEditable">
              <date-picker v-model="selectedLocation.closingHours" :place-holder-text="'hh:mm'"
                           :isTime="true"></date-picker>
              <!--              <input type="text" v-model="selectedLocation.closingHours"-->
               <!--                     class="form-control"/>-->
              </div>
              <div v-else>{{ data.item.closingHours }}</div>
            </template>
            <template v-slot:cell(delivery)="data">
              <div v-if="data.item.isEditable">
                <multiselect
                    class="settings-multiple-brand-add-location-multiselect"
                    v-model="selectedLocation.delivery"
                    :options="deliveryOptions"
                    :showLabels="false"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    placeholder="Select delivery"
                />
              </div>
              <div v-else>{{ data.item.delivery }}</div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="action-btn">
                <button
                    v-if="!data.item.isEditable"
                    class="clickable btn_edit"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit Location', placement:'leftbottom'}"
                    @click="onClickEditRow(data.index)"
                ></button>
                <button
                    v-else
                    class="clickable btn_save"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Save Location', placement:'leftbottom'}"
                    @click="onClickSaveRow(data.index)"
                ></button>
                <button
                    v-if="!data.item.isEditable"
                    class="clickable btn_trush"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete Location', placement:'leftbottom'}"
                    @click="onClickRemoveRow(data.index)"></button>
              </div>
            </template>
          </b-table>
        </div>
      </tab>
      <template>
        <tab :isShow="isAdministrator" name="RFQ" id="rfq" ref="rfq"
             class-name="tab-content-table">
          <the-rfq
              ref="rfqComponent"
              @changedBrand="v => $emit('changedBrand', v)"
              :propsSettings="companyInfo.settings"
              :company-name="initialCompanyInfo && initialCompanyInfo.businessName ? initialCompanyInfo.businessName : ''"
              :companyId="initialCompanyInfo && initialCompanyInfo.id ? initialCompanyInfo.id : ''"
          />
        </tab>
        <tab name="Credits" id="credits" ref="credits" class-name="tab-content-table">
          <credit
              ref="creditComponent"
              @changedBrand="v => $emit('changedBrand', v)"
              :propsSettings="companyInfo.settings"
              :companyId="initialCompanyInfo && initialCompanyInfo.id ? initialCompanyInfo.id : ''"
          ></credit>
        </tab>
        <tab :isShow="isShowTab" name="Integrations" id="integrations" ref="integrations" class-name="tab-content-table">
          <integrations
              ref="integrationsComponent"
              :propsKeys="companyInfo && companyInfo.settings && companyInfo.settings.keys ? companyInfo.settings.keys : [] "
              :companyId="initialCompanyInfo && initialCompanyInfo.id ? initialCompanyInfo.id : ''"
          ></integrations>
        </tab>
        <tab v-if="isUserTypeSupplier && isShowTab" name="Orders" id="order" ref="order" class-name="tab-content-table">
          <the-supplier-orders
              @changedBrand="v => $emit('changedBrand', v)"
              ref="orderComponent"
              :propsSettings="companyInfo.settings"
              :company-name="initialCompanyInfo && initialCompanyInfo.businessName ? initialCompanyInfo.businessName : ''"
              :companyId="initialCompanyInfo && initialCompanyInfo.id ? initialCompanyInfo.id : ''"
          />
        </tab>
      </template>
    </tabs>
    <b-modal ref="showDeleteProfile" size="delete-profile" title="WARNING - Delete a Business Profile">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3">Deleting this Business profile will <span
            class="font-weight-bold">Permanently Remove</span> all business linked Quotes, Order and Credits data.</p>
        <p class="pl-3 pr-3">Contact support if you need more information.</p>
        <p class="pl-3 pr-3">Are you sure you want to continue?</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn cancel mr-2" @click="cancel()">Cancel Action</button>
          <button @click="continueDeleteProfile()"
                  class="input-btn modal-undo-part-line-change-btn-yes">Continue
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import _ from 'lodash'
import NProgress from 'nprogress'
import { mapGetters, mapActions } from 'vuex'
import { email } from 'vuelidate/lib/validators'
import GoogleLocation from '@/components/utility/google-location'
import supplierMakes from '@/components/supplier-makes'
import DatePicker from '../../../../components/datePicker'
import Axios from 'axios'
import TheRfq from '../components/the-rfq'
import Integrations from '../components/the-integrations'
import Credit from '../components/the-credit'
import TheSupplierOrders from "../components/the-supplier-orders";

export default {
  name: 'MultipleBrandProfile',
  data () {
    return {
      selectedBrand: '',
      interval: null,
      contactFields: [
        {
          label: 'Name',
          key: 'username',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Position',
          key: 'companyRole',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Office Phone',
          key: 'workPhone',
          sortable: true,
          tdClass: 'table-body',
          thClass: ''
        },
        {
          label: 'Mobile Phone',
          key: 'mobilePhone',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Action',
          key: 'action',
          sortable: true,
          tdClass: 'table-body text-center',
          thClass: 'table-header text-center'
        }
      ],
      locationFields: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Address',
          key: 'address',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Suburb',
          key: 'suburb',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'State',
          key: 'state',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Postcode',
          key: 'postcode',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Phone',
          key: 'phone',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Open Days',
          key: 'openDays',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Open Hours',
          key: 'openHours',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Closing Hours',
          key: 'closingHours',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Delivery',
          key: 'delivery',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header'
        },
        {
          label: 'Action',
          key: 'action',
          sortable: true,
          tdClass: 'table-body',
          thClass: 'table-header action-btns'
        }
      ],
      companyLogoDropzoneOptions: {
        headers: { Authorization: localStorage.getItem('token') },
        resizeWidth: 900,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/company/logo/upload/' + this?.initialCompanyInfo?.id,
        method: 'post',
        maxFilesize: 50,
        // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        clickable: true,
        dictFileTooBig:
            'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      companyInfo: {
        id: 0,
        name: '',
        businessName: '',
        abn: '',
        acn: '',
        supplierType: '',
        supplierTypes: [],
        phone: '',
        fax: '',
        displayId: null,
        email: '',
        emailOrders: '',
        emailCredits: '',
        brands: [],
        deleteBrands: [],
        logoImgPath: '',
        address: {
          address: '',
          unitLevelLot: '',
          street: '',
          suburb: '',
          postcode: '',
          state: '',
          country: ''
        },
        primaryBusinessAddress: {
          address: '',
          unitLevelLot: '',
          street: '',
          suburb: '',
          postcode: '',
          state: '',
          country: ''
        },
        supplierVehicleCategory: 'CAR',
      },
      initialBrands: [],
      locations: [],
      deleteLocations: [],
      contacts: [],
      isSettedBrands: false,
      contactsVisibility: {
        visible: [],
        inVisible: []
      },
      selectedLocation: {
        id: '',
        name: '',
        address: '',
        suburb: '',
        state: '',
        postcode: '',
        phone: '',
        openHours: '',
        closingHours: '',
        openDays: null,
        delivery: '',
      },
      deliveryOptions: [
        'Road'
      ]
    }
  },
  props: {
    isCanDelete: {
      type: Boolean,
      default: false,
    },
    initialCompanyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    initialLocations: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    multiselect: Multiselect,
    GoogleLocation,
    TheSupplierOrders,
    supplierMakes,
    DatePicker,
    TheRfq,
    Integrations,
    Credit
  },
  computed: {
    ...mapGetters({
      usersAll: 'users/getAllUsers',
      // currentUser: 'currentUser/getCurrentUserInfo',
      addressCountries: 'addressCountries',
      addressStates: 'addressStates',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      brands: 'brandList/getBrands',
      isAdministrator: 'currentUser/isAdministrator',
      isPartsManager: 'currentUser/isPartsManager',
      getterSettings: 'settings/getSettings',
      isUnifiedRfqSettings: 'settings/isUnifiedRfqSettings',
      getSupplierBusinessTypes: 'getSupplierBusinessTypes'
    }),
    mainBusinessProfileId () {
      return _.find(this.getterSettings.multipleBrands, (b) => {
        return b.isMain
      })?.id
    },
    isShowTab () {
      return this.isAdministrator || this.isPartsManager
    },
    computedContacts () {
      return _.filter(this.contacts, (c) => {
        return c?.businessLocation?.includes(this.companyInfo?.id)
      })
    },
    computedBrands () {
      return _.filter(this.brands, (brand) => {
        return !this?.companyInfo?.brands?.includes(brand)
      })
    },
    computedDefaultBusinessProfileHash () {
      if (localStorage.getItem('settings.tabs.defaultBusinessProfileTab')) {
        return localStorage.getItem('settings.tabs.defaultBusinessProfileTab')
      }
      return 'business-details'
    },
  },
  methods: {
    ...mapActions({
      loadBrandList: 'brandList/loadBrandList',
      loadUsersAll: 'users/fetchAllUsers',
    }),
    getType(t) {
      return _.find(this.getSupplierBusinessTypes, (type) => {
        return type.value == t
      })?.label
    },
    addressChanged: function (addr) {
      let country = _.find( _.cloneDeep(this.addressCountries), item => {
        return item.key === addr['country']
      })
      let state = _.find( _.cloneDeep(this.addressStates), item => {
        return item.key === addr['state']
      })
      this.companyInfo.address.address = addr['address']
      this.companyInfo.address.unitLevelLot = addr['levelUnitLot']
      this.companyInfo.address.street = addr['street_name']
      this.companyInfo.address.suburb = addr['suburb']
      this.companyInfo.address.state = state
      this.companyInfo.address.postcode = addr['postcode']
      this.companyInfo.address.country = country
      this.changedBrand(false)
    },
    changedBrand (isNew = false) {
      this.$emit('changedBrand', { value: this.companyInfo, isNew: isNew })
    },
    sendTab () {
      this.$emit('changedTab', this.$refs['business-profile-tabs']?.getActiveTab()?.id)
    },
    onBusinessTabChange: function (tab) {
      this.$emit('changedTab', tab.tab.id)
      localStorage.setItem('settings.tabs.defaultBusinessProfileTab', tab.tab.id)
    },
    getLocationById: function (id) {
      let location = {}
      let locations = _.cloneDeep(this.locations)
      if (!_.isEmpty(locations)) {
        location = _.find(locations, (location) => {
          return id === location.id
        })
      }
      return location
    },
    setLocations: function (data) {
      let val = _.cloneDeep(data)
      let states = _.cloneDeep(this.addressStates)
      this.locations = val.map(location => ({
        ...location,
        isEditable: false,
        stateValue: _.find(states, item => {
          return item.key === location.state
        })
      }))
    },
    openDeleteProfileModal () {
      this.$refs['showDeleteProfile'].show()
    },
    continueDeleteProfile () {
      this.$refs['showDeleteProfile'].hide()
      let brandsLength = _.cloneDeep(this.getterSettings.multipleBrands).length
      let id = _.cloneDeep(this.initialCompanyInfo.id)

      Axios.post(`/ir/company/delete-brand/${this.initialCompanyInfo.id}`)
          .then(response => {
            if (response?.data?._status) {
              let siteContent = document.getElementById('site-content')
              let siteContentArea = document.getElementById('site-content-area')
              siteContent.classList.add('loading-company')
              siteContentArea.style.opacity = 0
              this.$store.commit('changeLoadStatus', false)
              this.$emit('onDeletedBrand', id)

              if (brandsLength > 2) {
                this.$emit('deletedBusinessProfile', '#businessProfile-' + this.mainBusinessProfileId)
              } else {
                this.$emit('deletedBusinessProfile', '#businessProfile')
              }
            }
          })
          .catch(() => {
          })
    },
    setContacts: function (data) {
      let usersAll = _.cloneDeep(data)
      if (!_.isEmpty(usersAll)) {
        this.contacts = null
        let contacts = []
        _.forEach(usersAll, (item) => {
          let contact = item
          contact.location = this.getLocationById(item.businessLocation)
          contacts.push(contact)
        })
        this.contacts = contacts
      }
    },
    setCompanyInfo: function (company) {
      if (!_.isEmpty(company)) {
        let val = _.cloneDeep(company),
            country = _.find(_.cloneDeep(this.addressCountries), item => {
              return (item.key === val.address.country) || (item.key === val.address?.country?.key)
            }),
            state = _.find(_.cloneDeep(this.addressStates), item => {
              return item.key === val.address.state || item.key === val?.address?.state?.key
            })
        if (!this.isSettedBrands) {
          this.initialBrands = _.cloneDeep(this.initialCompanyInfo.brands)
          this.isSettedBrands = true
        }

        let address = _.cloneDeep(val.address)

        this.companyInfo = {
          id: val.id,
          about: val.about,
          name: val.name,
          acn: val.acn,
          businessName: val.businessName,
          supplierVehicleCategory: val.supplierVehicleCategory,
          abn: val.abn,
          supplierType: val.supplierType,
          supplierTypes: val.supplierTypes,
          phone: val.phone,
          fax: val.fax,
          email: val.email,
          emailOrders: val.emailOrders,
          emailCredits: val.emailCredits,
          brands: (!_.isEmpty(val.brands)) ? val.brands : [],
          deleteBrands: !_.isEmpty(val.deleteBrands) ? val.deleteBrands : [],
          logoImgPath: val.logoImgPath,
          address: {
            address: address.address,
            unitLevelLot: address.unitLevelLot,
            street: address.street,
            suburb: address.suburb,
            postcode: address.postcode,
            state: (!_.isEmpty(state)) ? state : null,
            country: (!_.isEmpty(country)) ? country : null
          },
          displayId: val.displayId,
          primaryBusinessAddress: val.primaryBusinessAddress,
          settings: val.settings,
        }
        let primaryStateValue = null
        if (val.primaryBusinessAddress && val.primaryBusinessAddress.state) {
          primaryStateValue = _.find( _.cloneDeep(this.addressStates), (item) => {
            return val.primaryBusinessAddress.state === item.key
          })
        }
        if (!_.isEmpty(primaryStateValue)) {
          this.companyInfo.primaryBusinessAddress.state = primaryStateValue
        }
        setTimeout(() => {
          let ref = this.$refs['imgUploadDropBusiness' + this.initialCompanyInfo?.id]
          if (ref) {
            let url = appConfig.baseAPIURL + '/ir/company/logo/upload/' + this.initialCompanyInfo?.id
            ref.options.url = url
            ref.dropzone.options.url = url
            this.companyLogoDropzoneOptions.url = url
          }
        }, 200)
      }
    },
    clickDeleteCompanyLogo: function () {
      NProgress.start()
      this.companyInfo.logoImgPath = ''
      this.changedBrand(false)
      Axios.post(`/ir/company/logo/delete/` + this.initialCompanyInfo.id)
          .then((r) => {
            if (r.data.status)
              this.$toast.success('Company logo has been removed')
            NProgress.done()
          })
          .catch((error) => {
            this.$toast.error(error)
            NProgress.done()
          })
    },
    onClickShow: function (index) {
      let visibility = this.contactsVisibility
      const id = this.contacts[index].id
      this.contacts[index].isVisible = false
      visibility.inVisible.push(id)
      visibility.visible = _.without(this.contactsVisibility.visible, id)
      this.contactsVisibility = visibility
      this.$store.commit('users/settingsUpdateVisibleAllUsers', _.cloneDeep(this.contacts))
      this.saveCompanyInfo({
        contacts: visibility
      })
    },
    onClickHide: function (index) {
      let visibility = this.contactsVisibility
      const id = this.contacts[index].id
      this.contacts[index].isVisible = true
      visibility.visible.push(id)
      visibility.inVisible = _.without(this.contactsVisibility.inVisible, id)
      this.contactsVisibility = visibility
      this.$store.commit('users/settingsUpdateVisibleAllUsers', _.cloneDeep(this.contacts))
      this.saveCompanyInfo({
        contacts: visibility
      })
    },
    removeBrand: function (removedOption) {
      console.log('removedOption', removedOption)
      this.companyInfo.brands.splice(this.companyInfo.brands.indexOf(removedOption), 1)
      this.companyInfo.deleteBrands.push(removedOption)
      // this.changedBrand(false)
    },
    updatedBusinessProfile: function () {
      let data = {
        id: this.initialCompanyInfo.id,
        businessName: this.companyInfo.businessName,
        details: {
          abn: this.companyInfo.abn,
          about: this.companyInfo.about,
          email: this.companyInfo.email,
          emailOrders: this.companyInfo.emailOrders,
          emailCredits: this.companyInfo.emailCredits,
          fax: this.companyInfo.fax,
          businessName: this.companyInfo.businessName,
          phone: this.companyInfo.phone,
          acn: this.companyInfo.acn,
          name: this.companyInfo.name,
        },
        address: {
          address: this.companyInfo.address.address,
          country: this.companyInfo?.address?.country?.key ? this.companyInfo.address.country.key : null,
          unitLevelLot: this.companyInfo.address.unitLevelLot,
          street: this.companyInfo.address.street,
          postcode: this.companyInfo.address.postcode,
          suburb: this.companyInfo.address.suburb,
          state: this.companyInfo?.address?.state?.key ? this.companyInfo.address.state.key : null,
        },
        addBrands: this.companyInfo.brands.filter(x => !this.initialBrands.includes(x)),
        deleteBrands: this.companyInfo.deleteBrands.filter(x => !this.companyInfo.brands.includes(x)),
        locations: this.locations,
        settings: this.companyInfo.settings,
        deleteLocations: this.deleteLocations,
        contacts: this.contactsVisibility,
        primaryBusinessAddress: Object.assign({}, this.companyInfo.primaryBusinessAddress),
      }
      if (data.primaryBusinessAddress.state && data.primaryBusinessAddress.state.key) {
        data.primaryBusinessAddress.state = data.primaryBusinessAddress.state.key
      }
      data.details.supplierType = this.companyInfo.supplierType
      data.details.supplierTypes = this.companyInfo.supplierTypes
      data.details.supplierVehicleCategory = this.companyInfo.supplierVehicleCategory
      console.log('updatedBusinessProfile', data)
      return data
    },
    saveCompanyInfo: function (data = {}) {
      let dataForSave = data
      if (_.isEmpty(dataForSave)) dataForSave = this.updatedBusinessProfile()
      dataForSave.id = this.initialCompanyInfo.id
      NProgress.start()
      if (dataForSave.id) {
        this.$store.dispatch('currentCompany/saveBusinessProfile', _.cloneDeep(dataForSave))
            .then((res) => {
              console.log('res', res)
              if (res.data.status) {
                this.deleteLocations = []
                this.initialBrands = _.cloneDeep(this.companyInfo.brands)
                this.isSettedBrands = false
                this.companyInfo.deleteBrands = []
                this.$toast.success('Company details updated successfully')
                NProgress.done()
              }
            })
            .catch((err) => {
              this.$toast.error(err)
              NProgress.done()
            })
      } else {
        this.$store.dispatch('currentCompany/addBusinessProfile', _.cloneDeep(dataForSave))
            .then((res) => {
              if (res.data._status) {
                let siteContent = document.getElementById('site-content')
                let siteContentArea = document.getElementById('site-content-area')
                siteContent.classList.add('loading-company')
                siteContentArea.style.opacity = 0

                let newCompanyId = res.data.id
                this.companyInfo.id = _.cloneDeep(newCompanyId)
                this.deleteLocations = []
                this.initialBrands = _.cloneDeep(this.companyInfo.brands)
                this.isSettedBrands = false
                this.companyInfo.deleteBrands = []
                this.changedBrand(true)
                this.$toast.success('Company details updated successfully')
                this.$emit('addedNewBusinessProfile', newCompanyId)
                NProgress.done()
              }
            })
            .catch((err) => {
              this.$toast.error(err)
              NProgress.done()
            })
      }
    },
    businessLocationUpdate: function (data) {
      let user = {
        id: data.id,
        businessLocation: data.location.id,
      }
      this.updateUser(user)
    },
    updateUser: function (user) {
      NProgress.start()
      let roles = ['Administrator', 'Estimator', 'Parts Coordinator']
      user.groupId = roles.indexOf(user.companyRole) + 1
      this.$store.dispatch('users/updateUser', {
        id: user.id,
        updatedUser: user
      }).then(() => {
        this.$toast.success('User has been updated')
        NProgress.done()
        this.loadUsersAll()
      }).catch((err) => {
        this.$toast.error(err)
        NProgress.done()
      })

    },
    refreshSelectedLocation: function () {
      this.selectedLocation = {
        id: '',
        name: '',
        address: '',
        suburb: '',
        state: '',
        stateValue: '',
        postcode: '',
        phone: '',
        openHours: '',
        closingHours: '',
        openDays: null,
        delivery: '',
      }
      //this.$refs.locationTable.refresh()
    },
    onClickEditRow: function (index) {
      let locations = _.cloneDeep(this.locations)
      let location = _.cloneDeep(locations[index])
      let stateValue = _.find( _.cloneDeep(this.addressStates), (item) => {
        return location.state === item.key
      })
      for (let key in locations) {
        locations[key].isEditable = false
      }
      this.selectedLocation = {
        id: location.id,
        name: location.name,
        address: location.address,
        suburb: location.suburb,
        state: location.state,
        stateValue: stateValue,
        postcode: location.postcode,
        phone: location.phone,
        openHours: location.openHours,
        closingHours: location.closingHours,
        openDays: location.openDays,
        delivery: location.delivery,
      }
      locations[index].isEditable = true
      this.locations = locations
      this.$refs.locationTable.refresh()
    },
    onClickSaveRow: function (index) {
      let locations = _.cloneDeep(this.locations)
      locations[index] = _.cloneDeep(this.selectedLocation)
      if (!_.isEmpty(this.selectedLocation.stateValue)) locations[index].state = this.selectedLocation.stateValue.key
      this.locations = locations
      this.refreshSelectedLocation()
      this.saveCompanyInfo()
    },
    onClickRemoveRow: function (index) {
      let location = this.locations[index],
          locations = this.locations
      locations.splice(index, 1)
      if ('id' in location) this.deleteLocations.push(location.id)
      this.refreshSelectedLocation()
      this.locations = locations
    },
    onClickSaveCompanyInfo: function () {
      this.saveCompanyInfo()
    },
    onClickSaveCompanyInfoRfq: function () {
      this.$refs.rfqComponent.updateSettings()
    },
    onClickSaveCompanyInfoCredit: function () {
      this.$refs.creditComponent.updateCreditSettings()
    },
    onClickSaveCompanyIntegration: function () {
      this.$refs.integrationsComponent.onCreateClick()
    },
    onClickSaveCompanyOrders: function () {
      this.$refs.orderComponent.onSave()
    },
    onClickAddLocation: function () {
      this.locations.push({
        name: '',
        address: '',
        phone: '',
        openHours: '',
        closingHours: '',
        openDays: null,
        delivery: '',
        isEditable: true
      })
      this.refreshSelectedLocation()
    },
    addImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    completeImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].removeAttribute('style')
    },
    sendImgUpload ($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    successImgUploadBusinessProfile (file, response) {
      console.log('successImgUploadBusinessProfile', response.status)
      if (response.status) {
        this.companyInfo.logoImgPath = file.dataURL
        this.changedBrand(false)
      } else {
        setTimeout(() => {
          let ref = this.$refs['imgUploadDropBusiness' + this.companyInfo?.id]
          ref.removeFile(file)
        }, 1000)
      }
    },
  },
  mounted () {
    this.loadBrandList()
    this.setCompanyInfo(this.initialCompanyInfo)
    if (!_.isEmpty(this.initialLocations)) {
      this.setLocations(this.initialLocations)
    }
    this.setContacts(this.usersAll)

  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
    initialLocations: {
      deep: true,
      handler (val) {
        this.setContacts(this.usersAll)
        this.setLocations(val)
      }
    },
    // currentUser: {
    //   deep: true,
    //   handler () {
    //     this.setCompanyInfo(this.initialCompanyInfo)
    //   }
    // },
    initialCompanyInfo: {
      deep: true,
      handler (val) {
        this.setCompanyInfo(val)
      }
    },
    usersAll: {
      deep: true,
      handler (val) {
        this.setContacts(val)
      }
    },
    selectedBrand: function (newVal) {
      if (!newVal) return
      if (this.companyInfo.brands.includes(newVal)) return
      this.companyInfo.brands.push(newVal)
      this.companyInfo.brands = this.companyInfo.brands.filter((elem, pos, arr) => {
        return arr.indexOf(elem) === pos
      })
    },
  },
  validations: {
    companyInfo: {
      email: {
        email
      },
      emailOrders: {
        email
      },
      emailCredits: {
        email
      },
    }
  }
}
</script>

<style scoped>
.settings-multiple-brand-add-location-multiselect >>> .multiselect__tags{
  padding-right: 30px;
}

.business-profile-page .table-responsive {
  overflow-x: scroll !important;
  background-color: #fff;
}
</style>

