/* eslint-disable */

import {
    SUPPLIER_CUSTOMERS_UPDATED,
    SUPPLIER_CUSTOMER_REINSTALLATION,
    SUPPLIER_CUSTOMERS_LOAD_RFQ,
    SUPPLIER_CUSTOMERS_PER_PAGE,
    SUPPLIER_CUSTOMERS_FILTER,
    SUPPLIER_CUSTOMERS_ALL_CURRENT_PAGE,
    SUPPLIER_CUSTOMERS_PREFERED_CURRENT_PAGE, SUPPLIER_CUSTOMERS_SHORT_UPDATING,
} from '../../../mutation-types';
import _ from "lodash";

export default {
    [SUPPLIER_CUSTOMER_REINSTALLATION](state) {
        state.rfqs = {
            items: [],
            filter: {}
        };
        state.filter = '';
        state.perPage = 100;
        state.customersAllCurrentPage = 1;
        state.customersPreferedCurrentPage = 1;
        state.items = [];
    },
    [SUPPLIER_CUSTOMERS_UPDATED](state, ui) {
        state.items = _.unionBy(ui.data, state.items, 'id');
    },
    [SUPPLIER_CUSTOMERS_SHORT_UPDATING](state, ui) {
        state.items = _.unionBy(ui.data, [], 'id');
    },
    [SUPPLIER_CUSTOMERS_LOAD_RFQ](state, ui) {
        state.rfqs.items = ui;
    },
    [SUPPLIER_CUSTOMERS_PER_PAGE](state, ui) {
        state.perPage = ui;
    },
    [SUPPLIER_CUSTOMERS_FILTER](state, ui) {
        state.filter = ui;
    },
    [SUPPLIER_CUSTOMERS_ALL_CURRENT_PAGE](state, ui) {
        state.customersAllCurrentPage = ui;
    },
    [SUPPLIER_CUSTOMERS_PREFERED_CURRENT_PAGE](state, ui) {
        state.customersPreferedCurrentPage = ui;
    },
}
