<template>
  <div class="">
    <template v-for="itm in computedActivity">
      <div @click="redirectTo(itm)" class="notification_item" :class="[getClass(itm)]">
        <div class="notification_item-title">
          <span class="notification_item-title-name notification_item-title-color">{{ itm.title }}</span>
          <span style="white-space: nowrap">{{ getNumber(itm) }}</span>
          <span class="notification_item-date"> {{ formatDateTime(itm.createdOn) }}</span></div>
        <div class="notification_item-info">
          <span>{{ getText(itm) }}</span>
        </div>
      </div>
    </template>


    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'repairer-red-color-border-red-title': true}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Order Cancellation</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">McGraths</span> - <span class="notification_item-info-comment">has cancelled or declined this order</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    -->
    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'repairer-red-color-border-red-title': true}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Back Order - Acceptance Req</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">McGraths</span> - <span class="notification_item-info-comment">Needs Authorisation</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'active': true}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Back Order - Acceptance</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">McGraths</span> - <span class="notification_item-info-comment">Authorised</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'active': false}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Back Order - Acceptance</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">McGraths</span> - <span class="notification_item-info-comment">Authorised</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    -->
    <!--    -->


    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'supplier-red-color-border-red-title': true}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Back Order - Acceptance</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">North West Repairs</span> - <span class="notification_item-info-comment">Has Authorised</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'supplier-red-color-border-black-title': true}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Back Order - Acceptance</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">North West Repairs</span> - <span class="notification_item-info-comment">Has Authorised</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div @click="redirectToRfq(itm)"  class="notification_item" :class="{'active': false}">-->
    <!--      <div class="d-flex notification_item-title"><div class="notification_item-title-color">Back Order - Acceptance</div> <div>990</div> <div>11:28am</div></div>-->
    <!--      <div class="notification_item-info">-->
    <!--        <span class="notification_item-info-name">North West Repairs</span> - <span class="notification_item-info-comment"></span><span class="notification_item-info-text">Authorised</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import Axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'notifications',
  props: {
    activity: {
      type: Array,
      default: () => ([]),
    }
  },
  computed: {
    ...mapGetters({
      isUserTypeRepairer: 'currentUser/isUserTypeRepairer',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
    }),
    computedActivity() {
      return _.filter(this.activity, (a) => {
        return a.type === 'order_change_delivery_eta' || a.type === 'order_back_order_acceptance_sent' || a.type == 'rfq_parts_diagram_requested'
            || a.type === 'order_back_order_accepted' || a.type === 'order_back_order_declined' || a.type === 'order_cancel_by_supplier' || a.type === 'order_cancel_by_repairer'
      })
    },
  },
  methods: {
    getClass(itm) {
      if (((itm.type === 'order_cancel_by_supplier' || itm.type === 'order_cancel_by_repairer') && !itm.isViewed) || itm.type == 'order_back_order_acceptance_sent' || (this.isUserTypeSupplier && (itm.type === 'order_back_order_accepted' || itm.type === 'order_back_order_declined') && !itm.isViewed)) {
        return 'repairer-red-color-border-red-title'
      } else if (!(this.isUserTypeRepairer && (itm.type === 'order_back_order_accepted' || itm.type === 'order_back_order_declined')) && !itm.isViewed) {
        return 'active'
      }
    },
    getNumber(itm) {
      return itm?.data?.rfqNumber ? itm?.data?.rfqNumber : itm?.data?.orderNumber
    },
    getText(itm) {
      if (itm.type === 'order_back_order_acceptance_sent') {
        return itm?.data?.supplierName + ' - Needs Authorisation'
      }
      if (itm.type === 'order_change_delivery_eta') {
        return itm?.data?.supplierName + ' - new date ' + this.formatDate(itm?.data?.deliveryEta)
      }
      if (itm.type === 'rfq_parts_diagram_requested') {
        return itm?.data?.repairerName + ' - has requested Parts Diagram'
      }
      if (itm.type === 'order_back_order_accepted' || itm.type === 'order_back_order_declined') {
        return itm?.data?.repairerName + ' - Authorised'
      }
      if (itm.type === 'order_cancel_by_supplier' || itm.type === 'order_cancel_by_repairer') {
        let name = itm.type === 'order_cancel_by_supplier' ? itm?.data?.supplierName : itm?.data?.repairerName
        return name + ' has cancelled items in order.'
      }
    },
    formatDate (val) {
      return dayjs(val).format('DD/MM/YYYY')
    },
    redirectTo (itm) {
      if (!itm.isViewed) {
        this.markAsViewed(itm)
      }
      if (itm?.entity?.type == 'order' && itm?.entity?.id) {
        if (this.isUserTypeRepairer) {
          this.$router.push({ name: 'RepairerPartsOrderView', params: { orderId: itm.entity.id } })
        } else {
          this.$router.push({ name: 'SupplierPartsOrderView', params: { orderId: itm.entity.id } })
        }
      } else if (itm?.data?.orderId) {
        if (this.isUserTypeRepairer) {
          this.$router.push({ name: 'RepairerPartsOrderView', params: { orderId: itm.data.orderId } })
        } else {
          this.$router.push({ name: 'SupplierPartsOrderView', params: { orderId: itm.data.orderId } })
        }
      } else if (itm?.data?.rfqId || itm?.data?.rfqToSupplierId) {
        if (this.isUserTypeRepairer) {
          this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: itm.data.rfqId } })
        } else {
          this.$router.push({ name: 'SupplierRFQView', params: { rfq_id: itm.data.rfqToSupplierId } })
        }
      }
    },
    markAsViewed (itm) {
      let url = this.isUserTypeRepairer ? `/ir/repairer/activity/${itm.id}` : `/ir/supplier/activity/${itm.id}`
      Axios.post(url, { viewed: true })
          .then(res => {
            if (res.data._status) {
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    formatDateTime (val) {
      let format = 'DD/MM'
      if (dayjs(val).isToday()) {
        format = 'hh:mmA'
      }
      return (val) ? dayjs(val).format(format) : ''
    },
  }
}
</script>

<style scoped>
.notification_item-title div {
  margin-left: 15px;
}
.notification_item-title-name{
  margin-right: 20px;
}
.notification_item-title{
  margin-right: 43px;
}
.notification_item-date{
  position: absolute;
  right: 10px;
  top: 7px;
}
.notification_item-title div:first-child {
  margin-left: 0;
}

.notification_item.supplier-red-color-border-red-title {
  border-color: red;
}

.notification_item.supplier-red-color-border-red-title:hover {
  border-color: #ff6161;
}

.notification_item.supplier-red-color-border-red-title .notification_item-title {
  font-weight: bold;
}

.notification_item.supplier-red-color-border-red-title .notification_item-title-color {
  color: red;
}

.notification_item.supplier-red-color-border-red-title .notification_item-info-comment {
  font-weight: bold;
}

.notification_item.repairer-red-color-border-red-title {
  border-color: red;
}

.notification_item.repairer-red-color-border-red-title:hover {
  border-color: #ff6161;
}

.notification_item.repairer-red-color-border-red-title .notification_item-title {
  font-weight: bold;
}

.notification_item.repairer-red-color-border-red-title .notification_item-title-color {
  color: red;
}

.notification_item.repairer-red-color-border-red-title .notification_item-info-name {
  font-weight: bold;
}

.notification_item.repairer-red-color-border-red-title .notification_item-info-comment {
  font-weight: 500;
}

.notification_item.supplier-red-color-border-black-title {
  border-color: red;
}

.notification_item.supplier-red-color-border-black-title:hover {
  border-color: #ff6161;
}

.notification_item.supplier-red-color-border-black-title .notification_item-title {
  font-weight: bold;
}

.notification_item.supplier-red-color-border-black-title .notification_item-info-comment {
  font-weight: bold;
}


.notification_item.active .notification_item-title {
  font-weight: bold;
}

.notification_item.active {
  border: 2px solid #7bbec0 !important;
}

.notification_item.active:hover {
  border: 2px solid #3E8385FF !important;
}

.notification_item {
  min-height: 50px;
  border: 2px solid #D7D7D7FF;
  border-radius: 5px;
  margin: 5px 5px;
  font-size: 11px;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
}

.notification_item:hover {
  border: 2px solid #bdbdbd;
}

.notification_item-info {
  font-weight: 500;
  padding-right: 8px;
}


</style>
