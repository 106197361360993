/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    filter: '',
    isPinnedImages: false,
    quoteNbrs: {}, //array [<company_id>]:[<nbr>,<nbr>...]
    quoteNbrsX: {}, //array [<company_id>]:[<nbr>,<nbr>...]
    isPlayNotificationSound: false,
    token: [],
    tokens: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
    },
    searchFilterObj: {
        search: '',
    },
    isNeedRefreshTable: 0,
    items: [],
    rfqToSupplierCurrentlyViewing: [],
    new: [],
    priced: [],
    lost: [],
    won: [],
    ordered: [],
    closed: [],
    currentRfq: null,
    lastTimeCheck: {
        items: 0,
        new: 0,
        priced: 0,
        lost:0,
        won: 0,
        ordered:0,
        closed: 0,
    },
    perPage: 100,
    allRFQsCurrentPage: 1,
    newRFQsCurrentPage: 1,
    pricedRFQsCurrentPage: 1,
    wonRFQsCurrentPage: 1,
    orderedRFQsCurrentPage: 1,
    closedRFQsCurrentPage: 1,
    lostRFQsCurrentPage: 1,
}

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
