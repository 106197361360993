<script>
/* eslint-disable */

    export default {
        name: "HomePage",
        mounted() {
          this.$router.push('/login');
        },
    }

</script>

<template>

</template>