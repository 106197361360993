<template>
  <div class="dashboard-panel card" v-bind:style="{'max-width':width || '100%'}">
    <div class="card-body">
      <div class="card-title">
        <h4 class="title-text">Request For Quotes</h4>
        <div class="grid">
          <div class="input-text d-flex justify-content-end" style="margin-right: 0px">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :style="'min-width:' +  this.widthInputSearch + 'px'"
                :filterValue="filter"
                :limit-length="50"
                @selectAdditional="redirectToRfqView"
                @onEnter="onEnter"
                :additionalMenu="additionalMenu"
                @change="onSearchFilterChange"
                @updateToken="value => $store.commit('supplier/rfq/setToken', value)"
                @updateTokens="value => $store.commit('supplier/rfq/setTokens', value)"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="8"
            />
          </div>
        </div>
      </div>
      <div class="cards-list">
        <div class="card-item min-height">
          <div class="header-item">
            New
            <i @click="goToNewRFQ" class="bx bx-link-external clickable"></i>
          </div>
          <div class="body-item">
            <div @click="goToNewRFQ" class="list-values ">
              <div class="list-values-header aqua-bg clickable">
                <span class="numb">{{ countNewRFQs }}</span> New RFQ's
              </div>
            </div>
          </div>
        </div>
        <div class="card-item min-height">
          <div class="header-item">
            Priced
            <i class="bx bx-link-external clickable" @click="goToPricedRFQ"></i>
          </div>
          <div class="body-item">
            <div @click="goToPricedRFQ" class="list-values ">
              <div class="list-values-header aqua-bg clickable">
                <span class="numb">{{ countPricedRFQs }}</span> Priced Quotes
              </div>
            </div>
          </div>
        </div>
        <div class="card-item min-height">
          <div class="header-item">
            Won
            <i class="bx bx-dollar clickable" @click="goToWonRFQ" style="color:#2DCA73"></i>
          </div>
          <div class="body-item">
            <div @click="goToWonRFQ" class="list-values ">
              <div class="list-values-header aqua-bg clickable">
                <span class="numb">{{ countWonRFQs }}</span> Won Quotes
              </div>
            </div>
          </div>
        </div>
        <div class="card-item min-height">
          <div class="header-item">
            Lost
            <i class="bx bx-dollar clickable" @click="goToLostRFQ" style="color:#2DCA73"></i>
          </div>
          <div class="body-item">
            <div @click="goToLostRFQ" class="list-values ">
              <div class="list-values-header aqua-bg clickable">
                <span class="numb">{{ countLostRFQs }}</span> Lost Quotes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import SearchFilter from '../../../../components/utility/search-filter/search-filter'
import _ from "lodash";

export default {
  name: "RequestForQuotes",
  data() {
    return {
      widthInputSearch: 350,
      searchFilter: '',
      filter: '',
    }
  },
  props: {
    width: String
  },
  methods: {
    ...mapActions({
      setFilter: 'supplier/rfq/setFilter',
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter)) {
          this.setFilter('')
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        } else {
          this.$router.push({path: '/s/rfqs'})
        }
      }, 500)
    },
    onSearchFilterChange(filters) {
      console.log(filters, 'filters')
      this.$store.commit('supplier/rfq/setSearchFilterObj', filters)
      if (filters && filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
        // this.filter = filters.search;
      } else {
        this.setFilter('')
        // this.filter = '';
      }
    },
    goToNewRFQ() {
      this.$router.push({path: '/s/rfqs#new'}); //ToDo needs to change to :to attr
    },
    goToPricedRFQ() {
      this.$router.push({path: '/s/rfqs#priced'}); //ToDo needs to change to :to attr
    },
    goToWonRFQ() {
      this.$router.push({path: '/s/rfqs#won'}); //ToDo needs to change to :to attr
    },
    goToLostRFQ() {
      this.$router.push({path: '/s/rfqs#lost'}); //ToDo needs to change to :to attr
    },
  },
  computed: {
    ...mapGetters({
      countNewRFQs: 'supplier/rfq/countNewRFQs',
      countPricedRFQs: 'supplier/rfq/countPricedRFQs',
      countWonRFQs: 'supplier/rfq/countWonRFQs',
      countLostRFQs: 'supplier/rfq/countLostRFQs',
      gettersAllRFQs: 'supplier/rfq/getAllRfqs',
      getterSearchFilterObj: 'supplier/rfq/getSearchFilterObj',
    }),
    isWidthInputSearch(){
      if(this.searchFilter.length * 8 + 75 > 350 && this.searchFilter.length * 8 + 75 < 600){
        this.widthInputSearch = this.searchFilter.length * 8 + 75
      }
      if(this.searchFilter.length * 8 + 75 > 600){
        this.widthInputSearch = 600
      }
      if(this.searchFilter.length * 8 + 75 < 350){
        this.widthInputSearch = 350
      }
    },
    searchFilterObj() {
      return this.getterSearchFilterObj
    },
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllRFQs, (r) => {
        if (_.includes(this.searchFilter, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    computedSearchFilters() {
      let regos = {};
      let vins = {}
      let partNumbers = {}
      let makes = {}
      let customers = {}
      let supplierQuoteNumbers = {}
      let repairerQuoteNumbers = {}

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.rego) {
          regos[rfq.rego.trim().toLowerCase()] = {label: rfq.rego.trim()}
        }
      })

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.vin) {
          vins[rfq.vin.trim().toLowerCase()] = {label: rfq.vin.trim()}
        }
      })
      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
          })
        }
      })
      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = {label: rfq.make.trim()}
        }
      })
      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.customerName) {
          customers[rfq.customerName.trim().toLowerCase()] = {label: rfq.customerName.trim()}
        }
      })

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.supplierQuoteNumber) {
          supplierQuoteNumbers[rfq.supplierQuoteNumber.trim().toLowerCase()] = {label: rfq.supplierQuoteNumber.trim()}
        }
      })

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.repairerQuoteNumber) {
          repairerQuoteNumbers[rfq.repairerQuoteNumber.trim().toLowerCase()] = {label: rfq.repairerQuoteNumber.trim()}
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      vins = _.orderBy(Object.values(vins), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      supplierQuoteNumbers = _.orderBy(Object.values(supplierQuoteNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      repairerQuoteNumbers = _.orderBy(Object.values(repairerQuoteNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      // Create date template
      // const date = [
      //   {
      //     id: 'date',
      //     label: "Date",
      //     resultObjectOnly: true,
      //     type: "Date",
      //     iconClass: "bx-calendar"
      //   }
      // ];

      // Create searchFilter filters
      return [
        {
          id: 'rego',
          label: 'Rego',
          iconClass: 'bx-user',
          options: [
            regos,
          ]
        },
        {
          id: 'vin',
          label: 'VIN',
          iconClass: 'bx-user',
          options: [
            vins,
          ]
        },
        {
          id: 'customer',
          label: 'Customers',
          iconClass: 'bx-user',
          options: [
            customers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'quoteNumber',
          label: 'Quote Number',
          iconClass: 'bx-store',
          options: [
            supplierQuoteNumbers
          ]
        },
        {
          id: 'repairerQuoteNbr',
          label: 'Repairer Quote Nbr',
          iconClass: 'bx-store',
          options: [
            repairerQuoteNumbers
          ]
        },
        {
          id: 'dateRange',
          label: 'Date Range',
          iconClass: 'bx-store',
          options: [
            []
          ]
        }
      ]
    },
  },
  components: {
    SearchFilter
  }
};
</script>

<style scoped>
@import url(../../../../styles/dashboard/style.css);

.list-values .clickable:hover {
  color: #29BBC1;
}
</style>
