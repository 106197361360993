/* eslint-disable */

import Axios from 'axios'
import {
    SUPPLIER_CUSTOMERS_UPDATED,
    SUPPLIER_CUSTOMERS_LOAD_RFQ,
    SUPPLIER_CUSTOMER_REINSTALLATION, SUPPLIER_CUSTOMERS_SHORT_UPDATING,
} from '../../../mutation-types';
import _ from "lodash";

export function reinstallation(context) {
    context.commit(SUPPLIER_CUSTOMER_REINSTALLATION)
}

export function init(context, payload = 0) {
    let url = '/ir/supplier/customers/init'
    if (!_.isEmpty(payload)) url = '/ir/supplier/customers/' + payload

    Axios.get(url).then(res => {
        if (res.data && res.data._status) {
            context.commit(SUPPLIER_CUSTOMERS_UPDATED, {data: res.data.customers});
        }
    }).catch(err => {
        console.log(err);
    })
}

export function shortUpdating(context, payload = 0) {
    let url = '/ir/supplier/customers/init'
    if (!_.isEmpty(payload)) url = '/ir/supplier/customers/' + payload

    Axios.get(url).then(res => {
        if (res.data && res.data._status) {
            context.commit(SUPPLIER_CUSTOMERS_SHORT_UPDATING, {data: res.data.customers});
        }
    }).catch(err => {
        console.log(err);
    })
}

export function loadRFQsByCustomerId(context, payload) {
    const customerId = payload;
    Axios.get(`/ir/supplier/customer/${customerId}/rfqs`).then(res => {
        context.commit(SUPPLIER_CUSTOMERS_LOAD_RFQ, res.data)
    })
}
