/*eslint-disable */
import * as actions from "./actions";
import mutations from "./mutations";
import * as getters from "./getters";

const state = {
    priceSelectors: [],
    isLoadedPriceSelectors: false,
    settings: {
        "ordersNumberPrefix": "",
        "ordersAutosendToQps": false,
        "ordersReceivedAutosendToQps": false,
        orders: {
            specialOrder: {
                customerToApply: false,
                displayOrderAcceptanceTaCsDescription: null,
                displayOrderAcceptanceTaCsEnable: false,
                enableAcceptanceAgreement: false,
                enableRequireAcceptanceOfOrderTaCs: false,
                enableRequireESignature: false,
                enableRequireSigneeToAddNameOrJobPosition: false,
                sendOrderAcceptanceBy: 'Email'
            }
        },
        "ordersNumberType": "",
        "ordersNumberStart": "",
        franchises: [],
        multipleBrands: [],
        rfq: {
            isUnifiedRfqSettings:false,
            "partAcceptanceSettings": {
                "nonReturnableAcceptanceAgreement": false,
                "applyToAllCustomers": false,
                "displayPartAcceptanceTics": false,
                "tics": "",
                "requireAcceptanceOfOrderTics": false,
                "requireSigneeToAddNameOrJobPosition": false,
                "requireESignature": false,
                "notificationOfAcceptance": false,
                "sendOrderAcceptanceBy": ""
            },
            "statusNameTextDetection": {
                "active": false,
                "backOrderCheckboxes": null,
                "settings": {
                    "ExStock": [],
                    "NoStock": [],
                    "inStock": [],
                    "LowStock": [],
                    "OnBackOrd": [],
                    "NoLongerAvail": []
                }
            },
            "customResponseLevel": {
                "active": false,
                "settings": []
            },
            "responseTimeNotification": {
                "active": false,
                "settings": {
                    closingSoon:{
                        colour: "red",
                        timeLeft: 10,
                        notificationLevel: "Closing Soon",
                        displayAs: 'Text'
                    },
                    closingShortly:{
                        colour: "orange",
                        timeLeft: 20,
                        notificationLevel: "Closing Shortly",
                        displayAs: 'Text'
                    }
                }
            },
            "quoteEmail": null,
            "importQuoteOptions": {
                "importPdfQuote": false,
                "importCheck": false,
                "importCheckCsv": false,
                "importCsvQuote": false
            },
            'notApplicable': [],
            "extraPartCode": null,
            "freightCode": null,
            "quoteValidityPeriod": {
                "active": false,
                "number": null
            },
            "tableDisplayPinnedImages": {
                "active": false
            },
            enablePurchasePriceSelector: false,
            nilSupply: {
                isEnableNilSupply: false,
                items: [],
            },
            nonInStock: {
                nonInStockFormEnable: false,
                validationQrCode: false,
                isAvailableNilStock: false,
                formStatementIntroduction: null,
                formStatementBody: null,
                tics: null
            },
            quoteAssignmentAutoLock: {
                quoteAutoLock: false,
                lockQuoteWhenAssigned: false,
                lockQuoteWhenOpened: false,
                autoLockMessage: null,
            },
            stockStatusQuickComments: {
                "allEnabled": false,
                "all": {
                    "1": [],
                    "2": [],
                    "3": [],
                    "4": [],
                    "5": [],
                    "6": []
                },
                "single": {
                    "1": [],
                    "2": [],
                    "3": [],
                    "4": [],
                    "5": [],
                    "6": []
                }
            }
        },
        "credits": {
            "policies": {
                "enableCreditProcessingPolicy": false,
                "requirePhotosEveryCreditRequest": false,
                "applyAllCustomers": false,
                "reasonForReturnToDisplayPhoto": [],
                "creditReturnPeriod": null,
                "creditReturnPeriodNotice": null
            }
        }
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
