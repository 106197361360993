<script>
import NotificationsComponent from './notifications/notifications'
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "window-notification",
  data() {
    return {
      isActiveBell: false,
    }
  },
  components: {
    NotificationsComponent
  },
  computed: {
    ...mapGetters({
      getActivityList: 'supplier/dashboard/getActivityList',
    }),
    computedActivityList() {
      return _.orderBy(this.getActivityList, (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
    },
  }
}
</script>

<template>
<div>
  <div class="notifications-bell">
    <i class='bx bx-bell' :class="{active: isActiveBell}" @click="isActiveBell = !isActiveBell"></i>
  </div>
  <div class="window-notifications" :class="{active: isActiveBell}">
    <div class="window-notifications-header">
      <div class="window-notifications-header_title">All Notifications</div>
      <span class="window-notifications-header_close"><i class='bx bx-x' @click="isActiveBell = false"></i></span>
    </div>
    <div class="" style="height: 90%; overflow-y: auto">
      <notifications-component :activity="computedActivityList"></notifications-component>
    </div>
  </div>
</div>
</template>

<style scoped>
.bx-bell{
  font-size: 24px;
  z-index: 2147483647;
  margin-top: 11px;
  margin-left: 7px;
  margin-right: 25px;
  color: #03a3ae;
  cursor: pointer;
}
.bx-bell:hover{
  color: #038e94;
}
.bx-bell.active{
  color: rgb(147, 149, 161);
}
.bx-bell.active:hover{
  color: rgb(129, 131, 140);
}
.window-notifications.active{
  display: block;
}
.window-notifications{
  display: none;
  position: absolute;
  height: 600px;
  width: 350px;
  border: 2px solid #a5a5a5;
  border-radius: 5px;
  z-index: 1009;
  background-color: #fdfdfd;
  right: 11px;
  transition: all 0.2s ease-in-out;
}
.window-notifications-header{
  background-color: #14b3ba;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
  color: #f8f8f8;
  padding: 10px 30px;
}
.window-notifications-header_title{

}
.window-notifications-header_close{
  cursor: pointer;
  font-size: 32px;
  position: absolute;
  opacity: 0.9;
  right: 10px;
  top: 5px;
}
.window-notifications-header_close .bx-x:hover{
  color: #eaeaea;
}
</style>
