<template>
  <tabs ref="credits-tabs" cache-lifetime="0">
    <tab name="Policies" id="credits-policies" ref="credits-policies" class-name="tab-content-table">
      <div class="col-md-6 settings-credit-policies-page settings-credit-policies-page_max-width">
        <div class="group-title">
          Credit Processing Policy
        </div>
        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Enable Credit Processing Policy</span> <br/>
            <p class="notifications-info-text">Create and apply a policy in line with your business Credit processing Terms and Conditions.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 143px;">
              <b-form-checkbox
                  v-model="settings.credits.policies.enableCreditProcessingPolicy"
                  @change="changedBrand"
                  name="check-button"
                  switch
                  class="switch-checkbox"
              ></b-form-checkbox>
              <div class="status-text">
                <span v-if="settings.credits.policies.enableCreditProcessingPolicy" class="form-text">Active</span>
                <span v-else class="form-text">Not Active</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-7">
            <span class="notifications-info-title">Apply to All Customers</span> <br/>
            <p class="notifications-info-text">Selecting <strong>Yes</strong> applies to all customers across all RFQ's (Default). Selecting <strong>No</strong>
              allows you set this by customer in the customers settings page.</p>
          </label>
          <div class="d-flex justify-content-end col-12 col-lg-5 apply-to-all-customers-checkboxes" style="place-items: center; padding-bottom: 20px; padding-right: 15px;">
            <div class="text-right row justify-content-end" style="padding-right: 15px;">
              <label style="cursor: pointer" class="form-check-inline radio">
                <input class="form-check-input" type="radio"
                       name="remember"
                       :value="true"
                       @change="changedBrand"
                       v-model="settings.credits.policies.applyAllCustomers"
                       aria-label="Same as registered business address"
                >
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text received-quote">Yes</span>
              </label>
            </div>
            <div class="text-right row justify-content-end">
              <label style="cursor: pointer" class="form-check-inline radio">
                <input class="form-check-input" type="radio"
                       name="remember"
                       :value="false"
                       @change="changedBrand"
                       v-model="settings.credits.policies.applyAllCustomers"
                       aria-label="Same as registered business address"
                >
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text received-quote">No</span>
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Reminder - Require Photos with Credit Request</span> <br/>
            <p class="notifications-info-text">Enable this to remind customers that photos are required with every Credit request for processing.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 143px;">
              <b-form-checkbox
                  name="check-button"
                  switch
                  @change="changedBrand"
                  :class="{
                    'disabled-switch-checkbox': !settings.credits.policies.applyAllCustomers
                   }"
                  :disabled="!settings.credits.policies.applyAllCustomers"
                  v-model="computedRequirePhoto"
                  class="switch-checkbox"
              ></b-form-checkbox>
              <div class="status-text">
                <span v-if="computedRequirePhoto" class="form-text">Active</span>
                <span v-else class="form-text">Not Active</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Reasons for Return to Display Photo</span> <br/>
            <p class="notifications-info-text">Photos requests can be automatically displayed to the customer if they choose a reason for return. Select the Reason category to have your Photo requirement displayed.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px; width: 140px">
            <div class="d-flex justify-content-between" style="width: 140px;">
              <multiselect
                  class="settings-rfq-response-time-multiselect credit-make-multiselect credit-multiselect-height settings-credit-reason-for-return-multiselect"
                  :showLabels="false"
                  :option-height="29"
                  style="min-width: 140px"
                  :options="['Incorrect Parts Delivered', 'Incorrect Parts Ordered',  'Damaged Parts In Transport', 'Damaged Aprts Ex Warehouse', 'No Longer Needed', 'Change In Repair Method']"
                  v-model="computedReasonForReturnToDisplayPhoto"
                  :disabled="!settings.credits.policies.applyAllCustomers"
                  @change="changedBrand"
                  :close-on-select="true"
                  :max-height="250"
                  :multiple="true"
                  placeholder="None"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Credit Return Period</span> <br/>
            <p class="notifications-info-text">Set the number of days that a customer has to return a part for a Credit.</p>
            <p class="notifications-info-text">
              If an attempt to request a credit is outside of nbr of days then they will be notified as per your notice below.
            </p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 140px;">
              <b-form-input @change="changedBrand" v-model="computedCreditReturnPeriod" :disabled="!settings.credits.policies.applyAllCustomers" type="text" class="form-control"></b-form-input>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label">
            <span class="notifications-info-title">Credit Return Terms & Conditions</span> <br/>
            <p class="notifications-info-text">Add the credit return Terms and Conditions information you would like to appear when a customer creates a credit request outside of the return period.</p>
          </label>
          <div class="col-12 notifications-form-checkbox" style="padding-top: 15px; margin-bottom: 50px;">
            <b-textarea @change="changedBrand" v-model="computedCreditReturnPeriodNotice" :disabled="!settings.credits.policies.applyAllCustomers" style="height: 155px"></b-textarea>
          </div>
        </div>

      </div>
    </tab>
    <tab name="Fees" id="credits-fees" ref="credits-fees" class-name="tab-content-table">
      <div class="col-md-6 settings-credit-policies-page settings-credit-policies-page_max-width">
        <div class="group-title">
          Credit Fees
        </div>
        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Enable Credit Return/Handling Fees </span> <br/>
            <p class="notifications-info-text">Align your Return/Handling fees and conditions for processing returns with your customers. Calculations on fees wil be automatically applied
            and used to notify the customer at the time they process the credit.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 143px;">
              <b-form-checkbox
                  v-model="settings.credits.fees.enableCreditReturn"
                  name="check-button"
                  switch
                  @change="changedBrand"
                  class="switch-checkbox"
              ></b-form-checkbox>
              <div class="status-text">
                <span v-if="settings.credits.fees.enableCreditReturn" class="form-text">Active</span>
                <span v-else class="form-text">Not Active</span>
              </div>
            </div>
          </div>
        </div>

        <div class="" style="width: 96%; height: 2px; background-color: rgba(27, 30, 56, 0.12); margin: 0px 15px 10px 15px;"></div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-12">
            <span class="notifications-info-title">Choose the Return/Handling structure </span> <br/>
            <p class="notifications-info-text">Pick one of the following structures that align with your Business fee structure.</p>
          </label>
        </div>

        <div class="row">
          <div class="col-12 col-form-label col-lg-12">
            <multiselect
                :options="structureOptions"
                track-by="key"
                label="value"
                :showLabels="false"
                :option-height="29"
                @change="changedBrand"
                :close-on-select="true"
                :max-height="203"
                v-model="computedChooseReturn"
                placeholder="None"
            />
          </div>
        </div>

        <template v-if="!_isEmpty(settings.credits.fees.chooseReturn)">
          <div class="" style="width: 96%; height: 2px; background-color: rgba(27, 30, 56, 0.12); margin: 8px 15px 10px 15px;"></div>

          <div class="" style="padding: 0px 15px">
            <div class="" style="overflow-x: auto; ">
              <div style="min-width: 900px; width: auto;">
                <div class="row col-12 col-form-label col-lg-12 settings-rfq-table-header-all" style="padding: 0px;">
                  <div class="col-2 settings-rfq-table-header-left">Select Fee Type</div>
                  <div class="col-3 settings-rfq-table-header-center">Make</div>
                  <div class="col-2 settings-rfq-table-header-right">Non-Returnable <br/> Nbr of days</div>
                  <div class="col-2 settings-rfq-table-header-right">Value</div>
                  <div class="col-2 settings-rfq-table-header-right">Value of <br/> Non-Creditable</div>
                  <div class="col-1 settings-rfq-table-header-right">Action</div>
                </div>
                <div class="row col-12 col-form-label col-lg-12" v-for="(itm, index) in settings.credits.fees.feesConfiguration" style="padding: 0px; height: auto">
                  <div class="col-2 settings-rfq-table-block d-flex align-items-center">
                    <template v-if="settings.credits.fees.chooseReturn == 'all' || settings.credits.fees.chooseReturn == 'single'">
                      <multiselect
                          :options="['$Value', '%Percentage']"
                          :showLabels="false"
                          v-model="itm.feeType"
                          @change="changedBrand"
                          :option-height="29"
                          :close-on-select="true"
                          :max-height="203"
                          placeholder="None"
                      />
                    </template>
                    <template v-else> %Percentage</template>
                  </div>

                  <div class="col-3 settings-rfq-table-multislect d-flex align-items-center justify-content-center" style="padding-left: 15px; padding-right: 15px; height: auto">
                    <template v-if="settings.credits.fees.chooseReturn == 'all'">
                      All Makes
                    </template>
                    <multiselect
                        v-else
                        class="settings-rfq-response-time-multiselect credit-make-multiselect credit-multiselect-height"
                        :options="getMakes(itm.make)"
                        :showLabels="false"
                        :option-height="29"
                        @change="changedBrand"
                        :close-on-select="true"
                        :max-height="203"
                        v-model="itm.make"
                        :multiple="true"
                        @select="v => onSelectMake(v, itm)"
                        placeholder="None"
                    />
                  </div>
                  <div class="col-2 settings-rfq-table-block d-flex justify-content-center align-items-center">
                    <b-form-input v-model="itm.nbrOfDays" @change="changedBrand" @keypress="onlyNumber" type="text" class="form-control"></b-form-input>
                  </div>
                  <div class="col-2 settings-rfq-table-block d-flex justify-content-center align-items-center">
                    <number-formatter
                        type="text"
                        :percentage="itm.feeType == '%Percentage' ? true : false"
                        class="form-control rule-parameters-firts-input"
                        v-model="itm.value"
                        @change="changedBrand"
                        :format="itm.feeType == '%Percentage' ? '0%' : '$0.00'">
                    </number-formatter>
                  </div>
                  <div class="col-2 settings-rfq-table-block d-flex justify-content-center align-items-center">
                    <number-formatter
                        type="text"
                        @change="changedBrand"
                        v-model="itm.nonCreditable"
                        class="form-control rule-parameters-firts-input"
                        format="$0.00">
                    </number-formatter>
                  </div>
                  <div class="col-1 settings-rfq-table-block d-flex justify-content-center align-items-center">
                    <i
                        @click="removeItem(index)"
                        class='bx bx-trash clickable'
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove', placement:'leftbottom'}"
                        style="font-size: 19px; color: red; position: initial;">
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3 mb-4">
            <span @click="addAnother" style="cursor:pointer;" class="add-part"><i class="bx bx-plus"></i><span>Add Another</span></span>
          </div>
        </template>
      </div>
    </tab>
  </tabs>
</template>

<script>
import Axios from "axios";
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect'
import NumberFormatter from '../../../../components/utility/number-formatter'

export default {
  name: "the-credit",
  components :{
    multiselect: Multiselect,
    NumberFormatter
  },
  data () {
    return {
      testReason: [],
      settings: {
        credits: {
          policies: {
            applyAllCustomers: false,
            creditReturnPeriod: null,
            creditReturnPeriodNotice: null,
            enableCreditProcessingPolicy: false,
            reasonForReturnToDisplayPhoto: false,
            requirePhotosEveryCreditRequest: false,
          },
          fees: {
            enableCreditReturn: false,
            chooseReturn: '',
            feesConfiguration: []
          }
        }
      },
      structureOptions: [
        {
          value: 'Fixed Fees across all Makes',
          key: 'all',
        },
        {
          value: 'Fixed Fees based on single Makes',
          key: 'single',
        },
        {
          value: 'Variable Fees base on Nbr of Days',
          key: 'variable',
        },
      ],
      makes: [
        'All Makes',
        "Alfa Romeo",
        "Aston Martin",
        "Audi",
        "BMW",
        "BYD",
        "Bentley",
        "CUPRA",
        "Cadillac",
        "Chery",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Dacia",
        "Daewoo",
        "Daihatsu",
        "Dodge",
        "FOTON",
        "FUSO",
        "Ferrari",
        "Fiat",
        "Ford",
        "GENESIS",
        "GMC",
          "GMSV",
        "GREAT WALL",
        "Geely",
        "HAVAL",
        "HINO",
        "HOLDEN",
        "HSV",
        "HUMMER",
        "Honda",
        "Hyundai",
        "IVECO",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "KENWORTH",
        "Kia",
        "LDV",
        "LEXUS",
        "LOTUS",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "MACK",
        "MAHINDRA",
        "MCLAREN",
        "MERCEDES-BENZ",
        "MERCEDES-BENZ COMMERCIAL",
        "MG",
        "Maserati",
        "Mazda",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "POLESTAR",
        "Peugeot",
        "Porsche",
        "Proton",
        "RAM",
        "RANGE ROVER",
        "Renault",
        "Rolls-Royce",
        "Rover",
        "SCANIA",
        "Saab",
        "Seat",
        "Skoda",
        "Smart",
        "SssangYong",
        "Subaru",
        "Suzuki",
        "Tata",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "WESTERN STAR"
      ],
    }
  },
  props: {
    propsSettings: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyId: {
      type: [Number, String],
      default: () => {
        return null
      }
    },
  },
  computed: {
    ...mapGetters({
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
    }),
    computedChooseReturn: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.structureOptions, (itm) => {
            return itm.key === vm.settings.credits.fees.chooseReturn
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        this.settings.credits.fees.chooseReturn = item.key;
      },
    },
    getSettings () {
      return this.isMultipleLocations ? _.cloneDeep(this.propsSettings) : _.cloneDeep(this.getterSettings)
    },
    computedRequirePhoto: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return false;
        } else {
          return this.settings.credits.policies.requirePhotosEveryCreditRequest
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.requirePhotosEveryCreditRequest = value;
        }
      }
    },
    computedCreditReturnPeriod: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return '';
        } else {
          return this.settings.credits.policies.creditReturnPeriod
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.creditReturnPeriod = value;
        }
      }
    },
    computedReasonForReturnToDisplayPhoto: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return [];
        } else {
          return this.settings.credits.policies.reasonForReturnToDisplayPhoto
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.reasonForReturnToDisplayPhoto = value;
        }
      }
    },
    computedCreditReturnPeriodNotice: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return '';
        } else {
          return this.settings.credits.policies.creditReturnPeriodNotice
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.creditReturnPeriodNotice = value;
        }
      }
    },
  },
  methods: {
    addAnother() {
      this.settings.credits.fees.feesConfiguration.push({
        feeType: '',
        make: '',
        nbrOfDays: null,
        value: null,
        nonCreditable: null,
      })
      this.changedBrand()
    },
    removeItem(index) {
      this.settings.credits.fees.feesConfiguration.splice(index)
      this.changedBrand()
    },
    getMakes(make) {
      if (_.includes(make, 'All Makes')) {
        return ['All Makes']
      }
      return  this.makes
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    onSelectMake(v, itm) {
      if (v == 'All Makes') {
        itm.make = ['All Makes']
      }
    },
    onlyNumber (event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 32 && keyCode !== 45 && keyCode !== 43 && keyCode !== 40 && keyCode !== 41) {
        event.preventDefault();
      }
    },
    changedBrand() {
      let result = _.cloneDeep(this.propsSettings)
      result.credits = this.settings.credits
      this.$emit('changedBrand', {value: result, isSettings: true, companyId: this.companyId})
    },
    updateCreditSettings() {
      Axios.post('/ir/company/update-settings/' + this.companyId, {
        settings: this.settings
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success("The Credits Settings have been updated");
              this.$store.dispatch('settings/loadSettings')
            } else {
              this.$toast.error("The Credits Settings haven't been updated");
            }
          })
          .catch(error => {
            this.$toast.error("The Credits Settings haven't been updated");
            console.log(error);
          });
    },
    loadSettings () {
      this.settings = {
        credits: {
          policies: {
            applyAllCustomers: this.getSettings?.credits?.policies?.applyAllCustomers ? this.getSettings.credits.policies.applyAllCustomers : false,
            creditReturnPeriod: this.getSettings?.credits?.policies?.creditReturnPeriod ? this.getSettings.credits.policies.creditReturnPeriod : null,
            creditReturnPeriodNotice: this.getSettings?.credits?.policies?.creditReturnPeriodNotice ? this.getSettings.credits.policies.creditReturnPeriodNotice : null,
            reasonForReturnToDisplayPhoto: this.getSettings?.credits?.policies?.reasonForReturnToDisplayPhoto ? this.getSettings.credits.policies.reasonForReturnToDisplayPhoto : [],
            enableCreditProcessingPolicy: this.getSettings?.credits?.policies?.enableCreditProcessingPolicy ? this.getSettings.credits.policies.enableCreditProcessingPolicy : false,
            requirePhotosEveryCreditRequest: this.getSettings?.credits?.policies?.requirePhotosEveryCreditRequest ? this.getSettings.credits.policies.requirePhotosEveryCreditRequest : false,
          },
          fees: {
            enableCreditReturn: this.getSettings?.credits?.fees?.enableCreditReturn ? this.getSettings.credits.fees.enableCreditReturn : false,
            chooseReturn: this.getSettings?.credits?.fees?.chooseReturn ? this.getSettings.credits.fees.chooseReturn : '',
            feesConfiguration: this.getSettings?.credits?.fees?.feesConfiguration ? this.getSettings.credits.fees.feesConfiguration : [],
          }
        }
      }
    }
  },
  watch: {
    getterSettings: {
      handler() {
        if (!this.isMultipleLocations) {
          this.loadSettings();
        }
      },
      immediate: true,
    },
    propsSettings: {
      handler() {
        if (this.isMultipleLocations) {
          this.loadSettings();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.loadSettings();
  },
}
</script>

<style scoped>
.add-part {
  color: #29BBC1;
  opacity: 1;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin: 30px 15px;
}

.add-part i {
  font-size: 16px;
}

.add-part span {
  display: inline-block;
  position: relative;
  bottom: 2px;
  font-size: 13px !important;
}

.settings-rfq-table-header-left,
.settings-rfq-table-header-right,
.settings-rfq-table-header-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  background-color: #F2F2F2 !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 0px 0px 0px 15px;
  margin-bottom: 1px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.settings-rfq-table-header-right,
.settings-rfq-table-header-center {
  border-left: none;
}

.settings-rfq-table-multislect {
  font-size: 12px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: none;
  border-left: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.settings-rfq-table-block {
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: none;
  border-left: none;
  padding: 15px;
}

.settings-rfq-table-block:first-child {
  border-left: 1px solid rgba(27, 30, 56, 0.25);

}

.settings-rfq-page .settings-rfq-response-time-multiselect .multiselect__tags {
  border: 0;
}

.settings-rfq-page .display-as .settings-rfq-response-time-multiselect .multiselect__tags .multiselect__single {
  font-weight: bold;
  color: #1C1F39;
}

@media screen and (max-width: 991px) {
  .apply-to-all-customers-checkboxes {
    justify-content: flex-start !important;
  }
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.form-check-inline.radio {
  width: auto;
}
.credit-multiselect-height >>> .multiselect__tags{
  height: auto;
  min-height: 32px;
  margin: 15px 0;
  padding-bottom: 0;
}
.credit-multiselect-height >>> .multiselect__select {
  margin-top: 17px;
}
.settings-rfq-table-header-all div{
  padding-left: 10px;
}

.settings-credit-policies-page_max-width{
  max-width: 60%;
}

@media screen and (max-width: 1700px) {
  .settings-credit-policies-page_max-width{
    max-width: 70%;
  }
}
@media screen and (max-width: 1500px) {
  .settings-credit-policies-page_max-width{
    max-width: 95%;
  }
}
@media screen and (max-width: 1024px) {
  .settings-rfq-table-header-all div{
    height: auto;
  }
}

.settings-credit-reason-for-return-multiselect >>> .multiselect__content-wrapper{
  width: 190px;
  position: absolute;
  right: -25px;
}

</style>

<style>
.settings-credit-policies-page .credit-make-multiselect .multiselect__content-wrapper {
  width: 219px !important;
}
.settings-credit-reason-for-return-multiselect .multiselect__tags {
  min-width: 140px;
  height: auto !important;
}
.settings-credit-policies-page .disabled-switch-checkbox .custom-control-label {
  cursor: default;
}
.settings-credit-policies-page .disabled-switch-checkbox .custom-control-label:hover {
  cursor: default;
}
</style>
