/*eslint-disable */
import {
    MARKETPLACE_SET_MENU
} from '../../mutation-types';

export default {
    [MARKETPLACE_SET_MENU](state, v) {
        state.selectedStore = v.selectedStore;
    },
};
