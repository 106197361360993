<template>
  <div class="repairer-supplier-view-page">
    <div class="page-header">
      <h4>Suppliers | {{ supplier.name }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'RepairerSuppliersPrefered'}" v-if="supplier.supplierGroup == 'Prefered'">View
            Suppliers
          </router-link>
          <router-link :to="{ name: 'RepairerSuppliersOther'}" v-else-if="supplier.supplierGroup == 'Other'">View
            Suppliers
          </router-link>
          <router-link :to="{ name: 'RepairerSuppliers'}" v-else>View Suppliers</router-link>
        </li>
        <li class="breadcrumb-item active">Supplier Details</li>
      </ol>
    </div>
    <div class="repairer-supplier-view-body">
      <tabs ref="repairer-supplier-view-table" cache-lifetime="0" :options="{useUrlFragment:false}">
        <tab name="Detail" id="repairer-supplier-view-detail" :class-name="'tab-content p-0'">
          <div class="row repairer-supplier-view-detail-wrapper">
            <div class="col">
              <div class="vehicle-details-block block mt-0 ml-0" style="margin-bottom:30px;">
                <div class="block-header bg-navyblue">
                  Supplier Details
                </div>
                <div class="block-body bg-white">
                  <div class="form-group row">
                    <div class="col block-name">Business Name</div>
                    <div class="col-8 block-value">
                      {{ supplier.name }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">ABN</div>
                    <div class="col-8 block-value">
                      {{ supplier.abn }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">
                      <span v-if="supplier.types.length > 1">Types</span>
                      <span v-else>Type</span>
                    </div>
                    <div class="col-8 block-value">
                      <div v-for="(type, ind) in supplier.types" :key="ind" :class="{'supplier-business-type-item': ind + 1 !== supplier.types.length}" class="">{{ getType(type) }}</div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Phone</div>
                    <div class="col-8 block-value">
                      {{ supplier.phone }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Fax</div>
                    <div class="col-8 block-value">
                      {{ supplier.fax }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Email</div>
                    <div class="col-8 block-value">
                      {{ supplier.email }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="vehicle-details-block block ml-0" style="margin-bottom:30px;">
                <div class="block-header bg-navyblue">
                  Primary Business Address
                </div>
                <div class="block-body bg-white">
                  <div class="form-group row">
                    <div class="col block-name">Address</div>
                    <div class="col-8 block-value">
                      {{ supplier.address && supplier.address.address ? supplier.address.address : '' }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Unit/Level</div>
                    <div class="col-8 block-value">
                      {{ supplier.address && supplier.address.unitLevelLot ? supplier.address.unitLevelLot : '' }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Address Line</div>
                    <div class="col-8 block-value">
                      {{ supplier.address && supplier.address.street ? supplier.address.street : '' }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Suburb</div>
                    <div class="col-8 block-value">
                      {{ supplier.address && supplier.address.suburb ? supplier.address.suburb : '' }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Postcode</div>
                    <div class="col-8 block-value">
                      {{ supplier.address && supplier.address.postcode ? supplier.address.postcode : '' }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">Country</div>
                    <div class="col-8 block-value">
                      {{ supplier.address && supplier.address.country ? supplier.address.country : '' }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col">
              <div class="vehicle-details-block block mt-0 ml-0" style="margin-bottom:30px;">
                <div class="block-header bg-navyblue">
                  Supplier Brands
                </div>
                <div class="block-body bg-white">
                  <div class="form-group row">
                    <label class="col col-form-label">Makes of Part Supplied</label>
                    <div class="col-8">
                      <supplier-makes :chips="supplier.brands" :is-remove-show="false"></supplier-makes>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vehicle-details-block block mt-0 ml-0" style="margin-bottom:30px;">
                <div class="block-header bg-navyblue">
                  PartsSearch Rating
                </div>
                <div class="block-body bg-white">
                  <div class="form-group row ">
                    <div class="col block-name">Rating</div>
                    <div class="col-8 block-value">

                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col block-name">About {{ supplier.name }}</div>
                    <div class="col-8 block-value">
                      {{ supplier.about }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab name="Contacts" id="repairer-supplier-view-contacts" :class-name="'tab-content-table'">
          <div style="overflow-x: auto;">
            <b-table
                responsive
                hover
                :items="computedContacts"
                :fields="contactsFields"
                :filter="filter"
                :current-page="1"
                :per-page="100"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
            </b-table>
          </div>
        </tab>
        <tab name="Locations" id="repairer-supplier-view-locations" :class-name="'tab-content-table'">
          <div style="overflow-x: auto;">
            <b-table
                responsive
                hover
                :items="computedLocations"
                :fields="locationsFields"
                :filter="filter"
                :current-page="1"
                :per-page="100"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
            </b-table>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-options">
            <b-dropdown id="repairer-supplier-view-options" text="Options" class="dropdown-options">
              <template v-slot:button-content>Options
                <span class="dropdown-arrow">
                                    <i class='bx bx-down-arrow'></i>
                                     </span>
              </template>
              <b-dropdown-item @click="addAsPrefered" v-if="!isPreferedsupplierGroup">Add as Prefered</b-dropdown-item>
              <b-dropdown-item @click="removeAsPrefered" v-if="isPreferedsupplierGroup">Remove as Prefered
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import {mapActions, mapGetters} from 'vuex';
import supplierMakes from '../../../components/supplier-makes'

export default {
  name: 'supplier-view',
  components: {supplierMakes},
  data: function () {
    return {
      filter: '',
      contactsFields: [
        {label: "Name", key: "name", sortable: true, tdClass: ' contact-name', thClass: ''},
        {label: "Position", key: "position", sortable: true, tdClass: ' ', thClass: ''},
        {label: "Department", key: "department", sortable: true, tdClass: ' ', thClass: ''},
        {label: "Office Phone", key: "officePhone", sortable: true, tdClass: ' ', thClass: ''},
        {label: "Mobile Phone", key: "mobilePhone", sortable: true, tdClass: ' contact-mobile-phone', thClass: ''},
        {label: "Email", key: "email", sortable: true, tdClass: '', thClass: ''},
        {label: "Location", key: "location", sortable: true, tdClass: '', thClass: ''},
      ],
      locationsFields: [
        {label: "Warehouse Name", key: "name", sortable: true, tdClass: ' warehouse-name', thClass: ''},
        {label: "Address", key: "address", sortable: true, tdClass: ' ', thClass: ''},
        {label: "Phone", key: "phone", sortable: true, tdClass: ' phone', thClass: ''},
        {label: "Open Hours", key: "opepHours", sortable: true, tdClass: '', thClass: ''},
        {label: "Closing Hours", key: "closingHours", sortable: true, tdClass: '', thClass: ''},
        {label: "Delivery", key: "delivery", sortable: true, tdClass: '', thClass: ''},
      ],
      supplier_id: 0,
      defaultSupplier: {
        name: '',
        address: {
          address: '',
        },
        contacts: [],
        locations: [],
        supplierGroup: 'none',
      }
    };
  },
  methods: {
    ...mapActions({
      actionCheckSupplier: 'repairer/supplier/checkSupplier',
      changeSupplierGroup: 'repairer/supplier/changeSupplierGroup',
    }),
    getType(t) {
      return _.find(this.getSupplierBusinessTypes, (type) => {
        return type.value == t
      })?.label
    },
    loadSupplier: function (id) {
      NProgress.start();
      this.actionCheckSupplier(id);
      NProgress.done();
    },
    removeAsSpecialist: function () {
      let item = this.getShortSupplier()
      this.changeSupplierGroup({type: 'Other', item: item });
    },
    removeAsPrefered: function () {
      let item = this.getShortSupplier()
      if (this.changeSupplierGroup({type: 'Other', item: item })) {
        this.$toast.success(item.name + ' successfully removed from preferred suppliers list')
      } else {
        this.$toast.error(item.name + ' did not remove from preferred suppliers list. Please try again')
      }
    },
    addAsPrefered: function () {
      let item = this.getShortSupplier()
      if (this.changeSupplierGroup({ type: 'Prefered', item: item })) {
        this.$toast.success(item.name + ' successfully added as a preferred Supplier')
      } else {
        this.$toast.error(item.name + ' did not add as a preferred Supplier. Please try again')
      }
    },
    addAsSpecialist: function () {
      let item = this.getShortSupplier()
      this.changeSupplierGroup({type: 'Specialist', item: item });
    },
    getShortSupplier () {
      return {
        address: this.supplier.address,
        brands: this.supplier.brands,
        email: this.supplier.email,
        id: this.supplier.id,
        name: this.supplier.name,
        phone: this.supplier.phone,
        state: this.supplier.state,
        suburb: this.supplier.suburb,
        supplierGroup: this.supplier.supplierGroup,
        type: this.supplier.type,
        _dataType: 'short',
      }
    }
  },
  computed: {
    ...mapGetters({
      gettersFullSuppliers: 'repairer/supplier/getFullSuppliers',
      getSupplierBusinessTypes: 'getSupplierBusinessTypes'
    }),
    supplier: function () {
      let supplier = _.find(this.gettersFullSuppliers, {'id': this.supplier_id});
      if (supplier) {
        return supplier;
      } else {
        return this.defaultSupplier;
      }
    },
    computedContacts: function () {
      return this.supplier.contacts;
    },
    computedLocations: function () {
      return this.supplier.locations;
    },
    isPreferedsupplierGroup: function () {
      if (this.supplier && this.supplier.supplierGroup == 'Prefered') {
        return true;
      }
      return false;
    },
    isSpecialistsupplierGroup: function () {
      if (this.supplier.supplierGroup == 'Specialist') {
        return true;
      }
      return false;
    },
    isOthersupplierGroup: function () {
      if (this.supplier.supplierGroup == 'Other') {
        return true;
      }
      return false;
    },
  },
  mounted: function () {
    this.supplier_id = this.$route.params.supplier_id;
    this.loadSupplier(this.$route.params.supplier_id);
  },
};
</script>

<style scoped>
.supplier-business-type-item {
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .repairer-supplier-view-page .repairer-supplier-view-body .tab-content {
    padding-left: 0;
  }

  .vehicle-details-block {
    width: 100%;
    margin-left: 0;
  }

  .repairer-supplier-view-detail-wrapper {
    flex-direction: column;
  }
}

</style>
