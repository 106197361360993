<script>
  /*eslint no-param-reassign:0*/
  /* eslint-disable */
  import draggable from 'vuedraggable';
  import imageGallery from './image-gallery';
  import { isMobile } from 'mobile-device-detect';

  export default {
      name: 'image-lightbox',
      props: [
        'fromComponent',
        'imageList',
        'path',
        'position',
        'isPreviewImage',
        'isDownload',
              'isRemove',
              'isEdit',
        'isCanPin',
         'pinnedImage',
      ],
      data: function () {
        return {
          shownGallery: null,
          galleryImages: [],
          lastVerions: {},
          isMobile: false,
        }
      },
      computed: {
        isFromEstemate: function () {
          return this.fromComponent && this.fromComponent === 'estimate'
        },
        isFromCard: function () {
          return this.fromComponent && this.fromComponent === 'card'
        },
        categories: function () {
          return Object.keys(this.imageList)
        },
        reversedImageList() {
          return this?.imageList?.slice()?.reverse();
        },
        imageClass() {
          switch(this.position) {
              case "pin_images":
                  return "col-12";
              case "below_drag_area":
              default:
                  return "col-4";
          }

        }
      },
      methods: {
        imagesByCategory: function (category) {
          return this.imageList[category]
        },
        makeImageUrl: function (img) {
          return img.src.lastIndexOf('data', 0) === 0 ? img.src : this.path + img.src
        },
        makeImagePreviewUrl: function (img) {
          const versionPart = this.lastVerions[img.id.toString()] ? '?version=' + this.lastVerions[img.id.toString()] : ''
          return (img.previewH240.lastIndexOf('data', 0) === 0 ? img.previewH240 : this.path + img.previewH240) + versionPart
        },
        recoverToUpperCase: function (item) {
          return item.replace(/_/g, ' ').split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ')
        },
        isDraggedIMG: function (evt) {
          if (evt.to.classList.contains('no_images_drag_area') && evt.to.children.length) {
            evt.to.classList.remove('no_images_drag_area')
          }
          if (!evt.from.classList.contains('no_images_drag_area') && evt.from.children.length === 0) {
            evt.from.classList.add('no_images_drag_area')
          }

          let getAttr = evt.item.getAttribute('data-itemArray').split(',')
          let itemInfo = this.imageList[getAttr[1]].filter(e => e.id === getAttr[0])
          this.$emit('updateCategoryFunc', {imgInfo: (itemInfo) ? itemInfo[0] : null, categoryName: evt.to.getAttribute('data-status-wrap')})
        },
        onClickRemove: function (index,img) {
          this.$emit('removeImage', index, img)
        },
        onClickPin: function (index, img) {
          console.log('Pin image - ' + index + ' ' + img.id)
          let id = img.id;
          if (this?.pinnedImage?.id === img.id) {
            id = null;
          }
          this.$emit('onPinnedImage', id)
        },
        onClickImage: function (index) {
          this.shownGallery = {
            imageIndex: index,
            images: this.reversedImageList
          }
        },
        onCloseGallery: function () {
          this.shownGallery = null
        },
        onImageSaved: function (id) {
          this.lastVerions[id.toString()] = (new Date()).getTime().toString()
        }
      },
      mounted: function () {
        this.isMobile = isMobile;
        console.log(`_isMobile: ${this.isMobile}`);
      },
      destroyed: function () {
      },
      components: {
        draggable: draggable,
        'image-gallery': imageGallery
      }
    }
</script>

<template>
  <div :class="position">
    <template v-if="shownGallery">
      <div class="row">
        <div class="col-9">
          <image-gallery :use-editor="false"
                         :isDownload="isDownload"
                         :isRemove="isRemove"
                         :images="shownGallery.images"
                         :pinnedImage="pinnedImage"
                         :current-index="shownGallery.imageIndex"
                          @remove-image="onClickRemove"
                          @close-gallery="onCloseGallery"
                          @saved-image="onImageSaved">
          </image-gallery>
        </div>
        <div class="col-3"></div>
      </div>

    </template>
    <template v-if="false">
      <div v-for="item in categories" class="each_row_images" :class="{'' : position === 'below_drag_area'}">
        <div class="categories_title"><span class="text-title-categories">{{recoverToUpperCase(item)}}</span></div>
        <draggable
          class="draggable-images"
          :data-status-wrap="recoverToUpperCase(item)"
          :class="{'no_images_drag_area': imageList[item].length === 0}"
          element="div" :options="{animation:200, group:'people'}" @start="drag=true" @end="isDraggedIMG" list:item>
          <span :class="imageList[item].length !== 0 ? 'hidden-area-empty-images' : 'area-empty-images'">Empty</span>

          <div :data-itemArray="[img.id, item]"  v-for="(img, ind) in imageList[item]" class="item_div_drag">
            <div v-if="img.src" class="box box-block bg-grey">
              <div class="row icon-action-block">
                <div @click="onClickImage(ind, item)" class="action-button" type="button">
                  <svg width="20px" height="20px" viewBox="0 0 20 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: sketchtool 62 (101010) - https://sketch.com -->
                    <title>0D8EA4A3-1F9F-404B-8374-E502A91B81C9</title>
                    <desc>Created with sketchtool.</desc>
                    <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Dashboard-Redesign-(Detail-Cards)Images" transform="translate(-462.000000, -509.000000)" fill="#1B1E38" fill-rule="nonzero">
                        <g id="Group-3" transform="translate(265.000000, 427.000000)">
                          <g id="Uncategorised">
                            <g id="Group-6" transform="translate(0.000000, 63.000000)">
                              <g id="Group-5" transform="translate(184.000000, 5.000000)">
                                <g id="eye" transform="translate(13.000000, 14.000000)">
                                  <path d="M18,6 C18,4.19 14.24,2.015 9.993,1.99992347 C5.775,1.985 2,4.178 2,6 C2,7.825 5.754,10.006 9.997,10.0000124 C14.252,9.994 18,7.82 18,6 Z M10,12.0000136 C4.958,12.007 0,9.314 0,6 C0,2.686 4.984,-0.017 10,-7.97519788e-05 C15.016,0.017 20,2.686 20,6 C20,9.314 15.042,11.993 10,12.0000136 Z M10,10 C7.790861,10 6,8.209139 6,6 C6,3.790861 7.790861,2 10,2 C12.209139,2 14,3.790861 14,6 C14,8.209139 12.209139,10 10,10 Z M10,8 C11.1045695,8 12,7.1045695 12,6 C12,4.8954305 11.1045695,4 10,4 C8.8954305,4 8,4.8954305 8,6 C8,7.1045695 8.8954305,8 10,8 Z" id="Shape"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div  @click="$emit('removeImage', {key:item, id:img.id})" class="action-button" type="button">
                  <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: sketchtool 60.1 (101010) - https://sketch.com -->
                    <title>EFD4E02B-E531-48FD-903C-7C1EC7568772</title>
                    <desc>Created with sketchtool.</desc>
                    <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Dashboard-Redesign-(Detail-Cards)Files" transform="translate(-1264.000000, -933.000000)" fill="#FF5E5E" fill-rule="nonzero">
                        <g id="Group-4" transform="translate(265.000000, 933.000000)">
                          <g id="trash" transform="translate(999.000000, 0.000000)">
                            <path d="M6,2 L6,1 C6,0.44771525 6.44771525,0 7,0 L11,0 C11.5522847,0 12,0.44771525 12,1 L12,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,5 C18,6.1045695 17.1045695,7 16,7 L15.867,7 L15.187,17.2 C15.081727,18.775582 13.773095,20 12.194,20 L5.826,20 C4.24834545,20 2.94028051,18.7780028 2.833,17.204 L2.137,7 L2,7 C0.8954305,7 0,6.1045695 0,5 L0,4 C0,2.8954305 0.8954305,2 2,2 L6,2 Z M16,4 L2,4 L2,5 L16,5 L16,4 Z M4.141,7 L4.828,17.068 C4.86376888,17.5927957 5.29998676,18.0001655 5.826,18 L12.194,18 C12.7207338,18 13.1572356,17.5915853 13.192,17.066 L13.862,7 L4.142,7 L4.141,7 Z M7,8 C7.55228475,8 8,8.44771525 8,9 L8,16 C8,16.5522847 7.55228475,17 7,17 C6.44771525,17 6,16.5522847 6,16 L6,9 C6,8.44771525 6.44771525,8 7,8 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,16 C12,16.5522847 11.5522847,17 11,17 C10.4477153,17 10,16.5522847 10,16 L10,9 C10,8.44771525 10.4477153,8 11,8 Z" id="Shape"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <a class="hand-pointer" v-if="isPreviewImage" @click="onClickImage(ind, item)">
                <img :src="makeImagePreviewUrl(img)" class="wrap img-fluid">
              </a>
              <a class="hand-pointer" v-else @click="onClickImage(ind, item)">
                <img :src="makeImageUrl(img)" class="wrap img-fluid">
              </a>
            </div>
          </div>
        </draggable>
      </div>
    </template>

    <template v-else>
      <div class="image-lightbox">
        <div class="row no-gutter">
          <template v-for="(img, index) in reversedImageList">
            <div :class="imageClass" class="img-box-container" :key="img.id">
              <div v-if="img" class="box img-box">
                <div v-if="isCanPin" class="control-elem-1 pinImage justify-content-center align-item-center"
                :class="{
                  'd-none': isMobile,
                  'd-lg-flex d-none': !isMobile,
                  'active-pin': pinnedImage && pinnedImage.id && pinnedImage.id === img.id
                }">
                  <a
                      v-if="isCanPin"
                      href="#"
                      class="control-elem-1__item pinImage"
                      @click.prevent="onClickPin(index, img)"
                      v-b-tooltip.hover="{
                        customClass: 'ps-tooltip ps-image-lightbox-tooltip',
                        html: true,
                        title: pinnedImage && pinnedImage.id && pinnedImage.id === img.id ? 'Unpin this image from quotes list' : 'Pin this image to quotes list',
                        placement:'leftbottom'}">
                      <i class='bx bx-pin'></i>
                  </a>
                </div>
                <div v-if="isRemove || isPreviewImage" class="control-elem-1 justify-content-center align-item-center"
                  :class="isMobile ? 'd-none' : 'd-lg-flex d-none'">
                  <a
                          v-if="isPreviewImage"
                          href="#"
                      class="control-elem-1__item control-elem-1__item--bordered eye"
                      @click.prevent="onClickImage(index)"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip ps-image-lightbox-tooltip', html: true, title:'Enlarge Image', placement:'leftbottom'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12"><title></title><g fill="none"><g fill="#1B1E38"><path d="M18 6C18 4.2 14.2 2 10 2 5.8 2 2 4.2 2 6 2 7.8 5.8 10 10 10 14.3 10 18 7.8 18 6ZM10 12C5 12 0 9.3 0 6 0 2.7 5 0 10 0 15 0 20 2.7 20 6 20 9.3 15 12 10 12ZM10 10C7.8 10 6 8.2 6 6 6 3.8 7.8 2 10 2 12.2 2 14 3.8 14 6 14 8.2 12.2 10 10 10ZM10 8C11.1 8 12 7.1 12 6 12 4.9 11.1 4 10 4 8.9 4 8 4.9 8 6 8 7.1 8.9 8 10 8Z"/></g></g></svg>
                  </a>
                  <a
                          v-if="isRemove"
                          href="#"
                      class="control-elem-1__item"
                      @click.prevent="onClickRemove(index, img)"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip ps-image-lightbox-tooltip', html: true, title:'Remove this image', placement:'leftbottom'}">
                    <i class="bx bx-trash"></i>
                  </a>
                </div>
                <a class="hand-pointer img-box__a" v-if="isPreviewImage" @click="onClickImage(index, null)">
                  <img :src="makeImagePreviewUrl(img)" class="wrap img-fluid w-100">
                </a>
                <a class="hand-pointer img-box__a" v-else @click="onClickImage(index, null)">
                  <img :src="makeImageUrl(img)" class="wrap img-fluid w-100">
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<style>
.ps-image-lightbox-tooltip {
  z-index: 9999999 !important;
  color:red !important
}
</style>

<style lang="scss" scoped>
  .control-elem-1 {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 9999;
    /*width: 90px;*/
    height: 40px;
    border-radius: 3px;
    background-color: #FFFFFF;
    opacity: 0;
    transition: opacity 150ms ease-in;
    &__item {
      display: block;
      box-sizing: border-box;
      padding: 10px 13px;
    }
    &__item:hover {
      cursor: pointer;
    }
    &__item--bordered {
      border-right: 1px solid rgba(27,30,56,0.25);
    }
  }

  .control-elem-1.pinImage {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9999;
    /*width: 90px;*/
    height: 40px;
    width: 44px;
    border-radius: 3px;
    background-color: #FFFFFF;
    opacity: 0;
    transition: opacity 150ms ease-in;
    &__item {
      display: block;
      box-sizing: border-box;
      padding: 10px 13px;
    }
    &__item:hover {
      cursor: pointer;
    }
    &__item--bordered {
      border-right: 1px solid rgba(27,30,56,0.25);
    }
  }

  .control-elem-1.pinImage.active-pin {
    opacity: 1 !important;
  }

  .bx-pin {
    color: var(--repairer-color);
    font-size: 20px;
    float: right;
    cursor: pointer;
  }

  .control-elem-1 span {
    width: 1px;
    border-left: 1px rgba(27, 30, 56, 0.1) solid;
    height: 40px;
    margin-left: 12px;
    display: inline-block;
    cursor: pointer;
  }

  i.bx-trash {
    font-size: 20px;
    color: #FF5E5E;
    float: right;
    cursor: pointer;
  }

  .eye:hover g {
    fill: #FF5E5E;
  }

  .V3 .image-lightbox {
    margin-top: 15px;
  }

  .pin_images {
    .box {
      margin-right: 0;
    }
  }

  .img-box-container .img-box {
      margin-right: 5px;
      margin-left: 5px;
  }

  .hand-pointer {
    cursor: pointer;
  }

  .V3 .below_drag_area {
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
  }

  .V3 .below_drag_area div.box {
    text-align: center;
  }

  .V3 .below_drag_area div.box img {
    width: 100%;
    border-radius: 3px;
  }

  .V3 .below_drag_area .img-box {
    position: relative;
    width: 100%;
    height: 250px;
  }

  .V3 .below_drag_area .img-box a {
    display: inline-block;
    height: 250px;
  }

  .V3 .below_drag_area .img-box a img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .V3 .below_drag_area .img-box,
  .img-box
  {
    border-radius: 3px;
    overflow: hidden;
    &__a {
      display: block;
      position: relative;
      padding-top: 75%;
    }
  }

  .img-box img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  .V3 .below_drag_area .img-box:hover > .control-elem-1,
  .img-box:hover > .control-elem-1
  {
    opacity: 1;
  }

  .V3 .below_drag_area .each_row_images > div .item_div_drag{
    width: 100%;
  }

  @media screen and (max-width: 992px) and (min-width:576px) {
    .V3 .below_drag_area .each_row_images > div .item_div_drag{
     /* width: 33.3%; */
    }
  }

  // tablet
  @media screen and (max-width: 768px) {
    .img-box__a {
      height: 130px;
    }
  }

  @media screen and (max-width: 576px) {
    .V3 .below_drag_area .each_row_images > div .item_div_drag{
      width: 100%;
    }
    .img-box-container .img-box {
      // margin-right: 0;
    }
    .img-box__a {
      height: 70px;
    }
  }

  /* extra small device */
  @media screen and (max-width: 350px) {
    .img-box__a {
      height: 18vw;
    }
    .box {
      margin-right: 2.5vw;
      margin-bottom: 2.5vw;
    }
  }

  .V3 .below_drag_area .each_row_images{
    margin: 15px 0;
    padding: 0 15px;
    border-right: 1px solid lightgray;
    width: 283px;
    display: inline-block;
    text-align: center;
  }

  .V3 .each_row_images:last-child {
    border-right: none;
  }

  .V3 .below_drag_area .categories_title {
    height: 3rem;
    text-align: start;
    padding-top: 10px;
    border-radius: 3px;
    background-color: rgba(27,30,56,0.25);
  }

  .V3 .below_drag_area .area-empty-images {
    opacity: 0.25;
    color: #1C1F39;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    margin: auto;
  }

  .V3 .below_drag_area .hidden-area-empty-images{
    display:none;
  }

  .V3 .below_drag_area .no_images_drag_area {
    height: 30rem;
    margin: 1rem 0;
    text-align: center;
    padding-top: 82px;
  }

  .V3 .below_drag_area .draggable-images {
    width: 100%;
    height: 30rem;
    overflow: scroll;
    margin: 1rem 0;
  }

  .V3 .below_drag_area .text-title-categories{
    margin: 1rem;
    font-family: "Inter";
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
  }

  .V3 .below_drag_area .icon-action-block {
    position: absolute;
    height: 40px;
    width: 90px;
    border-radius: 3px;
    background-color: #FFFFFF;
    top: 5px;
    right: 5px;
  }

  .V3 .below_drag_area .icon-action-block div {
    cursor: pointer;
  }

  .V3 .below_drag_area .icon-action-block i {
    font-size: 20px;
    font-weight: bold;
  }

  .V3 .below_drag_area .action-button {
    float: left;
    width: 50%;
    height: 100%;
    padding-top: 9px;
  }

  .V3 .below_drag_area .action-button:first-child {
    border-right: 1px solid rgba(27,30,56,0.25);;
  }

  .V3 .pin_images {
    .image-lightbox {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      > .row .col-12 {
        padding: 0;
      }
    }
    .img-box {
      margin-bottom: 15px;
      &__a {
        height: 160px;
        background-color: #ffffff;
      }
      &__a > img {
        height: 160px;
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }
</style>
