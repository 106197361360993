<template>
  <div class="report-order-summary">
      <div class="chart-title pl-3">
        Order Summary
      </div>
      <div class="d-flex justify-content-end align-items-center position-relative mt-3">
        <div class="header-right d-flex">
          <div class="navbar-nav nav dropdown  dropdown-options show inline customer-name-button" style="margin-right: 48px;">
            <b-dropdown id="supplier-customer-name-view-options" ref="optionMenu" text="Options"
                        class="dropdown-options">
              <template v-slot:button-content>Customer Name
                <span class="dropdown-arrow">
                        <i class='fa fa-chevron-down pull-right'></i>
                    </span>
              </template>
              <b-dropdown-item>Customer 1</b-dropdown-item>
              <b-dropdown-item>Customer 2</b-dropdown-item>
              <b-dropdown-item>Customer 3</b-dropdown-item>
              <b-dropdown-item>Customer 4</b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="dropdown dropdown-options  show inline">
            <b-dropdown id="supplier-customer-name-view-options" ref="optionMenu" text="Options"
                        class="dropdown-options">
                <template v-slot:button-content>{{adjustedBy}}
                    <span class="dropdown-arrow">
                        <i class='fa fa-chevron-down pull-right'></i>
                    </span>
                </template>
                <b-dropdown-item @click="adjustedBy = 'Week'">Week</b-dropdown-item>
                <b-dropdown-item @click="adjustedBy = 'Month'">Month</b-dropdown-item>
                <b-dropdown-item @click="adjustedBy = 'Quarter'">Quarter</b-dropdown-item>
                <b-dropdown-item @click="adjustedBy = 'Year'">Year</b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-if="showFilters" class="active-filters-control">
            <div class="filters-checkboxes">
              <span class="filters-checkboxes-title">Display Difference</span>
              <label class="form-check-inline radio filters-checkbox">
                <input class="form-check-input" type="radio">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
              </label>
              <label class="form-check-inline radio filters-checkbox">
                <input class="form-check-input" type="radio">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
              </label>
            </div>

            <div class="filters-checkboxes">
              <span class="filters-checkboxes-title">Display By Estimator</span>
              <label class="form-check-inline radio filters-checkbox">
                <input class="form-check-input" type="radio">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
              </label>
              <label class="form-check-inline radio filters-checkbox">
                <input class="form-check-input" type="radio">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
              </label>
            </div>
          </div>
        </div>
      </div>

    <div class="chart-wrapper mt-5">
      <apexchart width="800" type="bar" :options="options" :series="series"></apexchart>
      </div>
    </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  import {appConfig} from '../../../../config'
  import _ from 'lodash';
  import {mapGetters} from "vuex";
  import accounting from "accounting";

  export default {
    name: "supplierOrderSummary",
    components: {
      VueApexCharts,
    },
    data() {
      return {
        adjustedBy: 'Month',
        showFilters: false,
        options: {
          chart: {
            id: 'vuechart-example',
            type: 'bar',
            height: 350,
            stacked: true,
            zoom: {
              enabled: true
            },
            toolbar: {
              show: false,
            }
          },
          dataLabels: {
            formatter: () => {
              return '';
            }
          },
          tooltip: {
            custom: ({series, seriesIndex, dataPointIndex, w}) => {
              return (
                  '<strong>' + 'Repairer - ' + w.globals.seriesNames[seriesIndex] + '</strong>' + '<br/>' +
                  'Net Sales ' +   accounting.formatMoney(series[seriesIndex][dataPointIndex])
              )
            }
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return  accounting.formatMoney(val)
              }
            }
          },
          xaxis: {
            categories: ['13-apr', '14-apr', '15-apr', '16-apr', '17-apr', '18-apr', '19-apr', '20-apr']
          }
        },
        series: [{
          name: 'Customer 1',
          data: [44, 55, 41, 67, 22, 43]
        }, {
          name: 'Customer 2',
          data: [13, 23, 20, 8, 13, 27]
        }, {
          name: 'Customer 3',
          data: [11, 17, 15, 15, 21, 14]
        }, {
          name: 'Customer 4',
          data: [21, 7, 25, 13, 22, 8]
        }],
      }
    },
    mounted() {

    },
    methods: {
      exportReport(format) {
        let token = localStorage.getItem('token');
        let type = 'insurers';
        let period = _.toLower(this.adjustedBy);
        let value = 'summary';
        window.open(appConfig.baseAPIURL + `/fe/reports/download/invoices/${type}/${period}/${value}/${format}?at=${token}`);
        return;
      },
      sortObject(obj) {
        let insurers = [];
        let filteredInsurers = {};
        for (let insurer in obj) {
          insurers.push({
            name: insurer,
            value: obj[insurer],
          })
        }
        insurers = _.sortBy(insurers, [function(o) {return o.name.trim().toLowerCase()}]);
        _.forEach(insurers, function (insurer) {
          filteredInsurers[insurer.name] = insurer.value
        })
        return filteredInsurers
      },
      sortData(type) {
        let dataWithoutNonInsurance = {};
        let dataWithoutNonInsuranceFiltered = {};
        let dataAllFiltered = {};

        for (let nameOfData in this.getterIINS[type]) {
          let filteredInsurers = _.pickBy(this.getterIINS[type][nameOfData], function (value, key) {
            return key !== 'Non-Insurance'
          })
          dataWithoutNonInsurance[nameOfData] = filteredInsurers;
        }

        for (let nameOfData in dataWithoutNonInsurance) {
          dataWithoutNonInsuranceFiltered[nameOfData] = this.sortObject(dataWithoutNonInsurance[nameOfData])
        }

        let isHasNonInsurance = true;

        for (let nameOfData in dataWithoutNonInsuranceFiltered) {
          if (!this.getterIINS[type][nameOfData]['Non-Insurance']) {
            isHasNonInsurance = false;
          }
          dataAllFiltered[nameOfData] = _.assign(
            {'Non-Insurance': this.getterIINS[type][nameOfData]['Non-Insurance']},
            dataWithoutNonInsuranceFiltered[nameOfData]
          )
        }
        if (isHasNonInsurance) {
          return dataAllFiltered
        }
        return dataWithoutNonInsuranceFiltered;
      },
    },
    computed: {
      ...mapGetters({

      }),

      computedReportsData() {
        if (this.isEmptyReportsData) {
          return null;
        }
        return {
          week: this.sortData('week'),
          month: this.sortData('month'),
          year: this.sortData('year'),
          quarter: this.sortData('quarter')
        }
      },

      isEmptyReportsData() {
        return _.isEmpty(this.getterIINS);
      },


    }
  }
</script>

<style>
  .report-order-summary .customer-name-button .dropdown-options .btn {
    min-width: 175px !important;
  }

  .theme-supplier .report-order-summary .dropdown-options.customer-name-button ul.dropdown-menu {
    width: 175px;
  }
  
  .report-order-summary .apexcharts-tooltip {
    padding: 15px;
  }
</style>

<style scoped>
  .chart-title {
    color: #1B1E38;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
  }

  .report-table {
    width: calc(100vw - 280px) !important;
  }

  @media screen and (max-width: 767px) {
    .report-table {
      width: calc(100vw - 30px) !important;
    }
  }

  .chart-legend-block {
    display: flex;
    flex-wrap: wrap;
    margin-left: 120px;
  }

  .theme-supplier .dropdown-options .pull-right {
    padding-right: 6px;
    padding-top: 10px;
  }

  .theme-supplier .dropdown-options .filters-button .pull-right {
    padding-right: 3px;
    padding-top: 6px;
  }

  .chart-legend-item {
    display: flex;
    align-items: center;
    font-size: 10px;
  }

  .rectangle-legend {
    width: 20px !important;
    height: 10px !important;
    margin-right: 3px;
  }

  .active-filters-control {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  right: 15px;
  top: 40px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.filters-checkboxes {
  padding: 10px 0 15px 15px;
  display: flex;
}

.filters-checkboxes-title {
  display: inline-block !important;
  width: 195px;
}

.filters-checkbox {
  display: flex;
  margin-right: 30px;
  display: inline-block !important;
  width: 100px;
}
</style>



