<script>
  /*eslint-disable */
  import NProgress from 'nprogress';
  import { appConfig } from "../../../config";
  import Axios from "axios";

  export default {
    name: 'LoginCcnsw',
  data() {
    return {
      username: "",
      password: "",
      isCheckUsername: true,
      isCheckPassword: true,
      rememberMe: true,
      showPassword: false,
    };
  },
  methods: {
    onClickForgotUsername(){
      this.$router.push({name: 'ForgotUserNamePage'});
    },
    onClickForgotPassword(){
      this.$router.push({name: 'ForgotPasswordPage'});
    },
    checkUsername: function () {
      let username = this.username;
      this.isCheckPassword = true;
      Axios({url: '/ir/auth/check/username', data: {username: username}, method: 'POST'})
              .then(resp => {
                if (resp && resp.data && resp.data.status == false) {
                  this.isCheckUsername = false;
                } else {
                  this.isCheckUsername = true;
                }
              })
              .catch(err => {
                console.log(err);
              });
    },
    login: function() {
      let self = this;
      let username = this.username;
      let password = this.password;
      let rememberMe = this.rememberMe;
      NProgress.start();
      this.$store
        .dispatch("auth/login", { username, password, rememberMe })
        .then(() => {
          this.$store.dispatch("auth/successLogin").then(() => {
            let currentPath = this.$router.currentRoute.path;
            if(currentPath==='/login') {
                if (this.$store.getters['currentUser/isUserTypeRepairer']) {
                    this.$router.push('/r');
                } else if (this.$store.getters['currentUser/isUserTypeSupplier']) {
                    this.$router.push('/s');
                }
            }
          });
          NProgress.done();
          //if (this.$store.getters["currentUser/isUserTypeSupplier"]) {
          //  this.$router.push("/s");
          //}else{
          //  this.$router.push("/home33");
          //}
        })
        .catch(error => {
          NProgress.done();
          self.isCheckPassword = false;
        });
    }
  },
      mounted() {
          console.log("this.$router.currentRoute", this.$router.currentRoute.path);
      },
      computed: {
      errorUsername() {
        if (!this.isCheckUsername) {
          return true;
        }
        return false;
      },
      errorPassword() {
        if (!this.isCheckPassword) {
          return true;
        }
        return false;
      }
    }
};
</script>

<template>
  <div class="container-fluid login-page page">
    <div class="content">
      <div class="background column">
        <div style="width: 694px"></div>
      </div>
      <div class="form-container column">
        <div class="form" style="bottom: 70px">
          <div class="">
            <div class="logo"></div>
          </div>

          <form method="POST" @submit.prevent="login">
            <div>
              <img src="@/assets/logos/accident-repair-centres-logo.jpg" class="accident-logo"/>
            </div>
            <h2 class="header-text">Welcome Back</h2>
            <br />
            <div class="form-group">
              <label for="username">Username</label>
              <input
                      type="text"
                      id="username"
                      class="form-control input-field"
                      placeholder="Enter your username"
                      name="username"
                      v-model="username"
                      @change="checkUsername()"
              />
              <div class="w-100 text-right blue-theme">
                <span v-if="errorUsername" class="error-text">
                  <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;We could not find that Username. Try again
                </span>
                <a href="" @click.prevent="onClickForgotUsername">Forgot your Username?</a>
              </div>
            </div>
            <div class="form-group position-relative">
              <label for="password">Password</label>
              <input
                id="password"
                :type="showPassword ? 'text' : 'password'"
                class="form-control input-field"
                placeholder="Enter your password"
                v-model="password"
                name="password"
              />
              <div @click="showPassword = !showPassword" class="toggle-password-btn">
                  <div class="line-password-input"></div>
                  <i v-if="showPassword" class='bx bx-show' ></i>
                  <i v-else class='bx bx-hide' ></i>
              </div>
              <div class="container-fluid w-100">
                <span v-if="errorPassword" class="error-text mb-sm-1">
                  <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;The password entered is incorrect. Try again
                </span>
                <div class="w-100 text-right blue-theme">
                  <a href="" class="text-right recover-password" @click.prevent="onClickForgotPassword">Forgot your Password?</a>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn input-btn">Log in</button>
              <br />
              <br />
<!--              <p class="text-center">-->
<!--                New to PartsSearch?-->
<!--                <router-link :to="'/repairer/register'">Sign Up</router-link>-->
<!--              </p>-->
            </div>
            <div style="margin-top: 70px; border-top: 1px solid rgba(128,128,128,0.28); padding-top: 20px">
              <label style="cursor: pointer" class="form-check-inline checkbox remember-me-checkbox">
                <input class="form-check-input" type="checkbox"
                       name="lock-quote-assigned"
                       v-model="rememberMe"
                       aria-label="Same as registered business address"
                >
                <span style="border: 2px solid #00529C !important; background-color: rgba(27, 30, 56, 0.03) !important;" class="icon">
                      <i class='bx bx-check' style="color: #00529C"></i>
                    </span>
                <span style="color: #1c1f39; font-weight: 600; font-size: 16px; margin-top: 2px; line-height: 25px;" class="text received-quote">Remember this device</span>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../../../styles/login/style.css";
</style>

<style scoped>
  .blue-theme a{
    color: #00529C;
  }
  .blue-theme a:hover{
    color: #013c72;
  }
  .input-btn{
   background-color: #00529C;
  }
  .input-btn:hover{
    background-color: #013c72;
    color: white;
  }
  .accident-logo{
    height: 100px;
    margin-bottom: 70px;
  }
  .remember-me-checkbox input[type="checkbox"]:checked+span.icon i {
    color: #29BBC1;
    transform: translate(-2px, -2px);
  }
    .page {
        height: 100vh;
    }

    @media screen and (max-width: 768px) {
        .page .content {
            height: 100%;
            width: 100%;
        }
        .page .content .form-container {
            height: 100%;
        }
        .page .form {
            height: 100%;
        }
        .page .form form {
            display: flex;
            flex-direction: column;
            height: 80%;
            justify-content: center;
        }
    }
</style>
<style scoped>
.toggle-password-btn {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 20px;
  color: #8d8f9c;
}

.line-password-input {
  width: 1px;
  height: 25px;
  background-color: rgba(27, 30, 56, 0.2);
  position: absolute;
  right: 32px;
}

.form-control.input-field, .input-field {
    padding-right: 50px !important;
}
</style>
