/*eslint-disable */
import {
    BRAND_LIST_LOAD,
    BRAND_LIST_REINSTALLATION
} from '../../mutation-types';

export default {
    [BRAND_LIST_REINSTALLATION] (state) {
        state.items = [];
    },
    [BRAND_LIST_LOAD](state, ui) {
        state.items = ui;
    },
};
