<script>
/* eslint-disable */
import Axios from 'axios'
import { appConfig } from '../../../config.js'
import { VStepper } from 'vue-stepper-component'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import GoodWizard from '@/components/vue-good-wizard/vue-good-wizard.vue'
import ImageLightbox from '@/components/image-lightbox/image-lightbox'
import _ from 'lodash'
import PartsList from '@/components/rfq/parts-list.vue'
import { mapActions, mapGetters } from 'vuex'
import DatePicker from '../../../components/datePicker'
import { numeric } from 'vuelidate/lib/validators'
import NumberFormatter from '../../../components/utility/number-formatter'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import { isMobile } from 'mobile-device-detect'
import TimeZoneConverter from '@/mixins/TimeZoneConverter'
import moment from 'moment'
import { States } from '@/store/states'
import Vue from 'vue'
import RFQImport from './rfq-add/rfq-import-quote-pdf'

import * as dayjs from 'dayjs'

var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
var minMax = require('dayjs/plugin/minMax')
dayjs.extend(minMax)

export default {
  name: 'repairer-rfq-add',
  mixins: [TimeZoneConverter],
  data: function () {
    return {
      nonRegisteredIds: [],
      callComputed: 0,
      callComputed2: 0,
      nonReturnableSupplier: {
        bussinesName: '',
        partTypes: [],
        vehiclePartsType: [],
        makes: [],
      },
      makes:  [
        "Alfa Romeo",
        "Daewoo",
        "Isuzu",
        "Mini",
        "Skoda",
        "Aston Martin",
        "Daihatsu",
        "Jaguar",
        "Mitsubishi",
        "Smart",
        "Audi",
        "Jeep",
        "Nissan",
        "SssangYong",
        "Bentley",
        "Dodge",
        "Kia",
        "Opel",
        "Subaru",
        "BMW",
        "Ferrari",
        "Peugeot",
        "Suzuki",
        "POLESTAR",
        "BYD",
        "Cadillac",
        "Fiat",
        "Lamborghini",
        "Porsche",
        "Tata",
        "Chery",
        "Ford",
        "Lancia",
        "Proton",
        "Chevrolet",
        "Geely",
        "Land Rover",
        "Renault",
        "Toyota",
        "Chrysler",
        "Honda",
        "Maserati",
        "Rover",
        "Volkswagen",
        "Citroen",
        "Hyundai",
        "Mazda",
        "Saab",
        "Volvo",
        "Dacia",
        "Infiniti",
        "MERCEDES-BENZ",
        "Seat",
        "RANGE ROVER",
        "LDV",
        "HAVAL",
        "FOTON",
        "GREAT WALL",
        "LEXUS",
        "HOLDEN",
        "MG",
        "RAM",
        "Rolls-Royce",
        "Tesla",
        "MAHINDRA",
        "MERCEDES-BENZ COMMERCIAL",
        "LOTUS",
        "CUPRA",
        "GMC",
        "HUMMER",
        "GENESIS",
        "MCLAREN",
        "HINO",
        "KENWORTH",
        "FUSO",
        "IVECO",
        "SCANIA",
        "MACK",
        "WESTERN STAR",
      ],
      tier: {
        'OEM-GEN': 1,
        'OEM-PAR': 4,
        'OEM-ALT': 4,
        'AFTM': 2,
        'CERT-AFTM': 2,
        'USED': 4,
        'RECO': 2
      },
      currentTime: null,
      timeInterval: null,
      tempSupp: {
        email: '',
        supplierType: '',
        businessName: '',
      },
      tempSupps: [],
      isDropzoneHidden: false,
      imagesUploading: 0,
      isMounted: false,
      pinnedImage: null,
      selectedReceivedRFQ: {},
      isCheckEmail: true,
      isCheckEmail2: true,
      selectedReceivedRFQId: null,
      selectTime: null,
      isSelectTimeActive: true,
      isShowAllPrefferedSuppliers: false,
      selectedState: '',
      selectedType: 'OEM-GEN',
      newSupplierElement: false,
      preferedSuppliersSearch: '',
      isUseTier: true,
      otherSuppliersSearch: '',
      isActiveGridView: false,
      typesListForPreferedSuppliers: [],
      temporarilyPreferedSuppliers: [],
      currentTimeWithTimeZone: null,
      timeOptions: [],
      valueSupplierByState: null,
      valueSupplierByType: ['OEM-GEN'],
      supplierTypesForGrid: ['OEM-GEN', 'OEM-PAR', 'OEM-ALT', 'CERT-AFTM', 'AFTM', 'USED', 'RECO'],
      supplierStates: [
        { id: '0', name: 'All States' },
        { id: 'ACT', name: 'Australian Capital Territory' },
        { id: 'NSW', name: 'New South Wales' },
        { id: 'NT', name: 'Northern Territory' },
        { id: 'QLD', name: 'Queensland' },
        { id: 'SA', name: 'South Australia' },
        { id: 'TAS', name: 'Tasmania' },
        { id: 'VIC', name: 'Victoria' },
        { id: 'WA', name: 'Western Australia' },
      ],
      isLoading: false,
      PDFUploadDropzoneOptions: {
        clickable: '#clickBrowsePDFBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/repairer/rfq/parse-pdf',
        headers: { 'Authorization': localStorage.getItem('token')},
        method: 'post',
        maxFilesize: 20,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'application/pdf',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 20MB per file. Please reduce the file size and upload again',
      },
      imageUploadDropzoneOptions: {
        clickable: '#clickBrowseImagesBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/repairer/rfq/uploadFile',
        //      url: appConfig.baseAPIURL + '/ir/user/photo/upload',
        headers: { 'Authorization': localStorage.getItem('token')},
        method: 'post',
        maxFilesize: 50,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      images: [],
      imageStorage: [],
      receivedImages: [],
      steps: [
        {
          label: 'Select Quote Type',
          slot: 'rfq-add-step-1',
          class: 'rfq-add-steps rfq-add-step-1',
          isAdvert: 'both',
        },
        {
          label: 'Quote Details',
          slot: 'rfq-add-step-2',
          class: 'rfq-add-steps rfq-add-step-2',
          isAdvert: 'both',
        },
        {
          label: 'Parts List',
          slot: 'rfq-add-step-3',
          class: 'rfq-add-steps rfq-add-step-3',
          isAdvert: 'both',
        },
        {
          label: 'Images',
          slot: 'rfq-add-step-4',
          class: 'rfq-add-steps rfq-add-step-4',
          isAdvert: 'both',
        },
        {
          label: 'Suppliers',
          slot: 'rfq-add-step-5',
          class: 'rfq-add-steps rfq-add-step-5',
          isAdvert: 'both',
        },
        {
          label: 'Time',
          slot: 'rfq-add-step-6',
          class: 'rfq-add-steps rfq-add-step-6',
          isAdvert: 'both',
          options: {
            nextDisabled: true, // control whether next is disabled or not
          },
        }
      ],
      step: 1,
      dpOptions: {
        autoclose: true,
        todayHighlight: true,
        format: 'dd/mm/yyyy'
      },
      drImageOptions: {
        messages: {
          'default': 'Drag and drop an image here or click to select it from your computer',
          'replace': 'Drag and drop or click to add new image',
          'remove': 'Remove',
          'error': 'Ooops, something went wrong.'
        }
      },
      receivedRFQsFields: [
        {
          label: 'Request Nbr',
          key: 'number',
          sortable: true,
          tdClass: 'clickable supplier-name',
          thClass: 'text-left align-center'
        },
        {
          label: 'Quoting Package Nbr',
          key: 'QPNumber',
          sortable: true,
          tdClass: 'clickable ',
          thClass: 'text-left align-center'
        },
        { label: 'Car Rego', key: 'rego', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        { label: 'Make', key: 'make', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        { label: 'Model', key: 'model', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        { label: 'Build Date', key: 'dom', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        {
          label: 'Date Received',
          key: 'dateReceived',
          sortable: false,
          tdClass: 'clickable text-center align-center supplier-select',
          thClass: 'text-center align-center'
        },
        {
          label: '',
          key: 'action',
          sortable: false,
          tdClass: 'clickable text-center align-center supplier-action',
          thClass: 'text-center align-center'
        },
      ],
      preferedSuppliersFields: [
        {
          label: 'Supplier Name',
          key: 'name',
          sortable: true,
          tdClass: 'clickable supplier-name',
          thClass: 'text-left align-center'
        },
        { label: 'Suburb', key: 'suburb', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        { label: 'Types', key: 'types', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        {
          label: 'PartSearch Rating',
          key: 'rating',
          sortable: true,
          tdClass: 'clickable ',
          thClass: 'text-left align-center'
        },
        { label: 'Status', key: 'status', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        {
          label: 'Select',
          key: 'select',
          sortable: false,
          tdClass: 'clickable text-center align-center supplier-select',
          thClass: 'text-center align-center'
        },
        // removed by #521.1
        // {
        //   label: 'Action',
        //   key: 'action',
        //   sortable: false,
        //   tdClass: 'clickable text-center align-center supplier-action',
        //   thClass: 'text-center align-center'
        // },
      ],
      otherSuppliersFields: [
        {
          label: 'Supplier Name',
          key: 'name',
          sortable: true,
          tdClass: 'clickable supplier-name',
          thClass: 'text-left align-center'
        },
        { label: 'Suburb', key: 'suburb', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        { label: 'State', key: 'state', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        { label: 'Types', key: 'types', sortable: true, tdClass: 'clickable ', thClass: 'text-left align-center' },
        {
          label: 'PartSearch Rating',
          key: 'rating',
          sortable: true,
          tdClass: 'clickable ',
          thClass: 'text-left align-center'
        },
        {
          label: 'Makes Supplied',
          key: 'makes',
          sortable: true,
          tdClass: 'clickable text-center align-center  supplier-select',
          thClass: 'text-center align-center'
        },
        {
          label: 'Action',
          key: 'action',
          sortable: false,
          tdClass: 'clickable text-center align-center supplier-action',
          thClass: 'text-center align-center'
        },
      ],
      isAddSuppliers: false,
      isSendAsAdditional: true,
      isApplyPartsNbrs: false,
      isShowApplyPartNumbers: false,
      tierSuppliers: null,
      isDraggableDisabled: false,
      supplierModalType: '',
      supplierBusinessTypes: [
        {
          value: 'OEM',
          label: 'OEM - Original Equipment Manufacturer'
        },
        {
          value: 'PAR',
          label: 'PAR - Paralell'
        },
        {
          value: 'PAR/AFTM',
          label: 'PAR/AFTM - Parralell & After-market'
        },
        {
          value: 'AFTM',
          label: 'AFT-M - After-Market',
        },
        {
          value: 'AFTM/USED',
          label: 'AFTM/USED - After-market & Used'
        },
        {
          value: 'USED',
          label: 'USED - Used Parts',
        },
        {
          value: 'RECO',
          label: 'RECO - Reconditioned Parts'
        }
      ],
      isSetDate: false,
      rfq: {
        id: null,
        type: 'Manual',
        QPNumber: '',
        number: '',
        estimator: '',
        parentQPNumber: null,
        comments: '',
        setPinImage: null,
        setPinImageFileId: null,
        additionalRfqFor: null,
        details: {
          rego: '',
          comments: '',
          vin: '',
          make: '',
          model: '',
          modelNumber: '',
          series: '',
          dom: '',
          body: '',
          colour: '',
          transmission: 'Automatic',
          odometer: '',
          claimNumber: '',
          insurer: '',
        },
        parts: [],
        images: [],
        newImages: [],
        suppliers: [],
        suppliers2: {},
        datetime: { date: '', time: '' },
        directOrderParentQpNumber: '',
        directOrder: {
          deliveryDate: null,
          isMarkAsUrgent: null,
          stockLvls: [],
          isDeliverAsCompleteOrder: null,
          purchaseOrderNumber: '',
          comment: '',
        },
        isSendAsDirectOrder: this.$route && this.$route.params && this.$route.params.isSentAsDirectOrder ? this.$route.params.isSentAsDirectOrder : false,
      },

      selectTimeInvalid: false,
      askForPaintCode: null,
      buttonsTop: 0,
    }
  },
  validations: {
    rfq: {
      details: {
        odometer: {
          numeric
        }
      }
    }
  },

  methods: {
    ...mapActions({
      changeSupplierGroup: 'repairer/supplier/changeSupplierGroup',
      updateRFQTable: 'repairer/rfq/getRFQsForTableView',
      getRFQById: 'repairer/rfq/getRFQById',
    }),
    getDayOfWeek(d) {
      let resultD = dayjs(d, 'DD/MM/YYYY').day()
      if (_.isEmpty(d)) {
        resultD = dayjs().day()
      }
      return  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][resultD]
    },
    onSelectMake(v) {
      if (v == 'All Makes') {
        this.nonReturnableSupplier.makes = ['All Makes']
      }
    },
    correctMake(v) {
      v = _.trim(_.toUpper(v))
      if (v === 'GWM') {
        v = 'GREAT WALL'
      } else if (v === 'GREAT') {
        v = 'GREAT WALL'
      } else if (v === 'MERCEDES BENZ') {
        v = 'MERCEDES-BENZ'
      }
      return v
    },
    getTypes(item) {
      if (item?.types?.length == 1) {
        return item.types[0]
      } else if (item?.types?.length > 1) {
        return 'Multiple'
      }
      return ''
    },
    createNonRegisteredSupplier(){
      let types = [];
      _.forEach(this.nonReturnableSupplier.partTypes, (t) => {
        types.push(t.value)
      })
      let makes = this.nonReturnableSupplier.makes
      if (makes[0] === 'All Makes') {
        makes = -1
      }
      Axios.post('/ir/repairer/supplier/add-non-registered', {
        businessName: this.nonReturnableSupplier.bussinesName,
        partTypes: types,
        vehiclePartsType: this.nonReturnableSupplier.vehiclePartsType?.value,
        supplierGroup: this.supplierModalType == 'Preffered' ? 'Prefered' : 'Other',
        makes: makes,
      }).then((r)=>{
        if (r.data._status && r.data.supplier) {
          let obj =  r.data.supplier
          this.$store.commit('repairer/supplier/repairerSupplierAddNew', obj)
          this.nonRegisteredIds.push(obj.id)
          if (obj.supplierGroup === 'Prefered') {
            if (obj.types.includes('OEM-GEN')) {
              this.rfq.suppliers2['OEM-GEN'] = [];
              this.rfq.suppliers = []
              for (let type in this.rfq.suppliers2) {
                _.forEach(this.rfq.suppliers2[type], (itm) => {
                  this.rfq.suppliers.push(itm)
                })
              }
              this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
            }
            this.selectPreferedSupplierFromList(true, obj.id, obj.types, true)
          }

          this.nonReturnableSupplier = {
            bussinesName: '',
            partTypes: [],
            vehiclePartsType: [],
            makes: [],
          }
        }
      })
    },
    getMakes() {
      if (_.includes(this.nonReturnableSupplier.makes, 'All Makes')) {
        return ['All Makes']
      }

      let makes = _.sortBy(this.makes, (item) => {
        return item;
      })

      if (!_.includes(makes, 'All Makes')) {
        makes.unshift('All Makes')
      }
      return makes
    },
    addedNewInsurer(v) {
      this.rfq.details.insurer = v;
      this.$store.commit('repairer/supplier/repaireInsurerAddNew', v)
    },
    selectPreferedSupplierFromList (isSelected, id, type, isManualSelect = false) {
      this.callComputed++
      this.callComputed2++
      if (isSelected) {
        if (type.length > 1) {
          _.forEach(type, (t) => {
            if (_.isEmpty(this.rfq.suppliers2[t])) {
              this.rfq.suppliers2[t] = []
            }
            if (this.tierSuppliers && !this.isShowAllPrefferedSuppliers && this.isUseTier) {
              if ( (t === 'OEM-GEN' && (Number(this.tierSuppliers.oemGen) === Number(id)) && this.computedTypesOfCheckedParts.includes('OEM-GEN')) ||
                  (t === 'OEM-PAR' && (Number(this.tierSuppliers.oemPar1) === Number(id) || Number(this.tierSuppliers.oemPar2) === Number(id) || Number(this.tierSuppliers.oemPar3) === Number(id) || Number(this.tierSuppliers.oemPar4) === Number(id)) && this.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
                  (t === 'OEM-ALT' && (Number(this.tierSuppliers.oemAlt1) === Number(id) || Number(this.tierSuppliers.oemAlt2) === Number(id) || Number(this.tierSuppliers.oemAlt3) === Number(id) || Number(this.tierSuppliers.oemAlt4) === Number(id)) ) ||
                  (t === 'CERT-AFTM' && (Number(this.tierSuppliers.certAftm1) === Number(id) || Number(this.tierSuppliers.certAftm2) === Number(id)) && this.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
                  (t === 'CERT-AFTM' && (Number(this.tierSuppliers.certAftm1) === Number(id) || Number(this.tierSuppliers.certAftm2) === Number(id)) && this.computedTypesOfCheckedParts.includes('CERT-AFTM')) ||
                  (t === 'AFTM' && (Number(this.tierSuppliers.aftm1) === Number(id) || Number(this.tierSuppliers.aftm2) === Number(id)) && this.computedTypesOfCheckedParts.includes('AFTM')) ||
                  (t === 'USED' && (Number(this.tierSuppliers.used1) === Number(id) || Number(this.tierSuppliers.used2) === Number(id) || Number(this.tierSuppliers.used3) === Number(id) || Number(this.tierSuppliers.used4) === Number(id)) && this.computedTypesOfCheckedParts.includes('USED'))
              ) {
                this.rfq.suppliers2[t].push(id)
              } else if (!isManualSelect) {
                let supplier = _.find(this.gettersFullSuppliers, (supp) => {
                  return Number(supp.id) === Number(id)
                })
                if (supplier) {
                  if (supplier.types.includes(t) && this.computedTypesOfCheckedParts.includes(t) && !_.includes(this.rfq.suppliers2[t], id)) {
                    this.rfq.suppliers2[t].push(id)
                  }
                }
              }
            } else {
              let supplier = _.find(this.gettersFullSuppliers, (supp) => {
                return Number(supp.id) === Number(id)
              })
              if (supplier) {
                if (supplier.types.includes(t) && this.computedTypesOfCheckedParts.includes(t) && !_.includes(this.rfq.suppliers2[t], id)) {
                  this.rfq.suppliers2[t].push(id)
                }
              }
            }
          })
        } else if (type.length === 1) {
          if (_.isEmpty(this.rfq.suppliers2[type[0]])) {
            this.rfq.suppliers2[type[0]] = []
          }
          this.rfq.suppliers2[type[0]].push(id)
        }

      } else {
        _.forEach(type, (t) => {
          if (!_.isEmpty(this.rfq.suppliers2[t])) {
            let index = this.rfq.suppliers2[t].indexOf(id)
            if (index !== -1) {
              this.rfq.suppliers2[t].splice(index, 1)
            }
          }
        })
      }
      this.rfq.suppliers = []
      for (let type in this.rfq.suppliers2) {
        _.forEach(this.rfq.suppliers2[type], (itm) => {
          this.rfq.suppliers.push(itm)
        })
      }
      this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
    },
    selectPreferedSupplierFromGrid (s, type) {

      this.rfq.suppliers = []
      for (let type in this.rfq.suppliers2) {
        _.forEach(this.rfq.suppliers2[type], (itm) => {
          this.rfq.suppliers.push(itm)
        })
      }
      this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)

      if (this.isPrefferdSupplierDisabled(s, type) || (type === 'OEM-GEN' && this.isAddSuppliers)) {
        return
      }

      if (_.isEmpty(this.rfq.suppliers2[type])) {
        this.rfq.suppliers2[type] = []
      }

      if (!this.rfq.suppliers2[type].includes(s.id)) {
        this.rfq.suppliers2[type].push(s.id)
      } else {
        this.rfq.suppliers2[type].splice(this.rfq.suppliers2[type].indexOf(s.id), 1)
      }

      this.rfq.suppliers = []
      for (let type in this.rfq.suppliers2) {
        _.forEach(this.rfq.suppliers2[type], (itm) => {
          this.rfq.suppliers.push(itm)
        })
      }
      this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
    },
    isNotStringOrNumber (v) {
      return !(_.isString(v) || _.isNumber(v))
    },
    goNext () {
      this.$refs.RFQAddStepper.goNext()
      if (this.$refs.RFQAddStepper.currentStep === 2) {
        this.handleResize()
      }
      if (this.$refs.RFQAddStepper.currentStep === 4) {
        if (!_.isEmpty(this.rfq.suppliers)) {
          this.rfq.suppliers = _.filter(this.rfq.suppliers, (id) => {
            let supplier = _.find(this.gettersPreferedSuppliers, (sup) => {
              return Number(sup.id) === Number(id)
            })
            return supplier && _.includes(this.computedTypesListForPreferedSuppliers, supplier.type)
          })
        }
      }
      if (this.$refs.RFQAddStepper.currentStep === 5) {
        if (!_.isEmpty(this.companyInfo) && this.companyInfo.address.state && _.isEmpty(this.rfq.datetime.date) && !this.isSetDate) {
          this.isSetDate = true
          this.initTime(this.companyInfo.address.state, this.companyInfo.address.country)
        }
      }
      if (this.$refs.RFQAddStepper.currentStep === 4) {
        this.clickSelectSupplierByState(this.valueSupplierByState.id)
        if (!_.isEmpty(this.rfq.details.make)) {
          this.tierSuppliers = _.find(this.getTier1, (t) => {
            return t.make === this.rfq.details.make
          })
          if (this.tierSuppliers && this.isNotStringOrNumber(this.tierSuppliers.oemGen) &&
              this.isNotStringOrNumber(this.tierSuppliers.oemPar1) && this.isNotStringOrNumber(this.tierSuppliers.oemPar2) && this.isNotStringOrNumber(this.tierSuppliers.oemPar3) && this.isNotStringOrNumber(this.tierSuppliers.oemPar4) &&
              this.isNotStringOrNumber(this.tierSuppliers.oemAlt1) && this.isNotStringOrNumber(this.tierSuppliers.oemAlt2) && this.isNotStringOrNumber(this.tierSuppliers.oemAlt3) && this.isNotStringOrNumber(this.tierSuppliers.oemAlt4) &&
              this.isNotStringOrNumber(this.tierSuppliers.used1) && this.isNotStringOrNumber(this.tierSuppliers.used2) && this.isNotStringOrNumber(this.tierSuppliers.used3) && this.isNotStringOrNumber(this.tierSuppliers.used4) &&
              this.isNotStringOrNumber(this.tierSuppliers.certAftm1) && this.isNotStringOrNumber(this.tierSuppliers.certAftm2) &&
              this.isNotStringOrNumber(this.tierSuppliers.aftm1) && this.isNotStringOrNumber(this.tierSuppliers.aftm2)
          ) {
            this.tierSuppliers = null
          }
        }

        if (!_.isEmpty(this.computedPreferedSuppliers)) {
          if (!_.isEmpty(this.tierSuppliers) && !this.isShowAllPrefferedSuppliers && this.isUseTier) {
            _.forEach(this.computedPreferedSuppliers, (s) => {
              if (!_.includes(this.rfq.suppliers, s.id)) {
                this.selectPreferedSupplierFromList(true, s.id, s.types, true)
                this.rfq.suppliers.push(s.id)
                this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
              }
            })
          }
        }

        if (!_.isEmpty(this.rfq.suppliers)) {
          this.rfq.suppliers = _.filter(this.rfq.suppliers, (id) => {
            let supplier = _.find(this.gettersFullSuppliers, (sup) => {
              return Number(sup.id) === Number(id)
            })
            let b = false;
            _.forEach(supplier.types, (t) => {
              if (_.includes(this.computedTypesListForPreferedSuppliers, t)) {
                b = true
              }
            })
            return supplier && b
          })
        }
        for (let type in this.rfq.suppliers2) {
          this.rfq.suppliers2[type] = _.filter(this.rfq.suppliers2[type], (itm) => {
            return this.rfq.suppliers.includes(itm)
          })
        }

      }

    },
    _isEmpty (v) {
      return _.isEmpty(v)
    },
    onChangeApplyPartsNbrs () {
      if (this.isApplyPartsNbrs) {
        _.forEach(this.rfq.parts, (prt) => {
          if (prt?.hasOwnProperty('numberCopy')) {
            prt.number = prt.numberCopy
          }
        })
      } else {
        _.forEach(this.rfq.parts, (prt) => {
          prt.numberCopy = prt.number
          prt.number = ''
        })
      }
    },
    onPinnedImage (imgId) {
      if (imgId) {
        let receivedImg = _.find(this.receivedImages, (img) => {
          return Number(img.id) === Number(imgId)
        })
        if (receivedImg) {
          this.rfq.setPinImage = imgId
          this.rfq.setPinImageFileId = null
        } else {
          this.rfq.setPinImage = null
          this.rfq.setPinImageFileId = imgId
        }
        this.pinnedImage = {
          id: imgId,
        }
      } else {
        this.pinnedImage = null
        this.rfq.setPinImage = null
        this.rfq.setPinImageFileId = null
      }
    },
    changeListView () {
      this.isActiveGridView = !this.isActiveGridView
      localStorage.setItem('isActiveGridView', this.isActiveGridView)
    },
    setSuppForEstimate () {
      if (this.rfq.isSendAsDirectOrder && !_.isEmpty(this.rfq.parentQPNumber) && _.isEmpty(this.rfq?.directOrderParentQpNumber)) {
        this.rfq.directOrderParentQpNumber = this.rfq.parentQPNumber
      }
    },
    checkTempSupp (isCheck) {
      if (isCheck) {
        this.$toast.success('Temporary Supplier Invite Queued to Send')
      }
    },
    checkEmail () {
      this.isCheckEmail2 = true
      Axios.post('/ir/company/check-temp-companies', { emails: [this.tempSupp.email] })
          .then(response => {
            if (response.data._status && response.data.data) {
              if (response.data.data[this.tempSupp.email] && response.data.data[this.tempSupp.email].id) {
                this.isCheckEmail2 = false
              }
            }
          })
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.isCheckEmail = re.test(this.tempSupp.email)
    },
    closeModal () {
      this.$refs.inviteModal.hide()
    },
    sendInviteSupplier () {
      this.$refs.inviteModal.hide()
      let tempObj = {
        type: this.tempSupp.supplierType.value,
        email: this.tempSupp.email,
        name: this.tempSupp.businessName,
        isChecked: true,
        isTemp: true,
        rating: 'Temporary Invite'
      }
      this.tempSupp.email = ''
      this.tempSupp.businessName = ''
      this.tempSupp.supplierType = ''
      this.tempSupps.push(tempObj)
      this.$toast.success('Temporary Supplier Invite Queued to Send')
    },
    getMinutesFromString (str) {
      let result = 0
      str = _.toLower(str)
      if (str.includes('hours')) {
        result += 60 * Number(str.split('hours')[0])
      } else if (str.includes('hour')) {
        result += 60
      }

      if (str.includes('mins')) {
        let mins = 0
        if (str.includes('hours')) {
          mins = str.split('hours')[1].split('mins')[0]
        } else if (str.includes('hour')) {
          mins = str.split('hour')[1].split('mins')[0]
        } else {
          mins = str.split('mins')[0]
        }
        result += Number(mins)
      }
      return result
    },
    incomingDataFromPartsbridge (data) {
      try {
        this.pasteDataFromPartsbridge(data)
        if (this.$refs.RFQAddStepper.currentStep == 0) {
          this.$refs.RFQAddStepper.goNext()
        }
      } catch (e) {
        console.error(e)
        return
      }
      window.postMessage({
        type: 'partsbridge-ESTIMATE_DATA_TO_PARTSSEARCH_COMPLETED'
      })
    },
    pasteDataFromPartsbridge (data) {
      const parts = []
      data.partsOrder?.forEach(partId => {
        let dataPart = data.parts[partId]
        parts.push({
          'id': null,
          'name': dataPart.description,
          'number': dataPart.partNumber,
          'nameBySupplier': null,
          'numberBySupplier': null,
          'rrpBySupplier': null,
          'isNeedsOEM': false,
          'isNeedsOEMGEN': false,
          'isNeedsOEMPAR': false,
          'isNeedsOEMALT': false,
          'isNeedsAFTM': false,
          'isNeedsCERTAFTM': false,
          'isNeedsRECO': false,
          'isNeedsUSED': false,
          'type': { 'oemGen': true, 'oemPar': false, 'oemAlt': false, 'certAftm': false, 'aftm': false, 'used': false, 'reco': false },
          'qty': dataPart.currentValueQuantity,
          'originalQty': dataPart.currentValueQuantity,
          'listOrder': 999,
          'groupSelectId': null,
          'qpsSystem': 'PARTSBRIDGE_IBODYSHOP',
          'qpsPartId': dataPart.itemId,
        })
      })

      this.rfq = {
        id: null,
        qpsId: data.estimate.id,
        type: 'Manual',
        QPNumber: data.estimate.number,
        number: '',
        estimator: data.estimate.estimatorName,
        comments: '',
        parentQPNumber: data.parentQPNumber,
        additionalRfqFor: data.additionalRfqFor,
        sourceSystem: 'partsbridge ibodyshop',
        setPinImage: null,
        setPinImageFileId: null,
        details: {
          rego: data.estimate.vehicle.registration,
          comments: null,
          vin: data.estimate.vehicle.vin,
          make: data.estimate.vehicle.make,
          model: data.estimate.vehicle.model,
          body: data.estimate.vehicle.body,
          modelNumber: null,
          series: data.estimate.vehicle.series,
          dom: null,
          colour: data.estimate.vehicle.colour,
          transmission: data.estimate.vehicle.transmission,
          odometer: data.estimate.vehicle.odometer,
          claimNumber: data.estimate.vehicle.claimNumber,
          insurer: null,
        },
        parts: [...parts],
        images: [],
        newImages: [],
        suppliers: [],
        suppliers2: {},
        datetime: { date: '', time: '' },
        directOrder: {
          deliveryDate: null,
          isMarkAsUrgent: null,
          stockLvls: [],
          isDeliverAsCompleteOrder: null,
          purchaseOrderNumber: '',
          comment: '',
        },
        directOrderParentQpNumber: '',
        isSendAsDirectOrder: this.$route && this.$route.params && this.$route.params.isSentAsDirectOrder ? this.$route.params.isSentAsDirectOrder : false,
      }

      if (data?.properties?.isSentAsDirectOrder) {
        this.rfq.isSendAsDirectOrder = true
        if (data?.parentQPNumber) {
          this.rfq.directOrderParentQpNumber = data.parentQPNumber
        }
      }

      this.receivedImages = []
    },
    partsbridgeImport () {
      window.postMessage({
        type: 'partsbridge-RUN_ESTIMATE_DATA_TO_PARTSSEARCH'
      })
    },
    handleResize () {
      if (this.isMounted && document.getElementsByClassName('wizard__body')[0]) {
        let width = document.getElementsByClassName('wizard__body')[0].clientWidth
        let diff = this.rfq.type === 'Manual' ? 0 : 219
        if (width <= 1200) {
          this.buttonsTop = 0 + diff
        } else {
          this.buttonsTop = ((width - 1200) / 2) + diff
        }
        if (this.isShowAdvert && ((this.rfq.type !== 'Manual' && this.$refs.RFQAddStepper.currentStep === 2))) {
          this.buttonsTop += 100
        }
      }
    },
    updateEstimator (v, field) {
      if (this.rfq.type === 'PDF') {
        v[field] = _.toUpper(v[field])
      }
    },
    changedMake(v, field) {
      v[field] = this.correctMake(v[field])
      this.upperCase(v, field)
    },
    upperCase (v, field) {
      v[field] = _.toUpper(v[field])
    },
    loadedParts (parts, details) {
      _.forEach(parts, (prt) => {
        prt.type = {
          aftm: false,
          certAftm: false,
          oemGen: true,
          oemPar: false,
          reco: false,
          used: false,
          oemAlt: false,
        }
      })
      this.rfq.QPNumber = details.QPNumber
      this.rfq.parts = parts
      this.isShowApplyPartNumbers = (this.rfq.type !== 'Manual' && _.filter(this.rfq.parts, (p) => {
        return !_.isEmpty(_.trim(p.number))
      }).length)
      this.isApplyPartsNbrs = false
      this.onChangeApplyPartsNbrs()
      this.rfq.estimator = this.gettersCurrentUserFullName
      this.rfq.details.vin = details.vin
      this.rfq.details.rego = details.rego
      this.rfq.details.make = this.correctMake(details.make)
      this.rfq.details.model = details.model
      this.rfq.details.body = details.body
      this.rfq.details.odometer = details.odometer
      this.rfq.details.claimNumber = details.claimNumber
      this.rfq.details.colour = details.colour
      this.rfq.details.dom = details.dom
      this.rfq.details.insurer = details.insurer
      this.$refs.RFQAddStepper.goNext()
      this.isLoading = false
    },
    checkManualType () {
      this.rfq = {
        id: null,
        type: 'Manual',
        QPNumber: '',
        number: '',
        estimator: this.gettersCurrentUserFullName,
        comments: '',
        setPinImage: null,
        setPinImageFileId: null,
        additionalRfqFor: null,
        details: {
          rego: '',
          comments: '',
          vin: '',
          make: '',
          model: '',
          body: '',
          modelNumber: '',
          series: '',
          dom: '',
          colour: '',
          transmission: 'Automatic',
          odometer: '',
          claimNumber: '',
          insurer: '',
        },
        parentQPNumber: null,
        parts: [],
        images: [],
        newImages: [],
        suppliers: [],
        suppliers2: {},
        datetime: { date: '', time: '' },
        directOrder: {
          deliveryDate: null,
          isMarkAsUrgent: null,
          stockLvls: [],
          isDeliverAsCompleteOrder: null,
          purchaseOrderNumber: '',
          comment: '',
        },
        directOrderParentQpNumber: '',
        isSendAsDirectOrder: this.$route && this.$route.params && this.$route.params.isSentAsDirectOrder ? this.$route.params.isSentAsDirectOrder : false,
      }
      this.receivedImages = []
    },
    selectReceivedRFQ (item) {
      console.log('selectReceivedRFQ', item)
      this.selectedReceivedRFQId = item.id
      this.loadRFQ(item.id)
    },
    loadRFQ: function (id) {
      this.getRFQById(id).then(response => {
        this.$refs.RFQAddStepper.goTo(1)
        this.selectedReceivedRFQId = id
        let loadRfq = _.cloneDeep(response.data.rfq)
        this.rfq.id = id
        this.rfq.number = loadRfq.number
        this.rfq.QPNumber = loadRfq.QPNumber
        this.rfq.parentQPNumber = loadRfq.parentQPNumber
        this.rfq.estimator = this.gettersCurrentUserFullName
        this.rfq.details = loadRfq.vehicle
        this.rfq.details.make = this.correctMake(loadRfq.vehicle.make)
        this.rfq.parts = loadRfq.parts
        _.forEach(this.rfq.parts, (prt) => {
          prt.type = {
            aftm: prt?.type?.aftm,
            certAftm: prt?.type?.certAftm,
            oemGen: prt?.type?.oemGen,
            oemPar: prt?.type?.oemPar,
            reco: prt?.type?.reco,
            used: prt?.type?.used,
            oemAlt: prt?.type?.oemAlt,
          }
        })
        this.isShowApplyPartNumbers = (this.rfq.type !== 'Manual' && _.filter(loadRfq.parts, (p) => {
          return !_.isEmpty(_.trim(p.number))
        }).length)
        this.isApplyPartsNbrs = false
        this.onChangeApplyPartsNbrs()
        this.rfq.additionalRfqFor = loadRfq.additionalRfqFor
        this.receivedImages = loadRfq.images
        this.rfq.directOrder = {
          deliveryDate: null,
          isMarkAsUrgent: null,
          stockLvls: [],
          isDeliverAsCompleteOrder: null,
          purchaseOrderNumber: '',
          comment: '',
        }
        this.rfq.directOrderParentQpNumber = ''
        this.rfq.isSendAsDirectOrder = this.$route && this.$route.params && this.$route.params.isSentAsDirectOrder ? this.$route.params.isSentAsDirectOrder : false
        if (loadRfq?.properties?.isSentAsDirectOrder) {
          this.rfq.isSendAsDirectOrder = true
          if (loadRfq?.parentQPNumber) {
            this.rfq.directOrderParentQpNumber = loadRfq.parentQPNumber
          }
        }
        if (this.isAddSuppliers) {
          this.rfq.parts = _.filter(this.rfq.parts, (p) => {
            return p.reportOn
          })
          _.forEach(this.rfq.parts, (prt) => {
            prt.type = {
              aftm: prt.isNeedsAFTM,
              certAftm: prt.isNeedsCERTAFTM,
              oemGen: prt.isNeedsOEMGEN,
              oemPar: prt.isNeedsOEMPAR,
              reco: prt.isNeedsRECO,
              used: prt.isNeedsUSED,
              oemAlt: prt.isNeedsOEMALT,
            }
          })
          let OEMSupp = null
          _.forIn(loadRfq.parts_to_suppliers, (supp) => {
            if (supp.types.includes('OEM-GEN')) {
              OEMSupp = supp
            }
          })
          if (OEMSupp) {
            this.rfq.suppliers.push(OEMSupp.supplierId)
          }
        }

      }).catch(error => {
        console.log(error)
        this.$toast.error('Error')
      })
    },
    isPrefferdSupplierDisabled: function (row, type = 'OEM-GEN') {
      let item = row.item ? row.item : row
      // console.log('item', item);
      // if (this.rfq.suppliers.includes(item.id)) {
      //   return false;
      // }

      if (type == 'OEM-GEN' && (item.types.includes('OEM-GEN')) && this.checkSelectOEMSupplier > 0 && this.checkSelectOEMSupplier != item.id) {
        return true
      } else if (item.type === 'OEM' || item.type === 'OEM/GEN') {
        let isHasTempOEMSupps = _.find(this.tempSupps, (s) => {
          return (s.type === 'OEM' || s.type === 'OEM/GEN') && s.isChecked === true
        })
        if (isHasTempOEMSupps) {
          return true
        }
      }

      // if ((item.type == 'OEM-GEN' || item.type == 'OEM') && this.computedLengthOfSelectedSupplierTypes['OEM-GEN'] == this.tier['OEM-GEN']) {
      //   return true
      // }
      // if (item.type == 'OEM-PAR' && this.computedLengthOfSelectedSupplierTypes['OEM-PAR'] == this.tier['OEM-PAR']) {
      //   return true
      // }
      // if ((item.type == 'AFTM/USED' || item.type == 'USED') && this.computedLengthOfSelectedSupplierTypes['USED'] == this.tier['USED']) {
      //   return true
      // }
      // if ((item.type == 'OEM-ALT/AFTM' || item.type == 'AFTM') && this.computedLengthOfSelectedSupplierTypes['AFTM'] == this.tier['AFTM']) {
      //   return true
      // }
      // if (item.type == 'RECO' && this.computedLengthOfSelectedSupplierTypes['RECO'] == this.tier['RECO']) {
      //   return true
      // }
      // if ((item.type == 'OEM-ALT/AFTM' || item.type == 'OEM-ALT') && this.computedLengthOfSelectedSupplierTypes['OEM-ALT'] == this.tier['OEM-ALT']) {
      //   return true
      // }
      return false
    },
    dateSelected (event) {
      return
      console.log('date', event.date.format('YYYY-MM-DD'), moment(moment().format('YYYY-MM-DD')).diff(moment(event.date.format('YYYY-MM-DD')), 'days'))

      if (moment(moment().format('YYYY-MM-DD')).diff(moment(event.date.format('YYYY-MM-DD')), 'days') < 0) {
        this.isSelectTimeActive = true
        this.selectedTime()
      } else {
        this.isSelectTimeActive = false
        if (!_.isEmpty(this.companyInfo) && this.companyInfo.address.state)
          this.initTime(this.companyInfo.address.state, this.companyInfo.address.country)
      }
    },
    selectedTime () {
      return
      setTimeout(() => {

        let co = Vue.prototype.$http.defaults.headers.common['CompanyTimeOffset']
        let lo = Vue.prototype.$http.defaults.headers.common['LocalTimeOffset']

        let currentCompanyTime = moment().subtract(lo, 'hours').add(co, 'hours')
        //console.log('moment1', moment())
        //console.log('moment2', moment().subtract(lo, 'hours'))
        //console.log('moment3', moment().subtract(lo, 'hours').add(co, 'hours'))
        //console.log('lo', lo)
        //console.log('co', co)

        if (!this.rfq.datetime.date) {
          this.rfq.datetime.date = currentCompanyTime.format('DD/MM/YYYY')
        }

        let selectedCompanyTime = moment(
            this.rfq.datetime.date
            + ' '
            + this.rfq.datetime.time,
            'DD/MM/YYYY hh:mm A'
        )

        let selectedTime = moment(this.rfq.datetime.time, 'hh:mmA')

        //console.log('selectedCompanyTime', selectedCompanyTime)
        //console.log('currentCompanyTime', currentCompanyTime)

        if (!currentCompanyTime.isBefore(selectedCompanyTime)) {
          this.selectTimeInvalid = true
        } else {
          this.selectTimeInvalid = false
        }
      }, 50)

    },
    checkStep: function (q, w, e) {
      // console.log(q);
      //  console.log(w);
      // console.log(e);
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    setCaretPosition: function (ctrl, start) {
      // IE >= 9 and other browsers
      if (ctrl.setSelectionRange) {
        ctrl.focus()
        ctrl.setSelectionRange(start, start)
      }
      // IE < 9
      else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange()
        range.collapse(true)
        range.moveEnd('character', start)
        range.moveStart('character', start)
        range.select()
      }
    },
    onPressArrowKey: function (index, event, el) {
      if (event && event.code) {
        let refName = 'rfq-add-parts-list-part-'
        if (event.code == 'Enter') {
          //addPart();
          this.addPart({ id: null, name: '' })
        }
        if (event.code == 'ArrowDown') {
          event.preventDefault()
          index = index + 1
          if (index >= this.rfq.parts.length) {
            index = 0
          }
          refName = refName + el + index
        } else if (event.code == 'ArrowUp') {
          event.preventDefault()
          index = index - 1
          if (index < 0) {
            index = this.rfq.parts.length - 1
          }
          refName = refName + el + index
        } else if (event.code == 'ArrowRight') {

          if (el != 'qty') {
            var elem = event.srcElement
            let pos = this.getCaretPosition(elem)
            if (pos < elem.value.length) {
              return false
            }
          } else {
            event.preventDefault()
          }
          if (el == 'name') {
            el = 'number'
          } else if (el == 'number') {
            el = 'qty'
          } else if (el == 'qty') {
            el = 'name'
          }
          refName = refName + el + index
        } else if (event.code == 'ArrowLeft') {
          if (el != 'qty') {
            var elem = event.srcElement
            let pos = this.getCaretPosition(elem)
            if (pos > 0) {
              return false
            }
          }
          if (el == 'name') {
            el = 'qty'
          } else if (el == 'number') {
            el = 'name'
          } else if (el == 'qty') {
            el = 'number'
          }
          refName = refName + el + index
        } else {
          // event.preventDefault();
          return
        }
        if (this.$refs[refName] && this.$refs[refName][0]) {
          this.$refs[refName][0].focus()
        }
        event.preventDefault()
      }
    },
    onItemMoveChange: function (q, w, e) {
      //   console.log(q);
      //   console.log(w);
      //   console.log(e);
    },
    changeQtyOfPartList: function (q, w, e) {
      //  console.log(q);
      //   console.log(w);
      //  console.log(e);
    },
    changePositionByArrowKey: function (ref, event) {
      let el = this.$refs[ref]
      if (el?.$el) {
        el.$el.focus()
      } else if (el) {
        el.focus()
      }
    },
    submitRFQ: function () {
      if (_.isEmpty(this.rfq.suppliers)) {
        return
      }
      this.create()
    },
    setSupplierAsPrefered: function (item, type, isFromList) {
      this.newSupplierElement = true

      if (_.find(this.gettersPreferedSuppliers, (s) => {return Number(item.id) === Number(s.id)}) || item.types.length > 1) {
        this.setSupplierAsTemporarilyPrefered(item, false, type, isFromList)
        this.$toast.success(item.name + ' was added to your preferred suppliers list')
      } else {
        if (this.changeSupplierGroup({ type: 'Prefered', item: item })) {
          this.$toast.success(item.name + ' was added to your preferred suppliers list')
        } else {
          this.$toast.error(item.name + ' could not be added. Check selection again')
        }
      }
    },
    setSupplierAsTemporarilyPrefered: function (item, isShowToast = true, type, isFromList = false) {
      this.newSupplierElement = true
      console.log(type, 'type')
      if (isFromList) {
        _.forEach(type, (t) => {
          if (t === 'OEM-GEN' && this.computedTypesOfCheckedParts.includes(t)) {
            this.rfq.suppliers2['OEM-GEN'] = [];
            this.rfq.suppliers = []
            for (let type in this.rfq.suppliers2) {
              _.forEach(this.rfq.suppliers2[type], (itm) => {
                this.rfq.suppliers.push(itm)
              })
            }
            this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
          }

          if (_.isEmpty(this.rfq.suppliers2[t])) {
            this.rfq.suppliers2[t] = []
          }
          if (!_.includes(this.rfq.suppliers2[t], item.id)  && this.computedTypesOfCheckedParts.includes(t)) {
            this.rfq.suppliers2[t].push(item.id)
          }
          if (this.computedTypesOfCheckedParts.includes(t)) {
            this.rfq.suppliers.push(item.id)
            this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
            this.temporarilyPreferedSuppliers.push({
              id: item.id,
              type: t,
            })
          }
        })
      } else {
        if (type === 'OEM-GEN') {
          this.rfq.suppliers2['OEM-GEN'] = [];
          this.rfq.suppliers = []
          for (let type in this.rfq.suppliers2) {
            _.forEach(this.rfq.suppliers2[type], (itm) => {
              this.rfq.suppliers.push(itm)
            })
          }
          this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
        }

        if (_.isEmpty(this.rfq.suppliers2[type])) {
          this.rfq.suppliers2[type] = []
        }
        if (!_.includes(this.rfq.suppliers2[type], item.id)) {
          this.rfq.suppliers2[type].push(item.id)
        }
        this.rfq.suppliers.push(item.id)
        this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
        this.temporarilyPreferedSuppliers.push({
          id: item.id,
          type: type,
        })
      }

      if (isShowToast) {
        this.$toast.success(item.name + ' was added to your temporary preferred suppliers list')
      }
    },
    isSupplierInTemporarilyPrefered: function (id, type, isFromList = false) {

      if (_.find(this.temporarilyPreferedSuppliers, (itm) => {
            if (isFromList) {
              return Number(itm.id) == Number(id)
            } else {
              return Number(itm.id) == Number(id) && itm.type == type
            }
          }) &&
          !_.find(this.gettersPreferedSuppliers, (s) => {return Number(id) === Number(s.id)})) {
        return true
      }

      return false
    },
    setSupplierAsOther: function (item, isFromList = false, type) {

      if (isFromList) {
        this.rfq.suppliers = _.filter(this.rfq.suppliers, (id) => {
          return Number(item.id) != Number(id)
        })
        for (let type in this.rfq.suppliers2) {
          this.rfq.suppliers2[type] = _.filter(this.rfq.suppliers2[type], (itm) => {
            return this.rfq.suppliers.includes(itm)
          })
        }
      } else {
        if (_.includes(this.rfq.suppliers2[type], item.id)) {
          this.rfq.suppliers2[type].splice(this.rfq.suppliers2[type].indexOf(item.id), 1)
          this.rfq.suppliers = []
          for (let type in this.rfq.suppliers2) {
            _.forEach(this.rfq.suppliers2[type], (itm) => {
              this.rfq.suppliers.push(itm)
            })
          }
          this.rfq.suppliers = _.uniqBy(this.rfq.suppliers)
        }
      }

      if (_.find(this.temporarilyPreferedSuppliers, (itm) => {
        if (isFromList) {
          return Number(itm.id) == Number(item.id)
        } else {
          return Number(itm.id) == Number(item.id) && itm.type == type
        }
      })) {

        let ids = [];
        _.forEach(this.temporarilyPreferedSuppliers, (i) => {
          if (isFromList) {
            if (Number(i.id) == Number(item.id)) {
              ids.push(i.id)
            }
          } else {
            if (Number(i.id) == Number(item.id) && i.type == type) {
              ids.push(i.id)
            }
          }
        })
        _.forEach(ids, (i) => {
          let index = _.findIndex(this.temporarilyPreferedSuppliers, function (itm) {
            if (isFromList) {
              return Number(i) == Number(itm.id)
            } else {
              return Number(i) == Number(itm.id) && itm.type == type
            }

          })
          if (index !== -1) {
            this.temporarilyPreferedSuppliers.splice(index, 1)
          }
        })

        // if (_.find(this.gettersPreferedSuppliers, (s) => {return Number(item.id) === Number(s.id)})) {
        //   this.changeSupplierGroup({ type: 'Other', item: item })
        // }
      } else {
        if (!isFromList) {

          _.forEach(item.types, (t) => {
            if (t !== type && !_.find(this.temporarilyPreferedSuppliers, (i) => { return Number(i.id) === Number(item.id)})) {
              this.temporarilyPreferedSuppliers.push({
                id: item.id,
                type: t,
              })
            }
          })
        }
        this.changeSupplierGroup({ type: 'Other', item: item })
      }
    },
    removePart: function (part, index) {
      this.rfq.parts.splice(index, 1)
    },

    addPart: function (part) {
      part.qty = 1
      part.number = ''
      part.type = {}
      // part.type.oem = true
      part.type.oemGen = true
      part.type.oemPar = false
      part.type.certAftm = false
      part.type.used = false
      part.type.oemAlt = false
      part.type.aftm = false
      part.type.reco = false
      this.rfq.parts.push(part)
    },
    clickSelectSupplierByState: function (state) {
      if (state != 0) {
        this.selectedState = state
      } else {
        this.selectedState = ''
      }
    },
    clickSelectSupplierByType: function (type) {
      if (type != 0) {
        if (type == 'OEM-GEN') {
          type = 'OEM-GEN'
        } else if (type == 'OEM-PAR') {
          type = 'OEM-PAR'
        } else if (type == 'OEM-ALT') {
          type = 'OEM-ALT'
        }
        this.selectedType = type
      } else {
        this.selectedType = ''
      }
    },
    checkColumnPart: function (type) {
      let self = this
      _.forEach(this.rfq.parts, function (item) {
        self.checkPartType(item.type, type)
      })
    },
    checkPartType: function (el, type) {
      if (type == 'OEM-GEN' || type == 'OEM') {
        el.oemGen = true
      } else if (type == 'OEM-PAR') {
        el.oemPar = true
      } else if (type == 'USED') {
        el.used = true
      } else if (type == 'AFTM') {
        el.aftm = true
      } else if (type == 'CERT-AFTM') {
        el.certAftm = true
      } else if (type == 'RECO') {
        el.reco = true
      } else if (type == 'OEM-ALT') {
        el.oemAlt = true
      }
    },
    unCheckColumnPart: function (type) {
      let self = this
      _.forEach(this.rfq.parts, function (item) {
        self.unCheckPartType(item.type, type)
      })
    },
    unCheckPartType: function (el, type) {
      if (type == 'OEM-GEN' || type == 'OEM') {
        el.oemGen = false
      } else if (type == 'OEM-PAR') {
        el.oemPar = false
      } else if (type == 'USED') {
        el.used = false
      } else if (type == 'AFTM') {
        el.aftm = false
      } else if (type == 'CERT-AFTM') {
        el.certAftm = false
      } else if (type == 'RECO') {
        el.reco = false
      } else if (type == 'OEM-ALT') {
        el.oemAlt = false
      }
    },
    clickCancel: function () {
      this.$router.push({ name: 'RepairerRFQs' })
    },
    sendImgUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    addImgUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    deleteImageById (id) {
      let data = {
        deleteImageIds: [id],
      }
      Axios.post('/ir/repairer/rfq/' + this.rfq.id, data).then((res) => {
        if (res?.data?.update?.deleteImages) {
          this.$toast.success('File has been deleted')
        } else {
          this.$toast.error('File hasn\'t been deleted')
        }
      })
          .catch(() => {
            this.$toast.error('File hasn\'t been deleted')
          })
    },
    deleteImage: function (index, img) {
      // console.log('deleteImage', index);
      //   console.log('deleteImage', img);
      let isSavedChanges = false
      if (this.rfq.type === 'Received') {
        if (this.images[index]) {
          this.deleteImageById(this.images[index])
          isSavedChanges = true
          this.images.splice(index, 1)
        }
      } else {
        this.images.splice(index, 1)
      }

      let i = _.findIndex(this.receivedImages, (image) => {
        return Number(image.id) === Number(img.id)
      })
      if (i !== -1) {
        if (!isSavedChanges) {
          this.deleteImageById(img.id)
        }

        this.receivedImages.splice(i, 1)
      }
      if (img && img.id) {
        this.imageStorage = _.reject(this.imageStorage, function (item) {
          // console.log('item',item)
          return item.upload.uuid == img.id
        })
      }
      //let correctItem = this.resultSortingImages[data.key].filter(_ => {
      //    return _.id === data.id;
      //});
      //let findIndex = this.images.indexOf(correctItem[0]);
      //this.images.splice(findIndex, 1);
    },
    addImage: function (img) {
      if (img.src == '' || img.src === undefined || img.src === null) {
        return
      } else {
        this.images.unshift(img)
      }
    },
    clickCancelDropzoneImages: function ($event) {
      $event.preventDefault()
      this.$refs.imgUpDrop.removeAllFiles(true)
      this.images = []
    },
    clickBrowse: function (id) {
      $(id).trigger('click')
    },
    completeImgUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    successImgUpload (file, response) {
      if (response.status) {
        this.images.push(response.fileId)
        this.imageStorage.push({
          id: response.fileId,
          isManual: true,
          ...file
        })

        if (this.rfq.type === 'Received') {
          let data = {
            imageIds: [response.fileId],
          }
          Axios.post('/ir/repairer/rfq/' + this.rfq.id, data)
              .then(() => {
              })
        }

      }
      var vm = this
      //console.log('file', file);
      setTimeout(function () {
        if (vm.$refs.imgUpDrop){
          vm.$refs.imgUpDrop.removeFile(file)
        }
      }, 1000)
    },
    getDataForAddSupps () {
      let result = {
        parts: [],
        // suppliers: [],
        suppliers2: this.rfq.suppliers2,
      }
      _.forEach(this.rfq.parts, (prt) => {
        if (prt.type.aftm || prt.type.certAftm  || prt.type.used || prt.type.oemAlt || prt.type.reco) {
          result.parts.push({
            id: prt.id,
            type: {
              aftm: prt.type.aftm,
              certAftm: prt.type.certAftm,
              used: prt.type.used,
              oemAlt: prt.type.oemAlt,
              reco: prt.type.reco,
            }
          })
        }
      })
      _.forEach(this.rfq.suppliers, (sId) => {
        result.suppliers.push(sId)
      })
      console.log('result', result)
      return result
    },
    create: function () {
      NProgress.start()
      this.rfq.newImages = this.images
      if (this.rfq.isSendAsDirectOrder) {
        this.rfq.directOrder.comment = this.computedComments
      }
      if (_.isEmpty(this.rfq.setPinImage)) {
        delete this.rfq.setPinImage
      }
      if (_.isEmpty(this.rfq.setPinImageFileId)) {
        delete this.rfq.setPinImageFileId
      }
      if (!this.isSendAsAdditional) {
        this.rfq.additionalRfqFor = null
      }

      if (this.isShowApplyPartNumbers) {
        this.rfq.isApplyPartsNbrs = this.isApplyPartsNbrs
      }

      let rfq = _.cloneDeep(this.rfq)

      rfq.parts = _.filter(rfq.parts, (prt) => {
        return prt.type.aftm || prt.type.used || prt.type.oemAlt || prt.type.reco || prt.type.oemGen || prt.type.oemPar || prt.type.certAftm
      })

      delete rfq.suppliers

      let tempSuppForSubmit = []
      _.forEach(this.tempSupps, (s) => {
        tempSuppForSubmit.push({
          businessName: s.name,
          supplierType: s.type,
          email: s.email,
        })
      })
      this.rfq.tempSuppliers = tempSuppForSubmit

      if (this.isAddSuppliers) {
        Axios.post(`/ir/repairer/rfq/${this.rfq.id}/add-suppliers`, this.getDataForAddSupps())
            .then(response => {
              NProgress.done()
              if (response.data._status) {
                this.updateRFQTable()
                this.$toast.success('The Quote has been updated')
                this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: this.rfq.id } })

                return
              }
            })
            .catch(error => {
              this.$toast.error('An error occurred in updating your Quote')
              NProgress.done()
            })
      } else {
        Axios.post(`/ir/repairer/rfq/add`, rfq)
            .then(response => {
              NProgress.done()
              if (response.data.status && response.data.id > 0) {
                this.updateRFQTable()
                this.$toast.success('New quote has been submitted - Ref ' + response.data.number)
                this.$router.push({ path: '/r/rfqs#submitted' })

              return
            }
          })
          .catch(error => {
            this.$toast.error('An error occurred in submitting your Request For Quote')
            NProgress.done()
          })}
    },
    initTime (state = '', country = '') {
      let address = [],
          searchString = '', //'Moscow RU',
          time = null

      if (_.isEmpty(state) || _.isEmpty(country)) {
        time = moment()
      } else {
        address = _.find(States.addressStates, item => {
          return item.key === state
        })
        searchString = address.value + ' ' + country //'Moscow RU',
        time = moment(this.getTimeByState('', searchString), 'DD-MM-YYYY HH:mm')
      }

      this.rfq.datetime.date = time.format('DD/MM/YYYY')
      this.currentTimeWithTimeZone = time
      this.currentTime = time.format('hh:mm A')
      this.rfq.datetime.time = null
      if (time.isAfter(moment('05:00 PM', 'h:mm A'))) {
        console.log('isSelectTimeActive - false', this.getUtcTimeZoneByState(searchString), 'time =>', time.format('h:mm A'))
        this.isSelectTimeActive = true
      } else {
        console.log('isSelectTimeActive - false', this.getUtcTimeZoneByState(searchString), 'time =>', time.format('h:mm A'))
        this.isSelectTimeActive = true
        time.add(2, 'hour')

        let next = this.timeOptions.map(function (s) {
          return moment(s, 'h:mm A')
        }).sort(function (m) {
          return m.valueOf()
        }).find(function (m) {
          return m.isAfter(time)
        })

        if (next) {
          console.log('Next time is', next.format('h:mm A'), 'which is', next.fromNow())
          this.selectTime = next.format('h:mm A')
        } else {
          console.log('No next event')
        }
      }
    },
    updateCurrentTime (state = '', country = '') {
      if (_.isEmpty(state) || _.isEmpty(country)) {
        this.currentTime = dayjs(new Date()).format('hh:mm A')
        return
      }
      let address = _.find(States.addressStates, item => {
            return item.key === state
          }),
          searchString = address.value + ' ' + country, //'Moscow RU',
          time = moment(this.getTimeByState('', searchString), 'DD-MM-YYYY HH:mm')
      this.currentTime = time.format('hh:mm A')
    },
    scrollTo () {
      if (this.isShowAllPrefferedSuppliers == false) {
        this.isShowAllPrefferedSuppliers = true
        setTimeout(function () {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 2)
      } else {
        this.isShowAllPrefferedSuppliers = false
        setTimeout(function () {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 2)
      }
      this.saveSelectedSupps()
    },
    saveSelectedSupps () {
      this.rfq.suppliers = _.filter(this.rfq.suppliers, (id) => {
        return _.find(this.computedPreferedSuppliers, (sup) => {
          return Number(sup.id) === Number(id)
        })
      })
      for (let type in this.rfq.suppliers2) {
        this.rfq.suppliers2[type] = _.filter(this.rfq.suppliers2[type], (itm) => {
          return this.rfq.suppliers.includes(itm)
        })
      }
    },
    getStockColor (v) {
      if (v === 'In Stock') {
        return '#2DCA73'
      } else if (v === 'EX Warehouse') {
        return '#F59345'
      } else {
        return 'red'
      }
    },
  },
  computed: {
    ...mapGetters({
      gettersPreferedSuppliers: 'repairer/supplier/getPreferedSuppliers',
      gettersOtherSuppliers: 'repairer/supplier/getOtherSuppliers',
      gettersFullSuppliers: 'repairer/supplier/getFullSuppliers',
      gettersCurrentUserFullName: 'currentUser/getCurrentUserFullName',
      gettersReceivedRFQs: 'repairer/rfq/getReceivedRFQs',
      companyInfo: 'currentCompany/getDetails',
      isHasActiveApiKey: 'currentUser/isHasActiveApiKey',
      getQPNumbers: 'repairer/rfq/getQPNumbers',
      getSettings: 'settings/getSettings',
      currentCompany: 'currentCompany/getDetails',
      getterSupplierBusinessTypes: 'getSupplierBusinessTypes',
      getTier1: 'repairer/supplier/getTier1',
      getInsurers: 'repairer/supplier/getInsurers',
    }),
    isCanInviteSupplier() {
      return !_.isEmpty(this.nonReturnableSupplier.bussinesName) && !_.isEmpty(this.nonReturnableSupplier.vehiclePartsType) && !_.isEmpty(this.nonReturnableSupplier.makes) && !_.isEmpty(this.nonReturnableSupplier.vehiclePartsType)
    },
    isShowAdvert() {
      let colour = this.rfq.details.colour;
      return colour === 'B74P' || colour === '46V' || colour === '41V' || colour === 'NH883P'
    },
    computedLengthOfSelectedSupplierTypes () {
      let r = {
        'OEM-GEN': 0,
        'OEM-PAR': 0,
        'OEM-ALT': 0,
        'CERT-AFTM': 0,
        'AFTM': 0,
        'USED': 0,
        'RECO': 0
      }
      _.forEach(this.rfq.suppliers, (s) => {
        let supplier = _.find(this.computedPreferedSuppliers, (i) => {
          return Number(i.id) === Number(s)
        })
        if (supplier.includes('OEM-GEN')) {
          r['OEM-GEN']++
        }
        if (supplier.includes('OEM-PAR')) {
          r['OEM-PAR']++
        }
        if (supplier.includes('USED')) {
          r['USED']++
        }
        if (supplier.includes('CERT-AFTM')) {
          r['CERT-AFTM']++
        }
        if (supplier.includes('AFTM')) {
          r['AFTM']++
        }
        if (supplier.includes('RECO')) {
          r['RECO']++
        }
        if (supplier.includes('OEM-ALT')) {
          r['OEM-ALT']++
        }
      })
      return r
    },
    isRfqTypeReceived () {
      return this.rfq.type == 'Received'
    },
    isCanSend () {
      return this.tempSupp.email && this.isCheckEmail && this.isCheckEmail2 && this.tempSupp.supplierType && this.tempSupp.businessName
    },
    isCanPinImages () {
      return this.getSettings?.rfq?.tableDisplayPinnedImages?.active ? true : false
    },
    insurerList () {
      let data = _.cloneDeep(this.getInsurers);
      return _.orderBy(data, [(itm) => itm.toLowerCase().trim()], ['asc'])
    },
    computedComments () {
      let result = `<div class="send-direct-order-comment-block"><div class="p-2">`
      if (this.rfq.isSendAsDirectOrder) {
        result += '<div class="pb-3"><b>DIRECT ORDER REQUEST</b></div>'
        result += `<div class="pb-1">
          <span style="font-weight: 600;">Pre-Approved: </span>
          <span style="font-weight: 400;">Order & Deliver</span>
        </div>`
      }
      if (this.rfq.directOrder.deliveryDate) {
        result += `<div class="pb-1">
        <span style="font-weight: 600;">Preferred delivery Date: </span>
        <span style="font-weight: 400;"> ${this.rfq.directOrder.deliveryDate}</span>
      </div>`
      }
      if (this.rfq.directOrder.isMarkAsUrgent !== null) {
        if (this.rfq.directOrder.isMarkAsUrgent) {
          result += `<div class="pb-1">
            <div style="font-weight: 600;">
              <span style="font-weight: 600;">Urgent: </span>
              <span style="font-weight: 400;">Yes</span>
            </div>
          </div>`
        } else {
          result += `<div class="pb-1">
            <div>
              <span style="font-weight: 600;">Urgent: </span>
              <span style="font-weight: 400;">No</span>
            </div>
          </div>`
        }
      }
      if (this.rfq.directOrder.stockLvls.length) {
        result += `<div class="pb-1 d-flex">
          <div>
            <span style="font-weight: 600;">Acceptable Stock Lvl: </span>
          </div>`

        _.forEach(this.rfq.directOrder.stockLvls, (lvl, ind) => {
          result += `<span style="font-weight: 700; color: ${this.getStockColor(lvl)}">&nbsp; ${lvl}`
          if (this.rfq.directOrder.stockLvls.length === 1) {
            result += `<span style="color: #1C1F39"> - Only</span>`
          } else if (ind < this.rfq.directOrder.stockLvls.length - 1) {
            result += `<span style="color: #1C1F39">&nbsp;|</span>`
          }
        })

        result += `</span></div>`
      }
      if (this.rfq.directOrder.isDeliverAsCompleteOrder !== null) {
        if (this.rfq.directOrder.isDeliverAsCompleteOrder) {
          result += `<div class="pb-1">
            <div>
              <span style="font-weight: 600;">Deliver as Complete Order: </span >
              <span style="font-weight: 400;">Yes</span>
            </div>
          </div>`
        } else {
          result += `<div v-if="selectDeliverCompleteOrder !== ''" class="pb-1">
            <div>
              <span style="font-weight: 600;">Deliver as Complete Order: </span >
              <span style="font-weight: 400;">No - (Partial Delivery Acceptable)</span>
            </div>
          </div>`
        }
      }

      if (this.rfq.directOrder.purchaseOrderNumber) {
        result += `<div>
        <span style="font-weight: 600;">Purchase Order Number: </span>
        <span style="font-weight: 400;">${this.rfq.directOrder.purchaseOrderNumber}</span>
      </div>`
      }

      if (!_.isEmpty(this.rfq.details.comments) && !this.rfq.askForPartsDiagram) {
        result += `<div style="margin-top: 5px">
        <span style="font-weight: 600;">${this.rfq.details.comments}</span>
      </div>`
      }

      if (this.rfq.askForPartsDiagram) {
        if (this.rfq.isSendAsDirectOrder) {
          result += '<div class="ask-for-parts-diagram-section"><div class="pb-3 pt-4"><b><b>PARTS DIAGRAM REQUEST</b></div>'
        } else {
          result += '<div class="ask-for-parts-diagram-section"><div class="pb-3"><b><b>PARTS DIAGRAM REQUEST</b></div>'
        }
        result += '<div class="pb-1"><span style="font-weight: 600;">Comment: </span></div>'
        result += `<div><span style="font-weight: 600;">${this.rfq.details.comments}</span> </div> </div>`
      }


      result += `</div></div>`

      return result
    },
    computedSelectedSuppliers () {
      let supps = []
      _.forEach(this.rfq.suppliers, (suppId) => {
        let supp = _.find(this.gettersFullSuppliers, (s) => {
          return Number(s.id) === Number(suppId)
        })
        if (supp) {
          supps.push(supp)
        }
      })
      return supps
    },

    computedMinTime () {
      let availableSupps = []
      let partLength = this.rfq.parts.length
      if (partLength === 0 || _.isEmpty(this.computedSelectedSuppliers)) {
        return 0
      }
      let result = null
      _.forEach(this.computedSelectedSuppliers, (supp) => {
        if (!_.isEmpty(supp.responseLevel)) {
          availableSupps.push(_.cloneDeep(supp))
        }
      })

      availableSupps = _.map(availableSupps, (s) => {
        let startValues = []
        let endValues = []
        s.responseLevel = _.orderBy(s.responseLevel, (itm) => {
          return Number(itm.numberLines)
        }, ['asc'])
        _.forEach(s.responseLevel, (l) => {
          if (partLength >= Number(l.numberLines)) {
            startValues.push(l)
          } else if (partLength < Number(l.numberLines)) {
            endValues.push(l)
          }
        })
        if (_.isEmpty(startValues) && _.isEmpty(endValues)) {
          s.minTime = 0
        } else if (_.isEmpty(startValues)) {
          s.minTime = 0
        } else if (_.isEmpty(endValues)) {
          s.minTime = Number(_.maxBy(startValues, (item) => {
            return Number(item.numberLines)
          }).minTime)
        } else {
          let end = _.maxBy(endValues, (item) => {
            return Number(item.numberLines)
          })
          let startValuesFiltered = _.filter(startValues, (item) => {
            return Number(item.numberLines) < Number(end.numberLines)
          })
          let start = _.maxBy(startValuesFiltered, (item) => {
            return Number(item.numberLines)
          })
          if (start) {
            s.minTime = Number(start.minTime)
          } else {
            s.minTime = 0
          }
        }
        return s
      })
      result = _.maxBy(availableSupps, (s) => {
        return Number(s.minTime)
      })
      if (!_.isEmpty(result)) {
        return Number(result.minTime)
      }
      return 0
    },

    selectTimeOptions () {
      let selectedDate = dayjs(this.rfq.datetime.date, 'DD/MM/YYYY')
      if (!selectedDate.isToday()) {
        return [
          '08:00 AM',
          '08:30 AM',
          '09:00 AM',
          '09:30 AM',
          '10:00 AM',
          '10:30 AM',
          '11:00 AM',
          '11:30 AM',
          '12:00 PM',
          '12:30 PM',
          '01:00 PM',
          '01:30 PM',
          '02:00 PM',
          '02:30 PM',
          '03:00 PM',
          '03:30 PM',
          '04:00 PM',
          '04:30 PM',
          '05:00 PM',
        ]
      } else {
        let now = dayjs()
        // let now = dayjs('2023-05-23 11:58')
        let timeStart = now.set('hour', 8).set('minute', 0).set('second', 0)
        let times = []
        let time = timeStart

        let minTime = this.computedMinTime ? this.computedMinTime : 30
        if (this.isAddSuppliers) {
          minTime = 30
        }
        console.log('minTime', minTime)

        for (let i = 0; i < 40; i++) {
          time = timeStart.add(i * 15, 'minute')
          let differenceInMinutes = ((time - now) / 1000) / 60
          if (time > now && differenceInMinutes >= minTime) {
            times.push(time.format('hh:mmA'))
          }
        }
        return times
      }

    },
    checkSelectOEMSupplier () {
      let ids = this.rfq.suppliers
      ids = _.filter(ids, (i) => {
        return _.includes(this.rfq.suppliers2?.['OEM-GEN'], i)
      })
      let result = _.filter(this.computedPreferedSuppliers, function (i) {
        return i.types.includes('OEM-GEN') && (_.indexOf(ids, i.id) >= 0)
      })
      if (result && result[0]) {
        return result[0].id
      }
      return 0
    },
    isMobile () {
      return isMobile
    },
    imageSource: function () {
      let arr = []
      _.forEach(this.receivedImages, function (i) {
        arr.push({ previewH240: i.previewH240, src: i.src, url: i.url, id: i.id })
      })
      _.forEach(this.imageStorage, function (i) {
        arr.push({ previewH240: i.dataURL, src: i.dataURL, url: i.dataURL, id: i.id })
      })

      return arr
    },
    computedTypesOfCheckedParts () {
      let result = []
      if (_.some(this.rfq.parts, function (item) {
        return item.type.oemGen == true
      })) {
        result.push('OEM-GEN')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.oemPar == true
      })) {
        result.push('OEM-PAR')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.oemAlt == true
      })) {
        result.push('OEM-ALT')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.aftm == true
      })) {
        result.push('AFTM')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.certAftm == true
      })) {
        result.push('CERT-AFTM')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.used == true
      })) {
        result.push('USED')
      }

      if (_.some(this.rfq.parts, function (item) {
        return item.type.reco == true
      })) {
        result.push('RECO')
      }

      return result
    },
    computedTypesListForPreferedSuppliers: function () {
      let result = []
      if (_.some(this.rfq.parts, function (item) {
        return item.type.oemGen == true
      })) {
        result.push('OEM-GEN')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.oemPar == true
      })) {
        result.push('OEM-PAR')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.oemAlt == true
      })) {
        result.push('OEM-ALT')
        result.push('OEM-ALT/AFTM')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.aftm == true
      })) {
        result.push('AFTM')
        result.push('OEM-ALT/AFTM')
        result.push('AFTM/USED')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.certAftm == true
      })) {
        result.push('CERT-AFTM')
      }
      if (_.some(this.rfq.parts, function (item) {
        return item.type.used == true
      })) {
        result.push('USED')
        result.push('AFTM/USED')
      }

      if (_.some(this.rfq.parts, function (item) {
        return item.type.reco == true
      })) {
        result.push('RECO')
      }

      return result
    },
    computedPreferedSuppliers: function () {
      let self = this
      let list = []
      list = this.gettersPreferedSuppliers
      list = _.filter(list, function (i) {
        return _.some(i.types, (t) => { return _.includes(self.computedTypesListForPreferedSuppliers, t)});
      })
      if (self.temporarilyPreferedSuppliers.length > 0) {
        list = _.concat(
            list,
            _.filter(self.gettersFullSuppliers, function (i) {
              return _.find(self.temporarilyPreferedSuppliers, (itm) => {
                return Number(itm.id) == Number(i.id)
              })
            }))
      }
      if (self.rfq && self.rfq.details && self.rfq.details.make && self.rfq.details.make != '' && !self.isShowAllPrefferedSuppliers) {
        list = _.filter(list, function (i) {
          return _.includes(
              _.map(i.brands, function (n) {
                return _.toUpper(n)
              }),
              _.toUpper(self.rfq.details.make)
          ) || self.nonRegisteredIds.includes(i.id)
        })
      }

      if (!_.isEmpty(this.tierSuppliers) && !this.isShowAllPrefferedSuppliers && this.isUseTier) {
        list = _.filter(this.gettersPreferedSuppliers, (s) => {
          return (Number(s.id) === Number(this.tierSuppliers.oemGen) && this.computedTypesOfCheckedParts.includes('OEM-GEN')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemPar1) && this.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemPar2) && this.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemPar3) && this.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemPar4) && this.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemAlt1) && this.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemAlt2) && this.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemAlt3) && this.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(this.tierSuppliers.oemAlt4) && this.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(this.tierSuppliers.certAftm1) && this.computedTypesOfCheckedParts.includes('CERT-AFTM')) ||
              (Number(s.id) === Number(this.tierSuppliers.certAftm2) && this.computedTypesOfCheckedParts.includes('CERT-AFTM')) ||
              (Number(s.id) === Number(this.tierSuppliers.aftm1) && this.computedTypesOfCheckedParts.includes('AFTM')) ||
              (Number(s.id) === Number(this.tierSuppliers.aftm2) && this.computedTypesOfCheckedParts.includes('AFTM')) ||
              (Number(s.id) === Number(this.tierSuppliers.used1) && this.computedTypesOfCheckedParts.includes('USED')) ||
              (Number(s.id) === Number(this.tierSuppliers.used2) && this.computedTypesOfCheckedParts.includes('USED')) ||
              (Number(s.id) === Number(this.tierSuppliers.used3) && this.computedTypesOfCheckedParts.includes('USED')) ||
              (Number(s.id) === Number(this.tierSuppliers.used4) && this.computedTypesOfCheckedParts.includes('USED')) ||
              self.nonRegisteredIds.includes(s.id)
        })
        if (self.temporarilyPreferedSuppliers.length > 0) {
          list = _.concat(
              list,
              _.filter(self.gettersFullSuppliers, function (i) {
                return _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
              })
          )
        }
        list = _.filter(list, function (i) {
          return _.some(i.types, (t) => { return _.includes(self.computedTypesListForPreferedSuppliers, t)});
        })
      }

      let listOEMGEN = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-GEN'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('OEM-GEN')
                  }),
              'name'
          ) : []
      let listOEMPAR = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-PAR'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('OEM-PAR')
                  }),
              'name'
          ) : []
      let listAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'AFTM'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('AFTM')
                  }),
              'name'
          ) : []
      let listCERTAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'CERT-AFTM'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('CERT-AFTM')
                  }),
              'name'
          ) : []
      let listUSED = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'USED'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('USED')
                  }),
              'name'
          ) : []
      let listRECO = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'RECO'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('RECO')
                  }),
              'name'
          ) : []
      let listOEMALT = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-ALT'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('OEM-ALT')
                  }),
              'name'
          ) : []

      return _.uniqBy(_.concat(listOEMGEN, listOEMPAR, listCERTAFTM, listAFTM, listUSED, listRECO, listOEMALT), 'id')
    },
    computedHasSomeOEMALT () {
      return _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-ALT'})
    },
    computedPreferedSuppliersByTypes: function () {
      this.callComputed++
      let self = this
      let list = []
      list = this.gettersPreferedSuppliers
      list = _.filter(list, function (i) {
        return _.some(i.types, (t) => { return _.includes(self.computedTypesListForPreferedSuppliers, t)});
      })
      if (self.temporarilyPreferedSuppliers.length > 0) {
        list = _.concat(
            list,
            _.filter(self.gettersFullSuppliers, function (i) {
              return _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
            })
        )
      }
      if (self.rfq && self.rfq.details && self.rfq.details.make && self.rfq.details.make != '' && !self.isShowAllPrefferedSuppliers) {
        list = _.filter(list, function (i) {
          return _.includes(
              _.map(i.brands, function (n) {
                return _.toUpper(n)
              }),
              _.toUpper(self.rfq.details.make)
          ) || self.nonRegisteredIds.includes(i.id)
        })
      }

      list = _.filter(list, (itm) => {
        return _.includes(_.trim(_.toLower(itm.name)), _.toLower(this.preferedSuppliersSearch)) || self.nonRegisteredIds.includes(itm.id)
      })

      if (!_.isEmpty(this.tierSuppliers) && !this.isShowAllPrefferedSuppliers && this.isUseTier) {
        list = _.filter(this.gettersPreferedSuppliers, (s) => {
          return Number(s.id) === Number(this.tierSuppliers.oemGen) ||
              Number(s.id) === Number(this.tierSuppliers.oemPar1) ||
              Number(s.id) === Number(this.tierSuppliers.oemPar2) ||
              Number(s.id) === Number(this.tierSuppliers.oemPar3) ||
              Number(s.id) === Number(this.tierSuppliers.oemPar4) ||
              Number(s.id) === Number(this.tierSuppliers.oemAlt1) ||
              Number(s.id) === Number(this.tierSuppliers.oemAlt2) ||
              Number(s.id) === Number(this.tierSuppliers.oemAlt3) ||
              Number(s.id) === Number(this.tierSuppliers.oemAlt4) ||
              Number(s.id) === Number(this.tierSuppliers.aftm1) ||
              Number(s.id) === Number(this.tierSuppliers.aftm2) ||
              Number(s.id) === Number(this.tierSuppliers.certAftm1) ||
              Number(s.id) === Number(this.tierSuppliers.certAftm2) ||
              Number(s.id) === Number(this.tierSuppliers.used1) ||
              Number(s.id) === Number(this.tierSuppliers.used2) ||
              Number(s.id) === Number(this.tierSuppliers.used3) ||
              Number(s.id) === Number(this.tierSuppliers.used4) ||
              self.nonRegisteredIds.includes(s.id)
        })
        list = _.filter(list, function (i) {
          return _.some(i.types, (t) => { return _.includes(self.computedTypesListForPreferedSuppliers, t)});
        })

        if (self.temporarilyPreferedSuppliers.length > 0) {
          list = _.concat(
              list,
              _.filter(self.gettersFullSuppliers, function (i) {
                return _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
              })
          )
        }
      }

      let listOEMGEN = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-GEN'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
                      let b = true

                      let temp = _.filter(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                      if (temp && temp.length == 1 && temp[0].type !== 'OEM-GEN' && !_.find(self.gettersPreferedSuppliers, (prS) => {return Number(prS.id) == Number(i.id)})) {
                        return false
                      }
                      if (!_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)}) &&
                          !(Number(self.tierSuppliers.oemGen) === Number(i.id) || self.nonRegisteredIds.includes(i.id))) {
                        return false
                      }

                      return i.types.includes('OEM-GEN') && b
                    }
                    return i.types.includes('OEM-GEN')
                  }),
              'name'
          ) : []
      let listOEMPAR = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-PAR'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
                      let b = true

                      let temp = _.filter(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                      if (temp && temp.length == 1 && temp[0].type !== 'OEM-PAR' && !_.find(self.gettersPreferedSuppliers, (prS) => {return Number(prS.id) == Number(i.id)})) {
                        return false
                      }
                      if (!_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)}) &&
                          !(Number(self.tierSuppliers.oemPar1) === Number(i.id) || Number(self.tierSuppliers.oemPar2) === Number(i.id) ||
                              Number(self.tierSuppliers.oemPar3) === Number(i.id) || Number(self.tierSuppliers.oemPar4) === Number(i.id) || self.nonRegisteredIds.includes(i.id))) {
                        return false
                      }

                      return i.types.includes('OEM-PAR') && b
                    }
                    return i.types.includes('OEM-PAR')
                  }),
              'name'
          ) : []
      let listOEMALT = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-ALT'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
                      let b = true

                      let temp = _.filter(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                      if (temp && temp.length == 1 && temp[0].type !== 'OEM-ALT' && !_.find(self.gettersPreferedSuppliers, (prS) => {return Number(prS.id) == Number(i.id)})) {
                        return false
                      }
                      if (!_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)}) &&
                          !(Number(self.tierSuppliers.oemAlt1) === Number(i.id) || Number(self.tierSuppliers.oemAlt2) === Number(i.id) ||
                              Number(self.tierSuppliers.oemAlt3) === Number(i.id) || Number(self.tierSuppliers.oemAlt4) === Number(i.id) || self.nonRegisteredIds.includes(i.id))) {
                        return false
                      }

                      return i.types.includes('OEM-ALT') && b
                    }
                    return i.types.includes('OEM-ALT')
                  }),
              'name'
          ) : []
      let listAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'AFTM'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
                      let b = true
                      let temp = _.filter(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                      if (temp && temp.length == 1 && temp[0].type !== 'AFTM' && !_.find(self.gettersPreferedSuppliers, (prS) => {return Number(prS.id) == Number(i.id)})) {
                        return false
                      }
                      if (!_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)}) &&
                          !(Number(self.tierSuppliers.aftm1) === Number(i.id) || Number(self.tierSuppliers.aftm2) === Number(i.id) || self.nonRegisteredIds.includes(i.id))) {
                        return false
                      }
                      return i.types.includes('AFTM') && b
                    }
                    return i.types.includes('AFTM')
                  }),
              'name'
          ) : []
        let listCERTAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'CERT-AFTM'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
                      let b = true
                      let temp = _.filter(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                      if (temp && temp.length == 1 && temp[0].type !== 'CERT-AFTM' && !_.find(self.gettersPreferedSuppliers, (prS) => {return Number(prS.id) == Number(i.id)})) {
                        return false
                      }
                      if (!_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)}) &&
                          !(Number(self.tierSuppliers.certAftm1) === Number(i.id) || Number(self.tierSuppliers.certAftm2) === Number(i.id) || self.nonRegisteredIds.includes(i.id))) {
                        return false
                      }
                      return i.types.includes('CERT-AFTM') && b
                    }
                    return i.types.includes('CERT-AFTM')
                  }),
              'name'
          ) : []
      let listUSED = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'USED'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
                      let b = true

                      let temp = _.filter(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                      if (temp && temp.length == 1 && temp[0].type !== 'USED' && !_.find(self.gettersPreferedSuppliers, (prS) => {return Number(prS.id) == Number(i.id)})) {
                        return false
                      }
                      if (!_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)}) &&
                          !(Number(self.tierSuppliers.used1) === Number(i.id) || Number(self.tierSuppliers.used2) === Number(i.id) ||
                              Number(self.tierSuppliers.used3) === Number(i.id) || Number(self.tierSuppliers.used4) === Number(i.id) || self.nonRegisteredIds.includes(i.id))) {
                        return false
                      }

                      if (_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type.includes('USED')}))

                        return (i.types.includes('USED')) && b
                    }
                    return i.types.includes('USED')
                  }),
              'name'
          ) : []
      let listRECO = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'RECO'}) ?
          _.sortBy(
              _.filter(list,
                  function (i) {
                    return i.types.includes('RECO')
                  }),
              'name'
          ) : []

      listOEMGEN = _.filter(listOEMGEN, (itm) => {
        if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
          if (
              itm.id === self.tierSuppliers.oemGen ||
              self.nonRegisteredIds.includes(itm.id)
          ) {
            return true
          }
        }
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'OEM-GEN'})
        }
        return true
      })
      listOEMPAR = _.filter(listOEMPAR, (itm) => {
        if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
          if (
              itm.id === self.tierSuppliers.oemPar1 ||
              itm.id === self.tierSuppliers.oemPar2 ||
              itm.id === self.tierSuppliers.oemPar3 ||
              itm.id === self.tierSuppliers.oemPar4 ||
              self.nonRegisteredIds.includes(itm.id)
          ) {
            return true
          }
        }
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'OEM-PAR'})
        }
        return true
      })
      listOEMALT = _.filter(listOEMALT, (itm) => {
        if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
          if (
              itm.id === self.tierSuppliers.oemAlt1 ||
              itm.id === self.tierSuppliers.oemAlt2 ||
              itm.id === self.tierSuppliers.oemAlt3 ||
              itm.id === self.tierSuppliers.oemAlt4 ||
              self.nonRegisteredIds.includes(itm.id)
          ) {
            return true
          }
        }
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'OEM-ALT'})
        }
        return true
      })
      listAFTM = _.filter(listAFTM, (itm) => {
        if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
          if (
              itm.id === self.tierSuppliers.aftm1 ||
              itm.id === self.tierSuppliers.aftm2 ||
              self.nonRegisteredIds.includes(itm.id)
          ) {
            return true
          }
        }
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'AFTM'})
        }
        return true
      })
      listCERTAFTM = _.filter(listCERTAFTM, (itm) => {
        if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
          if (
              itm.id === self.tierSuppliers.certAftm1 ||
              itm.id === self.tierSuppliers.certAftm2 ||
              self.nonRegisteredIds.includes(itm.id)
          ) {
            return true
          }
        }
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'CERT-AFTM'})
        }
        return true
      })
      listUSED = _.filter(listUSED, (itm) => {
        if (self.tierSuppliers && !self.isShowAllPrefferedSuppliers && self.isUseTier) {
          if (
              itm.id === self.tierSuppliers.used1 ||
              itm.id === self.tierSuppliers.used2 ||
              itm.id === self.tierSuppliers.used3 ||
              itm.id === self.tierSuppliers.used4 ||
              self.nonRegisteredIds.includes(itm.id)
          ) {
            return true
          }
        }
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'USED'})
        }
        return true
      })
      listRECO = _.filter(listRECO, (itm) => {
        let tempSupp = _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id)})
        if (tempSupp) {
          return _.find(this.temporarilyPreferedSuppliers, (i) => {return Number(i.id) == Number(itm.id) && i.type === 'RECO'})
        }
        return true
      })

      for (let type in this.rfq.suppliers2) {
        _.forEach(this.rfq.suppliers2[type], (id) => {
          let supp = _.find(this.gettersFullSuppliers, (s) => {
            return Number(s.id) == Number(id)
          })
          if (type === 'OEM-GEN') {
            listOEMGEN.push(supp)
          } else if (type === 'OEM-PAR') {
            listOEMPAR.push(supp)
          } else if (type === 'OEM-ALT') {
            listOEMALT.push(supp)
          } else if (type === 'CERT-AFTM') {
            listCERTAFTM.push(supp)
          } else if (type === 'AFTM') {
            listAFTM.push(supp)
          } else if (type === 'USED') {
            listUSED.push(supp)
          } else if (type === 'RECO') {
            listRECO.push(supp)
          }
        })
      }

      return {
        'OEM-GEN': _.uniqBy(listOEMGEN, 'id'),
        'OEM-PAR': _.uniqBy(listOEMPAR, 'id'),
        'OEM-ALT': _.uniqBy(listOEMALT, 'id'),
        'CERT-AFTM': _.uniqBy(listCERTAFTM, 'id'),
        'AFTM': _.uniqBy(listAFTM, 'id'),
        'USED': _.uniqBy(listUSED, 'id'),
        'RECO': _.uniqBy(listRECO, 'id'),
      }

    },

    computedOtherSuppliersByTypes: function () {
      this.callComputed2++
      let self = this
      let list = self.gettersOtherSuppliers

      if (!_.isEmpty(self.tierSuppliers) && !self.isShowAllPrefferedSuppliers && !self.isAddSuppliers && self.isUseTier) {
        _.forEach(self.gettersPreferedSuppliers, (s) => {
          let isInTier = false
          if (Number(s.id) === Number(self.tierSuppliers.oemGen) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar1) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar2) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar3) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar4) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt1) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt2) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt3) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt4) ||
              Number(s.id) === Number(self.tierSuppliers.aftm1) ||
              Number(s.id) === Number(self.tierSuppliers.aftm2) ||
              Number(s.id) === Number(self.tierSuppliers.certAftm1) ||
              Number(s.id) === Number(self.tierSuppliers.certAftm2) ||
              Number(s.id) === Number(self.tierSuppliers.used1) ||
              Number(s.id) === Number(self.tierSuppliers.used2) ||
              Number(s.id) === Number(self.tierSuppliers.used3) ||
              Number(s.id) === Number(self.tierSuppliers.used4)) {
            isInTier = true
          }

          if (_.includes(_.map(s.brands, function (n) {return _.toUpper(n)}), _.toUpper(self.rfq.details.make)) &&
              !isInTier) {
            list.push(s)
          }
        })
      }

      // if (self.temporarilyPreferedSuppliers.length != 0) {
      //
      //   list = _.filter(list, function (i) {
      //     return !_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
      //   })
      // }
      if (self.selectedState != '') {
        list = _.filter(list, function (i) {
          return i.state == self.selectedState
        })
      }
      if (self.selectedType != '' && !this.isActiveGridView) {
        list = _.filter(list, function (itm) {
          return itm.types.includes(self.selectedType)
        })
      }
      if (self.rfq.details.make != '') {
        list = _.filter(list, function (i) {
          return _.includes(
              _.map(i.brands, function (n) {
                return _.toUpper(n)
              }),
              _.toUpper(self.rfq.details.make)
          )
        })
      }

      list = _.sortBy(list, 'name')

      list = _.filter(list, (itm) => {
        return _.includes(_.trim(_.toLower(itm.name)), _.toLower(this.otherSuppliersSearch))
      })

      let listOEMGEN = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-GEN'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
                }
                return i.types.includes('OEM-GEN') && !b
              }),
          'name'
      ):[]
      let listOEMPAR = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-PAR'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type === 'OEM-PAR'})
                }
                return i.types.includes('OEM-PAR') && !b
              }),
          'name'
      ):[]
      let listAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'AFTM'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type === 'AFTM'})
                }
                return (i.types.includes('AFTM')) && !b
              }),
          'name'
      ):[]
      let listCERTAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'CERT-AFTM'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type === 'CERT-AFTM'})
                }
                return (i.types.includes('CERT-AFTM')) && !b
              }),
          'name'
      ):[]
      let listUSED = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'USED'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type === 'USED'})
                }
                return (i.types.includes('USED')) && !b
              }),
          'name'
      ):[]
      let listRECO = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'RECO'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type === 'RECO'})
                }
                return i.types.includes('RECO') && !b
              }),
          'name'
      ):[]
      let listOEMALT = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-ALT'}) ?
          _.sortBy(
          _.filter(list,
              function (i) {
                let b = false
                if (self.temporarilyPreferedSuppliers.length != 0) {
                  b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id) && itm.type === 'OEM-ALT'})
                }
                return (i.types.includes('OEM-ALT')) && !b
              }),
          'name'
      ):[]

      if (!_.isEmpty(self.tierSuppliers) && !self.isShowAllPrefferedSuppliers && !self.isAddSuppliers && self.isUseTier) {
        _.forEach(self.gettersPreferedSuppliers, (s) => {
          let isInTier = false
          if (Number(s.id) === Number(self.tierSuppliers.oemGen) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar1) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar2) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar3) ||
              Number(s.id) === Number(self.tierSuppliers.oemPar4) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt1) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt2) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt3) ||
              Number(s.id) === Number(self.tierSuppliers.oemAlt4) ||
              Number(s.id) === Number(self.tierSuppliers.certAftm1) ||
              Number(s.id) === Number(self.tierSuppliers.certAftm2) ||
              Number(s.id) === Number(self.tierSuppliers.aftm1) ||
              Number(s.id) === Number(self.tierSuppliers.aftm2) ||
              Number(s.id) === Number(self.tierSuppliers.used1) ||
              Number(s.id) === Number(self.tierSuppliers.used2) ||
              Number(s.id) === Number(self.tierSuppliers.used3) ||
              Number(s.id) === Number(self.tierSuppliers.used4)) {
            isInTier = true
          }
          if (_.includes(_.map(s.brands, function (n) {return _.toUpper(n)}), _.toUpper(self.rfq.details.make)) && isInTier) {

            _.forEach(s.types, (t) => {
              if ((t === 'OEM-GEN' && !(Number(this.tierSuppliers.oemGen) === Number(s.id)))) {
                  if (_.includes(_.trim(_.toLower(s.name)), _.toLower(this.otherSuppliersSearch))) {
                  let b = false
                  if (self.temporarilyPreferedSuppliers.length != 0) {
                    b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(s.id) && itm.type === 'OEM-GEN'})
                  }
                  if (!b) {
                    if (self.selectedState != '') {
                      if (s.state == self.selectedState) {
                        listOEMGEN.push(s)
                      }
                    } else {
                      listOEMGEN.push(s)
                    }
                  }
                }
              } else if ((t === 'OEM-PAR' && !(Number(this.tierSuppliers.oemPar1) === Number(s.id) || Number(this.tierSuppliers.oemPar2) === Number(s.id) || Number(this.tierSuppliers.oemPar3) === Number(s.id) || Number(this.tierSuppliers.oemPar4) === Number(s.id)))) {
                  if (_.includes(_.trim(_.toLower(s.name)), _.toLower(this.otherSuppliersSearch))) {
                  let b = false
                  if (self.temporarilyPreferedSuppliers.length != 0) {
                    b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(s.id) && itm.type === 'OEM-PAR'})
                  }
                  if (!b) {
                    if (self.selectedState != '') {
                      if (s.state == self.selectedState) {
                        listOEMPAR.push(s)
                      }
                    } else {
                      listOEMPAR.push(s)
                    }
                  }
                }
              } else if (t === 'OEM-ALT' && !(Number(this.tierSuppliers.oemAlt1) === Number(s.id) || Number(this.tierSuppliers.oemAlt2) === Number(s.id) || Number(this.tierSuppliers.oemAlt3) === Number(s.id) || Number(this.tierSuppliers.oemAlt4) === Number(s.id))) {
                  if (_.includes(_.trim(_.toLower(s.name)), _.toLower(this.otherSuppliersSearch))) {
                  let b = false
                  if (self.temporarilyPreferedSuppliers.length != 0) {
                    b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(s.id) && itm.type === 'OEM-ALT'})
                  }
                  if (!b) {
                    if (self.selectedState != '') {
                      if (s.state == self.selectedState) {
                        listOEMALT.push(s)
                      }
                    } else {
                      listOEMALT.push(s)
                    }
                  }
                }
              } else if (t === 'AFTM' && !(Number(this.tierSuppliers.aftm1) === Number(s.id) || Number(this.tierSuppliers.aftm2) === Number(s.id))) {
                  if (_.includes(_.trim(_.toLower(s.name)), _.toLower(this.otherSuppliersSearch))) {
                  let b = false
                  if (self.temporarilyPreferedSuppliers.length != 0) {
                    b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(s.id) && itm.type === 'AFTM'})
                  }
                  if (!b) {
                    if (self.selectedState != '') {
                      if (s.state == self.selectedState) {
                        listAFTM.push(s)
                      }
                    } else {
                      listAFTM.push(s)
                    }
                  }
                }
              } else if (t === 'CERT-AFTM' && !(Number(this.tierSuppliers.certAftm1) === Number(s.id) || Number(this.tierSuppliers.certAftm2) === Number(s.id))) {
                  if (_.includes(_.trim(_.toLower(s.name)), _.toLower(this.otherSuppliersSearch))) {
                  let b = false
                  if (self.temporarilyPreferedSuppliers.length != 0) {
                    b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(s.id) && itm.type === 'CERT-AFTM'})
                  }
                  if (!b) {
                    if (self.selectedState != '') {
                      if (s.state == self.selectedState) {
                        listCERTAFTM.push(s)
                      }
                    } else {
                      listCERTAFTM.push(s)
                    }
                  }
                }
              } else if  (t === 'USED' && !(Number(this.tierSuppliers.used1) === Number(s.id) || Number(this.tierSuppliers.used2) === Number(s.id) || Number(this.tierSuppliers.used3) === Number(s.id) || Number(this.tierSuppliers.used4) === Number(s.id))) {
                  if (_.includes(_.trim(_.toLower(s.name)), _.toLower(this.otherSuppliersSearch))) {
                  let b = false
                  if (self.temporarilyPreferedSuppliers.length != 0) {
                    b = _.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(s.id) && itm.type === 'USED'})
                  }
                  if (!b) {
                    if (self.selectedState != '') {
                      if (s.state == self.selectedState) {
                        listUSED.push(s)
                      }
                    } else {
                      listUSED.push(s)
                    }
                  }
                }
              }
            })
          }
        })

        for (let type in this.rfq.suppliers2) {
          _.forEach(this.rfq.suppliers2[type], (id) => {
            if (type === 'OEM-GEN') {
              listOEMGEN = _.filter(listOEMGEN, itm => {
                return itm.id != id
              })
            } else if (type === 'OEM-PAR') {
              listOEMPAR = _.filter(listOEMPAR, itm => {
                return itm.id != id
              })
            } else if (type === 'OEM-ALT') {
              listOEMALT = _.filter(listOEMALT, itm => {
                return itm.id != id
              })
            } else if (type === 'CERT-AFTM') {
              listCERTAFTM = _.filter(listCERTAFTM, itm => {
                return itm.id != id
              })
            } else if (type === 'AFTM') {
              listAFTM = _.filter(listAFTM, itm => {
                return itm.id != id
              })
            } else if (type === 'USED') {
              listUSED = _.filter(listUSED, itm => {
                return itm.id != id
              })
            } else if (type === 'RECO') {
              listRECO = _.filter(listRECO, itm => {
                return itm.id != id
              })
            }
          })
        }

        listAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'AFTM'}) ? _.sortBy(listAFTM, 'name') : [];
        listUSED = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'USED'}) ? _.sortBy(listUSED, 'name') : [];
        listOEMALT = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-ALT'}) ? _.sortBy(listOEMALT, 'name') : [];
        listOEMPAR = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-PAR'}) ? _.sortBy(listOEMPAR, 'name') : [];
        listOEMGEN = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'OEM-GEN'}) ? _.sortBy(listOEMGEN, 'name') : [];
        listCERTAFTM = _.some(this.computedTypesOfCheckedParts, (i) => { return i == 'CERT-AFTM'}) ? _.sortBy(listCERTAFTM, 'name') : [];
      }

      return {
        'OEM-GEN': _.uniqBy(_.orderBy(listOEMGEN,['isPrefered'], ['desc']), 'id'),
        'OEM-PAR': _.uniqBy(_.orderBy(listOEMPAR,['isPrefered'], ['desc']), 'id'),
        'OEM-ALT': _.uniqBy(_.orderBy(listOEMALT,['isPrefered'], ['desc']), 'id'),
        'CERT-AFTM': _.uniqBy(_.orderBy(listCERTAFTM,['isPrefered'], ['desc']), 'id'),
        'AFTM': _.uniqBy(_.orderBy(listAFTM,['isPrefered'], ['desc']), 'id'),
        'USED': _.uniqBy(_.orderBy(listUSED,['isPrefered'], ['desc']), 'id'),
        'RECO': _.uniqBy(_.orderBy(listRECO,['isPrefered'], ['desc']), 'id'),
      }
    },
    computedOtherSuppliers: function () {
      let self = this
      let list = self.gettersOtherSuppliers

      if (!_.isEmpty(self.tierSuppliers) && !self.isShowAllPrefferedSuppliers && !self.isAddSuppliers && self.isUseTier) {
        _.forEach(self.gettersPreferedSuppliers, (s) => {
          let isInTier = false
          if ((Number(s.id) === Number(self.tierSuppliers.oemGen) && self.computedTypesOfCheckedParts.includes('OEM-GEN')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemPar1) && self.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemPar2) && self.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemPar3) && self.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemPar4) && self.computedTypesOfCheckedParts.includes('OEM-PAR')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemAlt1) && self.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemAlt2) && self.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemAlt3) && self.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(self.tierSuppliers.oemAlt4) && self.computedTypesOfCheckedParts.includes('OEM-ALT')) ||
              (Number(s.id) === Number(self.tierSuppliers.certAftm1) && self.computedTypesOfCheckedParts.includes('CERT-AFTM')) ||
              (Number(s.id) === Number(self.tierSuppliers.certAftm2) && self.computedTypesOfCheckedParts.includes('CERT-AFTM')) ||
              (Number(s.id) === Number(self.tierSuppliers.aftm1) && self.computedTypesOfCheckedParts.includes('AFTM')) ||
              (Number(s.id) === Number(self.tierSuppliers.aftm2) && self.computedTypesOfCheckedParts.includes('AFTM')) ||
              (Number(s.id) === Number(self.tierSuppliers.used1) && self.computedTypesOfCheckedParts.includes('USED')) ||
              (Number(s.id) === Number(self.tierSuppliers.used2) && self.computedTypesOfCheckedParts.includes('USED')) ||
              (Number(s.id) === Number(self.tierSuppliers.used3) && self.computedTypesOfCheckedParts.includes('USED')) ||
              (Number(s.id) === Number(self.tierSuppliers.used4) && self.computedTypesOfCheckedParts.includes('USED'))) {
            isInTier = true
          }
          if (_.includes(_.map(s.brands, function (n) {return _.toUpper(n)}), _.toUpper(self.rfq.details.make)) &&
              !isInTier) {
            list.push(s)
          }
        })
      }

      if (self.temporarilyPreferedSuppliers.length != 0) {

        list = _.filter(list, function (i) {
          return !_.find(self.temporarilyPreferedSuppliers, (itm) => { return Number(itm.id) == Number(i.id)})
        })
      }
      if (self.selectedState != '') {
        list = _.filter(list, function (i) {
          return i.state == self.selectedState
        })
      }
      if (self.selectedType != '' && !this.isActiveGridView) {
        list = _.filter(list, function (itm) {
          return itm.types.includes(self.selectedType)
        })
      }
      if (self.rfq.details.make != '') {
        list = _.filter(list, function (i) {
          return _.includes(
              _.map(i.brands, function (n) {
                return _.toUpper(n)
              }),
              _.toUpper(self.rfq.details.make)
          )
        })
      }

      list = _.sortBy(list, 'name')
      list = _.uniqBy(_.orderBy(list, ['isPrefered'], ['desc']), 'id')

      return list
    },
    checkedPartsOEMGen: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.oemGen == false
        })

        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('OEM-GEN')
        } else {
          this.unCheckColumnPart('OEM-GEN')
        }
      }
    },
    checkedPartsCERTAftm: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.certAftm == false
        })

        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('CERT-AFTM')
        } else {
          this.unCheckColumnPart('CERT-AFTM')
        }
      }
    },
    checkedPartsOEMPar: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.oemPar == false
        })

        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('OEM-PAR')
        } else {
          this.unCheckColumnPart('OEM-PAR')
        }
      }
    },
    checkedPartsOEMAlt: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.oemAlt == false
        })

        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('OEM-ALT')
        } else {
          this.unCheckColumnPart('OEM-ALT')
        }
      }
    },
    checkedPartsUSED: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.used == false
        })
        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('USED')
        } else {
          this.unCheckColumnPart('USED')
        }
      }
    },
    checkedPartsAFTM: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.aftm == false
        })
        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('AFTM')
        } else {
          this.unCheckColumnPart('AFTM')
        }
      }
    },
    checkedPartsRECO: {
      get: function () {
        if (this.rfq.parts.length == 0) {
          return false
        }
        let result = _.filter(this.rfq.parts, function (item) {
          return item.type.reco == false
        })
        if (result && result.length > 0) {
          return false
        }
        return true

      },
      set: function (check) {
        if (check) {
          this.checkColumnPart('RECO')
        } else {
          this.unCheckColumnPart('RECO')
        }
      }
    },
  },
  mounted: function () {
    if (!_.isEmpty(localStorage.getItem('isActiveGridView'))) {
      this.isActiveGridView = localStorage.getItem('isActiveGridView') == 'true' || localStorage.getItem('isActiveGridView') == true ? true : false
    }
    this.rfq.estimator = this.gettersCurrentUserFullName

    console.log(this.$route, 'params')
    if (this.$route.params && this.$route.params.type) {
      if (_.toLower(this.$route.params.type) === 'received') {
        this.rfq.type = 'Received'
      }
    }
    if (this.$route.params && this.$route.params.rfq_id) {
      if (this.$route.params.rfq_id > 0) {
        this.rfq.type = 'Received'
        if (this.$route.path.includes('add/suppliers')) {
          this.isAddSuppliers = true
        }
        this.loadRFQ(this.$route.params.rfq_id)
      }
    }
    if (this.isHasActiveApiKey) {
      this.rfq.type = 'Received'
    }
    if (!_.isEmpty(this.companyInfo) && this.companyInfo.address.state)
      this.initTime(this.companyInfo.address.state, this.companyInfo.address.country)
    if (this.rfq.type == 'Manual') {
      console.log('M1', Object.assign({}, this.gettersCurrentUserFullName))
      this.$nextTick(() => {
        console.log('M2', Object.assign({}, this.gettersCurrentUserFullName))
        this.checkManualType()
      })
    }
    window.addEventListener('resize', this.handleResize)
    this.isMounted = true
    this.handleResize()

    let interval = setInterval(() => {
      if (!_.isEmpty(this.companyInfo.id)) {
        clearInterval(interval)
        let state = _.find(this.supplierStates, itm => {
          return itm.id === this.companyInfo.address.state
        })
        this.valueSupplierByState = !_.isEmpty(state) ? state : this.supplierStates[0]
      }
    }, 10)

    this.timeInterval = setInterval(() => {
      this.updateCurrentTime(this.companyInfo.address.state, this.companyInfo.address.country)
    }, 1000)
  },
  created () {
    this.$eventBus.$on('message-IMPORT_FROM_EXTENSION', this.incomingDataFromPartsbridge)
  },
  beforeDestroy () {
    this.$eventBus.$off('message-IMPORT_FROM_EXTENSION', this.incomingDataFromPartsbridge)
    clearInterval(this.timeInterval)
  },
  watch: {
    getTier1: {
      handler (v) {
        if (!_.isEmpty(this.rfq.details.make)) {
          this.tierSuppliers = _.find(v, (t) => {
            return t.make === this.rfq.details.make
          })
          if (this.tierSuppliers && this.isNotStringOrNumber(this.tierSuppliers.oemGen) &&
              this.isNotStringOrNumber(this.tierSuppliers.oemPar1) && this.isNotStringOrNumber(this.tierSuppliers.oemPar2) && this.isNotStringOrNumber(this.tierSuppliers.oemPar3) && this.isNotStringOrNumber(this.tierSuppliers.oemPar4) &&
              this.isNotStringOrNumber(this.tierSuppliers.oemAlt1) && this.isNotStringOrNumber(this.tierSuppliers.oemAlt2) && this.isNotStringOrNumber(this.tierSuppliers.oemAlt3) && this.isNotStringOrNumber(this.tierSuppliers.oemAlt4) &&
              this.isNotStringOrNumber(this.tierSuppliers.used1) && this.isNotStringOrNumber(this.tierSuppliers.used2) && this.isNotStringOrNumber(this.tierSuppliers.used3) && this.isNotStringOrNumber(this.tierSuppliers.used4) &&
              this.isNotStringOrNumber(this.tierSuppliers.aftm1) && this.isNotStringOrNumber(this.tierSuppliers.aftm2) &&
              this.isNotStringOrNumber(this.tierSuppliers.certAftm1) && this.isNotStringOrNumber(this.tierSuppliers.certAftm2)
          ) {
            this.tierSuppliers = null
          }

          if (!_.isEmpty(this.rfq.suppliers)) {
            this.rfq.suppliers = _.filter(this.rfq.suppliers, (id) => {
              let supplier = _.find(this.computedPreferedSuppliers, (sup) => {
                return Number(sup.id) === Number(id)
              })
              return supplier
            })
          }
          for (let type in this.rfq.suppliers2) {
            this.rfq.suppliers2[type] = _.filter(this.rfq.suppliers2[type], (itm) => {
              return this.rfq.suppliers.includes(itm)
            })
          }
        }
      },
      immediate: true,
    },
    companyInfo (val) {
      this.initTime(val.address.state, val.address.country)
    },
    selectTime (val) {
      this.rfq.datetime.time = val
    },
    gettersCurrentUserFullName (val) {
      if (this.rfq.type == 'Manual' && !_.isEmpty(this.gettersCurrentUserFullName)) {
        this.rfq.estimator = this.gettersCurrentUserFullName
      }
      if (this.rfq.type == 'PDF' && _.isEmpty(this.rfq.estimator) && !_.isEmpty(this.gettersCurrentUserFullName)) {
        this.rfq.estimator = this.gettersCurrentUserFullName
      }
    }
  },
  components: {
    NumberFormatter,
    VStepper,
    vueDropzone: vue2Dropzone,
    GoodWizard,
    ImageLightbox,
    PartsList,
    DatePicker,
    draggable,
    Multiselect,
    RFQImport,
  }
}

</script>

<template>
  <div class="rfq-add-page">
    <div class="page-header">
      <h4>Request for Quotes</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/r">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/r/rfqs">View Quotes</router-link>
        </li>
        <li v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.getCurrentStep > 0 && rfq.type === 'PDF'"
            class="breadcrumb-item active">Import New Quote
        </li>
        <li v-else class="breadcrumb-item active">Submit New Quote</li>
      </ol>
    </div>
    <div class="rfq-add-body">
      <good-wizard
          :steps="steps"
          :step="step"
          :currentStep="step"
          ref="RFQAddStepper"
      >
        <div slot="rfq-add-step-1" class="rfq-add-step-margin">
          <div class="header">
            Select Quote Type
          </div>
          <div class="container step-body">
            <div class="row">
              <div class="col-3 quote-action">
                Quote Action
              </div>
              <div class="col-9" style="display: flex; padding-top: 10px;">
                <div class="col pr-0 pl-0">
                  <label style="cursor: pointer" class="form-check-inline radio received-quote">
                    <input class="form-check-input" type="radio" v-model="rfq.type" name="rfq_type"
                           value="Received"
                    >
                    <span class="icon"><i class='bx bx-check'></i></span><span
                      class="text received-quote">Received Quote</span>
                  </label>
                </div>
                <div class="col">
                  <label style="cursor: pointer" class="form-check-inline radio manual-quote">
                    <input @click="checkManualType" class="form-check-input" type="radio"
                           v-model="rfq.type"
                           name="rfq_type" value="Manual" checked
                    >
                    <span class="icon"><i class='bx bx-check'></i></span><span
                      class="text manual-quote">Manual Quote </span>
                  </label>
                </div>
                <div class="col">
                  <label style="cursor: pointer" class="form-check-inline radio import-pdf-quote">
                    <input class="form-check-input" type="radio" v-model="rfq.type" name="rfq_type"
                           value="PDF"
                    >
                    <span class="icon"><i class='bx bx-check'></i></span><span
                      class="text import-pdf-quote">Import PDF Quote</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top:15px;">
              <div class="col">
                <div
                    style="  box-sizing: border-box;  height: 2px; width:100%;  border: 1px solid rgba(27,30,56,0.25);"></div>
              </div>
            </div>
            <div v-if="rfq.type=='Received'" class="subheader" style="margin:25px 0; font-size: 14px;">
              Select from the quote/s received from your quoting package to continue
            </div>
            <div v-if="rfq.type=='Manual'" class="subheader" style="margin:25px 0; font-size: 14px;">
              Create a New Manual Quote if you don't have a quoting package system to continue.
            </div>
            <b-table v-if="rfq.type=='Received'"
                     responsive
                     hover
                     :items="gettersReceivedRFQs"
                     :fields="receivedRFQsFields"
                     @row-clicked="selectReceivedRFQ"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(action)="row">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         type="checkbox"
                         :value="row.item.id"
                         @click="selectReceivedRFQ(row.item)"
                         :checked="Number(row.item.id) == Number(selectedReceivedRFQId)"
                  >
                  <span class="icon"><i class='bx bx-check'></i></span>
                </label>
              </template>
              <template v-slot:cell(dateReceived)="row">
                {{ row.item.dateReceived | formatDate('DD/MM/YYYY hh:mmA') }}
              </template>
              <template v-slot:cell(make)="row">
                {{ correctMake(row.item.make) }}
              </template>
            </b-table>

            <div v-if="isLoading && rfq.type === 'PDF'" class="import-loading-text">
              Importing and converting your PDF Quote
            </div>
            <r-f-q-import
                v-if="rfq.type == 'PDF'"
                @loadParts="(parts, details) => loadedParts(parts, details)"
                @changedLoading="(value) => isLoading = value"
            >
            </r-f-q-import>

          </div>
        </div>
        <div slot="rfq-add-step-2" class="rfq-add-step-margin" :class="{'d-flex block-with-advert': isShowAdvert}">
          <div class="">
          <div class="header">
            Quote Details
          </div>
          <div class="subheader">
            Confirm the vehicle details below to go in the Quote
          </div>
          <div class="vehicle-details-block block">
            <div class="block-header bg-navyblue">
              Vehicle Details
            </div>
            <div class="block-body bg-white">
              <div class="form-group row">
                <label class="col col-form-label">Quoting Package Nbr</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsQPNumber" :readonly="isAddSuppliers"
                      type="text" class="form-control" placeholder=""
                      v-on:keyup.down="changePositionByArrowKey(isAddSuppliers ? 'addRfqVehicleDetailsRFQNumber' :'addRfqVehicleDetailsRego',$event)"
                      @change="upperCase(rfq, 'QPNumber')"
                      style="text-transform: uppercase;"
                      v-model="rfq.QPNumber">
                </div>
              </div>

              <div v-if="isAddSuppliers" class="form-group row">
                <label class="col col-form-label">RFQ Number</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsRFQNumber"
                      type="text" class="form-control" placeholder=""
                      v-model="rfq.number"
                      readonly
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsQPNumber',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsRego',$event)">
                </div>
              </div>

              <div class="form-group row">
                <label class="col col-form-label">Rego</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsRego"
                      :readonly="isAddSuppliers"
                      type="text" class="form-control" placeholder=""
                      v-on:keyup.up="changePositionByArrowKey(isAddSuppliers ? 'addRfqVehicleDetailsRFQNumber' :'addRfqVehicleDetailsQPNumber',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsVIN',$event)"
                      @change="upperCase(rfq.details, 'rego')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.rego"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">VIN Number</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsVIN"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsRego',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsDom',$event)"
                      type="text"
                      class="form-control"
                      placeholder=""
                      @change="upperCase(rfq.details, 'vin')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.vin"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Build Date</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsDom"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsVIN',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsMake',$event)"
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="rfq.details.dom">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Make</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsMake"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsDom',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsModel',$event)"
                      type="text" class="form-control" placeholder=""
                      @change="changedMake(rfq.details, 'make')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.make">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Model</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsModel"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsMake',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsSeries',$event)"
                      type="text" class="form-control" placeholder=""
                      @change="upperCase(rfq.details, 'model')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.model">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Series</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsSeries"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsModel',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsColour',$event)"
                      type="text" class="form-control" placeholder=""
                      @change="upperCase(rfq.details, 'series')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.series">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Colour</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsColour"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsSeries',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsBody',$event)"
                      type="text" class="form-control" placeholder=""
                      @change="upperCase(rfq.details, 'colour')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.colour">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Body</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsBody"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsColour',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsTransmission',$event)"
                      type="text" class="form-control" placeholder=""
                      @change="upperCase(rfq.details, 'body')"
                      style="text-transform: uppercase;"
                      v-model="rfq.details.body">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Transmission</label>
                <div class="col-8">
                  <multiselect
                      ref="addRfqVehicleDetailsTransmission"
                      v-model="rfq.details.transmission"
                      :options="['Automatic','Manual']"
                      :showLabels="false"
                      :option-height="29"
                      :disabled="isAddSuppliers"
                      :max-height="203"
                      :close-on-select="true"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsBody',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsOdometer',$event)"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Odometer</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsOdometer"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsTransmission',$event)"
                      v-on:keyup.down="changePositionByArrowKey('viewRfqVehicleDetailsInsurer',$event)"
                      type="text" class="form-control" placeholder=""
                      v-model="rfq.details.odometer">
                </div>
              </div>

              <div class="form-group row">
                <label class="col col-form-label">Insurer</label>
                <div class="col-8">
                  <multiselect
                      ref="viewRfqVehicleDetailsInsurer"
                      v-model="rfq.details.insurer"
                      :disabled="isAddSuppliers"
                      :options="insurerList"
                      :showLabels="false"
                      :taggable="true"
                      tag-placeholder="+ New Insurer"
                      @tag="addedNewInsurer"
                      :option-height="29"
                      class="repairer-insurer-multiselect"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Select insurer"
                      v-on:keyup.up="changePositionByArrowKey('viewRfqVehicleDetailsInsurer',$event)"
                      v-on:keyup.down="changePositionByArrowKey('viewRfqVehicleDetailsInsurer',$event)"
                  >
                  </multiselect>
                </div>
              </div>

                            <div class="form-group row">
                              <label class="col col-form-label">Customer Claim Nbr</label>
                              <div class="col-8">
                                <input
                                    ref="addRfqVehicleDetailsCustomerClaimNbr"
                                    :readonly="isAddSuppliers"
                                    v-on:keyup.up="changePositionByArrowKey('viewRfqVehicleDetailsInsurer',$event)"
                                    v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsEstimator',$event)"
                                    type="text" class="form-control" placeholder=""
                                    v-model="rfq.details.claimNumber">
                              </div>
                            </div>

              <div class="form-group row">
                <label class="col col-form-label">Estimator</label>
                <div class="col-8">
                  <input
                      ref="addRfqVehicleDetailsEstimator"
                      :readonly="isAddSuppliers"
                      v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsCustomerClaimNbr',$event)"
                      v-on:keyup.down="changePositionByArrowKey('addRfqVehicleDetailsComments',$event)"
                      type="text" class="form-control" placeholder=""
                      @change="updateEstimator(rfq, 'estimator')"
                      :class="{
                        'estimator-field-upper': rfq.type === 'PDF'
                      }"
                      v-model="rfq.estimator">
                </div>
              </div>

              <div v-if="!_isEmpty(rfq.additionalRfqFor)" class="form-group row">
                <label class="col col-form-label">Send as Additional</label>
                <div class="col-8 d-flex">
                  <div>
                    <label class="form-check-inline checkbox">
                      <input v-model="isSendAsAdditional" :disabled="isAddSuppliers"  class="form-check-input" type="checkbox">
                      <span class="icon clickable"><i class='bx bx-check'></i></span>
                      <span class="direct-text">with Estimate {{rfq.additionalRfqFor.number}}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Send as Direct Order</label>
                <div class="col-8 d-flex">
                  <div>
                    <label class="form-check-inline checkbox">
                      <input v-model="rfq.isSendAsDirectOrder" :disabled="isAddSuppliers" @change="setSuppForEstimate" class="form-check-input"
                             type="checkbox"
                      >
                      <span class="icon clickable"><i class='bx bx-check'></i></span>
                      <span class="direct-text">Yes - Pre-Approved (No Quote, No Comparison)</span>
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="rfq.isSendAsDirectOrder" class="form-group row">
                <label class="col col-form-label">Supp for Estimate</label>
                <div class="col-8">
                  <multiselect
                      :options="getQPNumbers"
                      :disabled="isAddSuppliers"
                      v-model="rfq.directOrderParentQpNumber"
                      :showLabels="false"
                      :option-height="32"
                      placeholder="Estimate Number"
                      :max-height="203"
                      :close-on-select="true"
                  >
                  </multiselect>
                </div>
              </div>

              <div v-if="rfq.isSendAsDirectOrder">
                <div class="form-group row">
                  <label class="col col-form-label">Preferred Delivery Date</label>
                  <div class="col-8 d-flex">
                    <div>
                      <date-picker v-model="rfq.directOrder.deliveryDate" :isDisabled="isAddSuppliers"></date-picker>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col col-form-label">Mark as Urgent</label>
                  <div class="col-8 d-flex">
                    <div>
                      <label class="form-check-inline radio">
                        <input class="form-check-input" type="radio"
                               name="mark-as-urgent"
                               :readonly="isAddSuppliers"
                               v-model="rfq.directOrder.isMarkAsUrgent"
                               @click="!rfq.directOrder.isMarkAsUrgent ? rfq.directOrder.isMarkAsUrgent = true : rfq.directOrder.isMarkAsUrgent = null"
                               :value="true">
                        <span class="icon clickable"><i class='bx bx-check'></i></span><span
                          class="text">Yes</span>
                      </label>
                    </div>
                    <div class="">
                      <label class="form-check-inline radio">
                        <input
                            v-model="rfq.directOrder.isMarkAsUrgent"
                            :disabled="isAddSuppliers"
                            @click="rfq.directOrder.isMarkAsUrgent ? rfq.directOrder.isMarkAsUrgent = false : rfq.directOrder.isMarkAsUrgent = null"
                            class="form-check-input" type="radio" name="mark-as-urgent"
                            :value="false">
                        <span class="icon clickable"><i class='bx bx-check'></i></span><span
                          class="text">No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col col-form-label">Acceptable Stock Level/s</label>
                  <div class="col-8 d-flex justify-content-between">
                    <div>
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               :disabled="isAddSuppliers"
                               v-model="rfq.directOrder.stockLvls"
                               value="In Stock"
                               type="checkbox"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                        <span class="direct-text">In Stock</span>
                      </label>
                    </div>
                    <div class="">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               v-model="rfq.directOrder.stockLvls"
                               :disabled="isAddSuppliers"
                               value="EX Warehouse"
                               type="checkbox"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                        <span class="direct-text">EX Warehouse</span>
                      </label>
                    </div>
                    <div class="">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               v-model="rfq.directOrder.stockLvls"
                               :disabled="isAddSuppliers"
                               value="Back Order"
                               type="checkbox"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                        <span class="direct-text" style="min-width: 71px">Back Order</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col col-form-label">Deliver as Complete Order</label>
                  <div class="col-8 d-flex">
                    <div>
                      <label class="form-check-inline radio">
                        <input class="form-check-input" type="radio"
                               v-model="rfq.directOrder.isDeliverAsCompleteOrder"
                               :disabled="isAddSuppliers"
                               @click="!rfq.directOrder.isDeliverAsCompleteOrder ? rfq.directOrder.isDeliverAsCompleteOrder = true : rfq.directOrder.isDeliverAsCompleteOrder = null"
                               name="deliver-as-complete"
                               :value="true">
                        <span class="icon clickable"><i class='bx bx-check'></i></span><span
                          class="text">Yes</span>
                      </label>
                    </div>
                    <div class="">
                      <label class="form-check-inline radio">
                        <input class="form-check-input" type="radio" name="deliver-as-complete"
                               v-model="rfq.directOrder.isDeliverAsCompleteOrder"
                               :disabled="isAddSuppliers"
                               @click="rfq.directOrder.isDeliverAsCompleteOrder ? rfq.directOrder.isDeliverAsCompleteOrder = false : rfq.directOrder.isDeliverAsCompleteOrder = null"
                               :value="false">
                        <span style="min-width: 25px" class="icon clickable"><i class='bx bx-check'></i></span><span
                          class="text" style="min-width: 211px">No - (Partial Delivery Acceptable)</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col col-form-label">Purchase Order Number</label>
                  <div class="col-8">
                    <input
                        v-model="rfq.directOrder.purchaseOrderNumber"
                        ref="purchaseOrderNumber"
                        :readonly="isAddSuppliers"
                        type="text" class="form-control" placeholder="">
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col col-form-label">Ask for a Paint Code</label>
                <div class="col-8 d-flex">
                  <div>
                    <label class="form-check-inline radio">
                      <input v-model="rfq.askForPaintCode" class="form-check-input" type="radio"
                             name="ask-paint-code"
                             :disabled="isAddSuppliers"
                             value="true"
                             @click="(!!rfq.askForPaintCode)?rfq.askForPaintCode=null:null"
                             @change="rfq.askForPaintCode=true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input v-model="rfq.askForPaintCode"
                             :disabled="isAddSuppliers"
                             @click="(!rfq.askForPaintCode)?rfq.askForPaintCode=null:null"
                             @change="rfq.askForPaintCode=false"
                             class="form-check-input" type="radio" name="ask-paint-code"
                             value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col col-form-label">Ask for a Parts Diagram</label>
                <div class="col-8 d-flex">
                  <div>
                    <label class="form-check-inline radio">
                      <input v-model="rfq.askForPartsDiagram" class="form-check-input" type="radio"
                             name="ask-paint-code"
                             :disabled="isAddSuppliers"
                             value="true"
                             @click="(!!rfq.askForPartsDiagram)?rfq.askForPartsDiagram=null:null"
                             @change="rfq.askForPartsDiagram=true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input v-model="rfq.askForPartsDiagram"
                             :disabled="isAddSuppliers"
                             @click="(!rfq.askForPartsDiagram)?rfq.askForPartsDiagram=null:null"
                             @change="rfq.askForPartsDiagram=false"
                             class="form-check-input" type="radio" name="ask-paint-code"
                             value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Comments</label>
                <div class="col-8">
                  <template v-if="!rfq.isSendAsDirectOrder && !rfq.askForPartsDiagram">
                                    <textarea
                                        ref="addRfqVehicleDetailsComments"
                                        :readonly="isAddSuppliers"
                                        v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsEstimator',$event)"
                                        v-on:keydown.tab="changePositionByArrowKey('RFQAddWizardButtonNext',$event)"
                                        type="text" class="form-control" placeholder="" rows="8"
                                        v-model="rfq.details.comments">
                                    </textarea>
                  </template>
                  <template v-else>
                    <div>
                      <div class="send-direct-order-comment-block">
                        <div class="p-2">
                          <template v-if="rfq.isSendAsDirectOrder">
                            <template v-if="rfq.isSendAsDirectOrder">
                              <div class="pb-3"><b>DIRECT ORDER REQUEST</b></div>
                              <div class="pb-1">
                                <span style="font-weight: 600;">Pre-Approved: </span>
                                <span style="font-weight: 400;">Order & Deliver</span>
                              </div>
                            </template>

                            <template v-if="rfq.directOrder.deliveryDate">
                              <div class="pb-1">
                                <span style="font-weight: 600;">Preferred delivery Date: </span>
                                <span style="font-weight: 400;">{{rfq.directOrder.deliveryDate}}</span>
                              </div>
                            </template>

                            <template v-if="rfq.directOrder.isMarkAsUrgent !== null">
                              <template v-if="rfq.directOrder.isMarkAsUrgent">
                                <div class="pb-1">
                                  <div style="font-weight: 600;">
                                    <span style="font-weight: 600;">Urgent: </span>
                                    <span style="font-weight: 400;">Yes</span>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="pb-1">
                                  <div>
                                    <span style="font-weight: 600;">Urgent: </span>
                                    <span style="font-weight: 400;">No</span>
                                  </div>
                                </div>
                              </template>
                            </template>


                            <template v-if="rfq.directOrder.stockLvls.length">
                              <div class="pb-1 d-flex">
                                <div>
                                  <span style="font-weight: 600;">Acceptable Stock Lvl: </span>
                                </div>

                                <span> <span
                                    v-for="(lvl, ind) in rfq.directOrder.stockLvls"
                                    class="red-comment-lvl"
                                    :class="{
                                    'in-stock-comment-lvl': lvl == 'In Stock',
                                    'ex-stock-comment-lvl': lvl == 'EX Warehouse',
                                  }"
                                    style="font-weight: 700;">
                                {{lvl}}
                                <span v-if="rfq.directOrder.stockLvls.length === 1" style="color: #1C1F39"> - Only</span>
                                <span v-else-if="ind < rfq.directOrder.stockLvls.length - 1" style="color: #1C1F39">&nbsp;|</span>

                              </span></span>

                              </div>
                            </template>

                            <template v-if="rfq.directOrder.isDeliverAsCompleteOrder !== null">
                              <template v-if="rfq.directOrder.isDeliverAsCompleteOrder">
                                <div class="pb-1">
                                  <div>
                                    <span style="font-weight: 600;">Deliver as Complete Order: </span >
                                    <span style="font-weight: 400;">Yes</span>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="pb-1">
                                  <div>
                                    <span style="font-weight: 600;">Deliver as Complete Order: </span >
                                    <span style="font-weight: 400;">No - (Partial Delivery Acceptable)</span>
                                  </div>
                                </div>
                              </template>
                            </template>

                            <template v-if="rfq.directOrder.purchaseOrderNumber">
                              <div>
                                <span style="font-weight: 600;">Purchase Order Number: </span>
                                <span style="font-weight: 400;">{{rfq.directOrder.purchaseOrderNumber}}</span>
                              </div>
                            </template>
                          </template>
                          <template v-if="rfq.askForPartsDiagram">
                            <div class="pb-3" :class="{'pt-4': rfq.isSendAsDirectOrder}"><b>PARTS DIAGRAM REQUEST</b></div>
                            <div class="pb-1">
                              <span style="font-weight: 600;">Comment: </span>
                            </div>
                          </template>

                          <textarea
                              :readonly="isAddSuppliers"
                              type="text" class="comment-textarea" placeholder="" rows="8"
                              v-model="rfq.details.comments">
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isShowAdvert" class="img-code-paint-container">
                      <img v-if="rfq.details.colour === 'B74P'"  src="@/assets/img/paints/B74P.png">
                      <img v-else-if="rfq.details.colour === '46V'" src="@/assets/img/paints/46V.png">
                      <img v-else-if="rfq.details.colour === '41V'" src="@/assets/img/paints/41V.png">
                      <img v-else-if="rfq.details.colour === 'NH883P'" src="@/assets/img/paints/NH883P.png">
                    </div>
                </div>
                <div slot="rfq-add-step-3" style="padding-bottom: 146px;"
             :class="{'rfq-add-step-3-manual': rfq.type=='Manual', 'rfq-add-step-margin': rfq.type, 'rfq-add-step-3-advert': isShowAdvert}">

                  <div :class="{'d-flex block-with-advert': isShowAdvert}">
                    <div class="">
          <div class="d-flex justify-content-between">
                     <div class="w-100">
                       <div class="d-flex justify-content-between">
                <div class="header header-width-90px">
            Part List
          </div>
          <div class="wizard__buttons m-0 pr-0 w-100 d-flex align-items-center justify-content-end rfq-add-step-5-btn">
                  <div class="d-flex">
                    <button class="btn btn-outline-primary-light pull-right button-back"
                            type="button"
                            @click="$refs.RFQAddStepper.goBack()"
                    >
                      Back
                    </button>
                    <button
                        class="btn btn-outline-primary pull-right button-cancel"
                        type="button"
                        @click="clickCancel"
                    >
                      Cancel
                    </button>
                    <button
                        ref="RFQAddWizardButtonNext"
                        class="btn btn-primary pull-right button-next"
                        type="button"
                        @click="goNext"
                        :tabindex="4"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <div class="subheaderd-flex justify-content-between">
            Tick the parts and supplier types you want to get pricing from
          </div>
                     </div>
                     </div>
          <div v-if="isShowApplyPartNumbers" class="d-flex justify-content-end w-100 ">
            <div class="form-check-inline-position">
                       <label class="form-check-inline checkbox d-flex flex-row-reverse" >
                         <span style="padding: 5px 0px 0px 10px; width: 117px; color: #1C1F39; font-weight: 600;"> Apply Parts Nbrs</span>
                         <input
                             @change="onChangeApplyPartsNbrs"
                             v-model="isApplyPartsNbrs"
                             class="form-check-input" type="checkbox">
                         <span class="icon clickable"><i class='bx bx-check'></i></span>
                       </label>
                     </div>
                   </div>
          <div class="row">
            <div v-if="rfq.type=='Manual'" class="col" style="padding-left: 0px;padding-right: 0px;">
              <parts-list
                  @addPart="addPart"
              >
              </parts-list>
            </div>
            <div :class="rfq.type=='Manual'?'col-md-8':'col-12'" style="padding-left: 0px;padding-right: 0px;">
              <b-table-simple
                  responsive
                  hover
              >
                <b-thead class="rfq-add-step-3-table-head">
                  <b-tr>
                    <b-th class="text-left part-name align-top">Name</b-th>
                    <b-th class="text-left part-number align-top">Part Number</b-th>
                    <b-th class="text-center part-qty align-top">Qty</b-th>
                    <b-th class="text-center part-type align-top">
                      <div>OEM-GEN</div>
                                            <div style="margin-top:5px;"
                                                 v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsOEMGen ?'Un-Select All':'Select All', placement:'leftbottom'}">
                                                <label class="form-check-inline checkbox">
                                                    <input class="form-check-input"
                                                           type="checkbox"
                                                           :disabled="isAddSuppliers"
                                                           v-model="checkedPartsOEMGen"
                                                    >
                                                    <span class="icon clickable"><i class='bx bx-check'></i></span>
                                                </label>
                                            </div>
                                        </b-th>
                                      <b-th class="text-center part-type align-top">
                                            <div>OEM-PAR</div>
                      <div style="margin-top:5px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsOEMPar?'Un-Select All':'Select All', placement:'leftbottom'}">
                        <label class="form-check-inline checkbox">
                          <input class="form-check-input"
                                 type="checkbox"
                                 v-model="checkedPartsOEMPar"
                          >
                          <span class="icon clickable"><i class='bx bx-check'></i></span>
                        </label>
                      </div>
                    </b-th>
                    <b-th class="text-center part-type align-top">
                      <div>OEM-ALT</div>
                      <div style="margin-top:5px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsOEMAlt?'Un-Select All':'Select All', placement:'leftbottom'}">
                        <label class="form-check-inline checkbox">
                          <input class="form-check-input"
                                 type="checkbox"
                                 v-model="checkedPartsOEMAlt"
                          >
                          <span class="icon clickable"><i class='bx bx-check'></i></span>
                        </label>
                      </div>
                    </b-th>
                    <b-th class="text-center part-type-aftm align-top">
                      <div>CERT-AFTM</div>
                      <div style="margin-top:5px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsAFTM?'Un-Select All':'Select All', placement:'leftbottom'}">
                        <label class="form-check-inline checkbox">
                          <input class="form-check-input"
                                 type="checkbox"
                                 v-model="checkedPartsCERTAftm"

                          >
                          <span class="icon clickable"><i class='bx bx-check'></i></span>
                        </label>
                      </div>
                    </b-th>
                    <b-th class="text-center part-type-aftm align-top">
                      <div>AFTM</div>
                      <div style="margin-top:23px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsAFTM?'Un-Select All':'Select All', placement:'leftbottom'}">
                        <label class="form-check-inline checkbox">
                          <input class="form-check-input"
                                 type="checkbox"
                                 v-model="checkedPartsAFTM"
                          >
                          <span class="icon clickable"><i class='bx bx-check'></i></span>
                        </label>
                      </div>
                    </b-th>
                    <b-th class="text-center part-type align-top">
                      <div>USED</div>
                      <div style="margin-top:23px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsUSED?'Un-Select All':'Select All', placement:'leftbottom'}">
                        <label class="form-check-inline checkbox">
                          <input class="form-check-input"
                                 type="checkbox"
                                 v-model="checkedPartsUSED"
                          >
                          <span class="icon clickable"><i class='bx bx-check'></i></span>
                        </label>
                      </div>
                    </b-th>
                    <b-th class="text-center part-type align-top">
                      <div>RECO</div>
                      <div style="margin-top:23px;"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip tooltip-rfq-add', title:checkedPartsRECO?'Un-Select All':'Select All', placement:'leftbottom'}">
                        <label class="form-check-inline checkbox">
                          <input class="form-check-input"
                                 type="checkbox"
                                 v-model="checkedPartsRECO"

                          >
                          <span class="icon clickable"><i class='bx bx-check'></i></span>
                        </label>
                      </div>
                    </b-th>
                    <b-th class="text-center part-action align-top">
                      Action
                    </b-th>
                  </b-tr>
                </b-thead>
                <draggable
                    v-model="rfq.parts"
                    class="dragArea"
                    tag="tbody"
                    @change="onItemMoveChange"
                    :disabled="isDraggableDisabled"
                    handle=".handle">
                  <b-tr v-for="(part, index) in rfq.parts" class="handle"
                        :key="'rfq-add-parts-list'+index">
                    <b-td class="clickable part-name">
                      <input :ref="'rfq-add-parts-list-part-name'+index"
                             :key="'rfq-add-parts-list-part-name'+index"
                             @keydown="onPressArrowKey(index,$event, 'name')"
                             @change="upperCase(part, 'name')"
                             @mouseover="isDraggableDisabled = true"
                             @mouseleave="isDraggableDisabled = false"
                             style="text-transform: uppercase;"
                             :disabled="rfq.type=='Received'"
                             type="text" class="form-control" v-model="part.name" v-focus>
                    </b-td>
                    <b-td class="clickable part-number">
                      <input :ref="'rfq-add-parts-list-part-number'+index"
                             :key="'rfq-add-parts-list-part-number'+index"
                             @keydown="onPressArrowKey(index,$event, 'number')"
                             @mouseover="isDraggableDisabled = true"
                             @mouseleave="isDraggableDisabled = false"
                             :disabled="rfq.type=='Received'"
                             type="text" class="form-control" v-model="part.number">
                    </b-td>
                    <b-td class="clickable text-center part-qty">
                      <number-formatter
                          :ref="'rfq-add-parts-list-part-qty'+index"
                          @onKeyDown="onPressArrowKey(index,$event, 'qty')"
                          v-model="part.qty"
                          format=""
                          @mouseover="isDraggableDisabled = true"
                          @mouseleave="isDraggableDisabled = false"
                          :disabled="rfq.type=='Received'"
                          class="form-control text-center"
                      >
                      </number-formatter>
                    </b-td>
                    <b-td class="clickable text-center part-type">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               :disabled="isAddSuppliers"
                               v-model="part.type.oemGen"
                               @click="checkPartType(part.type,'OEM-GEN')"
                                                >
                                                <span class="icon clickable"><i class='bx bx-check'></i></span>
                                            </label>
                                        </b-td>
                                        <b-td class="clickable text-center part-type">
                                          <label class="form-check-inline checkbox">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   v-model="part.type.oemPar"
                                                   @click="checkPartType(part.type,'OEM-PAR')"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                      </label>
                    </b-td>
                    <b-td class="clickable text-center part-type">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               v-model="part.type.oemAlt"
                               @click="checkPartType(part.type,'OEM-ALT')"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                      </label>
                    </b-td>
                    <b-td class="clickable text-center part-type">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               v-model="part.type.certAftm"
                               @click="checkPartType(part.type,'CERT-AFTM')"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                      </label>
                    </b-td>
                    <b-td class="clickable text-center part-type">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               v-model="part.type.aftm"
                               @click="checkPartType(part.type,'AFTM')"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                      </label>
                    </b-td>
                    <b-td class="clickable text-center part-type-aftm">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               v-model="part.type.used"
                               @click="checkPartType(part.type,'USED')"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                      </label>
                    </b-td>
                    <b-td class="clickable text-center part-type">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               v-model="part.type.reco"
                               @click="checkPartType(part.type,'RECO')"
                        >
                        <span class="icon clickable"><i class='bx bx-check'></i></span>
                      </label>
                    </b-td>
                    <b-td class="part-action text-center">
                                            <span @click="removePart(part,index)"
                                                  v-b-tooltip.hover="{ customClass: 'ps-tooltip', title:'Remove this Line', placement:'leftbottom'}"><i
                                                class='bx bx-trash'></i></span>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </div>
          </div>
        </div>
<div v-if="rfq.type !== 'Manual' && isShowAdvert" class="" style="margin-left: 30px; margin-top: 59px;">
                      <img v-if="rfq.details.colour === 'B74P'"  src="@/assets/img/paints/B74P.png">
                      <img v-else-if="rfq.details.colour === '46V'" src="@/assets/img/paints/46V.png">
                      <img v-else-if="rfq.details.colour === '41V'" src="@/assets/img/paints/41V.png">
                      <img v-else-if="rfq.details.colour === 'NH883P'" src="@/assets/img/paints/NH883P.png">
                    </div>
                  </div>
                </div>
        <div slot="rfq-add-step-4" class="rfq-add-step-margin">
          <div class="d-flex justify-content-between">
            <div class="header header-width-90px">
              Images
            </div>
            <div class="wizard__buttons m-0 pr-0 w-100 d-flex align-items-center justify-content-end rfq-add-step-5-btn">
              <div class="d-flex mr-3">
                <button class="btn btn-outline-primary-light pull-right button-back"
                        type="button"
                        @click="$refs.RFQAddStepper.goBack()"
                >
                  Back
                </button>
                <button
                    class="btn btn-outline-primary pull-right button-cancel"
                    type="button"
                    @click="clickCancel"
                >
                  Cancel
                </button>
                <button
                    ref="RFQAddWizardButtonNext"
                    class="btn btn-primary pull-right button-next"
                    type="button"
                    @click="goNext"
                    :tabindex="4"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <template v-if="rfq.type !== 'Received'">
            <div class="subheader">
              Add Images to go in the Quote
            </div>
            <div>
              <vue-dropzone v-on:vdropzone-file-added="addImgUpload"
                            v-on:vdropzone-success="successImgUpload"
                            v-on:vdropzone-complete="completeImgUpload"
                            v-on:vdropzone-sending="sendImgUpload"
                            ref="imgUpDrop"
                            id="imgUploadDrop"
                            class="dropify-wrapper dropzoneContainer"
                            style="height: auto;"
                            :options="imageUploadDropzoneOptions"
                            :useCustomSlot=true>
                <div class="dropify-message" style="transform: translateY(0%);">
                  <i class='bx' :class="{'bx-camera': isMobile, 'bx-cloud-upload': !isMobile}"></i>
                  <p class="drop-down-text">
                    <template v-if="isMobile">Take Photo</template>
                    <template v-else>
                      Drag and Drop Images Here
                    </template>
                  </p>
                  <p class="text-or mb-0-5">Or</p>
                  <div class="text-sm-center mb-1 ex-image-upload-buttons">
                    <button type="button" id="clickBrowseImagesBtn"
                            class="btn btn-primary waves-effect waves-light browse-btn">Browse Images
                    </button>
                    <button @click="clickCancelDropzoneImages" type="button"
                            class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel
                    </button>
                  </div>
                </div>
              </vue-dropzone>
            </div>
          </template>
          <div class="image-block block">
            <div class="block-header bg-navyblue">
              Image
            </div>
            <image-lightbox
                :isPreviewImage="false"
                :isDownload="false"
                :isRemove="true"
                :isCanPin="isCanPinImages"
                :pinnedImage="pinnedImage"
                :position="''"
                :imageList="imageSource"
                                @onPinnedImage="onPinnedImage"
                                @removeImage="deleteImage"
                                :path="''"
                        ></image-lightbox>
                    </div>
                </div>
                <div slot="rfq-add-step-5" class="rfq-add-step-margin rfq-add-step-5-mobile" ref="rfq-add-step-5">
                    <div class="wizard-header d-flex justify-content-between align-items-center">
                        <div v-if="rfq.details.make && rfq.details.make != '' && !isShowAllPrefferedSuppliers"
                             class="wizard-header-name ">
                            Prefered Suppliers - Based on Make - {{ rfq.details.make }} <span v-if="tierSuppliers && isUseTier"> - Tier 1</span>
                        </div>
                        <div v-else class="wizard-header-name ">
                            Prefered Suppliers
                        </div>
                        <div class="d-flex align-items-center">
                          <i :class="{'icon-active': !isActiveGridView}" class='bx bx-list-ul' @click="changeListView"></i>
                          <div :class="{ 'icon-svg-active': isActiveGridView}" style="cursor: pointer;" @click="changeListView">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path :class="{ 'icon-svg-active': isActiveGridView}" fill="#e3e4e7" d="M3 21h8v-8H3m2 2h4v4H5m-2-8h8V3H3m2 2h4v4H5m8-6v8h8V3m-2 6h-4V5h4m-1 11h3v2h-3v3h-2v-3h-3v-2h3v-3h2Z"/></svg>
                          </div>
                        </div></div>
          <div style="margin: 10px 13px;" class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary browse-btn dz-clickable" @click="supplierModalType = 'Preffered'; $refs.addNotRegisteredSupplier.show()">
              <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>
              <span style="vertical-align: middle;">Non-Reg Supp</span>
            </button>
            <button type="button" class="btn btn-primary browse-btn dz-clickable ml-0-5" v-b-modal.custom-modal-invite-supplier>
              <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>
              <span style="vertical-align: middle;">Invite a Supp</span>
            </button>
                    </div>
                  <div>
                    <div>

                    </div>
                  </div>
                    <div class="wizard-header-suppliers-search rfq-add-step-5-search align-items-center">
                      <div class="w-100 d-flex align-items-center justify-content-start">
                        <b-form-input v-model="preferedSuppliersSearch" type="text" class="search-input"
                          placeholder="Search results" debounce="500"></b-form-input>
                        <div class="wizard-header-button-show-all">
                          <a v-if="rfq.details.make && rfq.details.make != '' && !isShowAllPrefferedSuppliers"
                            @click="scrollTo()"
                            class="btn-show-all">Show All Suppliers</a>
                          <a v-if="rfq.details.make && rfq.details.make != '' && isShowAllPrefferedSuppliers"
                            @click="scrollTo()"
                            class="btn-apple-filter-by">Apply Filter by {{ rfq.details.make }} <span v-if="tierSuppliers && isUseTier"> - Tier 1</span></a>
                        </div>

              <div class="" v-if="tierSuppliers">
                <label class="form-check-inline checkbox" style="margin-top: 6px; margin-left: 15px;">
                  <input class="form-check-input"
                         type="checkbox"
                         @change="saveSelectedSupps"
                         v-model="isUseTier"
                  >
                  <span class="icon clickable"><i class='bx bx-check'></i></span>
                  <span class="tier-checkbox">Tier 1</span>
                </label>
              </div>
            </div>
            <div class="wizard__buttons m-0 pr-0 w-100 d-flex align-items-center justify-content-end rfq-add-step-5-btn">
              <div class="d-flex mr-3">
                <button class="btn btn-outline-primary-light pull-right button-back"
                        type="button"
                        @click="$refs.RFQAddStepper.goBack()"
                >
                  Back
                </button>
                <button
                    class="btn btn-outline-primary pull-right button-cancel"
                    type="button"
                    @click="clickCancel"
                >
                  Cancel
                </button>
                <button
                    ref="RFQAddWizardButtonNext"
                    class="btn btn-primary pull-right button-next"
                    type="button"
                    @click="goNext"
                    :tabindex="4"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <b-table
              v-if="!isActiveGridView"
              responsive
              hover
              :filter="preferedSuppliersSearch"
              :items="computedPreferedSuppliers"
              :fields="preferedSuppliersFields"
          >
            <template v-slot:head()="data">
              <div v-if="!data.field.sortable">{{ data.label }}</div>
              <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
            </template>
            <template v-slot:cell(select)="row">
              <div
                  :class="{'disabled-supplier':isPrefferdSupplierDisabled(row) || (row.item.types.includes( 'OEM-GEN') && isAddSuppliers)}">
                <label class="form-check-inline checkbox list-select-checkbox">
                  <input v-if="!newSupplierElement" class="form-check-input"
                         type="checkbox"
                         v-model="rfq.suppliers"
                         :value="row.item.id"
                         @change="selectPreferedSupplierFromList(rfq.suppliers.includes(row.item.id), row.item.id, row.item.types)"
                         v-focus
                         :disabled="isPrefferdSupplierDisabled(row) || (row.item.types.includes( 'OEM-GEN') && isAddSuppliers)"
                  >
                  <input v-else class="form-check-input"
                         type="checkbox"
                         v-model="rfq.suppliers"
                         @change="selectPreferedSupplierFromList(rfq.suppliers.includes(row.item.id), row.item.id, row.item.types)"
                         :value="row.item.id"
                         v-focus
                         v-scroll-into-view
                         :disabled="isPrefferdSupplierDisabled(row) || (row.item.types.includes( 'OEM-GEN') && isAddSuppliers)"
                  >
                  <span class="icon"><i class='bx bx-check'></i></span>
                </label>
              </div>
            </template>

            <template v-slot:cell(types)="row">
              {{ getTypes(row.item) }}
            </template>

            <template v-slot:cell(action)="row">
              <div class="" v-if="!row.item.isTemp">
                <i v-if="isSupplierInTemporarilyPrefered(row.item.id, row.item.types, true)" class='bx bx-x-circle'
                   v-b-tooltip.hover="{ customClass: 'ps-tooltip', html:true,  title:'<strong>Remove</strong> '+row.item.name+'<br > from your temporary preferred suppliers list', placement:'leftbottom'}"
                   @click="setSupplierAsOther(row.item, true, row.item.type)"></i>
                <i v-else class='bx bx-x-circle'
                   v-b-tooltip.hover="{ customClass: 'ps-tooltip', html:true,  title:'<strong>Remove</strong> '+row.item.name+'<br > from your preferred suppliers list', placement:'leftbottom'}"
                   @click="setSupplierAsOther(row.item, true, row.item.type)"></i>
              </div>
            </template>
            <template v-slot:cell()="row">
              <div :class="{'disabled-supplier':isPrefferdSupplierDisabled(row) || (row.item.types.includes('OEM-GEN') && isAddSuppliers)}">{{ row.value }}</div>
            </template>
          </b-table><template v-else>
          <div class="rfq-add-step-5-mobile-wrapper">
              <div class="d-flex justify-content-between mb-4 rfq-add-step-5-mobile-table">
            <div v-for="type in supplierTypesForGrid" class="d-flex flex-column w-100">
                          <div class="grid-supplier-header">
              {{type}}
              <p v-if="type == 'OEM-GEN'" class="grid-supplier-header-small-text">Dealership</p>
                <p v-else class="grid-supplier-header-small-text">Non-Dealership</p>
              </div>
            <div class="grid-supplier-main-wrapper">
            <div v-for="s in computedPreferedSuppliersByTypes[type]"
                                :class="{
                                  'disabled-supplier':(s && isPrefferdSupplierDisabled(s, type)) || (type === 'OEM-GEN' && isAddSuppliers),
                                }"
                                class="grid-supplier-main-block">
                              <p class="mb-0">{{s.name}}</p>
                              <p class="grid-supplier-main-state mb-0">{{s.suburb}}</p>
              <div class="grid-supplier-main-btn">
                                <!--                      removed by #521.1-->
<!--                      <i v-if="isSupplierInTemporarilyPrefered(s.id, type)" class='bx bx-x-circle'-->
<!--                         v-b-tooltip.hover="{ customClass: 'ps-tooltip ps-tooltip-grid-supplier-main-btn', html:true,  title:'<strong>Remove</strong> '+s.name+'<br > from your temporary preferred suppliers list', placement:'leftbottom'}"-->
         <!--                         @click="setSupplierAsOther(s, false, type)"></i>-->
                <!--                      <i v-else class='bx bx-x-circle'-->
<!--                    v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-grid-supplier-main-btn', html:true,  title:'<strong>Remove</strong> '+s.name+'<br > from your preferred suppliers list', placement:'leftbottom'}"-->
         <!--                         @click="setSupplierAsOther(s, false, type)"></i>-->
                              </div>
                              <div class="grid-suppliers-checkboxes-block">
                                <label class="form-check-inline checkbox tile-checkbox-label">
                                    <input
                                        class="form-check-input"
                                        :disabled="(s && isPrefferdSupplierDisabled(s, type)) || (type === 'OEM-GEN' && isAddSuppliers)"
                                        @click="selectPreferedSupplierFromGrid(s, type)"
                                        v-model="rfq.suppliers2[type]"
                                        :value="s.id"
                                        type="checkbox"
                                    >
                                    <span
                                        class="icon tile-checkbox-icon"
                                        :class="{
                                          'clickable': !(s && isPrefferdSupplierDisabled(s, type)) || (type === 'OEM-GEN' && isAddSuppliers)
                                        }"
                        ><i class='bx bx-check'></i></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

                      </div>
            </div>
                      <div class="grid-supplier-bottom-line"></div>
                </template><div class="wizard-header d-flex justify-content-between wizard-header-1000px-down">
                        <div class="d-flex rfq-add-step-5-mobile-title">
                          <div class="wizard-header-name mr-3">
                            Other Suppliers to Invite
                          </div>
                          <div class="wizard-header-suppliers-search">
                <b-form-input v-model="otherSuppliersSearch" type="text" class="search-input"
                              placeholder="Search results"
                              debounce="500"></b-form-input>
              </div>
            </div>
            <div class="wizard-header-button-supplier-in d-flex" >
               <div class="" style="margin-right: 12px; transform: translateY(-10px)">
                 <button style="min-width: 122px" type="button" class="btn btn-primary browse-btn dz-clickable" @click="supplierModalType = 'Other'; $refs.addNotRegisteredSupplier.show()">
                   <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>
                   <span style="vertical-align: middle;">Non-Reg Supp</span>
                 </button>
               </div>

              <div v-if="!isActiveGridView" class="d-flex align-items-center">
                <div class="font-weight-bold mr-3">Type</div>

                <multiselect
                class="multiselect-type"
                                v-model="valueSupplierByType"
                                :options="supplierTypesForGrid"
                                :searchable="false"
                                @input="clickSelectSupplierByType"
                                :allow-empty="false"
                                :showLabels="false"
                                :option-height="32"
                                :max-height="403"
                                style="width: 112px; margin-right: 15px; padding-left: 0;"
                                :close-on-select="true"
                            >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option }}</strong></template>
                            </multiselect>
                          </div>

              <div class="d-flex align-items-center">
                <div class="font-weight-bold mr-3">State</div>

                              <multiselect
                                class="multiselect-state"
                                style="width: 103px"
                                v-model="valueSupplierByState"
                                track-by="id"
                                label="name"
                                :placeholder="companyInfo.address.state"
                                :options="supplierStates"
                                :searchable="false"
                                @input="v => clickSelectSupplierByState(v.id)"
                                :allow-empty="false"
              :showLabels="false"
                                :option-height="32"
                                :max-height="403"
                                :close-on-select="true"
                              >
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.id == 0 ? 'All State' : option.id }}</strong></template>
              </multiselect>
              <!-- <b-dropdown id="dropdown-2" text="State" class="dropdowns-header">
                <template v-slot:button-content><span
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show Suppliers By State', placement:'leftbottom'}">{{ selectedState }}
                                    <span class="dropdown-arrow">
                                        <i class='bx bx-down-arrow'></i>
                                    </span>
                                </span>
                </template>
                <b-dropdown-item @click="clickSelectSupplierByState(0)">All State</b-dropdown-item>
                <b-dropdown-item @click="clickSelectSupplierByState('ACT')">Australian Capital Territory</b-dropdown-item>
                                <b-dropdown-item @click="clickSelectSupplierByState('NSW')">New South Wales</b-dropdown-item>
                                <b-dropdown-item @click="clickSelectSupplierByState('NT')">Northern Territory</b-dropdown-item>
                                <b-dropdown-item @click="clickSelectSupplierByState('QLD')">Queensland</b-dropdown-item>
                <b-dropdown-item @click="clickSelectSupplierByState('SA')">South Australia</b-dropdown-item>
                <b-dropdown-item @click="clickSelectSupplierByState('TAS')">Tasmania</b-dropdown-item>
                <b-dropdown-item @click="clickSelectSupplierByState('VIC')">Victoria
                </b-dropdown-item>
                <b-dropdown-item @click="clickSelectSupplierByState('WA')">Western Australia
                </b-dropdown-item>
              </b-dropdown>-->
              </div>

            </div>
            </div>

                            <b-table
            v-if="!isActiveGridView"
              responsive
              hover
              :filter="otherSuppliersSearch"
              :items="computedOtherSuppliers"
              :fields="otherSuppliersFields"
          >

            <template v-slot:head(suburb)="data">
              <div class="table-sorting"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Location of supplier in your State', placement:'leftbottom'}">
                {{ data.label }}
                <i></i>
              </div>
            </template>
            <template v-slot:head(state)="data">
              <div class="table-sorting"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Type of parts the supplier provides', placement:'leftbottom'}">
                {{ data.label }}
                <i></i>
              </div>
            </template>

            <template v-slot:cell(types)="row">
              {{ getTypes(row.item) }}
            </template>

            <template v-slot:head(makes)="data">
              <div class="table-sorting"
                   v-b-tooltip.hover="{disabled:rfq.details.make=='', customClass: 'ps-tooltip', html: true, title:'Supplier is a match for '+rfq.details.make+' parts supply', placement:'leftbottom'}">
                {{ data.label }}
                <i></i>
              </div>
            </template>
            <template v-slot:head(action)="data">
                            <span v-if="selectedType !='OEM-GEN' && selectedType !='OEM-PAR'">
                                Action
                            </span>
              <span v-else
                    style="width:59px; display: block;">&nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; </span>
            </template>
            <template v-slot:head()="data">
              <div v-if="!data.field.sortable">{{ data.label }}</div>
              <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
            </template>
            <template v-slot:cell(makes)="row">
              <span>{{ rfq.details.make }}</span>
            </template>
            <template v-slot:cell(action)="row">
              <!--                            <div class="tooltip b-tooltip bs-tooltip-bottom ps-tooltip" role="tooltip">-->
              <!--                                <div class="arrow" style="left: calc(50% - 6px); display:none;"></div>-->
              <!--                                <div class="tooltip-inner">Tooltip on the top</div>-->
              <!--                            </div>-->
              <span>
                            <i style="margin-right: 15px;" class='bx bxs-add-to-queue'
                               @click="setSupplierAsPrefered(row.item, row.item.types, true)"
                               v-b-tooltip.hover="{ customClass: 'ps-tooltip', title:'Add '+row.item.name+' permanently to your prefered suppliers list', placement:'leftbottom', offset:'200px' }"></i>
                            <i class='bx bx-add-to-queue'
                               @click="setSupplierAsTemporarilyPrefered(row.item, true, row.item.types, true)"
                               v-b-tooltip.hover="{ customClass: 'ps-tooltip', title:'Add '+row.item.name+' temporarily to your prefered suppliers list', placement:'leftbottom', offset:'200px' }"></i>
                                </span>
            </template>

          </b-table>

                    <template v-else>
                      <div class="rfq-add-step-5-mobile-wrapper">
              <div class="d-flex justify-content-between mb-4 rfq-add-step-5-mobile-table">
                        <div v-for="type in supplierTypesForGrid" class="d-flex flex-column w-100">
                          <div class="grid-supplier-header grid-other-supplier-header">
                            {{type}}
                            <p v-if="type == 'OEM-GEN'" class="grid-supplier-header-small-text">Dealership</p>
                            <p v-else class="grid-supplier-header-small-text">Non-Dealership</p>
                          </div>
                          <div class="grid-supplier-main-wrapper">
                            <div v-for="s in computedOtherSuppliersByTypes[type]" class="grid-supplier-main-block grid-other-supplier-main-block">
                              <p class="mb-0">{{s.name}}</p>
                              <p class="grid-supplier-main-state mb-0">{{s.suburb}}</p>
                              <div class="grid-supplier-main-btn">
                                  <i style="margin-right: 5px;" class='bx bxs-add-to-queue'
                                    @click="setSupplierAsPrefered(s, type, false)"
                                    v-b-tooltip.hover="{ customClass: 'ps-tooltip ps-tooltip-grid-supplier-main-btn', title:'Add '+s.name+' permanently to your prefered suppliers list', placement:'leftbottom', offset:'200px' }"></i>
                                  <i class='bx bx-add-to-queue' @click="setSupplierAsTemporarilyPrefered(s, true, type)"
                                    v-b-tooltip.hover="{ customClass: 'ps-tooltip ps-tooltip-grid-supplier-main-btn', title:'Add '+s.name+' temporarily to your prefered suppliers list', placement:'leftbottom', offset:'200px' }"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></div>
                      <div class="grid-supplier-bottom-line"></div>
                    </template>
                </div>

                <div slot="rfq-add-step-6" class="rfq-add-step-margin">
                  <div :class="{'rfq-add-step-6-advert d-flex block-with-advert': isShowAdvert}">
                    <div class="date-and-time-required-block">
          <div class="header">
            Date & Time Quote Required
          </div>
          <div class="datetime-field row no-gutters">
            <div class="col datetime-field-name align-self-center">Select Date</div>
            <div class="col">
              <div class="d-flex">
                <div class="datetime-field-value date-input input-group date">
                  <date-picker v-model="rfq.datetime.date"></date-picker>
                </div>
                <div class="datetime-field-present">Today is: <span>{{ getDayOfWeek(rfq.datetime.date) }}</span></div>
              </div>
            </div>
          </div>
          <div class="datetime-field row no-gutters" :class="{'has-error': selectTimeInvalid}">
            <div class="col datetime-field-name align-self-center">Select Time</div>
            <div class="col">
              <div class="d-flex">
                <div class="select-time-field-value time-input input-group date">
                  <multiselect
                      ref="fieldSelectTime"
                      v-model="selectTime"
                      :options="selectTimeOptions"
                      :showLabels="false"
                      :option-height="32"
                      :max-height="203"
                      :close-on-select="true"
                      :disabled="!isSelectTimeActive"
                      @select="selectedTime"
                  >
                  </multiselect>
                </div>
                <div class="datetime-field-present">Current Time: <span>{{ currentTime }}</span></div>
              </div>
              <div class="invalid-time">This time is earlier than today's actual time. Choose another
              </div>
            </div>
            <div class="date-time-quote-repuired-top">
              <div
                  class="wizard__buttons date-time-quote-repuired-top-btn"
                  :class="{'wizard__buttons-absolute': $refs.RFQAddStepper && $refs.RFQAddStepper.currentStep === 2}">
                <!--                        <button v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep === 0"-->
                <!--                                style="width: 230px; display: none;" @click="partsbridgeImport"-->
                <!--                                class="partsbridge-interface btn btn-primary form-control">-->
                <!--                            Import From PartsBridge-->
                <!--                        </button>-->

                <button
                    ref="RFQAddWizardButtonNext"
                    v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep != 5 && !($refs.RFQAddStepper.currentStep === 0 && rfq.type === 'PDF')"
                    class="btn btn-primary pull-right button-next"
                    type="button"
                    @click="goNext"
                    :tabindex="4"
                >
                  Next
                </button>
                <span
                    style="float: right;" class="d-inline-block"
                    :class="{
                            'clickable': !_isEmpty(rfq.suppliers)
                          }"
                    @click="submitRFQ"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip submit-quote-tooltip', title: _isEmpty(rfq.suppliers) ? 'No supplier has been selected' : '', placement:'leftbottom'}">
                         <button
                             v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep == 5"
                             style="pointer-events: none;"
                             class="btn btn-primary pull-right button-next"
                             type="button"
                             :disabled="_isEmpty(rfq.suppliers)"
                         >
                          Submit Quote
                        </button>
                      </span>
                <button
                    class="btn btn-outline-primary pull-right button-cancel"
                    type="button"
                    @click="clickCancel"
                >
                  Cancel
                </button>
                <button v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep != 0"
                        class="btn btn-outline-primary-light pull-right button-back"
                        type="button"
                        :disabled="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep == 1 && isAddSuppliers"
                        @click="$refs.RFQAddStepper.goBack()"
                >
                  Back
                </button>

              </div>

            </div>
          </div>

                    <div class="header summary-header">
                        Summary
                    </div>
                    <div class="vehicle-info-block block">
                        <div class="block-header">
                            Vehicle Details
                        </div>
                        <div class="block-body bg-white">
                            <div class="form-group row">
                                <label class="col col-form-label">Quoting Package Number</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.QPNumber }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Rego</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.rego }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">VIN Number</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.vin }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Build Date</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.dom }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Make</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.make }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Model</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.model }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Series</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.series }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Colour</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.colour }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Body</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.body }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Transmission</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.transmission }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col col-form-label">Odometer</label>
                                <div class="col-sm-8 summary-text">
                                    {{ rfq.details.odometer }}
                                </div>
                            </div>
                            <div class="form-group row">
                              <label class="col col-form-label">Customer Claim Nbr</label>
                <div class="col-sm-8 summary-text">{{ rfq.details.claimNumber }}</div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Estimator</label>
                <div class="col-sm-8 summary-text">
                  {{ rfq.estimator }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Comments</label>
                <div class="col-sm-8 summary-text">
                  <template v-if="!rfq.isSendAsDirectOrder && !rfq.askForPartsDiagram">
                                    <textarea
                                        ref="addRfqVehicleDetailsComments"
                                        :readonly="isAddSuppliers"
                                        v-on:keyup.up="changePositionByArrowKey('addRfqVehicleDetailsEstimator',$event)"
                                        v-on:keydown.tab="changePositionByArrowKey('RFQAddWizardButtonNext',$event)"
                                        type="text" class="form-control" placeholder="" rows="8"
                                        v-model="rfq.details.comments">
                                    </textarea>
                                  </template>
                                  <template v-else>
                                    <div class="" v-html="computedComments"></div>
                                  </template>
                </div>
              </div>
            </div>
          </div></div>
                    <div v-if="isShowAdvert" class="img-code-paint-container" style="margin-left: 30px; margin-top: 59px;">
                      <img v-if="rfq.details.colour === 'B74P'"  src="@/assets/img/paints/B74P.png">
                      <img v-else-if="rfq.details.colour === '46V'" src="@/assets/img/paints/46V.png">
                      <img v-else-if="rfq.details.colour === '41V'" src="@/assets/img/paints/41V.png">
                      <img v-else-if="rfq.details.colour === 'NH883P'" src="@/assets/img/paints/NH883P.png">
                    </div>
                  </div>

        </div>
        <template v-slot:WizardButtons>
          <div
              :style="{top: rfq.parts.length > 0 ? 272 + 56 * rfq.parts.length + 'px': '272px', right: buttonsTop + 'px'}"
              class="wizard__buttons"
              :class="{
                            'wizard__buttons-absolute': $refs.RFQAddStepper && $refs.RFQAddStepper.currentStep === 2,
                            'rfq-add-step-4-advert-buttons': $refs.RFQAddStepper && $refs.RFQAddStepper.currentStep === 4 && isShowAdvert,
                    }">
            <!--                        <button v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep === 0"-->
            <!--                                style="width: 230px; display: none;" @click="partsbridgeImport"-->
            <!--                                class="partsbridge-interface btn btn-primary form-control">-->
            <!--                            Import From PartsBridge-->
            <!--                        </button>-->

            <button v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep === 0"
                    style="width: 230px; display: none;" @click="partsbridgeImport"
                    class="partsbridge-interface btn btn-primary form-control">
              Import From PartsBridge
            </button>
            <button
                ref="RFQAddWizardButtonNext"
                v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep != 5 && !($refs.RFQAddStepper.currentStep === 0 && rfq.type === 'PDF')"
                class="btn btn-primary pull-right button-next"
                type="button"
                @click="goNext"
                :tabindex="4"
            >
              Next
            </button>
            <span
                style="float: right;" class="d-inline-block"
                :class="{
                            'clickable': !_isEmpty(rfq.suppliers)
                          }"
                @click="submitRFQ"
                v-b-tooltip.hover="{customClass: 'ps-tooltip submit-quote-tooltip', title: _isEmpty(rfq.suppliers) ? 'No supplier has been selected' : '', placement:'leftbottom'}">
                         <button
                v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep == 5"
                style="pointer-events: none;"class="btn btn-primary pull-right button-next"
                type="button"
                :disabled="_isEmpty(rfq.suppliers)"
            >
              Submit Quote
            </button></span>
            <button
                class="btn btn-outline-primary pull-right button-cancel"
                type="button"
                @click="clickCancel"
            >
              Cancel
            </button>
            <button v-if="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep != 0"
                    class="btn btn-outline-primary-light pull-right button-back"
                    type="button"
                    :disabled="$refs.RFQAddStepper && $refs.RFQAddStepper.currentStep == 1 && isAddSuppliers"
                    @click="$refs.RFQAddStepper.goBack()"
            >
              Back
            </button>
          </div>

        </template>
      </good-wizard>

      <b-modal ref="addNotRegisteredSupplier"
               @ok="createNonRegisteredSupplier"
               size="add-not-registered-supp-modal"
               :title="'Add Non-Registered Supplier - ' + supplierModalType">
        <template v-slot:modal-backdrop class="my-backdrop"></template>
        <div class="form-container">
          <form>
            <div class="pr-3 pl-3">
              <div class="pl-0 pb-3 font-weight-bold">Non-Registered supplier details</div>
              <div>
                <div class="form-group row">
                  <label style="padding-left: 0px" class="col col-form-label">Supplier Business Name</label>
                  <input
                      type="text"
                      placeholder="Business Name"
                      v-model="nonReturnableSupplier.bussinesName"
                      class="form-control col-6"
                  />
                </div>
                <div class="form-group row multiselect-height">
                  <label style="padding-left: 0px" class="col col-form-label">Set the Part Type/s they supply</label>
                  <multiselect
                      :options="getterSupplierBusinessTypes"
                      :track-by="'value'"
                      :label="'label'"
                      v-model="nonReturnableSupplier.partTypes"
                      :multiple="true"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Set the Part Type"
                      class="col-6 pr-0 multiselect-height"
                  />
                </div>
                <div class="form-group row">
                  <label style="padding-left: 0px" class="col col-form-label">Vehicle Parts Type</label>
                  <multiselect
                      :options="[{
                        label: 'CAR - Parts Supplier',
                        value: 'CAR',
                      },
                      {
                        label: 'TRUCK - Parts Supplier',
                        value: 'TRUCK',
                      }
                      ]"
                      :track-by="'value'"
                      v-model="nonReturnableSupplier.vehiclePartsType"
                      :label="'label'"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Choose Vehicle Type"
                      class="col-6 pr-0"
                  />
                </div>
                <div class="form-group row">
                  <label style="padding-left: 0px" class="col col-form-label">Suppliers Makes</label>
                  <multiselect
                      :options="getMakes()"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      @select="onSelectMake"
                      v-model="nonReturnableSupplier.makes"
                      :multiple="true"
                      :close-on-select="true"
                      placeholder="Choose Make/s"
                      class="col-6 pr-0"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <template v-slot:modal-footer="{ ok, cancel }">
          <template>
            <b-button @click="cancel()" class="input-btn cancel mr-2" >Cancel</b-button>
            <b-button @click="ok()" :disabled="!isCanInviteSupplier" class="btn btn-primary pull-right button-next btn-modal-invite-supplier btn-send-modal-invite-supplier">Create</b-button>
          </template>
        </template>
      </b-modal>

      <b-modal id="custom-modal-invite-supplier" ref="inviteModal" centered size="custom-invite-supplier"
               :hide-footer="true" title="Invite a Supplier - Temporarily">
        <div class="modal-invite-supplier form-container">
          <h5 class="mt-3" style="padding-left: 15px">Add the suppliers details you want to temporarily invite</h5>
          <form class="mt-5">
            <div class="form-group row">
              <label class="col col-form-label">Supplier Business Name</label>
              <input
                  type="text"
                  v-model="tempSupp.businessName"
                  placeholder="Business Name"
                  class="form-control col-6"
              />
            </div>
            <div class="form-group row">
              <label class="col col-form-label">Set the Type for Pricing</label>
              <multiselect
                  :options="supplierBusinessTypes"
                  v-model="tempSupp.supplierType"
                  :track-by="'value'"
                  :label="'label'"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Set the Part Type"
                  class="col-6 pr-0"
              />
            </div>
            <div class="form-group row">
              <label class="col col-form-label">Supplier Email</label>
              <input
                  type="text"
                  v-model="tempSupp.email"
                  :class="{'error': !isCheckEmail || !isCheckEmail2}"
                  @input="checkEmail"
                  placeholder="Email to send invite"
                  class="form-control col-6"
              />
              <div style="display: flex; justify-content: end; margin-right: 47px;" class="w-100 text-right"
                   v-show="!isCheckEmail && tempSupp.email !== ''">
                          <span class="error-text">
                            <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is incorrect
                          </span>
              </div>
              <div style="display: flex; justify-content: end; margin-right: 47px;" class="w-100 text-right"
                   v-show="!isCheckEmail2 && tempSupp.email !== ''">
                          <span class="error-text">
                            <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp; This email is already in use
                          </span>
              </div>

            </div>
          </form>
        </div>
        <template>
          <div class="row w-100 justify-content-end" style="margin-top: 50px;">
            <b-button class="input-btn cancel" @click="closeModal">Cancel</b-button>&nbsp;
            <b-button :disabled="!isCanSend" class="input-btn" @click="sendInviteSupplier">Send</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<style>
.ask-for-parts-diagram-section {
  display: none;
}
.rfq-add-page .ask-for-parts-diagram-section {
  display: block !important;
}

.submit-quote-tooltip {
  top: -55px !important;
  left: 10px !important;
}

.submit-quote-tooltip .tooltip-inner {
  border-radius: 10px 10px 0px 10px !important;
}

.tier-checkbox {
  margin-top: 0px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  margin-left: 5px;
  width: 35px !important;
}

.V3 .theme-repairer .rfq-add-page .tile-checkbox-icon {
  height: 18px !important;
  width: 18px !important;
  border-color: #d3d3d3 !important;
}

.V3 .theme-repairer .rfq-add-page .list-select-checkbox .icon {
  border-color: #d3d3d3 !important;
}

.V3 .theme-repairer .rfq-add-page .tile-checkbox-label > input[type="checkbox"]:checked + span.icon ,
.V3 .theme-repairer .rfq-add-page .list-select-checkbox > input[type="checkbox"]:checked + span.icon {
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.V3 .theme-repairer .rfq-add-page .list-select-checkbox > input[type="checkbox"]:checked + span.icon i{
  color: white !important;
}

.V3 .theme-repairer .rfq-add-page .tile-checkbox-label > input[type="checkbox"]:checked + span.icon i{
  color: white !important;
}

.tile-checkbox-icon .bx-check {
  font-size: 17px !important;
}

@media (max-width: 1700px) {
  .block-with-advert {
    flex-direction: column;
  }
}
.estimator-field-upper {
  text-transform: uppercase;
}

.direct-text {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

.send-direct-order-comment-block {
  width: 100%;
  min-height: 139px;
  box-sizing: border-box;
  border: 1px solid rgba(28, 31, 57, 0.25);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  flex-direction: column;
}

.rfq-add-page .wizard__buttons-absolute {
  position: absolute;
  right: 269px;
  margin: 0 !important;
  padding: 0px !important;
}

.rfq-add-page .rfq-add-step-5 .multiselect-state .multiselect__content-wrapper {
  width: 200px;
  left: -100px;
}

.rfq-add-page .rfq-add-step-5 .multiselect-type .multiselect__content-wrapper {
  width: 112px;
  left: 0px;
}

.modal-add-not-registered-supp-modal {
  width: 500px;
  margin: 0 auto;
  height: 0 auto;
}

.modal-add-not-registered-supp-modal .btn-modal-invite-supplier {
  padding: 10px 26px;
  margin-left: 10px;
}

.modal-add-not-registered-supp-modal .btn-send-modal-invite-supplier {
  border-color: #79B1BB !important;
  font-weight: bold;
  border-radius: 3px;
  background-color: #79B1BB !important;
  color: #fff !important;
}

.modal-add-not-registered-supp-modal .btn-send-modal-invite-supplier:hover {
  color: #000000 !important;
}

.modal-add-not-registered-supp-modal .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-add-not-registered-supp-modal {
  max-width: 500px !important;
  margin: 15rem auto !important;
}

.modal-add-not-registered-supp-modal .modal-body {
  padding-bottom: 0;
}

.modal-add-not-registered-supp-modal .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-add-not-registered-supp-modal .modal-header {
  background-color: #79B1BB;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-add-not-registered-supp-modal .modal-content {
  border-radius: 3px !important;
}

.modal-add-not-registered-supp-modal .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.modal-add-not-registered-supp-modal .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-add-not-registered-supp-modal .input-btn:focus {
  box-shadow: none !important;
}
</style>

<style scoped>
.summary-header{
  margin-top: 70px !important;
}
.rfq-add-step-margin{
  position: relative;
}
.date-time-quote-repuired-top{
  position: absolute;
  top: 120px;
  right: -15px;
}
.datetime-field-present{
  margin-top: 6px;
  margin-left: 40px;
  font-size: 14px;
}
.datetime-field-present span{
  font-weight: bold;
}
.form-check-inline-position{
  position: relative;
  bottom: 10px;
}
.header-width-90px{
  width: 90px;
}
.icon .bx-check{
  background-color: #78b0ba;
  border-radius: 3px;
  color: white !important;
  height: 24px;
}
.icon.tile-checkbox-icon .bx-check{
  height: 18px;
}
.image-block >>> .col-4{
  flex: 0 0 20%;
}
.comment-textarea {
  padding: 0px;
  margin-top: 5px;
  border: none;
  color: #1c1f39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  resize: none;
}
.in-stock-comment-lvl {
  color: #2DCA73 !important;
}

.ex-stock-comment-lvl {
  color: #F59345 !important;
}

.red-comment-lvl {
  color: red;
}

.block-with-advert .date-and-time-required-block {
  width: 100%;
}

.rfq-add-step-4-advert-buttons {
  padding-right: 300px !important;
}

@media (max-width: 1699.99px) {
  .block-with-advert .img-code-paint-container {
   display: none;
  }

  .V3 .rfq-add-page .wizard__body .rfq-add-step-3-advert {
    max-width: 800px;
    width: 800px;
    margin: 0 auto;
  }

  .V3 .rfq-add-page .wizard__body .rfq-add-step-6-advert {
    width: 572px;
  }

  .block-with-advert .date-and-time-required-block {
    width: auto;
  }

  .rfq-add-step-4-advert-buttons {
    padding-right: 15px !important;
  }
}

.img-code-paint-container {
  max-width: 385px;
  width: 100% !important;
  margin-top: 107px;
  margin-left: 15px;
}
.img-code-paint-container img {
}

.import-loading-text {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 30px 0px 8px 0px;
}

i.bx-trash {
  font-size: 20px;
  color: #FF5E5E;
  cursor: pointer;
}

i.bx-download {
  font-size: 20px;
  color: #79B1BB;
  cursor: pointer;
  margin-right: 10px;
}

i.bx-camera {
  font-size: 42px;
  color: #79B1BB;
  cursor: pointer;
}

.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-card {
  padding: 10px 15px;
  background: #FFFFFF;
  color: #79B1BB;
}

.V3 .wizard__buttons .button-card-estimate {
  padding: 10px 15px;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  min-width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #79B1BB;
  color: #79B1BB;
  width: 83px;
}

@media (max-width: 823px) AND (min-width: 604px) {
  /*.V3 .rfq-add-page .wizard__buttons {*/
  /*    padding-left: 15px;*/
  /*    padding-right: 15px;*/
  /*    width: auto;*/
  /*    min-width: auto;*/
  /*}*/
  /*.V3 .compact-sidebar .rfq-add-page .wizard__buttons {*/
  /*    width: 722px;*/
  /*    margin: 0 auto;*/
  /*    min-width: 722px;*/
  /*    padding-left: 0px;*/
  /*    padding-right: 0px;*/
  /*}*/
}

@media (max-width: 603px) {
  .V3 .rfq-add-page .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }
}

@media (max-width: 512px) {
  .V3 .rfq-add-page .wizard__buttons button {
    margin-bottom: 10px;
    float: none !important;
    margin-left: 0px;
    margin-right: 0px;
    display: block;
  }
}

.datetime-field .datetime-field-value {
  position: relative;
  cursor: pointer;
}

.datetime-field .datetime-field-value.date-input i.bx-calendar-week,
.datetime-field .datetime-field-value.time-input i.bx-time-five {
  display: block;
  opacity: 0.5;
  position: absolute;
  top: 8px;
  font-size: 22px;
  left: 10px;
  border-right: 2px solid rgba(28, 31, 57, 0.25);
  padding-right: 9px;
}

.datetime-field .datetime-field-value.date-input {
  width: 35%;
}

.datetime-field .datetime-field-value.time-input,
.datetime-field .select-time-field-value.time-input {
  width: 35%;
}

.datetime-field .select-time-field-value.time-input {
  z-index: 9;
}

.datetime-field .datetime-field-value.date-input input,
.datetime-field .datetime-field-value.time-input input {
  opacity: 1;
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  padding-left: 50px;
}

.datetime-field i {
  z-index: 9;
}

.datetime-field .datetime-field-value.date-input i.bx-chevron-down,
.datetime-field .datetime-field-value.time-input i.bx-chevron-down {
  display: block;
  opacity: 0.25;
  position: absolute;
  top: 8px;
  font-size: 22px;
  right: 10px;
  padding-left: 9px;
}

.datetime-field .input-group-addon {
  padding: 0px;
  border-radius: inherit;
}

.datetime-field .input-group > .form-control {
  position: inherit;
}

.rfq-add-page >>> .disabled-supplier {
  opacity: 0.6;
  cursor: default !important;
}

.rfq-add-page >>> .selected-supplier {
  background-color: rgb(228, 239, 241) !important;
}

.rfq-add-page >>> .disabled-supplier input {
  opacity: 0.6;
}

.rfq-add-page .vehicle-details-block {
  width: 572px;
  margin-left: 0px;
  margin-top: 0px;
}

.btn-show-all {
  cursor: pointer;
  padding: 10px 26px;
  color: #79B1BB;
  border: 1px solid #79B1BB;
  border-radius: 0.25rem;
  font-weight: bold;
  white-space: nowrap;
}

.btn-apple-filter-by {
  color: #79B1BB;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid #79B1BB;
  padding: 10px 26px;
  border-radius: 0.25rem;
}

.rfq-add-step-5-search {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bx-list-ul {
  font-size: 28px;
  margin-right: 5px;
  color: #e3e4e7;
  cursor: pointer;
  }

.icon-active {
  color: #4079ac;
  cursor: default !important;
}

.icon-svg-active {
  fill: #4079ac !important;
  cursor: default !important;
}

.multiselect-state {
  width: 100px;
}

@media (min-width: 1024px) {
  .rfq-add-step-3-table-head {
    /*position: sticky;*/
    /*top: 234px;*/
    z-index: 9999;
    background: #ffffff;
  }
}

.rfq-add-step-3-manual {
  max-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  margin-left: -200px;
}

.modal-dialog {
  width: 500px;
  margin: 0 auto;
  height: 0 auto;
}

.rfq-add-step-5-mobile-table {
  width: 100%;
}

@media (max-width: 1440px) {
  .rfq-add-step-3-manual {
    width: 100%;
    margin-left: 0;
  }
  .rfq-add-step-3-manual .table-responsive {
    overflow-x: scroll;
  }
}
@media (max-width: 1024px) {
  .wizard-header-1000px-down{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .wizard-header-button-supplier-in{
    margin: 20px 0;
  }
}
@media (max-width: 768px) {
  .date-time-quote-repuired-top{
    position: absolute;
    top: 120px;
    left: 0;
  }
}

</style>

<style>
@media (max-width: 1450px) {
  .V3 .rfq-add-page .wizard__body .rfq-add-step-3 {
    max-width: none !important;
    width: auto !important;
    overflow-x: scroll;
  }
  .rfq-add-page .wizard__buttons-absolute {
    margin-right: 15px !important;
  }
}

@media (max-width: 1000px) {
  .rfq-add-step-3-manual {
    max-width: 100% !important;
    width: 100% !important;
  }
  .rfq-add-page .wizard__buttons-absolute {
    position: static;
    margin-bottom: 20px !important;
    height: 30px;
  }
  .rfq-add-page .rfq-add-step-3 .vh-height-75 {
    max-height: 30vh
  }
  .rfq-add-step-3-manual .manual-part-list {
    display: flex;
    flex-direction: column;
  }
  .rfq-add-page .vehicle-details-block {
    width: auto !important;
  }
  .rfq-add-step-3-manual .part-list {
    width: 101%;
  }
  .rfq-add-page .part-type-aftm {
    white-space: nowrap !important;
  }
}

@media (max-width: 768px) {
  .rfq-add-step-3-manual .part-list {
    width: 95vw;
  }
  .wizard__body {
    min-width: auto !important;
  }
  .rfq-add-page .select-time-field-value .multiselect {
    min-width: 130px;
  }
}

@media (max-width: 950px) {
  .rfq-add-step-5-mobile {
    width: 100%;
  }
  .rfq-add-step-5-mobile-wrapper {
    width: 100%;
    overflow-x: scroll;
  }
  .rfq-add-step-5-mobile-table {
    width: 880px !important;
  }
}

@media (max-width: 420px) {
 .rfq-add-step-5-mobile .wizard-header-button-supplier-in {
  width: 100%;
 }
}


.multiselect__content-wrapper{
  max-width: 223px;
}

.form-group .multiselect .multiselect__tags{
  min-height: 32px;
  height: auto;
}
</style>

