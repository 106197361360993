<template>
  <div class="credit-view-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>{{ pageTitle }} <span class="autosave">{{ autoSaveMessage }}</span></h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item home">
              <router-link to="/s">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <span style="cursor: pointer" @click="viewCredits">View Credit Management</span>
              <!--          <router-link :to="'/s/credits'">View Credit Management</router-link>-->
            </li>
            <li class="breadcrumb-item active">
              <span class="d-none d-lg-inline-block">View Credit Details</span>
            </li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <window-notification></window-notification>
          <search-filter
              type="estimates"
              v-model="searchFilter"
              :filterValue="searchFilter"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              :placeholder="'Search'"
              :isSearch="true"
              :additional-menu="additionalMenu"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :isMyltipleOptions="true"
              :isMyltiple="true"
              :optionsLength="8"
          />
        </div>
      </div>

      <!-- mobile options -->
      <div class="page-header-nav-options dropdown-actions">
        <b-dropdown v-if="credit.status === 'Denied' || credit.status === 'Approved' || credit.status === 'Cancelled'" id="credit-view-options-mobile" right text="Options" class="dropdown-options options-mobile"
                    :class="mobileOptionsClass">
          <template v-slot:button-content>Actions
            <span class="dropdown-arrow">
                            <i class='bx bx-down-arrow'></i>
                        </span>
          </template>
          <b-dropdown-item v-if="credit.status === 'Denied' || credit.status === 'Approved' || credit.status === 'Cancelled'"  @click="reinstateCredit">
            Reinstate Credit Review
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="credit-view-body">
      <tabs :custom-counts="computedTotalCounts" :options="{useUrlFragment:true}">
        <tab name="Credit Part List" id="creditPartList" :class-name="'credit-view-tab-1'">
          <div class="credit-additional-block d-flex">
            <div class="credit-title col-sm-5">
              <div class="credit-title__item">
                <span class="ps-label-value__label">Supplier :</span>
                <span class="ps-label-value__value">{{ credit.supplier.name }}</span>
              </div>
              <div class="credit-title__item">
                <span class="ps-label-value__label">Type :</span>
                <span class="ps-label-value__value">{{ getTypes(credit.supplier.types) }}</span>
              </div>
              <div class="credit-title__item">
                <span class="ps-label-value__label">Parts Dept PH :</span>
                <span class="ps-label-value__value">{{ credit.supplier.partsDepartmentPhone }}</span>
              </div>
            </div>
            <div class="credit-additional-block d-flex col-sm-7" style="justify-content: flex-end;">
              <div class="credit-title">
                <div class="credit-title__item">
                  <span class="ps-label-value__label">Order placed :</span>
                  <span class="ps-label-value__value">{{ formatDateTime(credit.order.createdOn) }}</span>
                </div>
                <div class="credit-title__item">
                  <span class="ps-label-value__label">Order Number :</span>
                  <span class="ps-label-value__value">
                    <a @click.prevent="goToOrder" :href="'#'">{{ credit.order.number }}</a></span>
                </div>
                <div class="credit-title__item">
                  <span class="ps-label-value__label">VIN :</span>
                  <span class="ps-label-value__value">{{ credit.rfq.vehicle.vin }}</span>
                </div>
              </div>
              <div class="credit-title">
                <div class="credit-title__item">
                  <span class="ps-label-value__label">Business :</span>
                  <span class="ps-label-value__value">{{ credit.repairer.name }}</span>
                </div>
                <div class="credit-title__item">
                  <span class="ps-label-value__label">Address :</span>
                  <span class="ps-label-value__value">{{ credit.address }}</span>
                </div>
                <div class="credit-title__item">
                  <span class="ps-label-value__label">Estimator :</span>
                  <span class="ps-label-value__value">{{ credit.rfq.estimator }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="wizard__divider"></div>

          <div class="d-flex justify-content-end credit-order-numbers">
            <div class="d-flex justify-content-between align-items-center credit-order-numbers-item">
              <span class="credit-order-numbers-title">Order Nbr</span>
              <b-form-input v-model="credit.order.number" :disabled="true" :readonly="true" class="form-control"></b-form-input>
            </div>
            <div class="d-flex justify-content-between align-items-center credit-order-numbers-item">
              <span class="credit-order-numbers-title">Credit Nbr</span>
              <b-form-input v-model="credit.manualNumber" @change="saveManualNumber" class="form-control"></b-form-input>
            </div>
          </div>

          <b-table
              responsive
              hover
              selectable
              ref="creditPartsTable"
              :items="creditPartsItems"
              :tbody-tr-class="rowClass"
              :select-mode="'range'"
              :fields="partsFields"
              :id="'credit-parts-table'"
              :primary-key="'id'"
              @row-clicked="handleRowClicked"
              @row-selected="handleRowSelected"
              @row-contextmenu="handleRightClick"
              class="mb-15">
            <template v-slot:table-colgroup="scope">
              <col class="credit-view__row credit-view__row--xl">
              <col class="credit-view__row credit-view__row--sm">
              <col class="credit-view__row credit-view__row--sm">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--sm">
              <col class="credit-view__row credit-view__row--lg">
              <col class="credit-view__row credit-view__row--xs">
            </template>

            <template v-slot:head(name)="data">
              <div class="d-flex">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         v-model="isCheckedAll"
                         type="checkbox"
                  >
                  <span class="icon clickable"><i
                      class='bx bx-check'></i></span>
                </label>
                <span style="padding-top: 12px;">Part Name</span>
              </div>
            </template>

            <template v-slot:cell(name)="row">
              <div class="d-flex">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         @change="onCheckboxChange(row.item, row.item.isChecked)"
                         v-model="row.item.isChecked"
                         type="checkbox"
                  >
                  <span class="icon clickable" style="transform: translateX(10px)"><i
                      class='bx bx-check'></i></span>
                </label>
                <div class="d-flex justify-content-between">
                  <span class="credit-table-name-text">{{ row.item.name }}</span>
                  <div class="table-name-border-bottom"></div>
                </div>
              </div>
            </template>

            <template v-slot:cell(qtyOrdered)="row">
              {{row.value}}
            </template>
            <template v-slot:cell(qtyReceived)="row">
              {{Number(row.value) - Number(row.item.qtyOrdered)}}
            </template>
            <template v-slot:cell(status)="row">
              <span
                  v-bind:class="{'color_denied': row.item.status === 'Denied','color_approved': row.item.status === 'Approved', 'color_review': row.item.status === 'UnderReview' || row.item.status === 'Cancelled'}">
                <template>{{ viewCreditStatus(row.item.status) }}</template>
              </span>
            </template>
            <template v-slot:cell(amount)="row">
              <span v-if="Number(row.value)">-</span>
              <span>{{ row.value | formatMoney }}</span>
            </template>
            <template v-slot:cell(price)="row">
              <span v-if="Number(row.value)">-</span>
              <span>{{ row.value | formatMoney }}</span>
            </template>
            <template v-slot:cell(rrp)="row">
              <span>{{ row.value | formatMoney }}</span>
            </template>
            <template v-slot:cell(amountApproved)="row">
              <span v-if="row.value>0">-{{ row.value | formatMoney }}</span>
            </template>
            <template v-slot:cell(dateCreditCreated)="row">
              <span>{{ row.value | formatDatetime }}</span>
            </template>
            <template v-slot:cell(orderNumber)="row">
              <span>{{ credit.order.number }}</span>
            </template>
            <template v-slot:head()="data">
              <div>{{ data.label }}</div>
            </template>
            <template v-slot:row-details="row">
              <div class="td-wrapper">
                <credit-details
                    :ref="'credit-details-' + row.item.id"
                    :creditPart="row.item"
                    @auto-save="changedCreditComment"
                    @closeContext="closeContextForReasonAction"
                    @openContext="openMenuForReasonAction"
                    @openRowContext="event => handleRightClick(row, row.index, event)"
                >
                </credit-details>
              </div>
            </template>
          </b-table>
        </tab>
        <tab name="Images" id="images">
          <file-upload
              v-model="credit.images"
              :params="{
                creditId: credit.id
              }"
              :api-urls="{uploadFile: '/ir/supplier/credit/upload-file',main: '/ir/supplier/credit/'}"
              :object-id="Number(credit.id)"
              type="image"></file-upload>
        </tab>
        <tab name="Activity" id="activity">
          Activity page
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-4">
            <!--            <a v-if="isEditable" class="btn btn-primary clickable d-none d-lg-inline-block"-->
            <!--               @click="clickSave"-->
            <!--               style=" vertical-align: middle; padding-left:25px; padding-right:25px;">-->
            <!--              Save-->
            <!--            </a>-->
          </div>
          <!-- desktop options -->
          <div class="tabs-nav-right-options">
            <chat v-if="true" @closeChat="onCloseChat" :isShowChatInit="!!$route.params.isNeedOpenChat" :userName="credit && credit.repairer && credit.repairer.name ? credit.repairer.name : ''" :messages="chatMessages" :is-chatter-online="chatRepairerIsOnline" :typers="chatTypers" @onChatMessageSubmit="onChatMessageSubmit" @onTyping="onChatInputTyping"></chat>
            <b-dropdown v-if="credit.status === 'Denied' || credit.status === 'Approved' || credit.status === 'Cancelled'" id="credit-view-options-desktop" text="Options" class="dropdown-options dropdown-actions">
              <template v-slot:button-content>
                Actions
                <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
              </template>
              <b-dropdown-item v-if="credit.status === 'Denied' || credit.status === 'Approved' || credit.status === 'Cancelled'"  @click="reinstateCredit">
                Reinstate Credit Review
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </tabs>
    </div>
    <vue-context ref="menuCredit"
                 :lazy="true"
                 @close="onCloseMenuCreditContext"
                 :close-on-click="false"
                 :closeOnScroll="true">
      <template slot-scope="item">
        <b-button-toolbar v-if="item && item.data && item.data.row" key-nav>
          <b-button-group vertical class="credit-view__context-menu">
            <template>
              <div
                  v-if="isCanSplitCredit"
                  @mouseover="isShowSplitCredit = true"
                  @mouseleave="isShowSplitCredit = false">
                <b-button style="width: 118px" class="text-left font-12 font-inter--semibold credit-view-context-menu__item" variant="outline-primary" block>
                  <span v-if="selectedRow.length == 1">Split Credit</span>
                  <span v-else>Split Credit Order</span>
                </b-button>

                <div v-if="isShowSplitCredit">
                  <div :style="{top: getCountForContext() + 'px'}" :class="{'credit-cancel-reason-only': true}"
                       class="form-control split-credit-menu">
                    <p class="pt-3 pb-5">Split Credit Order - {{credit.order.number}}</p>
                    <div v-for="(itm, ind) in selectedRow" :key="ind" class="pb-3">
                      <p>Part Name: <span class="font-weight-normal">{{ itm.name }}</span></p>
                      <p>Part Nbr: <span class="font-weight-normal">{{ itm.number }}</span></p>
                      <p>New Credit Ref Nbr: <span class="font-weight-normal">{{newCreditRefNumber}}</span></p>
                    </div>
                    <div class="d-flex justify-content-end pb-3">
                      <button @click="onClickCancel" class="btn btn-secondary mr-3">Cancel</button>
                      <button @click="onClickSplit" class="btn btn-primary split-button">Split</button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="selectedRow.length <= 1">
              <template v-if="!item.data.row._showDetails">
                <b-button @click="toggleDetails(item.data.row)"
                          class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                          variant="outline-primary" block>
                  View Credit
                </b-button>
                <b-button
                    v-if="!item.data.row.isEditCredit && (item.data.row.status === 'Denied' || item.data.row.status === 'Cancelled') "
                    @click="editCreditPart2(item.data.row)"
                    class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                    variant="outline-primary" block>
                  Edit Credit
                </b-button>
              </template>
              <b-button v-if="item.data.row.status === 'UnderReview'"
                        @click="approveCreditPart(item.data.row)"
                        class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                        variant="outline-primary" block>
                Approve Credit
              </b-button>
              <b-button v-if=" item.data.row.status === 'UnderReview'"
                        @click="denyCreditPart(item.data.row)"
                        class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                        variant="outline-primary" block>
                Deny Credit
              </b-button>
              <b-button
                  v-if="!item.data.row.isEditCredit && item.data.row._showDetails && (item.data.row.status === 'Denied' || item.data.row.status === 'Cancelled') "
                  @click="editCreditPart(item.data.row)" class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                  variant="outline-primary" block>
                Edit Credit
              </b-button>
              <!--            <b-button v-if=" item.data.row.status === 'UnderReview'"-->
              <!--                      @click="denyCreditPart(item.data.row)"-->
              <!--                      class="text-left font-12 font-inter&#45;&#45;semibold" variant="outline-primary" block>-->
              <!--              Cancel Credit-->
              <!--            </b-button>-->
              <b-button
                  v-if="item.data.row.status !== 'Denied' && (item.data.row.status === 'Cancelled' || item.data.row.cancelCreditReason)"
                  @click="undoCancelCreditPart(item.data.row)" class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                  variant="outline-primary" block>
                Undo Cancellation
              </b-button>
              <div
                  v-if="item.data.row.status === 'UnderReview' && !item.data.row.cancelCreditReason"
                  @mouseover="isShowReasonForCancellation = true"
                  @mouseleave="isShowReasonForCancellation = false">

                <b-button style="width: 118px" class="text-left font-12 font-inter--semibold credit-view-context-menu__item" variant="outline-primary"
                          block>
                  Cancel Credit
                </b-button>

                <div v-if="isShowReasonForCancellation" class="">
                <textarea
                    v-model="item.data.row.cancelCreditReasonDraft"
                    placeholder="Reason for Cancellation"
                    :class="{
                      'credit-cancel-reason-only': true || item.data.row.isEditCredit
                    }"
                    @keydown.enter.prevent="saveCancelCredit(item.data.row)"
                    class="form-control credit-cancel-reason">
                </textarea>
                  <i
                      @click="saveCancelCredit(item.data.row)"
                      :class="{
                      'credit-cancel-save-icon-2': true || item.data.row.isEditCredit
                    }"
                      class="bx bx-save credit-cancel-save-icon clickable">
                  </i>
                </div>
              </div>
            </template>
            <template v-else-if="selectedRow.length > 1">
              <div
                  v-if="isCanCancelCredit"
                  @mouseover="isShowReasonForCancellation = true"
                  @mouseleave="isShowReasonForCancellation = false">

                <b-button style="width: 118px" class="text-left font-12 font-inter--semibold credit-view-context-menu__item" variant="outline-primary"
                          block>
                  Cancel Credit
                </b-button>

                <div v-if="isShowReasonForCancellation " class="">
                <textarea
                    v-model="cancelCreditReasonDraft"
                    style="top: 0px; left: 116px"
                    placeholder="Reason for Cancellation"
                    :class="{
                      'credit-cancel-reason-only': true
                    }"
                    @keydown.enter.prevent="saveCancelCredit()"
                    class="form-control credit-cancel-reason">
                </textarea>
                  <i
                      style="top: 69px"
                      @click="saveCancelCredit()"
                      :class="{
                      'credit-cancel-save-icon-2': true
                    }"
                      class="bx bx-save credit-cancel-save-icon clickable">
                  </i>
                </div>
              </div>
              <b-button
                  v-if="isCanUndoCancellation"
                  @click="undoCancelCreditPart(item.data.row)" class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                  variant="outline-primary" block>
                Undo Cancellation
              </b-button>
              <b-button v-if="isCanApproveCredit"
                        @click="approveCreditPart(item.data.row)"
                        class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                        variant="outline-primary" block>
                Approve Credit
              </b-button>
            </template>
          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>

    <vue-context ref="menuActionForReason" :closeOnScroll="false" @open="isOpenPartContext = true"
                 @close="isOpenPartContext = false">
      <ul slot-scope="item" style="padding: 0px">
        <template>
          <b-button-group vertical class="credit-view__context-menu">
            <b-button
                v-if="item.data
                && item.data.part
                && item.data.isNeedSave
                && item.data.part.reason !== ''"
                @click="saveReason(item.data.part)"
                class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                variant="outline-primary"
                block>
              Save Credit
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && !item.data.part.isEditCredit
                && (item.data.part.reason !== '' && item.data.part.reason !== null)
                && !item.data.isNotNeedEdit"
                @click="editReason(item.data.part)"
                class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                variant="outline-primary"
                block>
              Edit Credit
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && (item.data.part.reason && item.data.part.reason !== '' && item.data.part.reason !== null)"
                @click="deleteReason(item.data.part)"
                class="text-left font-12 font-inter--semibold credit-view-context-menu__item"
                variant="outline-primary"
                block>
              Delete Credit
            </b-button>
          </b-button-group>
        </template>
      </ul>
    </vue-context>

  </div>
</template>

<script>
/*eslint-disable */

import { VueContext } from 'vue-context'
import CreditDetails from './credit-view/credit-view-details'
import FileUpload from '../rfq/rfq-view/file-upload'
import Axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import * as dayjs from 'dayjs'
import expiringStorage from "../../../components/rs-tabs/expiringStorage";
import NProgress from "nprogress";
import Chat from '../../../components/utility/chat/chat'
import {subscribeToCreditChannel, subscribeToRfqChannel} from "@/centrifuge";
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import WindowNotification from "@/components/utility/window-notification.vue";

export default {
  name: 'SuppliersCreditView',
  data () {
    return {
      _chat: null,
      chatMessages: [],
      onlineStatusIntervalId: null,
      chatRepairerIsOnline: false,
      chatTypers: {},
      newCreditRefNumber: '',
      selectedRowIndex: null,
      cancelCreditReasonDraft: null,
      interval: null,
      selectedRow: [],
      isSelectedManualy: false,
      callComputed: 0,
      isHoldCtrl: false,
      isHoldShift: false,
      isOpenPartContext: false,
      isUnderCreditRequest: false,
      autoSaveMessage: '',
      isShowReasonForCancellation: false,
      isShowSplitCredit: false,
      searchFilter: '',
      credit: {
        id: null,
        number: '',
        address: '',
        status: null,
        subStatus: 'None',
        amount: 0,
        manualNumber: null,
        images: [],
        order: {
          id: 0,
          createdOn: null,
          number: '',
        },
        parts: [],
        rfq: {
          id: 0,
          estimator: '',
          vehicle: {
            make: '',
            model: '',
            series: '',
            rego: '',
          },
        },
        repairer: {
          name: '',
          type: '',
          partsDepartmentPhone: '',
          id: 0,
        },
        supplier: {
          name: '',
          type: '',
          partsDeptPH: '',
        },
      },
      isEditable: true,
      partsFields: [
        {
          label: 'Part Name',
          key: 'name',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold part-name-sticky',
          class: 'font-inter',
          stickyColumn: true
        },
        {
          label: 'Part Number',
          key: 'number',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Order Number',
          key: 'orderNumber',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Credit Amt',
          key: 'amount',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable column-cost',
          thClass: 'font-13 font-inter--bold column-cost',
          class: 'font-inter'
        },
        {
          label: 'Qty Order',
          key: 'qtyOrdered',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable column-qty-order',
          thClass: 'font-13 font-inter--bold column-qty-order',
          class: 'font-inter'
        },
        {
          label: 'Qty Rtn',
          key: 'qtyReceived',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable column-qty-rcvd',
          thClass: 'font-13 font-inter--bold column-qty-rcvd',
          class: 'font-inter'
        },
        {
          label: 'Unit List',
          key: 'rrp',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Unit Net',
          key: 'price',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Amount Approved',
          key: 'amountApproved',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Date Credit Created',
          key: 'dateCreditCreated',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Credit Status',
          key: 'status',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable credit-status-td',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },

      ],
      isLoadedPartOrder: false,
    }
  },
  computed: {
    ...mapGetters({
      getterAllCredits: 'supplier/credit/getAllCredits',
      creditsFromUpdate: 'supplier/credit/creditsFromUpdate',
      companyInfo: 'currentCompany/getDetails',
      currentUser: 'currentUser/getCurrentUserInfo',
      getterMultipleBrandIds: 'currentUser/getMultipleBrandIds',
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
      gettersAllRFQs: 'supplier/rfq/getAllRfqs',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllRFQs, (r) => {
        if (_.includes(this.searchFilter, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    unviewedMessagesIds() {
      let ids = [];
      for(let m of this.chatMessages) {
        if (!_.isEmpty(this.getterMultipleBrandIds)) {

          let isNotMyMessage = !_.includes(this.getterMultipleBrandIds, m.companyId)


          if(m.messageId && !m.viewed && isNotMyMessage) {
            ids.push(m.messageId)
          }
        } else {
          if(m.messageId && !m.viewed && m.companyId != this.companyInfo.id) {
            ids.push(m.messageId)
          }
        }
      }
      return ids;
    },
    isCanApproveCredit() {
      return _.some(this.selectedRow, (r) => {
        return r.status === 'UnderReview'
      })
    },
    isCanUndoCancellation() {
      return _.some(this.selectedRow, (r) => {
        return r.status !== 'Denied' && r._showDetails && (r.status === 'Cancelled' || r.cancelCreditReason)
      })
    },
    isCanCancelCredit() {
      return _.some(this.selectedRow, (r) => {
        return r._showDetails && r.status == 'UnderReview' && !r.cancelCreditReason
      })
    },
    isCheckedAll: {
      get: function () {
        if (this.credit.parts.length == 0) {
          return false
        }
        let result = _.filter(this.credit.parts, function (item) {
          return item.isChecked == false
        })

        if (result && result.length > 0) {
          return false
        }

        return true
      },
      set: function (check) {
        _.forEach(this.credit.parts, (prt) => {
          prt.isChecked = check

          let isSelectedPart = _.find(this.selectedRow, (r) => {
            return Number(r.id) == Number(prt.id)
          })

          if (check && !isSelectedPart) {
            this.selectedRow.push(prt)
          } else if (!check && isSelectedPart) {
            let ind = _.findIndex(this.selectedRow, (r) => {
              return Number(r.id) == Number(prt.id)
            })
            this.selectedRow.splice(ind, 1)
          }
        })
        this.callComputed++
      }
    },
    isCanSplitCredit () {
      if (this.credit.parts.length <= 1) {
        return false
      }
      let statuses = [];
      _.forEach(this.credit.parts, (prt) => {
        if (!statuses.includes(prt.status)) {
          statuses.push(prt.status)
        }
      })
      return statuses.length > 1
    },
    pageTitle () {
      return `Credit Nbr - ${this.credit && this.credit.number ? this.credit.number : ''} | ${this.credit && this.credit.rfq && this.credit && this.credit.rfq.vehicle && this.credit.rfq.vehicle.make || ''}  - ${this.credit && this.credit.rfq && this.credit && this.credit.rfq.vehicle && this.credit.rfq.vehicle.rego || ''}`
    },
    mobileOptionsClass () {
      if (this.pageTitle.length >= 62 && this.pageTitle.length <= 69) {
        return 'top-15'
      }
      return ''
    },
    lengthSelectedRows () {
      return this.selectedRow.length
    },
    lengthCreditItems () {
      return this.creditItems.length
    },
    creditPartsItems () {
      this.callComputed++
      return this.credit.parts
    },
    computedTotalCounts() {
      let counts = {
        creditPartList: 0,
        images: 0,
      }
      counts.creditPartList = this.credit.parts.length
      counts.images = this.credit.images.length
      return counts
    },
  },
  methods: {
    redirectToRfqView(itm) {
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter)) {
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    onCloseChat() {
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
    },
    onChatMessage (data) {
      if(data.type === 'newMessage'){
        this.chatMessages.push(data)
        if (!_.isEmpty(this.getterMultipleBrandIds)) {

          let isNotMyMessage = !_.includes(this.getterMultipleBrandIds, data.companyId)

          if(data.messageId && isNotMyMessage) {
            this.markMessagesAsViewed([data.messageId]);
          }
        } else {
          if(data.messageId && data.companyId != this.companyInfo.id) {
            this.markMessagesAsViewed([data.messageId]);
          }
        }
      }
      if(data.type === 'repairerIsOnline'){
        this.chatRepairerIsOnline = true;
        this.repairerLeavedTheChat();
      }
      if(data.type === 'typingInChat' && data.id !== this.currentUser.id){
        this.chatTypers[data.id] = {
          name: data.name,
          microtime: Date.now(),
        }
      }
    },
    sendChatMessage(text){
      if(!text || !text.trim()){
        return new Promise((resolve) => {
          resolve(false)
        });
      }
      return Axios.post('/centrifuge/send-message-to-credit', {
        creditId: this.credit.id,
        message: text.trim(),
      })
    },
    onChatMessageSubmit({message,resolve}){
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
      this.sendChatMessage(message).then(res => {
        resolve(res.data?._status)
      }).catch(() => {
        resolve(false)
      })
    },
    markMessagesAsViewed(messageIds = []){
      return Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        messageIds
      })
    },
    onChatInputTyping(){
      this._chat.publish({
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      })
    },
    handleRowSelected (rows) {
      if (this.isSelectedManualy) {
        setTimeout(() => {
          this.isSelectedManualy = false
        }, 10)
        // this.isSelectedManualy = false;
        return
      }
      this.selectedRow = rows
      _.forEach(rows, row => {
        let index = _.findIndex(this.credit.parts, (prt) => {
          return Number(prt.id) == Number(row.id)
        })
        if (index !== -1) {
          this.$nextTick(() => {
            if (this.credit.parts[index].isChecked && (!this.isHoldShift && !this.isHoldCtrl)) {
              this.$set(this.credit.parts[index], 'isChecked', false)
            } else {
              this.$set(this.credit.parts[index], 'isChecked', true)
            }
          })
        }
      })
      this.$refs.creditPartsTable.clearSelected()

      this.callComputed++

      setTimeout(() => {
        _.forEach(this.credit.parts, (part, index) => {
          if (part.isChecked) {
            let isSelectedPart = _.find(this.selectedRow, (r) => {
              return Number(r.id) == Number(part.id)
            })
            if (!isSelectedPart) {
              this.selectedRow.push(part)
            }
          }
        })
      }, 10)
    },
    handleRowClicked (row, index, event) {
      setTimeout(() => {
        let checkedRows = _.filter(this.credit.parts, (prt) => {
          return prt.isChecked
        })
        if (checkedRows.length == 1) {
          let ind = _.findIndex(this.credit.parts, (prt) => {
            return Number(prt.id) == Number(checkedRows[0].id)
          })
          if (ind !== 1) {
            if (!this.$refs.creditPartsTable.selectedRows[ind]) {
              this.isSelectedManualy = true
              this.$refs.creditPartsTable.selectRow(ind)
              this.$set(this.credit.parts[ind], 'isChecked', true)
            } else {
              this.$refs.creditPartsTable.unselectRow(ind)
              this.$set(this.credit.parts[ind], 'isChecked', false)
            }
          }
        }
      }, 100)
    },
    onCheckboxChange(item, value) {
      let index = _.findIndex(this.credit.parts, (prt) => {
        return Number(prt.id) == Number(item.id)
      })
      if (index !== -1) {
        // if (value) {
        //   this.$refs.orderTable.selectRow(index)
        // } else {
        //   this.$refs.orderTable.unselectRow(index)
        // }

        this.$set(this.credit.parts[index], 'isChecked', value)

        let isSelectedPart = _.find(this.selectedRow, (r) => {
          return Number(r.id) == Number(item.id)
        })

        if (value && !isSelectedPart) {
          this.selectedRow.push(item)
        } else if (!value && isSelectedPart) {
          let ind = _.findIndex(this.selectedRow, (r) => {
            return Number(r.id) == Number(item.id)
          })
          this.selectedRow.splice(ind, 1)
        }
        this.callComputed++
      }
    },
    checkCtrlAndShift(e) {
      if (e.which == 17) {
        this.isHoldCtrl = true
      } else if (e.which == 16) {
        this.isHoldShift = true
      }
    },
    removeCtrlAndShift() {
      this.isHoldCtrl = false
      this.isHoldShift = false
    },
    getTypes(types) {
      if (types?.length == 1) {
        return types[0]
      } else if (types?.length > 1) {
        return 'Multiple'
      }
      return ''
    },
    clearAllSelectedParts () {
      this.$refs.creditPartsTable.clearSelected()
      _.forEach(this.credit.parts, (prt) => {
        prt.isSelected = false;
      })
    },
    openAllSelectedParts () {
      _.forEach(this.credit.parts, (prt, index) => {
        prt._showDetails = true;
      })
    },
    onClickCancel() {
      this.clearAllSelectedParts()
      this.$refs.menuCredit.close()
    },
    onClickSplit () {
      NProgress.start()
      let res = [];
      _.forEach(this.selectedRow, (itm) => {
        res.push(itm.id)
      })
      Axios.post(`/ir/supplier/credit/${this.$route.params.creditId}/split`, {
        creditPartIds: res
      }).then((response) => {
        if (response.data._status) {
          this.$toast.success('Split Credit Success - ' + this.credit.number)
          this.loadCredit(this.credit.id);
        } else {
          this.$toast.error('Split Credit Error - ' + this.credit.number)
        }
      }).catch(error => {
        this.$toast.error('Split Credit Error - ' + this.credit.number)
      }).finally(() => {
        NProgress.done();
        this.$refs.menuCredit.close()
        this.clearAllSelectedParts()
      })
    },
    getNewCreditOrderNumber () {
      Axios.get(`/ir/supplier/credit/${this.$route.params.creditId}/for-split`,)
          .then(response => {
            if (response?.data?._status) {
              this.newCreditRefNumber = response.data.newCreditRefNumber
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    getCountForContext() {
      // if (this.selectedRow.length == 1) {
        // let el = document.getElementsByClassName('credit-view__context-menu');
        // console.log(el)
        // if (el && el[0]) {
        //   return -((el[0].children.length - 2) * 42)
        // }
        // return 0
      // } else {
        return -((135 - 58) + (116 * this.selectedRow.length))
      // }
    },
    reinstateCredit () {
      NProgress.start()
      Axios.post('/ir/supplier/credit/' + this.credit.id + '/reinstate', {
        creditId: this.credit.id
      }).then((response) => {
        if (response.data._status) {
          this.$toast.success('The Credit has been reinstated for review')
        } else {
          this.$toast.error('The Credit Review didn`t reinstate')
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
    },
    goToOrder () {
      this.$router.push('/s/order/' + this.credit.order.id)
    },
    saveManualNumber () {
      NProgress.start();
      Axios.post(`/ir/supplier/credit/${this.credit.id}`,
          {
            creditId: this.credit.id,
            manualNumber: this.credit.manualNumber,
          })
          .then(response => {
            if (response.data._status) {
            }
          })
          .finally(() => {
            NProgress.done();
          })
    },
    viewCredits () {
      let filters = this.$route.params.filters;
      expiringStorage.set(`vue-tabs-component.cache.${window.location.host}` + '/s/credits', '#all', 500000);
      this.$router.push({name: 'SuppliersCreditManagement'});
      if (!_.isEmpty(filters)) {
        if (filters.creditNumber) {
          setTimeout(() => {
            this.$store.commit('supplier/credit/setSearchFilterObj', {
              0: {
                search: '',
              },
              1: {
                search: '',
                customer: filters.customer,
              },
              2: {
                search: '',
              },
              3: {
                search: '',
              },
            });
          }, 2000)
          this.$store.dispatch('supplier/credit/setFilter', filters.creditNumber, { root: true });
          let customers = {}
          _.forEach(this.getterAllCredits, (credit) => {
            if (customers.length >= 10){
              return false;
            }
            if (credit.repairerName) {
              customers[credit.repairerName.trim().toLowerCase()] = { label: credit.repairerName.trim() }
            }
          })

          customers = _.orderBy(Object.values(customers), ['label'], ['asc'])

          this.$store.commit('supplier/credit/setTokens', {
            0: [
              {
                id: 'customer',
                label: 'Customers',
                iconClass: 'bx-user',
                options: [
                  customers
                ],
                isCompleted: true,
                originalIndex: 0,
              },
              {
                label: filters.customer
              }
            ],
            1: [],
            2: [],
            3: [],
          })
        }
      }
    },
    formatDateTime (val) {
      return (val) ? dayjs(val).format('DD/MM/YYYY || hh:mmA') : ''
    },
    formatDate (val) {
      return (val) ? dayjs(val).format('DD/MM/YYYY') : ''
    },
    rowClass (item, type) {
      if (item && type === 'row') {
        if (item.isSelected || item.isChecked) {
          return 'row-selected'
        }
      }
    },
    onCloseMenuCreditContext () {
      this.isShowReasonForCancellation = false;
      this.isShowSplitCredit = false;
      this.clearAllSelectedParts()
      this.clearSelectedRows();
    },
    clearSelectedRows () {
      if (this.selectedRowIndex !== null) {
        const table = this.$refs.creditPartsTable
        this.$nextTick(() => {
          table.unselectRow(this.selectedRowIndex)
        })
        this.$set(this.credit.parts[this.selectedRowIndex], 'isSelected', false);
      }
    },
    changedCreditComment (payload) {
      let data = payload.saveData;
      let part = data.creditPart
      let comment = ''
      if (part.status === 'Cancelled' || payload.isDenied) {
        comment = part.cancelCreditReason
      } else {
        comment = part.supplierCreditPartComment
      }
      let errorText =  payload.isDenied ? 'Denied Credit Issue - ' + this.credit.number : 'The Reason for Credit has not been saved';

      let url = payload.isDenied ? `/ir/supplier/credit/${this.$route.params.creditId}/part/${part.id}/deny` : `/ir/supplier/credit/${this.$route.params.creditId}/part/${part.id}/edit`;
      let jsonData = payload.isDenied ? { cancelCreditReason: comment } : { comment: comment };

      Axios.post(url, jsonData)
          .then(response => {
            if (response.data._status) {
              let text = payload.isDenied ? 'Denied Credit Success - ' + this.credit.number : 'The Reason for Credit has been saved'
              this.$toast.success(text)
              if (payload.isDenied) {
                part.status = 'Denied'
              } else {
                if (part.status === 'Cancelled') {
                  part.cancelCreditReason = response.data.cancelCreditReason
                  part.cancelCreditOn = response.data.cancelCreditOn
                  part.cancelCreditBy = response.data.cancelCreditBy
                } else {
                  part.supplierCreditPartComment = response.data.supplierCreditPartComment
                  part.supplierCreditPartCommentDate = response.data.supplierCreditPartCommentDate
                  part.supplierCreditPartCommentByName = response.data.supplierCreditPartCommentByName
                  if (part.preStatus === 'Denied') {
                    part.status = 'Denied'
                  }
                }
              }
              part.isEditCredit = false
            } else {
              this.$toast.error(errorText)
            }
          })
          .catch(() => {
            this.$toast.error(errorText)
          })
    },
    undoCancelCreditPart (row) {
      //test
      // row.cancelCreditReason = '';
      //  row.status = 'UnderReview';
      //this.$toast.warning('Undo Cancellation Success - ' + this.credit.order.number)

      console.log('Undo Cancel', row)
      let data = {
        partsIds: []
      }
      _.forEach(this.selectedRow, (r) => {
        data.partsIds.push(r.id)
      })
      Axios.post(`/ir/supplier/credit/${this.$route.params.creditId}/undo-cancel-parts`, data)
          .then(response => {
            if (response.data._status) {
              _.forEach(this.selectedRow, (r) => {
                let prtIndex = _.findIndex(this.credit.parts, (p) => {
                  return Number(p.id) === Number(r.id)
                })
                if (prtIndex !== -1) {
                  this.credit.parts[prtIndex].cancelCreditReason = ''
                  this.credit.parts[prtIndex].status = 'UnderReview'
                  this.credit.parts[prtIndex].supplierCreditPartCommentByName = null
                  this.credit.parts[prtIndex].supplierCreditPartCommentDate = null
                }
              })
              // row.cancelCreditReason = ''
              // row.status = 'UnderReview'
              // row.supplierCreditPartCommentByName = null
              // row.supplierCreditPartCommentDate = null
              this.credit.status = response.data.creditStatus
              this.$toast.success('The cancelation of credit for this part has been removed')
            }
          })
      this.$refs.menuCredit.close()
    },
    denyCreditPart (row) {
      row.preStatus = 'Denied'
      this.editCreditPart2(row)
    },
    approveCreditPart (row) {
      let data = {
        partsIds: []
      }
      _.forEach(this.selectedRow, (r) => {
        data.partsIds.push(r.id)
      })

      Axios.post(`/ir/supplier/credit/${this.$route.params.creditId}/approve-credit-parts`, data)
          .then(response => {
            if (response.data._status) {
              _.forEach(this.selectedRow, (r) => {
                let prtIndex = _.findIndex(this.credit.parts, (p) => {
                  return Number(p.id) === Number(r.id)
                })
                if (prtIndex !== -1) {
                  this.credit.parts[prtIndex].preStatus = ''
                  this.credit.parts[prtIndex].status = 'Approved'
                  this.credit.parts[prtIndex].amountApproved = response.data.part[r.id].amountApproved
                }
              })

              // row.status = 'Approved'
              // row.amountApproved = response.data.part.amountApproved
              this.credit.status = response.data.creditStatus
              this.$toast.success('Approved Credit Success - ' + this.credit.order.number)
            }
          })
      this.$refs.menuCredit.close()
    },
    editCreditPart2 (row) {
      this.toggleDetails(row)
      setTimeout(() => {
        this.editCreditPart(row)
      }, 200)
    },
    editCreditPart (row) {
      let index = _.findIndex(this.credit.parts, (prt) => {
        return Number(prt.id) === Number(row.id)
      })
      if (index !== -1) {
        this.$set(this.credit.parts[index], 'isEditCredit', true)
      }
      console.log(index, 'index')
      this.$refs.menuCredit.close()
      let component = this.$refs['credit-details-' + row.id]
      if (component) {
        setTimeout(() => {
          component.focusInReasonForCredit()
        }, 200)
      }
    },
    saveCancelCredit (row) {
      let data = {}
      if (this.selectedRow.length > 1) {
        data = {
          cancelCreditReason: this.cancelCreditReasonDraft,
          partsIds: []
        }
        _.forEach(this.selectedRow, (r) => {
          data.partsIds.push(r.id)
        })
      } else if (this.selectedRow.length == 1) {
        data =  {
          cancelCreditReason: row.cancelCreditReasonDraft,
          partsIds: [row.id]
        }
      }
      Axios.post(`/ir/supplier/credit/${this.$route.params.creditId}/cancel-parts`, data)
          .then(response => {
            if (response.data._status) {
              _.forEach(this.selectedRow, (r) => {
                let prtIndex = _.findIndex(this.credit.parts, (p) => {
                  return Number(p.id) === Number(r.id)
                })
                if (prtIndex !== -1) {
                  this.credit.parts[prtIndex].preStatus = ''
                  this.credit.parts[prtIndex].cancelCreditReasonDraft = null
                  this.credit.parts[prtIndex].status = 'Cancelled'
                  this.credit.parts[prtIndex].cancelCreditReason = response.data.parts[r.id].cancelCreditReason
                  this.credit.parts[prtIndex].cancelCreditOn = response.data.parts[r.id].cancelCreditOn
                  this.credit.parts[prtIndex].cancelCreditBy = response.data.parts[r.id].cancelCreditBy
                }
              })
              this.cancelCreditReasonDraft = null
              // row.cancelCreditReason = response.data.cancelCreditReason
              // row.cancelCreditReasonDraft = null
              // row.status = 'Cancelled'
              // row.cancelCreditOn = response.data.cancelCreditOn
              // row.cancelCreditBy = response.data.cancelCreditBy
              this.credit.status = response.data.creditStatus
              this.$toast.success('The credit for this part has been canceled')
            }
          })
      this.$refs.menuCredit.close()
    },
    undoCancelCredit (row) {
      console.log('Undo Cancel', row)
      let id = row.id
      Axios.post(`/ir/supplier/credit/${this.$route.params.creditId}/part/${id}/undo-cancel`)
          .then(response => {
            if (response.data._status) {
              row.cancelCreditReason = ''
              row.status = 'UnderReview'
              this.credit.status = response.data.creditStatus
              this.$toast.success('The cancelation of credit for this part has been removed')
            }
          })
      this.$refs.menuCredit.close()
    },
    disableDefaultRightClickOn (elSelector) {
      // const noContext = document.querySelector(elSelector)
      // noContext.addEventListener('contextmenu', e => {
      //   e.preventDefault()
      // })
    },
    openMenuForReasonAction (data) {
      console.log(data, 'data')
      if (data.part.status !== 'UnderReview' && data.part.status !== 'Cancelled' && data.part.status !== 'Denied') {
        return
      }
      this.isOpenPartContext = true
      let partContext = this.$refs.menuCredit
      if (partContext && partContext.show) {
        partContext.close()
      }

      if (!(data.part.reason !== '' && data.isNeedSave)
          && !(data.part.reason !== '' && data.part.reason !== null && !data.isNotNeedEdit)
          && !(data.part.reason !== '' && data.part.reason !== null)) {
        setTimeout(() => {this.isOpenPartContext = false}, 100)
        return
      }

      this.isOpenPartContext = true

      this.$nextTick(() => {
        this.$refs.menuActionForReason.open(data.event, {
          part: data.part,
          type: data.type,
          isNotSavedChanges: data.isNotSavedChanges,
          isNeedSave: data.isNeedSave,
          isNotNeedEdit: data.isNotNeedEdit
        })
      })
    },
    closeContextForReasonAction () {
      this.$refs.menuActionForReason.close()
    },
    saveReason (part) {
      let el = this.$refs['credit-details-' + part.id]
      if (el) {
        el.saveReason()
      }
    },
    editReason (part) {

      let el = this.$refs['credit-details-' + part.id]

      let index = _.findIndex(this.credit.parts, (prt) => {
        return Number(prt.id) === Number(part.id)
      })
      if (index !== -1) {
        this.$set(this.credit.parts[index], 'isEditCredit', true)
      }

      if (el) {
        el.editReason()
      }
    },
    deleteReason (part) {
      let el = this.$refs['credit-details-' + part.id]
      if (el) {
        el.deleteReason()
      }
    },
    dateFormat () {
      return dayjs(this.dateDue).format('DD/MM/YYYY - hh:mmA')
    },
    toggleDetails (row) {
      this.$set(row, '_showDetails', true)
      this.$refs.menuCredit.close()
    },
    viewCreditStatus (status) {
      if (status === 'Approved') {
        return 'Approved'
      } else if (status === 'UnderReview') {
        return 'Under Review'
      } else if (status === 'Denied') {
        return 'Request Denied'
      } else if (status === 'Cancelled') {
        return 'Request Cancelled'
      } else {
        return ''
      }
    },
    getNameClass: function (item) {
      let status = item.status
      let elem = _.find(this.statusList, { id: status })
      if (elem) {
        return elem.nameClass
      }
      return ''
    },
    getIconClass: function (item) {
      //console.log('item', item)
      let status = item.status
      //console.log('status', status)
      let elem = _.find(this.statusList, { id: status })
      // console.log('elem', elem)
      if (elem) {
        return elem.iconClass
      }
      return ''
    },
    loadCredit: function (id, callback = false) {
      Axios.get('/ir/supplier/credit/' + id,)
          .then(response => {
            if (callback) {
              callback(response.data.credit);
              return
            }
            this.credit = response.data.credit
            this.credit.parts.forEach(part => {
              this.$set(part, 'isChecked', false)
            })
            if(response.data.chatMessages){
              this.chatMessages = response.data.chatMessages
              if(this.unviewedMessagesIds.length > 0){
                this.markMessagesAsViewed(this.unviewedMessagesIds);
              }
            }
            this.openAllSelectedParts()
          })
          .catch(error => {
            this.$toast.error('Page not found')
            this.$router.push('/s/credits')
            console.log(error)
          })
          .finally(() => {
            this.isLoadedPartOrder = true
          })
    },
    handleRightClick (row, index, e) {
      console.log('context menu open', this.isOpenPartContext)
      this.$nextTick(() => {
        if (!row.isChecked) {
          this.$set(this.credit.parts[index], 'isChecked', true)
          this.callComputed++

          let isSelectedPart = _.find(this.selectedRow, (r) => {
            return Number(r.id) == Number(row.id)
          })
          if (!isSelectedPart) {
            this.selectedRow.push(row)
          }
        }
      })
      // if (!this.isCanSplitCredit) {
      //   this.clearAllSelectedParts();
      // }
      if (this.isOpenPartContext) {
        return
      }
        e.preventDefault()
      let part = row
      if (row.item && row.item.id) {
        part = row.item
      }
      this.selectedRowIndex = index;
      // this.$set(this.credit.parts[index], 'isSelected', true);
      // this.$refs.creditPartsTable.selectRow(index)
      this.$refs.menuCredit.open(e, { row: part, index: index })
      // when right click selected, only a single row can be selected at one time
    },

    handleRequestCreditButton (data, event) {
      return
    },

    partCanRequestCredit (part) {
      return part.status === 'CancelPart'
          || part.status === 'ReturnDamagedPart'
          || part.status === 'IncorrectPartOrdered'
          || part.status === 'IncorrectPartDelivered'
          || part.status === 'DamagedPartExWarehouse'
          || part.status === 'NoLongerNeeded'
          || part.status === 'ChangeInRepairMethod'
    },
    handleIncorrectPartOrderedButton (data, event) {
      let _save = []
      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach(function (el) {
          _save.push({ partId: el.id, status: 'IncorrectPartOrdered' })
          el.status = 'IncorrectPartOrdered'
          el._showDetails = true
        })
        this.selectedRow = []
        this.clearAllSelectedParts()
      } else {
        data.row.status = 'IncorrectPartOrdered'
        data.row._showDetails = true
        _save.push({ partId: data.row.id, status: 'IncorrectPartOrdered' })
      }
      this.clickSave(_save)
    },
    handleIncorrectPartButton (data, event) {
      let _save = []
      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach(function (el) {
          _save.push({ partId: el.id, status: 'IncorrectPartDelivered' })
          el.status = 'IncorrectPartDelivered'
          el._showDetails = true
        })
        this.selectedRow = []
        this.clearAllSelectedParts()
      } else {
        data.row._showDetails = true
        data.row.status = 'IncorrectPartDelivered'
        _save.push({ partId: data.row.id, status: 'IncorrectPartDelivered' })
      }
      this.clickSave(_save)
    },
    savePartReceivedButton () {
      this.handlePartReceivedButton()
    },
    handlePartReceivedButton (data, event) {
      let _save = []
      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el._showDetails) {
            // el._showDetails = false
          }
          _save.push({ partId: el.id, status: 'Received' })
          el.status = 'Received'
          el.comment = null
          el.commentByName = null
          el.commentDate = null
          el.qtyRcv = el.qty
        })
        this.selectedRow = []
        this.clearAllSelectedParts()
      } else {
        console.log('data', data, data.row.detailsShowing)
        if (data.row._showDetails) {
          // data.row._showDetails = false
        }
        data.row.status = 'Received'
        data.row.comment = null
        data.row.commentByName = null
        data.row.commentDate = null
        data.row.qtyRcv = data.row.qty
        _save.push({ partId: data.row.id, status: 'Received' })
      }
      this.clickSave(_save)
    },
  },
  created: function () {
    (async () => {
      this._chat = await subscribeToCreditChannel(this.$route.params.creditId, {
        onPublication: this.onChatMessage,
        onSubscribed: () => {
          this.onlineStatusIntervalId = setInterval(() => {
            this._chat.publish({ type: 'supplierIsOnline' })
          }, 2000)
        },
        onUnsubscribed: () => {
          if(this.onlineStatusIntervalId !== null){
            clearInterval(this.onlineStatusIntervalId)
          }
        },
      })
    })()
  },
  mounted () {
    this.loadCredit(this.$route.params.creditId)
    this.disableDefaultRightClickOn('.credit-view-page .table')
    this.getNewCreditOrderNumber();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.checkCtrlAndShift)
    window.addEventListener('keyup', this.removeCtrlAndShift)
    this.repairerLeavedTheChat = _.debounce(function () {
      this.chatRepairerIsOnline = false;
    }, 5000)
    },
    beforeDestroy() {
      if(this._chat){
        console.log('UNSUBSCRIBING!',this._chat);
        this._chat.unsubscribe();
        console.log('UNSUBSCRIBED!');
      }
      window.removeEventListener('keydown', this.checkCtrlAndShift)
      window.removeEventListener('keyup', this.removeCtrlAndShift)
      clearInterval(this.interval);
    },
  watch: {
    creditsFromUpdate: {
      handler (val) {
        let isNeedUpdate = !!_.find(val, (itm) => {
          return Number(itm.id) === Number(this.credit.id);
        })
        if (isNeedUpdate) {
          this.loadCredit(this.$route.params.creditId, (credit) => {
            console.log(this.credit, credit)
            if (!this.credit || !credit) {
              return
            }
            if (this?.credit?.manualNumber !== credit?.manualNumber) {
              this.credit.manualNumber = credit.manualNumber;
            }
            if (!_.isEqual(this?.credit?.images, credit?.images)) {
              this.credit.images = credit.images;
            }

            _.forEach(credit.parts, (p) => {
              let originalPart = _.find(this.credit.parts, (prt) => {
                return Number(prt.id) === Number(p.id)
              })
              if (originalPart) {
                if (!_.isEqual(p.isChecked, originalPart.isChecked)) {
                  p.isChecked = originalPart.isChecked;
                }
              }
            })

            this.credit.parts = credit.parts;

            let selectedRowCopy = _.cloneDeep(this.selectedRow)
            _.forEach(this.credit.parts, (part, index) => {
              if (part.isChecked) {
                let selectedPartIndex = _.findIndex(this.selectedRow, (r) => {
                  return Number(r.id) == Number(part.id)
                })
                if (selectedPartIndex == -1) {
                  selectedRowCopy.push(part)
                } else {
                  selectedRowCopy[selectedPartIndex] = part
                }
              }
            })
            this.selectedRow.splice(0, this.selectedRow.length)
            _.forEach(selectedRowCopy, (p) => {
              this.selectedRow.push(p)
            })

            this.openAllSelectedParts()
          })
        }
        console.log('watch.getIsNeedUpdate', isNeedUpdate)
      },
      immediate: true,
    }
  },
  components: {
    WindowNotification,
    VueContext,
    Chat,
    CreditDetails,
    FileUpload,
    dayjs,
    SearchFilter
  },
}
</script>

<style>
.V3 .theme-supplier .credit-view-page .part-name-sticky {
  position: sticky !important;
  left: 0px;
  z-index: 999;
  background-color: #e3e4e7 !important;
}
.theme-supplier .credit-view-page .b-table-row-selected.table-active .table-name-border-bottom {
  background-color: #a6b2b9;
}

.theme-supplier .credit-view-page .split-button:hover {
  color: white !important;
}

.theme-supplier .credit-view-page .table.b-table > tbody > tr.row-selected > .table-b-table-default {
  background-color: rgb(233,248,249) !important;
}
.theme-supplier .credit-view-page .table.b-table > tbody > tr.row-selected > .table-b-table-default:hover {
  background-color: rgb(233,248,249) !important;
}

@supports (position: sticky) {
  .theme-supplier .credit-view-page .table.b-table > tbody > tr > .table-b-table-default {
    background-color: #fff !important;
  }
  .theme-supplier .credit-view-page .table.b-table .b-table-row-selected.table-active .b-table-sticky-column {
    background-color: #e9f8f9 !important;
  }
  .theme-supplier .credit-view-page .table.b-table.table-hover > tbody > tr.table-active:hover td.b-table-sticky-column  {
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075)) !important;
    background-repeat: no-repeat;
  }
}

.credit-view-page .row-selected {
    background-color: rgb(233,248,249) !important;
}
.credit-view-page .row-selected .b-table-sticky-column {
    background-color: rgb(233,248,249) !important;
}

.theme-supplier .credit-view-page .dropdown-options .dropdown-menu .dropdown-item {
  white-space: nowrap !important;
  padding: 15px;
}
</style>

<style lang="scss" scoped>
.credit-view {
  &__row {
    &--xl {
      width: 20%;
      min-width: 300px;
    }

    &--lg {
      width: 10%;
      min-width: 150px;
    }

    &--md {
      width: 7%;
      min-width: 150px;
    }

    &--sm {
      width: 5%;
      min-width: 110px;
    }

    &--xs {
      width: 3%;
      min-width: 80px;
    }
  }

  &__context-menu {
    .btn {
      border: none;
      padding: 15px 15px 11px 15px;
      transition-duration: 150ms;
    }

    .theme-supplier & .btn:hover {
      color: #29BBC1;
    }
  }
}

.credit-view-page {
  // right click menu container
  .v-context {
    width: auto !important;
    border: none;
    box-shadow: rgba(27, 30, 56, 0.15);
  }

  .table.b-table > tbody > .table-active,
  .table.b-table > tbody > .table-active > th,
  .table.b-table > tbody > .table-active > td {
    background-color: rgba(46, 169, 255, 0.1);
  }

  .table.b-table > thead > tr > [aria-sort="none"],
  .table.b-table > thead > tr > [aria-sort="descending"],
  .table.b-table > thead > tr > [aria-sort="ascending"],
  .table [role='columnheader'][aria-label='Status Id'] {
    padding: 15px 15px 17px 10px !important;
    background-color: #EFEFF1 !important;
    top: -1px;
    border: 0;
    box-sizing: border-box;
  }

  .b-table-sticky-header {
    max-height: 60vh;
    border-bottom: 1px solid rgb(175, 177, 179);
  }

  .dropdown-item {
    padding: 13px 4px 15px 15px !important;
  }

  // custom dot on buildings icon
  .bx-buildings {
    color: #1B1E38;
    position: relative;

    &:after {
      content: '';
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xMC4wMDEgMTYuNDEzTDYuMjg4IDEyLjcwOEw3LjcgMTEuMjkyTDkuOTk5IDEzLjU4N0wxNS4yOTMgOC4yOTNMMTYuNzA3IDkuNzA3TDEwLjAwMSAxNi40MTNaIiBmaWxsPSIjMkRDQTczIi8+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      background-size: contain;
      width: 7px;
      height: 7px;
      position: absolute;
      background-color: white;
      border-radius: 50%;
      top: 0;
      right: 0;
    }

    &--no-dot:after {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .b-table-sticky-header {
      max-height: 50vh;
    }
  }

  /* overwrite */
  [role='cell'] .text-primary,
  .bx.text-primary {
    color: #63BCFF !important
  }

  // table modifications
  // to make border works on sticky column (firefox)
  .table {
    border-collapse: separate;
    border-spacing: 0;
  }

  // to fix blue color on selected row,
  // when scrolled horizontally, so it is not transparent
  .b-table-row-selected.table-active .b-table-sticky-column {
    background-color: #D6EEFF !important;
  }

  .b-table-row-selected.table-active + .b-table-details {
    background-color: #D6EEFF !important;
  }

  // row border
  .table.b-table td {
    border-color: rgba(27, 30, 56, 0.1);
    vertical-align: top;
  }

  // dont use different shade on freeze column
  .table.b-table.table-hover > tbody > tr:hover:not(.b-table-row-selected) > .table-b-table-default {
    background: inherit
  }

  // horizontal scrollbar
  .table-responsive {
    &::-webkit-scrollbar {
      width: 7px
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b8c0;
    }

    scrollbar-width: thin;
    scrollbar-color: #b7b8c0;
  }
}

.credit-title {
  margin: 15px 0 12px 0;

  &__item {
    min-width: 360px;
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.ps-label-value__label {
  display: inline-block;
  width: 118px;
  white-space: nowrap;
  color: #a0a1a7;
  font-weight: 700;
}

.ps-label-value__value {
  font-weight: 700;
}

.credit-footer {
  //padding-left: 20%;
  &__whitespace {
    display: inline-block;
    width: 19%;
    min-width: 230px;
  }

  &__eta-label,
  &__eta-date {
    opacity: 0.5;
  }

  &__eta-date {
    margin-left: 50px;
  }

  @media screen and (max-width: 991px) {
    &__whitespace {
      width: 20%;
      min-width: 155px;
    }
  }
}

/* table column width */
.credit-th {
  &--part-list {
    min-width: 200px;
    width: 15%;
  }

  @media screen and (max-width: 991px) {
    &--part-list {
      min-width: 130px;
    }
  }
}

/* show details */
.credit-view-page tr.show-detail.b-table-row-selected {
  background-color: white !important;

  td,
  td.b-table-sticky-column {
    background-color: white !important;
  }
}

@media (max-width: 1245px) {
  .credit-view-page .credit-additional-block {
    display: block !important;
  }
}

@media (max-width: 764px) {
  .credit-view-page .credit-additional-block:not(:first-child) {
    display: none !important;
  }

  .credit-view-page .credit-additional-block .credit-title {
    max-width: 100%;
  }
}

.credit-view-page .credit-view-body .tabs-component {
  border-bottom: 10px solid #f1f1f7;
}
</style>

<style scoped>
.dropdown-actions >>> button{
  background-color: #7bbec0 !important;
  border-color: #7bbec0 !important;
  color: #fdfdfd !important;
}
.dropdown-actions >>> button:hover{
  background-color: #afd7d8 !important;
  color: #3f3f3f !important;
}
.dropdown-actions >>> button span{
  border-color: #7bbec0 !important;
}
.credit-order-numbers {
  padding: 15px;
}

.credit-order-numbers .credit-order-numbers-item:last-child {
  margin-left: 35px;
}

.credit-order-numbers input{
  max-width: 150px;
}

.credit-order-numbers .credit-order-numbers-title {
  min-width: 72px;
  font-weight: 700;
}

@media (max-width: 450px) {
  .credit-order-numbers {
    flex-direction: column;
  }
  .credit-order-numbers .credit-order-numbers-item:last-child {
    margin-left: 0px;
    margin-top: 15px;
  }
}
.credit-cancel-save-icon {
  font-size: 24px;
  position: absolute;
  left: 277px;
  top: 193px;
}

.credit-cancel-save-icon-2 {
  top: 149px;
}

.credit-cancel-reason {
  height: 100px;
  width: 200px;
  position: absolute;
  left: 118px;
  top: 123px;
  resize: none;
}

.credit-cancel-reason-only {
  top: 82px;
}

.raised-credit-request-sub-menu {
  right: -121px;
  top: 84px;
}

.part-sub-context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.autosave {
  font-size: 13px;
  margin-left: 100px;
}

.credit-view-body >>> td,
.credit-view-body >>> th {
  width: auto;
}

.credit-view-body >>> .credit-status-td {
  min-width: 125px;
}

.credit-view-body >>> .column-part-name {
  width: auto;
}

.credit-view-body >>> .column-qty-order {
  max-width: 66px;
  width: 66px;
}

.credit-view-body >>> .column-qty-rcvd {
  max-width: 66px;
  width: 66px;
}

.credit-view-body >>> .column-credit {
  max-width: 80px;
  width: 80px;
}


.options-mobile .bx-down-arrow {
  padding-top: 10px;
  padding-left: 5px;
}

.options-mobile.dropdown-options.show .dropdown-arrow .bx-down-arrow {
  transform: rotate(180deg) translateX(-5px) translateY(-10px) !important;
}

.split-credit-menu {
  width: 250px;
  height: auto;
  position: absolute;
  left: 118px;
  top: 123px;
  resize: none;
}

.split-credit-menu .btn-secondary {
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
}
</style>

<style>
  .credit-view-page .credit-table-name-text {
    padding: 0 0.75rem;
  }

  .credit-view-page .table-name-border-bottom {
    width: 100%;
    height: 1px;
    background-color: #c6c7cd;
    position: absolute;
    bottom: -1px;
  }

  @supports (position: sticky) {
    .credit-view-page .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
      background-color: #e9f8f9 !important;
      background-image: none;
      border-bottom: 1px solid rgba(27, 30, 56, 0.25);
    }
    .credit-view-page .table.b-table > tbody > tr > .table-b-table-default {
      background-color: #fff !important;
      padding: 0.75rem 0;
    }
  }
</style>
