/*eslint-disable */
import Axios from "axios";
import {
    BRAND_LIST_LOAD,
    BRAND_LIST_REINSTALLATION
} from '../../mutation-types';

export function reinstallation (context) {
    context.commit(BRAND_LIST_REINSTALLATION)
}

export function loadBrandList(context) {
    try {
        Axios.get('/ir/common/brandlist')
            .then(response => {
                if (response.data && response.data.brandList) {
                    context.commit(BRAND_LIST_LOAD, response.data.brandList);
                }
            })
            .catch(error => {
                console.log(error);
            });
    } catch (e) {
        console.log(e);
    }
};
