<template>
<div class="">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">Orders</div>

      <div class="card-block mt-3 text-heading mr-5">
        Define your Orders settings. Orders settings can be configured to follow your quoting package numbering or
      </div>

      <div class="card-block mt-5">
        <div class="form-group row">
          <label class="col col-form-label col-lg-4">
            <span class="info-title">Order Prefix</span> <br/>
            <p class="info-text">Set a prefix to add at the start of your Order number to appear as.</p>
          </label>
          <div class="col-lg-8">
            <input v-model="settings.ordersNumberPrefix" @change="onChange"  type="text" class="form-control"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col col-form-label col-lg-4">
            <span class="info-title">Order Number</span> <br/>
            <p class="info-text">Set the Order number to use on all your order's that are submitted to suppliers.</p>
          </label>
          <div class="col-lg-8">
            <multiselect
                :options="types"
                v-model="computedType"
                track-by="key"
                label="value"
                :showLabels="false"
                :option-height="29"
                :max-height="203"
                @input="onChange"
                :close-on-select="true"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col col-form-label col-lg-4">
            <span class="info-title">Order Number Start</span> <br/>
            <p class="info-text">Set the Order number that you want all orders to start from. You can set this to match your current ordering system number.</p>
          </label>
          <div class="col-lg-8">
            <number-formatter
                v-if="settings.ordersNumberType === 'partssearch'"
                @change="onChange"
                :is-disabled="settings.ordersNumberType !== 'partssearch'"
                type="text"
                v-model="settings.ordersNumberStart"
                class="form-control numeric"
                format="0"></number-formatter>
            <input v-else :disabled="true"   type="text" class="form-control"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col col-form-label col-lg-4">
            <span class="info-title">Send Automatically QPS</span> <br/>
            <p class="info-text">Have the Order sent automatically to your QPS</p>
          </label>
          <div class="col-lg-8">
            <label class="form-check-inline checkbox send-qps-checkbox">
              <input class="form-check-input"
                     v-model="settings.ordersAutosendToQps"
                     type="checkbox"
              >
              <span class="icon"><i class='bx bx-check'></i></span>
            </label>
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label col-lg-4" style="padding-top: 0px">
<!--            <span class="info-title">Send Automatically QPS</span> <br/>-->
            <p style="margin-top: 0px" class="info-text">Have Arrived Items sent automatically to your QPS</p>
          </label>
          <div class="col-lg-8">
            <label class="form-check-inline checkbox send-qps-checkbox">
              <input class="form-check-input" v-model="settings.ordersReceivedAutosendToQps"
                     type="checkbox"
              >
              <span class="icon"><i class='bx bx-check'></i></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Axios from 'axios';
import numberFormatter from '../../../../../src/components/utility/number-formatter';
import {mapGetters} from "vuex";

export default {
  name: "orders",
  data () {
    return {
      settings: {
        ordersNumberPrefix: '',
        ordersAutosendToQps: false,
        ordersReceivedAutosendToQps: false,
        ordersNumberType: '',
        ordersNumberStart: '',
      },
      types: [
        {
          key: 'partssearch',
          value: 'PartsSearch Number',
        },
        {
          key: 'rego',
          value: 'Rego',
        },
        {
          key: 'estimate_number',
          value: 'Estimate Number',
        },
        {
          key: 'qp_number',
          value: 'Quoting Package Order Number',
        },
      ]
    }
  },
  methods: {
    onChange() {
      console.log('change', this.settings);
      // this.$emit('change', this.settings);
    },
    updateOrderSettings() {
      Axios.post('/ir/company/update-settings', this.settings)
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success("The Orders Settings have been updated");
              this.$store.dispatch('settings/loadSettings')
            } else {
              this.$toast.error("The Orders Settings haven't been updated");
            }
          })
          .catch(error => {
            this.$toast.error("The Orders Settings haven't been updated");
            console.log(error);
          });
    },
    loadSettings () {
      this.settings = _.cloneDeep(this.getSettings);
    }
  },
  watch: {
    getSettings: {
      handler() {
        this.loadSettings();
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters({
      getSettings: 'settings/getSettings'
    }),
    computedType: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.types, (itm) => {
            return itm.key === vm.settings.ordersNumberType;
          });
          return item;
        } catch (e) {
          return {
            key: 'partssearch',
            value: 'PartsSearch Number',
          }
        }
      },
      set: function (item) {
        if (item) {
          this.settings.ordersNumberType = item.key;
        }
      },
    }
  },
  mounted() {
    // this.loadSettings();
  },
  components: {
    Multiselect,
    numberFormatter,
  }
}
</script>

<style scoped>
.info-title {
  font-size: 13px;
}

.info-text {
  color: rgba(0,0,0,.5);
  font-weight: normal;
  margin-top: 6px;
}

.text-heading {
  font-weight: bold;
  color: rgba(0,0,0,.5);
  padding-left: 15px;
}
</style>

<style>
.V3 .theme-repairer .settings-page .send-qps-checkbox .icon {
  border-color: #d3d3d3 !important;
}

.V3 .theme-repairer .settings-page .send-qps-checkbox > input[type="checkbox"]:checked + span.icon {
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.V3 .theme-repairer .settings-page .send-qps-checkbox > input[type="checkbox"]:checked + span.icon i{
  color: white !important;
}
</style>
