import axios from "axios";
import {Centrifuge} from "centrifuge";
import store from '../store'

let companyUpdateSubs = [];

const obtainToken = async () => {
  const response = await axios.post('/centrifuge/get-token')
  return response?.data?.token || null;
}
const obtainTokensForUpdateChannels = async () => {
  const response = await axios.post('/centrifuge/get-update-channels-tokens')
  return response?.data || null;
}

const subscribeToRfqChannel = async (rfqId, isSupplier = false, callbacks = { onPublication: () => {}, onSubscribing: false, onSubscribed: false, onUnsubscribed: false }) => {

  let url = '/centrifuge/repairer/get-rfq-channel-token';
  if(isSupplier){
    url = '/centrifuge/supplier/get-rfq-channel-token';
  }
  const response = await axios.post(url, {
    rfqId
  })

  if(!response?.data){
    return false;
  }

  while(!centrifuge){
    await sleep(100);
  }

  let sub = centrifuge.getSubscription(response?.data.channel)
  if(sub){
    centrifuge.removeSubscription(sub);
  }

  sub = centrifuge.newSubscription(response?.data.channel, {
    token: response?.data.token,
  })

  sub.on('publication',  (ctx) => {
    callbacks?.onPublication(ctx.data);
  })
  sub.on('subscribing', function (ctx) {
    console.log('[centrifuge]',`subscribing to rfq channel ${response?.data.channel}: ${ctx.code}, ${ctx.reason}`);
    if(callbacks?.onSubscribing){
      callbacks.onSubscribing(ctx);
    }
  })
  sub.on('subscribed', function (ctx) {
    console.log('[centrifuge]','subscribed to rfq channel ' + response?.data.channel, ctx);
    if(callbacks?.onSubscribed){
      callbacks.onSubscribed(ctx);
    }
  })
  sub.on('unsubscribed', function (ctx) {
    console.log('[centrifuge]',`unsubscribed to rfq channel ${response?.data.channel}: ${ctx.code}, ${ctx.reason}`);
    if(callbacks?.onUnsubscribed){
      callbacks.onUnsubscribed(ctx);
    }
    centrifuge.removeSubscription(sub)
  })
  sub.subscribe();
  return sub;
}

const subscribeToOrderChannel = async (orderId, callbacks = { onPublication: () => {}, onSubscribing: false, onSubscribed: false, onUnsubscribed: false }) => {

  let url = '/centrifuge/get-order-channel-token';

  const response = await axios.post(url, {
    orderId
  })

  if(!response?.data){
    return false;
  }

  while(!centrifuge){
    await sleep(100);
  }

  let sub = centrifuge.getSubscription(response?.data.channel)
  if(sub){
    centrifuge.removeSubscription(sub);
  }

  sub = centrifuge.newSubscription(response?.data.channel, {
    token: response?.data.token,
  })

  sub.on('publication',  (ctx) => {
    callbacks?.onPublication(ctx.data);
  })
  sub.on('subscribing', function (ctx) {
    console.log('[centrifuge]',`subscribing to order channel ${response?.data.channel}: ${ctx.code}, ${ctx.reason}`);
    if(callbacks?.onSubscribing){
      callbacks.onSubscribing(ctx);
    }
  })
  sub.on('subscribed', function (ctx) {
    console.log('[centrifuge]','subscribed to order channel ' + response?.data.channel, ctx);
    if(callbacks?.onSubscribed){
      callbacks.onSubscribed(ctx);
    }
  })
  sub.on('unsubscribed', function (ctx) {
    console.log('[centrifuge]',`unsubscribed to order channel ${response?.data.channel}: ${ctx.code}, ${ctx.reason}`);
    if(callbacks?.onUnsubscribed){
      callbacks.onUnsubscribed(ctx);
    }
    centrifuge.removeSubscription(sub)
  })
  sub.subscribe();
  return sub;
}

const subscribeToCreditChannel = async (creditId, callbacks = { onPublication: () => {}, onSubscribing: false, onSubscribed: false, onUnsubscribed: false }) => {

  let url = '/centrifuge/get-credit-channel-token';

  const response = await axios.post(url, {
    creditId
  })

  if(!response?.data){
    return false;
  }

  while(!centrifuge){
    await sleep(100);
  }

  let sub = centrifuge.getSubscription(response?.data.channel)
  if(sub){
    centrifuge.removeSubscription(sub);
  }

  sub = centrifuge.newSubscription(response?.data.channel, {
    token: response?.data.token,
  })

  sub.on('publication',  (ctx) => {
    callbacks?.onPublication(ctx.data);
  })
  sub.on('subscribing', function (ctx) {
    console.log('[centrifuge]',`subscribing to order channel ${response?.data.channel}: ${ctx.code}, ${ctx.reason}`);
    if(callbacks?.onSubscribing){
      callbacks.onSubscribing(ctx);
    }
  })
  sub.on('subscribed', function (ctx) {
    console.log('[centrifuge]','subscribed to order channel ' + response?.data.channel, ctx);
    if(callbacks?.onSubscribed){
      callbacks.onSubscribed(ctx);
    }
  })
  sub.on('unsubscribed', function (ctx) {
    console.log('[centrifuge]',`unsubscribed to order channel ${response?.data.channel}: ${ctx.code}, ${ctx.reason}`);
    if(callbacks?.onUnsubscribed){
      callbacks.onUnsubscribed(ctx);
    }
    centrifuge.removeSubscription(sub)
  })
  sub.subscribe();
  return sub;
}

let centrifuge;
const init = async (url) => {
  console.log('CENTRIFUGE INIT!!')
  if(centrifuge){
    centrifuge.disconnect();
  }
  centrifuge = new Centrifuge(url, {
    getToken: obtainToken
  });

  centrifuge.on('connecting', function (ctx) {
    console.log(`[centrifuge]`, `connecting: ${ctx.code}, ${ctx.reason}`);
  }).on('connected', function (ctx) {
    console.log(`[centrifuge]`, `connected over ${ctx.transport}`);


    listenForUpdateChannels((v) => {console.log('callbackUser', v)},
        (v) => {
          console.log('callbackCompany', v)
          if (store.getters['currentUser/isUserTypeRepairer']) {
            if (v.type === "newActivityRecord" || v.type === "updateActivityRecord" || v.type === "removeActivityRecord") {
              store.commit('repairer/dashboard/repairerUpdateActivity', v )
            }
            if (v?.file?.id || v?.fileId) {
              store.commit('repairer/dashboard/repairerUpdateFiles', v )
            }
            if (v.message) {
              store.commit('repairer/dashboard/repairerUpdateMessages', v.message )
            }
            if (v.type === "unreadChatMessageNotification") {
              store.commit('repairer/rfq/setMessageStatus', {id: v.rfqId, value: v.value} )
            }
            if (v.messageId && v.type == "markMessageAsViewed") {
              store.commit('repairer/dashboard/repairerMarkMessageAsRead', v.messageId )
            }

          }
          if (store.getters['currentUser/isUserTypeSupplier']) {
            if (v?.file?.id || v?.fileId) {
              store.commit('supplier/dashboard/supplierUpdateFiles', v )
            }
            if (v.type === "newActivityRecord" || v.type === "updateActivityRecord" || v.type === "removeActivityRecord") {
              store.commit('supplier/dashboard/supplierUpdateActivity', v)
            }
            if (v.message) {
              store.commit('supplier/dashboard/supplierUpdateMessages', v.message )
            }
            if (v.type === "unreadChatMessageNotification") {
              store.commit('supplier/rfq/setMessageStatus', {id: v.rfqToSupplierId, value: v.value} )
            }
            if (v.messageId && v.type == "markMessageAsViewed") {
              store.commit('supplier/dashboard/supplierMarkMessageAsRead', v.messageId )
            }
          }
    });


  }).on('disconnected', function (ctx) {
    console.log(`[centrifuge]`, `disconnected: ${ctx.code}, ${ctx.reason}`);
  }).connect();

}


const listenForUpdateChannels = async (callbackUser = () => {}, callbackCompany = () => {}) => {
  let tokens = await obtainTokensForUpdateChannels()
  if(!tokens?._status){
    return;
  }

  let userSub = centrifuge.getSubscription(tokens.userChannel.channel);
  if(userSub){
    centrifuge.removeSubscription(userSub);
  }

  userSub = centrifuge.newSubscription(tokens.userChannel.channel, {
    token: tokens.userChannel.token,
    getToken: () => {
      return new Promise((resolve, reject) => {
        obtainTokensForUpdateChannels().then((tokens) => {
          if(!tokens?._status){
            reject();
          }
          resolve(tokens.userChannel.token);
        })
      })
    }
  });

  userSub.on('publication',  (ctx) => {
    callbackUser(ctx.data);
  }).on('subscribing', function (ctx) {
    console.log('[centrifuge]',`subscribing to user channel: ${ctx.code}, ${ctx.reason}`);
  }).on('subscribed', function (ctx) {
    console.log('[centrifuge]','subscribed to user channel', ctx);
  }).on('unsubscribed', function (ctx) {
    console.log('[centrifuge]',`unsubscribed from user channel: ${ctx.code}, ${ctx.reason}`);
  }).subscribe();

  for(let cs of companyUpdateSubs){
    cs.unsubscribe();
  }

  companyUpdateSubs = [];
  const companyChannels = [];
  if(tokens.companyChannels && tokens.companyChannels instanceof Array){
    companyChannels.push(...tokens.companyChannels);
  } else {
    companyChannels.push(tokens.companyChannel);
  }

  for(let companyChannel of companyChannels){
    let companySub = centrifuge.getSubscription(companyChannel.channel);
    if(companySub){
      centrifuge.removeSubscription(companySub);
    }
    companySub = centrifuge.newSubscription(companyChannel.channel, {
      token: companyChannel.token,
    });

    companySub.on('publication',  (ctx) => {
      callbackCompany(ctx.data);
    }).on('subscribing', function (ctx) {
      console.log('[centrifuge]',`subscribing to company channel ${companyChannel.channel}: ${ctx.code}, ${ctx.reason}`);
    }).on('subscribed', function (ctx) {
      console.log('[centrifuge]','subscribed to company channel ' + companyChannel.channel, ctx);
    }).on('unsubscribed', function (ctx) {
      console.log('[centrifuge]',`unsubscribed from company channel ${companyChannel.channel}: ${ctx.code}, ${ctx.reason}`);
    }).subscribe();

    companyUpdateSubs.push(companySub);
  }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export {
  init,
  listenForUpdateChannels,
  subscribeToRfqChannel,
  subscribeToOrderChannel,
  subscribeToCreditChannel,
}
