<template>
    <div class="wrapper-vin-date mt-3 mb-1">
        <div class="vin-date">
            <div class="vin-date-item">
              <div class="mr-2">VIN: </div>
              <span>{{ vin }}</span>
            </div>
            <div class="vin-date-item">
              <div class="mr-2">Build Date: </div>
              <span>{{ date }}</span>
            </div>
            <div class="d-flex" :class="{'tracking-code-block': isEdit}">
                <div v-if="!hideTrackCode" class="vin-date-item">Tracking Code:</div>
                <div v-if="!hideTrackCode" :class="{'vin-date-item': !isEdit}">
                    <b-form-input v-if="isEdit" @change="onChangeCode" v-model="codeCopy" class="vin-date-input"></b-form-input>
                    <a target="_blank" :href="codeCopy" v-else>{{ codeCopy }}</a>
                </div>
            </div>
        </div>
        <div class="vin-date left-block ml-0">
          <div v-if="isShowRFQNbr" class="vin-date" style="margin-top: 0px;">
            <div class="vin-date-item" style="padding-right: 0px;">RFQ Number:</div>
            <div>
              <span @click.prevent="goToRfq" class="rfq-number-link" style="display: flex; align-items: center; cursor: pointer; color: #007bff; padding-left: 5px;">{{ rfqNumberCopy }}</span>
            </div>
          </div>
          <div v-if="isShowQuoteNbr" class="vin-date" style="margin-top: 0px;">
            <div class="vin-date-item" style="padding-right: 0px;">
              <span>
                  Quote Nbr:
              </span>
            </div>
            <div class="mr-0">
              <span style="display: flex; align-items: center; margin-left: 5px;">{{ quoteNbrCopy }}</span>
            </div>
          </div>
          <div v-if="isShowOrderNbr" class="vin-date" style="margin: 5px 15px 5px 0px;">
            <div class="vin-date-item" style="padding-right: 0px;">
              <span v-if="isRepairer" >Your Order Nbr:</span>
              <span v-else class="mr-2">Customer Order Number:</span>
            </div>
            <div>
              <span style="display: flex; align-items: center; margin-left: 5px;">{{ orderNumberCopy }}</span>
            </div>
          </div>
          <div v-if="isShowInvoiceNbr" class="vin-date" style="margin-top: 0px;">
            <div class="vin-date-item">
              <span>
                  <span v-if="isRepairer" style="margin-right: 0px;">Supplier</span>
                  Invoice Number:
              </span>
              </div>
            <div class="mr-0 pl-1">
              <template v-if="!isDisabledInvoiceNumber">
                <template v-if="status === 'Cancelled'">
                  <b-form-input
                      readonly
                      :value="'Order Cancelled'"
                      class="vin-date-input invoice-number-input"
                  ></b-form-input>
                </template>
                <template v-else-if="status === 'Denied'">
                  <b-form-input
                      readonly
                      :value="'Order Denied'"
                      class="vin-date-input invoice-number-input"
                  ></b-form-input>
                </template>
                <template v-else>
                  <b-form-input
                      @change="onChangeInvoiceNbr"
                      v-model="invoiceNumberCopy"
                      class="vin-date-input invoice-number-input"
                      :class="{'border-for-invoice-number': !invoiceNumberCopy}"
                  ></b-form-input>
                </template>
              </template>
              <template v-else>
                <a target="_blank" style="display: flex; align-items: center;">{{ invoiceNumberCopy }}</a>
              </template>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'vinDate',
  data () {
    return {
      codeCopy: '',
      invoiceNumberCopy: '',
      quoteNbrCopy: '',
      repairerQuoteNumberCopy: '',
      rfqNumberCopy: '',
      orderNumberCopy: '',
    }
  },
  mounted () {
    this.codeCopy = this.code;
    this.invoiceNumberCopy = this.invoiceNumber;
    this.quoteNbrCopy = this.quoteNumber;
    this.repairerQuoteNumberCopy = this.repairerQuoteNumber;
    this.orderNumberCopy = this.orderNumber;
  },
  methods: {
    onChangeCode () {
      this.$emit('onChangeCode', this.codeCopy)
    },
    onChangeInvoiceNbr () {
      this.$emit('onChangeInvoiceNbr', this.invoiceNumberCopy)
    },
    onChangeRepairerQuoteNumber () {
      this.$emit('onChangeRepairerQuoteNumber', this.repairerQuoteNumberCopy)
    },
    onChangeRFQNbr () {
      this.$emit('onChangeRFQNbr', this.rfqNumberCopy)
    },
    goToRfq () {
      this.$emit('goToRfq')
    },
  },
  props: {
    isRepairer: {
      type: Boolean,
      default: false,
    },
    isDisabledInvoiceNumber: {
      type: Boolean,
      default: false,
    },
    vin: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    isShowInvoiceNbr: {
      type: Boolean,
      default: false,
    },
    isShowQuoteNbr: {
      type: Boolean,
      default: false,
    },
    isShowRFQNbr: {
      type: Boolean,
      default: false,
    },
    isShowOrderNbr: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    invoiceNumber: {
      type: String,
      default: '',
    },
    quoteNumber: {
      type: String,
      default: '',
    },
    orderNumber: {
      type: String,
      default: '',
    },
    repairerQuoteNumber: {
      type: String,
      default: '',
    },
    rfqNumber: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    hideTrackCode: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    code: {
      handler (value) {
        this.codeCopy = value
      },
      immediate: true,
    },
    invoiceNumber: {
      handler (value) {
        this.invoiceNumberCopy = value
      },
      immediate: true,
    },
    quoteNumber: {
      handler (value) {
        this.quoteNbrCopy = value
      },
      immediate: true,
    },
    orderNumber: {
      handler (value) {
        this.orderNumberCopy = value
      },
      immediate: true,
    },
    repairerQuoteNumber: {
      handler (value) {
        this.repairerQuoteNumberCopy = value
      },
      immediate: true,
    },
    rfqNumber: {
      handler (value) {
        this.rfqNumberCopy = value
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.rfq-number-link:hover {
  color: #0056b3 !important;
}

.wrapper-vin-date {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;
}
.vin-date-item {
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.vin-date {
    font-weight: bold;
    display: flex;
    margin-right: 15px;
    flex-wrap: wrap;
    align-items: center;
}

.vin-date a {
    padding-left: 5px;
}

.invoice-number-input {
  width: 135px;
}

.border-for-invoice-number {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0 0px 1px 0px #29bbc1, 0 0 3px 1px #29bbc1;
  }
}

@media screen and (max-width: 1284px) {
    .vin-date {
        flex-direction: column;
        margin-left: 0;
    }
    .vin-date-item {
      margin-left: 0;
    }
    .mobile-text-position {
      display: block;
      padding-top: 7px;
    }
    .tracking-code-block {
      flex-direction: column;
    }
}
</style>
