<template>
  <div id="qr-code-full-region"></div>
</template>

<script>
import {Html5QrcodeScanner, Html5QrcodeScanType} from "html5-qrcode";

export default {
  name: "qrcode-scanner",
  props: {
    qrbox: {
      type: Number,
      default: 250
    },
    fps: {
      type: Number,
      default: 10
    },
  },
  data () {
    return {
      html5QrcodeScanner: null,
    }
  },
  beforeDestroy () {
    this.html5QrcodeScanner.clear();
  },
  mounted () {
    const config = {
      fps: this.fps,
      // qrbox: this.qrbox,
      rememberLastUsedCamera: true,
      // Only support camera scan type.
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
    };
    this.html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config);
    this.html5QrcodeScanner.render(this.onScanSuccess);
  },
  methods: {
    onScanSuccess (decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);
    }
  }
}
</script>

<style scoped>

</style>
