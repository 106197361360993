/*eslint-disable */
import _ from 'lodash'

export function getQuoteNbrs (state) {
  let result = _.forEach(state.quoteNbrs, (nbrs, cid) => {
    _.orderBy(nbrs, (n) => {
      return Number(n.id)
    }, ['asc'])
  })
  return result
}

export function getQuoteNbrsX (state) {
  let result = _.forEach(state.quoteNbrsX, (nbrs, cid) => {
    _.orderBy(nbrs, (n) => {
      return Number(n.id)
    }, ['asc'])
  })
  return result
}

export function getSearchFilterObj (state) {
  return state.searchFilterObj
}

export function isPinnedImages (state) {
  return state.isPinnedImages
}

export function getIsPlayNotificationSound (state) {
  return state.isPlayNotificationSound
}

export function getIsNeedRefreshTable (state) {
  return state.isNeedRefreshTable
}

export function getToken (state) {
  return state.token
}

export function getTokens (state) {
  return state.tokens
}

export function getAllRfqs (state) {
  return _.orderBy(state.items, ['dateDue'], ['desc'])
}

export function getCurrentRFQViewing (state) {
  return _.find(state.items, (itm) => {
    return Number(itm.id) === Number(state.currentRfq.id)
  })?.viewingUsers
}

export function getNewRFQs (state) {
  let rfqs = _.filter(state.items, function (i) {
    return (i.status === 'New' || (i.status === 'Priced' && i.rfqUpdateRequested === 'UpdateRequested'))
  })
  return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getPricedRFQs (state) {
  let rfqs = _.filter(state.items, function (i) {
    return i.status === 'Priced'
  })
  return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getWonRFQs (state) {
  let rfqs = _.filter(state.items, function (i) {
    return i.status === 'Won'
  })
  return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getOrderedRFQs (state) {
  let rfqs = _.filter(state.items, function (i) {
    return i.status === 'Ordered'
  })
  return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getLostRFQs (state) {
  let rfqs = _.filter(state.items, function (i) {
    return i.status === 'Lost'
  })
  return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getClosedRFQs (state) {
  let rfqs = _.filter(state.items, function (i) {
    return i.status === 'Closed' || i.status === 'Decline'
  })
  return _.orderBy(rfqs, ['dateDue'], ['desc'])
}

export function getCurrentRFQ (state) {
  return state.currentRfq
}

export function getFilter (state) {
  return state.filter
}

export function countNewRFQs (state, getters) {
  return getters.getNewRFQs.length
}

export function countPricedRFQs (state, getters) {
  return getters.getPricedRFQs.length
}

export function countWonRFQs (state, getters) {
  return getters.getWonRFQs.length
}

export function countOrderedRFQs (state, getters) {
  return getters.getOrderedRFQs.length
}

export function countLostRFQs (state, getters) {
  return getters.getLostRFQs.length
}

export function getAllRFQsCurrentPage (state) {
  return state.allRFQsCurrentPage
}

export function getNewRFQsCurrentPage (state) {
  return state.newRFQsCurrentPage
}

export function getPricedRFQsCurrentPage (state) {
  return state.pricedRFQsCurrentPage
}

export function getWonRFQsCurrentPage (state) {
  return state.wonRFQsCurrentPage
}

export function getOrderedRFQsCurrentPage (state) {
  return state.orderedRFQsCurrentPage
}

export function getClosedRFQsCurrentPage (state) {
  return state.closedRFQsCurrentPage
}

export function getLostRFQsCurrentPage (state) {
  return state.lostRFQsCurrentPage
}

export function getPerPage (state) {
  return state.perPage
}
