<template>
  <div>
    <div class="toast-header-wrapper">
      <span class="toastr-title-span" v-html="toastTitle"></span>
      <div class="toast-header-buttons">
        <button @click="$emit('dismissAll')" class="dismiss-all">Dismiss All</button>
        <span class="close-toast">×</span>
      </div>
    </div>
    <div v-if="toastTime" class="mt-1 toast-orange">
      <span class="toast-time">Open Time: <span>{{this.toastTime}}</span></span>
    </div>
    <div class="mt-1">
      <span class="toast-text" v-html="toastText"></span>
    </div>
  </div>


</template>

<script>
export default {
  name: "toast-template",
  mounted() {
    if (this.color) {
      let el = document.getElementsByClassName('my-custom-toast-class')[0];
      el.style.background = this.color
    }
  },
  watch: {
    toastText: {
      handler() {
        setTimeout(() => {
          let link = this.$el?.querySelectorAll('.primary-color')?.[0]
          if (link) {
            link.addEventListener("click", () => {
              if (this.type === 'Order' || this.type === 'order') {
                if (this.isUserTypeSupplier) {
                  window.open(window.location.origin + '/s/order/' + this.id, '_blank')
                } else  {
                  window.open(window.location.origin + '/r/order/' + this.id, '_blank')
                }
              } else {
                if (this.isUserTypeSupplier) {
                  if (_.includes(this.toastTitle, 'UPDATE RFQ requested')) {
                    window.open(window.location.origin + '/s/rfq/' + this.id + '#isEdit', '_blank')
                  } else {
                    window.open(window.location.origin + '/s/rfq/' + this.id, '_blank')
                  }
                } else  {
                  window.open(window.location.origin + '/r/rfq/' + this.id, '_blank')
                }
              }
            });
          }
        }, 200)
      },
      immediate: true
    }
  },
  props: {
    toastText: {
      type: String,
      default: '',
    },
    toastTime:{
      type: String,
      default: '',
    },
    toastTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isUserTypeSupplier: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style>
.toastr-title-span .primary-color {
  color: white !important;
}
.toastr-title-span .primary-color:hover {
  color: white !important;
}
</style>
<style scoped>
.dismiss-all {
  background: none;
  border: none;
  min-width: 80px;
}
.toast-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toast-header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
}

.close-toast {
  cursor: pointer !important;
  margin-left: 30px;
  padding-bottom: 5px;
}
</style>
