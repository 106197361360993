<template>
  <div class="rfq-order-page">
    <div class="page-header">
      <h4>{{ pageTitle }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/r">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{path:this.viewRfqsTableLink}">View Quotes</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{path: '/r/rfq/' + $route.params.rfq_id}" class="d-inline-block d-lg-none">View
            {{ rfq.status | maxFour }} Quote
          </router-link>
          <router-link :to="{path: '/r/rfq/' + $route.params.rfq_id}" class="d-none d-lg-inline-block">View
            {{ rfq.status }} Quote
          </router-link>
        </li>
        <li class="breadcrumb-item">Order</li>
      </ol>
    </div>
    <div ref="rfqOrderBody" class="page-body rfq-order-body">
      <good-wizard
          class="rfq-order-steps"
          :steps="steps"
          :step="1"
          ref="RFQOrderWizard">
        <div slot="step-1" class="rfq-order-body">
          <div class="header font-18 font-inter--bold header-margin-left">Order Details</div>

          <div class="container-fluid step-body">
            <div class="row order-action-container">
              <div class="col pl-0 font-12 font-inter--bold d-flex align-items-center">
                Order Action

              </div>
              <div class="col d-flex align-items-center">
                <label class="form-check-inline radio">
                  <input class="form-check-input" type="radio" name="order-action" value="single"
                         :checked="true" @click.prevent="false">
                  <span class="icon"><i class='bx bx-check'></i></span>
                  <span v-if="isSingleSelect" class="text">Single Supplier</span>
                  <span v-else class="text">Multiple Supplier</span>
                </label>
              </div>
              <div class="col d-flex align-items-center">

              </div>
            </div>
            <div class="row mb-30 justify-content-between">
              <div v-if="isShowExpiredText"  style="margin-top: 15px; font-weight: bold">
                <span class="expired-blinking-red" style="color: white">Quote Pricing has Expired </span>
                <span>- Request and Update for latest Pricing</span>
              </div>
              <button v-if="isShowExpiredText" @click="$refs['quote-expired-warning'].show()" style="color: white !important; margin-right: 0px !important"
                      class="clickable input-btn modal-undo-part-line-change-btn-yes modal-undo-part-line-change-btn-update">Get Update
              </button>
            </div>

            <div class="row mb-30 rfq-order-footer d-flex justify-content-end">
              <button
                  v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep !== 0"
                  class="btn btn-outline-primary-light pull-right button-back"
                  type="button"
                  @click="$refs.RFQOrderWizard.goBack()">
                Back
              </button>
              <button
                  v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep === steps.length - 1"
                  class="btn btn-primary pull-right button-next"
                  type="button"
                  @click="orderRFQ">
                Order
              </button>
              <button
                  class="btn btn-outline-primary pull-right button-cancel"
                  type="button"
                  @click="clickCancel">
                Cancel
              </button>
              <button
                  ref="RFQOrderWizardButtonNext"
                  v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep !== steps.length - 1"
                  class="btn btn-primary pull-right button-next"
                  type="button"
                  @click="goNext"
                  :tabindex="4">
                Next
              </button>

            </div>

            <div class="row no-gutter">
              <div class="col-12">
                <rfq-order-summary
                    :rfq="rfq"
                    :step="'order-list'"
                    :paymentTerms="paymentTerms"
                    :supplier-price="supplierPrice">
                </rfq-order-summary>
              </div>
            </div>
          </div>
          <div class="order-list-b-promo-block" :class="computedAdvertBlockLeftPosition">
            <advert-banner
                :advert-max-width="advertMaxWidthStep1"
                :bannerType="'1,2'"
                :rfq-id="$route.params.rfq_id"
                :page-type="'rfq-order'"
            />
          </div>

        </div>
        <div slot="step-2" class="rfq-order-body">
          <div class="header font-18 font-inter--bold">Delivery Details</div>
          <div class="block">
            <div class="block-header">
              Delivery Date and Time Request
            </div>
            <div class="block-body">
              <div class="form-group datetime-field row">
                <label class="col col-form-label">Select Date</label>
                <div class="col-sm-8">
                  <div class="datetime-field-value date-input input-group date d-flex" style="width: auto;">
                    <div class="" style="width: 156.5px">
                      <date-picker :ref="'datePicker'" v-model="order.datetime.date" :isDisabled="nextAvailableRun"></date-picker>
                    </div>
                    <div class="" style="  margin-top: 6px; margin-left: 40px;">
                      <span v-if="todayDate && todayDate == order.datetime.date">Today is: </span>
                      <span v-else>Delivery Due: </span>
                      <b>{{getDayOfWeek(order.datetime.date)}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group datetime-field row">
                <label class="col col-form-label">Select Time</label>
                <div class="col-sm-8 d-flex">
                  <div style="width: 156.5px;">
<!--                  <div class="datetime-field-value time-input input-group date">-->
<!--                    <date-picker v-model="order.datetime.time" :isDisabled="nextAvailableRun" :place-holder-text="'hh:mm'" :isTime="true"></date-picker>-->
                    <multiselect
                        ref="fieldSelectTime"
                        v-model="selectTime"
                        :options="selectTimeOptions"
                        :showLabels="false"
                        :option-height="32"
                        :max-height="203"
                        :close-on-select="true"
                        :disabled="!isSelectTimeActive || nextAvailableRun"
                    >
                    </multiselect>
                  </div>
                  <div style="  margin-top: 6px; margin-left: 40px;">Current Time: <b>{{ currentTime }}</b></div>
                </div>
              </div>

              <div class="form-group mb-30 row">
                <label class="col col-form-label">Next Available Run</label>
                <div class="col-sm-8">
                  <label class="checkbox clearfix">
                    <input class="form-check-input m-0 w-100 h-100" v-model="nextAvailableRun"
                           type="checkbox" :checked="true">
                    <span class="icon mr-0"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>

              <div class="row mb-30 rfq-order-footer d-flex justify-content-end">
                <button
                    v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep !== 0"
                    class="btn btn-outline-primary-light pull-right button-back"
                    type="button"
                    @click="$refs.RFQOrderWizard.goBack()">
                  Back
                </button>
                <button
                    class="btn btn-outline-primary pull-right button-cancel"
                    type="button"
                    @click="clickCancel">
                  Cancel
                </button>
                <button
                    ref="RFQOrderWizardButtonNext"
                    v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep !== steps.length - 1"
                    class="btn btn-primary pull-right button-next"
                    type="button"
                    @click="goNext"
                    :tabindex="4">
                  Next
                </button>
                <button
                    v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep === steps.length - 1"
                    class="btn btn-primary pull-right button-next"
                    type="button"
                    @click="orderRFQ">
                  Order
                </button>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="block-header">
              Delivery Location
            </div>
            <div class="block-body">
              <div class="form-group row">
                <label class="col col-form-label">Location</label>
                <div class="col-sm-8">
                  <multiselect
                      ref="deliveryDetailsLocation"
                      v-model="computedLocation"
                      :options="locations"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      track-by="id"
                      label="name"
                      v-on:keyup.up="changePositionByArrowKey('deliveryDetailsLocation',$event)"
                      v-on:keyup.down="changePositionByArrowKey('deliveryDetailsLocation',$event)"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Unit/Level</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Unit/Level"
                         v-model="order.location.unit_level_lot">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Address Line</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Address Line"
                         v-model="order.location.address">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Suburb</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Suburb"
                         v-model="order.location.suburb">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">State</label>
                <div class="col-sm-8">
                  <multiselect
                      ref="deliveryDetailsState"
                      v-model="state"
                      :options="addressStates"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      track-by="value"
                      label="value"
                      v-on:keyup.up="changePositionByArrowKey('deliveryDetailsState',$event)"
                      v-on:keyup.down="changePositionByArrowKey('deliveryDetailsState',$event)">
                  </multiselect>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Postcode</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control form-control--postcode"
                         placeholder="Postcode" v-model="order.location.postcode">
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label">Country</label>
                <div class="col-sm-8">
                  <multiselect
                      ref="deliveryDetailsCountry"
                      v-model="country"
                      :options="addressCountries"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      track-by="value"
                      label="value"
                      v-on:keyup.up="changePositionByArrowKey('deliveryDetailsCountry',$event)"
                      v-on:keyup.down="changePositionByArrowKey('deliveryDetailsCountry',$event)">
                  </multiselect>
                </div>
              </div>
              <template v-if="supplierPrice && supplierPrice.selectedSuppliers && supplierPrice.selectedSuppliers.length > 1">
                <div v-for="s in supplierPrice.selectedSuppliers" :key="s.id + s.type" class="form-group row">
                  <label class="col col-form-label">Special Instruction <br/> <b>{{s.name}}</b></label>
                  <div class="col-sm-8">
                    <div style="height: 137px" class="form-control">
                      <div class="pb-3"><b>ORDER INSTRUCTION</b></div>
                      <div><b>Deliver Order: </b>{{ s.deliveryType }}</div>
                      <textarea  class="comment-textarea" style="height: 80px" v-model="s.instruction2"></textarea>
                    </div>
                    <!--                  <textarea  class="form-control" style="height: 80px" v-model="order.instruction"></textarea>-->
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="form-group row">
                  <label class="col col-form-label">Special Instruction</label>
                  <div class="col-sm-8">
                    <div style="height: 137px" class="form-control">
                      <div class="pb-3"><b>ORDER INSTRUCTION</b></div>
                      <div><b>Deliver Order: </b>{{ supplierPrice && supplierPrice.selectedSuppliers && supplierPrice.selectedSuppliers[0] ? supplierPrice.selectedSuppliers[0].deliveryType : '' }}</div>
                      <textarea  class="comment-textarea" style="height: 80px" v-model="instruction2"></textarea>
                    </div>
                    <!--                  <textarea  class="form-control" style="height: 80px" v-model="order.instruction"></textarea>-->
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="delivery-details-b-promo-block"  :class="computedAdvertBlockLeftPosition2">
            <advert-banner
                :advert-max-width="advertMaxWidthStep2"
                :bannerType="'1,2'"
                :rfq-id="$route.params.rfq_id"
                :page-type="'rfq-order'"
            />
          </div>

        </div>
<!--         commented by 582-->
<!--        <div slot="step-3" class="rfq-order-body">-->
<!--          <div class="row">-->
<!--            <div class="col p-0">-->
<!--              <div class="header font-18 font-inter&#45;&#45;bold">Order Summary</div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="d-flex justify-content-center">-->
<!--            <rfq-order-summary-->
<!--                :rfq="rfq"-->
<!--                :step="'summary'"-->
<!--                :supplier-price="supplierPrice">-->
<!--            </rfq-order-summary>-->
<!--            <div>-->
<!--              <div class="block">-->
<!--                <div class="block-header">-->
<!--                  Order Details-->
<!--                </div>-->

<!--                <div class="block-body order-details">-->
<!--                  <template v-for="supplier in supplierPrice.selectedSuppliers">-->
<!--                    <div :key="supplier.orderId" class="order-details__item">-->
<!--                      <div class="form-group row">-->
<!--                        <label :for="'order-suppliername-' + supplier.id"-->
<!--                               class="col col-form-label py-0">Supplier Name</label>-->
<!--                        <div class="col-sm-8">-->
<!--                          <input :id="'order-suppliername-' + supplier.id" type="text"-->
<!--                                 class="form-control" placeholder="" :value="supplier.name"-->
<!--                                 disabled>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="form-group row">-->
<!--                        <label :for="'order-total-' + supplier.id"-->
<!--                               class="col col-form-label py-0">Order Total</label>-->
<!--                        <div class="col-sm-8">-->
<!--                          <input :id="'order-total-' + supplier.id" type="text"-->
<!--                                 class="form-control" placeholder=""-->
<!--                                 :value="supplier.orderTotal | formatMoney"-->
<!--                                 disabled>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="form-group row">-->
<!--                        <label :for="'order-number-' + supplier.id"-->
<!--                               class="col col-form-label py-0">Order number link</label>-->
<!--                        <div class="col-sm-8">-->
<!--                          <input :id="'order-number-' + supplier.id" type="text"-->
<!--                                 class="form-control" placeholder=""-->
<!--                                 v-model="orderNumbers[supplier.orderId]">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="form-group row">-->
<!--                        <label :for="'order-payment-' + supplier.id"-->
<!--                               class="col col-form-label py-0">Payment Terms</label>-->
<!--                        <div class="col-sm-8">-->
<!--                          <multiselect-->
<!--                              :id="'order-payment-' + supplier.id"-->
<!--                              ref="orderPayment"-->
<!--                              v-model="paymentTerms[supplier.id]"-->
<!--                              :options="paymentTypes"-->
<!--                              :showLabels="false"-->
<!--                              :option-height="29"-->
<!--                              :max-height="203"-->
<!--                              track-by="value"-->
<!--                              label="value"-->
<!--                              :close-on-select="true"-->
<!--                              v-on:keyup.up="changePositionByArrowKey('orderPayment',$event)"-->
<!--                              v-on:keyup.down="changePositionByArrowKey('orderPayment',$event)">-->
<!--                          </multiselect>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="block">-->
<!--                <div class="block-header">-->
<!--                  Delivery Location-->
<!--                </div>-->
<!--                <div class="block-body">-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col col-form-label">Unit/Level</label>-->
<!--                    <div class="col-sm-8">-->
<!--                      <input type="text" class="form-control" placeholder="Unit/Level"-->
<!--                             v-model="order.location.unit_level_lot" disabled>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col col-form-label">Address Line</label>-->
<!--                    <div class="col-sm-8">-->
<!--                      <input type="text" class="form-control" placeholder="Address Line"-->
<!--                             v-model="order.location.address" disabled>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col col-form-label">Suburb</label>-->
<!--                    <div class="col-sm-8">-->
<!--                      <input type="text" class="form-control" placeholder="Suburb"-->
<!--                             v-model="order.location.suburb" disabled>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col col-form-label">State</label>-->
<!--                    <div class="col-sm-8">-->
<!--                      <multiselect-->
<!--                          ref="deliveryDetailsState"-->
<!--                          :disabled="true"-->
<!--                          v-model="state"-->
<!--                          :options="addressStates"-->
<!--                          :showLabels="false"-->
<!--                          :option-height="29"-->
<!--                          :max-height="203"-->
<!--                          :close-on-select="true"-->
<!--                          track-by="value"-->
<!--                          label="value"-->
<!--                          v-on:keyup.up="changePositionByArrowKey('deliveryDetailsState',$event)"-->
<!--                          v-on:keyup.down="changePositionByArrowKey('deliveryDetailsState',$event)">-->
<!--                      </multiselect>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col col-form-label">Postcode</label>-->
<!--                    <div class="col-sm-8">-->
<!--                      <input type="text" class="form-control form-control&#45;&#45;postcode"-->
<!--                             placeholder="Postcode" v-model="order.location.postcode" disabled>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col col-form-label">Country</label>-->
<!--                    <div class="col-sm-8">-->
<!--                      <multiselect-->
<!--                          ref="deliveryDetailsCountry"-->
<!--                          :disabled="true"-->
<!--                          v-model="country"-->
<!--                          :options="addressCountries"-->
<!--                          :showLabels="false"-->
<!--                          :option-height="29"-->
<!--                          :max-height="203"-->
<!--                          :close-on-select="true"-->
<!--                          track-by="value"-->
<!--                          label="value"-->
<!--                          v-on:keyup.up="changePositionByArrowKey('deliveryDetailsCountry',$event)"-->
<!--                          v-on:keyup.down="changePositionByArrowKey('deliveryDetailsCountry',$event)">-->
<!--                      </multiselect>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <template v-if="supplierPrice && supplierPrice.selectedSuppliers && supplierPrice.selectedSuppliers.length > 1">-->
<!--                    <div v-for="s in supplierPrice.selectedSuppliers" :key="s.id + s.type" class="form-group row">-->
<!--                      <label class="col col-form-label">Special Instruction <br/> <b>{{s.name}}</b></label>-->
<!--                      <div class="col-sm-8">-->
<!--                        <div style="height: 137px" class="form-control">-->
<!--                          <div class="pb-3"><b>ORDER INSTRUCTION</b></div>-->
<!--                          <div><b>Deliver Order: </b>{{ s.deliveryType }}</div>-->
<!--                          <textarea  class="comment-textarea" style="height: 80px" v-model="s.instruction2"></textarea>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                  <textarea  class="form-control" style="height: 80px" v-model="order.instruction"></textarea>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <div class="form-group row">-->
<!--                      <label class="col col-form-label">Special Instruction</label>-->
<!--                      <div class="col-sm-8">-->
<!--                        <div style="height: 137px" class="form-control">-->
<!--                          <div class="pb-3"><b>ORDER INSTRUCTION</b></div>-->
<!--                          <div><b>Deliver Order: </b>{{ supplierPrice && supplierPrice.selectedSuppliers && supplierPrice.selectedSuppliers[0] ? supplierPrice.selectedSuppliers[0].deliveryType : '' }}</div>-->
<!--                          <textarea  class="comment-textarea" style="height: 80px" v-model="instruction2"></textarea>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                  <textarea  class="form-control" style="height: 80px" v-model="order.instruction"></textarea>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->

<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <template v-slot:WizardButtons>
          <div class="rfq-order-footer">
            <button
                ref="RFQOrderWizardButtonNext"
                v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep !== steps.length - 1"
                class="btn btn-primary pull-right button-next"
                type="button"
                @click="goNext"
                :tabindex="4">
              Next
            </button>
            <button
                v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep === steps.length - 1"
                class="btn btn-primary pull-right button-next"
                type="button"
                @click="orderRFQ">
              Order
            </button>
            <button
                class="btn btn-outline-primary pull-right button-cancel"
                type="button"
                @click="clickCancel">
              Cancel
            </button>
            <button
                v-if="$refs.RFQOrderWizard && $refs.RFQOrderWizard.currentStep !== 0"
                class="btn btn-outline-primary-light pull-right button-back"
                type="button"
                @click="$refs.RFQOrderWizard.goBack()">
              Back
            </button>
          </div>
        </template>
      </good-wizard>

      <b-modal ref="quote-expired-warning" size="quote-expired-warning">
        <template v-slot:modal-header>
          <h5 class="modal-title">Quote Expired - Stock and Pricing Update available</h5>
        </template>
        <div class="modal-invite-customer form-container">
          <p class="pl-3 pr-3" style="margin-bottom: 35px; margin-top: 15px;">Partssearch can get suppliers that priced this quote to provide an update in <b>Stock Available</b> and <b>Pricing</b> before you place an order.</p>
          <div class="pl-3 pr-3 d-flex" style="margin-bottom: 25px">
            <div class="" style="width: 40%;">
              <p><b>Request RFQ Update</b></p>
              <p>Suppliers will be notified & given a short time to respond</p>
            </div>
            <div class="expired-modal-supps-section">
              <template v-if="expiredSupps.length >= 1">
                <div  v-if="expiredSupps.length > 1" class="d-flex justify-content-between align-items-center">
                  <div class="">Send to All</div>
                  <div class="">
                    <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                      <input class="form-check-input" v-model="expiredSuppsCheckAll" @change="onExpiredSuppsCheckAllChanged" type="checkbox">
                      <span class="icon clickable" style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                    </label>
                  </div>
                </div>
                <div  v-for="supplier in expiredSupps"   :key="supplier.supplierId" class="d-flex justify-content-between align-items-center">
                  <div class="">   {{ supplier.name }}</div>
                  <div class="">
                    <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                      <input class="form-check-input" v-model="expiredSuppsIds" @change="onExpiredSuppsCheckChanged" :value="supplier.supplierId" type="checkbox">
                      <span class="icon clickable" style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                    </label>
                  </div>
                </div>
              </template>
              <template v-else>All the available requests are already sent</template>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer="{ cancel, yes }">
          <div class="d-flex w-100 justify-content-end mb-3">
            <button class="input-btn modal-undo-part-line-change-btn-no modal-undo-part-line-change-btn-cancel" @click="cancel()">Cancel</button>
            <button v-if="expiredSupps.length >= 1" @click="requestRfqUpdate(expiredSuppsIds); cancel()" :disabled="expiredSuppsIds.length == 0" style="color: white !important; margin-right: 0px !important"
                    class="clickable input-btn modal-undo-part-line-change-btn-yes modal-undo-part-line-change-btn-update">Get Update
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Axios from 'axios'
import SupplierPrice from '../../../components/rfq/models/SupplierPrice'
import Order from '../../../components/rfq/models/Order'
import GoodWizard from '../../../components/vue-good-wizard/vue-good-wizard.vue'
import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import RfqOrderSummary from './rfq-order/rfq-order-summary'
import DatePicker from '../../../components/datePicker'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AdvertBanner from '../../../components/advert-banner.vue'
import * as dayjs from "dayjs";
import Vue from "vue";
import {States} from "../../../store/states";
import TimeZoneConverter from '@/mixins/TimeZoneConverter'

export default {
  name: 'rfq-order',
  mixins: [TimeZoneConverter],
  components: {
    GoodWizard,
    Multiselect,
    RfqOrderSummary,
    DatePicker,
    AdvertBanner,
  },
  props: [
    'selectedParts'
  ],
  data () {
    return {
      isMounted: false,
      countSupplier: 1,
      order: new Order(),
      expiredSuppsIds: [],
      currentTime: null,
      expiredSuppsCheckAll: false,
      isExtensionActive: false,
      rfq: {
        id: null,
        type: 'Manual',
        QPNumber: '',
        number: '',
        comments: '',
        vehicle: {
          rego: '',
          vin: '',
          make: '',
          model: '',
          series: '',
          dom: '',
          colour: '',
          transmission: 'Automatic',
          odometer: '',

        },
        parts: [],
        images: [],
        newImages: [],
        suppliers: [],
        datetime: { date: '', time: '' },
        status: '',
      },
      instruction2: '',
      todayDate: null,
      supplierPrice: {},
      bannerSize: 'skyscrapper',
      steps: [
        {
          label: 'Order Details',
          slot: 'step-1',
          class: 'rfq-order-steps rfq-order-step-1',
          isAdvert: false,
        },
        {
          label: 'Delivery Details',
          slot: 'step-2',
          class: 'rfq-order-steps rfq-order-step-2',
          isAdvert: false,
          options: {
            nextDisabled: true, // control whether next is disabled or not
          },
        },
          // commented by 582
        // {
        //   label: 'Order Summary',
        //   slot: 'step-3',
        //   class: 'rfq-order-steps rfq-order-step-3',
        //   isAdvert: false,
        //   options: {
        //     nextDisabled: true, // control whether next is disabled or not
        //   },
        // },
      ],
      // check if this used
      offers: [],
      location: {},
      state: {},
      country: {},
      paymentTerms: {},
      orderNumbers: {},
      nextAvailableRun: false,
      isSelectTimeActive: false,
      timeOptions: [],
      lastSelectedDate: { date: '', time: '' },
      interval: null,
      selectTime: null,
      timeInterval: null,
    }
  },
  filters: {
    maxFour (str) {
      if (str) {
        return str.substring(0, 4)
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'currentCompany/getDetails',
      addressStates: 'addressStates',
      paymentTypes: 'paymentTypes',
      addressCountries: 'addressCountries',
      locations: 'currentCompany/getLocations',
      currentUser: 'currentUser/getCurrentUserInfo',
      currentUserLocation: 'currentUser/getLocation',
    }),
    computedMinTime () {
      return 0
      let availableSupps = []
      let partLength = this.rfq.parts.length
      if (partLength === 0 || _.isEmpty(this.supplierPrice.selectedSuppliers)) {
        return 0
      }
      let result = null
      _.forEach(this.supplierPrice.selectedSuppliers, (supp) => {
        if (!_.isEmpty(supp.responseLevel)) {
          availableSupps.push(_.cloneDeep(supp))
        }
      })

      availableSupps = _.map(availableSupps, (s) => {
        let startValues = []
        let endValues = []
        s.responseLevel = _.orderBy(s.responseLevel, (itm) => {
          return Number(itm.numberLines)
        }, ['asc'])
        _.forEach(s.responseLevel, (l) => {
          if (partLength >= Number(l.numberLines)) {
            startValues.push(l)
          } else if (partLength < Number(l.numberLines)) {
            endValues.push(l)
          }
        })
        if (_.isEmpty(startValues) && _.isEmpty(endValues)) {
          s.minTime = 0
        } else if (_.isEmpty(startValues)) {
          s.minTime = 0
        } else if (_.isEmpty(endValues)) {
          s.minTime = Number(_.maxBy(startValues, (item) => {
            return Number(item.numberLines)
          }).minTime)
        } else {
          let end = _.maxBy(endValues, (item) => {
            return Number(item.numberLines)
          })
          let startValuesFiltered = _.filter(startValues, (item) => {
            return Number(item.numberLines) < Number(end.numberLines)
          })
          let start = _.maxBy(startValuesFiltered, (item) => {
            return Number(item.numberLines)
          })
          if (start) {
            s.minTime = Number(start.minTime)
          } else {
            s.minTime = 0
          }
        }
        return s
      })
      result = _.maxBy(availableSupps, (s) => {
        return Number(s.minTime)
      })
      if (!_.isEmpty(result)) {
        return Number(result.minTime)
      }
      return 0
    },
    selectTimeOptions () {
      let selectedDate = dayjs(this.order.datetime.date, 'DD/MM/YYYY')
      if (!selectedDate.isToday()) {
        return [
          '08:00 AM',
          '08:30 AM',
          '09:00 AM',
          '09:30 AM',
          '10:00 AM',
          '10:30 AM',
          '11:00 AM',
          '11:30 AM',
          '12:00 PM',
          '12:30 PM',
          '01:00 PM',
          '01:30 PM',
          '02:00 PM',
          '02:30 PM',
          '03:00 PM',
          '03:30 PM',
          '04:00 PM',
          '04:30 PM',
          '05:00 PM',
        ]
      } else {
        let now = dayjs()
        // let now = dayjs('2023-05-23 11:58')
        let timeStart = now.set('hour', 8).set('minute', 0).set('second', 0)
        let times = []
        let time = timeStart

        let minTime = this.computedMinTime ? this.computedMinTime : 30
        console.log('minTime', minTime)

        for (let i = 0; i < 40; i++) {
          time = timeStart.add(i * 15, 'minute')
          let differenceInMinutes = ((time - now) / 1000) / 60
          if (time > now && differenceInMinutes >= minTime) {
            times.push(time.format('hh:mmA'))
          }
        }
        return times
      }

    },
    isCanRequestRfqUpdateToAll () {
      let suppsFiltered = _.filter(this.supplierPrice.orderedSuppliers, (supp) => {
        return this.isSupplierExpired(supp)
      })
      return suppsFiltered.length === this.supplierPrice.orderedSuppliers.length
    },
    expiredSupps() {
      return _.filter(this.supplierPrice?.orderedSuppliersForRequestUpdate, (s) => {
        return this.isSupplierExpired(s) && _.find(this.supplierPrice.selectedSuppliers, (supp) => {return Number(supp.id) === Number(s.id)})
      })
    },
    isShowExpiredText() {
      return this.rfq.status !== 'Ordered' && _.some(this.supplierPrice.selectedSuppliers, (supp) => {
        let flag = false
        if (supp.quoteValidTill) {
          let day1 = dayjs(supp.quoteValidTill);
          let day2 = dayjs.utc();
          let diff = day1.diff(day2, 'day');
          // if (diff <= 0) { //#274 hotfix
          //   bool = true;
          // }
          if (day1 < day2) {
            flag = true;
          }
        }
        return flag;
      })
      return false
    },
    currentDate: function () {
      return moment().subtract(1, 'days')
    },
    computedLocation: {
      get() {
        if (this.location && this.location.id) {
          return this.location
        }
        return this.currentUserLocation;
      },
      set (value) {
          this.location = value;
      }
    },
    isValid() {
      if (!this.nextAvailableRun && _.isEmpty(this.order.datetime.date)) {
        return false;
      }
       let orderLoc = this.order.location;
      if (_.isEmpty(orderLoc.state) || _.isEmpty(orderLoc.country)  || _.isEmpty(orderLoc.address)
          || _.isEmpty(orderLoc.suburb) || _.isEmpty(orderLoc.postcode)) {
        return false;
      }
      return true;
    },
    computedAdvertBlockLeftPosition2(){
      return 'advert-block-left-position-2';
    },
    computedAdvertBlockLeftPosition(){
      if (this.countSupplier == 2)
      {
        return 'advert-block-left-position-2';
      }else if (this.countSupplier == 3)
      {
        return 'advert-block-left-position-3';
      }
      else if (this.countSupplier == 4)
      {
        return 'advert-block-left-position-4';
      }
      return 'advert-block-left-position-1';
    },
    advertMaxWidthStep1(){
      if (this.isMounted) {
        let width = this.$el.clientWidth;
        width = width - (750 + this.countSupplier * 100)
        width = width / 2;
        return width;
      }
      return 0;
    },
    advertMaxWidthStep2(){
      if (this.isMounted) {
        let width = this.$el.clientWidth;
        width = width - 850
        width = width / 2;
        return width;
      }
      return 0;
    },
    isSingleSelect: function () {
      let count = []
      _.forEach(this.supplierPrice.selectedSuppliers, (item)=>{
        count.push(item.id);
      })
      count=_.uniq(count)
      return count.length === 1
    },
    viewRfqsTableLink () {
      let link = '/r/rfqs/#' + (this.rfq.status ? this.rfq.status.toLowerCase() : '')
      return link
    },
    pageTitle () {
      if (this.rfq && this.rfq.vehicle) {
        return `Priced Quote - ${this.rfq.number} | ${this.rfq.vehicle.rego} | ${this.rfq.vehicle.make} ${this.rfq.vehicle.model}`
      } else {
        return 'Priced Quote'
      }
    },
    mobileOptionsClass () {
      if (this.pageTitle.length >= 62 && this.pageTitle.length <= 69) {
        return 'top-15'
      } else {
        return ''
      }
    },
    currentStep () {
      if (this.$refs.RFQOrderWizard) {
        return this.$refs.RFQOrderWizard.currentStep
      } else {
        return 0
      }
    }
  },
  created () {
    this.loadRFQ(this.$route.params.rfq_id)
    if (this.$route?.params?.isExtensionActive) {
      this.isExtensionActive = this.$route.params.isExtensionActive
    }
    this.$eventBus.$on('message-IS-EXTENSION-ACTIVE', this.setExtensionActive);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    this.$eventBus.$off('message-IS-EXTENSION-ACTIVE', this.setExtensionActive);
  },
  updated () {
    if (this.supplierPrice.selectedSuppliers) {
      this.setDynamicWidth()
    } else {
      console.log('[dynamic width] not set')
    }
  },
  mounted() {
    if (!_.isEmpty(this.companyInfo) && this.companyInfo.address.state) {
      this.initTime(this.companyInfo.address.state, this.companyInfo.address.country)
    }
    this.timeInterval = setInterval(() => {
      this.updateCurrentTime(this.companyInfo.address.state, this.companyInfo.address.country)
    }, 1000)
      this.isMounted = true
  },
  methods: {
    setExtensionActive() {
      console.log('EXTENSION ACTIVATED')
      this.isExtensionActive = true
    },
    updateCurrentTime (state = '', country = '') {
      if (_.isEmpty(state) || _.isEmpty(country)) {
        this.currentTime = dayjs(new Date()).format('hh:mm A')
        return
      }
      let address = _.find(this.addressStates, item => {
            return item.key === state
          }),
          searchString = address.value + ' ' + country, //'Moscow RU',
          time = moment(this.getTimeByState('', searchString), 'DD-MM-YYYY HH:mm')
      this.currentTime = time.format('hh:mm A')
    },
    getDayOfWeek(d) {
      let resultD = dayjs(d, 'DD/MM/YYYY').day()
      if (_.isEmpty(d)) {
        resultD = dayjs().day()
      }
      return  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][resultD]
    },
    requestRfqUpdate (suppId) {
      if (suppId === -1 && !this.isCanRequestRfqUpdateToAll) {
        return
      }

      NProgress.start()
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/request-rfq-update', {
        rfqId: this.rfq.id,
        suppliersIds: suppId !== -1 ? suppId : null,
      }).then(response => {
        if (response.data._status) {
          this.$toast.success('Request have sent successfully')
        }
      }).catch(error => {
        this.$toast.error('Error')
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
    },
    isSupplierExpired (supp) {
      let flag = false

      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        if (!s.isSentRequestToUpdate || s.isSentRequestToUpdate !== true) {
          if (s.quoteValidTill && Number(s.supplierId) === Number(supp.supplierId)) {
            let day1 = dayjs(s.quoteValidTill)
            let day2 = dayjs.utc()
            let diff = day1.diff(day2, 'day')
            // if (diff <= 0) {
            //   flag = true;
            // }
            if (day1 < day2) {
              flag = true
            }
          }
        }
      })

      return flag
    },
    onExpiredSuppsCheckAllChanged() {
      if (this.expiredSuppsCheckAll) {
        this.expiredSuppsIds.splice(0, this.expiredSuppsIds.length);
        _.forEach(this.expiredSupps, (s) => {
          this.expiredSuppsIds.push(s.supplierId)
        })
      } else {
        this.expiredSuppsIds = []
      }
    },
    onExpiredSuppsCheckChanged() {
      if (this.expiredSupps.length == this.expiredSuppsIds.length) {
        this.expiredSuppsCheckAll = true
      } else {
        this.expiredSuppsCheckAll = false
      }
    },
    initTime (state = '', country = '') {
      let address = [],
          searchString = '', //'Moscow RU',
          time = null

      if (_.isEmpty(state) || _.isEmpty(country)) {
        time = moment()
      } else {
        address = _.find(States.addressStates, item => {
          return item.key === state
        })
        searchString = address.value + ' ' + country //'Moscow RU',
        time = moment(this.getTimeByState('', searchString), 'DD-MM-YYYY HH:mm')
      }

      this.order.datetime.date = time.format('DD/MM/YYYY')
      this.todayDate = time.format('DD/MM/YYYY')
      this.order.datetime.time = null
      if (time.isAfter(moment('05:00 PM', 'h:mm A'))) {
        console.log('isSelectTimeActive - false', this.getUtcTimeZoneByState(searchString), 'time =>', time.format('h:mm A'))
        this.isSelectTimeActive = true
      } else {
        console.log('isSelectTimeActive - false', this.getUtcTimeZoneByState(searchString), 'time =>', time.format('h:mm A'))
        this.isSelectTimeActive = true
        time.add(2, 'hour')

        let next = this.timeOptions.map(function (s) {
          return moment(s, 'h:mm A')
        }).sort(function (m) {
          return m.valueOf()
        }).find(function (m) {
          return m.isAfter(time)
        })

        if (next) {
          console.log('Next time is', next.format('h:mm A'), 'which is', next.fromNow())
          this.selectTime = next.format('h:mm A')
        } else {
          console.log('No next event')
        }
      }
    },
    getInstructionValue(v, supp) {
      let r = '<div class="pb-3"><b>ORDER INSTRUCTION</b></div>' +
          '<div><b>Deliver Order: </b>' + v + '</div>'
      if (this.supplierPrice?.selectedSuppliers?.length > 1) {
        if (supp.instruction2) {
          r += '<div  class="pt-2">' + supp.instruction2 + '</div>'
        }
      } else {
        if (this.instruction2) {
          r += '<div  class="pt-2">' + this.instruction2 + '</div>'
        }
      }
      return r
    },
    goNext() {
      let currentStep = this.$refs.RFQOrderWizard.getCurrentStep;
      if (currentStep === 0 && _.isEmpty(this.country) && _.isEmpty(this.state)) {
        this.location = this.currentUserLocation;
      }
      if (currentStep === 1 && !this.isValid) {
        this.$toast.error('Please fill in all the fields')
        return
      }
      this.$refs.RFQOrderWizard.goNext()
    },
    setDynamicWidth () {
      // dynamic wizard__body width by how many supplier
      const wizardBody = this.$refs.RFQOrderWizard.$refs['wizard-body']
      let countSupplier = 0
      // remove all count classes
      wizardBody.classList.remove('supplier-count-1')
      wizardBody.classList.remove('supplier-count-2')
      wizardBody.classList.remove('supplier-count-3')
      wizardBody.classList.remove('supplier-count-4')
      wizardBody.classList.remove('supplier-count-5')

      Object.keys(this.supplierPrice.selectedSuppliers).forEach(() => {
        countSupplier += 1
      })
      console.log('[dynamic width] is set for countSupplier:', countSupplier)
      switch (countSupplier) {
        case 1:
          wizardBody.classList.add('supplier-count-1')
          break
        case 2:
          wizardBody.classList.add('supplier-count-2')
          break
        case 3:
          wizardBody.classList.add('supplier-count-3')
          break
        case 5:
          wizardBody.classList.add('supplier-count-5')
          break
        case 4:
        default:
          wizardBody.classList.add('supplier-count-4')
      }
      this.countSupplier = countSupplier;
    },
    loadRFQ: async function (id) {
      return Axios.get('/ir/repairer/rfq/' + id,)
          .then(response => {

            let rfq = response.data.rfq,
                parts = rfq.parts,
                manualParts = rfq.manualParts
            if (!_.isEmpty(manualParts)) parts = parts.concat(manualParts)
            let supplierPrice = new SupplierPrice(rfq.parts_to_suppliers, parts, rfq.preOrders, [], rfq.status),
                terms = {},
                numbers = {}
            _.forEach(supplierPrice.selectedSuppliers, supplier => {
              terms[supplier.id] = this.paymentTypes[0]
              numbers[supplier.orderId] = supplier.orderNumber
            })
            this.paymentTerms = terms
            this.orderNumbers = numbers

            this.order.setRfqId(id)
            this.rfq = rfq
            this.supplierPrice = supplierPrice
            this.supplierPrice.orderParts();
            window.postMessage({
              type: 'CONNECTED_ESTIMATE_DATA_INIT',
              data: this.rfq?.extQps || null
            })
            return this.rfq
          })
          .catch(error => {
            console.log(error)
          })
    },
    // Wizard
    clickCancel: function () {
      Axios.post('/ir/repairer/order/pre-created/cancel', { rfqId: this.rfq.id, }
      ).then(response => {
        this.$toast.success('Order has been cancelled')
        this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: this.rfq.id } })
      }).catch(error => {
        this.$toast.success('Order hasn\'t been cancelled')
        console.log(error)
      })
    },
    orderRFQ: function () {
      if (!this.isValid || _.isEmpty(this.paymentTerms)) {
        this.$toast.error('Fill in all the fields')
        return
      }

      let instructions = []
      _.forIn(this.rfq.preOrders, (order) => {
        let supp = _.find(this.supplierPrice.selectedSuppliers, (s) => {
          return Number(s.rfqToSupplierId) === Number(order.rfqToSupplierId)
        })
        instructions.push({
          orderId: order.id,
          instruction: this.getInstructionValue(supp.deliveryType, supp),
          deliveryType: _.toLower(supp.deliveryType)
        })
      })
      this.order.instructions = instructions

      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/order', this.order).then(response => {
        console.log('debug', response)
        if (response.data && response.data.status && (response.data.status == true || response.data._status == true)) {
          this.$toast.success('Order has been created')
          this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: this.rfq.id } })
          this.$store.dispatch('repairer/rfq/init', {}, { root: true })
          this.$store.dispatch('repairer/order/init', {}, { root: true })
          this.$store.dispatch('repairer/credit/init', {}, { root: true })

          if (this.rfq?.extQps?.sourceSystem === 'PARTSBRIDGE_IBODYSHOP' && this.isExtensionActive && this.rfq?.properties?.ordersAutosendToQps) {
            window.postMessage({
              type: 'partsbridge-IBODYSHOP_CREATE_ORDER',
              data: {
                rfqId: this.rfq.id,
                qpsSystem: this.rfq?.extQps
              }
            })
          }

          return
        }
        this.$toast.error('Order hasn\'t been created')
      }).catch(error => {
        this.$toast.error('Order hasn\'t been created')
        console.log(error)
      })
    },
    // check usage if this method used
    checkOfferForOrder: function (partId, offerId) {
      if (this.ifOfferChecked(partId, offerId)) {
        _.remove(this.offers, function (i) {
          return i.offerId == offerId
        })
        this.offers.push({ partId: partId, offerId: 0 })
      } else {
        _.remove(this.offers, function (i) {
          return i.offerId == offerId
        })
        this.offers.push({ partId: partId, offerId: offerId })
      }
    },
    // also this
    ifOfferChecked: function (partId, offer) {
      if (this.offers && offer && offer.id) {
        if (_.findIndex(this.offers, { partId: partId, offerId: offer.id }) == -1) {
          return false
        }
        return true
      }
      return false
    },
    changePositionByArrowKey: function (ref, event) {
      let el = this.$refs[ref]
      console.log('el', el)
      console.log('event', event)
      if (el) {
        el.focus()
      }
    },
  },
  watch: {
    // locations: {
    //   handler: function (val) {
    //     if (!_.isEmpty(val)) {
    //       this.location = val[0]
    //     }
    //   },
    //   deep: true
    // },
    selectTime (val) {
      this.order.datetime.time = val
    },
    location: {
      handler: function(val) {
        if (val) {
          this.order.setLocation(val)
          this.state = _.find(this.addressStates, (item) => {
            return val.state === item.key
          })
          this.country = _.find(this.addressCountries, (item) => {
            return this.companyInfo.address.country === item.key
          })
        } else {
          // when null
          this.order.setLocation()
          this.state = {}
          this.country = {}
        }
      },
      deep: true,
    },
    state (val) {
      this.order.location.state = val.key
    },
    country (val) {
      this.order.location.country = val.key
    },
    paymentTerms: {
      handler: function (val) {
        _.forEach(val, (term, key) => {
          this.order.setPaymentTerms(key, term.key)
        })
      },
      deep: true
    },
    orderNumbers: {
      handler: function (val) {
        _.forEach(val, (val, key) => {
          _.forEach(this.rfq.preOrders, order => {
            if (order.id === key && order.number !== val) {
              this.order.setOrderNumber(key, val)
            }
          })
        })
      },
      deep: true
    },
    companyInfo (val) {
      this.initTime(val.address.state, val.address.country)
    },
    nextAvailableRun (val) {
      this.order.nextAvailableRun = val
      if (val) {
        this.lastSelectedDate = this.order.datetime
        this.order.datetime = {
          date: '',
          time: ''
        }
      } else {
        this.order.datetime = this.lastSelectedDate
      }
    }
  }
}
</script>

<style>
  .rfq-order-page .datetime-field-value .form-control {
    padding-left: 10px !important;
  }
  .expired-blinking-red {
    animation-name: blinking-red;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: 250ms;
  }
  @keyframes blinking-red {
    20% {
      color: #F44236;
      opacity: 1;
    }
    80% {
      color: #F44236;
      opacity: 1;
    }
  }
</style>
<style scoped>
.form-check-inline .bx-check{
  background-color: #78b0ba;
  color: white !important;
  border-radius: 3px;
}
.clearfix .bx-check{
  background-color: #78b0ba;
  color: white !important;
  border-radius: 3px;
  height: 24px;
}
.modal-undo-part-line-change-btn-cancel{
  width: 100px !important;
}
.modal-undo-part-line-change-btn-update{
  width: 110px !important;
  background-color: #F59345 !important;
  border-color: #F59345 !important;
}
.modal-undo-part-line-change-btn-cancel:hover{
  background-color: #f8f8f8 !important;
}
.modal-undo-part-line-change-btn-update:hover{
  background-color: #f9be8f !important;
  border-color: #f9be8f !important;
}
.comment-textarea {
  padding: 0px;
  margin-top: 5px;
  border: none;
  color: #1c1f39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  resize: none;
}

.order-list-multiselect {
  min-width: 100px;
  width: 100px;
}
.order-list-multiselect >>> .multiselect__single{
  min-width: 60px;
}
.rfq-order-body >>> .supplier-count-1 .rfq-order-step-3{
  width: 1000px;
}
.rfq-order-body >>> .supplier-count-2 .rfq-order-step-3{
  width: 1170px;
}
.rfq-order-body >>> .supplier-count-3 .rfq-order-step-3{
  width: 1270px;
}
.rfq-order-body >>> .supplier-count-4 .rfq-order-step-3{
  width: 1390px;
}
.rfq-order-body >>> .supplier-count-5 .rfq-order-step-3{
  width: 1490px;
}
.rfq-order-body >>> .wizard__step__line{
  background-color: #79B1BB;
  height: 10px;
  position: absolute;
  width: 30%;
  left: 35%;
  top: 17px;
  display: block;
  border-radius: 5px 0 0 5px;
}
</style>

<!--<style lang="scss">-->
<!--    @import "../../../styles/style-views";-->
<!--</style>-->
<style scoped>
.advert-block-left-position-1 {
  left: 890px !important;
}
.advert-block-left-position-2 {
  left: 1000px !important;
}
.advert-block-left-position-3 {
  left: 1110px !important;
}
.advert-block-left-position-4 {
  left: 1220px !important;
}
.button-back:hover,
.button-cancel:hover {
  background-color: white !important;
}

.button-cancel:hover {
  border-color: rgba(28, 31, 57, 0.25) !important;
}

@media screen and  (max-width: 1980px) {
  .order-list-b-promo-block{
    display: none;
  }
}
@media screen and  (max-width: 1770px) {
  .delivery-details-b-promo-block{
    display: none;
  }
}

</style>

