<template>
    <div class="quote-pricing-progress">
        <div class="all">
            <div class="header d-flex align-self-center">
                <div class="header-name mr-auto align-self-center">{{ title }}</div>
            </div>
            <div class="steps mobile-scroll">
                <ul class="wizard__steps">
                    <li class="wizard__step"
                        :class="{
                            'active':  currentStep >= index,
                            'current': currentStep === index,
                            'new-request': currentStep === 0,
                        }"
                        :style="stepStyle"
                        v-for="(step, index) of steps" :key="index">
                        <span class="wizard__step__line"></span>
<!--                        <span class="wizard__step__indicator"><i class='bx bx-check'></i></span>-->
                        <span class="wizard__step__label">
                            <div>{{ step.label }}</div>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="wizard__divider"></div>
    </div>
</template>

<script>
    /*eslint no-param-reassign:0*/

    export default {
    name: 'quote-pricing-progress',
    props: {
        steps: {},
        title: {
            type: String,
            default: 'Quote Pricing Progress'
        },
        previousStepLabel: {default: 'Back'},
        nextStepLabel: {default: 'Next'},
        finalStepLabel: {default: 'Save'},
        onNext: {},
        onBack: {},
        isMobileOn: {default: true},
        step: {default: 1},
    },
    watch: {
        steps: {
            handler() {
                this.parseOptions();
            },
            immediate: true,
        }
    },

    data() {
        return {
            isMounted: false,
            resizer: null,
            isMobile: false,
            options: []
        };
    },
    computed: {
        currentStep() {
            console.log('this.step ================== ' + this.step)
            return this.step - 1;
        },
        stepStyle() {
            //if (this.isMobile) {
            //    return {
            //        width: '100%',
            //    };
            //}

            return {
                width: `120px`,
            };
        },
        mobileArrowPosition() {
            return 'calc(50% - 14px)';
        },
        arrowPosition() {
            if (this.isMobile) {
                return this.mobileArrowPosition;
            }
            var stepSize = 100 / this.steps.length;
            var currentStepStart = stepSize * this.currentStep;
            var currentStepMiddle = currentStepStart + (stepSize / 2);
            if (this.steps.length == 1)
                return 'calc(' + currentStepMiddle + '%)';
            else
                return 'calc(' + currentStepMiddle + '% - 14px)';
        },
        currentSlot() {
            return this.steps[this.currentStep].slot;
        },
        getCurrentStep() {
            return this.currentStep;
        },
    },
    methods: {
        goTo(step) {
            if (Number.isInteger(step)
                && step < this.steps.length
                && step >= 0) {
                this.currentStep = step;
            }
        },

        parseOptions() {
            this.options = [];
            for (let i = 0; i < this.steps.length; i++) {
                this.options.push(this.steps[i].options ? this.steps[i].options : {});
            }
        },

        handleResize() {
            if (this.resizer) {
                clearTimeout(this.resizer);
            }
            let param = 620;
            if (!$('body').hasClass('compact-sidebar')) {
                param = param - 220;
            }
            this.resizer = setTimeout(() => {
                this.isMobile = (this.$el.clientWidth < param) && (this.isMobileOn);
            }, 100);
        },
    },
    mounted() {
        //    this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style>
.quote-pricing-progress .all {
    min-height: 113px;
    margin: 15px;
}

.quote-pricing-progress .header {
    margin-bottom: 10px;
    overflow: initial !important;
}

.quote-pricing-progress .steps {
    min-width: 418px;
}

.quote-pricing-progress .header .header-name {
    color: #1C1F39;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

.quote-pricing-progress .wizard__steps {
    display: flex;
    flex-wrap: nowrap;
}

@media screen and (max-width: 1200px) {
    .quote-pricing-progress .wizard__steps {
        min-width: 870px;
        padding-bottom: 80px;
    }

    .supplier-rfq-view-page .quote-pricing-progress .wizard__steps {
        min-width: 650px;
        padding-bottom: 100px;
    }

    .supplier-rfq-view-page .quote-pricing-progress .steps  {
        min-width: auto;
    }

    .part-list-qoute-pricing-progress {
        min-width: auto !important;
    }
}

@media (max-width: 768px) {
    .V3 .rfq-view-page .tab-content-table {
        overflow-x: hidden;
    }
}

@media screen and (max-width: 950px) {
    .quote-pricing-progress .steps  {
        overflow-y: hidden !important;
    }
}


</style>
