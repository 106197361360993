<script>
    //import SiteFooter from './site-footer';

    export default {
        name: 'site-content',
        components: {
        //    SiteFooter
        }
    };
</script>

<template>
    <div class="site-content" id="site-content">
        <!-- Content -->
      <span class="site-content-loading">Loading...</span>
        <div id="site-content-area" class="content-area">
          <div class="container-fluid">
            <router-view :key="$route.fullPath">Loading...</router-view>
          </div>
        </div>
        <!-- Footer -->

    </div>
</template>

<style>
.site-content-loading {
  display: none;
}
.site-content.loading-company .site-content-loading {
  display: inline-block;
  margin: 15px;
  font-weight: bold;
}
</style>
