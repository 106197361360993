<template>
    <div class="tab-table">
        <p class="font-weight-bold">{{ title }}</p>
        <b-table
            responsive
            hover
            :items="items"
            :fields="fields"
            :filter="filter"
            @row-clicked="handleRowClicked"
        >
            <template v-slot:cell(actions)="data"> </template>

            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>

          <template #cell(location)="data">
            <span v-html="data.item.location"></span>
          </template>

            <template v-slot:table-caption>
                <b-button block
                    class="text-align-center tab-table__link tab-table__link--more"
                    @click="handleViewMoreClicked"
                    variant="link">
                    View More
                    <i class="bx bx-right-arrow-alt"></i>
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: ["title", "items", "fields", "filter"],
    methods: {
        handleRowClicked(row) {
            this.$emit("row-clicked", row);
        },
        handleViewMoreClicked() {
            this.$emit("view-more-clicked");
        }
    }
};
</script>

<!--<style lang="scss">-->
<!--    @import "../styles/style-views.scss";-->
<!--</style>-->
