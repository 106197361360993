/*eslint-disable */
import _ from 'lodash';

export function getToken(state) {
    return state.token;
}

export function getTokens(state) {
    return state.tokens;
}


export function getSearchFilterObj(state) {
    return state.searchFilterObj;
}

export function getAllOrders(state) {
    return state.items;
}

export function getOpenOrders(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'Open')
    });
    // let ids = state.Open;
    // let orders = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return orders;
}

export function getProcessingOrders(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'Processing')
    });
    // let ids = state.Processing;
    // let orders = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return orders;
}

export function getPartsShippedOrders(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'Shipped')
    });
    // let ids = state.Shipped;
    // let orders = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return orders;
}

export function getPartialDeliveredOrders(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'PartialDelivered')
    });
    // let ids = state.Shipped;
    // let orders = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return orders;
}

export function getPartsDeliveredOrders(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'Delivered')
    });
    // let ids = state.Delivered;
    // let orders = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return orders;
}

export function getCompletedOrders(state) {
    return _.filter(state.items, function (i) {
        return (i.status === 'Completed' || i.status === 'Denied' || i.status === 'Cancelled')
    });
    // let ids = state.Completed;
    // let orders = _.filter(state.items, function (i) {
    //     return _.indexOf(ids, i.id) >= 0;
    // });
    // return orders;
}

export function getFilter(state) {
    return state.filter;
}

// export function countOpenOrders(state, getters) {
//     // let ids = state.Open;
//     return getters.getOpenOrders(state).length;
// }

// export function countProcessingOrders(state, getters) {
//     // let ids = state.Processing;
//     // return ids.length;
//     return getters.getProcessingOrders(state).length;
// }
//
// export function countPartsShippedOrders(state, getters) {
//     // let ids = state.PartsShipped;
//     // return ids.length;
//     return getters.getPartsShippedOrders(state).length;
// }

export function ordersFromUpdate(state) {
    return state.ordersFromUpdate;
}

// export function countPartsDeliveredOrders(state, getters) {
//     // let ids = state.PartsDelivered;
//     // return ids.length;
//     return getters.getPartsDeliveredOrders(state).length;
// }
//
// export function countCompletedOrders(state, getters) {
//     // let ids = state.Completed;
//     // return ids.length;
//     return getters.getCompletedOrders(state).length;
// }

export function perPageAll(state) {
    return state.perPageAll;
}

export function perPageOpen(state) {
    return state.perPageOpen;
}

export function perPageProcessing(state) {
    return state.perPageProcessing;
}

export function perPageRoute(state) {
    return state.perPageRoute;
}

export function perPageDelivered(state) {
    return state.perPageDelivered;
}

export function perPagePartialDelivery(state) {
    return state.perPagePartialDelivery;
}

export function perPageCompleted(state) {
    return state.perPageCompleted;
}

export function orderAllCurrentPage(state) {
    return state.orderAllCurrentPage;
}

export function orderOpenCurrentPage(state) {
    return state.orderOpenCurrentPage;
}

export function orderProcessingCurrentPage(state) {
    return state.orderProcessingCurrentPage;
}

export function orderRouteCurrentPage(state) {
    return state.orderRouteCurrentPage;
}

export function orderDeliveredCurrentPage(state) {
    return state.orderDeliveredCurrentPage;
}

export function orderPartialDeliveryCurrentPage(state) {
    return state.orderPartialDeliveryCurrentPage;
}

export function orderCompletedCurrentPage(state) {
    return state.orderCompletedCurrentPage;
}
