/*eslint-disable */
import camelCase from 'lodash/camelCase';

const requireModule = require.context(
    '.',
    true,
    /^(?!.*(actions|mutations|getters|index|repairer)).*\.js$/
);

const modules = {};

requireModule.keys().forEach(fileName => {
    modules[camelCase(fileName.split('/')[1].replace(/(\.\/|\.js)/g, ''))] = {
        namespaced: true,
        ...requireModule(fileName).default
    };
});

const mutations = {};
const actions = {
    init(context) {
        //console.log('init repairer')
        context.dispatch('partList/loadPartList', {}, { root: true });
        context.dispatch('repairer/rfq/init', {}, { root: true });
        context.dispatch('repairer/supplier/init', {}, { root: true });
        context.dispatch('repairer/order/init', {}, { root: true });
        context.dispatch('repairer/credit/init', {}, { root: true });
        context.dispatch('repairer/dashboard/init', {}, { root: true });
    }
};
const state = {};
const getters = {};

export default {
    namespaced: true,
    modules: modules,
    actions: actions,
    state: state,
    getters: getters,
    matations: mutations,
};
