/*eslint-disable */
import Axios from 'axios'
import {
  REPAIRER_SUPPLIER_FULL_UPDATED, REPAIRER_SUPPLIER_FULL_UPDATED_SUPPLIER_GROUP,
  REPAIRER_SUPPLIER_IDS_UPDATE_OTHER,
  REPAIRER_SUPPLIER_IDS_UPDATE_PREFERED,
  REPAIRER_SUPPLIER_IDS_UPDATE_SPECIALIST,
  REPAIRER_SUPPLIER_REMOVED,
  REPAIRER_TIER_UPDATED,
  REPAIRER_SUPPLIER_UPDATED,
  REPAIRER_INSURER_UPDATED
} from '../../../mutation-types'
import _ from 'lodash'

export function getSupplierByIds (context, payload) {
  Axios.post('/ir/repairer/suppliers', { ids: payload })
    .then(response => {
      context.commit(REPAIRER_SUPPLIER_UPDATED, response.data.suppliers)
      context.commit(REPAIRER_SUPPLIER_REMOVED, response.data.disabledSuppliers)
      context.commit(REPAIRER_TIER_UPDATED, response.data.tier1)
      context.commit(REPAIRER_INSURER_UPDATED, response.data.insurers)
    })
    .catch(error => {
      console.log(error)
    })
}

export function getSuppliersForTableView (context, timestamp = 0) {
  let url = '/ir/repairer/suppliers'
  if (timestamp > 0) url = '/ir/repairer/suppliers/' + timestamp
  Axios.get(url)
    .then(response => {
      if (response.data._status === true) {
        context.commit(REPAIRER_SUPPLIER_UPDATED, response.data.suppliers)
        context.commit(REPAIRER_SUPPLIER_REMOVED, response.data.disabledSuppliers)
        context.commit(REPAIRER_TIER_UPDATED, response.data.tier1)
        context.commit(REPAIRER_INSURER_UPDATED, response.data.insurers)
      }
    })
    .catch(error => {
      console.log(error)
    })

  // let url = '/ir/repairer/suppliers/init';
  // if(timestamp > 0 ) url = '/ir/repairer/suppliers/'+timestamp;
  //   Axios.post(url)
  //       .then(response => {
  //           context.commit(REPAIRER_SUPPLIER_IDS_UPDATE_PREFERED, response.data.prefered);
  //           //context.commit(REPAIRER_SUPPLIER_IDS_UPDATE_SPECIALIST, response.data.specialist);
  //           context.commit(REPAIRER_SUPPLIER_IDS_UPDATE_OTHER, response.data.other);
  //           let ids = [];
  //           Array.prototype.push.apply(ids, response.data.prefered);
  //          // Array.prototype.push.apply(ids, response.data.specialist);
  //           Array.prototype.push.apply(ids, response.data.other);
  //           //  console.log(ids);
  //           getSupplierByIds(context, ids);
  //       })
  //       .catch(error => {
  //
  //           console.log(error);
  //       });
}

export function init (context, payload = 0) {
  return getSuppliersForTableView(context, payload)
}

export function checkSupplier (context, supplier_id) {
  let supplier = _.find(context.state.suppliers.itemsFull, { 'id': supplier_id })
  if (!supplier) {
    loadSupplier(context, supplier_id)
  }
}

export function loadSupplier (context, supplier_id) {
  return Axios.post('/ir/repairer/supplier/' + supplier_id)
    .then(response => {
      context.commit(REPAIRER_SUPPLIER_FULL_UPDATED, response.data.supplier)
    })
    .catch(error => {

      console.log(error)
    })
}

export function changeSupplierGroup (context, { type, item }) {
  let id = item.id
  let changeItem = Object.assign({}, item)
  Axios.post('/ir/repairer/supplier/type', { supplier_id: id, type: type })
    .then(response => {
      if (response.data.status === true) {
        changeItem.supplierGroup = type
        context.commit(REPAIRER_SUPPLIER_UPDATED, [changeItem])
        if (changeItem._dataType === 'short') {
          context.commit(REPAIRER_SUPPLIER_FULL_UPDATED_SUPPLIER_GROUP, {id: changeItem.id, type: type})
        }

        return true
      }
    })
    .catch(error => {
      console.log(error)
      return false
    })
}
