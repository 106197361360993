import Part from './Part'
import _ from 'lodash'
import accounting from 'accounting'

export default class RFQ {

  /**
   * Constructor
   * @param rfq
   */
  constructor (rfq = {}) {

    let suppliers = {}
    let order = {}

    if (!_.isEmpty(rfq.partsToSuppliers)) {
      _.forEach(rfq.partsToSuppliers, (supplier, key) => {
        suppliers[key] = supplier
      })
    }

    if (!_.isEmpty(rfq.order)) {
      order = rfq.order
    }

    this.id = Number(rfq.id)
    this.lock = rfq.lock;
    this.assignedTo = rfq.assignedTo;
    this.repairer = rfq.repairer
    this.isRebateEnabled = rfq.isRebateEnabled
    this.rebatePercent = rfq.rebatePercent
    this.rfqUpdateRequested = rfq.rfqUpdateRequested
    this.rfqUpdateRequestedOn = rfq.rfqUpdateRequestedOn
    this.number = rfq.number
    this.QPNumber = rfq.QPNumber
    this.type = rfq.type
    this.claimNumber = rfq.claimNumber
    this.comments = rfq.comments
    this.quoteOpenDays = rfq.quoteOpenDays
    this.vehicle = rfq.vehicle
    this.location = rfq.location
    this.rego = rfq.rego
    this.statusId = rfq.statusId
    this.previousNumber = rfq.previousNumber
    this.supplierPreviousQuoteNumbers = rfq.supplierPreviousQuoteNumbers
    this.extensionTime = (!_.isEmpty(rfq.extensionTime)) ? rfq.extensionTime : {}
    this.status = rfq.status
    this.parts = this.partsWithLinks(rfq.parts)
    this.estimator = (!_.isEmpty(rfq.estimator)) ? String(rfq.estimator) : ''
    this.images = rfq.images
    if (rfq.files) {
      this.files = rfq.files
    } else {
      this.files = []
    }
    this.undoAvailable = rfq.undoAvailable
    this.suppliers = suppliers
    this.dueCountdown = rfq.dueCountdown
    this.discountsApplied = (rfq.discountsApplied) ? Number(rfq.discountsApplied) : 0
    this.partsDeliveryETA = rfq.partsDeliveryETA
    this.quoteValidTill = rfq.quoteValidTill
    this.quoteDateValidTill = rfq.quoteDateValidTill
    this.cuttOff = rfq.cuttOff
    this.dateDue = rfq.dateDue
    this.datePriced = rfq.datePriced
    this.order = order
    this.supplierBusinessName = rfq.supplierBusinessName
    this.supplierId = rfq.supplierId
    this.dmsQuoteNumber = rfq.dmsQuoteNumber
    this.supplierQuoteNumber = rfq.supplierQuoteNumber
    this.repairerQuoteNumber = rfq.repairerQuoteNumber
    this.properties = rfq.properties || {}
    this.directOrder = rfq.directOrder || {}
    this.paintCodeForRepair = rfq.paintCodeForRepair
    this.scheduleTime = rfq.scheduleTime
    this.sorting = {
      type: 'qty',
      direction: 'asc'
    }
    this.partsType = (rfq.partsType) ?? ''

  }

  /**
   * Get delivery to address
   * @return {string} [address], [Suburb],[State],[Postcode]
   */
  get deliveryTo () {
    let result = ''
    if (!_.isEmpty(this.order) && !_.isEmpty(this.order.deliveryTo) && !_.isEmpty(this.order.deliveryTo.address))
      result = this.order.deliveryTo.address + ', ' + this.order.deliveryTo.suburb + ', '
        + this.order.deliveryTo.state + ', ' + this.order.deliveryTo.postcode
    return result
  }

  /**
   * Return total price
   * @returns {number}
   */
  get total () {
    let total = 0
    _.forEach(this.parts, (part) => {
      if (!part.isLinkedWithNoCost) {
        total += parseFloat(accounting.toFixed(parseFloat(part.priceWithCount), 2))
      }
    })
    return _.round(total, 2)
  }

  /**
   * Return subtotal price
   * @returns {number}
   */
  get subtotal () {
    let discount = Number(this.discountsApplied)
    return (discount) ? this.total - (this.total / 100) * discount : this.total
  }

  /**
   * Get pricing completed percent
   * @returns {number}
   */
  get pricingCompletedPercent () {
    let contOfParts = this.parts.length,
      partsCountWithPrice = 0,
      result = 0

    _.forEach(this.parts, (part) => {
      //  if (Number(part.price) && !_.isEmpty(part.number)) partsCountWithPrice++;
      if (Number(part.price)) partsCountWithPrice++
    })
    result = partsCountWithPrice / (contOfParts / 100)
    return Math.round(result)
  }

  /**
   * Get parts in stock percent
   * @returns {number}
   */
  get partsInStock () {
    let parts = _.filter(this.parts, function (p) {
      return !p.isLinkedWithNoCost && !p.isDelivery && Number(p.stock) !== 7
    })
    let contOfParts = parts.length,
      partsInStock = 0,
      result = 0

    _.forEach(parts, (part) => {
      if (part.isInStock && !part.isDelivery) partsInStock++
    })
    result = partsInStock / (contOfParts / 100)
    return Math.round(result)
  }

  get partsInStockWithOutPercents () {
    let parts = _.filter(this.parts, function (p) {
      return !p.isLinkedWithNoCost && !p.isDelivery && Number(p.stock) !== 7
    })
    let countOfParts = parts.length,
      partsInStock = 0

    _.forEach(parts, (part) => {
      if (part.isInStock && !part.isDelivery) partsInStock++
    })
    return partsInStock + ' of ' + countOfParts
  }

  /**
   * Get parts gst
   * @return {number}
   */
  get gst () {
    return parseFloat(Math.abs(this.subtotal * 0.1).toFixed(2))
  }

  /**
   * Return parts list
   * @param parts
   * @return {*}
   */
  partsWithLinks (parts) {
    let result = []
    _.forEach(parts, part => {
      result.push(new Part(part))
    })
    result = _.orderBy(result, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
    return result
  }

  /*  partsWithLinks(parts) {
        let partsArray = JSON.parse(JSON.stringify(parts)),
            result = [],
            rootParts = _.filter(partsArray, part => {
                return !Number(part.linkId);
            });

        _.forEach(rootParts, part => {
            let partsLinked = _.filter(partsArray, partLinked => {
                return partLinked.linkId === part.id;
            });
            result.push(new Part(part));
            _.forEach(partsLinked, partLinked => {
                result.push(new Part(partLinked));
            });
        });
        return result;
    }*/

  /**
   * Check is Extension available
   * @return {boolean}
   */
  isExtensionTime () {
    return false;
    // let result = true
    // if (!_.isEmpty(this.extensionTime)) {
    //   //Change the option to supplier to extend time twice. This will now be changed to only 1 extension only.
    //   if (_.isBoolean(this.extensionTime.isApprove1) && this.extensionTime.isApprove1 === true/*!_.isEmpty(this.extensionTime.time1) && !_.isEmpty(this.extensionTime.time2)*/) {
    //     result = false
    //   }
    // }
    // return result
  }

  /**
   * Sort parts
   * type === field name
   * @param type
   */
  sortBy (type) {
    this.sorting.type = type
    if (this.sorting.direction === 'asc') this.sorting.direction = 'desc'
    else this.sorting.direction = 'asc'

    this.parts = (this.sorting.direction === 'asc') ? _.sortBy(this.parts, this.sorting.type) :
      _.reverse(_.sortBy(this.parts, this.sorting.type))
  }

  ///**
  // * Add new part
  // * @param part Object
  // */
  //addPart(part = {comment: 0, id: null, price: 0, status: 0, qty: 1, number: '', rrp: 0, name: '', gstTax: 10}) {
  //    this.parts.push(new Part(part));
  //}

  /**
   * Add part by manual
   * @param part Object
   */
  addManualPart (id) {
    let part = {
      comment: 0,
      id: Number(id),
      price: 0,
      status: 0,
      qty: 1,
      number: '',
      rrp: 0,
      name: '',
      gstTax: 10,
      type: 'manual',
      listOrderNumber: 999,
    }
    this.parts.push(new Part(part))
  }

  /**
   * Update parts
   */
  updateParts () {
    this.parts = this.partsWithLinks(this.parts)
  }

  /**
   * Is part selected
   * @param partId
   * @return {boolean}
   */
  isPartSelected (partId, id = null) {
    let result = false
    if (!_.isEmpty(this.order)) {
      _.forEach(this.order.parts, (part) => {
        let condition = partId === Number(part.originalPartId)
        if (id) {
          condition = condition && id === Number(part.partToSupplierId)
        }
        if (condition) {
          result = true
        }
      })
    }
    return result
  }

  /**
   * Delete part by key
   * @param key
   */
  removePartByKey (key) {
    this.parts.splice(key, 1)
  }
}
