<script>
/* eslint-disable */
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { VueContext } from 'vue-context'
import _ from 'lodash'
import { States } from '../../../../store/states'

export default {
  name: 'rfq-import-pdf',
  props: {
    loadedParts: {
      type: Array,
      default: () => ([]),
    },
    params: {
      type: Object,
      default: () => ({})
    },
    formatFiles: {
      type: String,
      default: 'application/pdf',
    },
    repairerParts: {
      type: Array,
      default: () => ([])
    },
    vin: {
      type: String,
      default: '',
    },
    buildDate: {
      type: String,
      default: '',
    },
    isPartsOrderViewPage: {
      type: Boolean,
      default: false,
    },
    isAutoConfirm: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      partsSelectedByCtrlClick: [],
      partsSelectedByShiftClick: [],
      newPartsState: {},
      noStockPartsState: {},
      dmsNumber: null,
      supplierQuoteNumber: null,
      lastSendedImportedData: [],
      isCheckedAllParts: false,
      selectedPart: {},
      selectedArea: '',
      isHasUnChecked: true,
      valueLoader: 0,
      maxLoader: 100,
      // valueLoader2: 0,
      maxLoader2: 100,
      isLoading: false,
      isStartImporting: false,
      parts: [],
      pdfFileId: null,
      resultParts: [],
      isLoadedParts: false,
      isError: false,
      isHideDropzone: false,
      PDFUploadDropzoneOptions: {
        clickable: '#clickBrowsePDFBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        url: (this.formatFiles ==  'application/pdf')? appConfig.baseAPIURL + '/ir/supplier/rfq/parse-pdf':appConfig.baseAPIURL + '/ir/supplier/rfq/parse-csv',
        headers: { 'Authorization': localStorage.getItem('token') },
        method: 'post',
        params: this.params,
        maxFilesize: 20,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: this.formatFiles,
        dictFileTooBig: 'Your file has exceeded the maximum limit of 20MB per file. Please reduce the file size and upload again',
      },
    }
  },
  computed: {
    computedPartsForExtra() {
      let result = !_.isEmpty(this.partsSelectedByCtrlClick) ? this.partsSelectedByCtrlClick :  this.checkedParts;
      return _.filter(result, (p) => {
        return !p.isNewPart
      })
    },
    computedPartsForMarkNoStock() {
      let result = !_.isEmpty(this.partsSelectedByCtrlClick) ? this.partsSelectedByCtrlClick :  this.checkedParts;
      return _.filter(result, (p) => {
        return !p.isMarkedNoStock
      })
    },
    isImportPdf () {
      return (this.formatFiles == 'application/pdf')
    },
    checkedParts () {
      return _.filter(this.resultParts, (p) => {
        return p.isChecked
      })
    }
  },
  mounted () {
    if (!_.isEmpty(this.loadedParts)) {
      this.$emit('changedLoading', false)
      this.isLoadedParts = true
      this.$emit('loadParts', this.isLoadedParts)
      this.parts = this.loadedParts;
      this.isHideDropzone = true
      this.valueLoader = 100
      // this.pdfFileId = response.pdfFileId
      // if (!_.isEmpty(response.data.quoteNumber)) {
      //   this.supplierQuoteNumber = response.data.quoteNumber
      // }
      // if (!_.isEmpty(response.data.DMSNumber)) {
      //   this.dmsNumber = response.data.DMSNumber
      // }
      this.resultParts.splice(0, this.resultParts.length)

      if (this.isPartsOrderViewPage) {
        _.forEach(this.parts, (prt) => {
          if (prt.price && prt.price.includes('$')) {
            prt.price = prt.price.replace('$', '')
          }
          if (prt.rrp && prt.rrp.includes('$')) {
            prt.rrp = prt.rrp.replace('$', '')
          }
        })
        if (this.parts.length >= this.repairerParts.length) {
          _.forEach(this.parts.slice(), (prt, index) => {
            if (index >= this.repairerParts.length) {
              prt.isNew = true
              prt.isMarkedAsNew = false
              prt.stock = null
            } else {
              // prt.price = this.repairerParts[index] && this.repairerParts[index].price ? this.repairerParts[index].price : null
              // prt.rrp = this.repairerParts[index] && this.repairerParts[index].rrp ? this.repairerParts[index].rrp : null
              prt.stock = this.repairerParts[index] && this.repairerParts[index].stock ? this.repairerParts[index].stock : null
              prt.name = this.repairerParts[index] && this.repairerParts[index].part_list ? this.repairerParts[index].part_list : null
              prt.number = this.repairerParts[index] && this.repairerParts[index].part_number ? this.repairerParts[index].part_number : null
            }
            prt.isNeedUpdate = true
            prt.isGreenUnitList = this.repairerParts[index] && Number(prt.rrp) > Number(this.repairerParts[index].rrp) ? true : false
            prt.isRedUnitList = this.repairerParts[index] && Number(prt.rrp) < Number(this.repairerParts[index].rrp) ? true : false
            prt.isGreenUnitNet = this.repairerParts[index] && Number(prt.price) > Number(this.repairerParts[index].price) ? true : false
            prt.isRedUnitNet = this.repairerParts[index] && Number(prt.price) < Number(this.repairerParts[index].price) ? true : false

            prt.id = this.repairerParts[index] && this.repairerParts[index].id ? this.repairerParts[index].id : null

            prt.isChecked = false
            this.resultParts.push(prt)
          })
        } else if (this.parts.length < this.repairerParts.length) {
          _.forEach(this.repairerParts.slice(), (prt, index) => {
            let part = {
              qty: this.parts[index] && this.parts[index].qty ? this.parts[index].qty : null,
              rrp: this.parts[index] && this.parts[index].rrp ? this.parts[index].rrp : null,
              price: this.parts[index] && this.parts[index].price ? this.parts[index].price : null,
              number: prt.part_number ? prt.part_number : null,
              stock: prt.stock ? prt.stock : null,
              name: prt.part_list ? prt.part_list : null,
              isChecked: false,
              isNeedUpdate: index < this.parts.length,
              id: prt.id,
              isNotApplicable: this.parts[index] && this.parts[index].isNotApplicable ? true : false,
              isGreenUnitList: this.parts[index] && Number(this.parts[index].rrp) > Number(prt.rrp) ? true : false,
              isRedUnitList: this.parts[index] && Number(this.parts[index].rrp) < Number(prt.rrp) ? true : false,
              isGreenUnitNet: this.parts[index] && Number(this.parts[index].price) > Number(prt.price) ? true : false,
              isRedUnitNet: this.parts[index] && Number(this.parts[index].price) < Number(prt.price) ? true : false,
            }

            this.resultParts.push(part)
          })
        }
      } else {
        console.log('110 !!!!!')
        if (this.parts.length >= this.repairerParts.length) {
          let superIndex=0;
          _.forEach(this.parts.slice(), (prt, index) => {
            if (!(prt.isFreight || prt.isExtra)) {
              prt.repairerName = this.repairerParts[superIndex] && this.repairerParts[superIndex].name ? this.repairerParts[superIndex].name : null
              prt.id = this.repairerParts[superIndex] && this.repairerParts[superIndex].id ? this.repairerParts[superIndex].id : null
              prt.id2 = this.getRandom();
              prt.isChecked = false
              superIndex++
            }
            console.log('110 !!!!!', prt.id , superIndex, _.cloneDeep(prt))
            this.resultParts.push(prt)
          })
        } else if (this.parts.length < this.repairerParts.length) {
          _.forEach(this.repairerParts.slice(), (prt, index) => {
            let part = {
              qty: this.parts[index] && this.parts[index].qty ? this.parts[index].qty : null,
              rrp: this.parts[index] && this.parts[index].rrp ? this.parts[index].rrp : null,
              price: this.parts[index] && this.parts[index].price ? this.parts[index].price : null,
              number: this.parts[index] && this.parts[index].number ? this.parts[index].number : null,
              repairerName: prt.name ? prt.name : null,
              name: this.parts[index] && this.parts[index].name ? this.parts[index].name : null,
              isNotApplicable: this.parts[index] && this.parts[index].isNotApplicable ? true : false,
              stockComment: this.parts[index] && this.parts[index].stockComment ? this.parts[index].stockComment : null,
              stockStatus: this.parts[index] && this.parts[index].stockStatus ? this.parts[index].stockStatus : null,
              isChecked: false,
              isExtra: this.parts[index]?.isExtra ? this.parts[index].isExtra : false,
              isFreight: this.parts[index]?.isFreight ? this.parts[index].isFreight : false,
              id: prt.id,
              id2: this.getRandom()
            }

            this.resultParts.push(part)
          })
        }
      }
      let isCompletedUpdate = _.isEmpty(this.resultParts)
      let lengthResult = this.resultParts.length;
      _.forEach(this.resultParts, (prt, ind) => {
        // if (prt.isFreight || prt.isExtra) {
        //   let prtCopy = _.cloneDeep(prt)
        //   setTimeout(()=> {
        //     let newIndex = _.findIndex(this.resultParts, (p) => {
        //       return p.name === prtCopy.name && ((prtCopy.isFreight && p.isFreight) || (prtCopy.isExtra && p.isExtra)) && !prt.isAdedAsNew
        //     })
        //     this.addAsNewPart2({
        //       part: this.resultParts[newIndex],
        //       index: newIndex,
        //     })
        //     if (ind + 1 === lengthResult) {
        //       setTimeout(() => {
        //         isCompletedUpdate = true;
        //       }, 500)
        //     }
        //   },  ind * 1)
        // } else if (ind + 1 === this.resultParts.length) {
        //   setTimeout(() => {
        //     isCompletedUpdate = true;
        //   }, 500)
        // }
        if (prt.isNotApplicable) {
          prt.lastName = prt.name
          prt.lastRepairerName = prt.repairerName
          prt.lastPrice = prt.price
          prt.lastRrp = prt.rrp

          if (_.isEmpty(prt.repairerName)) {
            prt.repairerName = prt.name
          }

          prt.name = 'Not Applicable'
          prt.price = null
          prt.rrp = null
        }
      })
      isCompletedUpdate = true
      if (this.isAutoConfirm) {
        let interval = setInterval(() => {
          if (isCompletedUpdate) {
            console.log(isCompletedUpdate, 'isCompletedUpdate')
            this.onChangeAllCheckbox()
            this.confirmImport()
            clearInterval(interval)
          }
        }, 10)
      }
    }
  },
  methods: {
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    markAsNoStockParts(data) {
      data = _.cloneDeep(data);
      _.forEach(data, (p) => {
        p.isChecked = true;
      })

      _.forEach(data, (el) => {
        let index = _.findIndex(this.resultParts, (p) => {
          return Number(p.id2) === Number(el.id2)
        })

        if (index !== -1) {
          this.markAsNoStock({
            part: el,
            index: index
          }, true)
        }
      })
    },
    markAsNoStock(data, v) {
      let part = data.part
      let index = data.index

      if (v) {

        this.noStockPartsState[part.id2] = _.cloneDeep(this.resultParts);

        let result = []
        let partsBefore = this.resultParts.slice(0, index);
        let partsAfter = this.resultParts.slice(index, this.resultParts.length + 1);
        console.log('partsAfter', _.cloneDeep(partsAfter))
        partsAfter = partsAfter.reverse()

        _.forEach(partsAfter, (p, i) => {
          if (!p.isMarkedNoStock) {
            let nextPart = _.find(partsAfter, (rP, ind) => {
              return ind > i && !rP.isMarkedNoStock
            })
            if (nextPart) {
              p.stock = nextPart.stock;
              p.name = nextPart.name;
              p.number = nextPart.number;
              p.price = nextPart.price;
              p.qty = nextPart.qty;
              p.rrp = nextPart.rrp;
              p.stockStatus = nextPart.stockStatus;
              p.stockComment = nextPart.stockComment;
            }
          }
        })

        partsAfter = partsAfter.reverse()

        partsAfter[0].isMarkedNoStock = v;
        partsAfter[0].stock = 0;
        partsAfter[0].name = '';
        partsAfter[0].number = '';
        partsAfter[0].price = null;
        partsAfter[0].qty = null;
        partsAfter[0].rrp = null;
        partsAfter[0].stockStatus = null;
        partsAfter[0].stockComment = null;

        _.forEach(partsBefore, (p) => {
          result.push(p)
        })
        _.forEach(partsAfter, (p) => {
          result.push(p)
        })

        this.resultParts = result

      } else {
        this.resultParts = this.noStockPartsState[part.id2]
      }
      console.log('NEW resultParts', _.cloneDeep(this.resultParts))
      this.$refs.partContext.close()
    },
    markAsNew (part) {
      let index = _.findIndex(this.resultParts, (itm) => {
        return itm.isNew && itm.name === part.name && itm.number === itm.number && Number(itm.qty) === Number(part.qty) && Number(itm.price) === Number(part.price) && Number(itm.rrp) === Number(part.rrp)
      })
      console.log(part, 'part', index, this.resultParts)
      if (index !== -1) {
        setTimeout(() => {
          this.$set(this.resultParts[index], 'isMarkedAsNew', true)
        }, 100)
      }
    },
    clickOnCheckbox (part) {
      if (part.isNew && !part.isMarkedAsNew) {
        this.$toast.error('Please Mark as New this part')
      }
      if (this.partsSelectedByCtrlClick.includes(part)) {
        this.partsSelectedByCtrlClick.splice(this.partsSelectedByCtrlClick.indexOf(part), 1)
        this.partsSelectedByShiftClick.splice(0, this.partsSelectedByShiftClick.length)
      }
    },
    toggleCheckbox(part, index, e) {
      let isCtrlKey = e.ctrlKey && e.which === 1;
      let isShiftKey = e.shiftKey && e.which === 1;

      if (!isCtrlKey) {
        this.partsSelectedByCtrlClick.splice(0, this.partsSelectedByCtrlClick.length)
      }
      if (!isShiftKey) {
        this.partsSelectedByShiftClick.splice(0, this.partsSelectedByShiftClick.length)
      }

      if (part.isNew && !part.isMarkedAsNew) {
        this.$toast.error('Please Mark as New this part')
        return
      }
      part.isChecked = !part.isChecked

      if (part.isChecked && isCtrlKey) {
        this.partsSelectedByCtrlClick.push(part)
      } else if (!part.isChecked && isCtrlKey) {
        if (this.partsSelectedByCtrlClick.includes(part)) {
          this.partsSelectedByCtrlClick.splice(this.partsSelectedByCtrlClick.indexOf(part), 1)
        }
      }


      if (isShiftKey) {
        if (_.isEmpty(this.partsSelectedByShiftClick)) {
          this.partsSelectedByShiftClick.push(part)
        } else {
          let firstIndex = this.resultParts.indexOf(this.partsSelectedByShiftClick[0]);
          let lastIndex = this.resultParts.indexOf(part);

          _.forEach(this.resultParts, (pr, i) => {
            if (firstIndex > lastIndex) {
              if (i >= lastIndex && i <= firstIndex) {
                pr.isChecked = true
              } else {
                pr.isChecked = false
              }
            } else {
              if (i >= firstIndex && i <= lastIndex) {
                pr.isChecked = true
              } else {
                pr.isChecked = false
              }
            }
          })
        }
      }

      this.$nextTick(() => {
        this.$set(this.resultParts, index, part)
      })
    },
    getStockNameById (value, isNew) {
      if (value === null && isNew) {
        return ''
      }
      let status = _.find(States.stockOptions, (itm) => {
        return Number(itm.value) === Number(value)
      })
      if (status) {
        return status.label
      }
      return ''
    },
    removePart (part) {
      let index = _.findIndex(this.resultParts, (itm) => {
        return itm.isNew && itm.name === part.name && itm.number === itm.number && Number(itm.qty) === Number(part.qty) && Number(itm.price) === Number(part.price) && Number(itm.rrp) === Number(part.rrp)
      })
      console.log(part, 'part', index, this.resultParts)
      if (index !== -1) {
        this.resultParts.splice(index, 1)
        this.onCheckboxClick()
      }
    },
    getRandom () {
      return Math.random()
    },
    returnToStandartLine(data, v) {
      let part = data.part
      let index = data.index
      part.isMarkedAsNecessary = v;

      this.$set(this.resultParts, index, part);
    },
    removeExtraPartLine(data) {
      this.resultParts = this.newPartsState[data.part.stateId];
    },
    addAsNewParts(data) {
      data = _.cloneDeep(data);
      _.forEach(data, (p) => {
        p.isChecked = true;
      })
      _.forEach(data, (el) => {
        let index = _.findIndex(this.resultParts, (p) => {
          return Number(p.id2) === Number(el.id2)
        })
        console.log('index', index)
        if (index !== -1) {
          this.addAsNewPart2({
            part: el,
            index: index
          })
        }
      })
    },
    addAsNewPart2 (data) {
      let part = data.part
      let index = data.index
      if (!part) {
        return
      }
      let resultPartsCopy = _.cloneDeep(this.resultParts)
      let newPrt = _.cloneDeep(part)
      newPrt.repairerName = newPrt.name
      newPrt.isNewPart = true
      newPrt.isChecked = false

      if (!(part.isFreight || part.isDelivery  || part.isExtra)) {
        newPrt.id = null;
        newPrt.stateId = this.getRandom();
        newPrt.id2 = this.getRandom();
      }

      let partsBeforeNewPart = this.resultParts.slice()
      partsBeforeNewPart.splice(index, 0, newPrt)
      let originalParts = this.resultParts.slice()
      _.forEach(partsBeforeNewPart, function (prt, indx) {
        console.log('indx:', _.cloneDeep(indx), _.cloneDeep(index), _.cloneDeep(originalParts.length))
        if (indx > index && indx < originalParts.length) {
          prt.name = originalParts[indx].name
          prt.number = originalParts[indx].number
          prt.price = originalParts[indx].price
          prt.qty = originalParts[indx].qty
          prt.rrp = originalParts[indx].rrp
          prt.stockStatus = originalParts[indx].stockStatus
          prt.stockComment = originalParts[indx].stockComment
          prt.isExtra = originalParts[indx].isExtra
          prt.isFreight = originalParts[indx].isFreight
          if (indx === index) {
            prt.isChecked = part.isChecked
          }
          prt.isAdedAsNew = true;
        } else if (indx >= originalParts.length) {
          prt.name = ''
          prt.number = null
          prt.price = null
          prt.qty = null
          prt.rrp = null
          prt.isExtra = false
          prt.isFreight = false
          prt.stockStatus = null
          prt.stockComment = null
          prt.isAdedAsNew = true;
        }
      })

      let partsAfterNewPart = []
      _.forEach(partsBeforeNewPart, function (prt, index) {
        if (!(prt.repairerName == null && prt.number == null && (prt.name == null || prt.name == '') && index + 1 === partsBeforeNewPart.length)) {
          partsAfterNewPart.push(prt)
        }
      })
      console.log('partsAfterNewPart', _.cloneDeep(partsAfterNewPart))

      this.newPartsState[newPrt.stateId] = _.cloneDeep(resultPartsCopy)
      this.resultParts = partsAfterNewPart
    },
    addAsNewPart (data) {
      let part = data.part
      let index = data.index
      let partsBeforeNewPart = this.resultParts.slice().splice(0, index)
      let partsAfterNewPart = this.resultParts.slice().splice(index)
      let newPrt = Object.assign({}, part)
      newPrt.repairerName = newPrt.name
      newPrt.id = null
      let countOfPartsWithEmptyNames = 0
      let countOfPartsWithEmptyRepairerNames = 0
      _.forEach(this.resultParts, (prt) => {
        if (_.isEmpty(prt.name)) {
          countOfPartsWithEmptyNames++
        }
        if (_.isEmpty(prt.repairerName)) {
          countOfPartsWithEmptyRepairerNames++
        }
      })

      let namesAfterWithoutFirst = []
      if (countOfPartsWithEmptyRepairerNames > 0 || countOfPartsWithEmptyNames > 0) {
        namesAfterWithoutFirst = partsAfterNewPart.slice(1)
      } else {
        namesAfterWithoutFirst = partsAfterNewPart.slice()
        let prt = partsAfterNewPart[partsAfterNewPart.length - 1]
        prt.name = ''
        prt.number = null
        prt.price = null
        prt.qty = null
        prt.rrp = null
      }

      if (countOfPartsWithEmptyRepairerNames > 0) {
        partsAfterNewPart.pop()
      }

      _.forEach(partsAfterNewPart, (prt, index) => {
        if (countOfPartsWithEmptyNames > 0 && index + 1 === partsAfterNewPart.length) {
          return
        }
        prt.name = namesAfterWithoutFirst[index].name
        prt.number = namesAfterWithoutFirst[index].number
        prt.price = namesAfterWithoutFirst[index].price
        prt.qty = namesAfterWithoutFirst[index].qty
        prt.rrp = namesAfterWithoutFirst[index].rrp
      })
      partsBeforeNewPart.push(newPrt)
      let result = partsBeforeNewPart.concat(partsAfterNewPart)

      this.resultParts.splice(0, this.resultParts.length)
      _.forEach(result, (prt) => {
        this.resultParts.push(prt)
      })
    },
    onCheckboxClick () {
      let isHasUnChecked = _.filter(this.resultParts, prt => {
        if (this.isPartsOrderViewPage && prt.isNew && !prt.isMarkedAsNew) {
          return true
        }
        return prt.isChecked === false
      }).length > 0
      this.isHasUnChecked = isHasUnChecked
      if (!this.isHasUnChecked) {
        this.isCheckedAllParts = true
      } else {
        this.isCheckedAllParts = false
      }
      _.forEach(this.resultParts, (prt, ind) => {
        if (this.isPartsOrderViewPage && prt.isNew && !prt.isMarkedAsNew) {
          prt.isChecked = false
          this.$set(this.resultParts, ind, prt)
        } else {
          this.$set(this.resultParts, ind, prt)
        }
      })
    },
    onChangeAllCheckbox () {
      _.forEach(this.resultParts, prt => {
        if (this.isCheckedAllParts) {
          if (this.isPartsOrderViewPage && prt.isNew && !prt.isMarkedAsNew) {
            prt.isChecked = false
          } else {
            prt.isChecked = true
          }
        } else {
          prt.isChecked = false
        }
      })
      this.partsSelectedByCtrlClick.splice(0, this.partsSelectedByCtrlClick.length)
      this.partsSelectedByShiftClick.splice(0, this.partsSelectedByShiftClick.length)
      this.onCheckboxClick()
    },
    pushResultParts () {
      if (!_.isEmpty(this.resultParts) && !_.isEqual(this.resultParts, this.lastSendedImportedData)) {
        console.log(this.resultParts, 'resultParts', this.lastSendedImportedData)
        console.log('confirm pdfFileId', this.pdfFileId)
        this.lastSendedImportedData = this.resultParts.slice()
        let deliveryParts = _.filter(this.resultParts.slice(), (prt) => {
          return prt.isFreight;
        })
        this.resultParts = _.filter(this.resultParts, (prt) => {
          return !prt.isFreight;
        })
        _.forEach(deliveryParts, (prt) => {
          this.resultParts.push(prt)
        })

        let orderedData = _.forEach(this.resultParts, (part, index) => {
          part.listOrderNumber = index
        })
        this.$emit('confirmedImport', _.cloneDeep(orderedData))
      }
    },
    confirmImport () {
      this.isStartImporting = true
      this.$emit('startImporting', true)
      this.$emit('addImportedPdf', this.pdfFileId)
      if (!_.isEmpty(this.supplierQuoteNumber)) {
        this.$emit('addSupplierQuoteNumber', this.supplierQuoteNumber)
      }
      if (!_.isEmpty(this.dmsNumber)) {
        this.$emit('addDMSNumber', this.dmsNumber)
      }
      this.pushResultParts();
      this.closeWizard()

      // let interval = setInterval(() => {
      //   if (this.valueLoader2 !== 100 && this.isStartImporting) {
      //     if (this.isAutoConfirm) {
      //       if (this.isLoadedParts && !this.isLoading && this.valueLoader === 100 && this.isStartImporting) {
      //         this.valueLoader2 += 10
      //       }
      //     } else  {
      //       this.valueLoader2 += 10
      //     }
      //   } else if (this.valueLoader2 === 100 && this.isStartImporting) {
      //     setTimeout(() => {
      //       if (this.isPartsOrderViewPage) {
      //         this.pushResultParts();
      //       }
      //       clearInterval(interval)
      //       this.closeWizard()
      //     }, this.isAutoConfirm ? 500 : 200)
      //   } else {
      //     if (this.isPartsOrderViewPage) {
      //       this.pushResultParts();
      //     }
      //     clearInterval(interval)
      //   }
      // }, 100)
    },
    cancelImporting () {
      this.isStartImporting = false
      this.valueLoader2 = 0
      this.$emit('clickCancel')
      this.$emit('startImporting', false)
    },
    closeWizard () {
      this.isLoading = false
      this.parts.splice(0, this.parts.length)
      // this.resultParts.splice(0, this.resultParts.length);
      this.isLoadedParts = false
      this.isError = false
      this.isStartImporting = false
      this.$emit('closeWizard')
    },
    cancelDropzone ($event, file) {
      console.log('cancel', $event, file)
    },
    errorFileUpload () {
      let interval2 = setInterval(() => {
        if (this.valueLoader === 100) {
          this.isError = true
          setTimeout(() => {
            this.isLoading = false
            this.isHideDropzone = true
            this.$emit('changedLoading', this.isLoading)
          }, 1000)
          clearInterval(interval2)
        }
      }, 500)
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    startLoading () {
      this.isLoading = true
      this.$emit('changedLoading', this.isLoading)
      let interval = setInterval(() => {
        if (this.valueLoader === 100 && (this.isLoadedParts || this.isError)) {
          this.valueLoader = 100
          setTimeout(() => {
            this.isLoading = false
            this.isHideDropzone = true
            this.$emit('changedLoading', this.isLoading)
          }, 1000)
          clearInterval(interval)
        } else if (this.valueLoader !== 100) {
          this.valueLoader += 2
        }
      }, 100)
    },
    sendFileUpload ($event) {
      this.startLoading()
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    successFileUpload (file, response) {
      // this.$refs.PDFUpDrop.removeAllFiles();
      if (response._status) {
        this.isLoadedParts = true
        this.$emit('loadParts', this.isLoadedParts)
        this.parts = response.data.parts
        this.pdfFileId = response.pdfFileId
        if (!_.isEmpty(response.data.quoteNumber)) {
          this.supplierQuoteNumber = response.data.quoteNumber
        }
        if (!_.isEmpty(response.data.DMSNumber)) {
          this.dmsNumber = response.data.DMSNumber
        }
        this.resultParts.splice(0, this.resultParts.length);

        if (this.isPartsOrderViewPage) {
          _.forEach(this.parts, (prt) => {
            if (prt.price && prt.price.includes('$')) {
              prt.price = prt.price.replace('$', '')
            }
            if (prt.rrp && prt.rrp.includes('$')) {
              prt.rrp = prt.rrp.replace('$', '')
            }
          })
          if (this.parts.length >= this.repairerParts.length) {
            _.forEach(this.parts.slice(), (prt, index) => {
              if (index >= this.repairerParts.length) {
                prt.isNew = true
                prt.isMarkedAsNew = false
                prt.stock = null
              } else {
                // prt.price = this.repairerParts[index] && this.repairerParts[index].price ? this.repairerParts[index].price : null
                // prt.rrp = this.repairerParts[index] && this.repairerParts[index].rrp ? this.repairerParts[index].rrp : null
                prt.stock = this.repairerParts[index] && this.repairerParts[index].stock ? this.repairerParts[index].stock : null
                prt.name = this.repairerParts[index] && this.repairerParts[index].part_list ? this.repairerParts[index].part_list : null
                prt.number = this.repairerParts[index] && this.repairerParts[index].part_number ? this.repairerParts[index].part_number : null
              }
              prt.isNeedUpdate = true
              prt.isGreenUnitList = this.repairerParts[index] && Number(prt.rrp) > Number(this.repairerParts[index].rrp) ? true : false
              prt.isRedUnitList = this.repairerParts[index] && Number(prt.rrp) < Number(this.repairerParts[index].rrp) ? true : false
              prt.isGreenUnitNet = this.repairerParts[index] && Number(prt.price) > Number(this.repairerParts[index].price) ? true : false
              prt.isRedUnitNet = this.repairerParts[index] && Number(prt.price) < Number(this.repairerParts[index].price) ? true : false

              prt.id = this.repairerParts[index] && this.repairerParts[index].id ? this.repairerParts[index].id : null

              prt.isChecked = false
              this.resultParts.push(prt)
            })
          } else if (this.parts.length < this.repairerParts.length) {
            _.forEach(this.repairerParts.slice(), (prt, index) => {
              let part = {
                qty: this.parts[index] && this.parts[index].qty ? this.parts[index].qty : null,
                rrp: this.parts[index] && this.parts[index].rrp ? this.parts[index].rrp : null,
                price: this.parts[index] && this.parts[index].price ? this.parts[index].price : null,
                number: prt.part_number ? prt.part_number : null,
                stock: prt.stock ? prt.stock : null,
                name: prt.part_list ? prt.part_list : null,
                isChecked: false,
                isNeedUpdate: index < this.parts.length,
                id: prt.id,
                isNotApplicable: this.parts[index] && this.parts[index].isNotApplicable ? true : false,
                isGreenUnitList: this.parts[index] && Number(this.parts[index].rrp) > Number(prt.rrp) ? true : false,
                isRedUnitList: this.parts[index] && Number(this.parts[index].rrp) < Number(prt.rrp) ? true : false,
                isGreenUnitNet: this.parts[index] && Number(this.parts[index].price) > Number(prt.price) ? true : false,
                isRedUnitNet: this.parts[index] && Number(this.parts[index].price) < Number(prt.price) ? true : false,
              }

              this.resultParts.push(part)
            })
          }
        } else {
          if (this.parts.length >= this.repairerParts.length) {
            let superIndex=0;
            _.forEach(this.parts.slice(), (prt, index) => {
              if (!(prt.isFreight || prt.isExtra)) {
                prt.repairerName = this.repairerParts[superIndex] && this.repairerParts[superIndex].name ? this.repairerParts[superIndex].name : null
                prt.id = this.repairerParts[superIndex] && this.repairerParts[superIndex].id ? this.repairerParts[superIndex].id : null
                prt.id2 = this.getRandom();
                prt.isChecked = false
                superIndex++
              }
              console.log('10 !!!!!', prt.id , superIndex, _.cloneDeep(prt))
              this.resultParts.push(prt)
            })
          } else if (this.parts.length < this.repairerParts.length) {
            _.forEach(this.repairerParts.slice(), (prt, index) => {
              let part = {
                qty: this.parts[index] && this.parts[index].qty ? this.parts[index].qty : null,
                rrp: this.parts[index] && this.parts[index].rrp ? this.parts[index].rrp : null,
                price: this.parts[index] && this.parts[index].price ? this.parts[index].price : null,
                number: this.parts[index] && this.parts[index].number ? this.parts[index].number : null,
                repairerName: prt.name ? prt.name : null,
                name: this.parts[index] && this.parts[index].name ? this.parts[index].name : null,
                isNotApplicable: this.parts[index] && this.parts[index].isNotApplicable ? true : false,
                stockComment: this.parts[index] && this.parts[index].stockComment ? this.parts[index].stockComment : null,
                stockStatus: this.parts[index] && this.parts[index].stockStatus ? this.parts[index].stockStatus : null,
                isChecked: false,
                isExtra: this.parts[index]?.isExtra ? this.parts[index].isExtra : false,
                isFreight: this.parts[index]?.isFreight ? this.parts[index].isFreight : false,
                id: prt.id,
                id2: this.getRandom(),
              }

              this.resultParts.push(part)
            })
          }
        }
        console.log('0 !!!!', _.cloneDeep(this.resultParts))
        let isCompletedUpdate = _.isEmpty(this.resultParts)
        let lengthResult = this.resultParts.length;
        _.forEach(this.resultParts, (prt, ind) => {
          console.log('1 !!!!', _.cloneDeep(prt), _.cloneDeep(ind))
          // if (prt.isFreight || prt.isExtra) {
          //   let prtCopy = _.cloneDeep(prt)
          //   setTimeout(()=> {
          //     let newIndex = _.findIndex(this.resultParts, (p) => {
          //       return p.name === prtCopy.name && ((prtCopy.isFreight && p.isFreight) || (prtCopy.isExtra && p.isExtra)) && !prt.isAdedAsNew
          //     })
          //     console.log('2 !!!!',_.cloneDeep(newIndex), 'new Index', _.cloneDeep(prt),_.cloneDeep(prtCopy))
          //     this.addAsNewPart2({
          //       part: this.resultParts[ _.cloneDeep(newIndex)],
          //       index: _.cloneDeep(newIndex),
          //     })
          //     if (ind + 1 === lengthResult) {
          //       setTimeout(() => {
          //         isCompletedUpdate = true;
          //       }, 500)
          //     }
          //   },  (ind+1)* 10)
          // } else if (ind + 1 === this.resultParts.length) {
          //   setTimeout(() => {
          //     isCompletedUpdate = true;
          //   }, 500)
          // }
          if (prt.isNotApplicable) {
            prt.lastName = prt.name
            prt.lastRepairerName = prt.repairerName
            prt.lastPrice = prt.price
            prt.lastRrp = prt.rrp

            if (_.isEmpty(prt.repairerName)) {
              prt.repairerName = _.cloneDeep(prt.name)
            }

            prt.name = 'Not Applicable'
            prt.price = null
            prt.rrp = null
          }
        })
        isCompletedUpdate = true;

        if (this.isAutoConfirm) {
          let interval = setInterval(() => {
            console.log(isCompletedUpdate, 'isCompletedUpdate')
            if (isCompletedUpdate) {
              this.onChangeAllCheckbox()
              this.confirmImport()
              clearInterval(interval)
            }
          }, 10)
        }

      } else {
        this.isError = true
      }
    },
    openMenu (event, part, index, area) {
      this.closeMenu(false)
      let ref = 'part-' + part.name + '-' + part.number + '-' + part.price + '-' + part.repairerName
      this.selectedPart = part
      if (this.$refs[ref][0]) {
        this.$refs[ref][0].classList.add('light-blue-tr-background')
      }
      this.selectedArea = area;
      this.$refs.partContext.open(event, { part: part, index: index })
    },
    closeMenu (isNeedClear = true) {
      let ref = 'part-' + this.selectedPart.name + '-' + this.selectedPart.number + '-' + this.selectedPart.price + '-' + this.selectedPart.repairerName
      if (this.$refs[ref] && this.$refs[ref][0]) {
        this.$refs[ref][0].classList.remove('light-blue-tr-background')
      }
      if (isNeedClear) {
        this.partsSelectedByCtrlClick.splice(0, this.partsSelectedByCtrlClick.length)
        this.partsSelectedByShiftClick.splice(0, this.partsSelectedByShiftClick.length)
      }
      this.selectedArea = '';
    },
    markNotApplicable (part) {
      part.lastName = part.name
      part.lastRepairerName = part.repairerName
      part.lastPrice = part.price
      part.lastRrp = part.rrp

      if (_.isEmpty(part.repairerName)) {
        part.repairerName = part.name
      }

      part.name = 'Not Applicable'
      part.price = null
      part.rrp = null
      this.closeMenu()
    },
    unMarkNotApplicable (part) {
      part.name = part.lastName
      part.repairerName = part.lastRepairerName
      part.price = part.lastPrice
      part.rrp = part.lastRrp
      this.closeMenu()
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    VueContext,
  },
}

</script>

<template>
  <div class="import-pdf-section prevent-select">
    <div :class="{'hidden-vue-dropzone': isLoading}" v-if="!isHideDropzone" class="import-pdf-loader">
      <vue-dropzone
          v-on:vdropzone-complete="completeFileUpload"
          v-on:vdropzone-sending="sendFileUpload"
          v-on:vdropzone-success="successFileUpload"
          v-on:vdropzone-error="errorFileUpload"
          v-on:vdropzone-canceled="cancelDropzone"
          ref="PDFUpDrop"
          id="PDFUploadDrop"
          class="dropify-wrapper dropzoneContainer"
          :options="PDFUploadDropzoneOptions"
          :useCustomSlot="true">
        <div class="dropify-message" style="transform: translateY(0%); min-width: 125px;">
          <div class="dropzone-block">
            <i v-if="isImportPdf" class='bx bxs-file-pdf'></i>
            <i v-else class='bx bxs-spreadsheet'></i>
            <i class='bx bx-cloud-upload'></i>
            <p class="dnd-title">Drag and Drop File Here</p>
            <p class="text-or mb-0-5 dnd-or">Or</p>
          </div>
          <div class="text-sm-center mb-1 ex-image-upload-buttons">
            <button id="clickBrowsePDFBtn" type="button"
                    class="btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Browse Files
            </button>
            <button @click="closeWizard" style="height: 41px; margin-left: 15px;" type="button"
                    class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Close
            </button>
          </div>
        </div>
      </vue-dropzone>
    </div>

    <div v-if="isLoading" style="margin-top: 20px;">
      <div class="pdf-upload-loader-wrapper">
        <div class="pdf-upload-loader">
          <i class='bx bx-file'></i>
          <b-progress :value="valueLoader" :max="maxLoader" height="100%" class="pdf-upload-progress"></b-progress>
        </div>
      </div>
    </div>

    <div v-if="isError && !isLoading && valueLoader === 100" style="margin-top: 20px">
      <div class="pdf-upload-loader-wrapper">
        <div class="pdf-upload-error">
          <p>An <span>ERROR</span> has occurred converting your PDF Quote</p>
          <p>A support ticket has been raised.</p>
          <p>Sorry, we will work on resolving so it doesn't happen again!</p>
          <button @click="closeWizard" type="button"
                  class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Close
          </button>
        </div>
      </div>
    </div>

    <div v-if="isLoadedParts && !isLoading && valueLoader === 100 && !isStartImporting" style="margin-top: 26px;">
      <div class="vin-date">
        <span>VIN: {{ vin }}</span>
        <span>Build Date: {{ buildDate }}</span>
      </div>
      <div class="box box-block rs-scroll rs-scroll--y" style="overflow: auto; margin: 10px">
        <div class="row">
          <table class="table b-table select-parts">
            <thead class="bg-navyblue">
            <tr>
              <template v-if="isPartsOrderViewPage">
                <th class="header-part-number" style="text-align: left;">Part Name</th>
                <th class="header-part-your-name" style="text-align: left;">Part Number</th>
                <th class="header-part-customer-name" style="text-align: left;">
                  <span>RFQ Stock Status</span>
                </th>
              </template>
              <template v-else>
                <th class="header-part-line" style="text-align: left;">Line</th>
                <th class="header-part-customer-name" style="text-align: left;"><span>Customer Description</span></th>
                <th class="th-line"></th>
                <th class="header-part-your-name" style="text-align: left;">Your Description</th>
                <th class="header-part-number" style="text-align: left;">Part Number</th>
              </template>
              <th class="header-part-qty">Qty</th>
              <th class="header-part-list">Unit List</th>
              <th class="header-part-net">Unit Net</th>
              <th class="header-check" :class="{'checkbox-all': isPartsOrderViewPage}">
                <label class="form-check-inline checkbox">
                  <input @change="onChangeAllCheckbox" v-model="isCheckedAllParts" class="form-check-input"
                         type="checkbox" name="allCheckbox">
                  <span :class="{'unChecked': !isCheckedAllParts}" class="icon"><i class='bx bx-check'></i></span>
                </label>
              </th>

            </tr>
            </thead>
            <div style="height: 4px !important;">
              <div class="table-line"></div>
            </div>

            <tbody>
            <tr :ref="'part-' + part.name + '-' + part.number + '-' + part.price + '-'  + part.repairerName" v-for="(part, index) in resultParts"
                :key="part.name + part.number + part.price + part.qty + part.rrp + getRandom()"
            >

              <template v-if="isPartsOrderViewPage">
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-your-name" :class="{'green-part': part.isNew}">{{ part.name }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-number" :class="{'green-part': part.isNew}">{{ part.number }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-customer-name">{{ getStockNameById(part.stock, part.isNew) }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-qty text-center" :class="{'green-part': part.isNew}">{{ part.qty }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-list text-center"
                    :class="{'green-part': part.isNew || part.isGreenUnitList, 'red-part': part.isRedUnitList}">
                  <span v-if="part.isNeedUpdate && part.rrp !== null">{{ part.rrp | formatMoney }}</span></td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-net text-center"
                    :class="{'green-part': part.isNew || part.isGreenUnitNet, 'red-part': part.isRedUnitNet}">
                  <span v-if="part.isNeedUpdate && part.price !== null">{{ part.price | formatMoney }}</span></td>
              </template>
              <template v-else>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" class="part-line" >{{ index + 1 }}</td>
                <td
                    @contextmenu.prevent="openMenu($event, part, index, 'customerDescription')"
                    @click="toggleCheckbox(part, index, $event)"
                    style="cursor: pointer;"
                    :class="{
                      'part-customer-name-shift': part.isNewPart && !part.isMarkedAsNecessary,
                      'part-customer-name': !_isEmpty(part.repairerName)
                    }"> {{ part.repairerName }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" style="border: none">
                  <div class="left-line"></div>
                </td>
                <td @contextmenu.prevent="openMenu($event, part, index, 'yourDescription')" @click="toggleCheckbox(part, index, $event)" class="part-your-name" role='button' :class="{'part-checked-border': part.isChecked}">{{ part.name }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" @click="toggleCheckbox(part, index, $event)" class="part-number" role='button' :class="{'part-checked-border': part.isChecked}">{{ part.number }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" @click="toggleCheckbox(part, index, $event)" class="part-qty text-center" role='button' :class="{'part-checked-border': part.isChecked}">{{ part.qty }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" @click="toggleCheckbox(part, index, $event)" class="part-list text-center" role='button' :class="{'part-checked-border': part.isChecked}">{{ part.rrp }}</td>
                <td @contextmenu.prevent="openMenu($event, part, index, '')" @click="toggleCheckbox(part, index, $event)" class="part-net text-center" role='button' :class="{'part-checked-border': part.isChecked}">{{ part.price }}</td>
              </template>
              <td>
                <label class="form-check-inline checkbox">
                  <input @change="onCheckboxClick" v-model="part.isChecked" class="form-check-input" type="checkbox"
                         name="partCheckbox">
                  <span
                      role='button'
                      @click="clickOnCheckbox(part)"
                      :class="{'unChecked': !part.isChecked, 'not-applicable-checkbox': part.name === 'Not Applicable'}"
                      class="icon"><i class='bx bx-check'></i></span>
                </label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex" style="justify-content: end; height: 150px">
        <button style="height: 41px; margin-left: 15px;" type="button"
                class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Report Issue
        </button>
        <button @click="closeWizard" style="height: 41px; margin-left: 25px;" type="button"
                class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Cancel
        </button>
        <button :disabled="isHasUnChecked" @click="confirmImport"
                style="height: 41px; margin-left: 15px; margin-right: 150px;" type="button"
                class="confirm-button btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Confirm
        </button>
      </div>
    </div>

<!--    <div v-if="isLoadedParts && !isLoading && valueLoader === 100 && isStartImporting" style="text-align: center"-->
<!--         class="">-->
<!--      <p style="font-size: 16px; font-weight: bold; margin-top: 55px; margin-bottom: 40px;">Processing</p>-->
<!--      <div class="d-flex" style="justify-content: center">-->
<!--        <b-progress :value="valueLoader2" :max="maxLoader2" class="pdf-upload-progress-2"></b-progress>-->
<!--      </div>-->
<!--      <div class="d-flex" style="justify-content: center; margin-top: 50px;">-->
<!--        <button @click="cancelImporting" style="height: 41px; margin-left: -5px;" type="button"-->
<!--                class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Cancel-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

    <vue-context @close="closeMenu" ref="partContext" class="import-parts-context">
      <ul slot-scope="scope">
        <template v-if="isPartsOrderViewPage && scope.data && scope.data.part && scope.data.part.isNew">
          <li v-if="!scope.data.part.isMarkedAsNew" @click="markAsNew(scope.data.part)">Mark as New</li>
          <li @click="removePart(scope.data.part)">Remove Part</li>
        </template>
        <template v-else-if="!isPartsOrderViewPage">
          <template v-if="checkedParts.length > 1 || partsSelectedByCtrlClick.length">
            <li
                v-if="computedPartsForExtra.length && selectedArea !== 'customerDescription'"
                @click="addAsNewParts(computedPartsForExtra)">
              <span v-if="computedPartsForExtra.length === 1">Add as Extra Part Line</span>
              <span v-else>Add Selected Parts as Extra Part Line</span>
            </li>
          </template>
          <template v-else>
            <li v-if="scope.data && scope.data.part && !scope.data.part.isNewPart && selectedArea !== 'customerDescription'" @click="addAsNewPart2(scope.data)">Add as Extra Part Line</li>
          </template>
          <li v-if="scope.data && scope.data.part && scope.data.part.isNewPart" @click="removeExtraPartLine(scope.data)">Undo as Extra Part Line</li>

          <template v-if="scope.data && scope.data.part && scope.data.part.isNewPart && selectedArea === 'customerDescription'">
            <li v-if="!scope.data.part.isMarkedAsNecessary" @click="returnToStandartLine(scope.data, true)">Mark as Necessary Part</li>
            <li v-else-if="scope.data.part.isMarkedAsNecessary" @click="returnToStandartLine(scope.data, false)">Mark as Associated Part</li>
          </template>

          <li v-if="scope.data && scope.data.part && scope.data.part.name !== 'Not Applicable'"
              @click="markNotApplicable(scope.data.part)">Mark Not Applicable
          </li>
          <li v-if="scope.data && scope.data.part && scope.data.part.name === 'Not Applicable'"
              @click="unMarkNotApplicable(scope.data.part)">UnMark Not Applicable
          </li>

          <template v-if="selectedArea === 'customerDescription' && scope.data && scope.data.part">
            <template v-if="checkedParts.length > 1 || partsSelectedByCtrlClick.length">
              <li v-if="computedPartsForMarkNoStock.length" @click="markAsNoStockParts(computedPartsForMarkNoStock)">
                <span v-if="computedPartsForMarkNoStock.length === 1">Mark - No Stock</span>
                <span v-else>Mark Selected Parts - No Stock</span>
               </li>
              <li v-if="scope.data.part.isMarkedNoStock" @click="markAsNoStock(scope.data, false)">Undo No Stock</li>
            </template>
            <template v-else>
              <li v-if="!scope.data.part.isMarkedNoStock" @click="markAsNoStock(scope.data, true)">Mark - No Stock</li>
              <li v-else-if="scope.data.part.isMarkedNoStock" @click="markAsNoStock(scope.data, false)">Undo No Stock</li>
            </template>
          </template>
        </template>
      </ul>
    </vue-context>
  </div>
</template>

<style>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
<style scoped>
.table td {
  vertical-align: middle !important;
}

.green-part {
  color: #0cb458;
}

.red-part {
  color: red;
}

.checkbox-all {
  padding-left: 14px;
}

.select-parts {
  border-spacing: 0.5em !important;
}

.V3 .theme-supplier label.checkbox > span.icon.not-applicable-checkbox {
  border: 2px solid #febe36 !important;
}

.V3 .theme-supplier label.checkbox > span.icon.unChecked {
  border: 2px solid #9e9e9e !important;
}

.vin-date {
  margin-left: 28px;
  margin-top: 15px;
  font-weight: bold;
}

.vin-date span {
  margin-right: 15px;
}

.import-pdf-loader {
  max-width: 600px;
  min-height: 300px;
  height: calc(100vh - 500px);
  max-height: 600px;
  margin: 20px auto 0px auto;
  border: 2px dashed rgba(27, 30, 56, 0.25);
}

.dropify-wrapper {
  height: 98%;
  border: 2px dashed rgba(27, 30, 56, 0.25);
  margin: 5px;
}

.bxs-file-pdf,
.bxs-spreadsheet {
  font-size: 70px;
  color: #1C1F39
}

.bx-cloud-upload {
  font-size: 120px;
  color: #1C1F39
}

.bx-file {
  font-size: 300px;
  color: #1C1F39;
  position: relative;
  z-index: 9;
}

.dropzone-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85%;
}

.pdf-upload-loader-wrapper {
  max-width: 600px;
  min-height: 330px;
  height: calc(100vh - 500px);
  max-height: 600px;
  margin: 0 auto;
  border: 2px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  position: relative;
  background-color: white;
}

.pdf-upload-loader {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  margin: 5px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pdf-upload-error {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  margin: 5px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdf-upload-error p {
  text-align: center;
  width: 50%;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #1C1F39;
  margin-bottom: 2rem;
}

.pdf-upload-error p span {
  color: red;
}

.import-quote-table {
  margin-top: 50px;
}

table {
  border-collapse: separate;
  border-spacing: 0.5em;
}

table.table.b-table > thead > tr > th {
  border: 1px solid #1d1d21bd;
  font-size: 15px;
  background-color: white !important;
}

table.table.b-table > thead > tr > th:last-child,
.table th, .table td:last-child {
  border: none;
}

.table th, .table td {
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.table td {
  padding: 0.1rem 15px;
  height: 35px !important;
}

.table-line {
  height: 2px;
  background-color: #b3b3b3;
  position: absolute;
  width: calc(100vw - 300px);
}

.header-part-qty,
.part-qty {
  width: 60px;
}

.header-part-list,
.part-list,
.header-part-net,
.part-net {
  width: 100px;
}

.header-part-customer-name,
.part-customer-name {
  border: 2px solid #29BBC1 !important;
}


.hidden-vue-dropzone {
  display: none;
}

.pdf-upload-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.th-line {
  padding: 0;
  width: 20px !important;
  border: none !important;
}

.part-line {
  border: none !important;
  width: 10px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}

.header-part-line {
  padding: 0.75rem 5px;
}

.left-line {
  height: calc(100% + 11px);
  padding: 0 !important;
  border: none !important;
  background-color: #29BBC1;
  width: 3px !important;
  margin-left: 10px;
}

.part-customer-name-shift {
  position: relative;
  left: 10px;
  background-color: white !important;
  border: 1px solid rgba(27, 30, 56, 0.25) !important;
}

.table tbody tr:hover {
  background-color: transparent !important;
}

.table tbody tr:hover .part-your-name,
.table tbody tr:hover .part-number,
.table tbody tr:hover .part-qty,
.table tbody tr:hover .part-list,
.table tbody tr:hover .part-net {
  background-color: #e9f8f9 !important;
}

.part-checked-border {
  border: 2px solid #29BBC1 !important;
  background-color: #e9f8f9 !important;
}

@media screen and (max-height: 880px) {
  .bxs-file-pdf {
    font-size: 50px;
  }

  .bx-cloud-upload {
    font-size: 50px;
  }

  .bx-file {
    font-size: 150px;
  }

  .dropify-wrapper {
    height: 97%;
  }

  .dropzone-block {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .pdf-upload-error p {
    width: 90%;
  }
}

.btn-primary:disabled:hover, .btn-primary:disabled {
  background-color: #a8a8a8 !important;
  border-color: #a8a8a8 !important;
  cursor: not-allowed;
}
</style>
