<template>
  <div class="report-parts-wrapper ml-3">
    <div class="d-flex align-items-center flex-wrap position-relative report-parts-credits-runing-total-page">
      <div class="header-left mt-3">
        <div class="title-options">Date: {{ getFilter() }}</div>
        <div class="navbar-nav nav dropdown dropdown-options inline customer-name-button">
          <b-dropdown :disabled="true" id="supplier-customer-name-view-options" ref="optionMenu" text="Options"
                      class="dropdown-options">
            <template v-slot:button-content>{{ getFilter() }}
              <span class="dropdown-arrow">
                      <i class='bx bx-down-arrow'></i>
                  </span>
            </template>
            <b-dropdown-item @click="selectedFilter = 'Today'">Today - {{ getToday() }}</b-dropdown-item>
            <b-dropdown-item @click="selectedFilter = 'This Month'">End of this Month - {{ getEndOfThisMonth() }}</b-dropdown-item>
            <div class="options-line"></div>
            <b-dropdown-item @click="selectedFilter = 'Last Month'">End of Last Month - {{ getEndOfLastMonth() }}</b-dropdown-item>
            <b-dropdown-item @click="selectedFilter = 'Last Quarter'">End of Last Quarter -
                {{ getEndOfLastQuarter() }}</b-dropdown-item>
            <b-dropdown-item @click="selectedFilter = 'Last Financial Year'">End of Last Financial Year - {{ getEndOfLastYear() }}</b-dropdown-item>
              <b-dropdown id="dropdown-2"
                          dropright class="request-time-extension supp-reports-custom-date-dropdown font-12">
                <template v-slot:button-content>
                  Custom Date Range
                </template>
                <div class="custom-date-range-context">
                  <date-range-picker
                      ref="picker"
                      v-model="dateRangePickerValue"
                      @onChange="selectedFilter = 'Custom Date Range'"
                  />
                </div>
              </b-dropdown>
          </b-dropdown>
        </div>
      </div>
      <div class="header-left mt-3">
        <div class="title-options">Suppliers</div>
        <div class="multiselect-suppliers-options">
          <multiselect
              v-model="valueSupplier"
              :options="suppliersOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick Supplier"
              @remove="onRemoveSupplier"
              @select="onSelectSupplier"
              :preselect-first="true"
              selectLabel="Select"
              deselectLabel="Remove"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length" v-show="!isOpen"><span v-if="values.includes('All - Suppliers')">All</span>
                <span v-else>{{ values.length }}</span> Suppliers selected</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center additional-sort-block">
        <div class="header-right d-flex">
          <div class="navbar-nav nav dropdown  dropdown-options inline customer-name-button">
            <b-dropdown :disabled="true" id="supplier-customer-name-view-options" ref="optionMenu"
                        class="dropdown-options">
              <template v-slot:button-content>Export
                <span class="dropdown-arrow">
                    <i class='bx bx-down-arrow'></i>
                  </span>
              </template>
              <b-dropdown-item>.CSV</b-dropdown-item>
              <b-dropdown-item>.XLS</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex align-items-center justify-content-between mt-5">
      <h4 class="chart-title mt-3">
        Parts Credit Return Percentage
      </h4>
    </div>

    <div class="mt-3">
      <p class="mb-0"><b>Business Name:</b> South Westside Smash Repairs Ptv Ltd</p>
      <p><b>As of:</b> {{ getFilter() }}</p>
    </div>

    <div class="report-table mt-3">
      <div class="table-header">
        <div class="table-header-row supplier-name">
          <p>Supplier Name</p>
        </div>
        <div class="table-header-row phone">
          <p>Phone</p>
        </div>
        <div class="table-header-row status">
          <p>Status</p>
        </div>
        <div class="table-header-row date">
          <p>Date</p>
        </div>
        <div class="table-header-row marked-rtrnd">
          <p>Marked Rtrnd</p>
        </div>
        <div class="table-header-row make">
          <p>Make</p>
        </div>
        <div class="table-header-row type">
          <p>Type</p>
        </div>
        <div class="table-header-row part-description">
          <p>Part Description</p>
        </div>
        <div class="table-header-row part-number">
          <p>Part Number</p>
        </div>
        <div class="table-header-row order-nbr">
          <p>Order Nbr</p>
        </div>
        <div class="table-header-row qty-order">
          <p>Qty Order</p>
        </div>
        <div class="table-header-row qty-crd">
          <p>Qty Crd</p>
        </div>
        <div class="table-header-row list-price">
          <p>List Price</p>
        </div>
        <div class="table-header-row buy-price">
          <p>Buy Price</p>
        </div>
        <div class="table-header-row credit-price">
          <p>Credit Price</p>
        </div>
        <div class="table-header-row gst">
          <p>GST</p>
        </div>
        <div class="table-header-row total">
          <p>TOTAL</p>
        </div>
      </div>
      <div class="table-main"><div v-for="(itm, ind) in filteredData" :key="ind" class="">
            <template  v-if="!isEmpty(itm.credits)">
        <div class="table-main-row-supplier-wrapper">
          <div class="table-main-row-supplier supplier-name font-weight-bold">
            <p>{{itm.supplierName}}</p>
          </div>
          <div class="table-main-row-supplier phone">
            <p>{{itm.phone}}</p>
          </div>
          <div class="table-main-row-supplier status">
            <p></p>
          </div>
          <div class="table-main-row-supplier date">
            <p></p>
          </div>
          <div class="table-main-row-supplier marked-rtrnd">
            <p></p>
          </div>
          <div class="table-main-row-supplier make">
            <p></p>
          </div>
          <div class="table-main-row-supplier type">
            <p></p>
          </div>
          <div class="table-main-row-supplier part-description">
            <p></p>
          </div>
          <div class="table-main-row-supplier part-number">
            <p></p>
          </div>
          <div class="table-main-row-supplier order-nbr">
            <p></p>
          </div>
          <div class="table-main-row-supplier qty-order">
            <p></p>
          </div>
          <div class="table-main-row-supplier qty-crd">
            <p></p>
          </div>
          <div class="table-main-row-supplier list-price">
            <p></p>
          </div>
          <div class="table-main-row-supplier buy-price">
            <p></p>
          </div>
          <div class="table-main-row-supplier credit-price">
            <p></p>
          </div>
          <div class="table-main-row-supplier gst">
            <p></p>
          </div>
          <div class="table-main-row-supplier total">
            <p></p>
          </div>
        </div>
        <div v-for="(credit, index) in itm.credits" :key="index"  :class="{'status-denied': credit.status === 'Denied'}" class="table-main-row-make-wrapper">
          <div class="supplier-name">
            <p></p>
          </div>
          <div class="phone">
            <p></p>
          </div>
          <div class="table-main-row-make status">
            <p>{{getStatus(credit.status)}}</p>
          </div>
          <div class="table-main-row-make date">
            <p>05/07/2023</p>
          </div>
          <div class="table-main-row-make marked-rtrnd">
            <p>{{ getIsMarkedAsReturn(credit) }}</p>
          </div>
          <div class="table-main-row-make make">
            <p>{{credit.make}}</p>
          </div>
          <div class="table-main-row-make type">
            <p>{{credit.type}}</p>
          </div>
          <div class="table-main-row-make part-description">
            <p>{{credit.partDescription}}</p>
          </div>
          <div class="table-main-row-make part-number">
            <p>{{credit.partNumber}}</p>
          </div>
          <div class="table-main-row-make order-nbr">
            <p>{{credit.orderNumber}}</p>
          </div>
          <div class="table-main-row-make qty-order">
            <p>{{credit.qtyOrdered}}</p>
          </div>
          <div class="table-main-row-make qty-crd">
            <p>{{credit.qtyReceived}}</p>
          </div>
          <div class="table-main-row-make list-price">
            <p>{{formatValue(credit.rrp) | formatMoney}}</p>
          </div>
          <div class="table-main-row-make buy-price">
            <p>{{formatValue(credit.buyPriceSubtotal) | formatMoney}}</p>
          </div>
          <div class="table-main-row-make credit-price">
            <p>{{formatValue(credit.buyPriceSubtotal) | formatMoney}}</p>
          </div>
          <div class="table-main-row-make gst">
            <p>10%</p>
          </div>
          <div class="table-main-row-make total">
            <p>{{formatValue(credit.buyPriceSubtotal) *1.1 | formatMoney}}</p>
          </div>
        </div>
        <div class="table-main-row-total-wrapper">
          <div class="supplier-name">
            <p></p>
          </div>
          <div class="phone">
            <p></p>
          </div>
          <div class="table-main-row-total status">
            <p></p>
          </div>
          <div class="table-main-row-total date">
            <p></p>
          </div>
          <div class="table-main-row-total marked-rtrnd">
            <p></p>
          </div>
          <div class="table-main-row-total make">
            <p></p>
          </div>
          <div class="table-main-row-total type">
            <p></p>
          </div>
          <div class="table-main-row-total part-description">
            <p></p>
          </div>
          <div class="table-main-row-total part-number">
            <p></p>
          </div>
          <div class="table-main-row-total order-nbr">
            <p></p>
          </div>
          <div class="table-main-row-total qty-order">
            <p></p>
          </div>
          <div class="table-main-row-total qty-crd">
            <p></p>
          </div>
          <div class="table-main-row-total list-price">
            <p></p>
          </div>
          <div class="table-main-row-total buy-price font-weight-bold">
            <p>Totals</p>
          </div>
          <div class="table-main-row-total credit-price font-weight-bold">
            <p>{{getTotalCreditPrice(itm) | formatMoney}}</p>
          </div>
          <div class="table-main-row-total gst font-weight-bold">
            <p></p>
          </div>
          <div class="table-main-row-total total font-weight-bold">
            <p>{{getTotal(itm) | formatMoney}}</p>
          </div>
        </div>

        <div class="table-main-row-all-total-line"></div>

              <div class="table-main-row-all-total-wrapper">
                <div class="supplier-name additional-width-block">
                  <p></p>
                </div>
                <div class="phone">
                  <p></p>
                </div>
                <div class="status">
                  <p></p>
                </div>
                <div class="date">
                  <p></p>
                </div>
                <div class="marked-rtrnd">
                  <p></p>
                </div>
                <div class="make">
                  <p></p>
                </div>
                <div class="type">
                  <p></p>
                </div>
                <div class="part-description">
                  <p></p>
                </div>
                <div class="part-number">
                  <p></p>
                </div>
                <div class="order-nbr">
                  <p></p>
                </div>
                <div class="qty-order">
                  <p></p>
                </div>
                <div class="qty-crd">
                  <p></p>
                </div>
                <div class="list-price">
                  <p></p>
                </div>
                <div class="table-main-row-total buy-price font-weight-bold">
                  <p>Month End Spend</p>
                </div>
                <div class="table-main-row-total credit-price font-weight-bold">
                  <p></p>
                </div>
                <div class="table-main-row-total gst font-weight-bold">
                  <p></p>
                </div>
                <div class="table-main-row-total total font-weight-bold">
                  <p>{{getMonthEndSpend(itm) | formatMoney}}</p>
                </div>
              </div>
              <div class="table-main-row-all-total-wrapper">
                <div class="supplier-name additional-width-block">
                  <p></p>
                </div>
                <div class="phone">
                  <p></p>
                </div>
                <div class="status">
                  <p></p>
                </div>
                <div class="date">
                  <p></p>
                </div>
                <div class="marked-rtrnd">
                  <p></p>
                </div>
                <div class="make">
                  <p></p>
                </div>
                <div class="type">
                  <p></p>
                </div>
                <div class="part-description">
                  <p></p>
                </div>
                <div class="part-number">
                  <p></p>
                </div>
                <div class="order-nbr">
                  <p></p>
                </div>
                <div class="qty-order">
                  <p></p>
                </div>
                <div class="qty-crd">
                  <p></p>
                </div>
                <div class="list-price">
                  <p></p>
                </div>
                <div class="table-main-row-all-total buy-price font-weight-bold">
                  <p>Credit Return Percentage</p>
                </div>
                <div class="table-main-row-all-total credit-price font-weight-bold">
                  <p></p>
                </div>
                <div class="table-main-row-all-total gst font-weight-bold">
                  <p></p>
                </div>
                <div class="table-main-row-all-total total font-weight-bold">
                  <p>{{getCreditReturnPercent(itm)}}%</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  /* eslint-disable */
    import {mapActions, mapGetters} from "vuex"
    import * as dayjs from 'dayjs'
    import Multiselect from 'vue-multiselect'
    import _ from "lodash";
    import DateRangePicker from '../../../components/DateRangePicker'

    export default {
      name: "partsCreditReturnPercentage",
      components: {
        Multiselect,
        DateRangePicker
      },
      data() {
        return {
          dateRangePickerValue: {
            end: null,
            start: null,
          },
          data: [
            {
              "\ufeffcreditId":"95",
              "orderPartId":"787",
              "amountCredit":"275",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Auto Parts Group",
              "phone":"287 854 599",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"RH HEADLAMP",
              "partNumber":"13465",
              "orderNumber":"6",
              "rrp":"300",
              "buyPriceSubtotal":"250",
              "isMarkAsReturned":"1"
            },
            {
              "\ufeffcreditId":"96",
              "orderPartId":"952",
              "amountCredit":"88",
              "amountApproved":"88.00000",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"Peter Warren",
              "phone":"298 288 999",
              "make":"HONDA",
              "type":"OEM",
              "partDescription":"RH FRONT GUARD",
              "partNumber":"RYU678",
              "orderNumber":"7",
              "rrp":"100",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"97",
              "orderPartId":"951",
              "amountCredit":"88",
              "amountApproved":"88.00000",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"Peter Warren",
              "phone":"298 288 999",
              "make":"HONDA",
              "type":"OEM",
              "partDescription":"FRONT BUMPER BAR",
              "partNumber":"ASD123",
              "orderNumber":"7",
              "rrp":"100",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"104",
              "orderPartId":"1364",
              "amountCredit":"39.6",
              "amountApproved":"39.60000",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"West End Mazda",
              "phone":"296 338 383",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"TAILGATE BADGE-3",
              "partNumber":"K341-51-771",
              "orderNumber":"15",
              "rrp":"56.46",
              "buyPriceSubtotal":"36",
              "isMarkAsReturned":"1"
            },
            {
              "\ufeffcreditId":"120",
              "orderPartId":"992",
              "amountCredit":"88",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Cancelled",
              "supplierName":"Peter Warren",
              "phone":"298 288 999",
              "make":"HONDA",
              "type":"OEM",
              "partDescription":"FRONT BUMPER BAR",
              "partNumber":"AS4435",
              "orderNumber":"10",
              "rrp":"100",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"121",
              "orderPartId":"1367",
              "amountCredit":"2.76",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"West End Mazda",
              "phone":"296 338 383",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"REAR BAR TOW HOOK COVER",
              "partNumber":"K131-50-EK1A",
              "orderNumber":"16",
              "rrp":"5",
              "buyPriceSubtotal":"2.51",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"122",
              "orderPartId":"1368",
              "amountCredit":"60.5",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"West End Mazda",
              "phone":"296 338 383",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"TAILGATE EMBLEM",
              "partNumber":"TK78-51-731A",
              "orderNumber":"16",
              "rrp":"85.18",
              "buyPriceSubtotal":"55",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"134",
              "orderPartId":"682",
              "amountCredit":"550",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"New Town Toyota",
              "phone":"894 722 600",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"FRONT BUMPER & DISSASS",
              "partNumber":"T009000",
              "orderNumber":"1",
              "rrp":"550",
              "buyPriceSubtotal":"500",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"135",
              "orderPartId":"1760",
              "amountCredit":"60.5",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"New Town Toyota",
              "phone":"894 722 604",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"RHF BAR OUTER RETAINER",
              "partNumber":"789363",
              "orderNumber":"20",
              "rrp":"50",
              "buyPriceSubtotal":"45",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"135",
              "orderPartId":"1762",
              "amountCredit":"11",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"New Town Toyota",
              "phone":"894 722 604",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"FRT GRILLE",
              "partNumber":"456564",
              "orderNumber":"20",
              "rrp":"25",
              "buyPriceSubtotal":"10",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"137",
              "orderPartId":"1459",
              "amountCredit":"71.5",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Cancelled",
              "supplierName":"Auto Parts Group",
              "phone":"287 854 599",
              "make":"VOLKSWAGEN",
              "type":"AFTM",
              "partDescription":"RH-L MIRROR ASSY",
              "partNumber":"VVT01325",
              "orderNumber":"19",
              "rrp":"",
              "buyPriceSubtotal":"65",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"143",
              "orderPartId":"1824",
              "amountCredit":"308",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Sydney City Toyota",
              "phone":"281 178 494",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"CLOCK SPRING",
              "partNumber":"TYCMY00005",
              "orderNumber":"21",
              "rrp":"350",
              "buyPriceSubtotal":"280",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"144",
              "orderPartId":"1953",
              "amountCredit":"151.03",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Audi Centre Sydney",
              "phone":"299 313 490",
              "make":"AUDI",
              "type":"OEM",
              "partDescription":"Frt Bar Center Conn",
              "partNumber":"8R0807661GRU",
              "orderNumber":"QWE54Q",
              "rrp":"171.09",
              "buyPriceSubtotal":"137.3",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"145",
              "orderPartId":"2010",
              "amountCredit":"102.33",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"AUDI",
              "type":"OEM",
              "partDescription":"AUDI SIGN",
              "partNumber":"4208537422ZZ",
              "orderNumber":"YYU76R",
              "rrp":"102.74",
              "buyPriceSubtotal":"93.03",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"146",
              "orderPartId":"2676",
              "amountCredit":"88",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"FRT BUMPER BAR 2 PIECE",
              "partNumber":"TOY-002",
              "orderNumber":"TEST001",
              "rrp":"100",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"146",
              "orderPartId":"2678",
              "amountCredit":"88",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"",
              "partNumber":"",
              "orderNumber":"TEST001",
              "rrp":"100",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"147",
              "orderPartId":"2728",
              "amountCredit":"88",
              "amountApproved":"88.00000",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"FRT BUMPER BAR 2 PIECE",
              "partNumber":"OEM - 0002",
              "orderNumber":"TEST0002",
              "rrp":"100",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"1"
            },
            {
              "\ufeffcreditId":"148",
              "orderPartId":"2784",
              "amountCredit":"165",
              "amountApproved":"",
              "qtyOrdered":"3",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"SUBARU",
              "type":"OEM",
              "partDescription":"LH FRT BUMPER MNT BRCKT ON GRD",
              "partNumber":"PRT-4",
              "orderNumber":"2614",
              "rrp":"130",
              "buyPriceSubtotal":"50",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"148",
              "orderPartId":"2785",
              "amountCredit":"55",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"SUBARU",
              "type":"OEM",
              "partDescription":"",
              "partNumber":"",
              "orderNumber":"2614",
              "rrp":"120",
              "buyPriceSubtotal":"50",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"149",
              "orderPartId":"2788",
              "amountCredit":"198",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"HONDA",
              "type":"OEM",
              "partDescription":"LHF BUMPER END",
              "partNumber":"GEFSFDG002",
              "orderNumber":"1022",
              "rrp":"200",
              "buyPriceSubtotal":"180",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"156",
              "orderPartId":"2797",
              "amountCredit":"103.75",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"RETAINER RR BUMPER",
              "partNumber":"MZ-D0ZH-52-1R0A",
              "orderNumber":"2531",
              "rrp":"99.02",
              "buyPriceSubtotal":"94.32",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"156",
              "orderPartId":"2798",
              "amountCredit":"330",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"REINFORCEMENT RR",
              "partNumber":"MZ-DA6T-51-0K0B",
              "orderNumber":"2531",
              "rrp":"335.37",
              "buyPriceSubtotal":"300",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"156",
              "orderPartId":"2799",
              "amountCredit":"59.49",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"",
              "partNumber":"",
              "orderNumber":"2531",
              "rrp":"56.78",
              "buyPriceSubtotal":"54.08",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"161",
              "orderPartId":"2818",
              "amountCredit":"275",
              "amountApproved":"275.00000",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"PANEL(RH).FENDER-FRT",
              "partNumber":"MZ-DA6V-52-111",
              "orderNumber":"258795",
              "rrp":"390",
              "buyPriceSubtotal":"250",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"161",
              "orderPartId":"2819",
              "amountCredit":"55",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"GUARD(RH).MUD",
              "partNumber":"MZ-DA6V-56-131B",
              "orderNumber":"258795",
              "rrp":"80",
              "buyPriceSubtotal":"50",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"163",
              "orderPartId":"3370",
              "amountCredit":"159.5",
              "amountApproved":"159.50000",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"RHF GUARD",
              "partNumber":"B45A-52-111B",
              "orderNumber":"1068",
              "rrp":"310.41",
              "buyPriceSubtotal":"145",
              "isMarkAsReturned":"1"
            },
            {
              "\ufeffcreditId":"164",
              "orderPartId":"2781",
              "amountCredit":"99",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"BMW",
              "type":"OEM",
              "partDescription":"BONNET",
              "partNumber":"BMW-0004",
              "orderNumber":"1016",
              "rrp":"120",
              "buyPriceSubtotal":"90",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"165",
              "orderPartId":"3403",
              "amountCredit":"35.88",
              "amountApproved":"",
              "qtyOrdered":"7",
              "qtyReceived":"1",
              "status":"Denied",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"VOLVO",
              "type":"OEM",
              "partDescription":"CLIP -D",
              "partNumber":"30622764",
              "orderNumber":"1069",
              "rrp":"115.7",
              "buyPriceSubtotal":"24198",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"166",
              "orderPartId":"2744",
              "amountCredit":"352",
              "amountApproved":"",
              "qtyOrdered":"4",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"RH FRT BUMPER MNT BRACKET ON GRD",
              "partNumber":"TOY-0004",
              "orderNumber":"TEST01",
              "rrp":"120",
              "buyPriceSubtotal":"80",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"167",
              "orderPartId":"3687",
              "amountCredit":"1302.33",
              "amountApproved":"1302.33",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"Approved",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"HOLDEN",
              "type":"OEM",
              "partDescription":"",
              "partNumber":"",
              "orderNumber":"1094",
              "rrp":"1578.59",
              "buyPriceSubtotal":"1183.94",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"170",
              "orderPartId":"3750",
              "amountCredit":"1028.5",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier PARAFTM",
              "phone":"288 888 885",
              "make":"TOYOTA",
              "type":"OEM",
              "partDescription":"RH HEADLAMP",
              "partNumber":"1232135",
              "orderNumber":"E123345",
              "rrp":"1000",
              "buyPriceSubtotal":"935",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"171",
              "orderPartId":"3769",
              "amountCredit":"60.5",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"LHR BUMPER SLIDE",
              "partNumber":"D10J-50-2J1B",
              "orderNumber":"1064",
              "rrp":"89.91",
              "buyPriceSubtotal":"55",
              "isMarkAsReturned":"0"
            },
            {
              "\ufeffcreditId":"178",
              "orderPartId":"3768",
              "amountCredit":"509.3",
              "amountApproved":"",
              "qtyOrdered":"1",
              "qtyReceived":"1",
              "status":"UnderReview",
              "supplierName":"Test Supplier OEM",
              "phone":"288 888 888",
              "make":"MAZDA",
              "type":"OEM",
              "partDescription":"REAR BUMPER",
              "partNumber":"DHYE-50-22XABB",
              "orderNumber":"1064",
              "rrp":"990.02",
              "buyPriceSubtotal":"463",
              "isMarkAsReturned":"0"
            }
          ],
          sortBy: 'None',
          showFilters: false,
          supplierName: 'All - Suppliers',
          makeName: 'All - Makes',
          partType: 'All - Part Types',
          selectedFilter: 'Today',
          valueSupplier: ['All - Suppliers'],
        }
      },
      mounted() {
        this.initMenu();
      },
      methods: {
        ...mapActions({

        }),
        initMenu () {
          this.$root.$on('bv::dropdown::show', bvEvent => {
            if (bvEvent.componentId === 'dropdown-2') {
              this.isDropdown2Visible = true
            }
          })
          this.$root.$on('bv::dropdown::hide', bvEvent => {
            if (bvEvent.componentId === 'dropdown-2') {
              this.isDropdown2Visible = false
              if (!_.isEmpty(this.$refs.optionMenu)) this.$refs.optionMenu.hide()
            }
            if (this.isDropdown2Visible) {
              bvEvent.preventDefault()
            }
          })
        },
        onSelectSupplier (v) {
          if (this.valueSupplier.includes('All - Suppliers')) {
            let index = this.valueSupplier.indexOf('All - Suppliers');
            this.valueSupplier.splice(index, 1);
          } else if (v === 'All - Suppliers') {
            this.valueSupplier.splice(0, this.valueSupplier.length)
          }
        },
        onRemoveSupplier () {
          if (this.valueSupplier.length === 1) {
            this.valueSupplier.push('All - Suppliers');
          }
        },
        isDateInDateFilter (value) {
          let result = {
            start: null,
            end: null,
          }
          value = dayjs(value, 'DD.MM.YYYY hh:mm').format('DD/MM/YYYY')
          if (this.selectedFilter) {
            if (this.selectedFilter === 'Today') {
              result.start = dayjs(new Date()).format('DD/MM/YYYY');
              result.end = dayjs(new Date()).format('DD/MM/YYYY');
            } else if (this.selectedFilter === 'This Month') {
              let date = new Date(), y = date.getFullYear(), m = date.getMonth();
              result.start = dayjs(new Date(y, m, 1)).format('DD/MM/YYYY');
              result.end = dayjs(new Date(y, m + 1, 0)).format('DD/MM/YYYY');
            } else if (this.selectedFilter === 'Last Month') {
              let date = new Date(), y = date.getFullYear(), m = date.getMonth();
              result.start = dayjs(new Date(y, m - 1, 1)).format('DD/MM/YYYY');
              result.end = dayjs(new Date(y, m, 0)).format('DD/MM/YYYY');
            } else if (this.selectedFilter === 'Last Quarter') {
              let quarterAdjustment= (dayjs().month() % 3) + 1;
              let lastQuarterEndDate = dayjs().subtract(quarterAdjustment, 'month').endOf('month');
              let lastQuarterStartDate = lastQuarterEndDate.clone().subtract(2, 'month').startOf('month');
              result.start = dayjs(lastQuarterStartDate).format('DD/MM/YYYY');
              result.end = dayjs(lastQuarterEndDate).format('DD/MM/YYYY');
            } else if (this.selectedFilter === 'Last Financial Year') {
              let year = new Date().getFullYear()
              let month = new Date().getMonth();
              if (month > 5) {
                year -= 1
              } else {
                year -=2
              }

              result.end = dayjs(new Date(year + 1, 5, 30)).format('DD/MM/YYYY');
              result.start = dayjs(new Date(year, 6, 1)).format('DD/MM/YYYY');
            } else if (this.selectedFilter === 'Custom Date Range') {
              result.start = this.dateRangePickerValue.start
              result.end = this.dateRangePickerValue.end
            }
          }

          let isBetween = require('dayjs/plugin/isBetween')
          dayjs.extend(isBetween)

          return dayjs(dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD')).isBetween(dayjs(result.start, 'DD/MM/YYYY').format('YYYY-MM-DD'), dayjs(result.end, 'DD/MM/YYYY').format('YYYY-MM-DD')) || value === result.start || value === result.end;
        },
        filterData (v) {
          let result = _.filter(v, (itm) => {
            let bool = true
            // if (this.selectedFilter) {
            //   bool = bool && this.isDateInDateFilter(itm.creditPartCreatedOn);
            // }
            if (this.valueSupplier) {
              if (!this.valueSupplier.includes('All - Suppliers')) {
                bool = bool && this.valueSupplier.includes(itm.supplierName)
              }
            }
            return bool
          })

          return result
        },
        getMonthEndSpend(itm) {
          let r = 0;
          _.forEach(itm.credits, (credit) => {
            r += this.customParseFloat(credit.buyPriceSubtotal)
          })
          return r
        },
        getCreditReturnPercent(itm) {
          let countOfReturned = 0;
          _.forEach(itm.credits, (credit) => {
            if (Number(credit.isMarkAsReturned)) {
              countOfReturned++;
            }
          })
          return ((countOfReturned * 100) / itm.credits.length).toFixed(2);
        },
        isEmpty (v) {
          return _.isEmpty(v)
        },
        getStatus (v) {
          if (v === 'UnderReview') {
            return 'Under Review'
          } else {
            return  v
          }
        },
        getIsMarkedAsReturn (v) {
          return (Number(v.isMarkAsReturned)) ? 'Yes' : 'No'
        },
        getTotalCreditPrice(itm) {
          let r = 0;
          _.forEach(itm.credits, (credit) => {
            r += this.customParseFloat(credit.buyPriceSubtotal)
          })
          return r
        },
        getTotal (itm) {
          let r = 0;
          _.forEach(itm.credits, (credit) => {
            r += this.customParseFloat(credit.buyPriceSubtotal * 1.1)
          })
          return r
        },
        getAllTotalCreditPrice() {
          let r = 0;
          _.forEach(this.filteredData, (itm) => {
            _.forEach(itm.credits, (credit) => {
              r += this.customParseFloat(credit.buyPriceSubtotal)
            })
          })
          return r
        },
        formatValue (v) {
          if (v && _.isString(v) && v.includes(',')) {
            v = v.replace(',', '.')
          }
          return v
        },
        customParseFloat (v) {
          if (v && _.isString(v) && v.includes(',')) {
            v = v.replace(',', '.')
          }
          return parseFloat(v)
        },
        getToday () {
          return dayjs(new Date()).format('DD MMM YYYY')
        },
        getEndOfThisMonth () {
          var date = new Date(), y = date.getFullYear(), m = date.getMonth()
          var lastDay = new Date(y, m + 1, 0)
          return dayjs(lastDay).format('DD MMM YYYY')
        },
        getEndOfLastMonth () {
          var date = new Date(), y = date.getFullYear(), m = date.getMonth()
          var lastDay = new Date(y, m, 0)
          return dayjs(lastDay).format('DD MMM YYYY')
        },
        getEndOfLastQuarter () {
          var quarterAdjustment = (dayjs().month() % 3) + 1
          var lastQuarterEndDate = dayjs().subtract(quarterAdjustment, 'month').endOf('month')
          // var lastQuarterStartDate = lastQuarterEndDate.clone().subtract({ months: 3 }).startOf('month');
          return dayjs(lastQuarterEndDate).format('DD MMM YYYY')
        },
        getEndOfLastYear () {
          let lastDay = new Date(new Date().getFullYear() - 1, 5, 30)
          return dayjs(lastDay).format('DD MMM YYYY')
        },
        getFilter () {
          if (this.selectedFilter) {
            return this.selectedFilter
          } else {
            return dayjs(new Date()).format('DD MMM YYYY')
          }
        },
      },
      computed: {
        ...mapGetters({

        }),
        computedData () {
          let result = []
          let data = this.data;
          _.forEach(data, (itm) => {
            let supp = _.find(result, (s) => {
              return s && s.supplierName === itm.supplierName
            })
            if (supp) {
              if (_.isArray(supp.credits)) {
                if (itm.status === 'Approved') {
                  supp.credits.push(itm)
                }
              } else {
                if (itm.status === 'Approved') {
                  supp.credits = [
                    itm
                  ]
                } else {
                  supp.credits = []
                }
              }

            } else {
              let obj = {
                ...itm,
                credits: [],
              }
              if (itm.status === 'Approved') {
                obj.credits.push(itm)
              }
              result.push(obj)

            }
          })

          return _.filter(result, (it) => {
            return !_.isEmpty(it.credits)
          })
        },
        suppliersOptions () {
          let res = ['All - Suppliers']
          _.forEach(this.computedData, (itm) => {
            if (!res.includes(itm.supplierName)) {
              res.push(itm.supplierName)
            }
          })
          return res
        },
        filteredData () {
          let result = []
          let data = this.data;
          _.forEach(data, (itm) => {
            let supp = _.find(result, (s) => {
              return s && s.supplierName === itm.supplierName
            })
            if (supp) {
              if (_.isArray(supp.credits)) {
                if (itm.status === 'Approved') {
                  supp.credits.push(itm)
                }
              } else {
                if (itm.status === 'Approved') {
                  supp.credits = [
                    itm
                  ]
                } else {
                  supp.credits = []
                }
              }

            } else {
              let obj = {
                ...itm,
                credits: [],
              }
              if (itm.status === 'Approved') {
                obj.credits.push(itm)
              }
              result.push(obj)

            }
          })
          return this.filterData(result)
        }
      }
    }
  </script>

  <style scoped>
    .chart-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #1C1F39;
    }

    .theme-repairer .dropdown-options .pull-right {
      padding-right: 6px;
      padding-top: 10px;
    }

    .options-line {
      width: 95%;
      margin: 0 auto;
      height: 2px;
      background-color: rgba(27, 30, 56, 0.25);
      margin-top: 12px;
    }

    .multiselect-suppliers-options {
      width: 220px;
      margin-right: 15px;
    }

    .multiselect-make-options,
    .multiselect-type-options {
      margin-right: 15px;
    }

    .report-parts-wrapper {
      width: calc(100vw - 435px);
    }

    .report-parts-wrapper .dropdown-options.show .dropdown-arrow .bx-down-arrow {
      transform: rotate(0.5turn);
    }

    .title-options {
      font-weight: bold;
      height: 20px;
      margin-bottom: 5px;
    }

    .additional-sort-block {
      margin-top: 38px;
    }

    /* Report Table */
    .report-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
    }

    .table-header,
    .table-main,
    .table-main-row-make-wrapper,
    .table-main-row-total-wrapper,
    .table-main-row-all-total-wrapper {
      display: flex;
    }

.table-main {
  flex-direction: column;
}

.table-main-row {
  padding: 0.5rem 1rem;
}

.table-main-row p {
  margin: 0;
  height: 100%;
}

.table-header-row {
  color: #fff;
  background-color: #79B1BB;
  padding: 1.25rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.table-header-row p,
.table-main-row-supplier p,
.table-main-row-make p,
.table-main-row-total p,
.table-main-row-all-total p {
  margin-bottom: 0;
}

.table-main-row-supplier-wrapper {
  display: flex;
  width: 100%;
  color: #1C1F39;
}

.status-denied {
  color: #ff0009;
}

.table-main-row-supplier {
  background-color: #F3F3F3;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
  padding: 0.5rem 1rem;
  width: 100%;
}

.table-main-row-make {
  width: 100%;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}

.table-main-row-all-total-line {
  height: 30px;
  width: 100%;
}

.table-main-row-total {
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 2px solid #1C1F39;
}

.table-main-row-all-total {
  padding: 0.5rem 1rem;
}

.additional-width-block {
  width: 100%;
}

.supplier-name {
  min-width: 200px;
}

.phone {
  min-width: 120px;
}

.type,
.status {
  min-width: 80px;
}

.part-description,
.part-number {
  min-width: 150px;
}

.date,
.list-price {
  min-width: 100px;
}

.credit-price,
.buy-price {
  min-width: 100px;
  max-width: 100px;
}

.list-price-subtotal {

}

.make {
  min-width: 120px;
}

.total {
  min-width: 120px;
  max-width: 120px;
}

.gst {
  min-width: 75px;
  max-width: 75px;
}

.marked-rtrnd,
.order-nbr,
.qty-crd,
.qty-order {
  min-width: 70px;
}

.table-main-row-total.buy-price p,
.table-main-row-all-total.buy-price p {
  white-space: nowrap;
}
</style>