<template>
    <div class="customer-account-deliver">
        <div class="customer-account-deliver-blocks">
            <span class="label mr-2" style="white-space: nowrap;">{{labelBusiness}} :</span>
            <span class="val"> {{ customer }}</span>
        </div>
        <div v-if="account" class="customer-account-deliver-blocks">
            <span class="label mr-2" style="white-space: nowrap;">{{labelAccount}} :</span>
            <span class="val"> {{ account }}</span>
        </div>
        <div  class="customer-account-deliver-blocks">
            <span class="label mr-2" style="white-space: nowrap;">{{labelAddress}} :</span>
            <span v-if="deliveryToText" class="val" v-html="deliveryToText"></span>
        </div>
        <div  class="customer-account-deliver-blocks">
            <span class="label mr-2" style="white-space: nowrap;">{{labelEstimator}} :</span>
            <span v-if="estimator" class="val">{{ estimator }}</span>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";

    export default {
        name: "CustomerAccountDeliver",
        props: {
            labelBusiness: {
                type: String,
                default: 'Business'
            },
            labelAccount: {
                type: String,
                default: 'Account'
            },
            labelAddress: {
                type: String,
                default: 'Address'
            },
            labelEstimator: {
                type: String,
                default: 'Estimator'
            },
            customer: {
                type: String,
                required: true
            },
            account: {
                type: String,
                required: true
            },
            deliveryTo: {
                required: true
            },
            estimator: {
                type: String,
                default: ''
            }
        },
        computed: {
            deliveryToText() { //with html
                let result = '';
                if (!_.isEmpty(this.deliveryTo) && typeof this.deliveryTo === "string") {
                    result = this.deliveryTo;
                } else if (!_.isEmpty(this.deliveryTo) && typeof this.deliveryTo === "object") {
                    const {address, suburb, state, postcode, unitLevelLot} = this.deliveryTo;
                    if (!_.isEmpty(address)) {
                      result += address;
                      if (!_.isEmpty(unitLevelLot)) {
                        result += ', '
                      }
                    }
                    if (!_.isEmpty(unitLevelLot)) {
                      result += 'Unit/Level: ' + unitLevelLot;
                    }
                    if (!_.isEmpty(address) || !_.isEmpty(unitLevelLot)) {
                      result += '<br>'
                    }
                    if (!_.isEmpty(suburb)) {
                      result += suburb;
                      if (!_.isEmpty(state) || !_.isEmpty(postcode)) {
                        result += ', ';
                      }
                    }
                    if (!_.isEmpty(state)) {
                      result += state;
                      if (!_.isEmpty(postcode)) {
                        result += ', ';
                      }
                    }
                    if (!_.isEmpty(postcode)) {
                      result += postcode;
                    }
                }
                return result;
            }
        }
    }
</script>

<style scoped>
    .customer-account-deliver span.label {
        width: 73px;
        text-align: justify;
        display: inline-block;
    }

    .customer-account-deliver {
        margin: 0 0 15px 0px;
        font-weight: bold;
    }

    .customer-account-deliver div {
        margin: 0px 0px 5px 0px;
        color: #a0a1a7;
    }
    .customer-account-deliver span:nth-child(2) {
        margin-right: 20px;
    }

    .customer-account-deliver span.val {
        color: #1C1F39;
        display: inline-flex;
    }

    .customer-account-deliver-blocks {
        /*min-width: 360px;*/
    }

    @media (min-width: 1200px) {
        .customer-account-deliver {
            margin: 45px 0 0 0;
        }
    }

    @media screen and (max-width: 1240px) {
        .customer-account-deliver-blocks {
            display: flex;
            flex-direction: column;
            min-width: 200px !important;
        }

        .customer-account-deliver {
            margin: 15px 0 15px 0px;
        }
    }

    @media screen and (max-width: 991px) {
        .customer-account-deliver-blocks {
            flex-direction: unset;
            min-width: 360px !important;
        }

        .customer-account-deliver {
            margin: 0 0 15px 0px;
        }
    }

    @media screen and (max-width: 450px) {
        .customer-account-deliver-blocks {
            flex-direction: unset;
            min-width: auto !important;
        }

        .customer-account-deliver span {
            margin-right: 5px !important;
        }
    }
</style>
