<template>
  <div>
    <div class="tabs-component" :class="viewClass">
      <div class="tab-header view-header-buttons" v-if="$slots['nav-item-center']">
        <slot name="nav-item-center"/>
      </div>

      <ul role="tablist" class="tab-header nav nav-tabs nav-tabs-2 float-sm-left">
        <li v-for="(tab, i) in computedTabs"
            :key="i"
            :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
            class="tabs-component-tab nav-item"
            role="presentation"
            v-show="tab.isVisible">
          <a
              :aria-controls="tab.hash"
              :aria-selected="tab.isActive"
              @click.prevent="selectTab(tab.hash, $event)"
              :class="{ 'active': tab.isActive, 'disabled': tab.isDisabled }"
              :href="tab.hash"
              class="tabs-component-tab-a  nav-link"
              role="tab">
            {{ tab.header }} <span v-if="customCounts[tab.id]" v-b-tooltip.hover="{customClass: 'ps-tooltip tabs-tooltip-custom', html: true, title:customTooltips[tab.id], placement:'rightbottom'}" class="tag tag-info">{{ customCounts[tab.id] }}</span>
          </a>
        </li>
        <div v-if="isShowTabButton" v-html="tabButtonHTML" @click="$emit('onClickBtn')"
             class="d-flex align-items-center"></div>
      </ul>
      <div class="tab-header rs-btn-group float-sm-right mt-1-sm pr-3 d-flex align-items-center mb-2 mobile-version-margin" style="height: 41px;">
        <slot name="nav-item-right"/>
      </div>
    </div>
    <div :style="cssProps">
      <slot/>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import expiringStorage from '../expiringStorage'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    isShowTabButton: {
      type: Boolean,
      default: false,
    },
    isNeedSaveInStorage: {
      type: Boolean,
      default: true,
    },
    tabButtonHTML: {
      type: String,
      default: ''
    },
    viewClass: {
      default: '',
    },
    cacheLifetime: {
      default: 500000,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        useUrlFragment: true,
        defaultTabHash: null,
        isOrdered: false,
      }),
    },
    topGap: {
      type: Boolean,
      default: true,
    },
    customCounts: {
      type: Object,
      default:  () => ({}),
    },
    customTooltips: {
      type: Object,
      default: () => ({}),
    }
  },

  data: () => ({
    dataCacheLifetime: 500000,
    tabs: [],
    activeTabHash: '',
    isStartedInterval: false,
    activeTabIndex: 0,
    lastActiveTabHash: '',
  }),

  computed: {
    ...mapGetters({
      currentUser: 'currentUser/getCurrentUserInfo',
    }),
    storageKey () {
      return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`
    },
    cssProps () {
      return {
        'border': !this.topGap && 'none'
      }
    },

    computedTabs: function () {
      this.initTabs()
      let tabs = _.filter(this.tabs, function (i) {
        return i.isTab && i.isShow
      })
      if (this.options.isOrdered) {
        tabs = _.orderBy(tabs, 'order')
      }
      return tabs
    }
  },
  watch: {
    computedTabs: {
      handler () {
        if (!this.isStartedInterval) {
          if (_.isEmpty(this.activeTabHash) && this.activeTabIndex == 0 && _.isEmpty(this.lastActiveTabHash)) {
            let interval = setInterval(() => {
              this.isStartedInterval = true
              if (!_.isEmpty(this?.currentUser?.id)) {
                this.setTab()
                this.isStartedInterval = false
                clearInterval(interval)
              }
            }, 20)
          } else {
            this.$nextTick(() => {
              const previousSelectedTabHash = this.isNeedSaveInStorage ? expiringStorage.get(this.storageKey) : null
              if (previousSelectedTabHash && this.findTab(previousSelectedTabHash)) {
                this.selectTab(previousSelectedTabHash)
                return
              }
            })
          }
        }
      },
      immediate: true,
    }
  },
  created () {
    this.$nextTick(() => {
      this.initTabs()
    })
  },
  mounted () {
    window.addEventListener('hashchange', () => this.selectTab(window.location.hash))
    //console.log('window.location.hash', window.location.hash);
    if (this.findTab(window.location.hash)) {
      this.selectTab(window.location.hash)
      return
    }

    this.setTab()

    this.$nextTick(() => {
      this.initTabs()
    })
  },

  methods: {
    initTabs () {
      this.tabs = this.$children
    },
    setTab () {
      const previousSelectedTabHash = this.isNeedSaveInStorage ? expiringStorage.get(this.storageKey) : null
      this.$nextTick(() => {
        //console.log('this.findTab(previousSelectedTabHash)', this.findTab(previousSelectedTabHash));
        if (this.findTab(previousSelectedTabHash)) {
          this.selectTab(previousSelectedTabHash)
          return
        }

        if (this.options.defaultTabHash !== null && this.findTab('#' + this.options.defaultTabHash)) {
          //console.log('set defaultTabHash', this.options.defaultTabHash)
          this.selectTab('#' + this.options.defaultTabHash)
          return
        }

        if (this.computedTabs.length) {
          let hash = ''
          if (this.computedTabs[0].hash) {
            hash = this.computedTabs[0].hash
          } else if (this.computedTabs[1].hash) {
            hash = this.computedTabs[1].hash
          }
          this.selectTab(hash, null, true)
        }
      })
    },
    findTab (hash) {
      return this.computedTabs.find(tab => tab.hash === hash)
    },

    selectTab (selectedTabHash, event, isMounted = false) {
      // console.log('selectTab', selectedTabHash, event)
      // return;

      // See if we should store the hash in the url fragment.
      if (event && !this.options.useUrlFragment) {
        event.preventDefault()
      }

      const selectedTab = this.findTab(selectedTabHash)

      if (!selectedTab) {
        return
      }

      if (selectedTab.isDisabled) {
        event.preventDefault()
        return
      }

      if (this.lastActiveTabHash === selectedTab.hash && isMounted) {
        this.$emit('clicked', { tab: selectedTab })
        return
      }

      this.computedTabs.forEach(tab => {
        tab.isActive = (tab.hash === selectedTab.hash)
      })

      this.$emit('changed', { tab: selectedTab })

      this.activeTabHash = selectedTab.hash
      this.activeTabIndex = this.getTabIndex(selectedTabHash)

      this.lastActiveTabHash = this.activeTabHash = selectedTab.hash
      if (!isMounted && this.isNeedSaveInStorage) {
        expiringStorage.set(this.storageKey, selectedTab.hash, this.dataCacheLifetime)
      }
    },

    setActive (hash, visible) {
      const tab = this.findTab(hash)

      if (!tab) {
        return
      }

      tab.isActive = visible
    },

    setTabVisible (hash, visible) {
      const tab = this.findTab(hash)

      if (!tab) {
        return
      }

      tab.isVisible = visible

      if (tab.isActive) {
        // If tab is active, set a different one as active.
        tab.isActive = visible

        this.tabs.every((tab, index, array) => {
          if (tab.isVisible) {
            tab.isActive = true

            return false
          }

          return true
        })
      }
    },

    getTabIndex (hash) {
      const tab = this.findTab(hash)

      return this.computedTabs.indexOf(tab)
    },

    getTabHash (index) {
      const tab = this.computedTabs.find(tab => this.computedTabs.indexOf(tab) === index)

      if (!tab) {
        return
      }

      return tab.hash
    },

    getActiveTab () {
      return this.findTab(this.activeTabHash)
    },

    getActiveTabIndex () {
      return this.getTabIndex(this.activeTabHash)
    },
  },
}
</script>
<style>
.tabs-tooltip-custom .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}
</style>
<style scoped>
.tabs-component-tab-a {
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
}
</style>
