/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {
    SUPPLIER_CREDIT_IDS_UPDATE_APPROVED,
    SUPPLIER_CREDIT_IDS_UPDATE_DENIED,
    SUPPLIER_CREDIT_IDS_UPDATE_UNDER_REVIEW,
    SUPPLIER_CREDIT_UPDATED,
    SUPPLIER_CREDIT_REINSTALLATION,
    SUPPLIER_CREDIT_FILTER, SUPPLIER_CREDIT_SHORT_UPDATING,
} from '../../../mutation-types';

export function reinstallation (context) {
    context.commit(SUPPLIER_CREDIT_REINSTALLATION)
}

export function setFilter (context, filter) {
    context.commit(SUPPLIER_CREDIT_FILTER, filter)
}

export function getCreditsByIds(context, payload) {
    Axios.post('/ir/supplier/credits', {ids: payload})
        .then(response => {
            context.commit(SUPPLIER_CREDIT_UPDATED, response.data.credits);
        })
        .catch(error => {
            console.log(error);
        });
}

export function getCreditsForTableView(context, payload = 0) {

    let url = '/ir/supplier/credits/init';
    if(!_.isEmpty(payload)) url = '/ir/supplier/credits/'+payload;
    Axios.post(url)
        .then(response => {
          if (response.data._status) {
            context.commit(SUPPLIER_CREDIT_UPDATED, response.data.credits);
          }
        })
        .catch(error => {
            console.log(error);
        });
}

export function init(context, payload = 0) {
    return getCreditsForTableView(context, payload);
}

export function shortUpdating(context, payload = 0) {
    return updatingCredits(context, payload);
}

export function updatingCredits(context, payload = 0) {

    let url = '/ir/supplier/credits/init';
    if(!_.isEmpty(payload)) url = '/ir/supplier/credits/'+payload;
    Axios.post(url)
        .then(response => {
            if (response.data._status) {
                context.commit(SUPPLIER_CREDIT_SHORT_UPDATING, response.data.credits);
            }
        })
        .catch(error => {
            console.log(error);
        });
}

