<template>
    <div class="repairer-reports-page">
        <div class="page-header">
            <h4>Reports</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    View Reports
                </li>
            </ol>
        </div>

        <div class="d-flex mt-4">
            <div class="repairer-reports-body">
                <div class="repairer-reports-menu-block">
                    <tabs ref="repairer-reports-table" cache-lifetime="0">
                        <tab class-name="tab-content-table" name="Reports" id="reports" ref="reports">
                            <div class="title-orders-block">Orders</div>
                            <div class="btn-orders-block" @click="selectOption('BackOrders')" :class="{'active-report': isReport === 'BackOrders'}">Back Orders</div>
                            <div class="title-orders-block">Purchases</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsPurchases')" :class="{'active-report': isReport === 'PartsPurchases'}">Parts Purchases</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsPurchasesNetProfit')" :class="{'active-report': isReport === 'PartsPurchasesNetProfit'}">Parts Purchases / Net Profit</div>
                            <div class="title-orders-block">Credits</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsCredits')" :class="{'active-report': isReport === 'PartsCredits'}">Parts Credits</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsCreditsRuningTotal')" :class="{'active-report': isReport === 'PartsCreditsRuningTotal'}">Parts Credits By SPLR (Runing Totals)</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsCreditsClosed')" :class="{'active-report': isReport === 'PartsCreditsClosed'}">Parts Credits By SPLR (Closed)</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsCreditReturnPercentage')" :class="{'active-report': isReport === 'PartsCreditReturnPercentage'}">Parts Credits Return Percentage</div>
                            <div  class="btn-orders-block" @click="selectOption('PartsCreditsDemo')" :class="{'active-report': isReport === 'PartsCreditsDemo'}">Parts Credits Demo</div>
                            <template v-if="false && currentCompany.id == 180 && isAdministrator">
                              <div class="title-orders-block">Custom Business</div>
                              <div class="btn-orders-block" @click="selectOption('HistoricalPartsPurchase')" :class="{'active-report': isReport === 'HistoricalPartsPurchase'}">Historical Parts Purchase</div>
                              <div class="btn-orders-block" @click="selectOption('CarCraftNSWPurchase')" :class="{'active-report': isReport === 'CarCraftNSWPurchase'}">Car Craft - NSW - Purchase  </div>
                            </template>
                          <template v-if="currentCompany.id == 180 && isAdministrator">
                            <div class="title-orders-block">Custom Business</div>
                            <div class="btn-orders-block" @click="selectOption('CustomBusiness180')" :class="{'active-report': isReport === 'CustomBusiness180'}">Snapshot 2023 Parts</div>
                          </template>
                        </tab>
                    </tabs>
                </div>

            </div>
            <div class="w-100">
                <back-orders v-if="isReport === 'BackOrders'"></back-orders>
                <parts-purchases v-if="isReport === 'PartsPurchases'"></parts-purchases>
                <parts-purchases-net-profit v-if="isReport === 'PartsPurchasesNetProfit'"></parts-purchases-net-profit>
                <parts-credits v-if="isReport === 'PartsCredits'"></parts-credits>
                <parts-credits-running-total v-if="isReport === 'PartsCreditsRuningTotal'"></parts-credits-running-total>
<!--                <parts-credits-closed v-if="isReport === 'PartsCreditsClosed'"></parts-credits-closed>-->
                <parts-credit-return-percentage v-if="isReport === 'PartsCreditReturnPercentage'"></parts-credit-return-percentage>
                <parts-credits-demo v-if="isReport === 'PartsCreditsDemo'"></parts-credits-demo>
                <custom-business-180 v-if="isReport === 'CustomBusiness180'"></custom-business-180>
            </div>
        </div>

    </div>
</template>

<script>
import BackOrders from './back-orders.vue';
import PartsPurchases from './parts-purchases.vue';
import PartsPurchasesNetProfit from './parts-purchases-net-profit.vue';
import PartsCredits from './parts-credits.vue';
import PartsCreditsRunningTotal from './parts-credits-running-total.vue';
// import PartsCreditsClosed from './parts-credits-closed.vue';
import PartsCreditReturnPercentage from './parts-credit-return-percentage.vue';
import PartsCreditsDemo from './parts-credits-demo.vue';
// import CustomBusinessHistroricalPartsPurchase from './custom-business-historical-parts-purchase';
// import CustomBusinessCarCraftNSWPurchase from './custom-business-car-craft-nsw-purchase'
import CustomBusiness180 from './custom-business-180'
import {mapGetters} from "vuex";

export default {
    name: "reports",
    data() {
        return {
            isReport: 'PartsPurchases',
        }
    },
    methods: {
        selectOption(option) {
            this.isReport = option
        },
    },
    computed: {
      ...mapGetters({
        isAdministrator: 'currentUser/isAdministrator',
        currentCompany: 'currentCompany/getDetails',
      }),
    },
    components: {
        PartsPurchases,
        BackOrders,
        PartsPurchasesNetProfit,
        PartsCredits,
        PartsCreditsRunningTotal,
     //   PartsCreditsClosed,
        PartsCreditReturnPercentage,
        PartsCreditsDemo,
        CustomBusiness180,
    },
};
</script>

<style scoped>
    .breadcrumb-item {
        cursor: pointer;
    }

    .active {
        cursor: default;
    }

    .btn-report {

    }

    .repairer-reports-menu-block {
        width: 170px;
        border: 1px solid rgba(27, 30, 56, 0.2);
        border-radius: 3px;
        padding: 15px;
    }


</style>

<style>
    .repairer-reports-page .nav-tabs-2 .nav-link.active,
    .repairer-reports-page .nav-tabs-2 .nav-link.active:focus,
    .repairer-reports-page .nav-tabs-2 .nav-link.active:hover {
        color: #79B1BB !important;
    }

    .repairer-reports-page .nav-tabs.nav-tabs-2 {
        padding-left: 0;
    }

    .repairer-reports-page .tab-content-table {
        border-top: none;
    }

    .repairer-reports-page .nav-tabs-2 .nav-link.active {
        color: #79B1BB;
    }

    .repairer-reports-page .active-report {
        color: #79B1BB;
    }

    .repairer-reports-page .title-orders-block {
        font-weight: bold;
        margin: 40px 0 15px 0;
    }

    .repairer-reports-page .btn-orders-block {
        margin: 15px 0;
        cursor: pointer;
    }

    .repairer-reports-page .btn-orders-block:hover {
        color: #79B1BB;
    }
</style>

<style>
  .report-parts-wrapper .dropdown-options .btn,
  .report-parts-wrapper .theme-repairer .report-parts-wrapper .dropdown-options,
  .report-parts-wrapper .dropdown-options .dropdown-menu {
    width: 220px !important;
  }

  .report-parts-wrapper .dropdown-options .dropdown-menu {
    margin: 0 !important;
  }

  .report-parts-wrapper .multiselect__tags {
    height: 39px;
    width: 220px;
    border-radius: 3px;
    border-color: #79B1BB;
    font-size: 13px;
    font-weight: bold;
  }

  .report-parts-wrapper .multiselect__placeholder {
    padding-top: 3px;
    color: #1C1F39;
    font-size: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 18px !important;
  }

  .report-parts-wrapper .multiselect-suppliers-options .multiselect__content-wrapper {
    width: 250px;
  }

  .report-parts-wrapper .multiselect__option {
    display: flex;
    align-items: center;
  }

  .report-parts-wrapper .multiselect__single,
  .report-parts-wrapper .multiselect__input {
    color: #1C1F39;
    font-size: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 18px !important;
    padding-top: 3px;
  }

  .report-parts-wrapper .multiselect__single {
    width: 97%;
  }

  .report-parts-wrapper .multiselect__option--highlight:after {
    color: #1C1F39;
    font-size: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 18px !important;
    padding-top: 10px;
  }

  .report-parts-wrapper .multiselect__select {
    border-left: 1px solid #79B1BB;
    height: 39px;
    top: 0px !important;
    width: 45px !important;
    padding: 8px 12px;
    transition-property: none;
  }

  .report-parts-wrapper .multiselect--active .multiselect__select{
    border-right: 1px solid #79B1BB;
    border-left: none;
    transition-property: none;
    padding: 8px 15px;
  }

  .report-parts-wrapper .multiselect--active .multiselect__input {
    width: 97% !important;
  }

  .report-parts-wrapper .multiselect__select:before {
    content: "" !important; /* Очистка содержимого псевдоэлемента */
    background-image: url('../../../../public/img/avatars/down-arrow-regular-24.png');
    height: 16px;
    display: block;
    width: 16px;
    background-size: contain;
  }
</style>
