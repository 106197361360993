<template>

</template>

<script>
import _ from "lodash";
import accounting from "accounting";
import Axios from "axios";

export default {
  name: "tooltipOptions",
  methods: {
    isShowSimpleTooltip (offer, supplier) {
      return _.isEmpty(this.purchasePriceSelector?.display)
      return _.isEmpty(this.purchasePriceSelector?.display) && !_.isNumber(offer?.rebate) && !_.isString(offer?.rebate) && !_.isNumber(supplier?.rebate) && !_.isString(supplier?.rebate)
    },
    isShowChargePrice (supplier) {
      let b = false
      if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty('all')) {
        b = true
      } else {
        let field = ''
        if (supplier.partsType == 'OEM-GEN') {
          field = 'oemGen'
        } else if (supplier.partsType == 'OEM-PAR') {
          field = 'oemPar'
        } else if (supplier.partsType == 'OEM-ALT') {
          field = 'oemAlt'
        } else {
          field = _.toLower(supplier.partsType)
        }
        if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty(field)) {
          b = true
        }
      }
      return b
    },
    getCostPricePercentageForTooltip (offer, part, supplier) {
      if (_.isEmpty(offer.purchasePriceFigures)) {
        return null
      }
      let profitValue = Number(offer.purchasePriceFigures.profitValue)
      if (offer.purchasePriceFigures?.sellPricePercent) {
        profitValue = (Number(offer.purchasePriceFigures?.sellPricePercent) * Number(this.getListPrice(offer, part, supplier)) / 100) - Number(offer.purchasePriceFigures?.costPrice)
      }
      let costPricePercentage = accounting.toFixed((100 * profitValue) / Number(offer.purchasePriceFigures?.costPrice), 2)
      if (costPricePercentage < 0) {
        costPricePercentage = accounting.toFixed(Math.abs(costPricePercentage), 2)
      }
      if (costPricePercentage?.includes('Infinity')) {
        return 0
      }
      return costPricePercentage
    },
    isPriceGrayBlinking (part, supplier) {
      if (this.isShowMUP(supplier, part)) {
        return false
      }
      let offer = supplier.getOffer(part)
      return !!(offer?.purchasePriceFigures?.sellPrice !== null && Number(this.getCostPricePercentageForTooltip(offer, part, supplier)) < Number(this.purchasePriceSelector?.listPricePercents?.markUp)
              && Number(offer?.purchasePriceFigures?.markUpAndPrice) > 0)
          && this.supplierPrice.isAllowscsChk && part.stock[supplier.id] !== 3
    },
    getPercentForTooltip (offer, part, supplier) {
      let percent = null
      let listPrice = this.getListPrice(offer, part, supplier)
      if (listPrice > 0) {
        percent = accounting.toFixed((100 * offer.purchasePriceFigures.sellPrice) / listPrice, 0)
      }
      if (offer.purchasePriceFigures?.sellPricePercent) {
        percent = accounting.toFixed(offer.purchasePriceFigures?.sellPricePercent, 0)
      }
      return percent
    },
    getSupplierColor(supplier){
      switch (supplier.type){
        case 'OEM-GEN':
          return '#083d77'
        case 'OEM-PAR':
          return '#ee964b'
        case 'OEM-ALT':
          return '#f4d35e'
        case 'CERT-AFTM':
          return '#fa7a61'
        case 'AFTM':
          return '#6b8f71'
        case 'USED':
          return '#b287a3'
        case 'RECO':
          return '#57b8ff'

        default:
          return '#a3a3a3'
      }
    },
    onShowTooltip (offer, supplier) {
      this.$root.$emit('bv::hide::tooltip')
      if (this.isPercentHasChanged(offer, supplier)) {
        this.editPartsTooltip(offer, supplier)
      }
    },
    getPartTooltip (prt, supplier, stockValue) {
      if (!this.isShowSimpleTooltip(prt, supplier)) {
        //    return this.getCalculateInformation(prt, supplier)
      }
      let r = ''
      if (stockValue === 0) {
        r += 'Undefined'
      } else if (stockValue === 1) {
        r += 'In Stock'
      } else if (stockValue === 2) {
        r += 'Low Stock'
      } else if (stockValue === 3) {
        r += 'No Stock'
      } else if (stockValue === 4) {
        r += 'On Back Order'
      } else if (stockValue === 5) {
        r += 'No Longer Avail'
      } else if (stockValue === 6) {
        r += 'Ex Warehouse'
      } else if (stockValue === 7) {
        r += 'Nil Supply'
      }

      let stockComment = _.trim(prt?.stockComment)
      let condition = _.trim(supplier?.getPart(prt?.id)?.condition)
      let comment = ''

      if (stockComment) {
        comment += stockComment
      }
      if (condition) {
        if (stockComment) {
          comment += '<br/>'
        }
        comment += `Condition: ${condition}`
      }

      if (!_.isEmpty(comment)) {
        r += '<br/> ' + comment
      }

      if ((stockValue !== 7 && stockValue !== 3) && this.getPartNumber(prt.originalPartId)) {
        if (!supplier.isSupplierTypeOEMGEN) {
          // if (!supplier.isSupplierTypeOEMGEN && prt.partNumber !== this.getPartNumber(prt.originalPartId) && !_.isEmpty(this.getPartNumber(prt.originalPartId)) && !_.isEmpty(this.getPartNumber(prt.partNumber))) {
          //   r += `<br/> <span>${prt.partNumber}</span>`
          // }
          if (!_.isEmpty(prt.partNumber)) {
            r += `<br/> <span>Part Nbr: ${prt.partNumber}</span>`
          }
          if (!_.isEmpty(prt.oePartNumber)) {
            r += `<br/> <span>OE Ref Nbr: <b>${prt.oePartNumber}</b></span>`
          }
        } else {
          r += `<br/> <strong>${this.getPartNumber(prt.originalPartId)}</strong>`
        }
      }

      if (stockValue === 1 || stockValue === 2 || stockValue === 4 || stockValue === 6) {
        let isHasLinked = false
        let priceWithLinked = parseFloat(prt.price)
        let rrpWithLinked = parseFloat(prt.rrp)
        _.forIn(supplier.parts, (p, pId) => {
          if (Number(pId) === 0) {
            _.forIn(p, (mP) => {
              if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' && Number(mP.linkId) === Number(prt.id)) {
                isHasLinked = true
                if (mP.price > 0) {
                  priceWithLinked += parseFloat(mP.price)
                }
                if (mP.rrp > 0) {
                  rrpWithLinked += parseFloat(mP.rrp)
                }
              }
            })
          } else if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(prt.id)) {
            isHasLinked = true
            if (p.price > 0) {
              priceWithLinked += parseFloat(p.price)
            }
            if (p.rrp > 0) {
              rrpWithLinked += parseFloat(p.rrp)
            }
          }
        })

        if (isHasLinked) {
          r += `<br/>Combined Price: $${priceWithLinked.toFixed(2)}`
          if (rrpWithLinked && priceWithLinked) {
            let profit = parseFloat(((Number.parseFloat(rrpWithLinked).toFixed(2) - Number.parseFloat(priceWithLinked).toFixed(2)) * 100) / priceWithLinked).toFixed(2)
            if (profit > 0) {
              r += `<br/>Markup: ${profit}% <br>Profit: $${(Number.parseFloat(rrpWithLinked).toFixed(2) - Number.parseFloat(priceWithLinked).toFixed(2)).toFixed(2)}`
            }
          }
        } else {
          let part = _.find(this.allParts, (p) => {
            return Number(p.id) === Number(prt.originalPartId)
          })
          let rrp = part ? part.rrp : prt.rrp
          if (rrp && prt.price) {
            let profit = parseFloat(((Number.parseFloat(rrp).toFixed(2) - Number.parseFloat(prt.price).toFixed(2)) * 100) / prt.price).toFixed(2)
            if (profit > 0) {
              r += `<br/>Markup: ${profit}% <br>Profit: $${(Number.parseFloat(rrp).toFixed(2) - Number.parseFloat(prt.price).toFixed(2)).toFixed(2)}`
            }
          }
        }
      }
      return r
    },
    stopEditPartsTooltip (offer, supplier) {
      let sid = supplier.id

      let ind = _.findIndex(this.supplierPrice.orderedSuppliers, (s) => {
        return Number(s.id) === Number(sid)
      })
      if (ind !== -1) {
        let ind2 = _.findIndex(this.supplierPrice.orderedSuppliers[ind].offers, (o) => {
          return Number(o.id) === Number(offer.id)
        })
        if (ind2 !== -1) {
          this.$set(this.supplierPrice.orderedSuppliers[ind].offers[ind2].purchasePriceFigures, 'editMode', false)
        }
      }
    },
    editPartsTooltip (offer, supplier) {
      let sid = supplier.id

      let ind = _.findIndex(this.supplierPrice.orderedSuppliers, (s) => {
        return Number(s.id) === Number(sid)
      })
      if (ind !== -1) {
        let ind2 = _.findIndex(this.supplierPrice.orderedSuppliers[ind].offers, (o) => {
          return Number(o.id) === Number(offer.id)
        })
        if (ind2 !== -1) {
          this.$set(this.supplierPrice.orderedSuppliers[ind].offers[ind2].purchasePriceFigures, 'editMode', true)

          let percent = null
          if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty('all')) {
            percent = this.purchasePriceSelector.listPricePercents.all
          } else {
            let field = ''
            if (supplier.partsType == 'OEM-GEN') {
              field = 'oemGen'
            } else if (supplier.partsType == 'OEM-PAR') {
              field = 'oemPar'
            } else if (supplier.partsType == 'OEM-ALT') {
              field = 'oemAlt'
            } else {
              field = _.toLower(supplier.partsType)
            }
            if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty(field)) {
              percent = this.purchasePriceSelector.listPricePercents[field]
            }
          }
          if (offer.purchasePriceFigures?.sellPricePercent) {
            percent = accounting.toFixed(offer.purchasePriceFigures?.sellPricePercent, 0)
          }

          if (!offer.purchasePriceFigures.markUpAndPrice) {
            this.$set(offer.purchasePriceFigures, 'markUpAndPrice', offer.price + (offer.price * (Number(this.purchasePriceSelector.listPricePercents.markUp) / 100)))
            // offer.purchasePriceFigures.markUpAndPrice = offer.price + (offer.price * (Number(this.purchasePriceSelector.listPricePercents.markUp) / 100))
          }

          this.$set(offer.purchasePriceFigures, 'sellPricePercent', percent)
          // offer.purchasePriceFigures.sellPricePercent = percent
          this.newPercentForTooltip = percent
          setTimeout(() => {
            let el = this.$refs['percent-input-' + offer.id]
            if (el?.[0]) {
              el[0].focus()
            }
          }, 100)
        }
      }
    },
    getChargePriceForTooltip (offer, part, supplier) {
      let price = offer.purchasePriceFigures.sellPrice
      let listPrice = this.getListPrice(offer, part, supplier)
      if (offer.purchasePriceFigures?.sellPricePercent) {
        price = Number(offer.purchasePriceFigures?.sellPricePercent) * Number(listPrice) / 100
      }
      return this.formatValue(price)
    },
    getListPrice(offer, part, supplier) {
      let listPrice = offer.purchasePriceFigures.listPrice;
      if (supplier.partsType !== 'OEM-GEN' && !_.isEmpty(this.OEMSupplier) && this.isOemGenPartHasLinkedParts(part, supplier)) {
        listPrice = this.getCombinedListPrice(this.OEMSupplier, this.OEMSupplier.getOffer(part), supplier.getOffer(part).purchasePriceFigures.listPrice)
      }
      return listPrice
    },
    getMarkUpPercentForTooltip (offer) {
      if (_.isEmpty(offer.purchasePriceFigures.markUpAndPrice)) {
        return this.purchasePriceSelector.listPricePercents.markUp
      }
      let diffence = Number(offer.purchasePriceFigures.markUpAndPrice) - Number(offer.purchasePriceFigures?.costPrice)
      return Math.round(accounting.toFixed(diffence * 100 / Number(offer.purchasePriceFigures?.costPrice), 2))
    },
    onChangedOnCheckbox (type, offer, supplier, part) {
      if (type == 1) {
        if (offer.purchasePriceFigures.sellPriceCheck) {
          offer.purchasePriceFigures.markUpAndPriceCheck = false
        }
      } else {
        if (offer.purchasePriceFigures.markUpAndPriceCheck) {
          offer.purchasePriceFigures.sellPriceCheck = false
        }
      }
      this.updateTooltipPercent(offer, supplier, part)
    },
    onChangedSellPriceCheckBox (supplier, part) {
      setTimeout(() => {
        let isSelected = !!_.find(this.selectedParts, (p) => {
          return Number(p.partId) == Number(part.id) && Number(p.suppId) == Number(supplier.id)
        })
        if (supplier.getOffer(part).purchasePriceFigures.sellPriceCheck) {
          if (!isSelected) {
            this.priceClicked(supplier, part)
          }
        } else {
          if (isSelected) {
            this.priceClicked(supplier, part)
          }
        }
      }, 10)
    },
    onChangedMarkUpCheckBox (supplier, part) {
      setTimeout(() => {
        let isSelected = !!_.find(this.selectedParts, (p) => {
          return Number(p.partId) == Number(part.id) && Number(p.suppId) == Number(supplier.id)
        })
        if (supplier.getOffer(part).purchasePriceFigures.markUpAndPriceCheck) {
          if (!isSelected) {
            this.priceClicked(supplier, part)
          }
        } else {
          if (isSelected) {
            this.priceClicked(supplier, part)
          }
        }
      }, 10)
    },
    isPercentHasChanged(offer, supplier) {
      let percent = null
      if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty('all')) {
        percent = this.purchasePriceSelector.listPricePercents.all
      } else {
        let field = ''
        if (supplier.partsType == 'OEM-GEN') {
          field = 'oemGen'
        } else if (supplier.partsType == 'OEM-PAR') {
          field = 'oemPar'
        } else if (supplier.partsType == 'OEM-ALT') {
          field = 'oemAlt'
        } else {
          field = _.toLower(supplier.partsType)
        }
        if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty(field)) {
          percent = this.purchasePriceSelector.listPricePercents[field]
        }
      }
      console.log(offer.purchasePriceFigures.sellPricePercent, percent)
      return Number(offer.purchasePriceFigures.sellPricePercent) !== Number(percent)
    },
    updateTooltipPercent (offer, supplier, part) {
      if (_.isEmpty(this.newPercentForTooltip) || Number(this.newPercentForTooltip) == 0) {
        let percent = null
        if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty('all')) {
          percent = this.purchasePriceSelector.listPricePercents.all
        } else {
          let field = ''
          if (supplier.partsType == 'OEM-GEN') {
            field = 'oemGen'
          } else if (supplier.partsType == 'OEM-PAR') {
            field = 'oemPar'
          } else if (supplier.partsType == 'OEM-ALT') {
            field = 'oemAlt'
          } else {
            field = _.toLower(supplier.partsType)
          }
          if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty(field)) {
            percent = this.purchasePriceSelector.listPricePercents[field]
          }
          this.newPercentForTooltip = percent;
          offer.purchasePriceFigures.sellPricePercent = this.newPercentForTooltip
        }
      } else if (this.newPercentForTooltip != null) {
        this.newPercentForTooltip = accounting.toFixed(this.newPercentForTooltip, 0)
        if (Number(this.newPercentForTooltip) > 100) {
          this.newPercentForTooltip = 100
        }
        offer.purchasePriceFigures.sellPricePercent = this.newPercentForTooltip
      }

      let payload = {
        sellPrice: accounting.toFixed(this.getChargePriceForTooltip(offer, part, supplier), 2),
        sellPricePercent: offer.purchasePriceFigures?.sellPricePercent,
        profitValue: accounting.toFixed((Number(offer.purchasePriceFigures.sellPricePercent) * Number(this.getListPrice(offer, part, supplier)) / 100) - Number(offer.purchasePriceFigures?.costPrice), 2),
        offerId: offer.id,
        sellPriceCheck: offer.purchasePriceFigures.sellPriceCheck,
        markUpAndPriceCheck: offer.purchasePriceFigures.markUpAndPriceCheck,
      }

      if (Number(this.getCostPricePercentageForTooltip(offer, part, supplier)) < Number(this.purchasePriceSelector.listPricePercents.markUp)) {
        payload.markUpAndPrice = accounting.toFixed(offer.purchasePriceFigures.markUpAndPrice)
      }

      console.log(payload, 'payload')
      NProgress.start()
      Axios.post('/ir/repairer/rfq/' + this.rfqId, { purchasePriceFigures: payload })
          .then(response => {
            if (response.data._status) {

            }
          })
          .finally(() => {
            NProgress.done()
          })

    },
    closeAllTooltipsByClick (e) {
      let b = false
      let tooltips = document.getElementsByClassName('tooltip-inner')
      _.forEach(tooltips, (t) => {
        if (t.contains(e.target)) {
          b = true
        }
      })
      if (!b) {
        this.$root.$emit('bv::hide::tooltip')
      }
    },
    getStockLabel (stock) {
      if (stock == 7) {
        return 'Nill Supply'
      } else if (stock == 0) {
        return 'Undefined'
      }
      return _.find(this.stockOptions, (s) => {
        return s?.value === stock
      })?.label
    },
    // isShowSimpleTooltip (offer, supplier) {
    //   return _.isEmpty(this.purchasePriceSelector?.display)
    //   return _.isEmpty(this.purchasePriceSelector?.display) && !_.isNumber(offer?.rebate) && !_.isString(offer?.rebate) && !_.isNumber(supplier?.rebate) && !_.isString(supplier?.rebate)
    // },
    getTooltipClass (offer, supplier, index) {
      if (this.isShowSimpleTooltip(offer, supplier)) {
        return 'ps-tooltip custom-tooltip-position'
      }

      let icon = this.$refs['icon-' + supplier.id + '-' + offer.id]
      if (icon?.[0] && window.innerWidth - icon?.[0]?.getBoundingClientRect()?.right < 350) {
        if (this.supplierPrice.orderedSuppliers && this.supplierPrice.orderedSuppliers.length > 1 && index + 1 !== this.supplierPrice.orderedSuppliers.length) {
          return 'custom-tooltip-calculate-information ps-tooltip custom-tooltip-position revert-custom-tooltip-position-2'
        }
        return 'custom-tooltip-calculate-information ps-tooltip custom-tooltip-position revert-custom-tooltip-position'
      }
      if (offer && offer.nonReturnable) {
        return 'nr-custom-tooltip-calculate-information custom-tooltip-calculate-information ps-tooltip custom-tooltip-position'
      }
      return 'custom-tooltip-calculate-information ps-tooltip custom-tooltip-position'
    },
    getTooltipPlacement (offer, supplier) {
      if (this.isShowSimpleTooltip(offer, supplier)) {
        return 'leftbottom'
      }
      let icon = this.$refs['icon-' + supplier.id + '-' + offer.id]
      if (icon?.[0] && window.innerWidth - icon?.[0]?.getBoundingClientRect()?.right < 350) {
        return 'custom-tooltip-calculate-information ps-tooltip custom-tooltip-position revert-custom-tooltip-position'
      }
      return 'rightbottom'
    },
    getRrp (partId) {
      return this.supplierPrice.getRrp(partId)
    },
    getRebate (prt, supplier) {
      if (!_.isNumber(prt.rebate) && !_.isString(prt.rebate)) {
        return accounting.formatMoney(prt.price * ((100 - parseFloat(supplier.rebate)) / 100))
      } else {
        return accounting.formatMoney(prt.rebate)
      }
    },
    getCombinedCostPriceMarkUp(supplier, prt) {
      let profitValue = Number(this.getCombinedProfitValue(supplier, prt))
      let costPricePercentage = accounting.toFixed((100 * profitValue) / Number(this.getCombinedCostPrice(supplier, prt)), 2)
      if (costPricePercentage < 0) {
        costPricePercentage = accounting.toFixed(Math.abs(costPricePercentage), 2)
      }
      if (costPricePercentage?.includes('Infinity')) {
        return 0
      }
      return costPricePercentage
    },
    getCombinedProfitValue(supplier, prt) {
      let profitValue = null;
      if (prt.purchasePriceFigures?.sellPricePercent) {
        profitValue += parseFloat((Number(prt.purchasePriceFigures?.sellPricePercent) * Number(prt.purchasePriceFigures.listPrice) / 100) - Number(prt.purchasePriceFigures?.costPrice))
      } else {
        profitValue += parseFloat(prt.purchasePriceFigures.profitValue)
      }

      _.forIn(supplier.parts, (p, pId) => {
        if (Number(pId) === 0) {
          _.forIn(p, (mP) => {
            if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' && Number(mP.linkId) === Number(prt.id)) {
              if (supplier.getOffer(mP).purchasePriceFigures?.sellPricePercent) {
                profitValue += parseFloat((Number(supplier.getOffer(mP).purchasePriceFigures?.sellPricePercent) * Number(supplier.getOffer(mP).purchasePriceFigures.listPrice) / 100) - Number(supplier.getOffer(mP).purchasePriceFigures?.costPrice))
              } else {
                profitValue += parseFloat(supplier.getOffer(mP).purchasePriceFigures.profitValue)
              }
            }
          })
        } else if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(prt.id)) {
          if (supplier.getOffer(p).purchasePriceFigures?.sellPricePercent) {
            profitValue += parseFloat((Number(supplier.getOffer(p).purchasePriceFigures?.sellPricePercent) * Number(supplier.getOffer(p).purchasePriceFigures.listPrice) / 100) - Number(supplier.getOffer(p).purchasePriceFigures?.costPrice))
          } else {
            profitValue += parseFloat(supplier.getOffer(p).purchasePriceFigures.profitValue)
          }
        }
      })
      return profitValue
    },
    getCombinedListPrice(supplier, prt, rrp = null) {
      let rrpWithLinked = rrp ? parseFloat(rrp) : parseFloat(prt.rrp);
      _.forIn(supplier.parts, (p, pId) => {
        if (Number(pId) === 0) {
          _.forIn(p, (mP) => {
            if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' && Number(mP.linkId) === Number(prt.id)) {
              if (mP.rrp > 0) {
                rrpWithLinked += parseFloat(mP.rrp)
              }
            }
          })
        } else if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(prt.id)) {
          if (p.rrp > 0) {
            rrpWithLinked += parseFloat(p.rrp)
          }
        }
      })
      return rrpWithLinked
    },
    isOemGenPartHasLinkedParts (part, supplier) {
      if (!_.isEmpty(this.OEMSupplier) && supplier.partsType !== 'OEM-GEN') {
        return this.isPartHasLinkedParts(this.OEMSupplier.getOffer(part).id, this.OEMSupplier)
      }
      return false
    },
    isShowPercents(supplier, part) {
      return supplier.getOffer(part) &&
          supplier.getOffer(part).purchasePriceFigures &&
          supplier.getOffer(part).purchasePriceFigures.sellPriceCheck &&
          (!supplier.isRfqToSupplierStatusDecline && part.id && supplier.isPartSelected(part.id) && this.position > 2) &&
          this.getPercentForTooltip(supplier.getOffer(part), part, supplier)>0
    },
    isShowMUP(supplier, part) {
      return supplier.getOffer(part) && supplier.getOffer(part).purchasePriceFigures
          && supplier.getOffer(part).purchasePriceFigures.sellPrice !== null
          && Number(this.getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) < Number(this.purchasePriceSelector.listPricePercents.markUp)
          && Number(supplier.getOffer(part).purchasePriceFigures.markUpAndPrice) > 0
          && supplier.getOffer(part).purchasePriceFigures.markUpAndPriceCheck
          && (!supplier.isRfqToSupplierStatusDecline && part.id
              && part.stock[supplier.id] !== 3
              && supplier.isPartSelected(part.id) && this.position > 2)
    },
    getMUpProfitValue(supplier, part) {
      let markUp = null
      if (supplier.getOffer(part).purchasePriceFigures.markUpAndPrice) {
        markUp =supplier.getOffer(part).purchasePriceFigures.markUpAndPrice
      } else {
        markUp = supplier.getOffer(part).price + (supplier.getOffer(part).price * (Number(this.purchasePriceSelector.listPricePercents.markUp) / 100))
      }
      let costPrice = supplier.getOffer(part).purchasePriceFigures?.costPrice;
      return markUp - costPrice
    },
    formatValue (value) {
      return accounting.formatMoney(value)
    },
    formatPercent (value, precision = 2) {
      return accounting.toFixed(value, precision)
    },
    isPartHasLinkedParts (prtId, supplier) {
      let isHasLinked = false

      _.forIn(supplier.parts, (p, pId) => {
        if (Number(pId) === 0) {
          _.forIn(p, (mP) => {
            if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' &&  Number(mP.linkId) === Number(prtId)) {
              isHasLinked = true
            }
          })
        } else if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(prtId)) {
          isHasLinked = true
        }
      })
      return isHasLinked
    },
    getStockCommentForTooltip (offer, supplier) {
      let stockComment = _.trim(offer?.stockComment)
      let condition = _.trim(supplier?.getPart(offer?.id)?.condition)
      let comment = ''

      if (stockComment) {
        comment += stockComment
      }
      if (condition) {
        if (stockComment) {
          comment += '<br/>'
        }
        comment += `Condition: ${condition}`
      }
      return comment
    },
    _isEmpty(value) {
      return _.isEmpty(value)
    },
  }
}
</script>

<style scoped>

</style>
