/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    isStoresCategory: 'Automotive',
    isStoresCategory2: null,
    selectedStore: null,
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
