/*eslint-disable */
import {
  SETTINGS_FETCH_ALL_USERS,
  SETTINGS_SET_SELECTED_USER,
  SETTINGS_APPEND_NEW_USER,
  REINSTALLATION_USERS,
  SETTINGS_CLEAR_USERS,
  SETTINGS_UPDATE_VISIBLE_ALL_USERS
} from "../../mutation-types";

export default {
  [REINSTALLATION_USERS](state, ui) {
    state.usersAll = [];
  },
  [SETTINGS_FETCH_ALL_USERS](state, ui) {
    state.usersAll = ui;
  },
  [SETTINGS_SET_SELECTED_USER](state, ui) {
    state.selectedUser = ui
  },
  [SETTINGS_UPDATE_VISIBLE_ALL_USERS] (state, ui) {
    _.forEach(ui, (updatedUser) => {
      let oldUser = _.find(state.usersAll, (user) => {
        return Number(user.id) === Number(updatedUser.id);
      });
      if (oldUser) {
        oldUser.isVisible = updatedUser.isVisible;
      }
    })
  }
  // [SETTINGS_APPEND_NEW_USER](state) {
  //   state.usersAll.push({})
  // },
  // [SETTINGS_CLEAR_USERS](state) {
  //   state.usersAll.pop();
  // }
};
