<template>
  <date-picker
      v-model="computedValue"
      mode="date"
      :masks="masks"
      color="green"
      is-range
      :model-config="modelConfig"
      @input="updateData"
      @popoverDidHide="hidePopover"
      :popover="{ visibility: 'click' }"
  >
    <template v-slot="{ inputValue, inputEvents, isDragging }">
      <div class="row no-gutter d-flex">
        <div class="dateRangeInput" style="padding-left: 10px">
          <input
              ref="startInput"
              :value="inputValue.start"
              v-on="inputEvents.start"
              class="form-control"
              @keydown.enter="$emit('onEnter')"
              @blur="isOpenCalendar = false; isFocused = false;"
              @focus="isFocused = true"
              :disabled="isDisabled"
              :readonly="isReadonly"
              :placeholder="placeHolderText"

          />
        </div>
        <div class="dateRangeArrowIcon">
          <i class="bx bx-right-arrow-alt" style="font-size: 20px; margin-top: 3px; color: black"></i>
        </div>
        <div class="dateRangeInput" style="padding-right: 10px">
          <input
              :value="inputValue.end"
              v-on="inputEvents.end"
              class="form-control"
              @focus="isFocused = true"
              @blur="isFocused = false"
              @keydown.enter="$emit('onEnter')"
              :disabled="isDisabled"
              :readonly="isReadonly"
              :placeholder="placeHolderText"
          />
        </div>
      </div>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'v-calendar/src/components/DatePicker';
import * as dayjs from "dayjs";
export default {
  name: 'date-range-picker',
  props: {
    placeHolderText: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    value: {
      type: Object,
      default() {
        return {
          end: null,
          start: null,
        }
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    hidePopover() {
      if (this.isFocused) {
        return
      }
      if (this.oldValue && ((this.oldValue.startDate && this.oldValue.endDate) || (this.oldValue.start && this.oldValue.end))) {
        if (this.oldValue.start && this.oldValue.end) {
          this.oldValue.start = dayjs(this.oldValue.start).format('DD/MM/YYYY');
          this.oldValue.end = dayjs(this.oldValue.end).format('DD/MM/YYYY');

          if (this.oldValue.start !== this.value.start && this.oldValue.end !== this.value.end) {
            this.$emit('hidePopover')
          }
        } else {
          this.oldValue.startDate = dayjs(this.oldValue.startDate).format('DD/MM/YYYY');
          this.oldValue.endDate = dayjs(this.oldValue.endDate).format('DD/MM/YYYY');

          if (this.oldValue.startDate !== this.value.start && this.oldValue.endDate !== this.value.end) {
            this.$emit('hidePopover')
          }
        }
      }
    },
    updateData(val) {
      this.$emit('input', val)
      this.$emit('onChange');
    },
    focusInput() {
      if (!this.isClicked) {
        this.$refs.startInput.click();
        this.isClicked = true;
      }
      this.$refs.startInput.focus();
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        return val;
      }
    }
  },
  data() {
    return {
      isClicked: false,
      isFocused: false,
      masks: {
        input: 'DD/MM/YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'DD/MM/YYYY'
      },
      oldValue: null,
    }
  },
  watch: {
    value: {
      handler: function (val, old) {
        this.oldValue = old;
      },
      immediate: true
    }
  }
};

</script>

<style scoped>

</style>
