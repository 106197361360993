/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    token: [],
    isPlayNotificationSound: false,
    currentRfq: 0,
    filter: '',
    searchFilterObj: {
        search: '',
    },
    lastTimeCheck: {
        items: 0,
    },
    perPage: 20,
    receivedRFQsCurrentPage: 1,
    submittedRFQsCurrentPage: 1,
    pricedRFQsCurrentPage: 1,
    orderedRFQsCurrentPage: 1,
    cancelledRFQsCurrentPage: 1,
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
