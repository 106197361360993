import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    suppliers: {
        items: [],
        itemsFull: [],
        lastUpdate: 0,
    },
    insurers: [],
    tier1: [],
    prefered: {
        ids: [],
        lastUpdate: 0,
        order: {},
        filter: {},
        pagination: {
            currentPage: 1,
            onPage: 30,
        },
        count: 0,
    },
    specialist: {
        ids: [],
        lastUpdate: 0,
        order: {},
        filter: {},
        pagination: {
            currentPage: 1,
            onPage: 30,
        },
        count: 0,
    },
    others: {
        ids: [],
        lastUpdate: 0,
        order: {},
        filter: {},
        pagination: {
            currentPage: 1,
            onPage: 30,
        },
        count: 0,
    },
    perPage: 100,
    perPage2: 10,
    filter: '',
    suppliersAllCurrentPage: 1,
    suppliersPreferedCurrentPage: 1,
    suppliersTierCurrentPage: 1,

};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
