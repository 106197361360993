<template>
  <div @contextmenu.prevent="$emit('openRowContext', $event)"
       class="parts-order-details pt-2 d-flex"
  >
    <div class="time-line"></div>
    <div style="width:300px">
      <div class="textarea-input-wrapper mb-2">
        <label class="adaptive-input__label font-inter--bold">{{ computedReasonLabel }} : </label>
        <div @contextmenu.prevent="openMenuForStatus($event, part)" style="width: 400px" class="">
          <b-textarea
              :ref="'part-comment-' + part.id"
              v-model="part.comment"
              :disabled="!isEditComment"
              @input="isNeedSaveInStatusContext = true"
              @keydown.enter.prevent="saveReason('status')"
              @blur="onBlurComment('part-comment-' + part.id)"
              @focus="onFocusComment('part-comment-' + part.id)"
              max-rows="10"
              placeholder="Write the reason here"
              class="ps-scroll adaptive-input-textarea"
          >
          </b-textarea>
        </div>
      </div>

      <div v-if="part.commentDate || part.commentByName">
        <div v-if="part.commentDate" class="d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Added on :</p>
          <p class="mb-0" style="padding-left: 15px">{{ formatDateTime(part.commentDate) }}</p>
        </div>

        <div v-if="part.commentByName" class="d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Added by :</p>
          <p class="mb-0" style="padding-left: 12px">{{ part.commentByName }}</p>
        </div>
      </div>

      <div v-if="part.cancelComment" class="" style="margin-bottom: 0px !important; margin-top: 15px; width: 400px">
        <div class="d-flex">
          <p class="input-comment-part font-inter--bold">Supplier reason for Cancellation:</p>
          <p class="pl-3">{{ part.cancelComment }}</p>
        </div>
      </div>

      <div class="parts-order-details__comment-block"></div>

      <div v-if="showRequestCredit" class="parts-order-details__item font-12 d-flex" style="flex-direction: column">
        <div class="textarea-input-wrapper mb-2">
          <label class="adaptive-input__label font-inter--bold">Reason for credit : </label>
          <div @contextmenu.prevent="openMenuForCredit($event, part)" class="">
            <b-textarea
                :ref="'credit-comment-' + part.id"
                v-model="credit.reason"
                :disabled="!isEditCreditRequest"
                @keydown.enter.prevent="saveReason('credit')"
                @blur="onBlurCreditComment('credit-comment-' + part.id)"
                @focus="onFocusCreditComment('credit-comment-' + part.id)"
                @input="isNeedSaveInCreditContext = true"
                max-rows="10"
                placeholder="Write the reason here"
                class="ps-scroll adaptive-input-textarea"
            >
            </b-textarea>
          </div>
        </div>

        <div v-if="credit.subStatus === 'Return' || credit.subStatus === 'Cancellation'" class=""
             style="margin-bottom: 0px !important;">
          <div class="d-flex">
            <p v-if="credit.subStatus === 'Return'" class="input-comment-part font-inter--bold"
               style="margin-bottom: 3px !important; margin-top: 3px !important;">Mark For Return</p>
            <p v-else-if="credit.subStatus === 'Cancellation'" class="input-comment-part font-inter--bold"
               style="margin-bottom: 3px !important; margin-top: 3px !important;">Cancel Return</p>
          </div>
        </div>

        <div v-if="credit.reasonDenied" class="" style="margin-bottom: 0px !important;">
          <div class="d-flex">
            <p class="input-comment-part font-inter--bold">Supplier Reason for Denied:</p>
            <p class="pl-3">{{ credit.reasonDenied }}</p>
          </div>
        </div>
        <div v-if="showRequestCredit" class="textarea-input-wrapper d-flex">
          <p class="input-comment-part font-inter--bold mb-0">Credit ref:</p>
          <p class="pl-3 mb-0">
            <a @click.prevent="goToCredit" v-if="credit.number" style="padding-top: 7px;" :href="'#'">{{
                credit.number
              }}</a>
            <span v-else></span>
          </p>
        </div>
        <template v-if="showRequestCredit && credit.creditDate && credit.creditByName">
          <div class="textarea-input-wrapper d-flex">
            <p class="input-comment-part font-inter--bold mb-0">Added on:</p>
            <p class="pl-3 mb-0">{{ credit.creditDate ? formatDateTime(credit.creditDate) : '' }}</p>
          </div>
          <div class="textarea-input-wrapper d-flex">
            <p class="input-comment-part font-inter--bold mb-0">Added by:</p>
            <p class="pl-3 mb-0">{{ credit.creditByName ? credit.creditByName : '' }}</p>
          </div>
        </template>
      </div>
    </div>
<!--    commented by #656.1-->
<!--    <div class="d-flex" v-if="credit && credit.id && part.qty !== part.qtyRcv && part.qtyRcv - part.qty !== 0">-->
<!--      <b-form-input-->
<!--          :value="getRtnCost()"-->
<!--          :style="{'marginLeft': marginCount + 'px', 'marginRight': minCellWith + 'px'}"-->
<!--          :disabled="true"-->
<!--          style="color: red;"-->
<!--          class="return-credit-input">-->
<!--      </b-form-input>-->
<!--      <b-form-input-->
<!--          :value="part.qtyRcv - part.qty"-->
<!--          :style="{'marginRight': minCellWith + 'px'}"-->
<!--          :disabled="true"-->
<!--          style="color: red;"-->
<!--          class="return-credit-input">-->
<!--      </b-form-input>-->
<!--      <b-form-input-->
<!--          :value="part.price | formatMoney"-->
<!--          :style="{'marginRight': minCellWith + 'px'}"-->
<!--          :disabled="true"-->
<!--          class="return-credit-input">-->
<!--      </b-form-input>-->
<!--    </div>-->
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import accounting from "accounting";

export default {
  props: {
    part: {
      type: Object,
      default() {
        return {
          id: 0,
          subtotal: '',
          total: '',
          comment: '',
          commentDate: '',
          commentByName: '',
          status: '',
          originalPartId: '',
          partNumber: '',
          stock: 0,
          credit: {
            id: 0,
            status: '',
            number: '',
            reason: null,
            reasonDenied: null,
            subStatus: null,
          },
          rrp: '',
          type: '',
          linkId: null,
          name: '',
          qty: '',
          originalQty: '',
          qtyRcv: 0
        }
      }
    },
    marginCount: {
      type: Number,
      default: 0,
    },
    minCellWith: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      editComment: false,
      editCredit: false,
      isDeletedComment: false,
      isDeletedCredit: false,
      isNotSavedChangesInStatusContext: false,
      isNotSavedChangesInCreditContext: false,
      isNeedSaveInCreditContext: false,
      isNeedSaveInStatusContext: false,
      isNotNeedEditInStatusContext: false,
      isNotNeedEditInCreditContext: false,
    }
  },
  computed: {
    computedCreditUrl() {
      return window.location.origin + '/r/credit/view/' + this.credit.id
    },
    isEditComment() {
      if (this.isDeletedComment) {
        return true
      }
      return !!(this.editComment || this.part.comment == '' || this.part.comment == null)
    },
    isEditCreditRequest() {
      if (this.isDeletedCredit) {
        return true
      }
      return !!(this.editCredit || this.part.credit.reason == '' || this.part.credit.reason == null)
    },
    showRequestCredit() {
      return this.partCanRequestCredit && this.credit.id > 0 && this.credit.creditPartStatus !== 'Cancelled'
    },
    partCanRequestCredit() {
      return this.status === 'CancelPart'
          || this.status === 'ReturnDamagedPart'
          || this.status === 'IncorrectPartOrdered'
          || this.status === 'IncorrectPartDelivered'
          || this.status === 'DamagedPartExWarehouse'
          || this.status === 'NoLongerNeeded'
          || this.status === 'ChangeInRepairMethod'
    },
    credit() {
      return this.part.credit
    },
    status() {
      return this.part.status
    },
    computedReasonLabel() {
      if (this.status === 'CancelPart') {
        return 'Comment for - Cancel Part'
      } else if (this.status === 'ReturnDamagedPart') {
        return 'Comment for - Damaged Part in Transport'
      } else if (this.status === 'IncorrectPartDelivered') {
        return 'Comment for - Incorrect Part Delivered'
      } else if (this.status === 'IncorrectPartOrdered') {
        return 'Comment for - Incorrect Part Ordered'
      } else if (this.status === 'DamagedPartExWarehouse') {
        return 'Comment for - Damaged Part Ex Warehouse'
      } else if (this.status === 'NoLongerNeeded') {
        return 'Comment for - No Longer Needed'
      } else if (this.status === 'ChangeInRepairMethod') {
        return 'Comment for - Change in Repair Method'
      }
      return ''
    },
  },
  methods: {
    onBlurCreditComment(ref) {
      this.$emit('blur', ref)
      if (this.isNeedSaveInCreditContext) {
        this.saveReason('credit')
      }
    },
    onFocusCreditComment(ref) {
      this.$emit('focus', ref)
    },
    onBlurComment(ref) {
      this.$emit('blur', ref)
      if (this.isNeedSaveInStatusContext) {
        this.saveReason('status')
      }
    },
    onFocusComment(ref) {
      this.$emit('focus', ref)
    },
    getRtnCost() {
      return '-' + accounting.formatMoney(parseFloat(Math.abs(this.part.price * Math.abs(this.part.qtyRcv - this.part.qty) * 1.1).toFixed(2)))
    },
    focusOnComment() {
      this.$refs['part-comment-' + this.part.id].focus();
    },
    goToCredit() {
      if (!this.credit.id) {
        return
      }
      this.$router.push('/r/credit/view/' + this.credit.id)
    },
    saveReason(contextType) {
      if (contextType === 'status') {
        this.editComment = false
        this.isDeletedComment = false
        this.$emit('autoSave', [{partId: this.part.id, comment: this.part.comment}])
        this.isNotSavedChangesInStatusContext = false
        this.isNotNeedEditInStatusContext = false
        this.isNeedSaveInStatusContext = false
      } else if (contextType === 'credit') {
        this.editCredit = false
        this.isDeletedCredit = false
        this.$emit('autoSave', [{creditId: this.credit.id, partId: this.part.id, credit: this.credit.reason}])
        this.isNotSavedChangesInCreditContext = false
        this.isNotNeedEditInCreditContext = false
        this.isNeedSaveInCreditContext = false
      }
      this.closeContext()
    },
    editReason(contextType) {
      if (contextType === 'status') {
        this.editComment = true
      } else if (contextType === 'credit') {
        this.editCredit = true
      }
      let ref = null
      if (contextType === 'status') {
        ref = 'part-comment-'
      } else if (contextType === 'credit') {
        ref = 'credit-comment-'
      }
      let el = this.$refs[ref + this.part.id]
      if (el) {
        console.log(el, 'el')
        setTimeout(() => {
          el.focus()
          if (contextType === 'status') {
            this.isNotSavedChangesInStatusContext = true
            this.isNotNeedEditInStatusContext = true
          } else if (contextType === 'credit') {
            this.isNotSavedChangesInCreditContext = true
            this.isNotNeedEditInCreditContext = true
          }
        }, 100)
      }
      this.closeContext()
    },
    deleteReason(contextType) {
      if (contextType === 'status') {
        this.editComment = false
        this.isDeletedComment = true
        this.isNotSavedChangesInStatusContext = false
        this.isNotNeedEditInStatusContext = false
        this.isNeedSaveInStatusContext = false
        this.part.comment = null
        this.part.commentByName = null
        this.part.commentDate = null
        this.$emit('autoSave', [{partId: this.part.id, comment: null,}])
      } else if (contextType === 'credit') {
        this.editCredit = false
        this.isDeletedCredit = true
        this.isNotSavedChangesInCreditContext = false
        this.isNotNeedEditInCreditContext = false
        this.isNeedSaveInCreditContext = false
        this.part.credit.reason = null
        this.$emit('autoSave', [{creditId: this.credit.id, partId: this.part.id, credit: null}])
      }

      this.closeContext()
    },
    openMenuForStatus(event, part) {
      this.$emit('openMenuForReasonAction',
          {
            event: event,
            part: part,
            type: 'status',
            isNotSavedChanges: this.isNotSavedChangesInStatusContext,
            isNotNeedEdit: this.isNotNeedEditInStatusContext,
            isNeedSave: this.isNeedSaveInStatusContext,
          })
    },
    openMenuForCredit(event, part) {
      this.$emit('openMenuForReasonAction',
          {
            event: event,
            part: part,
            type: 'credit',
            isNotSavedChanges: this.isNotSavedChangesInCreditContext,
            isNotNeedEdit: this.isNotNeedEditInCreditContext,
            isNeedSave: this.isNeedSaveInCreditContext,
          })
    },
    closeContext() {
      this.$emit('closeContext')
    },
    formatDateTime(val) {
      return dayjs(val).format('DD/MM/YYYY hh:mmA')
    },
  },
  mounted() {
    if (this.part.comment == '' || this.part.comment == null) {
      this.editComment = true
    }
    if (this.part.credit && (this.part.credit.reason == '' || this.part.credit.reason == null)) {
      this.editCredit = true
    }
  },
  watch: {}
}
</script>

<style scoped>
.return-credit-input:first-child {
  /*margin-left: 200px !important;*/
}
.return-credit-input:first-child,
.return-credit-input:last-child
{
  text-align: right;
}

.return-credit-input {
  width: 80px;
  /*margin-right: 78px;*/
}
</style>
