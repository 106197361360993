import SupplierRFQView from "../views/suppliers/rfq/rfq-view";
import SuppliersPartsOrders from "../views/suppliers/parts-orders/parts-orders";
import SuppliersCreditManagement from "../views/suppliers/credit/credit-management";
import SupplierCustomers from "../views/suppliers/customer/customers";
import SupplierReports from "../views/suppliers/reports/reports";
import SupplierCustomerView from "../views/suppliers/customer/customer-view";
import SupplierDashboard from "../views/suppliers/dashboard/dashboard";
import SupplierPartsOrderView from '../views/suppliers/parts-orders/parts-order-view.vue';
import SupplierRFQs from  '../views/suppliers/rfq/rfqs.vue'
import RefView from '../views/suppliers/ref/ref-view'
import SuppliersCreditView from '@/views/suppliers/credit/credit-view'
export default [
    {
        path: "/s",
        name: "SupplierDashboard",
        component: SupplierDashboard
    },
    {
        path: '/s/rfqs',
        name: 'SupplierRFQs',
        component: SupplierRFQs,
        children: [
            {
                path: 'all',
                name: 'SupplierRFQsAll',
                component: SupplierRFQs,
                props: true,
                meta: { currentTab: 'All' }
            },
            {
                path: 'new',
                name: 'SupplierRFQsNew',
                component: SupplierRFQs,
                props: true,
                meta: { currentTab: 'New' }
            },
            {
                path: 'priced',
                name: 'SupplierRFQsPriced',
                component: SupplierRFQs,
                props: true,
                meta: { currentTab: 'Priced' }
            },
            {
                path: 'lost',
                name: 'SupplierRFQsLost',
                component: SupplierRFQs,
                props: true,
                meta: { currentTab: 'Lost' }
            },
            {
                path: 'won',
                name: 'SupplierRFQsWon',
                component: SupplierRFQs,
                props: true,
                meta: { currentTab: 'Won' }
            },
            {
                path: 'closed',
                name: 'SupplierRFQsClosed',
                component: SupplierRFQs,
                props: true,
                meta: { currentTab: 'Closed' }
            },
        ]
    },
    {
        path: '/s/rfq/:rfq_id',
        name: 'SupplierRFQView',
        component: SupplierRFQView,
    },
    {
        path: '/s/ref/:rfqId',
        name: 'RefView',
        component: RefView,
    },
    {
        path: '/s/orders',
        name: 'SuppliersPartsOrders',
        component: SuppliersPartsOrders,
    },
    {
        path: '/s/order/:orderId',
        name: 'SupplierPartsOrderView',
        component: SupplierPartsOrderView,
        props: true
    },
    {
        path: '/s/credits',
        name: 'SuppliersCreditManagement',
        component: SuppliersCreditManagement,
    },
    {
        path: '/s/credit/:creditId',
        name: 'SuppliersCreditView',
        component: SuppliersCreditView
    },
    {
        path: '/s/customers',
        name: 'SupplierCustomers',
        component: SupplierCustomers
    },
    {
        path: '/s/reports',
        name: 'SupplierReports',
        component: SupplierReports
    },
    {
        path: '/s/customer/view/:customer_id',
        name: 'SupplierCustomerView',
        component: SupplierCustomerView
    }
];
