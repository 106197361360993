<template>
  <div class="suppliers-customers-page">
    <div class="page-header">
      <h4>Customers</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'SupplierDashboard'}">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'SupplierCustomers'}">View Customers</router-link>
        </li>
      </ol>
    </div>
    <div class="suppliers-customers-body">
      <tabs ref="suppliers-customer-view-table" cache-lifetime="0" :options="{useUrlFragment:false}">
        <tab name="Active" id="suppliers-customers-active" :class-name="'tab-content-table'">
          <tab-paginated-table
              :fields="customerFields"
              :items="activeCustomers"
              :filter="filter"
              @changedPerPage="value => $store.commit('supplier/customers/supplierCustomersPerPage', value)"
              @changedCurrentPage="value => $store.commit('supplier/customers/supplierCustomersAllCurrentPage', value)"
              :perPage="gettersPerPage"
              :currentPage="getterCustomersAllCurrentPage"
              :role="'Customers'"
              @row-clicked="onActiveRowClicked">
          </tab-paginated-table>
        </tab>
        <tab name="Cancelled" id="suppliers-customers-cancelled" :class-name="'tab-content-table'">
          <tab-paginated-table
              :fields="customerFields"
              :items="cancelledCustomers"
              :filter="filter"
              @changedPerPage="value => $store.commit('supplier/customers/supplierCustomersPerPage', value)"
              @changedCurrentPage="value => $store.commit('supplier/customers/supplierCustomersPreferedCurrentPage', value)"
              :perPage="gettersPerPage"
              :currentPage="getterCustomersPreferedCurrentPage"
              :role="'Customers'"
              @row-clicked="onActiveRowClicked">
          </tab-paginated-table>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input
                v-model="filter"
                type="text"
                class="search-input ml-3"
                placeholder="Search or Filter results"
                debounce="500">
            </b-form-input>
          </div>
          <div class="page-header-nav-options">
            <b-button
                class="btn-submit ml-1 text-center w-100 d-block d-lg-none btn-submit--invite"
                variant="primary">
              Invite Customer
            </b-button>
          </div>
        </template>
      </tabs>
    </div>

  </div>
</template>

<script>
import tabPaginatedTable from '../../../components/tabPaginatedTable';
import {mapGetters} from "vuex";

export default {
  name: "customers",
  components: {
    tabPaginatedTable
  },
  data() {
    return {
      filter: '',
      customerFields: [
        {
          label: "Account Nbr",
          key: "number",
          sortable: true,
          tdClass: 'font-12 clickable rfq-number',
          thClass: 'font-13',
          class: 'font-inter font-inter--bold'
        },
        {
          label: "Customer Name",
          key: "name",
          sortable: true,
          tdClass: 'font-12 clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "Type",
          key: "type",
          sortable: true,
          tdClass: 'font-12 clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter',
          formatter: (value => {
            return this.getBusinessType(value)
          })
        },
        {
          label: "Customer Location",
          key: "customer_location",
          sortable: true,
          tdClass: 'font-12 clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "Phone",
          key: "phone",
          sortable: true,
          tdClass: 'font-12 clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
          tdClass: 'font-12 clickable ',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      getActiveCustomers: 'supplier/customers/getActiveCustomers',
      getCancelledCustomers: 'supplier/customers/getCancelledCustomers',
      getAllCustomers: 'supplier/customers/getCustomers',
      gettersPerPage: 'supplier/customers/getPerPage',
      gettersFilter: 'supplier/customers/getFilter',
      getterCustomersAllCurrentPage: 'supplier/customers/getCustomersAllCurrentPage',
      getterCustomersPreferedCurrentPage: 'supplier/customers/getCustomersPreferedCurrentPage',
    }),
    activeCustomers() {
      return _.map(this.getActiveCustomers, item => {
        item.customer_location = '';
        if (item.postcode) item.customer_location += item.postcode;
        if (item.customer_location) item.customer_location += ', ';
        if (item.suburb) item.customer_location += item.suburb;
        if (item.customer_location) item.customer_location += ', ';
        if (item.state) item.customer_location += item.state;
        if (_.isEmpty(item.type)) item.type = "Repair";
        return item;
      });
    },
    cancelledCustomers() {
      return _.map(this.getCancelledCustomers, item => {
        item.customer_location = '';
        if (item.postcode) item.customer_location += item.postcode;
        if (item.customer_location) item.customer_location += ', ';
        if (item.suburb) item.customer_location += item.suburb;
        if (item.customer_location) item.customer_location += ', ';
        if (item.state) item.customer_location += item.state;
        if (_.isEmpty(item.type)) item.type = "Repair";
        return item;
      });
    },
  },
  mounted() {
    if (!this.filter) {
      this.filter = this.gettersFilter;
    }
  },
  watch: {
    filter(val) {
      this.$store.commit('supplier/customers/supplierCustomersFilter', val)
    }
  },
  methods: {
    getBusinessType (value) {
      if (value === 'Car Repairer') {
        return 'Auto Body Repairer'
      } else if (value === 'Bike Repairer') {
        return 'Motorbike Repairer'
      } else if (value === 'Truck Repairer') {
        return 'Truck Repairer'
      }
      return value
    },
    onActiveRowClicked(row) {
      this.$router.push({name: 'SupplierCustomerView', params: {'customer_id': row.id}});
    },
  }
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .suppliers-customers-body .page-header-nav-options {
    position: absolute;
    right: 15px;
    top: 147px;
  }
}
</style>
