<template>
    <div :class="position">
        <template>
            <div class="image-lightbox" >
                <div class="row no-gutter">
                    <template v-for="(img, index) in reversedImageList">
                        <div :class="imageClass" class="img-box-container" :key="img.id">
                            <div v-if="img" class="box img-box">
                                <div v-if="isRemove" class="control-elem-1 justify-content-center align-item-center"
                                     :class="isMobile ? 'd-none' : 'd-lg-flex d-none'">
                                    <a
                                            href="#"
                                            class="control-elem-1__item control-elem-1__item--bordered eye"
                                            @click.prevent="$emit('selected',index)"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Enlarge Image', placement:'leftbottom'}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12"
                                             viewBox="0 0 20 12"><title></title>
                                            <g fill="none">
                                                <g fill="#1B1E38">
                                                    <path d="M18 6C18 4.2 14.2 2 10 2 5.8 2 2 4.2 2 6 2 7.8 5.8 10 10 10 14.3 10 18 7.8 18 6ZM10 12C5 12 0 9.3 0 6 0 2.7 5 0 10 0 15 0 20 2.7 20 6 20 9.3 15 12 10 12ZM10 10C7.8 10 6 8.2 6 6 6 3.8 7.8 2 10 2 12.2 2 14 3.8 14 6 14 8.2 12.2 10 10 10ZM10 8C11.1 8 12 7.1 12 6 12 4.9 11.1 4 10 4 8.9 4 8 4.9 8 6 8 7.1 8.9 8 10 8Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                    <a
                                            href="#"
                                            class="control-elem-1__item"
                                            @click.prevent="onClickRemove(index, img)"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove this image', placement:'leftbottom'}">
                                        <i class="bx bx-trash"></i>
                                    </a>
                                </div>
                                <a class="hand-pointer img-box__a" v-if="isPreviewImage"
                                   @click="$emit('selected',index)">
                                    <img :src="makeImagePreviewUrl(img)" class="wrap img-fluid w-100">
                                </a>
                                <a class="hand-pointer img-box__a" v-else @click="$emit('selected',index)">
                                    <img :src="makeImageUrl(img)" class="wrap img-fluid w-100">
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>


<script>
    import imageLightbox from "../../../../components/image-lightbox/image-lightbox";

    export default {
        name: "SupplierImageLightbox",
        extends: imageLightbox
    }
</script>

