import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
    lastTimestamp: 0,
};

export default {
    namespaced: true,
    mutations: Mutations,
    actions: Actions,
    getters: Getters,
    state,
};
