<script>
import SearchFilter from "@/components/utility/search-filter/search-filter.vue";
import notificationOnTable from "@/components/notification-on-table.vue";
import _ from "lodash";
import moment from "moment";
import Axios from "axios";
import {mapGetters} from "vuex";
import * as dayjs from "dayjs";
import PdfViewer from '../../../components/utility/pdf-viewer'
import Notifications from '../../../components/utility/notifications/notifications'

export default {
  name: "RefView",
  components: {notificationOnTable, SearchFilter, PdfViewer, Notifications},
  data() {
    return{
      jobData: {
        "jobId": null,
        "rfqIds": [],
        "orderIds": [],
        "activityList": [],
        "creditIds": [],
        "notices": [],
        "rfqFiles": [],
        "orderFiles": []
      },
      searchFilter1: '',
      searchFilter2: '',
      searchFilter3: '',
      filter1: '',
      filter2: '',
      filter3: '',
      rfqFields: [
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Make", key: "make", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Model", key: "model", sortable: true, tdClass: 'clickable', thClass: ''},
        // {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: 'notification-column'},
        // {label: "Nbr Parts", key: "nbrParts", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      orderFields: [
        { label: 'Repairer Order Nbr', key: 'number', sortable: true, tdClass: 'clickable number', thClass: '' },
        {label: "Invoice Nbr", key: "invoiceNumber", sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        { label: 'Parts Delivery Date', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: '' },
        // {label: "Delivery Type", key: "deliveryType", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      creditsFields: [
        { label: 'Credit Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: '' },
        { label: 'Credit Nbr', key: 'manualNumber', sortable: true, tdClass: 'clickable', thClass: '' },
        { label: 'Repairer', key: 'repairerName', sortable: true, tdClass: 'clickable', thClass: '' },
        {label: "Order Nbr", key: "orderNumber", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Date Created", key: "dateCreated", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      searchFilterObj1: {},
      searchFilterObj2: {},
      isShowSearch1: false,
      isShowPdf: false,
      documentHeight: 0,
      selectedUrl: '',
      documentWidth: 0,
      isShowSearch2: false,
    }
  },
  watch: {
    filesUpdater: {
      handler(v) {
        v = _.cloneDeep(v)
        if (v.type === 'fileRemovedFromRfq') {
          let index = _.findIndex(this.jobData.rfqFiles, (f) => {
            return Number(f.id) === Number(v.fileId)
          })
          if (index !== -1) {
            this.jobData.rfqFiles.splice(index, 1)
          }
        } else if (v.type === 'fileRemovedFromOrder') {
          let index = _.findIndex(this.jobData.orderFiles, (f) => {
            return Number(f.id) === Number(v.fileId)
          })
          if (index !== -1) {
            this.jobData.orderFiles.splice(index, 1)
          }
        } else if (v.type === 'fileAddedToRfq') {
          let index = _.findIndex(this.jobData.rfqFiles, (f) => {
            return Number(f.id) === Number(v.file.id)
          })
          if (index === -1) {
            v.file.url += '?at=' + localStorage.getItem('token')
            this.jobData.rfqFiles.push(v.file)
          }
        } else if (v.type === 'fileAddedToOrder') {
          let index = _.findIndex(this.jobData.orderFiles, (f) => {
            return Number(f.id) === Number(v.file.id)
          })
          if (index === -1) {
            v.file.url += '?at=' + localStorage.getItem('token')
            this.jobData.orderFiles.push(v.file)
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    redirectToRfq2(itm) {
      if (itm?.entity?.type == 'order' && itm?.entity?.id) {
        this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.entity.id}})
      }
    },
    getRefNumber() {
      if (this.$route?.params?.number) {
        return this.$route.params.number
      }
      if (this.currentRfq?.repairerQuoteNumber) {
        return this.currentRfq?.repairerQuoteNumber
      }
      return ''
    },
    filterOrdersForComputed (orders) {
      if (this.isPartNumberFilterActive2) {
        let formattedFilter = this.computedPartNumberFilter2
        orders = _.filter(orders, order => {
          let bool = false
          _.forEach(formattedFilter, (nbr) => {
            if (order.partsNumbers && order.partsNumbers.toLowerCase().includes(nbr.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isDateRangeFilterActive2) {
        orders = _.filter(orders, order => {
          let time = dayjs(order.dateOrdered).unix()
          if (order.dateOrdered && time > 0 && time >= this.computedDateRangeFilter2.start && time <= this.computedDateRangeFilter2.end) {
            // console.log('isDateRangeFilterActive.unix', dayjs.unix(time).format('DD/MM/YYYY'))
            // console.log('isDateRangeFilterActive.unix', dayjs.unix(this.computedDateRangeFilter.start).format('DD/MM/YYYY'))
            // console.log('isDateRangeFilterActive.unix', dayjs.unix(this.computedDateRangeFilter.end).format('DD/MM/YYYY'))
            return true
          }
          return false
        })
      }
      if (this.isCustomerNameFilterActive2) {
        orders = _.filter(orders, order => {
          return order.customerName && _.some(this.computedCustomerNameFilter2, (itm) => itm == order.customerName)
        })
      }

      if (this.isBackOrderCustomerNameFilterActive) {
        orders = _.filter(orders, order => {
          return order.customerName && _.some(this.computedBackOrderCustomerNameFilter, (itm) => ((itm == 'All Customers' || itm == order.customerName) && order.isBackOrderAcceptances))
        })
      }

      if (this.isNumberFilterActive) {
        orders = _.filter(orders, order => {
          return order.number && _.some(this.computedNumberFilter, (itm) => itm == order.number)
        })
      }

      if (this.isInvoiceNumberFilterActive) {
        orders = _.filter(orders, order => {
          return order.invoiceNumber && _.some(this.computedInvoiceNumberFilter, (itm) => itm == order.invoiceNumber)
        })
      }

      if (this.isPartReceivedStatusFilterActive) {
        let filter = []
        let isReceivedFilterActive = false
        let isNotReceivedFilterActive = false
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partRcvdStatus) {
            filter = obj[key]
            let statuses = obj[key].partRcvdStatus.split(';').filter(Boolean)
            _.forEach(statuses, (st) => {
              if (st === 'Received') {
                isReceivedFilterActive = true
              }
              if (st === 'Not Received') {
                isNotReceivedFilterActive = true
              }
            })
            break
          }
        }
        orders = _.filter(orders, order => {
          let bool = false
          if (isReceivedFilterActive) {
            if (order.partsStatuses && (order.partsStatuses.somePartsReceived || order.partsStatuses.allPartsReceived)) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Order Completed')) {
            if (order.status === 'Completed') {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Incorrect Part Delivered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrect) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Partial Delivery')) {
            if (order.partsStatuses && order.partsStatuses.somePartsPartialDelivery) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Incorrect Part Ordered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrectOrdered) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('No Longer Needed')) {
            if (order.partsStatuses && order.partsStatuses.somePartsNoLongerNeeded) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Change in Repair Method')) {
            if (order.partsStatuses && order.partsStatuses.somePartsChangeInRepairMethod) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Damaged Part in Transport')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamaged) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Damaged Part EX Warehouse')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamagedExWarehouse) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Cancelled Part')) {
            if (order.partsStatuses && order.partsStatuses.somePartsCancelled) {
              bool = true
            }
          }
          if (isNotReceivedFilterActive) {
            if (order.partsStatuses && order.partsStatuses.somePartsReceived === false) {
              bool = true
            }
          }
          return bool
        })
      }

      if (this.isPartStockStatusFilterActive) {
        let filter = ''
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partStockStatus) {
            filter = obj[key].partStockStatus
            break
          }
        }
        orders = _.filter(orders, order => {
          let bool = false
          if (filter.includes('None')) {
            if (order.partsStocks && order.partsStocks.someNone) {
              bool = true
            }
          }
          if (filter.includes('In Stock')) {
            if (order.partsStocks && order.partsStocks.someInStock) {
              bool = true
            }
          }
          if (filter.includes('Low Stock')) {
            if (order.partsStocks && order.partsStocks.someLowStock) {
              bool = true
            }
          }
          if (filter.includes('No Stock')) {
            if (order.partsStocks && order.partsStocks.someNoStock) {
              bool = true
            }
          }
          if (filter.includes('On Back Order')) {
            if (order.partsStocks && order.partsStocks.someOnBackOrder) {
              bool = true
            }
          }
          if (filter.includes('No Longer Avail')) {
            if (order.partsStocks && order.partsStocks.someNoLongerAvailable) {
              bool = true
            }
          }
          return bool
        })
      }
      return orders
    },
    getFilteredRFQs(rfqs) {
      if (this.isPartNumberFilterActive) {
        let formattedFilter = this.computedPartNumberFilter
        rfqs = _.filter(rfqs, rfq => {
          let bool = false
          _.forEach(formattedFilter, (nbr) => {
            if (rfq.partsNumbers && rfq.partsNumbers.toLowerCase().includes(nbr.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isDateRangeFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          let time = dayjs(rfq.dateReceived).unix()
          if (rfq.dateReceived && time > 0 && time >= this.computedDateRangeFilter.start && time <= this.computedDateRangeFilter.end) {
            return true
          }
          return false
        })
      }
      if (this.isCustomerNameFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.customerName && _.some(this.computedCustomerNameFilter, (itm) => itm == rfq.customerName)
        })
      }
      if (this.isMakeFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.make && _.some(this.computedMakeFilter, (itm) => itm == rfq.make)
        })
      }
      if (this.isQuoteNumberFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.supplierQuoteNumber && _.some(this.computedQuoteNumberFilter, (itm) => _.trim(itm) == _.trim(rfq.supplierQuoteNumber))
        })
      }
      if (this.isRegoFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.rego && _.some(this.computedRegoFilter, (itm) => _.trim(itm) == _.trim(rfq.rego))
        })
      }
      if (this.isVinFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.vin && _.some(this.computedVinFilter, (itm) => _.trim(itm) == _.trim(rfq.vin))
        })
      }
      if (this.isRepairerQuoteNumberFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.repairerQuoteNumber && _.some(this.computedRepairerQuoteNumberFilter, (itm) => itm == rfq.repairerQuoteNumber)
        })
      }
      return rfqs
    },
    handleResize() {
      this.documentHeight =  window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight - 4

      this.documentWidth =  window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth
    },
    redirectToRfq(itm) {
      if (itm.creditId) {
        this.$router.push({name: 'SuppliersCreditView', params: {creditId: itm.creditId, isNeedOpenChat: true}})
      } else if (itm.orderId) {
        this.$router.push({name: 'SupplierPartsOrderView', params: {orderId: itm.orderId, isNeedOpenChat: true}})
      } else if (itm.rfqToSupplierId) {
        this.$router.push({name: 'SupplierRFQView', params: {rfq_id: itm.rfqToSupplierId, isNeedOpenChat: true}})
      }
    },
    formatDateTime2 (val) {
      let format = 'DD/MM'
      if (dayjs(val).isToday()) {
        format = 'hh:mmA'
      }
      return (val) ? dayjs(val).format(format) : ''
    },
    getOrderNumber(id) {
      let order = _.find(this.gettersAllOrders, (o) => {
        return Number(o.id) === Number(id)
      })
      if (order) {
        return ' - ' + order.number
      }
      return ''
    },
    downloadFile(itm) {
      if (itm.type === 'scheme') {
        this.$router.push({ name: 'SupplierRFQView', params: { rfq_id: itm.rfqToSupplierId, shemeId: itm.id } })
      } else if (itm.fileType === 'application/pdf') {
        this.selectedUrl = itm.url;
        this.isShowPdf = true;
        this.handleResize()
      } else {
        window.open(itm.url, '_blank')
      }
    },
    formatDeliveryType(v) {
      if (_.isEmpty(v)) {
        return ''
      }
      return v.charAt(0).toUpperCase() + v.slice(1)
    },
    isToday(v) {
      return dayjs(v).isToday()
    },
    isTomorrow(v) {
      return dayjs(v).isTomorrow()
    },
    loadData() {
      Axios.get('/ir/supplier/ref/' + this.$route.params.rfqId)
          .then((response) => {
            if (response.data._status) {
              this.jobData = response.data
            }
          })
          .catch(() => {

          })
    },
    onSearchFilterChange1(filters) {
      this.searchFilterObj1 = filters
      if (filters && filters.search) {
        this.filter1 = filters.search;
      } else {
        this.filter1 = '';
      }
    },
    isStatusApproved: function (item) {
      return item.status === 'Approved'
    },
    isStatusPartialApproved: function (item) {
      return item.status === 'PartialApproved'
    },
    isStatusUnderReview: function (item) {
      return item.status === 'UnderReview'
    },
    isStatusDenied: function (item) {
      return item.status === 'Denied'
    },
    isStatusCancelled: function (item) {
      return item.status === 'Cancelled'
    },
    onSearchFilterChange2(filters) {
      this.searchFilterObj2 = filters
      if (filters[0] && filters[0].search) {
        this.filter2 = filters[0].search;
      } else {
        this.filter2 = '';
      }
    },
    filterTable1(row, filter) {
      let formattedFilter = filter.toLowerCase()
      let dateDue = row.dateDue ? moment(row.dateDue).format('DD/MM/YYY || hh:mmA') : ''
      let dateReceived = row.dateReceived ? moment(row.dateReceived).format('DD/MM/YYYY') : ''

      if (dateDue && dateDue.includes(formattedFilter)) {
        return true
      } else if (dateReceived && dateReceived.includes(formattedFilter)) {
        return true
      } else if (row.partsNumbers && row.partsNumbers.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.customerName && row.customerName.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.estimator && row.estimator.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.number && row.number.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.orderNumber && row.orderNumber.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.qty && String(row.qty).toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.vin && row.vin.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.supplierQuoteNumber && row.supplierQuoteNumber.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.repairerQuoteNumber && row.repairerQuoteNumber.toLowerCase().includes(formattedFilter)) {
        return true
      }

    },
    clickRFQRow:function (item,index,event) {
      this.$router.push({name: 'SupplierRFQView', params: {rfq_id: item.id, isNeedOpenChat: item.hasUnreadMessages}});
      return;
    },
    clickOrderRow: function (order, index, event) {
      this.$router.push({ path: '/s/order/' + order.id })
      return
    },
    onCreditRowClick: function (item, index, event) {
      this.$router.push('/s/credit/' + item.id)
      return
    },
    filterTable2 (row, filter) {
      let formattedFilter = filter.toLowerCase()
      let dateDue = row.dateDue ? dayjs(row.dateDue).format('DD/MM/YYYY || hh:mmA') : ''
      let dateOrdered = row.dateOrdered ? dayjs(row.dateOrdered).format('DD/MM/YYYY') : ''

      if (dateDue && dateDue.includes(formattedFilter)) {
        return true
      } else if (dateOrdered && dateOrdered.includes(formattedFilter)) {
        return true
      } else if (row.partsNumbers && row.partsNumbers.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.customerName && row.customerName.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.costStr && row.costStr.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.estimator && row.estimator.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.grossValue && row.grossValue.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.number && row.number.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.vin && row.vin.toLowerCase().includes(formattedFilter)) {
        return true
      }

    },
  },
  computed: {
    ...mapGetters({
      gettersAllRFQs: 'supplier/rfq/getAllRfqs',
      getterAllCredits: 'supplier/credit/getAllCredits',
      gettersAllOrders: 'supplier/order/getAllOrders',
      messages: 'supplier/dashboard/getMessages',
      filesUpdater: 'supplier/dashboard/getFiles',
      getActivityList: 'supplier/dashboard/getActivityList',
    }),
    ordersActivity() {
      return _.orderBy(_.filter(this.getActivityList, (a) => {
        let id = a?.data?.orderId;
        if (a?.entity?.type == 'order' && a?.entity?.id) {
          id = a.entity.id
        }
        return  (a?.entity?.type == 'order' || a?.entity?.type == 'back_order') && _.includes(this.jobData.orderIds, id)
      }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
    },
    rfqsActivity() {
      return _.orderBy(_.filter(this.getActivityList, (a) => {
        return  a?.entity?.type == 'rfq_to_supplier' && _.includes(this.jobData.rfqIds, a?.data?.rfqToSupplierId)
      }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
    },
    computedCustomCountsCredit() {
      return {
        'credit': this.credits.length
      }
    },
    computedCustomCountsRfq() {
      return {
        'rfq': this.rfq.length
      }
    },
    computedCustomCountsOrder() {
      return {
        'order': this.orders.length
      }
    },
    currentRfq() {
      let data = _.orderBy(this.gettersAllRFQs, [(itm) => new Date(itm.dateReceived).getTime()], 'asc')
           return _.find(data, (r) => {
        return Number(r.repairerMainRfqId) === Number(this.$route.params.rfqId)
      })
    },
    rfq() {
      return _.filter(this.gettersAllRFQs, (r) => {
        return this.jobData.rfqIds.includes(r.id)
      })
    },
    orders() {
      return _.filter(this.gettersAllOrders, (r) => {
        return this.jobData.orderIds.includes(r.id)
      })
    },
    credits() {
      return _.filter(this.getterAllCredits, (r) => {
        return this.jobData.creditIds.includes(r.id)
      })
    },
    messagesCredit() {
      let ids = []
      _.forEach(this.credits, (r) => {
        ids.push(r.id)
      })
      return _.filter(this.messages, (m) => {
        return _.includes(ids, m.creditId)
      })
    },
    messagesRfqs() {
      let ids = []
      _.forEach(this.rfq, (r) => {
        ids.push(r.id)
      })
      return _.filter(this.messages, (m) => {
        return _.includes(ids, m.rfqToSupplierId)
      })
    },
    messagesOrders() {
      let ids = []
      _.forEach(this.orders, (r) => {
        ids.push(r.id)
      })
      return _.filter(this.messages, (m) => {
        return _.includes(ids, m.orderId)
      })
    },
    computedSearchFilters1() {
      let regos = {};
      let vins = {}
      let partNumbers = {}
      let makes = {}
      let customers = {}
      let supplierQuoteNumbers = {}
      let repairerQuoteNumbers = {}

      this.rfq.forEach(rfq => {
        if (rfq.rego) {
          regos[rfq.rego.trim().toLowerCase()] = {label: rfq.rego.trim()}
        }
      })

      this.rfq.forEach(rfq => {
        if (rfq.vin) {
          vins[rfq.vin.trim().toLowerCase()] = {label: rfq.vin.trim()}
        }
      })
      this.rfq.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
          })
        }
      })
      this.rfq.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = {label: rfq.make.trim()}
        }
      })
      this.rfq.forEach(rfq => {
        if (rfq.customerName) {
          customers[rfq.customerName.trim().toLowerCase()] = {label: rfq.customerName.trim()}
        }
      })

      this.rfq.forEach(rfq => {
        if (rfq.supplierQuoteNumber) {
          supplierQuoteNumbers[rfq.supplierQuoteNumber.trim().toLowerCase()] = {label: rfq.supplierQuoteNumber.trim()}
        }
      })

      this.rfq.forEach(rfq => {
        if (rfq.repairerQuoteNumber) {
          repairerQuoteNumbers[rfq.repairerQuoteNumber.trim().toLowerCase()] = {label: rfq.repairerQuoteNumber.trim()}
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      vins = _.orderBy(Object.values(vins), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      supplierQuoteNumbers = _.orderBy(Object.values(supplierQuoteNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      repairerQuoteNumbers = _.orderBy(Object.values(repairerQuoteNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      // Create date template
      // const date = [
      //   {
      //     id: 'date',
      //     label: "Date",
      //     resultObjectOnly: true,
      //     type: "Date",
      //     iconClass: "bx-calendar"
      //   }
      // ];

      // Create searchFilter filters
      return [
        {
          id: 'rego',
          label: 'Rego',
          iconClass: 'bx-user',
          options: [
            regos,
          ]
        },
        {
          id: 'vin',
          label: 'VIN',
          iconClass: 'bx-user',
          options: [
            vins,
          ]
        },
        {
          id: 'customer',
          label: 'Customers',
          iconClass: 'bx-user',
          options: [
            customers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'quoteNumber',
          label: 'Quote Number',
          iconClass: 'bx-store',
          options: [
            supplierQuoteNumbers
          ]
        },
        {
          id: 'repairerQuoteNbr',
          label: 'Repairer Quote Nbr',
          iconClass: 'bx-store',
          options: [
            repairerQuoteNumbers
          ]
        },
        {
          id: 'dateRange',
          label: 'Date Range',
          iconClass: 'bx-store',
          options: [
            []
          ]
        }
      ]
    },
    isDateRangeFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].dateRange) {
          return true
        }
      }
      return false
    },
    isPartNumberFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].partNumber) {
          return true
        }
      }
      return false
    },
    isInvoiceNumberFilterActive () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].invoiceNumber) {
          return true
        }
      }
      return false
    },
    isPartStockStatusFilterActive(){
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].partStockStatus) {
          return true
        }
      }
      return false
    },
    isPartReceivedStatusFilterActive () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].partRcvdStatus) {
          return true
        }
      }
      return false
    },
    isCustomerNameFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].customer) {
          return true
        }
      }
      return false
    },
    isBackOrderCustomerNameFilterActive () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].backOrderCustomer) {
          return true
        }
      }
      return false
    },
    isNumberFilterActive () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].number) {
          return true
        }
      }
      return false
    },
    computedPartNumberFilter2 () {
      let filter = {
        partNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj2)
      for (let key in obj) {
        if (obj[key].partNumber) {
          filter = obj[key]
          break
        }
      }
      let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)
      return formattedFilter
    },
    computedInvoiceNumberFilter () {
      let filter = {
        invoiceNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj2)
      for (let key in obj) {
        if (obj[key].invoiceNumber) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.invoiceNumber, ';')
      return filter
    },
    computedDateRangeFilter2 () {
      let filter = {
        start: '',
        end: '',
      }
      let obj = Object.assign({}, this.searchFilterObj2)
      for (let key in obj) {
        if (obj[key].dateRange && obj[key].dateRange.start && obj[key].dateRange.end) {
          filter.start = dayjs(obj[key].dateRange.start, 'DD/MM/YYYY').unix()
          filter.end = dayjs(obj[key].dateRange.end, 'DD/MM/YYYY').unix()
          break
        }
      }
      return filter
    },
    computedCustomerNameFilter2 () {
      let filter = {
        customer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj2)
      for (let key in obj) {
        if (obj[key].customer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.customer, ';')
      return filter
    },
    computedBackOrderCustomerNameFilter () {
      let filter = {
        backOrderCustomer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj2)
      for (let key in obj) {
        if (obj[key].backOrderCustomer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.backOrderCustomer, ';')
      return filter
    },
    computedNumberFilter () {
      let filter = {
        number: '',
      }
      let obj = Object.assign({}, this.searchFilterObj2)
      for (let key in obj) {
        if (obj[key].number) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.number, ';')
      return filter
    },
    computedSearchFilters2 () {
      let partNumbers = {}
      let customers = {}
      let backOrderCustomers = {}
      let repairerOrderNumbers = {}
      let invoiceNbrs = {}

      this.orders.forEach(order => {
        if (order.partsNumbers) {
          let numbers = order.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }

      })

      this.orders.forEach(order => {
        if (order.customerName) {
          customers[order.customerName.trim().toLowerCase()] = { label: order.customerName.trim() }
        }
      })

      this.orders.forEach(order => {
        if (order.customerName && order.isBackOrderAcceptances) {
          backOrderCustomers[order.customerName.trim().toLowerCase()] = { label: order.customerName.trim() }
        }
      })

      this.orders.forEach(order => {
        if (order.number) {
          repairerOrderNumbers[order.number.trim().toLowerCase()] = { label: order.number.trim() }
        }
      })

      this.orders.forEach(order => {
        if (order.invoiceNumber) {
          invoiceNbrs[order.invoiceNumber.trim().toLowerCase()] = { label: order.invoiceNumber.trim() }
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      backOrderCustomers = _.orderBy(Object.values(backOrderCustomers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      backOrderCustomers.unshift({label: 'All Customers'})
      repairerOrderNumbers = _.orderBy(Object.values(repairerOrderNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      invoiceNbrs = _.orderBy(Object.values(invoiceNbrs), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      // // Create date template
      const date = [
        {
          id: 'date',
          label: 'Date',
          resultObjectOnly: true,
          type: 'Date',
          iconClass: 'bx-calendar'
        }
      ]

      let partStockStatuses = [
        { label: 'None' },
        { label: 'In Stock' },
        { label: 'Low Stock' },
        { label: 'No Stock' },
        { label: 'On Back Order' },
        { label: 'No Longer Avail' },
      ]

      let partRcvdStatuses = [
        { label: 'Received' },
        { label: 'Incorrect Part Delivered' },
        { label: 'Incorrect Part Ordered' },
        { label: 'Damaged Part in Transport' },
        { label: 'Damaged Part EX Warehouse' },
        { label: 'No Longer Needed' },
        { label: 'Change in Repair Method' },
        { label: 'Cancelled Part' },
        { label: 'Not Received' },
        { label: 'Partial Delivery' },
        { label: 'Order Completed' },
      ]

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'customer',
          label: 'Customer',
          iconClass: 'bx-user',
          options: [
            customers,
          ]
        },
        {
          id: 'number',
          label: 'Repairer Order Nbr',
          iconClass: 'bx-user',
          options: [
            repairerOrderNumbers
          ]
        },
        {
          id: 'invoiceNumber',
          label: 'Invoice Nbr',
          iconClass: 'bx-user',
          options: [
            invoiceNbrs
          ]
        },
        {
          id: 'backOrderCustomer',
          label: 'Back Order Acceptances',
          iconClass: 'bx-user',
          options: [
            backOrderCustomers
          ]
        },
        {
          id: 'partStockStatus',
          label: 'Part Stock Status',
          iconClass: 'bx-user',
          options: [
            partStockStatuses,
          ]
        },
        {
          id: 'partRcvdStatus',
          label: 'Part Rcvd Status',
          iconClass: 'bx-user',
          options: [
            partRcvdStatuses,
          ]
        },
        {
          id: 'dateRange',
          label: 'Date Range',
          iconClass: 'bx-store',
          options: [
            []
          ]
        }
      ]
    },

    computedMakeFilter() {
      let filter = {
        make: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].make) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.make, ';')
      return filter
    },
    computedCustomerNameFilter() {
      let filter = {
        customer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].customer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.customer, ';')
      return filter
    },
    computedDateRangeFilter() {
      let filter = {
        start: '',
        end: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].dateRange && obj[key].dateRange.start && obj[key].dateRange.end) {
          filter.start = dayjs(obj[key].dateRange.start, 'DD/MM/YYYY').unix()
          filter.end = dayjs(obj[key].dateRange.end, 'DD/MM/YYYY').unix()
          break
        }
      }
      return filter
    },
    computedPartNumberFilter() {
      let filter = {
        partNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].partNumber) {
          filter = obj[key]
          break
        }
      }
      let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)
      return formattedFilter
    },
    computedQuoteNumberFilter() {
      let filter = {
        quoteNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].quoteNumber) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.quoteNumber, ';')
      return filter
    },
    computedVinFilter() {
      let filter = {
        vin: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].vin) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.vin, ';')
      return filter
    },
    computedRegoFilter() {
      let filter = {
        rego: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].rego) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.rego, ';')
      return filter
    },
    computedRepairerQuoteNumberFilter() {
      let filter = {
        repairerQuoteNbr: '',
      }
      let obj = Object.assign({}, this.searchFilterObj1)
      for (let key in obj) {
        if (obj[key].repairerQuoteNbr) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.repairerQuoteNbr, ';')
      return filter
    },
    isDateRangeFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].dateRange) {
          return true
        }
      }
      return false
    },
    isMakeFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].make) {
          return true
        }
      }
      return false
    },
    isPartNumberFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].partNumber) {
          return true
        }
      }
      return false
    },
    isCustomerNameFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].customer) {
          return true
        }
      }
      return false
    },
    isQuoteNumberFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].quoteNumber) {
          return true
        }
      }
      return false
    },
    isRegoFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].rego) {
          return true
        }
      }
      return false
    },
    isVinFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].vin) {
          return true
        }
      }
      return false
    },
    isRepairerQuoteNumberFilterActive() {
      for (let key in this.searchFilterObj1) {
        if (this.searchFilterObj1[key].repairerQuoteNbr) {
          return true
        }
      }
      return false
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.loadData()
    this.isShowSearch1 = true;
    setTimeout(() => {
      this.isShowSearch2 = true
    }, 10)
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

  },
}
</script>

<template>
  <div class="ref-view">
    <div class="page-header">
      <h4>Ref:<span v-if="getRefNumber()"> {{ getRefNumber()}}</span><span v-if="currentRfq && (currentRfq.make || currentRfq.model)"> | {{currentRfq.make}} {{currentRfq.model}}</span><span v-if="currentRfq && currentRfq.customerName"> | Customer: {{currentRfq.customerName}}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/s">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <span style="cursor: pointer;">View Quotes & Orders</span>
        </li>
      </ol>
      <!--      <div class="">-->
      <!--        <sticky-header-->
      <!--            v-if="isShowStickyHeader"-->
      <!--            @onFocusPaintCode="onFocusPaintCode"-->
      <!--            @saveClaimNbr="saveClaimNbr"-->
      <!--            @onBlurPaintCode="onBlurPaintCode"-->
      <!--            @onQuoteNbrBlur="onQuoteNbrBlur"-->
      <!--            @selectedAllStock="selectedAllStock"-->
      <!--            @updatePaintCodeForRepair="updatePaintCodeForRepair"-->
      <!--            @viewOrder="viewOrder"-->
      <!--            @updateSupplierQuoteNumber="updateSupplierQuoteNumber"-->
      <!--            @selectQuoteNbr="selectQuoteNbr"-->
      <!--            @removeQuoteNbr="removeQuoteNbr"-->
      <!--            @onFocusOrBlurClaimNbr="v => isClaimNumberFieldFocused = v"-->
      <!--            :isSelectedImportCSV="isSelectedImportCSV"-->
      <!--            :priceResubmitFlag="priceResubmitActiveFlag"-->
      <!--            :isQuoteNbrFocus="isQuoteNbrFocus"-->
      <!--            :isSelectedImportDMS="isSelectedImportDMS"-->
      <!--            :showPlaceholderPaintCode="showPlaceholderPaintCode"-->
      <!--            :isRfqToUsed="isRfqToUsed"-->
      <!--            :isFlashingPaintCode="isFlashingPaintCode"-->
      <!--            :isRFQNew="isRFQNew"-->
      <!--            :isAdditionalRfq="isAdditionalRfq"-->
      <!--            :isRFQWon="isRFQWon"-->
      <!--            :isNewStatus="rfq.status === 'New'"-->
      <!--            :isRFQPriced="isRFQPriced"-->
      <!--            :priceResubmitActiveFlag="priceResubmitActiveFlag"-->
      <!--            :isLockedRfq="isLockedRfq"-->
      <!--            :computedUpdatedQuoteNbr="computedUpdatedQuoteNbr"-->
      <!--            :quoteNumbersFiltered="quoteNumbersFiltered"-->
      <!--            :activeSupplierId="activeSupplierId"-->
      <!--            :clickedSupplierId="clickedSupplierId"-->
      <!--            :activeColumnIndex="activeColumnIndex"-->
      <!--            @changedClickedSupplierId="changedClickedSupplierId"-->
      <!--            @changedActiveColumnIndex="changedActiveColumnIndex"-->
      <!--            @changedActiveSupplierId="changedActiveSupplierId"-->
      <!--            :rfq="rfq"-->
      <!--        ></sticky-header>-->
      <!--      </div>-->
    </div>
    <div v-if="!isShowPdf" class="job-view-body">
      <tabs
          :custom-counts="computedCustomCountsRfq"
      >
        <div class="job-view-body-bottom-border"></div>
        <tab name="Request For Quotes" id="rfq">
          <div class="d-flex table-and-notification-wrapper">
            <div class="col-md-6 rfq-table table">
              <b-table
                  :items="getFilteredRFQs(rfq)"
                  :fields="rfqFields"
                  :filter="filter1"
                  :filter-function="filterTable1"
                  @row-clicked="clickRFQRow"
              >
              </b-table>
            </div>
            <div class="job-view-right">
              <div class="d-flex">
                <div class="job-view-right-block">
                  <div class="job-view-title">Files</div>
                  <div class="job-view-notifications">
                    <template v-if="jobData.rfqFiles && jobData.rfqFiles.length">
                      <div class="job-view-notification_item clickable" @click="downloadFile(itm)" v-for="(itm) in jobData.rfqFiles">
                        <div class="job-view-notification_item-title">
                          <span v-if="itm.type === 'scheme'">Parts Diagram</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'application/pdf'">Quote PDF</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/html'">Quote HTML</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'">Quote CSV</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'">Image</span>
                        </div>
                        <div class="job-view-notification_item-info">{{itm.name}}<span class="job-view-notification_item-info-icon-file">
                            <i v-if="itm && itm.fileType && itm.fileType === 'application/pdf'" class='bx bxs-file-pdf'></i>
                            <i v-else-if="itm && itm.fileType && itm.fileType === 'text/html'" class='bx bxs-file-html'></i>
                            <i v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'" class='bx bxs-file-txt'></i>
                            <i v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'" class='bx bxs-file-image' ></i>
                        </span></div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No files to show</div>
                    </template>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Notification</div>
                  <div class="job-view-notifications">
                    <div v-if="_isEmpty(rfqsActivity)" style="padding: 10px 0px 0px 5px;">No new notifications</div>

                    <notifications v-else :activity="rfqsActivity"></notifications>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Message</div>
                  <div class="job-view-notifications">
                    <template v-if="messagesRfqs && messagesRfqs.length">
                      <div class="job-view-notifications-last">
                        <div v-for="itm in messagesRfqs" @click="redirectToRfq(itm)" class="job-view-notification_item" :class="{'active': !itm.viewedOn}">
                          <div class="job-view-notification_item-title"><div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span> - {{formatDateTime2(itm.createdOn)}}</span></div></div>
                          <div class="job-view-notification_item-info">{{itm.message}} <span class="job-view-notification_item-info-icon-message">
<!--                          <i class='bx bx-message-rounded-dots active' ></i>-->
                        </span></div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;" >No new messages</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <search-filter
                v-if="isShowSearch1"
                type="estimates"
                v-model="searchFilter1"
                :filters="computedSearchFilters1"
                :limitLength="50"
                :filterValue="filter1"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="8"
                :debug="false"
                height="41px"
                @change="onSearchFilterChange1"
                aria-expanded="false"
                data-toggle="dropdown"
            />
            <!--                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"-->
            <!--                                      debounce="500"></b-form-input>-->
          </div>
        </template>
      </tabs>
    </div>
    <div v-if="!isShowPdf" class="job-view-body">
      <tabs
          :custom-counts="computedCustomCountsOrder"
      >
        <div class="job-view-body-bottom-border"></div>
        <tab name="Parts Orders" id="order">
          <div class="d-flex table-and-notification-wrapper">
            <div class="col-md-6 table parts-order-table">
              <b-table
                  :items="filterOrdersForComputed(orders)"
                  :fields="orderFields"
                  :filter="filter2"
                  :filter-function="filterTable2"
                  @row-clicked="clickOrderRow"
              >
                <template #cell(deliveryType)="data">
                  {{ formatDeliveryType(data.value) }}
                  <span v-if="data.item.onBackOrder" style="color: red"><br v-if="data.value"/>Back Order</span>
                </template>
                <template #cell(status)="data">
                  <span v-if="data.value == 'PartialDelivered'">
                      Partial Delivered
                  </span>
                  <span v-else>
                    {{ data.value }}
                  </span>
                </template>
                <template #cell(dateDue)="data">
                  <template v-if="!data.value">Next available run</template>
                  <template v-else-if="isToday(data.value)">
                    <span style="color: #0cb458">Today</span>
                  </template>
                  <template v-else-if="isTomorrow(data.value)">
                    <span style="color: #f0710f">Tomorrow</span>
                  </template>
                  <template v-else>
                    {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
                  </template>
                </template>
              </b-table>
            </div>
            <div class="job-view-right">
              <div class="d-flex">
                <div class="job-view-right-block">
                  <div class="job-view-title">Files</div>
                  <div class="job-view-notifications">
                    <template v-if="jobData.orderFiles && jobData.orderFiles.length">
                      <div class="job-view-notification_item clickable" @click="downloadFile(itm)" v-for="(itm) in jobData.orderFiles">
                        <div class="job-view-notification_item-title">
                          <span v-if="itm.type === 'scheme'">Parts Diagram</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'application/pdf'">
                            <span v-if="itm.name.includes('NonReturnable')">Non-Returnable pdf</span>
                            <span v-else>Invoice PDF</span>
                          </span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/html'">Quote HTML</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'">Quote CSV</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'">Image</span>
                        </div>
                        <div class="job-view-notification_item-info">{{itm.name}}<span class="job-view-notification_item-info-icon-file">
                          <i v-if="itm && itm.fileType && itm.fileType === 'application/pdf'" class='bx bxs-file-pdf'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/html'" class='bx bxs-file-html'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'" class='bx bxs-file-txt'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'" class='bx bxs-file-image' ></i>
                        </span></div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No files to show</div>
                    </template>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Notification</div>
                  <div class="job-view-notifications">
                    <div v-if="_isEmpty(ordersActivity)" style="padding: 10px 0px 0px 5px;">No new notifications</div>

                    <notifications v-else :activity="ordersActivity"></notifications>

                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Message</div>
                  <div class="job-view-notifications">
                    <div class="job-view-notifications-last">
                      <template v-if="messagesOrders && messagesOrders.length">
                        <div v-for="itm in messagesOrders" @click="redirectToRfq(itm)" class="job-view-notification_item" :class="{'active': !itm.viewedOn}">
                          <div class="job-view-notification_item-title"><div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span>{{getOrderNumber(itm.orderId)}} - {{formatDateTime2(itm.createdOn)}}</span></div></div>
                          <div class="job-view-notification_item-info">{{itm.message}} <span class="job-view-notification_item-info-icon-message">
<!--                        <i class='bx bx-message-rounded-dots active' ></i>-->
                      </span></div>
                        </div>
                      </template>
                      <template v-else>
                        <div style="padding: 10px 0px 0px 5px;">No new messages</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <search-filter
                v-if="isShowSearch2"
                type="estimates"
                v-model="searchFilter2"
                :filters="computedSearchFilters2"
                :limitLength="50"
                :filterValue="filter2"
                @change="onSearchFilterChange2"
                :limit-length="50"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="5"
                :debug="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
            <!--                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"-->
            <!--                                      debounce="500"></b-form-input>-->
          </div>
        </template>
      </tabs>
    </div>
    <div v-if="!isShowPdf" class="job-view-body">
      <tabs
          :custom-counts="computedCustomCountsCredit"
      >
        <div class="job-view-body-bottom-border"></div>
        <tab name="Credits Management" id="credit">
          <div class="d-flex table-and-notification-wrapper">
            <div class="col-md-6 credit-management-table table">
              <b-table
                  :items="credits"
                  :fields="creditsFields"
                  :filter="filter3"
                  @row-clicked="onCreditRowClick"
              >
                <template #cell(dateCreated)="data">
                  {{ data.value | formatDate }}
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="isStatusUnderReview(data.item)" class="color_review">Under Review</span>
                  <span v-else-if="isStatusPartialApproved(data.item)"
                        class="color_partial_approved">Partial Approved</span>
                  <span v-else-if="isStatusApproved(data.item)" class="color_approved">Approved</span>
                  <span v-else-if="isStatusDenied(data.item)" class="color_denied">Denied</span>
                  <span v-else-if="isStatusCancelled(data.item)" class="color_cancelled">Cancelled</span>
                </template>
              </b-table>
            </div>
            <div class="job-view-right">
              <div class="d-flex">
                <div class="job-view-right-block">
                  <div class="job-view-title">Files</div>
                  <div class="job-view-notifications">
                    <template v-if="jobData.creditFiles && jobData.creditFiles.length">
                      <div @click="downloadFile(itm)" class="job-view-notification_item clickable" v-for="(itm) in jobData.creditFiles">
                        <div class="job-view-notification_item-title">
                          <span v-if="itm.type === 'scheme'">Parts Diagram</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'application/pdf'">Quote PDF</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/html'">Quote HTML</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'">Quote CSV</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'">Image</span>
                        </div>
                        <div class="job-view-notification_item-info">{{itm.name}}<span class="job-view-notification_item-info-icon-file">
                          <i v-if="itm && itm.fileType && itm.fileType === 'application/pdf'" class='bx bxs-file-pdf'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/html'" class='bx bxs-file-html'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'" class='bx bxs-file-txt'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'" class='bx bxs-file-image' ></i>
                        </span></div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No files to show</div>
                    </template>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Notification</div>
                  <div class="job-view-notifications">
                    <div style="padding: 10px 0px 0px 5px;">No new notifications</div>
                    <!--                    <div class="job-view-notification_item active">-->
                    <!--                      <div class="job-view-notification_item-title">Back Order - Acceptance</div>-->
                    <!--                      <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification"><i class='bx bx-bell active' ></i></span></div>-->
                    <!--                    </div>-->
                    <!--                    <div class="job-view-notification_item">-->
                    <!--                      <div class="job-view-notification_item-title">Update Quote-Completed</div>-->
                    <!--                      <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification active"><i class='bx bx-bell' ></i></span></div>-->
                    <!--                    </div>-->
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Message</div>
                  <div class="job-view-notifications">
                    <template v-if="messagesCredit && messagesCredit.length">
                      <div class="job-view-notifications-last">
                        <div v-for="itm in messagesCredit" @click="redirectToRfq(itm)" class="job-view-notification_item" :class="{'active': !itm.viewedOn}">
                          <div class="job-view-notification_item-title"><div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span> - {{formatDateTime2(itm.createdOn)}}</span></div></div>
                          <div class="job-view-notification_item-info">{{itm.message}} <span class="job-view-notification_item-info-icon-message">
<!--                        <i class='bx bx-message-rounded-dots active' ></i>-->
                      </span></div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No new messages</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input v-model="filter3" type="text" class="search-input" placeholder="Search or Filter results" debounce="500"></b-form-input>
          </div>
        </template>
      </tabs>
    </div>

    <pdf-viewer v-if="isShowPdf" :documentHeight="documentHeight" :documentWidth="documentWidth" :documentPath="selectedUrl" @closePdf="isShowPdf = false"></pdf-viewer>
  </div>
</template>

<style>
.ref-view .custom-dropdown-2 {
  position: static !important;
}
</style>
<style scoped>
.ref-view >>> .tabs-component{
  max-width: 50%;
}

@media (max-width: 1340px) {
  .table-and-notification-wrapper {
    flex-direction: column;
  }
  .table-and-notification-wrapper .table {
    max-width: none !important;
  }

  .table-and-notification-wrapper .job-view-right {
    padding-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .ref-view >>> .tabs-component{
    max-width: 100% !important;
  }
}

.job-view-body >>> .nav-tabs.nav-tabs-2{
  padding-right: 0;
}

.job-view-body-bottom-border{
  border-bottom: 10px solid #e3e4e7;
}
.job-view-right{
  width: 100%;
}
.job-view-right-block{
  flex: 0 1 33.333%;
}
.job-view-title{
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
  border-bottom: 5px solid #7bbec0;
  display: inline-block;
  width: auto;
}
.job-view-notifications{
  height: 200px;
  overflow-y: scroll;
  margin-top: 5px;
}

.job-view-notifications::-webkit-scrollbar-track{
  background-color: #dadada;
  border-radius: 5px;
}

.job-view-right .job-view-right-block:last-child .job-view-notifications::-webkit-scrollbar-track{
  background-color: #ffffff;
}
.job-view-right .job-view-right-block:last-child .job-view-notifications::-webkit-scrollbar-thumb{
  background-color: #979797;
}

.job-view-notifications-last{
  border: 0;
}
.job-view-notification_item{
  min-height: 50px;
  border: 2px solid #D7D7D7FF;
  border-radius: 5px;
  margin: 5px 5px;
  font-size: 11px;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
}
.job-view-notification_item:hover {
  border: 2px solid #bdbdbd;
}
.job-view-notification_item-title{
  font-weight: 700;
}
.job-view-notification_item-info{
  font-weight: 500;
  padding-right: 8px;
}
.rfq-table >>> th:nth-child(1){
  width: 50px;
}
.rfq-table >>> th:nth-child(2){
  width: 123px;
}
.rfq-table >>> th:nth-child(3){
  width: 89px;
}
.rfq-table >>> th:nth-child(6){
  width: 50px;
}
.parts-order-table >>> th:nth-child(1){
  width: 100px;
}
.parts-order-table >>> th:nth-child(2){
  width: 70px;
}
.credit-management-table >>> th:nth-child(4){
  width: 70px;
}
.credit-management-table >>> th:nth-child(5){
  width: 50px;
}
.credit-management-table >>> th:nth-child(6){
  width: 50px;
}
.job-view-notification_item-info .bx{
  font-size: 15px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  color: #626262;
}
.job-view-notification_item-info .bx-bell.active,.bx-message-rounded-dots.active{
  color: #29babf;
}
.tabs-nav-right-search{
  float: left !important;
}
.table >>> .number{
  font-weight: bold;
}
.job-view-notification_item.active{
  border: 2px solid #7bbec0
}

.job-view-notification_item.active:hover{
  border: 2px solid #3E8385FF
}
.tabs-nav-right-search >>> .search-filter__input{
  z-index: 1 !important;
}
.tabs-nav-right-search >>> .search-filter__box{
  position: static;
}
.tabs-nav-right-search >>> .search-filter__li{
  z-index: 1 !important;
}

.tabs-nav-right-search >>> .search-filter__box__with__dropdown .search-filter__input{
  z-index: 999 !important;
}
.tabs-nav-right-search >>> .search-filter__box__with__dropdown .search-filter__box{
  position: static;
}
.tabs-nav-right-search >>> .search-filter__box__with__dropdown .search-filter__li{
  z-index: 999 !important;
}


</style>
