/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    token: [],
    searchFilterObj: {
        search: '',
    },
    ordersFromUpdate: [],
    tokens: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
    filter: '',
    perPageAll: 100,
    perPageOpen: 100,
    perPageProcessing: 100,
    perPageRoute: 100,
    perPagePartialDelivery: 100,
    perPageDelivered: 100,
    perPageCompleted: 100,
    orderAllCurrentPage: 1,
    orderOpenCurrentPage: 1,
    orderProcessingCurrentPage: 1,
    orderRouteCurrentPage: 1,
    orderPartialDeliveryCurrentPage: 1,
    orderDeliveredCurrentPage: 1,
    orderCompletedCurrentPage: 1,
    // Open: [],
    // Processing: [],
    // Shipped: [],
    // Delivered: [],
    // Completed: [],
    lastTimeCheck: {
        items: 0,
        // Open: 0,
        // Processing: 0,
        // PartsShipped:0,
        // PartsDelivered: 0,
        // Completed: 0,
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
