/*eslint-disable */

export function getAllOrders(state) {
    return _.orderBy(state.items, ['dateCreated'], ['desc']);
}

export function getCompletedOrders(state) {
    let orders = _.filter(state.items, (itm) => {
        return itm.state == 2
    })
    return _.orderBy(orders, ['dateCreated'], ['desc']);
}

export function getCurrentOrders(state) {
    let orders = _.filter(state.items, (itm) => {
        return itm.state == 1
    })
    return _.orderBy(orders, ['dateCreated'], ['desc']);
}

export function getBackOrders(state) {
    let orders = _.filter(state.items, (itm) => {
        return itm.state == 3
    })
    return _.orderBy(orders, ['dateCreated'], ['desc']);
}

export function getToken(state) {
    return state.token;
}

export function getTokens(state) {
    return state.tokens;
}

export function getSearchFilterObj(state) {
    return state.searchFilterObj;
}

export function getFilter(state) {
    return state.filter;
}

export function getPerPage(state) {
    return state.perPage;
}

export function getCurrentPageCurrent(state) {
    return state.currentPageCurrent;
}

export function getCurrentPageCompleted(state) {
    return state.currentPageCompleted;
}

export function getCurrentPageBackOrder(state) {
    return state.currentPageBackOrder;
}

export function getIsNeedUpdate(state) {
    return state.isNeedUpdate;
}

export function getCurrentOrderId(state) {
    return state.currentOrderId;
}

export function ordersFromUpdate(state) {
    return state.ordersFromUpdate;
}
