/*eslint no-unused-vars: "off"*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";
import Vuelidate from "vuelidate";
import { VueContext } from 'vue-context';

import { appConfig } from "./config.js";
Vue.use(appConfig);
window.appConfig = appConfig;
Vue.use(Vuelidate);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import './styles/style.css';
// import './styles/style-views.css';
// import './styles/style-components.css';
import "vue-themify-icons/themify-icons/themify-icons.css";

import BootstrapVue from "bootstrap-vue";
import VueMobileDetection from "vue-mobile-detection";
import moment from 'moment';

import { registerLicense } from '@syncfusion/ej2-base'
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0VjWntYdHRXTmFd')

//import detectmobilebrowser from 'detect-browser'

Vue.config.productionTip = false;

Axios.defaults.baseURL = appConfig.baseAPIURL;

const $eventBus = new Vue();
window.addEventListener('message', e => {
  const type = e.data?.type;
  const data = e.data?.data;
  $eventBus.$emit('message', e);
  if(type){
    $eventBus.$emit('message-' + type, data);
  }
})

Vue.prototype.$http = Axios;
Vue.prototype.$eventBus = $eventBus; // Global Event Bus
const APIToken = window.location.href.includes('/partsbridge/activate') || localStorage.getItem("token") ? localStorage.getItem("token")  :  '';
if (APIToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = APIToken;
  Vue.prototype.$http.defaults.headers.common["CompanyTimeOffset"] = '0';
}
Vue.prototype.$http.defaults.headers.common["LocalTimeOffset"] = moment().utcOffset()/60;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log('to',to)
    console.log('from',from)
    if (store.getters.auth.isLoggedIn) {
      next();
      return;
    }
    console.log("next('/login')");
    next("/login");
  } else {
    console.log("else.next()");
    next();
  }
});

Vue.use(BootstrapVue);

Vue.use(VueMobileDetection);

Vue.filter("filterDayMonthYearTime", function(date) {
  if (date) {
    return moment(date, "MM/DD/YYYY, h:mm:ss a").format(
      "DD/MM/YYYY hh:mm:ss A"
    );
  }
  return null;
});

Vue.filter("percentage", function(value, decimals) {
  if(!value) {
      value = 0;
  }

  if(!decimals) {
      decimals = 0;
  }

  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value + '%';
  return value;
});

Vue.filter("formatDatetime", function(val) {
  if (!val) {
    return '';
  }
  return moment(val).format('DD/MM/YYYY - hh:mmA');
})

Vue.filter("formatTimeDate", function(val) {
  if (!val) {
    return '';
  }
  return moment(val).format('hh:mmA - DD/MM/YYYY');
})

Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

Vue.directive('scroll-into-view', {
  inserted: function (el) {
    el.scrollIntoView({block: "center", inline: "center"});
  }
});

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import Tabs from './components/rs-tabs';
Vue.use(Tabs);

window.$ = require("jquery");
window.JQuery = require("jquery");

window.NProgress = require('nprogress');

import VueToast from 'vue-toast-notification';
//import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast,{
duration: 6000,
  position: 'top-right'
});

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

