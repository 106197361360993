/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    perPage: 100,
    creditsFromUpdate: [],
    filter: '',
    token: [],
    tokens: {
        0: [],
        1: [],
        2: [],
        3: [],
    },
    searchFilterObj: {
        search: '',
    },
    creditAllCurrentPage: 1,
    creditDeniedCurrentPage: 1,
    creditApprovedCurrentPage: 1,
    creditUnderCurrentPage: 1,
    creditCancelledCurrentPage:1,
    lastTimeCheck: {
        items: 0,
        UnderReview: 0,
        Approved: 0,
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
