<script>
export default {
  name: 'supplier-makes',

  props: {
    chips: {
      type: Array,
      default () {
        return []
      }
    },
    isRemoveShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      chipsData: []
    }
  },
  watch: {
    chips: {
      handler (v) {
        this.generateChips(v)
      },
      immediate: true
    }
  },
  methods: {
    generateChips (newVal) {
      this.chipsData = _.sortBy(_.uniq(newVal))
    },
    removeChip (ind) {
      this.$emit('remove', this.chipsData[ind])
      this.chipsData.splice(ind, 1)
    }
  }
}
</script>
<template>
  <div class="chips__supplied">
        <span class="chip__wrap" v-for="(item, index) in chipsData" :key="item">{{ item }}
            <i v-if="isRemoveShow" @click="removeChip(index)" class="ti-close"></i>
        </span>
  </div>
</template>
