<script>
import {defineComponent} from 'vue'
import {Centrifuge} from "centrifuge";
import axios from "axios";

export default defineComponent({
  name: "test",
  data() {
    return {
      messages: [],
      messageText: '',
      inputDisabled: false
    }
  },
  async mounted(){
    const token = await this.obtainToken();
    console.log('token', token)
    if(token){
      await this.startCentrifuge(token)
    }
  },
  methods: {
    async obtainToken(){
      const response = await axios.get('/test/centrifugeAuth')
      return response?.data?.token || null;
    },
    async obtainTokenForChannel(){
      const response = await axios.get('/test/centrifugeSubscribe')
      return response?.data?.token || null;
    },
    async startCentrifuge(token){
      const centrifuge = new Centrifuge("wss://localhost:9000/connection/websocket", {
        token
      });

      centrifuge.on('connecting', function (ctx) {
        console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      }).on('connected', function (ctx) {
        console.log(`connected over ${ctx.transport}`);
      }).on('disconnected', function (ctx) {
        console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
      }).connect();


      const sub = centrifuge.newSubscription("$global-chat", {
        token: await this.obtainTokenForChannel()
      });

      sub.on('publication',  (ctx) => {
        console.log('publication', ctx.data)
        this.messages.push(ctx.data);
      }).on('subscribing', function (ctx) {
        console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      }).on('subscribed', function (ctx) {
        console.log('subscribed', ctx);
      }).on('unsubscribed', function (ctx) {
        console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      }).subscribe();
    },
    async sendMessage(){
      this.inputDisabled = true;
      const status = await this.sendMessageToServer(this.messageText);
      if(status){
        this.messageText = '';
      }
      this.inputDisabled = false;
    },
    async sendMessageToServer(text){
      const response = await axios.post('/test/centrifugeSendMessage', {
        text
      })
      return response?.data?._status || false;
    },
  }
})
</script>

<template>

  <div>
    <h3>Centrifuge Test</h3>

    <div class="test-chat">
      <div class="test-message" v-for="msg of messages">
        <h5>{{msg.author}}</h5>
        <p>{{msg.text}}</p>
        <small>{{msg.date}}</small>
      </div>
      <hr>
      <div class="chat-input-holder">
        <b-form-input v-model="messageText" @keyup.enter="sendMessage" :disabled="inputDisabled"></b-form-input>
      </div>
    </div>
  </div>

</template>

<style scoped>
.test-chat {
  max-width: 300px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
}
.test-message {
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.test-message:last-child {
  margin-bottom: 0;
}
</style>
