<script>
import ForgotPassUserDataPageCcnsw from "@/views/auth/ccnsw/forgot_pass-userdata.vue";
import ForgotPassUserDataPageDefault from "@/views/auth/default/forgot_pass-userdata.vue";

export default {
  components: {ForgotPassUserDataPageDefault, ForgotPassUserDataPageCcnsw},
  data () {
    return {
      host: '',
    }
  },
  methods: {},
  mounted () {
    this.host = window.location.hostname
  },
  computed: {
    isCCNSW () {
      return (this.host == 'ccnsw.dev.partssearch.com.au')
    },
    isDefault () {
      return !this.isCCNSW
    }
  }
}
</script>

<template>
  <forgot-pass-user-data-page-ccnsw v-if="isCCNSW"></forgot-pass-user-data-page-ccnsw>
  <forgot-pass-user-data-page-default v-else></forgot-pass-user-data-page-default>
</template>
