<template>
  <div class="settings-page">
    <div class="page-header">
      <h4>Advanced Settings</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'SupplierDashboard'}">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">Advanced Settings</li>
      </ol>
    </div>
    <div class="settings-body">
      <tabs
          ref="settings-table"
          cache-lifetime="0"
          @changed="onMainTabChange"
          @onClickBtn="addBusinessProfile"
          :is-show-tab-button="mainTabId.includes('businessProfile') && isShowAddBusinessProfileButton"
          :tabButtonHTML="getBtn()"
          :options="{useUrlFragment: false, defaultTabHash: computedDefaultSettingsHash, isOrdered: true }">
        <template v-if="currentUser.id">
          <tab :isShow="isAdministrator && (!isMultipleLocations || isUnifiedRfqSettings)"
               name="RFQ"
               id="rfq"
               ref="rfq"
               :order="100"
               class-name="tab-content-table">
            <the-rfq
                ref="rfqComponent"
                @onTabChange="v => rfqTabId = v"
                :company-name="currentCompany.businessName"
                :companyId="currentCompany.id"
            />
          </tab>
          <tab :isShow="!isUserTypeRepairer && !isMultipleLocations"
               name="Credits"
               id="credits"
               ref="credits"
               :order="200"
               class-name="tab-content-table">
            <credit
                ref="creditComponent"
                :companyId="currentCompany.id"
            ></credit>
          </tab>
          <tab :isShow="isShowTab && !isMultipleLocations"
               name="Integrations"
               id="integrations"
               ref="integrations"
               :order="300"
               class-name="tab-content-table">
            <integrations ref="integrationsComponent"></integrations>
          </tab>
          <tab :isShow="!isMultipleLocations && isShowTab"
               name="Orders"
               id="order"
               ref="order"
               class-name="tab-content-table">
            <the-supplier-orders
                  ref="orderComponent"
                :company-name="currentCompany.businessName"
                :companyId="currentCompany.id"/>
          </tab>
          <tab :isShow="isShowTab"
               name="Users"
               id="users"
               ref="users"
               :order="400"
               class-name="tab-content-table">
            <users
                @changedMakes="changedMakes"
                ref="usersComponent"
                @updatedTeamsMakes="v => isShowSaveUserButton = v"
                :myAccountMakes="myAccountMakes"
                :isAddNewUserClicked="isAddNewUserClicked"
                @addNewUserClicked="value => isAddNewUserClicked = value"
            >
            </users>
          </tab>
          <tab
              :isShow="isShowTab"
              name="Groups"
              id="groups"
              :order="500"
              ref="groups"
              class-name="tab-content-table">
            <groups ref="groupsComponent"></groups>
          </tab>
          <tab
              name="My Account"
              id="settings-account"
              :order="600"
              ref="my-account"
              :class-name="'tab-content-table'">
            <my-account
                ref="myAccountComponent"
                :usersMakes="usersMakes"
                @updatedTeamsMakes="v => isShowSaveMyAccButton = v"
                @changedMakes="changedMakes"></my-account>
          </tab>

          <template v-if="!isMultipleLocations">
            <tab :isShow="isShowTab && !isMultipleLocations"
                 name="Business Profile"
                 id="businessProfile"
                 :order="700"
                 :class-name="'tab-content-table'"
            >
              <business-profile
                  :ref="'businessProfile'"
                  @changedTab="changedBusinessTab"
              ></business-profile>
            </tab>
          </template>

          <template v-else>
            <div v-for="(bTab, index) in businessTabs" :key="bTab + index" class="">
              <!--              <template v-if="currentCompany.id === bTab.id">-->
              <!--                <tab-->
              <!--                    v-if="isShowTab"-->
              <!--                    :name="bTab.businessName"-->
              <!--                    :id="'businessProfile-' + bTab.id"-->
              <!--                    :class-name="'tab-content-table'"-->
              <!--            >-->
              <!--              <business-profile-->
              <!--                  :ref="'businessProfile-' + bTab.id"-->
              <!--                  @onDeletedBrand="onDeletedBrand"-->
              <!--                  :isCanDelete="!bTab.isMain"-->
              <!--                  @changedTab="changedBusinessTab"-->
              <!--                  @changedBrand="changedBrand"-->
              <!--              />-->
              <!--                </tab>-->
              <!--              </template>-->
              <template>
                <tab :isShow="isShowTab"
                     :name="bTab.businessName"
                     :id="'businessProfile-' + bTab.id"
                     :order="800 + (index * 100)"
                     :class-name="'tab-content-table'"
                >
                  <multiple-brand-profile
                      :ref="'businessProfile-' + bTab.id"
                      :initialCompanyInfo="bTab"
                      :isCanDelete="!bTab.isMain"
                      :initialLocations="bTab.locations"
                      @onDeletedBrand="onDeletedBrand"
                      @changedTab="changedBusinessTab"
                      @deletedBusinessProfile="deletedBusinessProfile"
                      @addedNewBusinessProfile="addedNewBusinessProfile"
                      @changedBrand="changedBrand"
                  ></multiple-brand-profile>
                </tab>
              </template>
            </div>
          </template>

        </template>

        <template
            v-if="mainTabId === 'groups' || mainTabId === 'orders'|| mainTabId === 'order' || mainTabId === 'credits' || (mainTabId === 'rfq' && rfqTabId !== 'nonReturnable')"
            slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="updateSettings" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId === 'integrations'" slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button class="btn btn-primary" v-if="!isAddNewUserClicked" @click="onApiKeyCreateClick()">
              <i class="bx bx-plus"></i> Create Api Key
            </button>
          </div>
        </template>
        <template v-if="mainTabId === 'users'" slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-1" v-if="isAddNewUserClicked">
            <button class="white-save-btn" @click="onClickSaveUser()">Save</button>
            <button class="white-save-btn" @click="onClickCancelEditUser()">Cancel
            </button>
          </div>
          <div class="tabs-nav-right-btn mr-1" v-if="isShowSaveUserButton">
            <button class="white-save-btn" @click="onClickSaveTeamsTab">Save</button>
          </div>
          <div class="tabs-nav-right-btn">
            <button class="btn btn-primary" v-if="!isAddNewUserClicked && isAdministrator" @click="onClickAddNewUser()">
              <i class="bx bx-plus"></i> Add User
            </button>
          </div>
        </template>
        <template v-if="mainTabId === 'settings-account'" slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-1" v-if="isShowSaveMyAccButton">
            <button class="white-save-btn" @click="onClickSaveTeamsTab">Save</button>
          </div>
        </template>
        <template v-if="mainTabId.includes('businessProfile') && businessTabId === 'business-details'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="onClickSaveCompanyInfo(null)" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId.includes('businessProfile') && businessTabId === 'rfq'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="onClickSaveCompanyInfo('rfq')" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId.includes('businessProfile') && businessTabId === 'order'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="onClickSaveCompanyInfo('order')" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId.includes('businessProfile') && businessTabId === 'credits'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="onClickSaveCompanyInfo('credit')" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId.includes('businessProfile') && businessTabId === 'integrations'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button class="btn btn-primary" v-if="!isAddNewUserClicked" @click="onClickSaveCompanyInfo('integration')">
              <i class="bx bx-plus"></i> Create Api Key
            </button>
          </div>
        </template>
        <template v-if="mainTabId.includes('businessProfile') && businessTabId === 'business-locations'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button class="white-save-btn" @click="onClickSaveCompanyInfo(null)">Save</button>
          </div>
          <div class="tabs-nav-right-btn">
            <button href="#" class="add-location-btn" @click="onClickAddLocation()">
              <i class="bx bx-plus"></i> Add Location
            </button>
          </div>
        </template>

      </tabs>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import TheRfq from './components/the-rfq'
import Integrations from './components/the-integrations'
import Groups from './components/the-groups'
import Users from './components/the-users'
import BusinessProfile from './components/business-profile'
import MyAccount from './components/the-my-account'
import Credit from './components/the-credit'
import MultipleBrandProfile from './components/multiple-brand-profile'
import TheSupplierOrders from "./components/the-supplier-orders";

export default {
  name: 'SupplierSettings',
  data () {
    return {
      mainTabId: '',
      businessTabId: '',
      rfqTabId: '',

      isShowSaveUserButton: false,
      isShowSaveMyAccButton: false,

      tempBusinessBrands: [],
      usersMakes: [],
      myAccountMakes: [],

      interval: null,
      interval2: null,
      interval3: null,

      // users
      isAddNewUserClicked: false,
    }
  },
  mounted () {
    if (!_.isEmpty(this.currentUser.role) && this.isShowTab) {
      this.tabId = this.$refs.users.id
    }
    this.loadUsersAll()
    this.loadSettings()
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
  },
  computed: {
    ...mapGetters({
      appLoadStatus: 'appLoadStatus',
      currentUser: 'currentUser/getCurrentUserInfo',
      currentCompany: 'currentCompany/getDetails',
      currentCompanyLocations: 'currentCompany/getLocations',
      currentCompanyContacts: 'currentCompany/getContacts',
      isUserTypeRepairer: 'currentUser/isUserTypeRepairer',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      isAdministrator: 'currentUser/isAdministrator',
      isPartsManager: 'currentUser/isPartsManager',
      isMultipleLocations: 'settings/isMultipleLocations',
      getterSettings: 'settings/getSettings',
      addressCountries: 'addressCountries',
      addressStates: 'addressStates',
      isUnifiedRfqSettings: 'settings/isUnifiedRfqSettings',
    }),
    businessTabs () {
      if (this.isMultipleLocations) {
        let temp = _.filter(this.tempBusinessBrands, (itm) => {
          return !_.find(this.getterSettings.multipleBrands, (b) => {
            return Number(b.id) === Number(itm.id)
          })
        })
        return _.concat(_.cloneDeep(this.getterSettings.multipleBrands), temp)
      }

      return []
    },
    isShowTab () {
      return this.isAdministrator || this.isPartsManager
    },
    isShowAddBusinessProfileButton () {
      return !_.find(this.businessTabs, (b) => {
        return !b.id
      })
    },
    computedDefaultSettingsHash () {
      if (localStorage.getItem('settings.tabs.defaultTab')) {
        return localStorage.getItem('settings.tabs.defaultTab')
      }
      return 'businessProfile'
    },
  },
  methods: {
    ...mapActions({
      loadUsersAll: 'users/fetchAllUsers',
      loadSettings: 'settings/loadSettings',
    }),
    onClickSaveTeamsTab () {
      if (this.mainTabId === 'users') {
        this.$refs.usersComponent.onClickSaveTeamsTab()
      } else {
        this.$refs.myAccountComponent.onClickSaveTeamsTab()
      }
    },
    onDeletedBrand (id) {
      let ind = _.find(this.tempBusinessBrands, (b) => {
        return Number(b.id) === Number(id)
      })
      if (ind !== -1) {
        this.tempBusinessBrands.splice(ind, 1)
      }
    },
    getBtn () {
      return '<button href="#" class="add-business-btn"> <i class="bx bx-plus"></i> Add Business Profile </button>'
    },
    changedMakes (makes) {
      if (this.mainTabId.includes('settings-account')) {
        this.myAccountMakes = _.cloneDeep(makes)
      } else {
        this.usersMakes = _.cloneDeep(makes)
      }
    },
    changedBrand (payload) {
      let v = _.cloneDeep(payload.value)
      if (payload.isNew) {
        let tab = _.find(this.tempBusinessBrands, (itm) => {
          return itm.id === null
        })
        if (tab) {
          tab.id = v.id
        }
        this.mainTabId = this.mainTabId.replaceAll('-null', '-' + v.id)
        return
      }

      let id = v.id
      if (payload.isSettings) {
        id = payload.companyId
      }

      console.log(payload, this.businessTabs, 'changed brand')
      let data = _.cloneDeep(this.businessTabs)
      let tab = _.find(data, (itm) => {
        return Number(itm.id) === Number(id)
      })
      if (payload.isSettings) {
        tab.settings = v
      } else {
        if (tab.id !== v.id) {
          tab.id = v.id
        }
        let country = _.find(_.cloneDeep(this.addressCountries), item => {
              return (item.key === v.address.country) || (item.key === v.address?.country?.key) || (item.key === v.address?.country?.[0]?.key)
            });
        let state = _.find(_.cloneDeep(this.addressStates), item => {
          return item.key === v.address.state || item.key === v?.address?.state?.key  || item.key === v?.address?.state?.[0]?.key
        })

        tab.businessName = v.businessName
        tab.about = v.about
        tab.name = v.name
        tab.acn = v.acn
        tab.abn = v.abn
        tab.businessType = v.businessType
        tab.phone = v.phone
        tab.fax = v.fax
        tab.email = v.email
        tab.emailOrders = v.emailOrders
        tab.emailCredits = v.emailCredits
        tab.brands = v.brands
        tab.deleteBrands = v.deleteBrands
        tab.logoImgPath = v.logoImgPath
        tab.address = {
          address: v.address.address,
          unitLevelLot: v.address.unitLevelLot,
          street: v.address.street,
          suburb: v.address.suburb,
          postcode: v.address.postcode,
          state: (!_.isEmpty(state)) ? state : null,
          country: (!_.isEmpty(country)) ? country : null
        }
        tab.displayId = v.displayId
        tab.primaryBusinessAddress = v.primaryBusinessAddress
      }

      console.log(v, data, tab)
      this.$store.commit('settings/setMultipleBrands', data)
      this.tempBusinessBrands = []
    },
    addBusinessProfile () {
      this.tempBusinessBrands.splice(0, this.tempBusinessBrands.length)
      if (!this.isMultipleLocations) {
        let currentCompany = _.cloneDeep(this.currentCompany)
        currentCompany.locations = this.currentCompanyLocations
        currentCompany.contacts = this.currentCompanyContacts
        this.$store.commit('settings/setMultipleBrands', [
          {
            ...currentCompany,
            isMain: true,
          },
          {
            'id': null,
            'displayId': null,
            'name': null,
            'businessName': 'Set Business Name',
            'abn': null,
            'acn': null,
            'companyType': 'supplier',
            'businessType': null,
            'phone': null,
            'fax': null,
            'email': null,
            'about': null,
            'logoImgPath': null,
            'brandingEnabled': false,
            'supplierType': 'OEM-GEN',
            'supplierTypes': ['OEM-GEN'],
            'address': {
              'address': null,
              'unitLevelLot': null,
              'street': null,
              'suburb': null,
              'postcode': null,
              'state': null,
              'country': null
            },
            'primaryBusinessAddress': {
              'address': null,
              'unitLevelLot': null,
              'street': null,
              'suburb': null,
              'postcode': null,
              'state': null,
              'country': null
            },
            'locations': [],
            'brands': [],
            'contacts': {},
            'settings': {
              'rfq': {
                timeNotifications: {
                  closingShortly: {
                    displayAs: 'Text',
                    timeLeft: 20
                  },
                  closingSoon: {
                    displayAs: 'Text',
                    timeLeft: 10
                  }
                },
                'customResponseLevel': [],
                'quoteEmail': null,
                'importQuoteOptions': {
                  'importPdfQuote': false,
                  "importCheckCsv": false,
                  'importCheck': false,
                  'importCsvQuote': false
                }
              }
            },
            'isMain': false
          },
        ])
      } else {
        this.tempBusinessBrands.push(
            {
              'id': null,
              'displayId': null,
              'name': null,
              'businessName': 'Set Business Name',
              'abn': null,
              'acn': null,
              'companyType': 'supplier',
              'businessType': null,
              'phone': null,
              'fax': null,
              'email': null,
              'about': null,
              'logoImgPath': null,
              'brandingEnabled': false,
              'supplierType': 'OEM-GEN',
              'supplierTypes': ['OEM-GEN'],
              'address': {
                'address': null,
                'unitLevelLot': null,
                'street': null,
                'suburb': null,
                'postcode': null,
                'state': null,
                'country': null
              },
              'primaryBusinessAddress': {
                'address': null,
                'unitLevelLot': null,
                'street': null,
                'suburb': null,
                'postcode': null,
                'state': null,
                'country': null
              },
              'locations': [],
              'brands': [],
              'contacts': {},
              'settings': {
                'rfq': {
                  timeNotifications: {
                    closingShortly: {
                      displayAs: 'Text',
                      timeLeft: 20
                    },
                    closingSoon: {
                      displayAs: 'Text',
                      timeLeft: 10
                    }
                  },
                  'customResponseLevel': [],
                  'quoteEmail': null,
                  'importQuoteOptions': {
                    'importPdfQuote': false,
                    'importCheck': false,
                    "importCheckCsv": false,
                    'importCsvQuote': false
                  }
                }
              },
              'isMain': false
            },
        )
      }

      this.tempBusinessName = ''
      setTimeout(() => {
        this.$refs['settings-table'].selectTab('#businessProfile-null')
      }, 0)
    },
    deletedBusinessProfile(ref) {
      let siteContent = document.getElementById('site-content')
      let siteContentArea = document.getElementById('site-content-area')
      this.$store.dispatch('reinstallationApp')
          .then(() => {
            this.$store.dispatch('initAppAfterLogin').then(() => {

              if (ref.includes('businessProfile-')) {
                this.interval = setInterval(() => {
                  console.log(_.cloneDeep(this.getterSettings.multipleBrands), this.appLoadStatus, this.getterSettings, 'interval')
                  if (this.appLoadStatus && !_.isEmpty(this.getterSettings.multipleBrands)) {
                    setTimeout(() => {
                      siteContent.classList.remove('loading-company')
                      siteContentArea.style.opacity = 1
                      this.$refs['settings-table'].selectTab(ref)
                    }, 200)
                    clearInterval(this.interval)
                  }
                }, 10)
              } else {
                siteContent.classList.remove('loading-company')
                siteContentArea.style.opacity = 1
                this.$refs['settings-table'].selectTab(ref)
              }

            })
          })

    },
    addedNewBusinessProfile(id) {
      let siteContent = document.getElementById('site-content')
      let siteContentArea = document.getElementById('site-content-area')

      this.$store.dispatch('settings/reinstallation')

      this.$store.dispatch('settings/loadSettings').then(() => {
        this.interval3 = setInterval(() => {
          if (this.appLoadStatus && !_.isEmpty(this.getterSettings.multipleBrands)) {
            this.$refs['settings-table'].selectTab('#businessProfile-' + id)
            clearInterval(this.interval3)
          }
        }, 10)
        setTimeout(() => {
          siteContent.classList.remove('loading-company')
          siteContentArea.style.opacity = 1
        }, 200)
      })
    },
    changedBusinessTab (tab) {
      this.businessTabId = tab
    },
    onMainTabChange: function (tab) {
      console.log('tab', tab)
      this.mainTabId = tab.tab.id
      if (this.mainTabId.includes('businessProfile') && this.isMultipleLocations) {
        if (this.$refs?.[this.mainTabId]?.length) {
          this.$refs?.[this.mainTabId]?.[0]?.sendTab()
        } else {
          this.$refs?.[this.mainTabId]?.sendTab()
        }
      }
      localStorage.setItem('settings.tabs.defaultTab', this.mainTabId)
    },

    updateSettings () {
      if (this.mainTabId === 'groups') {
        this.$refs.groupsComponent.updateGroups()
      } else if (this.mainTabId === 'credits') {
        this.$refs.creditComponent.updateCreditSettings()
      } else if (this.mainTabId === 'rfq') {
        this.$refs.rfqComponent.updateSettings()
      } else if (this.mainTabId === 'order') {
        this.$refs.orderComponent.onSave()
      }
    },

    //integrations
    onApiKeyCreateClick: function () {
      this.$refs.integrationsComponent.onCreateClick()
    },

    //users methods
    onClickSaveUser: function () {
      this.$refs.usersComponent.onClickSaveUser()
    },
    onClickAddNewUser () {
      this.$refs.usersComponent.onClickAddNewUser()
    },
    onClickCancelEditUser () {
      this.$refs.usersComponent.onClickCancelEditUser()
    },

    // business profile methods
    onClickSaveCompanyInfo: function (type = null) {
      let component = this.$refs?.[this.mainTabId]
      if (this.$refs?.[this.mainTabId]?.length) {
        component = this.$refs?.[this.mainTabId]?.[0]
      }
      if (!type) {
        component?.onClickSaveCompanyInfo()
      } else if (type === 'rfq') {
        component?.onClickSaveCompanyInfoRfq()
      } else if (type === 'credit') {
        component?.onClickSaveCompanyInfoCredit()
      } else if (type === 'integration') {
        component?.onClickSaveCompanyIntegration()
      } else if (type === 'order') {
        component?.onClickSaveCompanyOrders()
      }
    },
    onClickAddLocation: function () {
      if (this.$refs?.[this.mainTabId]?.length) {
        this.$refs?.[this.mainTabId]?.[0]?.onClickAddLocation()
      } else {
        this.$refs?.[this.mainTabId]?.onClickAddLocation()
      }
    },
  },
  components: {
    BusinessProfile,
    Integrations,
    Groups,
    Users,
    MyAccount,
    TheRfq,
    Credit,
    MultipleBrandProfile,
    TheSupplierOrders
  },
}
</script>

<style>
.theme-supplier .settings-page .settings-business-profile-tab .vc-date {
  display: none !important;
}

.add-business-btn {
  height: 38px;
  font-size: 13px;
  font-weight: bold;
  color: #29BBC1;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid white;
  background-color: white;
  white-space: nowrap;;
}
</style>
