/*eslint-disable */
import Axios from "axios";
import {
    CURRENTCOMPANY_LOAD_INFO,
    CURRENTCOMPANY_LOGOUT,
    CURRENTCOMPANY_REINSTALLATION,
    CURRENTCOMPANY_SET_TIMEZONE,
} from "../../mutation-types";
import momentTimeZone from "moment-timezone";
import cityTimeZone from "../../../components/utility/city-timezones";
import {States} from '@/store/states';
import Vue from 'vue';

export function reinstallation(context) {
    context.commit(CURRENTCOMPANY_REINSTALLATION)
}

export function loadCompanyInfo(context) {
    try {
        Axios.get('/ir/company/info')
            .then(response => {
                if (response.data && response.data.currentCompany) {
                    context.commit(CURRENTCOMPANY_LOAD_INFO, response.data.currentCompany);
                    let state = response.data.currentCompany.address.state,
                        country = response.data.currentCompany.address.country,
                        address = '';
                    address = _.find(States.addressStates, item => {
                        return item.key === state;
                    });
                    let tm = cityTimeZone.findFromCityStateProvince(address.value + " " + country),
                        timezone = '';
                    if (!_.isEmpty(tm)) {
                        timezone = tm[0].timezone;
                    }
                    let tz = momentTimeZone().tz(timezone);
                    let tzResult = tz.utcOffset() / 60
                    Vue.prototype.$http.defaults.headers.common["CompanyTimeOffset"] = tzResult;
                    context.commit(CURRENTCOMPANY_SET_TIMEZONE, tzResult);
                }
            })
            .catch(error => {
                console.log(error);
            });
    } catch (e) {
        console.log(e);
    }
};

export function saveBusinessProfile(context, payload) {
    return new Promise((resolve, reject) => {
        Axios.post('/ir/company/update/' + payload.id, payload)
            .then(response => {
                loadCompanyInfo(context);
                context.dispatch('settings/loadSettings', {}, {root: true});
                context.dispatch('currentUser/loadUserInfo', {}, {root: true})
                resolve(response);
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function addBusinessProfile(context, payload) {
    return new Promise((resolve, reject) => {
        payload = {
            ...payload,
            brand: payload.businessName,
        }
        Axios.post('/ir/company/add-brand', payload)
            .then(response => {
                // context.dispatch('settings/loadSettings', {}, {root: true});
                context.dispatch('currentUser/loadUserInfo', {}, {root: true})
                context.dispatch('users/fetchAllUsers', {}, {root: true})
                resolve(response);
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function removeCompanyLogo({commit, dispatch}) {
    return new Promise((resolve, reject) => {
        Axios.post(`/ir/company/logo/delete`)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function hideContacts(context) {
}

export function saveContacts(context) {
}

export function saveLocation(context, payload) {
}

export function removeLocations(context) {
}

export function logout({commit}) {
    return new Promise((resolve, reject) => {
        commit(CURRENTCOMPANY_LOGOUT);
        resolve();
    });
};
