<template>
    <div class="detail-section integrations-create-form">
        <div class="section-header">Create Api Key</div>

        <div class="detail-body row">

            <div class="col-md-12 user-detail-section">
                <div class="form-group row title-edit-form">
                    Use this Key in your Web Application
                </div>

                <div class="form-group row">
                    <div class="col-lg-4 ml-0 pl-0 pr-0">
                        <label for="name" class="form-label pl-0">Name</label>
                        <div class="sub-label">Create a Name for the API Key</div>
                    </div>
                    <div class="col-lg-8">
                        <input
                            type="text"
                            class="form-control"
                            v-model="newKey.name"
                            id="name"
                            name="api_key_name"
                            autocomplete="off"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-4 ml-0 pl-0 pr-0">
                        <label for="name" class="form-label pl-0">System Name</label>
                        <div class="sub-label">Select the application System name from the list</div>
                    </div>
                    <div class="col-lg-8">
                        <multiselect
                            ref="repairer-system-name-options"
                            v-model="newKey.system_name"
                            :options="systemNames"
                            :showLabels="false"
                            :allow-empty="true"
                            :option-height="32"
                            :max-height="203"
                            :searchable="false"
                            :close-on-select="true"
                            placeholder="Select System Name"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-4 ml-0 pl-0 pr-0">
                        <label for="name" class="form-label pl-0">Api Key</label>
                        <div class="sub-label">Your API key has been generated.This will be used to add to your
                            vendor to enable quote submissions to PartSearch</div>
                    </div>
                    <div class="col-lg-8">
                        <input
                            name="hash"
                            type="text"
                            class="form-control"
                            v-model="newKey.hash"
                            disabled
                            id="hash"
                            aria-disabled="true"
                        />
                    </div>
                </div>
                <div class="form-group row title-edit-form">
                    Your request for an API key will be submitted for approval, allow up to 30 mins for activation of the key be completed.
                </div>
                <div class="form-group row action-btns">
                    <button class="update-btn" @click="onCreateClick()">Create</button>
                    <button class="edit-btn" @click="onCancelEditClick()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { uuid } from 'vue-uuid';

export default {
    name: "SettingsIntegrationsCreateForm",
    components: {
        Multiselect
    },
    mounted() {
        this.$nextTick(() => {
            this.newKey = this.newGeneratedKey;
        })
    },
    props: ['newGeneratedKey'],
    methods: {
        onCancelEditClick() {
            this.$emit('on-cancel');
        },
        onCreateClick() {
            this.$emit('on-create', this.newKey);
        }
    },
    data() {
        return {
            newItem: {
                name: '',
                systemName: 'Repair-Shop',
                apiKey: uuid.v1()
            },
            systemNames: [
                'Repair-Shop',
                'PartsBridge',
            ],
            newKey: {
                system_name:'Repair-Shop',
            },
        }
    }
}
</script>

<style scoped>
    .integrations-create-form >>> .sub-label{
        font-size: 12px;
        padding-bottom: 5px;
    }

    .integrations-create-form >>> .form-label{
        padding-left: 0 !important;
        padding-bottom: 3px;
        padding-top: 8px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
    }

</style>
