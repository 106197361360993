import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    isLogout: false,
    status: '',
    token: window.location.href.includes('/partsbridge/activate') || localStorage.getItem("token") ? localStorage.getItem("token"): '',
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
