/*eslint-disable */
import {
    REPAIRER_CREDIT_IDS_UPDATE_APPROVED,
    REPAIRER_CREDIT_IDS_UPDATE_DENIED,
    REPAIRER_CREDIT_IDS_UPDATE_UNDER_REVIEW,
    REPAIRER_CREDIT_UPDATED,
    REPAIRER_CREDIT_ALL_CURRENT_PAGE,
    REPAIRER_CREDIT_UNDER_CURRENT_PAGE,
    REPAIRER_CREDIT_APPROVED_CURRENT_PAGE,
    REPAIRER_CREDIT_DENIED_CURRENT_PAGE,
    REPAIRER_CREDIT_PER_PAGE,
    REPAIRER_CREDIT_FILTER,
    REPAIRER_CREDIT_CANCELLED_CURRENT_PAGE,
} from '../../../mutation-types';
import _ from "lodash";

export default {
    [REPAIRER_CREDIT_IDS_UPDATE_UNDER_REVIEW](state, ui) {
        //Array.prototype.push.apply(state.UnderReview, ui);
        state.UnderReview = _.union(state.UnderReview, ui);
        state.Approved    = _.pullAll(state.Approved, ui);
        state.Denied      = _.pullAll(state.Denied, ui);
    },
    [REPAIRER_CREDIT_IDS_UPDATE_APPROVED](state, ui) {
        //Array.prototype.push.apply(state.Approved, ui);
        state.Approved = _.union(state.Approved, ui);
        state.UnderReview = _.pullAll(state.UnderReview, ui);
        state.Denied      = _.pullAll(state.Denied, ui);
    },
    [REPAIRER_CREDIT_IDS_UPDATE_DENIED](state, ui) {
        //Array.prototype.push.apply(state.Denied, ui);
        state.Denied = _.union(state.Denied, ui);
        state.UnderReview = _.pullAll(state.UnderReview, ui);
        state.Approved    = _.pullAll(state.Approved, ui);
    },
    [REPAIRER_CREDIT_UPDATED](state, ui) {
        state.items = _.unionBy(ui, state.items, 'id');
        if (!_.isEmpty(ui)) {
            state.creditsFromUpdate = ui;
        }
    },

    [REPAIRER_CREDIT_ALL_CURRENT_PAGE](state, ui) {
      state.creditAllCurrentPage = ui;
    },

    [REPAIRER_CREDIT_UNDER_CURRENT_PAGE](state, ui) {
      state.creditUnderCurrentPage = ui;
    },

    [REPAIRER_CREDIT_APPROVED_CURRENT_PAGE](state, ui) {
      state.creditApprovedCurrentPage = ui;
    },

    [REPAIRER_CREDIT_DENIED_CURRENT_PAGE](state, ui) {
      state.creditDeniedCurrentPage = ui;
    },

    [REPAIRER_CREDIT_CANCELLED_CURRENT_PAGE](state, ui) {
      state.creditCancelledCurrentPage = ui;
    },

    [REPAIRER_CREDIT_PER_PAGE](state, ui) {
      state.perPage = ui;
    },

    [REPAIRER_CREDIT_FILTER](state, ui) {
      state.filter = ui;
    },
};
