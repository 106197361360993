/*eslint-disable */
import {
  SETTINGS_UPDATED,
  SETTINGS_REINSTALLATION,
  SET_MULTIPLE_BRANDS,
  SETTINGS_PRICE_SELECTORS_UPDATED,
  SETTINGS_RFQ_REMOVE_PART_FROM_NIL_SUPPLY_LIST,
  SETTINGS_RFQ_ADD_PART_FROM_NIL_SUPPLY_LIST,
} from '../../mutation-types'
import _ from "lodash";

export default {
  [SETTINGS_PRICE_SELECTORS_UPDATED] (state, ui) {
    state.priceSelectors = ui
    state.isLoadedPriceSelectors = true
  },
  [SETTINGS_REINSTALLATION] (state) {
    state.settings = {
      'ordersNumberPrefix': '',
      'ordersAutosendToQps': false,
      'ordersReceivedAutosendToQps': false,
      'ordersNumberType': '',
      'ordersNumberStart': '',
        orders: {
            customerToApply: false,
            displayOrderAcceptanceTaCsDescription: null,
            displayOrderAcceptanceTaCsEnable: false,
            enableAcceptanceAgreement: false,
            enableRequireAcceptanceOfOrderTaCs: false,
            enableRequireESignature: false,
            enableRequireSigneeToAddNameOrJobPosition: false,
            sendOrderAcceptanceBy: 'Email'
        },
      multipleBrands: [],
      rfq: {
        nilSupply: {
          isEnableNilSupply: false,
          items: [],
        },
        isUnifiedRfqSettings: false,
          "partAcceptanceSettings": {
              "nonReturnableAcceptanceAgreement": false,
              "applyToAllCustomers": false,
              "displayPartAcceptanceTics": false,
              "tics": '',
              "requireAcceptanceOfOrderTics": false,
              "requireSigneeToAddNameOrJobPosition": false,
              "requireESignature": false,
              "notificationOfAcceptance": false,
              "sendOrderAcceptanceBy": '',
          },
        'statusNameTextDetection': {
          'active': false,
          'backOrderCheckboxes': null,
          'settings': {
            'ExStock': [],
            'NoStock': [],
            'inStock': [],
            'LowStock': [],
            'OnBackOrd': [],
            'NoLongerAvail': []
          }
        },
        'customResponseLevel': {
          'active': false,
          'settings': []
        },
        'responseTimeNotification': {
          'active': false,
          'settings': {
            closingSoon: {
              colour: 'red',
              timeLeft: 10,
              notificationLevel: 'Closing Soon',
              displayAs: 'Text'
            },
            closingShortly: {
              colour: 'orange',
              timeLeft: 20,
              notificationLevel: 'Closing Shortly',
              displayAs: 'Text'
            }
          }
        },
        'quoteEmail': null,
        'importQuoteOptions': {
          'importPdfQuote': false,
          'importCheck': false,
          'importCheckCsv': false,
          'importCsvQuote': false
        },
        'extraPartCode': null,
        'freightCode': null,
        'quoteValidityPeriod': {
          'active': false,
          'number': null
        },
        enablePurchasePriceSelector: false,
        'tableDisplayPinnedImages': {
          'active': false
        }
      },
      'credits': {
        'policies': {
          'enableCreditProcessingPolicy': false,
          'requirePhotosEveryCreditRequest': false,
          'applyAllCustomers': false,
          "reasonForReturnToDisplayPhoto": [],
          'creditReturnPeriod': null,
          'creditReturnPeriodNotice': null
        }
      }
    }
  },
  [SETTINGS_UPDATED] (state, ui) {
    state.settings = {
      'ordersNumberPrefix': ui?.ordersNumberPrefix ? ui.ordersNumberPrefix : '',
      'ordersAutosendToQps': ui?.ordersAutosendToQps ? true : false,
      'ordersReceivedAutosendToQps': ui?.ordersReceivedAutosendToQps ? true : false,
      'ordersNumberType': ui?.ordersNumberType ? ui.ordersNumberType : '',
      'ordersNumberStart': ui?.ordersNumberStart ? ui.ordersNumberStart : '',
        orders: {
            specialOrder: {
                customerToApply: ui?.orders?.specialOrder?.customerToApply ? true : false,
                displayOrderAcceptanceTaCsDescription: ui?.orders?.specialOrder?.displayOrderAcceptanceTaCsDescription ? ui.orders.specialOrder.displayOrderAcceptanceTaCsDescription : null,
                displayOrderAcceptanceTaCsEnable: ui?.orders?.specialOrder?.displayOrderAcceptanceTaCsEnable ? true : false,
                enableAcceptanceAgreement: ui?.orders?.specialOrder?.enableAcceptanceAgreement ? true : false,
                enableRequireAcceptanceOfOrderTaCs: ui?.orders?.specialOrder?.enableRequireAcceptanceOfOrderTaCs ? true : false,
                enableRequireESignature: ui?.orders?.specialOrder?.enableRequireESignature ? true : false,
                enableRequireSigneeToAddNameOrJobPosition: ui?.orders?.specialOrder?.enableRequireSigneeToAddNameOrJobPosition ? true : false,
                sendOrderAcceptanceBy: 'Email'
            }
        },
        multipleBrands: ui?.multipleBrands ? ui.multipleBrands : [],
      'franchises': ui?.franchises ? ui.franchises : [],
      'rfq': {
        isUnifiedRfqSettings: ui?.rfq?.isUnifiedRfqSettings,
        customResponseLevel: {
          active: ui?.rfq?.customResponseLevel?.active ? ui.rfq.customResponseLevel.active : false,
          settings: ui?.rfq?.customResponseLevel?.settings ? ui.rfq.customResponseLevel.settings : [],
        },
        responseTimeNotification: {
          active: ui?.rfq?.responseTimeNotification?.active ? ui.rfq.responseTimeNotification.active : false,
          settings: ui?.rfq?.responseTimeNotification?.settings ? ui.rfq.responseTimeNotification.settings : {
            closingSoon: {
              colour: 'red',
              timeLeft: 10,
              notificationLevel: 'Closing Soon',
              displayAs: 'Text'
            },
            closingShortly: {
              colour: 'orange',
              timeLeft: 20,
              notificationLevel: 'Closing Shortly',
              displayAs: 'Text'
            }
          },
        },
          "partAcceptanceSettings": {
              "nonReturnableAcceptanceAgreement": ui?.rfq?.partAcceptanceSettings?.nonReturnableAcceptanceAgreement ? true : false,
              "applyToAllCustomers": ui?.rfq?.partAcceptanceSettings?.applyToAllCustomers ? true : false,
              "displayPartAcceptanceTics": ui?.rfq?.partAcceptanceSettings?.displayPartAcceptanceTics ? true : false,
              "tics": ui?.rfq?.partAcceptanceSettings?.tics ? ui.rfq.partAcceptanceSettings.tics : '',
              "requireAcceptanceOfOrderTics": ui?.rfq?.partAcceptanceSettings?.requireAcceptanceOfOrderTics ? true : false,
              "requireSigneeToAddNameOrJobPosition": ui?.rfq?.partAcceptanceSettings?.requireSigneeToAddNameOrJobPosition ? true : false,
              "requireESignature": ui?.rfq?.partAcceptanceSettings?.requireESignature ? true : false,
              "notificationOfAcceptance": ui?.rfq?.partAcceptanceSettings?.notificationOfAcceptance ? true : false,
              "sendOrderAcceptanceBy": ui?.rfq?.partAcceptanceSettings?.displayPartAcceptanceTics ? ui.rfq.partAcceptanceSettings.sendOrderAcceptanceBy : 'Email',
          },
        statusNameTextDetection: {
          active: ui?.rfq?.statusNameTextDetection?.active ? ui.rfq.statusNameTextDetection.active : false,
          backOrderCheckboxes: ui?.rfq?.statusNameTextDetection?.backOrderCheckboxes ? ui.rfq.statusNameTextDetection.backOrderCheckboxes : null,
          settings: ui?.rfq?.statusNameTextDetection?.settings ? ui.rfq.statusNameTextDetection.settings : {
            'ExStock': [],
            'NoStock': [],
            'InStock': [],
            'LowStock': [],
            'OnBackOrd': [],
            'NoLongerAvail': [],
          }
        },
        notApplicable: ui?.rfq?.notApplicable ? ui.rfq.notApplicable : [],
        extraPartCode: ui?.rfq?.extraPartCode ? ui.rfq.extraPartCode : [],
        freightCode: ui?.rfq?.freightCode ? ui.rfq.freightCode : [],
        quoteEmail: ui?.rfq?.quoteEmail ? ui.rfq.quoteEmail : '',
        quoteEmailCopy: ui?.rfq?.quoteEmail ? ui.rfq.quoteEmail : '',
        enablePurchasePriceSelector: ui?.rfq?.enablePurchasePriceSelector ? true : false,
        importQuoteOptions: {
          importPdfQuote: ui?.rfq?.importQuoteOptions?.importPdfQuote ? ui.rfq.importQuoteOptions.importPdfQuote : false,
          importCheckCsv: ui?.rfq?.importQuoteOptions?.importCheckCsv ? ui.rfq.importQuoteOptions.importCheckCsv : false,
          importCheck: ui?.rfq?.importQuoteOptions?.importCheck ? ui.rfq.importQuoteOptions.importCheck : false,
          importCsvQuote: ui?.rfq?.importQuoteOptions?.importCsvQuote ? ui.rfq.importQuoteOptions.importCsvQuote : false,
        },
        quoteValidityPeriod: {
          active: ui?.rfq?.quoteValidityPeriod?.active ? ui.rfq.quoteValidityPeriod.active : false,
          number: Number(ui?.rfq?.quoteValidityPeriod?.number) ? ui.rfq.quoteValidityPeriod.number : this.quoteOpenDaysForRequest,
        },
        tableDisplayPinnedImages: {
          active: ui?.rfq?.tableDisplayPinnedImages?.active ? true : false,
        },
        nilSupply: {
          isEnableNilSupply: ui?.rfq?.nilSupply?.isEnableNilSupply ? true : false,
          items: ui?.rfq?.nilSupply?.items,
        },
        nonInStock: {
          nonInStockFormEnable: ui?.rfq?.nonInStock?.nonInStockFormEnable ? true : false,
          validationQrCode: ui?.rfq?.nonInStock?.validationQrCode ? true : false,
          isAvailableNilStock: ui?.rfq?.nonInStock?.isAvailableNilStock ? true : false,
          formStatementIntroduction: ui?.rfq?.nonInStock?.formStatementIntroduction ? ui.rfq.nonInStock.formStatementIntroduction : null,
          formStatementBody: ui?.rfq?.nonInStock?.formStatementBody ? ui.rfq.nonInStock.formStatementBody : null,
          tics: ui?.rfq?.nonInStock?.tics ? ui.rfq.nonInStock.tics : null
        },
        quoteAssignmentAutoLock: {
          quoteAutoLock: ui?.rfq?.quoteAssignmentAutoLock?.quoteAutoLock ? true : false,
          lockQuoteWhenAssigned: ui?.rfq?.quoteAssignmentAutoLock?.lockQuoteWhenAssigned ? true : false,
          lockQuoteWhenOpened: ui?.rfq?.quoteAssignmentAutoLock?.lockQuoteWhenOpened ? true : false,
          autoLockMessage: ui?.rfq?.quoteAssignmentAutoLock?.autoLockMessage ? ui.rfq.quoteAssignmentAutoLock.autoLockMessage : '',
        },
        stockStatusQuickComments: {
          'allEnabled': ui?.rfq?.stockStatusQuickComments?.allEnabled ? ui.rfq.stockStatusQuickComments.allEnabled : false,
          'all': ui?.rfq?.stockStatusQuickComments?.all ? ui.rfq.stockStatusQuickComments.all : {
            '1': [],
            '2': [],
            '3': [],
            '4': [],
            '5': [],
            '6': []
          },
          'single': ui?.rfq?.stockStatusQuickComments?.single ? ui.rfq.stockStatusQuickComments.single : {
            '1': [],
            '2': [],
            '3': [],
            '4': [],
            '5': [],
            '6': []
          },
        }
      },
      'credits': {
        'policies': {
          'enableCreditProcessingPolicy': ui?.credits?.policies?.enableCreditProcessingPolicy ? true : false,
          'requirePhotosEveryCreditRequest': ui?.credits?.policies?.requirePhotosEveryCreditRequest ? true : false,
          'applyAllCustomers': ui?.credits?.policies?.applyAllCustomers ? true : false,
          'creditReturnPeriod': ui?.credits?.policies?.creditReturnPeriod ? ui.credits.policies.creditReturnPeriod : null,
          'reasonForReturnToDisplayPhoto': ui?.credits?.policies?.reasonForReturnToDisplayPhoto ? ui.credits.policies.reasonForReturnToDisplayPhoto : null,
          'creditReturnPeriodNotice': ui?.credits?.policies?.creditReturnPeriodNotice ? ui.credits.policies.creditReturnPeriodNotice : null,
        }
      },
    }
  },
  [SET_MULTIPLE_BRANDS] (state, ui) {
    state.settings.multipleBrands = ui
  },
  [SETTINGS_RFQ_REMOVE_PART_FROM_NIL_SUPPLY_LIST] (state, part) {
    let items = state.settings.rfq.nilSupply.items
    items = _.filter(items, (itm) => {
      return Number(itm.id) !== Number(part.id)
    })
    _.forEach(state.settings.multipleBrands, (brand) => {
      brand.settings.rfq.nilSupply.items = items
    })
    state.settings.rfq.nilSupply.items = items
  },
  [SETTINGS_RFQ_ADD_PART_FROM_NIL_SUPPLY_LIST] (state, part) {
    _.forEach(state.settings.multipleBrands, (brand) => {
      brand.settings.rfq.nilSupply.items.push(part)
    })
    state.settings.rfq.nilSupply.items.push(part)
  },
}
