/*eslint-disable */
import _ from 'lodash';
import Vue from 'vue';
import {
    REPAIRER_SUPPLIER_FULL_UPDATED,
    REPAIRER_SUPPLIER_UPDATED,
    REPAIRER_SUPPLIER_REMOVED,
    REPAIRER_SUPPLIER_IDS_UPDATE_OTHER,
    REPAIRER_SUPPLIER_IDS_UPDATE_PREFERED,
    REPAIRER_SUPPLIER_IDS_UPDATE_SPECIALIST,
    REPAIRER_SUPPLIER_PER_PAGE,
    REPAIRER_SUPPLIER_FILTER,
    REPAIRER_SUPPLIER_ALL_CURRENT_PAGE,
    REPAIRER_SUPPLIER_PREFERED_CURRENT_PAGE,
    REPAIRER_SUPPLIER_FULL_UPDATED_SUPPLIER_GROUP,
    REPAIRER_SUPPLIER_TIER_CURRENT_PAGE,
    REPAIRER_SUPPLIER_PER_PAGE2,
    REPAIRER_TIER_UPDATED,
    REPAIRER_INSURER_UPDATED,
    REPAIRER_INSURER_ADD_NEW
} from '../../../mutation-types';

export default {
    [REPAIRER_SUPPLIER_PER_PAGE](state, ui) {
      state.perPage = ui;
    },
    [REPAIRER_SUPPLIER_PER_PAGE2](state, ui) {
      state.perPage2 = ui;
    },
    [REPAIRER_SUPPLIER_FILTER](state, ui) {
      state.filter = ui;
    },
    [REPAIRER_SUPPLIER_ALL_CURRENT_PAGE](state, ui) {
      state.suppliersAllCurrentPage = ui;
    },
    [REPAIRER_SUPPLIER_PREFERED_CURRENT_PAGE](state, ui) {
      state.suppliersPreferedCurrentPage = ui;
    },
    [REPAIRER_SUPPLIER_TIER_CURRENT_PAGE](state, ui) {
      state.suppliersTierCurrentPage = ui;
    },
    [REPAIRER_SUPPLIER_IDS_UPDATE_PREFERED](state, ui){
       // Array.prototype.push.apply(state.prefered.ids, ui);
        state.prefered.ids.splice(0, state.prefered.ids.length);
        let index = -1;
        _.forEach(ui,(id)=>
          {
              state.prefered.ids.push(id)
              console.log(state.prefered.ids, id, 'ids')
              index = state.specialist.ids.indexOf(id);
              if (index !== -1){
                  state.specialist.ids.splice(index, 1)
              }
               index = state.others.ids.indexOf(id);
              if (index !== -1){
                  state.others.ids.splice(index, 1)
              }

          })
        // state.prefered.ids.push = ui
        console.log(ui);
    },
    [REPAIRER_SUPPLIER_IDS_UPDATE_SPECIALIST](state, ui){
       // Array.prototype.push.apply(state.specialist.ids, ui);
       //  state.specialist.ids= ui

        return;

        // state.specialist.ids.splice(0, state.prefered.ids.length);
        // let index = -1;
        // _.forEach(ui,(id)=>
        // {
        //     state.specialist.ids.push(id)
        //     index = state.prefered.ids.indexOf(id);
        //     if (index !== -1){
        //         state.prefered.ids.splice(index, 1)
        //     }
        //     index = state.others.ids.indexOf(id);
        //     if (index !== -1){
        //         state.others.ids.splice(index, 1)
        //     }
        // })
    },
    [REPAIRER_SUPPLIER_IDS_UPDATE_OTHER](state, ui){
       // Array.prototype.push.apply(state.others.ids, ui);
       //  state.others.ids= ui
        state.others.ids.splice(0, state.prefered.ids.length);
        let index = -1;
        _.forEach(ui,(id)=>
        {
            state.others.ids.push(id)
            index = state.specialist.ids.indexOf(id);
            if (index !== -1){
                state.specialist.ids.splice(index, 1)
            }
            index = state.prefered.ids.indexOf(id);
            if (index !== -1){
                state.prefered.ids.splice(index, 1)
            }
        })
    },
    [REPAIRER_SUPPLIER_UPDATED](state, ui) {
        if (ui && ui.length > 0) {
            let data = _.union(ui, state.suppliers.items)
            let res = _.sortBy(
              _.uniqWith(data, function (first, second) {
                return (first.id != second.id) ? false : true
            }),['name']           );
            state.suppliers.items = res
        }
    },
    [REPAIRER_SUPPLIER_REMOVED](state, ui) {
        if (ui && ui.length > 0) {
            let res =  _.sortBy(_.filter(state.suppliers.items, function(itm){
                return !_.includes(ui, itm.id)
            }),['name'])
            state.suppliers.items = res
        }
    },
    [REPAIRER_TIER_UPDATED](state, ui) {
        state.tier1 = ui;
    },
    [REPAIRER_INSURER_UPDATED](state, ui) {
        state.insurers = ui;
    },
    [REPAIRER_INSURER_ADD_NEW](state, ui) {
        if (!_.includes(state.insurers, ui)) {
            state.insurers.push(ui);
        }
    },
    [REPAIRER_SUPPLIER_FULL_UPDATED](state, ui) {
        let index = _.findIndex(state.suppliers.itemsFull, {'id': ui.id});
        if (index !== -1) {
            Vue.set(state.suppliers.itemsFull,index, ui);
        } else {
            state.suppliers.itemsFull.push(ui);
        }
    },
    [REPAIRER_SUPPLIER_FULL_UPDATED_SUPPLIER_GROUP] (state, ui) {
        let index = _.findIndex(state.suppliers.itemsFull, itm => {
            return Number(itm.id) === Number(ui.id);
        });
        if (index !== -1) {
            Vue.set(state.suppliers.itemsFull[index], 'supplierGroup', ui.type);
        }
    },
};
