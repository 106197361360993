/*eslint-disable */

export function getSettings(state) {
    return state.settings;
}

export function getPriceSelectors(state) {
    return state.priceSelectors;
}

export function isLoadedPriceSelectors(state) {
    return state.isLoadedPriceSelectors;
}
export  function isMultipleLocations(state) {
    return !_.isEmpty(state?.settings?.multipleBrands)
}

export  function isUnifiedRfqSettings(state) {
    return (state?.settings?.rfq?.isUnifiedRfqSettings === true)
}

