<template>
  <div class="groups-page row d-flex">
    <div class="col-lg-4">
      <div class="group-title">
        <span style="color: black; font-weight: bold">Group Name</span>
      </div>
      <div class="">
        <ul class="group-list">
          <li v-for="group in groups" :key="group.groupId" class="group-list-item" :class="{'active': Number(selectedGroup) === Number(group.groupId)}" @click="selectedGroup = group.groupId">{{group.groupName}}</li>
        </ul>
      </div>
    </div>
    <div v-if="isHasGroups" class="col-lg-6 d-flex" style="flex-direction: column">
      <div v-if="Number(currentGroup.groupId) !== 1" style="margin-bottom: 20px;" class="d-flex">
        <div class="">
          <p class="title-of-right-column">Enable Group User Visibility</p>
          <p>Allow members of the group to see <br>each other's group Quotes and Orders.</p>
          <p>Disable to let members see only their<br/> assigned items.</p>
        </div>
        <div class="block-with-toggle">
          <div class="d-flex">
            <b-form-checkbox
                name="check-button"
                @change="$emit('updatedGroups', groupsForUpdate)"
                v-model="currentGroup.groupVisibilityEnabled"
                switch
                class="switch-checkbox"
            ></b-form-checkbox>
            <div class="status-text">
              <span v-if="currentGroup.groupVisibilityEnabled" class="form-text toggle-text">Active</span>
              <span v-else style="width: 67px" class="form-text toggle-text">Not Active</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Number(currentGroup.groupId) !== 1" style="margin-bottom: 20px;" class="d-flex">
        <div class="">
          <p class="title-of-right-column">Enable Group Location Visibility</p>
          <p>Allow members of the same location to<br> see each other's group Quotes and<br/> Orders.</p>
        </div>
        <div class="d-flex block-with-toggle">
          <b-form-checkbox
              name="check-button"
              @change="$emit('updatedGroups', groupsForUpdate)"
              v-model="currentGroup.locationVisibilityEnabled"
              switch
              class="switch-checkbox"
          ></b-form-checkbox>
          <div class="status-text">
            <span v-if="currentGroup.locationVisibilityEnabled" class="form-text toggle-text">Active</span>
            <span v-else style="width: 67px" class="form-text toggle-text">Not Active</span>
          </div>
        </div>
      </div>
      <div style="height: auto; min-height: 300px" class="">
        <div class="use-pagination-block-bottom">
          <b-table
              :items="currentGroup.members"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              hover
              responsive
          >
            <template v-slot:cell(fullName)="data">
              <div class="d-flex">
                <span class="col-12 col-sm-6" style="font-weight: bold; padding-left: 0px">{{data.item.firstName}} {{data.item.lastName}}</span>
                <span v-if="data.item.location && data.item.location.name" class="col-12 col-sm-6" style="text-align: right; color: rgba(0, 0, 0, 0.5);">
                    Business Location:
                <span style="font-weight: bold; color: #1C1F39">{{ data.item.location.name }}</span>
                  </span>
              </div>

            </template>
          </b-table>
        </div>

        <block-pagination
            :role="'Group Members'"
            :arrayOfSomeone="currentGroup.members"
            :arrayOfSomeoneFiltered="currentGroup.members"
            :currentPage="currentPage"
            @changedCurrentPage="value => currentPage = value"
            :perPage="perPage"
            @changedPerPage="value => perPage = value"
        >
        </block-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import {mapGetters} from "vuex";
import BlockPagination from '@/components/utility/block-pagination';

export default {
  name: "SettingsGroups",
  components: {
    BlockPagination,
  },
  data() {
    return {
      groupMembers: [
        {
          fullName: 'Test User',
          address: 'test address',
        },
        {
          fullName: 'Test User',
          address: 'test address',
        }
      ],
      groups: [],
      currentPage: 1,
      perPage: 10,
      selectedGroup: 1,
    }
  },
  computed: {
    ...mapGetters({
      usersAll: 'users/getAllUsers',
    }),
    isHasGroups () {
      return !_.isEmpty(this.groups)
    },
    computedGroups() {
      _.forEach(this.groups, (gr) => {
        gr.members = [];
      });
      _.forEach(this.usersAll, (user) => {
        let group = _.find(this.groups, (gr) => {
          return Number(gr.groupId) === Number(user.groupId);
        });
        if (group) {
          group.members.push(user);
        }
      });
      return this.groups;
    },
    currentGroup() {
      let group = _.find(this.computedGroups, (gr) => {
        return Number(gr.groupId) === Number(this.selectedGroup);
      })
      if (group) {
        return group
      }
      return  {
        groupVisibilityEnabled: false,
        locationVisibilityEnabled: false,
        members: [],
      }
    },
    groupsForUpdate() {
      let groups = [];
      _.forEach(this.computedGroups, (gr) => {
        groups.push({
          groupId: gr.groupId,
          groupVisibilityEnabled: gr.groupVisibilityEnabled,
          locationVisibilityEnabled: gr.locationVisibilityEnabled,
        })
      });
      return groups;
    },
    computedTitleForTable() {
      if (this.currentGroup && this.currentGroup.groupName) {
        return 'Group Members - ' + this.currentGroup.groupName;
      }
      return '';
    },
    fields() {
      return [
        {
          label: this.computedTitleForTable,
          key: 'fullName',
          tdClass: 'clickable',
        }
      ]
    },
  },
  methods: {
    updateGroups() {
      Axios.post('/ir/company/update-groups-settings', {groupsSettings: this.groups})
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success("Groups have been updated");
            } else {
              this.$toast.error("Groups have not been updated");
            }
          })
          .catch(error => {
            this.$toast.error("Groups have not been updated");
            console.log(error);
          });
    },
    loadGroups() {
      Axios.get('/ir/company/get-groups-settings')
          .then(res => {
            if (res.data && res.data._status && res.data.groupsSettings) {
              if (this.$store.getters["currentUser/isUserTypeSupplier"]) {
                this.groups = [];
                // let adminGroup = _.find(res.data.groupsSettings, (gr) => {
                //   return Number(gr.groupId) === 1;
                // });
                // if (adminGroup) {
                //   this.groups.push(adminGroup)
                // }
              } else {
                this.groups = res.data.groupsSettings;
              }
              this.$emit('updatedGroups', this.groupsForUpdate);
            }
          }).catch(err => {
            console.log(err);
      })
    },
  },
  mounted() {
    this.loadGroups();
  },
}
</script>

<style>
.groups-page .toggle-text {
  font-weight: bold;
  transform: translateX(-18px);
}
.groups-page .block-with-toggle {
  margin-left: 20px;
  width: 40px;
}
.groups-page .title-of-right-column {
  font-weight: bold;
  font-size: 14px;
}
.groups-page .group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}
.groups-page .group-list {
  list-style: none;
  padding-left: 0px;
}
.groups-page .group-list .group-list-item {
  border-bottom: 1px solid #e3e4e7;
  padding: 10px 10px 10px 15px;
  font-weight: bold;
}
.groups-page .group-list .group-list-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.groups-page .group-list .active {
  background-color: #bdd8dc !important;
}
</style>
