<template>
  <div class="page invite-repairer">
    <div class="container">
      <div class="auth-header">
        <div class="logo">
          <h1 style="cursor: pointer; width: 200px" @click="$router.push('/')" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></h1>
        </div>
      </div>
      <div class="invite-repairer-form text-center">
        <div class="intro-text">
          <div class="big-icon">
            <i class="bx bxs-check-circle" style="color:#2DCA73"></i>
          </div>
          <h1>Success</h1>
          <p>
            Your Account is being created soon you will be able to create your first Request for quote on
            <br/>parts to supplier in your local area.
          </p>
        </div>
        <div class="frame">
          Dont forget to check your inbox and confirm your email address to unlock all features
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style scoped>
.frame a,
.frame {
  font-size: 16px;
  line-height: 26px;
}

.frame {
  margin-top: 25px;
}

.intro-text {
  line-height: 26px;
}

.big-icon {
  margin: 0 auto;
  margin-bottom: 5px;
}

h1 {
  font-weight: bold;
  margin-bottom: 15px;
}
</style>
<script>
export default {
  name: 'InviteRepairerAccountCreated',
  components: {}
}
</script>
