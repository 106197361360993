/*eslint-disable */
import Axios from 'axios'
import _ from 'lodash'
import {
  REPAIRER_CREDIT_UPDATED
} from '../../../mutation-types'

// export function getCreditsByIds(context, payload) {
//     Axios.post('/ir/repairer/credits', {ids: payload})
//         .then(response => {
//             context.commit(REPAIRER_CREDIT_UPDATED, response.data.credits);
//         })
//         .catch(error => {
//             console.log(error);
//         });
// }

export function getCreditsForTableView (context, payload = 0) {
  let url = '/ir/repairer/credits/init'
  if (!_.isEmpty(payload)) url = '/ir/repairer/credits/' + payload
  Axios.post(url)
    .then(response => {
      console.log('Response data', response.data)
      context.commit(REPAIRER_CREDIT_UPDATED, response.data.credits)
    })
    .catch(error => {
      console.log(error)
    })
}

export function init (context, payload = 0) {
  return getCreditsForTableView(context, payload)
}

