<template>
    <div class="wizard" ref="wizard">
        <div class="sticky">
            <ul class="wizard__steps">
                <li class="wizard__step"
                    :class="{
                             'active':  currentStep >= index,

                             'current': currentStep === index,
                            }"
                    :style="wizardStepStyle"
                    v-for="(step, index) of steps" :key="index">
                    <span class="wizard__step__line" ></span>
<!--                    <span class="wizard__step__indicator"><i class='bx bx-check'></i></span>-->
                    <span class="wizard__step__label">
                    <div>{{step.label}}</div>
                </span>
                </li>
            </ul>
            <div class="wizard__divider">
            </div>
        </div>
        <div ref="wizard-body" class="wizard__body" >
            <div :key="currentSlot" class="wizard__body__step">
                <div class="d-flex flex-row" v-if="isAdvert && !isMobile">
                    <div :class="currentClass">
                        <slot :name="currentSlot"></slot>
                        <slot name="WizardButtons"></slot>
                    </div>
                </div>
                <div style="" v-else>
                    <div :class="currentClass">
                        <slot :name="currentSlot"></slot>
                        <slot name="WizardButtons"></slot>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    /*eslint no-param-reassign:0*/

    export default {

        name: 'vue-good-wizard',

        props: {
            steps: {},
            previousStepLabel: {default: 'Back'},
            nextStepLabel: {default: 'Next'},
            finalStepLabel: {default: 'Save'},
            onNext: {},
            onBack: {},
            isMobileOn: {default: true},
            step: {default: 1},
        },

        watch: {
            steps: {
                handler() {
                    this.parseOptions();
                },
                immediate: true,
            }
        },

        data() {
            return {
                currentStep: this.step-1,
                isMounted: false,
                resizer: null,
                isMobile: false,
                options: [],
            };
        },
        computed: {
            wizardStepStyle() {
                //if (this.isMobile) {
                //    return {
                //        width: '100%',
                //    };
                //}

                return {
                    width: `${100 / this.steps.length}%`,
                };
            },
            mobileArrowPosition() {
                return 'calc(50% - 14px)';
            },
            arrowPosition() {
                if (this.isMobile) {
                    return this.mobileArrowPosition;
                }
                var stepSize = 100 / this.steps.length;
                var currentStepStart = stepSize * this.currentStep;
                var currentStepMiddle = currentStepStart + (stepSize / 2);
                if (this.steps.length == 1)
                    return 'calc(' + currentStepMiddle + '%)';
                else
                    return 'calc(' + currentStepMiddle + '% - 14px)';
            },
            currentSlot() {
                return this.steps[this.currentStep].slot;
            },
            currentClass() {
                return this.steps[this.currentStep].class;
            },
            isAdvert() {
                if (this.steps[this.currentStep].isAdvert) {
                    return true;
                }
                return false;
            },
            backEnabled() {
                return this.currentStep != 0;
            },
            getCurrentStep() {
                return this.currentStep;
            }
        },
        methods: {
            goNext(skipFunction) {
                if (!skipFunction && typeof this.onNext == 'function') {
                    if (!this.onNext(this.currentStep)) {
                        //returned false. don't do anything
                        return;
                    }
                }
                if (this.currentStep < this.steps.length - 1) {
                    this.currentStep++;
                }
                window.scrollTo(0, 0);
            },
            goBack(skipFunction) {
                if (!skipFunction && typeof this.onBack == 'function') {
                    if (!this.onBack(this.currentStep)) {
                        //returned false. don't do anything
                        return;
                    }
                }
                if (this.currentStep > 0) {
                    this.currentStep--;
                }
                window.scrollTo(0, 0);
            },

            goTo(step) {
                if (Number.isInteger(step)
                    && step < this.steps.length
                    && step >= 0) {
                    this.currentStep = step;
                }
            },

            parseOptions() {
                this.options = [];
                for (let i = 0; i < this.steps.length; i++) {
                    this.options.push(this.steps[i].options ? this.steps[i].options : {});
                }
            },

            handleResize() {

                if (this.resizer) {
                    clearTimeout(this.resizer);
                }
                let param = 620;
                if (!$('body').hasClass('compact-sidebar')) {
                    param = param - 220;
                }
                this.resizer = setTimeout(() => {
                    // console.log('resizing...');
                    //this.isMobile = this.$refs['wizard-body'].clientWidth < 620;
                    this.isMobile = (this.$el.clientWidth < param) && (this.isMobileOn);
                }, 100);
            },
        },
        mounted() {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
    };
</script>

<style scoped>
    .wizard {
        position: relative;
        width: 100%;
    }

    .V3 .wizard .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 135px;
        z-index: 1000;
        background-color: #FFFFFF;
        height: 84px;
    }
</style>
