import {
    REPAIRER_MARK_MESSAGE_AS_READ,
    REPAIRER_SET_MESSAGES,
    REPAIRER_UPDATE_MESSAGES,
    REPAIRER_UPDATE_FILES,
    REPAIRER_UPDATE_ACTIVITY,
    REPAIRER_SET_ACTIVITY
} from "../../../mutation-types";
import _ from "lodash";

export default {
    [REPAIRER_SET_MESSAGES] (state, ui) {
        state.messages = ui
    },
    [REPAIRER_UPDATE_FILES] (state, ui) {
        state.filesUpdater = ui
    },
    [REPAIRER_SET_ACTIVITY] (state, ui) {
        state.activityList = ui
    },

    [REPAIRER_UPDATE_ACTIVITY] (state, ui) {
        let activity = ui.activity

        if (ui.type == 'newActivityRecord') {
            let index = _.findIndex(state.activityList, (a) => {
                return Number(a.id) === Number(activity.id)
            })
            if (index === -1) {
                state.activityList.push(activity)
            }
        } else if (ui.type == 'updateActivityRecord') {
            let index = _.findIndex(state.activityList, (a) => {
                return Number(a.id) === Number(ui?.id?.id)
            })
            if (index != -1) {
                state.activityList.splice(index, 1, ui?.id)
            }
        } else if (ui.type == 'removeActivityRecord') {
            let index = _.findIndex(state.activityList, (a) => {
                return Number(a.id) === Number(ui.id)
            })
            if (index != -1) {
                state.activityList.splice(index, 1)
            }
        }
    },
    [REPAIRER_UPDATE_MESSAGES] (state, ui) {
        let index = _.findIndex(state.messages, (m) => {
            return (m.rfqId &&  ui.rfqId && Number(m.rfqId) === Number(ui.rfqId)) ||
                (m.orderId &&  ui.orderId && Number(m.orderId) === Number(ui.orderId)) ||
                (m.creditId &&  ui.creditId && Number(m.creditId) === Number(ui.creditId))
        })
        console.log(ui, index, state.messages, index !== -1)
        if (index !== -1) {
            state.messages.splice(index, 1)
        }
        state.messages.push(ui)
    },
    [REPAIRER_MARK_MESSAGE_AS_READ](state, ui) {
        let index = _.findIndex(state.messages, (m) => {
            return Number(m.id) === Number(ui)
        })
        if (index !== -1) {
            state.messages[index].viewedOn = '9999'
        }
    },
}
