<template>
    <div>
      <highlightable-input-component
          :class="[{'text-input':!isTextHasWord() && !isTextHasChar(), 'has-error-highlightable-input':isTextHasWord() || isTextHasChar()}, computedCustomClass]"
          :highlight-style="highlightStyle"
          :highlight-enabled="highlightEnabled"
          :highlight="highlight"
          ref="inputText"
          v-model="text"
          @input="onInput"
      />
      <div class="text-danger"
           :class="{'has-text': isTextHasWord() || isTextHasChar()}">
        <template v-if="isTextHasWord()">
          This word cannot be used in the comment
        </template>
        <template v-else-if="isTextHasChar()">
          This character cannot be used in the comment
        </template>
      </div>
      <button class="text-send"><i v-if="isShowSendIcon" @click="$emit('onIconClick')" style="transform: translateY(11px)" class='bx bxs-right-arrow-square'></i></button>
    </div>
</template>

<script>

import HighlightableInputComponent from "vue-highlightable-input"

export default {
    name: "HighlightableInput",
    components: {
        HighlightableInputComponent
    },
    props: {
       isShowSendIcon: {
         type: Boolean,
         default: false
       },
        value: {
            type: String,
            default: ''
        },
        limit: {
          type: Number,
          default: -1,
        },
        customClass: {
          type: String,
          default: '',
        }
    },
    data() {
        return {
            text: '',
            highlight: [
                'whatever',
                '$',
                'dollar',
                'dollars',
                '00.00',
                '0.00',
                'price match',
                'price beat',
                'percentage',
                'discount',
                '%',
                'competition',
                'reduction',
                'pricing',
                'rebate',
            ],
            highlightStyle: 'background-color: #FFD5D0; border-bottom: 1px solid #FF0000;',
            highlightEnabled: true
        }
    },
    mounted() {
        this.text = this.value;

        if (this.limit !== -1) {
          this.$refs.inputText.saveSelection = function (containerEl){
            var start;
            if (window.getSelection && document.createRange) {
              var selection = window.getSelection();
              if (!selection || selection.rangeCount == 0)
                return
              var range = selection.getRangeAt(0);
              var preSelectionRange = range.cloneRange();
              preSelectionRange.selectNodeContents(containerEl);
              preSelectionRange.setEnd(range.startContainer, range.startOffset);
              start = preSelectionRange.toString().length;

              return {
                start: start > 50 ? 50 : start,
                end: start + range.toString().length > 50 ? 50 : start + range.toString().length
              }
            } else if (document.selection) {
              var selectedTextRange = document.selection.createRange();
              var preSelectionTextRange = document.body.createTextRange();
              preSelectionTextRange.moveToElementText(containerEl);
              preSelectionTextRange.setEndPoint("EndToStart", selectedTextRange);
              start = preSelectionTextRange.text.length;

              return {
                start: start > 50 ? 50 : start,
                end: start + selectedTextRange.text.length > 50 ? 50 : start + selectedTextRange.text.length
              }
            }
          };
        }

      if (this.$refs.inputText?.$el) {
        this.$refs.inputText.$el.addEventListener('keydown', (e) => {
          if(e.keyCode === 13) {
            e.preventDefault();
            this.onInputEnter(e)
          }
        })
      }
    },
    computed: {
      computedCustomClass() {
        if (this.customClass) {
          return this.customClass;
        }
        return '';
      }
    },
    methods: {
      onInput(e){
        this.$emit('input', e);
      },
      onInputEnter(e){
        this.$emit('onEnter', e);
      },
      focus() {
        if (this.$refs.inputText?.$el) {
          this.$refs.inputText.$el.focus()
        }
      },
        indexes(source, find) {
            if (!source) {
                return [];
            }
            if (!find) {
                return source.split('').map(function (_, i) {
                    return i;
                });
            }
            source = source.toLowerCase();
            find = find.toLowerCase();
            let result = [];
            let i = 0;
            while (i < source.length) {
                if (source.substring(i, i + find.length) === find)
                    result.push(i++);
                else
                    i++;
            }
            return result;
        },
        findHighlights(text = '') {
            let result = {},
                positions = 0;
            _.forEach(this.highlight, (item) => {
                positions = this.indexes(text, item);
                if(!_.isEmpty(positions)){
                    _.forEach(positions, pos => {
                        result[pos] = item;
                    });
                }
            });
            return result;
        },
        isHighlight(condition = hl => hl.length > 1) {
            let highlights = this.findHighlights(this.text),
                result = false,
                lastFound = '';
            if (!_.isEmpty(highlights)) {
                let keys = _.map(Object.keys(highlights), item => {
                    return Number(item);
                });
                lastFound = _.max(keys);
                result = condition(highlights[lastFound]);
            }
            this.$emit('onHighlight', !_.isEmpty(highlights));
            return result;
        },
        isTextHasWord() {
            return this.isHighlight();
        },
        isTextHasChar() {
            return this.isHighlight(hl => hl.length === 1);
        }
    },
    watch: {
      value : {
        handler: function (val) {
          this.text = val;
        },
        immediate: true
      },
       text: {
          handler: function (val) {
            if (this.limit !== -1) {
              this.$nextTick(() => {
                this.$set(this, 'text', val.substr(0, this.limit))
              })
            }
            this.$emit('input', this.text);
          },
          immediate: true
        }
    },
}
</script>

<style scoped>
.text-input {
    height: 90%;
    word-break: break-all;
}
.text-danger.has-text {
    padding: 5px;
    border: 1px solid #DDDAD6;
    margin-top: 1px;
    border-radius: 3px;
}
.text-typing{
  display: flex;
  font-size: 13px;
  margin: 5px;
  color: grey;
}
.text-typing-dot{
  background-color: grey;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin: 9px 2px 0;
  align-items: flex-end;
}
.text-typing-one-dot{
  margin-left: 3px;
  -webkit-animation: pulsing1 2s infinite;
  animation: pulsing1 2s infinite;
}
.text-typing-two-dot{
  -webkit-animation: pulsing2 2s infinite;
  animation: pulsing2 2s infinite;
  animation-delay: 0.5s;
}
.text-typing-three-dot{
  -webkit-animation: pulsing3 2s infinite;
  animation: pulsing3 2s infinite;
  animation-delay: 1s;
}
@-webkit-keyframes pulsing1 {
  0% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7)
  }
  100% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}
@keyframes pulsing1 {
  0% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7)
  }
  100% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}

@-webkit-keyframes pulsing2 {
  0% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7)
  }
  100% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}
@keyframes pulsing2 {
  0% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7)
  }
  100% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}

@-webkit-keyframes pulsing3 {
  0% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7)
  }
  100% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}
@keyframes pulsing3 {
  0% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7)
  }
  100% {
    -webkit-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}
.text-send{
  position: absolute;
  right: 0;
  bottom: 15px;
  border: 0;
  background-color: rgba(255, 255, 255, 0);
}
.bxs-right-arrow-square{
  font-size: 30px;
  color: #29babf;
}

</style>
