<template>
<div style="text-align: center; margin-top: 50px">
  <button @click="openScanModal" style="margin-left: 10px;" class="btn btn-primary primary-save-btn">Scan</button>
  <b-modal ref="notifySupplier" id="mark-as-returned-modal" size="custom-mark-as-returned" @hide="decodedResult = null; decodedText = null"
           title="Scan">
    <template v-slot:modal-backdrop class="my-backdrop"></template>

    <div class="modal-mark-as-returned form-container" style="margin-bottom: 15px;">
      <qr-code-scanner
          :qrbox="250"
          :fps="10"
          style="width: auto;"
          @result="onScan"
      ></qr-code-scanner>
      <div v-if="decodedResult || decodedText" class="notify-supplier-and-parts-block">
        <div class="notify-supplier-item">{{decodedText}}</div>
        <div class="notify-supplier-item">{{decodedResult}}</div>
      </div>
    </div>
    <template v-slot:modal-footer="{ confirm, cancel }">
      <b-button @click="closeScanModal" class="input-btn cancel mr-2">Cancel</b-button>
      <b-button @click="closeScanModal" class="input-btn">Confirm</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import QrCodeScanner from '../../components/qrcode-scanner'
import ScannerSound from '../../assets/audio/scanner-sound.mp3'
export default {
  name: "scanner-page",
  components: {
    QrCodeScanner
  },
  data () {
    return {
      decodedResult: null,
      decodedText: null,
    }
  },
  methods: {
    onScan (decodedText, decodedResult) {
      let audio = new Audio(ScannerSound);
      let interval = setInterval(() => {
        audio.play()
            .then(() => {
              clearInterval(interval)
            })
            .catch(e => {
              // console.log(e, 'e')
            })
      }, 0)
      this.decodedText = decodedText;
      this.decodedResult = decodedResult;
      console.log(decodedResult, decodedText)
    },

    closeScanModal() {
      this.$refs.notifySupplier.hide()
      this.decodedText = null;
      this.decodedResult = null;
    },
    openScanModal() {
      this.$refs.notifySupplier.show()
    },
  }
}
</script>

<style>

#html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-start,
#html5-qrcode-button-file-selection,
#html5-qrcode-button-camera-permission {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
  cursor: pointer;
  font-weight: bold;
  background: #29bbc1;
  color: white;
  line-height: 18px;
  box-sizing: border-box;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
}

.notify-supplier-and-parts-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid silver;
  width: 470px;
  border-top: none;
  padding: 16px;
}

.notify-supplier-and-parts-block .notify-supplier-item {
  border: 1px solid silver;
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 11px;
  font-weight: bold;
}

.notify-supplier-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: white !important;
  border-color: #79B1BB !important;
  cursor: pointer;
  font-weight: bold;
  background: white;
  color: white;
  line-height: 18px;
  box-sizing: border-box;
  border: 2px solid #79B1BB !important;
  border-radius: 3px;
}

#html5-qrcode-anchor-scan-type-change {
  color: #1C1F39;
  line-height: 38px;
  font-size: 1rem !important;
  font-weight: bold;
}

#qr-code-full-region div:first-child img {
  display: none;
}

#html5-qrcode-button-camera-stop:hover,
#html5-qrcode-button-camera-start:hover,
#html5-qrcode-button-file-selection:hover,
#html5-qrcode-button-camera-permission:hover {
  color: #1C1F39;
}

@media (max-width: 785px) {
  .repairer-credit-page .tab-header.rs-btn-group.float-sm-right.mt-1-sm.pr-3.d-flex.align-items-center {
    flex-direction: column !important;
    height: auto !important;
    justify-content: start !important;
    float: left !important;
    align-items: start !important;
    margin-left: 15px !important;
    padding-right: 25px !important;
    margin-top: 10px !important;
  }

  .repairer-credit-page .tab-header.rs-btn-group.float-sm-right.mt-1-sm.pr-3.d-flex.align-items-center .dropdown-options {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 767px) {
  .repairer-credit-page .tab-header.rs-btn-group.float-sm-right.mt-1-sm.pr-3.d-flex.align-items-center .dropdown-options .dropdown-menu.show {
    transform: translate3d(0px, 38px, 0px) !important;
  }
}

@media (max-width: 905px) {
  .tabs-component .tab-header.float-sm-right div.tabs-nav-right-search {
    min-width: 300px !important;
  }
  .theme-repairer .repairer-parts-orders-body .dropdown-options {
    margin-right: 0px !important;
  }
}

.notify-supplier-and-parts-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid silver;
  width: auto;
  border-top: none;
  padding: 16px;
}

.notify-supplier-and-parts-block .notify-supplier-item {
  border: 1px solid silver;
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 11px;
  font-weight: bold;
}
</style>
