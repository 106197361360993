import { render, staticRenderFns } from "./parts-credit-return-percentage.vue?vue&type=template&id=634f2bf1&scoped=true"
import script from "./parts-credit-return-percentage.vue?vue&type=script&lang=js"
export * from "./parts-credit-return-percentage.vue?vue&type=script&lang=js"
import style0 from "./parts-credit-return-percentage.vue?vue&type=style&index=0&id=634f2bf1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634f2bf1",
  null
  
)

export default component.exports