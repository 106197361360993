<script>
/*eslint-disable */
import NProgress from "nprogress";

export default {
  name: "ForgotPassUserDataPageCcnsw",
  data() {
    return {
      userEmail: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      companyName: ""
    };
  },
  methods: {
    login: function() {
      const username = this.username;
      const password = this.password;
      NProgress.start();
      this.$store
          .dispatch("auth/login", { username, password })
          .then(() => {
            this.$store.dispatch("auth/successLogin");
            NProgress.done();
            this.$router.push("/home");
          })
          .catch(error => {
            NProgress.done();
          });
    },
    goToHome() {
      document.location.replace('/login');
      // this.$router.push('/');
    },
  }
};
</script>

<template>
  <div class="container-fluid page">
    <div class="content">
      <div class="background column">
        <div style="width: 694px"></div>
      </div>
      <div class="form-container column">
        <div class="form" style="bottom: 70px">
          <div>
            <div class="logo">
            </div>
          </div>
          <form method="POST" @submit.prevent="login">
            <div>
              <img src="@/assets/logos/accident-repair-centres-logo.jpg" class="accident-logo"/>
              <span class="close" @click.prevent="goToHome">×</span>
            </div>
            <h2 class="header-text">Forgot your Password?</h2>
            <p>Get Help by providing us with more information about your account and one of our team members will contact you.</p>
            <br />
            <p>Enter the Email Address That Was Registered With Your Account</p>
            <div class="form-group">
              <input
                  type="email"
                  class="input-field error"
                  placeholder="Enter your email address"
                  name="userEmail"
                  v-model="userEmail"
              />
              <div class="w-100 text-right">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;We could not find an account with that email address.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="firstname">First Name</label>
              <input
                  type="email"
                  class="input-field error"
                  placeholder="Your First Name"
                  name="firstname"
                  v-model="firstName"
              />
              <div class="w-100 text-right">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input
                  type="text"
                  class="input-field error"
                  placeholder="Your Last Name"
                  name="lastName"
                  v-model="lastName"
              />
              <div class="w-100 text-right" v-show="false">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="contactNumber">Contact Number</label>
              <input
                  type="text"
                  class="input-field error"
                  placeholder="Your Contact Number"
                  name="contactNumber"
                  v-model="contactNumber"
              />
              <div class="w-100 text-right" v-show="false">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="companyName">Company Name The Account is Registered With</label>
              <input
                  type="text"
                  class="input-field error"
                  placeholder="Company Name"
                  name="companyName"
                  v-model="companyName"
              />
              <div class="w-100 text-right" v-show="false">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn input-btn">Submit to Support</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../../../styles/login/style.css";
</style>

<style scoped>
.close{
  cursor: pointer;
}
.input-btn{
  background-color: #00529C;
}
.input-btn:hover{
  background-color: #013c72;
  color: white;
}
.input-btn:disabled:hover{
  background-color: #ccc;
}
.accident-logo{
  height: 100px;
  margin-bottom: 70px;
}
.page {
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .page .content {
    height: 100%;
  }
  .page .content .form-container {
    height: 100%;
  }
  .page .form {
    height: 100%;
  }
  .page .form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>