<template>
    <div class="timer">
        <div v-if="dateDue" class="timer__row font-13 mb-5">
            <span class="color-lightgray">Request Required By :</span>
            <span>{{ dateDue }}</span>
        </div>
        <div class="timer__row mb-5">
            <span class="color-lightgray">Open Time Remaining</span>
            <span class="color-lightgray">:</span>

            <vue-countdown :interval="1000*60"
                           :emitEvents="false"
                           :time="currentCountdown*1000"
                           v-if="currentCountdown > 0">
                <template slot-scope="props">{{ props.days }} D - {{ props.hours }} Hrs - {{ props.minutes }} Mins</template>
            </vue-countdown>
            <span v-if="currentCountdown == '0'">Not Started</span>
            <span v-if="currentCountdown == '-1'">Finished</span>
        </div>
        <div v-if="isShowOpenDays" class="timer__row mb-5">
          <span style="width: 115px" class="color-lightgray">Quote Open Days</span>
          <span>:</span>
          <span>{{ openDays }}</span>
        </div>

    </div>
</template>

<script>
    import VueCountdown from '@chenfengyuan/vue-countdown';
    import moment from 'moment/moment';
    // import dayjs from 'dayjs';

    export default {
        name: 'RepaireRfqTimer',
        components: {
            VueCountdown,
        },
        props: {
            isShowOpenDays: {
              default: false,
              type: Boolean,
            },
            openDays: {
              type: Number,
              default: 0,
            },
            countdown: {default: 0},
            dateDueProp: {default: 0},
        },
        data() {
            return {
                acceptRequest: false,
                rejectRequest: false,
            }
        },
        computed: {
            currentCountdown() {
                return this.countdown;
            },
            dateDue(){
              return this.dateDueProp ? moment(this.dateDueProp).format('hh:mmA - DD/MM/YYYY') : '';
            }
        },
        methods: {
        }
    }
</script>