<template>
  <b-form-input
      ref="limitedInput"
      @change="onChange"
      @keypress="isNumber($event)"
      @keypress.enter="onEnter"
      v-model="newValue"
      :disabled="isDisabled"
      type="text"
      class="form-control">
  </b-form-input>
</template>

<script>
export default {
  name: "limitedInput",
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isConversely: {
      type: Boolean,
      default: false,
    },
    value: null,
    notAvailableValue: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      oldValue: '',
      newValue: '',
    }
  },
  methods: {
    focus() {
      this.$refs.limitedInput.focus();
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onEnter () {
      setTimeout(() => {
        if (this.isConversely && this.notAvailableValue.includes(this.newValue)) {
          this.$emit('update', this.newValue);
          this.$emit('onEnter')
        } else if (!this.isConversely) {
          this.$emit('update', this.newValue);
          this.$emit('onEnter')
        }
      }, 100)
    },
    onChange() {
      console.log(this.newValue, 'onCHANGE', this.notAvailableValue.includes(this.newValue))
      if (this.isConversely) {
        if (!this.notAvailableValue.includes(this.newValue)) {
          this.newValue = this.oldValue;
          this.$emit('update', this.newValue);
        }
        if (this.newValue !== this.oldValue) {
          this.$emit('update', this.newValue);
        }
      } else {
        if (this.notAvailableValue.includes(this.newValue)) {
          this.newValue = this.oldValue;
          this.$toast.error('This value is already in use')
          this.$emit('update', this.newValue);
        }
        if (this.newValue !== this.oldValue) {
          this.$emit('update', this.newValue);
        }
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        console.log('val')
        this.newValue = val;
        this.oldValue = val;
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
