<template>
  <div class="" style="display: flex; flex-direction: column;">
    <slot/>
    <div class="block-message">
      {{ message.message }}
    </div>
    <div class="message-time">{{message.senderName}} {{ message.createdOn | formatDatetime }}</div>
  </div>
</template>

<script>
export default {
  name: "chat-message",
  props: {
    message: null,
  }
}
</script>

<style scoped>
.block-message{
  width: 70%;
  border: 2px solid rgb(20, 178, 185);
  margin-bottom: 3px;
  background-color: #14b3ba;
  border-radius: 5px;
  color: #f1f9fa;
  padding: 10px;
}
.my .block-message{
  width: 70%;
  background-color: #fdfdfd;
  color: #3f3f3f;
  margin-bottom: 3px;
  border-radius: 5px;
  border: 2px solid rgba(27,30,56,0.12);
  align-self: flex-end;
  padding: 10px;
}
.message-time{
  font-size: 11px;
  margin-bottom: 13px;
  padding-left: 0%;
}
.my .message-time{
  padding-left: 0%;
  text-align: right;
}
</style>
