<template>
    <div class="purchase-detail">
        <div class="purchase-detail__body">
            <h2 class="font-16 purchase-detail__title">{{ title }}<br/> Purchase</h2>
            <div class="purchase-detail__col2">
                <div :class="{'oem-color': isOEMParts}" class="row purchase-detail__item">
                    <div :class="{'oem-purchase-detail__label': isOEMParts}" class="col px-0 font-16 purchase-detail__label">Your Profit</div>
                    <div :class="{'color-green': isAvailableOrderParts}" class="col px-0 font-18">{{ profit | formatMoney }}</div>
                </div>
                <div class="row purchase-detail__item">
                  <div class="col px-0 font-12 purchase-detail__label">Sell Price</div>
                  <div class="col px-0 font-12">{{ sellPrice | formatMoney }}</div>
                </div>
                <div class="row purchase-detail__item">
                    <div class="col px-0 font-12 purchase-detail__label">Buy Price</div>
                    <div class="col px-0 font-12">{{ buyPrice | formatMoney }}</div>
                </div>
                <div class="row purchase-detail__item">
                    <div class="col px-0 font-12 purchase-detail__label">Mark Up</div>
                    <div class="col px-0 font-12">{{ markUp | percentage(2) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'purchase-detail',
    props: [
        'title',
        'buyPrice',
        'sellPrice',
        'profit',
        'markUp',
        'isOEMParts',
        'isAvailableOrderParts',
    ]
}
</script>

<!--<style lang="scss">-->
<!--    @import "../styles/style-views.scss";-->
<!--</style>-->
<style lang="scss">
    .color-green {
      color: #17ae58;
    }
    .oem-color {
      color: #17a2ac;
    }
    .oem-purchase-detail__label {
      opacity: 1 !important;
    }
    .purchase-detail {
        position: relative;
        border: 1px solid rgba(27,30,56,.1);
        border-radius: 3px 3px 0 0;
        &:not(:first-child) {
            top: -1px;
            border-top: 0px;
            border-radius: 0 0 3px 3px;
        }

        &__body {
            padding: 1rem;
        }
        &__title {
            margin-bottom: 15px;
        }
        &__item {
            margin-bottom: 15px;
        }
        &__label {
            opacity: 0.5;
        }
    }
</style>
