/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    // UnderReview: [],
    // Denied: [],
    // Approved: [],
    perPage: 200,
    filter: '',
    creditsFromUpdate: [],
    creditAllCurrentPage: 1,
    creditDeniedCurrentPage: 1,
    creditApprovedCurrentPage: 1,
    creditUnderCurrentPage: 1,
    creditCancelledCurrentPage: 1,
    lastTimeCheck: {
        items: 0,
        // UnderReview: 0,
        // Approved: 0,
        // Denied: 0,
    },
    isNeedUpdate: false,
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
