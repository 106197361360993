/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    filter: '',
    token: [],
    tokens: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
    },
    searchFilterObj: {
        search: '',
    },
    ordersFromUpdate: [],
    currentPageCurrent: 1,
    currentPageCompleted: 1,
    currentPageBackOrder: 1,
    perPage: 20,
    currentOrderId: null,
    isNeedUpdate: false,
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
