import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
  rfqs: {
    items: [],
    filter: {}
  },
  filter: '',
  perPage: 100,
  customersAllCurrentPage: 1,
  customersPreferedCurrentPage: 1,
  items: [],
}

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  getters,
};
