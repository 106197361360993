import {AUTH, AUTH_LOGIN_FAILD, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS} from '../../mutation-types';

export default {
    /**
     * @param { AuthState } state
     * @param { string } data
     */
    [AUTH](state, {data}) {
        state.data = data;
    },

    [AUTH_LOGIN_REQUEST](state) {
        state.status = 'loading';
    },
    [AUTH_LOGIN_SUCCESS](state, token) {
        state.status = 'success';
        state.token = token;
    },
    [AUTH_LOGIN_FAILD](state) {
        state.status = 'error';
    },
    [AUTH_LOGOUT_SUCCESS](state) {
        state.isLogout = true;
        state.status = '';
        state.token = '';
    },
};
