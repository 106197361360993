/*eslint-disable */
import camelCase from 'lodash/camelCase';

const requireModule = require.context(
    '.',
    true,
    /^(?!.*(actions|mutations|getters|index|supplier)).*\.js$/
);

const modules = {};

requireModule.keys().forEach(fileName => {
    modules[camelCase(fileName.split('/')[1].replace(/(\.\/|\.js)/g, ''))] = {
        namespaced: true,
        ...requireModule(fileName).default
    };
});

const mutations = {};
const actions = {
    init(context) {
        console.log('init supplier');
        context.dispatch('partList/loadPartList', {}, { root: true });
        context.dispatch('supplier/rfq/init', {}, {root: true});
        context.dispatch('supplier/order/init', {}, {root: true});
        context.dispatch('supplier/credit/init', {}, {root: true});
        context.dispatch('supplier/customers/init', {}, {root: true});
        context.dispatch('brandList/loadBrandList', {}, { root: true });
        context.dispatch('supplier/dashboard/init', {}, { root: true });
    },
    shortReinstallation(context) {
        context.dispatch('supplier/rfq/reinstallation', {}, {root: true});
        context.dispatch('supplier/order/reinstallation', {}, {root: true});
        context.dispatch('supplier/credit/reinstallation', {}, {root: true});
        context.dispatch('supplier/customers/reinstallation', {}, {root: true});
        context.dispatch('supplier/dashboard/reinstallation', {}, { root: true });
        context.dispatch('supplier/dashboard/init', {}, { root: true });
        context.dispatch('supplier/rfq/init', {}, {root: true});
        context.dispatch('supplier/order/init', {}, {root: true});
        context.dispatch('supplier/credit/init', {}, {root: true});
        context.dispatch('supplier/customers/init', {}, {root: true});
    },
    shortUpdating(context) {
        context.dispatch('supplier/rfq/shortUpdating', {}, {root: true});
        context.dispatch('supplier/order/shortUpdating', {}, {root: true});
        context.dispatch('supplier/credit/shortUpdating', {}, {root: true});
        context.dispatch('supplier/customers/shortUpdating', {}, {root: true});
    },
    reinstallation(context) {
        context.dispatch('partList/reinstallation', {}, { root: true });
        context.dispatch('supplier/rfq/reinstallation', {}, {root: true});
        context.dispatch('supplier/order/reinstallation', {}, {root: true});
        context.dispatch('supplier/credit/reinstallation', {}, {root: true});
        context.dispatch('supplier/customers/reinstallation', {}, {root: true});
        context.dispatch('brandList/reinstallation', {}, { root: true });
        context.dispatch('supplier/dashboard/reinstallation', {}, { root: true });
    }
};
const state = {};
const getters = {};

export default {
    namespaced: true,
    modules: modules,
    actions: actions,
    state: state,
    getters: getters,
    matations: mutations,
};
