/*eslint-disable */
import {
  CURRENTCOMPANY_LOAD_INFO,
  CURRENTCOMPANY_LOGOUT,
  CURRENTCOMPANY_CHANGE_RFQ_TIME_NOTIFICATION,
  CURRENTCOMPANY_CHANGE_RFQ_IMPORT_QUOTE_OPTIONS,
  CURRENTCOMPANY_REINSTALLATION,
  CURRENTCOMPANY_SET_BUSINESS_NAME,
  CURRENTCOMPANY_SET_TIMEZONE
} from '../../mutation-types'

export default {
  [CURRENTCOMPANY_SET_TIMEZONE] (state, ui) {
    state.timezone = ui
  },
  [CURRENTCOMPANY_REINSTALLATION] (state) {
    state.isCCNSW = false
        state.id = 0
    state.name = ''
    state.businessName = ''
    state.isCCNSW = false;
        state.abn = ''
    state.acn = ''
    state.phone = ''
    state.fax = ''
    state.displayId = null
    state.email = ''
    state.emailOrders = '';
        state.emailCredits = '';
        state.about = ''
    state.supplierType = ''
    state.supplierTypes = []
    state.supplierVehicleCategory = 'CAR'
    state.logoImgPath = ''
    state.settings = {}
    state.address = {
      address: '',
      unitLevelLot: '',
      street: '',
      suburb: '',
      postcode: '',
      state: '',
      country: ''
    }
    state.primaryBusinessAddress = {
      address: '',
      unitLevelLot: '',
      street: '',
      suburb: '',
      postcode: '',
      state: '',
      country: ''
    }
    state.locations = []
    state.contacts = {
      inVisible: [],
      visible: []
    }
    state.isLoadedInfo = false
  state.brandingEnabled = false},
  [CURRENTCOMPANY_LOAD_INFO] (state, ui) {
    state.isCCNSW = ui.isCCNSW??false
    state.name = ui.name
    state.id = ui.id
    state.primaryBusinessAddress = ui.primaryBusinessAddress
    state.name = ui.name
    state.settings = ui.settings
    state.acn = ui.acn
    state.businessName = ui.businessName
    state.isCCNSW = ui.isCCNSWstate.about = ui.about
    state.abn = ui.abn
    state.phone = ui.phone
    state.brands = ui.brands
    state.fax = ui.fax
    state.email = ui.email
    state.emailOrders = ui.emailOrders
    state.emailCredits = ui.emailCredits
    state.logoImgPath = ui.logoImgPath
    state.address = ui.address
    state.locations = ui.locations
    state.contacts = ui.contacts
    state.supplierType = ui.supplierType
    state.supplierTypes = ui.supplierTypes
    state.brandingEnabled = ui.brandingEnabled
    state.supplierVehicleCategory = ui.supplierVehicleCategory
    state.isLoadedInfo = true
    state.displayId = ui.displayIdstate.brandingEnabled = ui.brandingEnabled
  },
  [CURRENTCOMPANY_LOGOUT] (state) {
    state = {isCCNSW :false,
      id: 0,
      name: '',
      businessName: '',isCCNSW: false,
      abn: '',
      phone: '',
      fax: '',
      settings: {},
      email: '',emailOrders: '',
            emailCredits: '',
            about: '',
            supplierType: '',
            supplierVehicleCategory: 'CAR',
            displayId: null,
            acn: null,
            logoImgPath: '',
            address: {
                address: '',
                unitLevelLot: '',
                street: '',
                suburb: '',
                postcode: '',
                state: '',
                country: ''
            },
            locations: [],
            supplierTypes: [],
            contacts: {
                inVisible: [],
                visible: []
            },
            primaryBusinessAddress: {
                address: '',
                unitLevelLot: '',
                street: '',
                suburb: '',
                postcode: '',
                state: '',
                country: ''
            },
          isLoadedInfo: false,
          brandingEnabled: false,
        }
    },
    [CURRENTCOMPANY_CHANGE_RFQ_TIME_NOTIFICATION](state, ui) {
        if (!state?.settings?.rfq?.timeNotifications) {
            if (_.isEmpty(state.settings)) {
                state.settings = {}
            }
            if (_.isEmpty(state.settings.rfq)) {
                state.settings.rfq = {}
            }
            if (_.isEmpty(state.settings.rfq.timeNotifications)) {
                state.settings.rfq.timeNotifications = {
                    closingSoon:{
                        colour: "red",
                        timeLeft: 10,
                        notificationLevel: "Closing Soon",
                        displayAs: 'Text'
                    },
                    closingShortly:{
                        colour: "orange",
                        timeLeft: 20,
                        notificationLevel: "Closing Shortly",
                        displayAs: 'Text'
                    }
                }
            }
        }
        if (!_.isEqual(ui.closingSoon, state.settings.rfq.timeNotifications.closingSoon)) {
            state.settings.rfq.timeNotifications.closingSoon = ui.closingSoon
        }
        if (!_.isEqual(ui.closingShortly, state.settings.rfq.timeNotifications.closingShortly)) {
            state.settings.rfq.timeNotifications.closingShortly = ui.closingShortly
        }
    },
    [CURRENTCOMPANY_CHANGE_RFQ_IMPORT_QUOTE_OPTIONS](state, ui) {
        if (!state?.settings?.rfq?.importQuoteOptions) {
            if (_.isEmpty(state.settings)) {
                state.settings = {}
            }
            if (_.isEmpty(state.settings.rfq)) {
                state.settings.rfq = {}
            }
            if (_.isEmpty(state.settings.rfq.importQuoteOptions)) {
                state.settings.rfq.importQuoteOptions = {}
            }
        }
        if (_.isEqual(ui, state.settings.rfq.importQuoteOptions)) {
            return
        }
        state.settings.rfq.importQuoteOptions = ui

  }
}
