import Axios from "axios";
import _ from "lodash";
import {
    REPAIRER_SET_ACTIVITY,
    REPAIRER_SET_MESSAGES,
} from "../../../mutation-types";

export function init(context, payload = 0) {
    Axios.get('/ir/repairer/dashboard')
        .then(response => {
            if (response?.data?.chatMessages) {
                context.commit(REPAIRER_SET_MESSAGES, response.data.chatMessages);
            }
            if (response?.data?.activityList) {
                context.commit(REPAIRER_SET_ACTIVITY, response.data.activityList);
            }
        })
}
