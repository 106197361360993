/* eslint-disable */

export function getRfqs(state) {
  return state.rfqs.items;
}
export function getActiveCustomers(state) {
  return  _.filter(state.items, item => item.isActive);
}

export function getCancelledCustomers(state) {
  return  _.filter(state.items, item => !item.isActive);
}

export function getCustomers(state) {
  return state.items;
}

export function getPerPage(state) {
  return state.perPage;
}

export function getFilter(state) {
  return state.filter;
}

export function getCustomersAllCurrentPage(state) {
  return state.customersAllCurrentPage;
}

export function getCustomersPreferedCurrentPage(state) {
  return state.customersPreferedCurrentPage;
}
