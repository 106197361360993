import { render, staticRenderFns } from "./multiple-brand-profile.vue?vue&type=template&id=0936228f&scoped=true"
import script from "./multiple-brand-profile.vue?vue&type=script&lang=js"
export * from "./multiple-brand-profile.vue?vue&type=script&lang=js"
import style0 from "./multiple-brand-profile.vue?vue&type=style&index=0&id=0936228f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0936228f",
  null
  
)

export default component.exports