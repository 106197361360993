<template>
  <div class="row">
    <div class="col-md-6">
      <b-table
          responsive
          hover
          :items="usersAll"
          :fields="usersAllFields"
          @row-clicked="onUsersRowClick"
      >
        <template v-slot:cell(username)="data">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </template>
        <template v-slot:cell(actions)="data">
          <button
              v-if="isAdministrator || Number(data.item.id) === Number(currentUser.id)"
              class="btn-edit"
              @click="onUserEdit(data.item)"
              v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit User Details', placement:'leftbottom'}"
          ></button>
          <button
              v-if="isAdministrator"
              class="btn-trush"
              @click="onClickRemoveUser(data.item)"
              v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete User', placement:'leftbottom'}"
          ></button>
        </template>
      </b-table>
    </div>

    <div class="col-md-6">
      <!-- Users Detail Tab View -->
      <tabs ref="users-tabs" cache-lifetime="0">
        <tab name="Details" id="details" ref="details" class-name="tab-content-table">
          <div class="detail-section">
            <!--        <div class="section-header">Detail</div>-->

            <div class="detail-body row">
              <div v-if="selectedUser.userPhoto" class="col-md-4 user-avatar-section">
                <div class="user-photo">
                  <img :src="selectedUser.userPhoto" alt="User Image"/>
                </div>
                <button class="btn btn-danger" @click="onClickDeletePhoto()">Delete Photo
                </button>
              </div>
              <div v-else class="col-md-4 user-avatar-section">
                <vue-dropzone
                    v-on:vdropzone-file-added="addImgUpload"
                    v-on:vdropzone-complete="completeImgUpload"
                    v-on:vdropzone-sending="sendImgUpload"
                    v-on:vdropzone-success="successImgUploadSelectedUser"
                    ref="imgUpDropUsers"
                    id="imgUpDropUsers"
                    class="dropify-wrapper dropzoneContainer"
                    :options="selectedUserAvatarDropdownOptions"
                    :useCustomSlot="true"
                >
                  <div class="dropify-message" style="transform: translateY(0%);">
                    <i class="bx bx-cloud-upload"></i>
                    <p class="upload-title">Upload Photo</p>
                  </div>
                </vue-dropzone>
              </div>

              <div class="col-md-8 user-detail-section">
                <div class="form-group row">
                  <h3 class="user-name">{{ selectedUser.firstName }}
                    {{ selectedUser.lastName }}</h3>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label" for="account-status">Account
                    Status</label>
                  <div v-if="selectedUser.username" class="col-lg-9">
                    <div
                        v-if="!isUsersEdit || Number(selectedUser.id) === Number(currentUser.id)"
                        class="d-flex">
                      <label v-if="selectedUser.isActive"
                             class="col-form-label form-text">
                        <div class="active-icon"></div>
                        <span class="active-user-text">Active User</span>
                      </label>
                      <label v-else class="col-form-label form-text">
                        <div class="active-icon inactive"></div>
                        <span class="active-user-text">Not Active User</span>
                      </label>
                    </div>
                    <div v-else class="d-flex">
                      <b-form-checkbox
                          v-model="selectedUser.isActive"
                          name="check-button"
                          switch
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                                                        <span v-if="selectedUser.isActive"
                                                              class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-if="selectedUser.username" class="col-lg-9 d-flex">
                    <label class="col-form-label form-text">
                      <div class="active-icon"></div><span class="active-user-text">Active User</span>
                    </label>
                  </div>
                  <b-form-checkbox
                    v-if="isUsersEdit"
                    v-model="selectedUser.isActive"
                    class="col-lg-5"
                    name="check-button"
                    switch
                  ></b-form-checkbox> -->
                </div>

                <div class="form-group row">
                  <label for="username" class="col-lg-3 col-form-label">Username</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        class="col-form-label form-text"
                    >{{ selectedUser.username }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        @input="debouncedCheckUsername(selectedUser.username)"
                        v-model="selectedUser.username"
                        id="username"
                    />
                  </div>
                  <template v-if="!isCheckingUserName">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9 mt-1">
                      <div v-if="!selectedUser.isCheckUsername" class="w-100 text-left">
                        <span class="error-text font-12">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This username is in use. Try to another one.
                        </span>
                      </div>
                      <div v-else-if="!selectedUser.isEqualUsernames" class="w-100 text-left">
                        <span style="color:#2DCA73; font-weight: 600" class="font-12 d-flex align-items-center">
                          <i class="bx bx-check-circle" style="width: 24px; height: 24px; font-size: 24px; margin-right: 5px;"></i>&nbsp;Congrats this username is not in use
                        </span>
                      </div>
                    </div>
                  </template>
                </div>

                <div class="form-group row">
                  <label for="first_name" class="col-lg-3 col-form-label">First Name</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        class="col-form-label form-text"
                    >{{ selectedUser.firstName }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="selectedUser.firstName"
                        id="first_name"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="last_name" class="col-lg-3 col-form-label">Last Name</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        class="col-form-label form-text"
                    >{{ selectedUser.lastName }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="selectedUser.lastName"
                        id="last_name"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Email Address</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        class="col-form-label form-text"
                    >{{ selectedUser.email }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        :value="selectedUser.email"
                        @change="validateSelectedUserEmail($event.target.value)"
                    />
                    <small
                        class="col-form-label form-text show-help-for-body"
                        v-if="!$v.selectedUser.email.email"
                    >Please enter a valid email address</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="location" class="col-lg-3 col-form-label">Location</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        for="location"
                        class="col-form-label form-text">
                      <template v-if="selectedUser.location">
                        {{ selectedUser.location.name }}
                      </template>
                    </label>
                    <multiselect
                        v-else
                        v-model="selectedUser.location"
                        :options="locations"
                        track-by="id"
                        label="name"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :multiple="false"
                        placeholder="Select business location"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="work_phone" class="col-lg-3 col-form-label">Work Phone</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        for="work_phone"
                        class="col-form-label form-text"
                    >{{ selectedUser.workPhone }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="selectedUser.workPhone"
                        id="work_phone"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="mobile_phone" class="col-lg-3 col-form-label">Mobile
                    Phone</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit"
                        for="mobile_phone"
                        class="col-form-label form-text"
                    >{{ selectedUser.mobilePhone }}</label>
                    <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="selectedUser.mobilePhone"
                        id="mobile_phone"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Role</label>
                  <div class="col-lg-9">
                    <label
                        v-if="!isUsersEdit || !isAdministrator || (isAdministrator && isSelectedUserIsMe)"
                        class="col-form-label form-text"
                    >{{ selectedUser.companyRole }}</label>
                    <multiselect
                        v-else
                        ref="repairer-user-role-options"
                        v-model="selectedUser.companyRole"
                        :options="getRoles()"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        placeholder="Select Role"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Last Logged In</label>
                  <div class="col-lg-9">
                    <label
                        class="col-form-label form-text">{{
                        selectedUser.lastLoggedIn
                      }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-form-label">Active sessions</label>
                  <div v-if="selectedUser.username" class="col-lg-9">
                    <label
                        class="col-form-label form-text">{{
                        selectedUser.activeSessions
                      }}</label>
                  </div>
                </div>
                <div class="form-group row action-btns" v-show="!isAddNewUserClicked">
                  <template v-if="isUsersEdit">
                    <button class="update-btn" :disabled="!selectedUser.isCheckUsername" @click="onClickUpdateUser()">Update</button>
                    <button class="edit-btn" @click="onClickCancelEditUser()">Cancel
                    </button>
                  </template>
                  <template v-else>
                    <button
                        class="close-active-btn"
                        @click="onClickCloseActiveSessions()"
                    >Close Active Sessions
                    </button>
                    <button class="delete-user" @click="onClickRemoveUser()">Delete User
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="update-password-section">
            <div class="section-header">
              <div>Password</div>
            </div>

            <div class="form-body">
              <div class="form-group row mt-4">
                <label class="col-lg-4 col-form-label"
                       for="password_of_selected_user">Password</label>
                <div class="col-lg-8">
                  <input
                      type="password"
                      class="form-control"
                      v-model="selectedUser.password"
                      id="password_of_selected_user"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="confirm_password_of_selected_user" class="col-lg-4 col-form-label">Confirm
                  Password</label>
                <div class="col-lg-8">
                  <input
                      type="password"
                      class="form-control"
                      v-model="selectedUser.confirmPassword"
                      id="confirm_password_of_selected_user"
                  />
                </div>
              </div>

              <div class="form-group row action-btns">
                <button v-if="!isAddNewUserClicked" class="update-btn"
                        @click="onClickUpdatePassword()">Update
                </button>
                <button v-else class="update-btn" @click="onClickSaveUser()">Save</button>
              </div>
            </div>
          </div>
        </tab>
        <tab name="Notifications" id="notifications" ref="notifications" class-name="tab-content-table">
          <div class="detail-section form-group row">
            <div class="detail-body">
              <template v-if="isUserTypeSupplier">
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Request for Quote Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time new RFQ is submitted from a
                      Customer to your account email.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex" :class="{'settings-active-toggle-button': selectedUser.isEmailNoticeNewRfq}">
                      <b-form-checkbox
                          v-model="selectedUser.isEmailNoticeNewRfq"
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isEmailNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Request for Quote Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound notification each time new RFQ is submitted from a
                      Customer.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex" :class="{'settings-active-toggle-button': selectedUser.isSoundNoticeNewRfq}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          v-model="selectedUser.isSoundNoticeNewRfq"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isSoundNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Update Priced Quote Request Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time a new update priced quote
                      requested submitted from a customer to your account email.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"
                         :class="{'settings-active-toggle-button': selectedUser.isEmailNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          v-model="selectedUser.isEmailNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isEmailNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Update Priced Quote Request Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound each time a new update priced quote request
                      submitted from a customer.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"
                         :class="{'settings-active-toggle-button': selectedUser.isSoundNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          v-model="selectedUser.isSoundNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isSoundNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Priced Quote Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time new pricing is submitted
                      from supplier in a Priced Quote.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex" :class="{'settings-active-toggle-button': selectedUser.isEmailNoticeNewRfq}">
                      <b-form-checkbox
                          v-model="selectedUser.isEmailNoticeNewRfq"
                          name="check-button"
                          @change="updateUser(selectedUser)"
                          switch
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isEmailNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">New Priced Quote Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound notification each time new pricing is submitted from
                      supplier in a Priced Quote.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex" :class="{'settings-active-toggle-button': selectedUser.isSoundNoticeNewRfq}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          v-model="selectedUser.isSoundNoticeNewRfq"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isSoundNoticeNewRfq" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Updated Priced Quote Receive Email</span> <br/>
                    <p class="notifications-info-text">Receive Email notifications each time after pricing update
                      request submitted from parts supplier.</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"
                         :class="{'settings-active-toggle-button': selectedUser.isEmailNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          v-model="selectedUser.isEmailNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isEmailNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-12 col-form-label col-lg-6">
                    <span class="notifications-info-title">Updated Priced Quote Play Sound</span> <br/>
                    <p class="notifications-info-text">Play a sound each time after pricing update request submitted
                      from parts supplier</p>
                  </label>
                  <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                    <div class="d-flex"
                         :class="{'settings-active-toggle-button': selectedUser.isSoundNoticeUpdatedPriceRequest}">
                      <b-form-checkbox
                          name="check-button"
                          switch
                          @change="updateUser(selectedUser)"
                          v-model="selectedUser.isSoundNoticeUpdatedPriceRequest"
                          class="switch-checkbox"
                      ></b-form-checkbox>
                      <div class="status-text">
                        <span v-if="selectedUser.isSoundNoticeUpdatedPriceRequest" class="form-text">Active</span>
                        <span v-else class="form-text">Not Active</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import NProgress from "nprogress";
import _ from "lodash";
import {email} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Axios from "axios";

export default {
  name: "the-users",
  components: {
    vueDropzone: vue2Dropzone,
    multiselect: Multiselect,
  },
  props: {
    isAddNewUserClicked: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isUsersEdit: false,
      locations: [],
      lastSelectedUser: {
        id: '',
        username: '',
        username2: '',
        isCheckUsername: true,
        isEqualUsernames: true,
        firstName: '',
        lastName: '',
        email: '',
        companyRole: '',
        businessLocation: '',
        userPhoto: '',
        mobilePhone: '',
        workPhone: '',
        lastLoggedIn: '',
        activeSessions: -1,
        password: '',
        confirmPassword: '',
        isEmailNoticeNewRfq: false,
        isEmailNoticeUpdatedPriceRequest: false,
        isSoundNoticeNewRfq: false,
        isSoundNoticeUpdatedPriceRequest: false,
      },
      isCheckingUserName: false,
      selectedUser: {
        id: '',
        username: '',
        username2: '',
        isCheckUsername: true,
        isEqualUsernames: true,
        firstName: '',
        lastName: '',
        email: '',
        companyRole: '',
        businessLocation: '',
        userPhoto: '',
        mobilePhone: '',
        workPhone: '',
        lastLoggedIn: '',
        activeSessions: -1,
        password: '',
        confirmPassword: '',
        groupId: null,
        isEmailNoticeNewRfq: false,
        isEmailNoticeUpdatedPriceRequest: false,
        isSoundNoticeNewRfq: false,
        isSoundNoticeUpdatedPriceRequest: false,
      },
      selectedUserAvatarDropdownOptions: {
        headers: {Authorization: localStorage.getItem('token') },
        resizeWidth: 400,
        resizeQuality: 0.7,
        url: 'url', // need to input dynamic url
        method: 'post',
        maxFilesize: 5,
        thumbnailWidth: 160,
        thumbnailHeight: 160,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig:
            'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      repairerRoleOptions: [
        'Administrator',
        'Estimator',
        'Parts Coordinator'
      ],
      supplierRoleOptions: [
        'Administrator',
        'Parts Interpreter',
        'Parts Manager'
      ],
      repairerRolesGroupId: [
        {
          name: 'Administrator',
          groupId: 1
        },
        {
          name: 'Estimator',
          groupId: 2
        },
        {
          name: 'Parts Coordinator',
          groupId: 3
        },
      ],
      supplierRolesGroupId: [
        {
          name: 'Administrator',
          groupId: 1
        },
        {
          name: 'Parts Interpreter',
          groupId: 3
        },
        {
          name: 'Parts Manager',
          groupId: 4
        },
      ],
      usersAllFields: [
        {
          label: 'Name',
          key: 'username',
          tdClass: 'name clickable',
          thClass: 'table-header'
        },
        {
          label: 'Role',
          key: 'companyRole',
          tdClass: 'role clickable',
          thClass: 'table-header'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'actions clickable text-right',
          thClass: 'table-header'
        }
      ],
    }
  },
  mounted() {
    if (!_.isEmpty(this.initialLocations)) {
      this.setLocations(this.initialLocations)
      this.updateSelectedUser(this.usersAll[0])
    }
    this.debouncedCheckUsername = _.debounce(function (v) {
      this.checkUsername(v)
    }, 500)
    this.updateUser = _.debounce(this.updateUser2, 1000)
  },
  computed: {
    ...mapGetters({
      usersAll: 'users/getAllUsers',
      initialLocations: 'currentCompany/getLocations',
      currentUser: 'currentUser/getCurrentUserInfo',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      addressStates: 'addressStates',
      isAdministrator: 'currentUser/isAdministrator',
    }),
    isSelectedUserIsMe() {
      return Number(this.selectedUser.id) === Number(this.currentUser.id);
    },
  },
  methods: {
    ...mapActions({
      loadUsersAll: 'users/fetchAllUsers',
    }),
    checkUsername: function () {
      this.isCheckingUserName = true;
      this.selectedUser.isEqualUsernames = this.selectedUser.username === this.selectedUser.username2
      if (this.selectedUser.username === this.selectedUser.username2) {
        this.selectedUser.isCheckUsername = true;
        return
      }
      let username = this.selectedUser.username;
      this.isCheckPassword = true;
      Axios({url: '/ir/auth/check/username', data: {username: username}, method: 'POST'})
          .then(resp => {
            if (resp && resp.data && resp.data.status == false) {
              this.selectedUser.isCheckUsername = true;
            } else {
              this.selectedUser.isCheckUsername = false;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally((() => {
            this.isCheckingUserName = false
          }))
    },
    getGroupId (role) {
      let roles = this.isUserTypeSupplier ? this.supplierRolesGroupId : this.repairerRolesGroupId;
      let currentRole = _.find(roles, (r) => {
        return r.name === role;
      });
      if (currentRole) {
        return currentRole.groupId
      }
      return null;
    },
    getLocationById: function (id) {
      let location = {}
      if (!_.isEmpty(this.locations)) {
        location = _.find(this.locations, (location) => {
          return id === location.id
        })
      }
      return location
    },
    setLocations: function (val) {
      this.locations = val.map(location => ({
        ...location,
        isEditable: false,
        stateValue: _.find(this.addressStates, item => {
          return item.key === location.state
        })
      }))
    },
    getRoles: function () {
      return (this.isUserTypeSupplier) ? this.supplierRoleOptions : this.repairerRoleOptions
    },
    updateUser2: function (user) {
      NProgress.start();
      user.groupId = this.getGroupId(user.companyRole);
      this.$store.dispatch('users/updateUser', {
        id: user.id,
        updatedUser: user
      }).then(() => {
        this.$toast.success('User has been updated')
        NProgress.done()
        if (Number(this.currentUser.id) === Number(this.selectedUser.id)) {
          this.$store.dispatch('currentUser/loadUserInfo')
        }
        this.loadUsersAll();
      }).catch((err) => {
        this.$toast.error(err)
        NProgress.done()
      })

    },
    onClickUpdateUser: function () {
      this.updateUser(this.selectedUser)
      this.isUsersEdit = false
      this.isCheckingUserName = false;
      this.$emit('addNewUserClicked', false);
    },
    onClickSaveUser: function () {
      if (!this.selectedUser.username) {
        this.$toast.error('The Username must be placed')
      } else if (!this.selectedUser.password || !this.selectedUser.confirmPassword) {
        this.$toast.error('The Password must be placed')
      } else {
        NProgress.start()
        this.selectedUser.groupId = this.getGroupId(this.selectedUser.companyRole);
        this.$store.dispatch('users/createNewUser', this.selectedUser)
            .then((response) => {
              if (response.data.status) {
                this.$toast.success('New user has been created')
                this.isUsersEdit = false
                this.$emit('addNewUserClicked', false);
                this.updateSelectedUser({})
              } else {
                this.$toast.error(response.data.err)
                this.isUsersEdit = true
                this.$emit('addNewUserClicked', true);
              }
              NProgress.done()
            })

            .catch((err) => {
              this.$toast.error(err)
              this.isUsersEdit = true
              this.$emit('addNewUserClicked', true);
              NProgress.done()
            })
            .finally(() => {
              this.loadUsersAll();
            })
      }
    },
    onClickAddNewUser: function () {
      this.isUsersEdit = true
      this.$emit('addNewUserClicked', true);
      this.updateSelectedUser({})
    },
    onClickCancelEditUser: function () {
      if (this.isAddNewUserClicked) {
        this.updateSelectedUser(this.lastSelectedUser)
      }
      this.isUsersEdit = false
      this.$emit('addNewUserClicked', false);
    },
    setUploadUrlForUsersPhoto: function (id) {
      if (!_.isEmpty(this.$refs.imgUpDropUsers)) {
        let url = appConfig.baseAPIURL + '/ir/user/' + id + '/photo/upload'
        this.$refs.imgUpDropUsers.setOption('url', url)
      }
    },
    onUsersRowClick: function (item) {
      this.setUploadUrlForUsersPhoto(item.id)
      this.updateSelectedUser(item)
      this.isUsersEdit = false
      this.$emit('addNewUserClicked', false);
    },
    onUserEdit: function (item) {
      this.updateSelectedUser(item)
      this.isUsersEdit = true
      this.$emit('addNewUserClicked', false);
    },
    onClickRemoveUser: function (item) {
      let userId
      NProgress.start()

      if (!item) userId = this.selectedUser.id
      else userId = item.id

      this.$store.dispatch('users/removeUserById', userId)
          .then(() => {
            this.$toast.success('User has been removed')
            NProgress.done()
          })
          .catch((err) => {
            this.$toast.error(err)
            NProgress.done()
          })
      this.updateSelectedUser({})
    },
    onClickCloseActiveSessions: function () {
      NProgress.start()
      this.$store.dispatch('users/closeActiveSessions', this.selectedUser.id)
          .then(() => {
            if (Number(this.selectedUser.id) === Number(this.currentUser.id)) {
              NProgress.start();
              let wrapper = document.getElementById('app-wrapper');
              wrapper.style.opacity = 0;
              setTimeout(() => {
                this.$store.dispatch('auth/logout')
              }, 500)
            }
            this.$toast.success('Active sessions have been closed')
            NProgress.done()
          })
          .catch((err) => {
            this.$toast.error(err)
            NProgress.done()
          })
      this.isUsersEdit = false
      this.$emit('addNewUserClicked', false);
    },
    addImgUpload($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    completeImgUpload($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].removeAttribute('style')
    },
    sendImgUpload($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = 'none'
    },
    successImgUploadSelectedUser(file, response) {
      if (response.status) {
        this.$set(this.selectedUser, 'userPhoto', file.dataURL)
        if (Number(this.currentUser.id) === this.selectedUser.id) {
          this.updateUserAvatar(file.dataURL)
        }
        this.$store.dispatch('users/fetchAllUsers').catch(err => {
          this.$toast.error(err)
        })
      } else {
        setTimeout(() => {
          this.$refs.imgUpDropUsers.removeFile(file)
        }, 1000)
      }
    },
    onClickUpdatePassword: function () {
      if (Number(this.currentUser.id) === Number(this.selectedUser.id)) {
        this.onUpdatePasswordMyAccount({
          newPassword: this.selectedUser.password,
          confirmPassword: this.selectedUser.confirmPassword
        }, () => {
          this.selectedUser.password = ''
          this.selectedUser.confirmPassword = ''
        })
      } else {
        if (!this.validateSelectedUserPassword()) return
        NProgress.start()
        this.$store.dispatch('users/updatePasswordById', {
          id: this.selectedUser.id,
          password: this.selectedUser.password,
          confirmNewPassword: this.selectedUser.confirmPassword
        }).then(() => {
          this.$toast.success('The Password has been updated')
          this.selectedUser.password = ''
          this.selectedUser.confirmPassword = ''
        }).catch(err => {
          this.$toast.error(err)
        }).finally(() => {
          NProgress.done()
        })
      }

    },
    validateSelectedUserEmail: function (value) {
      this.selectedUser.email = value
      this.$v.selectedUser.email.$touch()
    },
    validateSelectedUserPassword: function () {
      if (!this.selectedUser.password) {
        this.$toast.error('Please input the password!')
        return false
      }
      if (this.selectedUser.password != this.selectedUser.confirmPassword) {
        this.$toast.error('The Passwords must be the same!')
        return false
      }
      return true
    },
    onClickDeletePhoto() {
      this.selectedUser.userPhoto = ''
      if (Number(this.currentUser.id) === this.selectedUser.id) {
        this.myAccountAvatar = ''
        this.updateUserAvatar('')
      }
      NProgress.start()
      this.$store.dispatch('users/removeUserPhotoById', this.selectedUser.id)
          .then(() => {
            this.$toast.success('User photo has been removed')
            NProgress.done()
            this.$store.dispatch('users/fetchAllUsers').catch(err => {
              this.$toast.error(err)
            })
          })
          .catch(error => {
            this.$toast.error(error)
            NProgress.done()
          })
    },
    updateSelectedUser: function (data) {
      let location = {},
          locationId = 0
      if (!_.isEmpty(data)) {
        locationId = data.businessLocation;
        location = this.getLocationById(locationId);

        this.selectedUser = {
          id: data.id,
          username: data.username,
          username2: _.cloneDeep(data.username),
          isCheckUsername: true,
          isEqualUsernames: true,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          companyRole: data.companyRole,
          isActive: data.isActive,
          businessLocation: locationId,
          location: location,
          userPhoto: data.userPhoto,
          mobilePhone: data.mobilePhone,
          workPhone: data.workPhone,
          activeSessions: data.activeSessions,
          lastLoggedIn: data.lastLoggedIn,
          groupId: this.getGroupId(data.companyRole),
          isEmailNoticeNewRfq: data.isEmailNoticeNewRfq,
          isEmailNoticeUpdatedPriceRequest: data.isEmailNoticeUpdatedPriceRequest,
          isSoundNoticeNewRfq: data.isSoundNoticeNewRfq,
          isSoundNoticeUpdatedPriceRequest: data.isSoundNoticeUpdatedPriceRequest,
        }
      } else {
        this.selectedUser = {
          id: '',
          username: '',
          username2: '',
          isCheckUsername: true,
          isEqualUsernames: true,
          firstName: '',
          lastName: '',
          email: '',
          companyRole: '',
          businessLocation: '',
          userPhoto: '',
          mobilePhone: '',
          workPhone: '',
          lastLoggedIn: '',
          activeSessions: -1,
          password: '',
          confirmPassword: '',
          groupId: null,
          isEmailNoticeNewRfq: false,
          isEmailNoticeUpdatedPriceRequest: false,
          isSoundNoticeNewRfq: false,
          isSoundNoticeUpdatedPriceRequest: false,
        }
      }
    },
  },
  watch: {
    currentUser: {
      deep: true,
      handler(val) {
        if (Number(this.selectedUser.id) === Number(val.id)) {
          this.updateSelectedUser(val);
        }
      }
    },
    selectedUser: {
      deep: true,
      handler(newVal) {
        this.setUploadUrlForUsersPhoto(newVal.id)
        if (!_.isEmpty(newVal.location)) this.selectedUser.businessLocation = newVal.location.id
      }
    },
    usersAll: {
      deep: true,
      handler(val) {
        if (!_.isEmpty(val) && _.isEmpty(this.selectedUser.firstName)) {
          this.updateSelectedUser(val[0])
        }
      }
    },
    initialLocations: {
      deep: true,
      handler(val) {
        this.setLocations(val);
        this.updateSelectedUser(this.usersAll[0])
      }
    }
  },
  validations: {
    selectedUser: {
      email: {
        email
      }
    },
  }
}
</script>

<style scoped>

</style>
