<template>
  <div v-if="brands && brands.length">
    <div class="detail-section form-group row">
      <h3 style="margin: 19px 0px 0px 27px " class="user-name">{{ selectedUserName}}</h3>
      <div v-for="(b, ind) in computedBrands" :key="b && b.id ? b.id : getRandom()" class="detail-body w-100">
        <div class="row mb-3">
          <label class="col-12 col-form-label col-lg-6">
            <span v-if="toNumber(b.id)  === toNumber(currentBrand)" class="notifications-info-title">Primary Business</span>
            <span v-else class="notifications-info-title">Additional Business</span>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox">
            <div class="d-flex justify-content-between w-100">
              <b-form-input :value="(b.brand + ' - ' +b.brandLocation)" type="text" :disabled="true" class="form-control"></b-form-input>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Assigned Makes/Franchise</span>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox">
            <div class="d-flex justify-content-between w-100">
              <multiselect
                  v-model="selectedMake"
                  @input="v => onSelectedMake(v, b)"
                  :options="b && b.makesForOptions ? b.makesForOptions : []"
                  :multiple="false"
                  autocomplete="off"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="true"
                  :resetAfter="true"
                  placeholder="Type to search and add a make"
                  :preselect-first="false"
              >
                <template
                    v-slot:cell(selection)="{ values, search, isOpen }"></template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-form-label col-lg-6"></label>
          <div class="col-12 col-lg-6 notifications-form-checkbox">
            <div class="d-flex justify-content-between w-100">
              <!--                      <b-textarea style="min-height: 100px"></b-textarea>-->
              <supplier-makes :chips="b.demoMakes"
                              @remove="v => removeBrand(v, b)"></supplier-makes>
            </div>
          </div>
        </div>
        <div v-if="brands[ind + 1]" style="height: 3px; width: 100%; background-color: rgba(27,30,56,0.12); margin-top: 20px;" class=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import supplierMakes from '@/components/supplier-makes'
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import {uuid} from "vue-uuid";
import Axios from "axios";

export default {
  name: "user-teams",
  components: {
    multiselect: Multiselect,
    supplierMakes
  },
  props: {
    userId: {
      type: String,
      default: () => ('')
    },
    selectedUserName: {
      type: String,
      default: () => ('')
    },
    currentBrand: {
      type: String,
      default: () => ('')
    },
    locations: {
      type: Array,
      default: () => ([])
    },
    multiBrands: {
      type: Array,
      default: () => ([])
    },
    updatedMakes: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      selectedMake: '',
      isClickedSave: false,
      brands: [],
    }
  },
  computed: {
    ...mapGetters({
      isMultipleLocations: 'settings/isMultipleLocations',
      getterSettings: 'settings/getSettings',
      currentUser: 'currentUser/getCurrentUserInfo',
    }),
    computedBrands() {
      let result = [];
      let currentCompanyBrand = _.find(this.brands, (b) => {
        return Number(b?.id) === Number(this.currentBrand)
      })
      if (currentCompanyBrand) {
        result.push(currentCompanyBrand)
      }
      _.forEach(this.brands, (b) => {
        if (Number(b?.id) !== Number(this.currentBrand)) {
          result.push(b);
        }
      })
      return result
    }
  },
  mounted() {
    this.getBrands();
  },
  watch: {
    userId: {
      handler() {
        this.getBrands()
        this.$emit('updatedTeamsMakes', false)
      },
      immediate: true
    },
    updatedMakes: {
      handler(v) {
        if (Number(this.currentUser.id) === Number(this.userId) && !_.isEmpty(v)) {
          this.setBrands(v)
        }
      },
      immediate: true,
    },
    'getterSettings.multipleBrands': {
      handler() {
        this.getBrands();
      },
      immediate: true,
    },
    locations: {
      handler() {
        this.getBrands();
      },
      immediate: true,
    },
    // multiBrands: {
    //   handler() {
    //     this.getBrands();
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    toNumber(v) {
      return Number(v)
    },
    getRandom() {
      return uuid.v1()
    },
    onSelectedMake(newVal, b) {
      if (!newVal) return
      if (!b.demoMakes){
        b.demoMakes = []
      }
      if (b.demoMakes.includes(newVal)) return
      b.demoMakes.push(newVal)

      b.demoMakes = b.demoMakes.filter((elem, pos, arr) => {
        return arr.indexOf(elem) === pos
      })
      this.getOptionsForMultiselect();
      this.$emit('updatedTeamsMakes', true)
      // this.onClickSaving()
    },
    removeBrand: function (removedOption, b) {
      b.demoMakes.splice(b.demoMakes.indexOf(removedOption), 1)
      if (!b.deleteBrands) {
        b.deleteBrands = []
      }
      b.deleteBrands.push(removedOption)
      this.getOptionsForMultiselect();
      this.$emit('updatedTeamsMakes', true)
      // this.onClickSaving()
    },
    getOptionsForMultiselect() {
      this.brands = _.map(this.brands, (itm) => {
        let options = _.filter(itm.brands, (v) => {
          return !(itm.demoMakes && itm.demoMakes.includes(v))
        })
        options = _.orderBy(options, [(itm) => itm.toLowerCase().trim()], ['asc'])
        return {
          ...itm,
          makesForOptions: options
        }
      })
    },
    setDemoMakes () {
      this.brands = _.map(this.brands, (itm) => {
        let makes = []
        if (this.isClickedSave) {
          makes = itm.makes;
        } else {
          makes = _.find(this.multiBrands, (b) => {
            return Number(b.id) === Number(itm.id);
          })?.makes
        }
        makes = _.uniq(makes)
        return {
          ...itm,
          demoMakes: makes ? _.cloneDeep(makes) : [],
          makes: makes ? _.cloneDeep(makes) : [],
        }
      })
    },
    getBrands() {
      let r = [];
      if (!this.isClickedSave) {
        if (_.isArray(this.locations) && this.locations?.length && this.isMultipleLocations) {
          r = _.filter(_.cloneDeep(this.getterSettings.multipleBrands), (itm) => {
            let b = _.find(this.locations, (i) => {
              return Number(i.id) === Number(itm.id)
            })
            return b || this.locations.includes(itm.id)
          })
        }
      } else {
        r = _.cloneDeep(this.brands);
      }

      this.brands.splice(0, this.brands.length);
      _.forEach(_.cloneDeep(r), (b) => {
        this.brands.push(b);
      })
      this.setDemoMakes();
      this.getOptionsForMultiselect();
      // console.log(this.userId, this.currentUser.id, isAfterSaving, 'changedmakes')
    },
    setBrands(v) {
      console.log(v, 'value set')
      setTimeout(() => {
        this.brands.splice(this.brands, this.brands.length);
        _.forEach(v, (i) => {
          this.brands.push(i)
        })
      }, 200)
    },
    onClickSaving() {
      let result = {
        addMakes: [],
        deleteMakes: [],
      };
      _.forEach(this.brands, (b) => {
        if (b.demoMakes) {
          let addR = _.filter(b.demoMakes, (m) => {
            return !b?.makes?.includes(m)
          })
          let delR = _.filter(b.deleteBrands, (m) => {
            return b?.makes?.includes(m) && !addR?.includes(m);
          })
          if (!_.isEmpty(addR)) {
            result.addMakes.push({
              brandId: b.id,
              makes: addR,
            })
          }
          if (!_.isEmpty(delR)) {
            result.deleteMakes.push({
              brandId: b.id,
              makes: delR,
            })
          }
        }
      })

      Axios({
        method: 'post',
        responseType: 'json',
        url: `/ir/user/${this.userId}`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: result
      })
          .then(response => {
            if (response.data.status) {
              _.forEach(this.brands, (br) => {
                br.deleteBrands = [];
                let addUpdate = _.find(result.addMakes, (v) => {
                  return Number(v.brandId) === Number(br.id);
                })
                let removeUpdate = _.find(result.deleteMakes, (v) => {
                  return Number(v.brandId) === Number(br.id);
                })
                if (!br.makes) {
                  br.makes = [];
                }
                if (addUpdate?.makes) {
                  _.forEach(addUpdate.makes, (v) => {
                    br.makes.push(v);
                  })
                }

                if (removeUpdate?.makes) {
                  _.forEach(removeUpdate.makes, (v) => {
                    let index = br.makes.indexOf(v);
                    br.makes.splice(index, 1);
                  })
                }
              })
              this.isClickedSave = true;
              this.$emit('updatedTeamsMakes', false)
              if (Number(this.currentUser.id) === Number(this.userId)) {
                this.$emit('changedMakes', this.brands)
              }
              this.getBrands();
              this.$toast.success('User details updated successfully')
            }
          })
          .catch(error => {
            console.log(error);
          });


      // console.log('result', result)
      // return result
    }
  }
}
</script>

<style scoped>

</style>
