/*eslint-disable */
import _ from 'lodash'

export function getToken (state) {
  return state.token
}

export function getTokens (state) {
  return state.tokens
}

export function getSearchFilterObj (state) {
  return state.searchFilterObj
}

export function getAllCredits (state) {
  return state.items
}

export function getApprovedCredits (state) {
  return _.filter(state.items, function (i) {
    return (i.status === 'Approved' || i.status === 'PartialApproved')
  })
}

export function getUnderReviewCredits (state) {
  return _.filter(state.items, function (i) {
    return (i.status === 'UnderReview')
  })
}

export function getDeniedCredits (state) {
  return _.filter(state.items, function (i) {
    return (i.status === 'Denied')
  })
}

export function getCancelledCredits (state) {
  return _.filter(state.items, function (i) {
    return (i.status === 'Cancelled')
  })
}

export function getFilter (state) {
  return state.filter
}

export function getAllCreditsCurrentPage (state) {
  return state.creditAllCurrentPage
}

export function getUnderCreditsCurrentPage (state) {
  return state.creditUnderCurrentPage
}

export function getApprovedCreditsCurrentPage (state) {
  return state.creditApprovedCurrentPage
}

export function getDeniedCreditsCurrentPage (state) {
  return state.creditDeniedCurrentPage
}

export function getCancelledCreditsCurrentPage (state) {
  return state.creditCancelledCurrentPage
}

export function getPerPage (state) {
  return state.perPage
}

export function creditsFromUpdate(state) {
  return state.creditsFromUpdate;
}



