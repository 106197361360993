<template>
    <div>
        <div class="gallery-bg gallery-img-mobile gallery-ready"></div>
        <div class="gallery-wrap gallery-gallery gallery-close-btn-in gallery-auto-cursor gallery-img-mobile gallery-ready"
             tabindex="-1"
             style="overflow: hidden auto;">
            <div class="gallery-container gallery-image-holder gallery-s-ready container-fluid">
                <div class="row">
                    <div class="col-9 offset-3 col-md-11 offset-md-0 offset-lg-0 col-lg-9" style="background-color: white;">
                        <div class="gallery-content d-flex flex-column justify-content-center">
                            <div class="gallery-figure">
                                <button v-if="!loading" title="Close (Esc)" type="button" class="gallery-close"
                                        @click="onClickClose">
                                    <i class='bx bx-x'></i>
                                </button>
                                <image-edditor v-if="isEditing" :image-id="currentImageId" :image-url="currentImageUrl"
                                               @saved-image="onImageSaved"></image-edditor>
                                <figure v-else class="position-relative">
                                    <img v-show="!loading" class="gallery-img" :src="currentImageUrl"
                                         style="max-height: 80vh" @load="onImageLoaded" @loadstart="onImageLoadStart">
                                    <link rel="preload" :href="nextImageUrl" as="image">
                                    <link rel="preload" :href="prevImageUrl" as="image">
                                    <div v-show="loading" class="gallery-img" style="color: white; font-weight: bold">
                                        Loading image {{currentNo}}...
                                    </div>
                                    <b-button
                                            v-if="isRemove"
                                            @click.prevent="onClickRemove"
                                            class="btn btn--remove">
                                        <i class='bx bx-trash'></i>
                                    </b-button>
                                    <figcaption v-if="!loading">
                                        <div class="gallery-bottom-bar d-flex flex-column justify-content-start align-items-end">
                                            <div class="gallery-counter" v-if="countImages > 1">{{currentNo}} of
                                                {{countImages}}
                                            </div>
                                            <div v-if="isDownload"
                                                 class="download_edit_wrap btn-container d-flex justify-content-end">
                                                <!--                          <button v-if="useEditor" class="btn btn-primary order-1 order-md-0 btn&#45;&#45;edit waves-effect waves-light sec_btn" @click="onClickEdit">Edit</button>-->
                                                <button class="button--download btn btn-primary order-0 order-md-1 btn--download waves-effect waves-light"
                                                        @click="onClickDownload">Download
                                                </button>
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <button v-if="countImages > 1" title="Previous (Left arrow key)" type="button"
                                @click="onClickPrev"
                                class="gallery-arrow gallery-arrow-left gallery-prevent-close">
                            <i class='bx bx-chevron-left'></i>
                        </button>
                        <button v-if="countImages > 1" title="Next (Right arrow key)" type="button" @click="onClickNext"
                                class="gallery-arrow gallery-arrow-right gallery-prevent-close">
                            <i class='bx bx-chevron-right'></i>
                        </button>
                    </div>
                </div>
                <div class="gallery__gray-bg"></div>


            </div>
        </div>
    </div>
</template>

<script>
    import imageGallery from "../../../../components/image-lightbox/image-gallery"
    export default {
        name: "SupplierImageGallery",
        extends: imageGallery,
        watch: {
            currentIndex(val){
                this.selectedIndex = val;
            }
        }
    }
</script>