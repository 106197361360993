/*eslint-disable */
import Axios from "axios";
import _ from "lodash";

import {
    REPAIRER_ORDERS_UPDATE_ALL,
    REPAIRER_ORDERS_FILTER_UPDATE,
    REPAIRER_UPDATE_CURRENT_ORDER,
} from '../../../mutation-types';

export function getOrdersForTableView(context, payload) {
    let url = '/ir/repairer/orders/init';
    if(!_.isEmpty(payload.timestamp)) url = '/ir/repairer/orders/'+payload.timestamp;
    Axios.post(url)
        .then(response => {
            if (response && response.data && response.data.status && response.data.orders) {
                context.commit(REPAIRER_ORDERS_UPDATE_ALL, response.data.orders);
                // if (payload.isFromUpdate) {
                //     context.commit(REPAIRER_UPDATE_CURRENT_ORDER, response.data.orders);
                // }
            }
        })
        .catch(error => {

            console.log(error);
        });
}

export function init(context, payload) {
    return getOrdersForTableView(context, payload);
}

export function setFilter(context, filter) {
    context.commit(REPAIRER_ORDERS_FILTER_UPDATE, filter);
}


