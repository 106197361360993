<script>
import ForgotUserDataPageCcnsw from "@/views/auth/ccnsw/forgot-userdata.vue";
import ForgotUserDataPageDefault from "@/views/auth/default/forgot-userdata.vue";

export default {
  components: { ForgotUserDataPageDefault, ForgotUserDataPageCcnsw},
  data () {
    return {
      host: '',
    }
  },
  methods: {},
  mounted () {
    this.host = window.location.hostname
  },
  computed: {
    isCCNSW () {
      return (this.host == 'ccnsw.dev.partssearch.com.au')
    },
    isDefault () {
      return !this.isCCNSW
    }
  }
}
</script>

<template>
  <forgot-user-data-page-ccnsw v-if="isCCNSW"></forgot-user-data-page-ccnsw>
  <forgot-user-data-page-default v-else></forgot-user-data-page-default>
</template>
