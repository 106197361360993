<template>
  <div :id="id"  @mouseleave="handleBlur" class="stock-select__comment stock-comment" tabindex="0" @click="setVisible" @keydown="handleKeyInput" >
    <div class="stock-comment__header">
      <b-button-group v-if="presets && presets.length" class="stock-comment__preset-group">
        <b-button v-for="(preset, index) in presets"
                  class="stock-comment__preset font-11"
                  :key="index"
                  tabindex="0"
                  @click.stop.prevent="presetSelect($event, index)"
                  @mouseover="focusParentInput">
          {{ preset }}
        </b-button>
      </b-button-group>
      <div v-if="!commentVisible" class="stock-text">Add Comment...</div>
    </div>
    <highlightable-input
        v-show="commentVisible"
        class="stock-text"
        :limit="50"
        v-model="text"
        ref="hInput"
        @onHighlight="handleHighlight"
    />
    <div class="d-flex justify-content-between">
      <span :class="{'text-danger': !isLengthValid()}" class="stock-comment__info">Limit of 50 characters</span>
      <i @click="clickSaveIcon($event)" class="bx bx-save save-comment-icon"></i>
    </div>

  </div>
</template>

<script>

import HighlightableInput from './HighlightableInput'
import {mapGetters} from "vuex";

export default {
  name: 'StockComment',
  components: {
    HighlightableInput
  },
  props: {
    isForContext: {
      type: Boolean,
      default: false,
    },
    isStockAll: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    option: {
      type: Object
    }
  },
  data () {
    return {
      commentVisible: false,
      text: '',
    }
  },
  computed: {
    ...mapGetters({
      getterSettings: 'settings/getSettings'
    }),
    getSettings () {
      return _.cloneDeep(this.getterSettings)
    },
    presets () {
      if (this.option) {
        if (this.isStockAll) {
          if (this.getSettings.rfq.stockStatusQuickComments.allEnabled) {
            return this.getSettings.rfq.stockStatusQuickComments.all[this.option.value]
          }
        } else {
          return this.getSettings.rfq.stockStatusQuickComments.single[this.option.value]
        }
      }
      return []
    },
  },
  mounted () {
    this.text = this.value
    this.commentVisible = !_.isEmpty(this.text)

  //  const textInput = this.$el.querySelector('.text-input')
    // textInput.addEventListener('keydown', this.handleKeyInput)
    //textInput.addEventListener('blur', this.handleBlur)
  },
  methods: {
    focusParentInput () {
      if (!this.isForContext) {
        this.$parent.$refs.statusInput.focus()
      }
      this.$parent.commentVisible = true
    },
    isLengthValid () {
      return this.text.length <= 50
    },
    setVisible (e) {
      console.log('setVisible', e)
      e.stopPropagation()

      if (!this.commentVisible) {
        this.commentVisible = true
        this.text = ' '
      }
    },
    onInputText () {
      this.$emit('input', this.text)
    },
    onFocus () {
      this.$emit('onFocus')
    },
    handleHighlight (isHighlight) {
      this.$emit('onHighlight', isHighlight || !this.isLengthValid())
    },
    clickSaveIcon (e) {
      console.log('click-icon',e);
      e.stopPropagation()
      this.$emit('on-enter',e)
    },
    handleKeyInput (e) {
      console.log('SC:handleKeyInput',e)
      if (e.keyCode === 13) {
        // enter
        e.preventDefault()
        this.$emit('on-enter', e)
      } else if (e.keyCode === 38) {
        // up key
        e.preventDefault()
        this.$emit('on-up', e)
      } else if (e.keyCode === 40) {
        // down key
        e.preventDefault()
        this.$emit('on-down', e)
      } else if (e.keyCode === 27) {
        // esc key
        e.preventDefault()
        this.$emit('on-esc', e)

        //only trigger when comment empty
      } else if (this.text.length === 0) {
        // left key
        if (e.keyCode === 37) {
          e.preventDefault()
          this.$emit('on-empty-left', e)

          // right key
        } else if (e.keyCode === 39) {
          e.preventDefault()
          this.$emit('on-empty-right', e)
        }
      }
    },
    handleBlur (e) {
      console.log('BLUR',e)
      this.$emit('on-blur', e)
    },
    presetSelect (e, index) {
      console.log('presetSelect', index)
      this.setVisible(e)
      if (this.isStockAll) {
        if (this.getSettings.rfq.stockStatusQuickComments.allEnabled) {
          this.text += this.getSettings.rfq.stockStatusQuickComments.all[this.option.value][index]
        }
      } else {
        this.text += this.getSettings.rfq.stockStatusQuickComments.single[this.option.value][index]
      }
      this.$emit('presetSelect')
    }
  },
  watch: {
    value: {
      handler (val) {
        this.text = val
        this.commentVisible = !_.isEmpty(val)
      },
      immediate: true,
    },
    text (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<!--<style lang="scss">-->
<!--    @import "../styles/style-views.scss";-->
<!--</style>-->

<style scoped>
.save-comment-icon {
  position: absolute;
  font-size: 25px;
  bottom: 6px;
  right: 10px;
  cursor: pointer;
  opacity: 0.7;
}

.save-comment-icon:hover {
  opacity: 1;
}
</style>
