/*eslint no-unused-vars: "off"*/
import * as centrifuge from "@/centrifuge";

export const Actions = {
  initAppAfterLogin(context) {
    return new Promise((resolve, reject) => {
      context.dispatch('currentCompany/loadCompanyInfo', {}, {root: true});
      context.dispatch("users/fetchAllUsers", {}, {root: true});
      context.dispatch('currentUser/loadApiKeys', {}, {root: true});
      context.dispatch('settings/loadSettings', {}, {root: true});
      context.dispatch('currentUser/loadUserInfo', {}, {root: true}).then(() => {
        if (context.getters['currentUser/isUserTypeRepairer']) {
          context.dispatch("repairer/init", {}, {root: true});
        }
        if (context.getters['currentUser/isUserTypeSupplier']) {
          context.dispatch("supplier/init", {}, {root: true});
        }
        context.commit('changeLoadStatus', true)

        centrifuge.init(appConfig.centrifugeUrl + "/connection/websocket");
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  },

  reinstallationApp(context) {
    return new Promise((resolve, reject) => {
      context.commit('setReinstateStatus', true)
      // context.dispatch('currentCompany/reinstallation', {}, {root: true});
      context.dispatch("users/reinstallation", {}, {root: true});
      // context.dispatch('currentUser/reinstallation', {}, {root: true});
      context.dispatch('settings/reinstallation', {}, {root: true});
      // context.dispatch('currentUser/reinstallation', {}, {root: true})
      context.dispatch("supplier/reinstallation", {}, {root: true});
      setTimeout(() => {
        context.commit('setReinstateStatus', false)
        resolve()
      }, 1000)
    });
  },
  shortReinstallation(context) {
    return new Promise((resolve, reject) => {
      context.commit('setReinstateStatus', true)
      context.dispatch("supplier/shortReinstallation", {}, {root: true});
      centrifuge.init(appConfig.centrifugeUrl + "/connection/websocket");
      setTimeout(() => {
        context.commit('setReinstateStatus', false)
        resolve();
      }, 1000)
    });
  },
  shortUpdating(context) {
    return new Promise((resolve, reject) => {
      context.dispatch("supplier/shortUpdating", {}, {root: true});
    });
  }
};
