<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <router-link to="/login" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></router-link>
                </div>
            </div>
            <div class="invite-repairer-form">
                <div class="intro-text text-center">
                    <h1>Find your Account</h1>
                    <p class="text-center">
                        Get help by providing us with more information about the email address you are
                        <br />using to register an account with and one of our team members will contact you.
                    </p>
                </div>
                <div class="form">
                    <form method="POST" @submit.prevent="login">
                        <div class="form-group">
                            <label
                                for="email"
                            >Enter your email address you are trying to register an account with</label>
                            <input
                                autocomplete="off"
                                type="email"
                                class="form-control input-field"
                                name="email"
                                placeholder="Email Address"
                            />
                            <div class="w-100 text-right" v-show="false">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="firstname">You First Name</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control input-field"
                                name="firstname"
                                placeholder="First Name"
                            />
                            <div class="w-100 text-right" v-show="false">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="contactnumber">Your Contact Number</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control input-field"
                                name="contactnumber"
                                placeholder="Contact Number"
                            />
                            <div class="w-100 text-right" v-show="false">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                </span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="companyname">Company Name You Would Like to Register</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control input-field"
                                name="companyname"
                                placeholder="Company Name"
                            />
                            <div class="w-100 text-right" v-show="false">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                </span>
                            </div>
                        </div>

                        <div class="form-group">
                            <button
                                type="button"
                                class="btn input-btn"
                                @click="$router.push('/');"
                            >Send to Support</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
.form {
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.intro-text h1 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}
.intro-text p {
  margin-bottom: 20px;
  line-height: 26px;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 29px;
}
</style>
<script>
export default {
    name: "SupplierFindAccount"
};
</script>
